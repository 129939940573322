import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation, Redirect } from "react-router-dom";
import { decode as base64_decode, encode as base64_encode } from 'base-64';
import { EditorState, convertToRaw } from 'draft-js';
import { Editor } from "react-draft-wysiwyg"
//import { convertToHTML } from 'draft-convert';
import draftToHtmlPuri from "draftjs-to-html";
import { NavMenu } from '../NavMenu'
import LoaderControl from '../Controls/LoaderControl';
import BreadCumControl from '../Controls/BreadcumControl';
import AlertText from '../Controls/AlertText';
import EnrollmentBanner from '../EnrollmentBanner';
import Footer from '../Footer';
import { ApiHelper } from '../../customFunctions/apiHelper';
import { MessageFollowup, MessageResponseType } from '../../customFunctions/SysParmConfig';
import { MessageUserListModal } from './MessageUserListModal';
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { useSelector, useDispatch } from 'react-redux';

const MessageCompose = (props) => {

    const sessions = useSelector((state) => state.sessionMgmnt);
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(false);
    const [msg, setMsg] = useState('');
    const [isSuccessMsg, setIsSuccessMsg] = useState(false);
    const [editorState, setEditorState] = useState(EditorState.createEmpty());
    let history = useNavigate();
    const [emailList, setEmailList] = useState([]);
    const [selectedEmail, setSelectedEmail] = useState('')
    const [selectedName, setSelectedName] = useState('')
    const [toContactId, setToContactId] = useState(-1)
    const [showUserList, setShowUserList] = useState(false)
    const [suggestionItems, setSuggestionItems] = useState([]);
    const [priorityTypId, setPriorityTypId] = useState(MessageResponseType.None);
    const [followupTypId, setFollowupTypId] = useState(MessageFollowup.None);
    const [subject, setSubject] = useState('');
    const [htmlContent, setHtmlContent] = useState('');
    const [orgMessageId, setOrgMessageId] = useState(null)

    const [priorityTypeList, setPriorityTypeList] = useState([]);
    const [recipientTypeList, setRecipientTypeList] = useState([]);
    const [responseTypeList, setResponseTypeList] = useState([]);
    const [followupTypeList, setFollowupTypeList] = useState([]);


    const handleEditorChange = (state) => {
        setEditorState(state);
        //convertContentToHTML();
    }

    const onEmailChange = (event) => {      
        setSelectedName(event.target.value);
        var selectedValue = emailList.find(email => email.Name === event.target.value);
        if (selectedValue) {
            setSelectedEmail(selectedValue.EmailAddress);
            setToContactId(selectedValue.ContactId);
            setSelectedName(selectedValue.Name);
        } else {
            setSelectedEmail('');          
            setToContactId(-1);
        }
    };

    const loadEmailList = () => {
        setLoading(true);
        //setAlertMsg('');

        let headers = {
            'Content-Type': 'application/json'
        };
                
        let apiUrl = process.env.REACT_APP_GET_MESSAGE_LOOKUP + props.location.state.loginusrId;

        ApiHelper({ url: apiUrl, method: 'GET', headers: headers, sessions: sessions, dispatch: dispatch })
            .then(resposnse => {
                if (resposnse !== null) {
                    setEmailList(resposnse["MessageNotifyList"]);
                    prepareSuggestionItems(resposnse["MessageNotifyList"]);

                    setPriorityTypeList(resposnse["PriorityType"]);
                    setRecipientTypeList(resposnse["RecipientType"]);
                    setResponseTypeList(resposnse["ResponseType"]);
                    setFollowupTypeList(resposnse["FollowupType"]);

                    setLoading(false);
                }
            });
    }

    const prepareSuggestionItems = (items) => {
        let sugItems = [];
        items.map((e) => (           
            sugItems.push({ text: e.Name, value: e.Name, url: e.EmailAddress })
        ));
        setSuggestionItems(sugItems);
    }

    const SelectedUserFromModalOnHide = (cntctId, emailAdrs, name) => {
        if (cntctId) {
            setToContactId(cntctId);
            setSelectedEmail(emailAdrs);
            setSelectedName(name);
            setShowUserList(false);
        }
    }

    const handleUserListClick = () => {   
        setShowUserList(true);
    }

    const handelCancelClick=() => {
        history.push({ pathname: '/DashBoard' });
    }

    const handleSendMessage = () => {

        let plainText = editorState.getCurrentContent().getPlainText();
        if (!plainText) {
            setMsg('Email body content cannot be empty.');
            setIsSuccessMsg(false);
            return;
        }

        let htmlText = draftToHtmlPuri(convertToRaw(editorState.getCurrentContent()));
        let text = encodeURIComponent(htmlText); //base64_encode(htmlText);

        if (text.length > 8000) {
            setMsg('You can\'t use more than 8000 characters in Email body.');
            setIsSuccessMsg(false);
            return;
        }

        setMsg('');
        setLoading(true);
       
        let inputJson = {
            "MessageId": null,
            "PriorityTypId": parseInt(priorityTypId),
            "FromCntctId": parseInt(props.location.state.loginusrId),
            "ToCntctId": toContactId,
            "Subject": subject,
            "Text": text,
            "OrgMessageId": orgMessageId,
            "ModUserId": parseInt(props.location.state.loginusrId),
            "ResponseTypId": parseInt(props.location.state.responseTypId),
            "FlagShowSendMail": true, //None
            "FollowupTypId": parseInt(followupTypId), //'None'
            "HasReplied": false,
            "IsDeleted":false
        }

        let headers = {
            'Content-Type': 'application/json'
        };

        let apiUrl = process.env.REACT_APP_CREATEMODIFY_MESSAGE;

        ApiHelper({ url: apiUrl, method: 'POST', headers: headers, body: inputJson, sessions: sessions, dispatch: dispatch})
            .then(resposnse => {
                if (resposnse !== null) {
                    if (resposnse.MessageId > 0) {
                        setIsSuccessMsg(true);
                        setMsg("Message has been sent.")
                        setLoading(false);
                        history.push({ pathname: '/DashBoard' });
                    } else {
                        setIsSuccessMsg(false);
                        setMsg(resposnse.Message);
                        setLoading(false);
                    }
                }
            });
       

    }

    useEffect(() => {
        loadEmailList();

        if (props.location.state.orgMsgId && parseInt(props.location.state.orgMsgId) > 0) {
            setHtmlContent(props.location.state.data.Content);
            setSubject(props.location.state.data.Subject);
            setToContactId(props.location.state.data.ToContactId);
            setSelectedName(props.location.state.data.ToContact);
            setOrgMessageId(props.location.state.orgMsgId);
            setSubject(props.location.state.subject);
            setFollowupTypId(props.location.state.followupId);
        }

    }, []);


    return (
        <>
            <EnrollmentBanner />            
            <section className="p-t-1 da-body app-dashboard m-b-20">
                <div className="container p-0">
                    <div className="row g-0">
                        <NavMenu />
                    </div>
                    <div className="row g-0">
                        <LoaderControl show={loading} />
                        <BreadCumControl pathname="Compose Message" />
                    </div>
                    <div className="row p-t-10 justify-content-center">
                        <div className="col-sm-9">
                            <div className="p-3 mb-2 bg-white text-dark border rounded">
                                <h6>Notes:</h6>
                                <h6>* Please do not send time sensitive information</h6>
                                <h6>* Only one attachment is allowed per email with maximum size of attachment not to exceed 10MB</h6>
                            </div>                            
                        </div>
                        
                        <div className="col-sm-9">
                            <AlertText Message={msg} isSuccess={ isSuccessMsg} />
                        </div>
                        <div className="col-sm-9">
                            <label htmlFor="toEmail" className="form-label">To</label>
                            <div className="mb-3 input-group">
                                {/*<input placeholder="Email Address" id="toEmail" aria-label="Email Address" aria-describedby="basic-addon1" className="form-control" readOnly />*/}
                                <input className="form-control" list="userList" id="toEmail" placeholder="Type to search Contact Person..." value={selectedName} onChange={onEmailChange} autoComplete="off" disabled={parseInt(props.location.state.orgMsgId) > 0} />
                                <datalist id="userList">
                                    {
                                        emailList.map((e) => (
                                            <option key={e.ContactId} value={e.Name}>{e.EmailAddress}</option>
                                        ))
                                    }
                                </datalist>
                                <button className="btn btn-outline-dark border-0" type="button" onClick={() => handleUserListClick(toContactId)} disabled={parseInt(props.location.state.orgMsgId) > 0}><i className="fas fa-user"></i></button>
                                {/*<span className="input-group-text" style={{ backgroundColor: 'transparent', border: 'none' }} id="basic-addon1"><a onClick={() => alert('User List...')}><i className="fas fa-user"></i></a></span>*/}
                            </div>
                        </div>
                        <div className="col-sm-7">
                            <label htmlFor="subject" className="form-label">Subject</label>
                            <div className="mb-3 form-group">
                                <input placeholder="Subject" id="subject" aria-label="Email Address" className="form-control" value={subject} onChange={(event) => setSubject(event.target.value)} maxLength='80' />
                            </div>
                        </div>
                        <div className="col-sm-2">
                            <label htmlFor="piority" className="form-label">Priority</label>
                            <div className="mb-3 form-group">
                                <select className="form-select" aria-label="Default select example" value={priorityTypId} onChange={(event) => setPriorityTypId(event.target.value)}>
                                    {
                                        priorityTypeList.map((e) => (
                                            <option key={e.LookupId} value={e.LookupId}>{e.LookupDesc}</option>
                                            ))
                                    }
                                    {/*<option value="44">Normal</option>*/}
                                    {/*<option value="45">Medium</option>*/}
                                    {/*<option value="46">High</option>*/}
                                </select>
                            </div>
                        </div>
                        
                        <div className="col-sm-7 p-b-4">
                            <label htmlFor="formFile" className="form-label">Attachment</label>
                            <input className="form-control" type="file" id="formFile" />
                        </div>

                        <div className="col-sm-2">
                            <label htmlFor="piority" className="form-label">Response</label>
                            <div className="mb-3 form-group">
                                <select className="form-select" aria-label="Default select example" value={followupTypId} onChange={(event) => setFollowupTypId(event.target.value)}>
                                    {
                                        followupTypeList.map((e) => (
                                            <option key={e.LookupId} value={e.LookupId}>{e.LookupDesc}</option>
                                        ))
                                    }
                                    {/*<option value="53">None</option>*/}
                                    {/*<option value="54">Follow-up</option>*/}
                                    {/*<option value="55">Complete</option>*/}
                                </select>
                            </div>
                        </div>

                        <div className="col-sm-9">
                            <label htmlFor="formFile" className="form-label">Enter email body content</label>
                            <div className="mb-3 form-group">
                                <div className='editor'>
                                    <Editor
                                        editorState={editorState}
                                        toolbarClassName="demo-toolbar-custom"
                                        wrapperClassName="editor-border editor-wrapper"
                                        //onEditorStateChange={onEditorStateChange}
                                        onEditorStateChange={handleEditorChange}
                                        
                                        toolbar={{
                                            inline: { inDropdown: true },
                                            list: { inDropdown: true },
                                            textAlign: { inDropdown: true },
                                            link: { inDropdown: true },
                                            history: { inDropdown: true }
                                        }}
                                        mention={{
                                            separator: ' ',
                                            trigger: '@',
                                            suggestions: suggestionItems
                                        }}
                                        hashtag={{}}
                                            />
                                </div>
                                {
                                    htmlContent ?
                                        <div>
                                            {/*<span className="font-weight-bold">Message History</span>*/}
                                            <p style={{ padding: '10px 0px 0px 0px'}}><strong>Message History</strong></p>
                                            <div style={{ maxHeight: '250px', overflow: 'auto' }} className="editor-border"
                                                dangerouslySetInnerHTML={{
                                                    __html: htmlContent
                                                }}
                                                />
                                        </div>
                                        :
                                        <div />
                                }
                                
                            </div>
                        </div>
                        <div className="col-sm-9">
                            <div className="d-grid gap-2 d-md-flex justify-content-md-end">
                                <button className="btn btn-secondary me-md-2" type="button" onClick={handelCancelClick}>
                                    <svg id="Layer_1" dataname="Layer 1" xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 122.88 122.88">
                                        <title>cross</title>
                                        <path className="cls-1000" d="M6,6H6a20.53,20.53,0,0,1,29,0l26.5,26.49L87.93,6a20.54,20.54,0,0,1,29,0h0a20.53,20.53,0,0,1,0,29L90.41,61.44,116.9,87.93a20.54,20.54,0,0,1,0,29h0a20.54,20.54,0,0,1-29,0L61.44,90.41,35,116.9a20.54,20.54,0,0,1-29,0H6a20.54,20.54,0,0,1,0-29L32.47,61.44,6,34.94A20.53,20.53,0,0,1,6,6Z" />
                                    </svg>
                                    <span className="m-s-3">Cancel</span>
                                </button>
                                <button className="btn btn-primary" type="button" disabled={!(subject && (toContactId > 0))} onClick={handleSendMessage}><i className="fa fa-paper-plane px-1"></i>&nbsp; Send</button>
                            </div>
                        </div>

                        {
                            showUserList ?
                                <MessageUserListModal onHide={SelectedUserFromModalOnHide} onExit={() => setShowUserList(false)} show={(emailList && showUserList ? true : false)} title={"Contact Person"} data={emailList} selectedcntctid={toContactId} />
                                : <div />
                        }
                    </div>
                </div>
            </section>
            <Footer />
        </>);
}

export default MessageCompose;