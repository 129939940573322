import React from 'react';

export const ErrorMessages = {
    MultipleLoginMsg: "You are currently logged on from some other place. Continuing would invalidate your other session.",
    UnknownError: "We are facing technical problems. Please try after sometime.",
    AccountLocked: "Your account is temporarily locked to prevent unauthorized use. Try again later, and if you still have trouble, contact us.",
    InvalidCredential: "The Email address or Password entered is invalid. Please try again.",
    InactiveUser: "Login denied. You are no longer an active user.",
    AccountNotActivated: "Account associated with the email has not been activated, kindly activate your account to login.",
    DuplicateEmail: "An account is already registered with your email address. If you don't own that account, please call our customer care number."
}

