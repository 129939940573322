import React, { Component }  from 'react';
import Cookies from "js-cookie";
import * as $ from 'jquery';
import { ApiHelper } from '../customFunctions/apiHelper';
import 'reactjs-popup/dist/index.css';
import './TemplateUI.css';
import ButtonDashBoardHome from './ButtonDashBoardHome';
import { useLocation } from "react-router-dom";
import { useSelector, useDispatch } from 'react-redux';
import { NavMenu } from './NavMenu';
import BreadCumControl from './Controls/BreadcumControl';
import LoaderControl from './Controls/LoaderControl';

//For Element Drag and Drop
//require("jquery-ui-sortable");
require('formBuilder/dist/form-render.min.js')


class FormRender extends Component {
    constructor(props) {
        super(props);
        this.state = { loading: false };
    }

    componentDidMount() {        
        //let jsonObj = Cookies.get("token");
        //let authToken = this.props.token;
        //let accessToken = authToken["Access_Token"];
        //let tokenType = authToken["Token_Type"];

        let headers = {
            'Content-Type': 'application/json'
        };
       
        let apiUrl = process.env.REACT_APP_GET_TEMPLATE_VALUE + this.props.templateid;
        ApiHelper({ url: apiUrl, method: 'GET', headers: headers, sessions: this.props.sessions, dispatch: this.props.dispatch })
            .then(resposnse => {

                if (resposnse !== null && resposnse[0].templateValue !== 'undefined') {
                    $('.fb-render').formRender({
                        dataType: 'json',
                        formData: resposnse[0].templateValue,
                        i18n: {
                            location: process.env.REACT_APP_I18N_LANG
                        }
                    });
                    this.setState({ loading: false });
                }
            });
        
    }

    render() {
        return (<>
            <NavMenu />
            <section className="bg-light text-left">
                <div className="container pb-5 pt-2">
                    <BreadCumControl pathname="Application" />
                    <LoaderControl show={this.state.loading} />
                    <div className="fb-render"></div>
                </div>
            </section>
        </>);
    }
}

export function TemplateUI() {
    const location = useLocation();
    const sessions = useSelector((state) => state.sessionMgmnt);
    const dispatch = useDispatch();
    let token = '';
    sessions.map((sn) => { if (sn.key === 'AuthToken') { token = sn.value; } });

    return (
        <FormRender templateid={location.state.templateid} token={token} sessions={sessions} dispatch={dispatch} />
    );
}
