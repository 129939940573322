import React, { useEffect, useState } from 'react';
import { Nav, Navbar, Container, NavDropdown } from 'react-bootstrap';
import { Tools, StopCircleFill  } from 'react-bootstrap-icons';
import { Link } from 'react-router-dom';
import './NavMenu.css';
import logo from '../assets/images/BankLogo.png'
import uacct from '../assets/images/acct.png'
//import { useHistory } from "react-router-dom";
import { useSelector } from 'react-redux';
import Popper from 'popper.js'
/*import { PicUploadModal } from './DashBoard/Modal/PicUploadModal';*/
import axios from 'axios';

export function NavMenu(){
    const [imageUrl, setImageUrl] = useState(null);
    //let history = useHistory();

    let roleInd = {
        admin: 1,
        prospect: 2,
        bankEmp: 3
    }

    const sessions = useSelector((state) => state.sessionMgmnt);
    const [state, setState] = useState({
        CntctId:parseInt(null),
        EmailAdrs: '',
        UserNm: '',
        RolePrcsId: parseInt(null)
    });
   /* const [hasSession, setHasSession] = useState(true);*/

    useEffect(() => {
        let cntctId = parseInt(null);
        let emailAdrs = '';
        let userNm = ''
        let roleId = parseInt(null);
        sessions.map(
            (sn) => {
                if (sn.key === 'LoginUserId') {
                    cntctId = parseInt(sn.value);
                }
                else if (sn.key === 'LoginUserEmail') {
                    emailAdrs = sn.value;
                }
                else if (sn.key === 'LoginUserName') {
                    userNm = sn.value;
                }
                else if (sn.key === 'Role') {
                    roleId = parseInt(sn.value);
                }
            });
        loadProfilePic(cntctId);
        //if (!cntctId && !emailAdrs && !userNm && process.env.REACT_APP_ENVIORNMENT !== 'dev') {
        //    window.location.href = '/?status=session_out';
        //}
        
        setState({
            CntctId: cntctId,
            EmailAdrs: emailAdrs,
            UserNm: userNm,
            RolePrcsId: roleId
        });
    }, []);

    const handleManageProfile = (event) => {
        event.preventDefault();
       
        //history.push({
        //    pathname: '/UserProfile',
        //    state: { CntCtId: state.CntctId, EmailAdrs: state.EmailAdrs }
        //})
    }
    const handleSysParms = (event) => {
        event.preventDefault();

        //history.push({
        //    pathname: '/ParameterMaintenance',
        //    state: { CntCtId: state.CntctId, EmailAdrs: state.EmailAdrs }
        //})
    }
    const handleHelp = (event) => {
        event.preventDefault();
    }

    const handleUserMangement = (event) => {
        event.preventDefault();
        
        //history.push({
        //    pathname: '/UserManagement'
        //})
    }

    const handleWorkgrpMangement = (event) => {
        event.preventDefault();

        //history.push({
        //    pathname: '/RoleManagement'
        //})
    }

    const handleErrorLogInfo = (event) => {
        event.preventDefault();

        //history.push({
        //    pathname: '/ErrorInfoMain',
        //    state: { infotype: 1, CntCtId: state.CntctId, EmailAdrs: state.EmailAdrs }
        //})
    }
    const handleTraceLogInfo = (event) => {
        event.preventDefault();

        //history.push({
        //    pathname: '/TraceInfoMain',
        //    state: { infotype: 2, CntCtId: state.CntctId, EmailAdrs: state.EmailAdrs }
        //})
    }
    const handleActivityLogInfo = (event) => {
        event.preventDefault();

        //history.push({
        //    pathname: '/ActivityInfoMain',
        //    state: { infotype: 3, CntCtId: state.CntctId, EmailAdrs: state.EmailAdrs }
        //})
    };

    const handleTemplateRestriction = (event) => {
        event.preventDefault();
        //history.push({
        //    pathname: '/TemplateRestriction'
        //})
    };

    const loadProfilePic = (cntctId) => {
        //Load Profile Picture
        const formData = new FormData();
        formData.append("UserId", cntctId);

        const headers = {
            "x-api-key": process.env.REACT_APP_API_KEY,
            "Content-Type": "application/json",
            'Accept': 'multipart/form-data'
        };

        let endPoint = process.env.REACT_APP_BASE_URL + process.env.REACT_APP_GET_PROFILE_PICTURE;

        try {
            axios.post(endPoint, formData, {
                responseType: 'blob',
                headers: headers
            }).then((response) => {
                if (response && response.data) {
                    if (response.headers["content-type"] !== 'image/gif') {
                        setImageUrl(response.data);
                    }
                    else {
                        setImageUrl(null);
                    }
                    //console.log(response);
                    //console.log(response.headers);
                    //console.log(response.headers["content-type"]);
                 }
                else {
                    setImageUrl(null);
                }
            });

        } catch (ex) {
            console.log(ex);
        }
    }

    //const onClickHandlePic = (event) => {
    //    event.preventDefault();
    //    setShowErrDetails(true);
    //}
    return (
        <>

            <Navbar className="w-100 p-t-4 p-b-1 post-login-nav" collapseOnSelect bg="white" expand="md">
                <Container fluid className="p-0">
                    <Navbar.Brand>
                        <h2 className="" style={{ fontSize: '25px', color: '#3a3a3a', letterSpacing: '0px', lineHeight: 1.5 }}>Account Onboarding</h2>
                    </Navbar.Brand>
                    <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                    <Navbar.Collapse className="justify-content-end" id="responsive-navbar-nav">
                        <Nav>
                            {
                                state.RolePrcsId !== roleInd.prospect ?
                                    <Nav.Item className="align-middle">
                                        <NavDropdown title="Dropdown" id="nav-dropdown" className="m-t-3" title={<span><i className="fa fa-ellipsis-v px-1"></i><span className="m-s-0-1">System</span></span>}>
                                            <NavDropdown.Item eventKey="1.1" onClick={handleUserMangement}>
                                                <span className="m-s-2">
                                                    <svg role="img" xmlns="http://www.w3.org/2000/svg" width="32px" height="32px" viewBox="0 0 24 24" aria-labelledby="peopleIconTitle" stroke="#141414" strokeWidth="1" strokeLinecap="square" strokellinejoin="miter" fill="none" color="#2329D6">
                                                        <title id="peopleIconTitle">People</title> <path d="M1 18C1 15.75 4 15.75 5.5 14.25 6.25 13.5 4 13.5 4 9.75 4 7.25025 4.99975 6 7 6 9.00025 6 10 7.25025 10 9.75 10 13.5 7.75 13.5 8.5 14.25 10 15.75 13 15.75 13 18M12.7918114 15.7266684C13.2840551 15.548266 13.6874862 15.3832994 14.0021045 15.2317685 14.552776 14.9665463 15.0840574 14.6659426 15.5 14.25 16.25 13.5 14 13.5 14 9.75 14 7.25025 14.99975 6 17 6 19.00025 6 20 7.25025 20 9.75 20 13.5 17.75 13.5 18.5 14.25 20 15.75 23 15.75 23 18" /> <path strokeLinecap="round" d="M12,16 C12.3662741,15.8763472 12.6302112,15.7852366 12.7918114,15.7266684" />
                                                    </svg>
                                                    <span className="m-s-2">Manage User</span>
                                                </span>
                                            </NavDropdown.Item>
                                            <NavDropdown.Item eventKey="1.1" onClick={handleWorkgrpMangement}>
                                                <span className="m-s-2">
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="32px" height="32px" viewBox="0 0 24 24" fill="currentColor" className="bi bi-person-fill-gear" viewBox="0 0 16 16">
                                                        <title id="workgrpIconTitle">Workgroup Management</title> <path d="M11 5a3 3 0 1 1-6 0 3 3 0 0 1 6 0Zm-9 8c0 1 1 1 1 1h5.256A4.493 4.493 0 0 1 8 12.5a4.49 4.49 0 0 1 1.544-3.393C9.077 9.038 8.564 9 8 9c-5 0-6 3-6 4Zm9.886-3.54c.18-.613 1.048-.613 1.229 0l.043.148a.64.64 0 0 0 .921.382l.136-.074c.561-.306 1.175.308.87.869l-.075.136a.64.64 0 0 0 .382.92l.149.045c.612.18.612 1.048 0 1.229l-.15.043a.64.64 0 0 0-.38.921l.074.136c.305.561-.309 1.175-.87.87l-.136-.075a.64.64 0 0 0-.92.382l-.045.149c-.18.612-1.048.612-1.229 0l-.043-.15a.64.64 0 0 0-.921-.38l-.136.074c-.561.305-1.175-.309-.87-.87l.075-.136a.64.64 0 0 0-.382-.92l-.148-.045c-.613-.18-.613-1.048 0-1.229l.148-.043a.64.64 0 0 0 .382-.921l-.074-.136c-.306-.561.308-1.175.869-.87l.136.075a.64.64 0 0 0 .92-.382l.045-.148ZM14 12.5a1.5 1.5 0 1 0-3 0 1.5 1.5 0 0 0 3 0Z" />
                                                    </svg>
                                                    <span className="m-s-2">Manage Workgroup</span>
                                                </span>
                                            </NavDropdown.Item>
                                            <NavDropdown.Item eventKey="1.1" onClick={handleTemplateRestriction}>
                                                <span className="m-s-2">
                                                    <StopCircleFill className="" color="#3D3D3D" size={24} title="Template Restriction" />
                                                    <span className="m-s-2">Template Restriction</span>
                                                </span>
                                            </NavDropdown.Item>
                                            <NavDropdown.Item eventKey="1.1" onClick={handleSysParms}>
                                                <span className="m-s-2">
                                                    <Tools className="" color="#3D3D3D" size={24} title="System Parameter" />
                                                    <span className="m-s-2">System Parameter</span>
                                                </span>
                                            </NavDropdown.Item>
                                            <NavDropdown.Item eventKey="1.2" onClick={handleErrorLogInfo}>
                                                <svg xmlns="http://www.w3.org/2000/svg" width="32px" height="24" viewBox="0 0 24 24" fill="currentColor" className="bi bi-exclamation-diamond-fill" viewBox="0 0 16 16">
                                                    <title id="peopleIconTitle">Error Log</title><path d="M9.05.435c-.58-.58-1.52-.58-2.1 0L.436 6.95c-.58.58-.58 1.519 0 2.098l6.516 6.516c.58.58 1.519.58 2.098 0l6.516-6.516c.58-.58.58-1.519 0-2.098L9.05.435zM8 4c.535 0 .954.462.9.995l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 4.995A.905.905 0 0 1 8 4zm.002 6a1 1 0 1 1 0 2 1 1 0 0 1 0-2z" />
                                                </svg>
                                                
                                                <span className="m-s-2">Error Log</span>
                                            </NavDropdown.Item>
                                            <NavDropdown.Item eventKey="1.3" onClick={handleTraceLogInfo}>
                                                <svg xmlns="http://www.w3.org/2000/svg" width="32px" height="24" fill="currentColor" viewBox="0 0 24 24" className="bi bi-clock" viewBox="0 0 16 16">
                                                    <title id="peopleIconTitle">Trace Log</title><path d="M8 3.5a.5.5 0 0 0-1 0V9a.5.5 0 0 0 .252.434l3.5 2a.5.5 0 0 0 .496-.868L8 8.71V3.5z" />
                                                    <path d="M8 16A8 8 0 1 0 8 0a8 8 0 0 0 0 16zm7-8A7 7 0 1 1 1 8a7 7 0 0 1 14 0z" />
                                                </svg>
                                                <span className="m-s-2">Trace Log</span>
                                            </NavDropdown.Item>
                                            <NavDropdown.Item eventKey="1.4" onClick={handleActivityLogInfo}>
                                                <svg xmlns="http://www.w3.org/2000/svg" width="32px" height="24" fill="currentColor" className="bi bi-card-checklist" viewBox="0 0 16 16">
                                                    <title id="peopleIconTitle">Activity Log</title><path d="M14.5 3a.5.5 0 0 1 .5.5v9a.5.5 0 0 1-.5.5h-13a.5.5 0 0 1-.5-.5v-9a.5.5 0 0 1 .5-.5h13zm-13-1A1.5 1.5 0 0 0 0 3.5v9A1.5 1.5 0 0 0 1.5 14h13a1.5 1.5 0 0 0 1.5-1.5v-9A1.5 1.5 0 0 0 14.5 2h-13z" />
                                                    <path d="M7 5.5a.5.5 0 0 1 .5-.5h5a.5.5 0 0 1 0 1h-5a.5.5 0 0 1-.5-.5zm-1.496-.854a.5.5 0 0 1 0 .708l-1.5 1.5a.5.5 0 0 1-.708 0l-.5-.5a.5.5 0 1 1 .708-.708l.146.147 1.146-1.147a.5.5 0 0 1 .708 0zM7 9.5a.5.5 0 0 1 .5-.5h5a.5.5 0 0 1 0 1h-5a.5.5 0 0 1-.5-.5zm-1.496-.854a.5.5 0 0 1 0 .708l-1.5 1.5a.5.5 0 0 1-.708 0l-.5-.5a.5.5 0 0 1 .708-.708l.146.147 1.146-1.147a.5.5 0 0 1 .708 0z" />
                                                </svg>
                                                <span className="m-s-2">Activity Log</span>
                                            </NavDropdown.Item>
                                        </NavDropdown>
                                    </Nav.Item> : ''
                            }
                            <Nav.Item className="align-middle">
                                <Nav.Link className="m-t-3">
                                    <svg xmlns="http://www.w3.org/2000/svg" shapeRendering="geometricPrecision" textRendering="geometricPrecision" imageRendering="optimizeQuality" fillRule="evenodd" clipRule="evenodd" width="28" height="28" viewBox="0 0 512 512">
                                        <path fillRule="nonzero" d="M256 0c70.69 0 134.7 28.66 181.02 74.98C483.34 121.31 512 185.31 512 256c0 70.69-28.66 134.7-74.98 181.02C390.7 483.34 326.69 512 256 512c-70.69 0-134.69-28.66-181.02-74.98C28.66 390.7 0 326.69 0 256c0-70.69 28.66-134.69 74.98-181.02C121.31 28.66 185.31 0 256 0zm-21.49 301.51v-2.03c.16-13.46 1.48-24.12 4.07-32.05 2.54-7.92 6.19-14.37 10.97-19.25 4.77-4.92 10.51-9.39 17.22-13.46 4.31-2.74 8.22-5.78 11.68-9.18 3.45-3.36 6.19-7.27 8.23-11.69 2.02-4.37 3.04-9.24 3.04-14.62 0-6.4-1.52-11.94-4.57-16.66-3-4.68-7.06-8.28-12.04-10.87-5.03-2.54-10.61-3.81-16.76-3.81-5.53 0-10.81 1.11-15.89 3.45-5.03 2.29-9.25 5.89-12.55 10.77-3.3 4.87-5.23 11.12-5.74 18.74h-32.91c.51-12.95 3.81-23.92 9.85-32.91 6.1-8.99 14.13-15.8 24.08-20.42 10.01-4.62 21.08-6.9 33.16-6.9 13.31 0 24.89 2.43 34.84 7.41 9.96 4.93 17.73 11.83 23.27 20.67 5.48 8.84 8.28 19.1 8.28 30.88 0 8.08-1.27 15.34-3.81 21.79-2.54 6.45-6.1 12.24-10.77 17.27-4.68 5.08-10.21 9.54-16.71 13.41-6.15 3.86-11.12 7.82-14.88 11.93-3.81 4.11-6.56 8.99-8.28 14.58-1.73 5.63-2.69 12.59-2.84 20.92v2.03h-30.94zm16.36 65.82c-5.94-.04-11.02-2.13-15.29-6.35-4.26-4.21-6.35-9.34-6.35-15.33 0-5.89 2.09-10.97 6.35-15.19 4.27-4.21 9.35-6.35 15.29-6.35 5.84 0 10.92 2.14 15.18 6.35 4.32 4.22 6.45 9.3 6.45 15.19 0 3.96-1.01 7.62-2.99 10.87-1.98 3.3-4.57 5.94-7.82 7.87-3.25 1.93-6.86 2.9-10.82 2.94zM417.71 94.29C376.33 52.92 319.15 27.32 256 27.32c-63.15 0-120.32 25.6-161.71 66.97C52.92 135.68 27.32 192.85 27.32 256c0 63.15 25.6 120.33 66.97 161.71 41.39 41.37 98.56 66.97 161.71 66.97 63.15 0 120.33-25.6 161.71-66.97 41.37-41.38 66.97-98.56 66.97-161.71 0-63.15-25.6-120.32-66.97-161.71z" />
                                    </svg>
                                    <span className="m-s-2">Help</span>
                                </Nav.Link>
                            </Nav.Item>
                            {/*<Nav.Item>*/}
                            {/*    <Nav.Link className="profile-icon">*/}
                            {/*        <img className="BankLogo" src={uacct} width="24" />*/}
                            {/*    </Nav.Link>*/}
                            {/*</Nav.Item>*/}
                            
                            <Nav.Item>
                                <NavDropdown className="nav-profile-dropdown" id="nav-dropdown" title={
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" width="38" height="38">
                                        <path className="cls-1001" d="M256 0C114.6 0 0 114.6 0 256s114.6 256 256 256s256-114.6 256-256S397.4 0 256 0zM362.1 365.8c-3.266 1.506-6.687 2.229-10.05 2.229c-9.078 0-17.75-5.18-21.81-14l-12.03-26.18H193.8l-12.03 26.18c-5.576 12.09-19.76 17.36-31.86 11.77c-12.03-5.559-17.28-19.91-11.73-31.99l95.99-208.1c7.842-17.08 35.75-17.08 43.59 0l95.99 208.1C379.3 345.9 374.1 360.2 362.1 365.8zM215.1 279.6h80.05L256 192.5L215.1 279.6z" />
                                    </svg>
                                }>
                                    <div className="profile-dropdown">
                                        <NavDropdown.Item className="" eventKey="1.2" style={{ padding: '32px 36px 20px' }}>
                                                <div className="text-center">
                                                <div className="">
                                                    {imageUrl ?
                                                        <img src={URL.createObjectURL(imageUrl)} alt="Prifile Picture" height="100" width="100" className="rounded-circle"/>
                                                        : 
                                                        <svg xmlns="http://www.w3.org/2000/svg" shapeRendering="geometricPrecision" textRendering="geometricPrecision" imageRendering="optimizeQuality" fillRule="evenodd" clipRule="evenodd" width="72" height="72" viewBox="0 0 424 511.65">
                                                            <path className="cls-1002" d="M28.21 53.37h67.64v43.72H66.68c-13.03 0-23.82 10.8-23.82 23.82v326.57c0 13.04 10.73 23.82 23.82 23.82h290.64c13.09 0 23.82-10.75 23.82-23.82V120.91c0-13.08-10.79-23.82-23.82-23.82h-29.17V53.37h67.64c15.52 0 28.21 12.77 28.21 28.21v401.86c0 15.46-12.75 28.21-28.21 28.21H28.21C12.75 511.65 0 498.96 0 483.44V81.58c0-15.52 12.69-28.21 28.21-28.21zM212 379.45H111.25c-1.64 0-2.98-1.33-2.98-2.98 0-.85.12-1.68.33-2.52 4.77-37.82 24.32-38.49 45.36-43.98 6.29-1.65 17.76-5.14 24.44-10.99 3.66-3.23 5.9-7.17 5.01-11.92-5.09-4.71-8.99-9.8-9.88-19.52l-.6.01c-1.42-.02-2.78-.34-4.05-1.06-2.8-1.59-4.34-4.65-5.08-8.14-.94-4.41-.63-9.64-.17-12.96l.17-.64c.98-2.73 2.19-4.21 3.73-4.85l.04-.02c-.7-13.13 1.52-33.93-11.97-38.01 26.62-32.9 57.32-50.79 80.36-21.53 25.68 1.35 37.13 39.2 21.19 59.56h-.68c1.54.64 2.75 2.12 3.73 4.85l.17.64c.46 3.32.77 8.55-.17 12.96-.74 3.49-2.28 6.55-5.08 8.14-1.27.72-2.63 1.04-4.05 1.06l-.6-.01c-.89 9.72-4.79 14.81-9.88 19.52-.89 4.75 1.35 8.69 5.01 11.92 6.68 5.85 18.15 9.34 24.44 10.99 21.04 5.49 40.59 6.16 45.36 43.98.21.84.33 1.67.33 2.52 0 1.65-1.34 2.98-2.98 2.98H212zM134.93 39.4h31.97C169.6 17.18 187.53 0 209.28 0c21.6 0 39.44 16.95 42.33 38.93l37.42.47c2.62 0 4.71 2.09 4.71 4.7v63.84c0 2.6-2.09 4.7-4.71 4.7H134.97c-2.56 0-4.7-2.1-4.7-4.7V44.1c-.05-2.61 2.05-4.7 4.66-4.7zm56.84 19.33c2.89 3.96 7.03 7.86 11.41 9.87 3.58 1.07 7.49 1.17 11.13.18 5.68-2.61 10.9-8.89 13.31-14.15.29-1.49.48-3.03.48-4.71 0-11.13-8.53-20.16-19.06-20.16-10.52 0-19.04 9.03-19.04 20.16.05 3.36.65 6.34 1.77 8.81z" />
                                                        </svg>
                                                        }
                                                    </div>
                                                    <h6 className="m-t-10 p-b-0 m-b-0">
                                                        {state.UserNm}
                                                    </h6>
                                                    <p className="text-muted small m-t-0 p-t-0">
                                                        {state.EmailAdrs}
                                                    </p>
                                                    <button type="button" className="btn btn-outline p-s-5 p-e-5 p-t-1 m-t-10" style={{ border: '1px solid rgba(0,0,0,0.125)', borderRadius: '40px' }} onClick={handleManageProfile}>
                                                        <small className="m-t-1" style={{ fontWeight: 'bold' }}>Manage Your Account</small>
                                                    </button>
                                                </div>
                                            </NavDropdown.Item>
                                        <NavDropdown.Divider />
                                        <div className="text-center prof-terms m-t-5 p-b-2">
                                            <a className="prof-terms"><small>Privacy Policy</small></a> . <a className="prof-terms"><small>Terms &amp; Services</small></a>
                                        </div>
                                    </div>
                                </NavDropdown>
                            </Nav.Item>                            
                        </Nav>
                    </Navbar.Collapse>
                </Container>
            </Navbar>
        </>
    );  
}