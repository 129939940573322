import { Box, Button, FormControl, Grid, InputLabel, MenuItem, Select, TextField, Typography, CardMedia, Paper, Divider } from '@mui/material';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import dayjs from 'dayjs';
import { useFormik } from 'formik';
import React, { useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from "react-router-dom";
import * as yup from 'yup';
import { ErrorMessages } from '../common/ErrorMessages';
import { InfoMessages } from '../common/InfoMessages';
import { CallAPI, GetLoggedInUserId, GetLoggedInUserEmail, GetLoggedInUserName } from '../global/APIUtils';
import { LoadProfilePic } from '../global/Helpers';
import { ProfilePicUpload } from './DashBoard/Modal/ProfilePicUpload';
import AlertControl from './Controls/AlertControl';
import OverlaySpinner from './Controls/OverlaySpinner';
import ProfilePicUpdDlg from '../dialogs/ProfilePicUpdDlg';
import profimg from '../assets/images/profimg.jpg';
import Image from 'material-ui-image';
import moment from "moment";
import { useLocation } from "react-router-dom";
import { APICall, WriteToActivityLog } from '../global/Api';
import { ActivityLogEvent } from '../common/AOBEnum';

const PersonalInfoSection = (props) => {

    const location = useLocation();
    //const userId = useRef(null);
    const emailAdrs = useRef(null);
    const userNm = useRef(null);

    const sessions = useSelector((state) => state.sessionMgmnt);
    const dispatch = useDispatch();
    const [data, setData] = useState(null);
    const [roleData, setRoleData] = useState(null);
    const [titleData, setTitleData] = useState(null);
    const [countryData, setCountryData] = useState(null);
    const [stateData, setStateData] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    //const { isLoading, data, error } = useHttp({ url: process.env.REACT_APP_GET_USER_PROF + (props.userId) });
    const [disableRole] = useState(props.disablerole);
    const [disableEmail] = useState(props.userId > 0);
    const [submit, setSubmit] = useState(false);
    /* const [regSuccessStatus, setRegSuccessStatus] = useState(false);*/
    const [regMsg, setRegMsg] = useState('');
    const [errorMsg, setErrorMsg] = useState('');
    const [infoMsg, setInfoMsg] = useState('');
    const [msgModalShow, setMsgModalShow] = useState(data != null);
    const [alertSucStatus, setAlertSucStatus] = useState(false);

    const [showStateTextbox, setShowStateTextbox] = useState(true);
    const [showStateCombobox, setShowStateCombobox] = useState(false);

    const [showImageDlg, setShowImageDlg] = useState(false);
    const [imageUrl, setImageUrl] = useState(null);

    const [userId, setUserId] = useState(props.userId || -1);
    const [sessionId, setSessionId] = useState(props.sessionId || '');

    const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;
    const pwdRegExp = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/;
    let history = useNavigate();

    const today = dayjs();

    const userProfileForm = useFormik({
        enableReinitialize: true,

        initialValues: {
            FirstName: data ? data["FirstName"] : "",
            LastName: data ? data["LastName"] : "",
            Phone: data ? data["PhoneNbr"] : "",
            Mobile: data ? data["MobileNbr"] : "",
            Title: data ? data["Title"] : "",
            Gender: data ? data["Gender"] : "",
            IsActive: true,
            UserStatus: data ? data["UserStatus"] : "",
            DOB: data ? data["DOB"] && data["DOB"] != "" ? data["DOB"] : '' : '',
            RoleTypeId: data ? data["RoleId"] : ""
        },

        validationSchema: yup.object({
            FirstName: yup.string()
                .max(40, "First Name - must be a string with a maximum length of '40'.")
                .trim('First Name - cannot contain leading and trailing spaces')
                .required('First Name - required.'),
            LastName: yup.string()
                .max(40, "Last Name - must be a string with a maximum length of '40'.")
                .trim('Last Name - cannot contain leading and trailing spaces')
                .required('Last Name - required.'),
            Mobile: yup.string()
                .required('Mobile Number - required.'),
            RoleTypeId: yup.number()
                .required("Role - required.")
                .min(1, "Role - required."),
            Title: yup.number(),
            //.required("Title - required.")
            //.min(1, "Title - required."),
            Gender: yup.number(),
                //.required("Gender - required.")
                //.min(1, "Gender - required."),
            DOB: yup
                .date()
                .nullable()
                //.required("Date Of Birth - required")
        //        .max(today, "Date of birth that you entered is invalid")
        //        .min("1900-01-01", "Date is too early"),
        }),
        onSubmit: async values => {
            submitClick(values);
        },
    });

    const submitClick = async (values) => {

        setSubmit(true); //Disabled

        setInfoMsg('');
        setErrorMsg('');

        var birthDate = moment(values.DOB, 'YYYY-MM-DD');

        let inputJson = {
            FullName: values.FirstName + ' ' + values.LastName,
            EmailAddress: emailAdrs.current,
            PhoneNbr: values.Phone,
            MobileNbr: values.Mobile,
            Title: parseInt(values.Title),
            GenderId: parseInt(values.Gender),
            DOB: birthDate.format('MM/DD/YYYY'),
            RoleTypeId: parseInt(values.RoleTypeId),
            FirstName: values.FirstName,
            LastName: values.LastName,
            ActiveInd: values.IsActive,
            UserStatus: values.UserStatus,
            UserId: parseInt(userId)
        };

        let headers = {
            'Content-Type': 'application/json'
        };

        //let apiUrl = process.env.REACT_APP_BASE_URL + process.env.REACT_APP_MODIFY_USER;

        const apiUrl = new URL(
            process.env.REACT_APP_MODIFY_USER,
            process.env.REACT_APP_BASE_URL,
        );

        try {
            setIsLoading(true);

            let response = await APICall({ url: apiUrl.href, method: 'POST', payload: inputJson, contentType: 'application/json', responseType: '' }, userId, sessionId);

            //let response = await CallAPI({ url: apiUrl.href, method: 'POST', headers: headers, body: inputJson }, userId, sessionId);

            if (response !== null && response !== undefined) {

                setIsLoading(false);

                if (response.meta.UserUpdated == "Yes") {
                    await WriteToActivityLog(ActivityLogEvent.ProfileScreen, "Saved personal information.", userId, sessionId);
                    setInfoMsg("Your data has been successfully saved.");
                }
                else
                    setErrorMsg(ErrorMessages.UnknownError);
            }
        }
        catch (err) {
            setErrorMsg(ErrorMessages.UnknownError);
            setIsLoading(false);
        }
        finally {
            
        }
    }

    //const loadProfilePic = async () => {
    //    const formData = new FormData();
    //    formData.append("UserId", props.userId);

    //    let endPoint = process.env.REACT_APP_GET_PROFILE_IMAGE;

    //    try {

    //        let response = await DownloadUserFiles(endPoint, formData, sessions, dispatch);
    //        if (response && response.data) {
    //            if (response.headers["content-type"] == 'image/jpeg') {
    //                setImageUrl(response.data);
    //            }
    //            else {
    //                setImageUrl(null);
    //            }
    //        }

    //    } catch (ex) {
    //        console.log(ex);
    //    }
    //}

    const onClickHandlePic = (event) => {
        event.preventDefault();
        setShowImageDlg(true);
    }
    const closeImageDlg = () => {
        //event.preventDefault();
        setShowImageDlg(false);
    }

    const onPicChangeHandle = async () => {
        setShowImageDlg(false);

        let imageURL = await LoadProfilePic(userId, sessions, dispatch);
        setImageUrl(imageURL);
    }

    const existHandeler = () => {
        history.push({ pathname: '/DashBoard' });
    }

    const loadProfile = async () => {

        let apiUrl = new URL(
            process.env.REACT_APP_GET_USER_DETAIL,
            process.env.REACT_APP_BASE_URL,
        );
        apiUrl.searchParams.set('CntCtId', `${parseInt(userId)}`);

        try {

            setIsLoading(true);

            let response = await APICall({ url: apiUrl.href, method: 'GET', contentType: 'application/json', responseType: '' }, userId, sessionId);

            if (response !== undefined && response !== null) {
                setData(response["data"][0]);
            }

            apiUrl = new URL(
                process.env.REACT_APP_GET_PERSON_TITLES,
                process.env.REACT_APP_BASE_URL,
            );

            response = await APICall({ url: apiUrl.href, method: 'GET', contentType: 'application/json', responseType: '' }, userId, sessionId);

            if (response !== undefined && response !== null) {
                setTitleData(response["data"]);
                setIsLoading(false);
            }
        }
        catch (err) {
            setErrorMsg(ErrorMessages.UnknownError);
            setIsLoading(false);
        }
        finally {
        }
    };

    useEffect(() => {

        //userId.current = GetLoggedInUserId();
        emailAdrs.current = GetLoggedInUserEmail(userId, sessionId);
        userNm.current = GetLoggedInUserName(userId, sessionId);

        loadProfile();
    }, []);

        return (
            <React.Fragment>
                <OverlaySpinner disappear={isLoading} />
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <Grid item xs container direction="row" sx={{ border: 0, borderColor: '#C9CDCF', ml: 0, mt: 0 }}>
                        <Grid item xs={12} sx={{ ml: 4, mb: 0, textAlign: 'center', mt: 3}}>
                            <Typography align="center" gutterBottom variant="body1" color="text.secondary" sx={{ fontWeight: 500, borderBottom: '0px solid #898F94', mr: 15, pb: 1, }}>
                                Personal info
                            </Typography>
                            <Box sx={{ ml: 0, }}>
                                <Divider variant="fullWidth" component="div" sx={{ background: '#BBBFBF', }} />
                            </Box>
                        </Grid>
                        <Grid item xs={12} sx={{ mt: 1, mb: 0, textAlign: 'center', }}>
                            <Box sx={{ ml: 6, }}>
                                <AlertControl Message={errorMsg} severity="error" color="error" icon={true} />
                                <AlertControl Message={infoMsg} severity="success" color="success" icon={true} />
                            </Box>
                        </Grid>
                        <Grid item xs={12} sx={{ ml: 4, pb: 2, pt: 1}}>
                            <Typography variant="body1" color="text.blueText" sx={{ mb: 0, fontWeight: 500 }}>
                                Your details.
                            </Typography>
                        </Grid>
                        <Grid item sx={{ ml: 4, }}>
                            <form onSubmit={userProfileForm.handleSubmit} id="frmUserProfile">
                                <Box sx={{ border: 0, borderColor: '#d7d8d6', pt: 1, pb: 6, pr: 5, pr: 0 }}>
                                    <Grid item container direction="row" sx={{}} >
                                    <Grid item sm={8} sx={{}}>
                                        {/*<Typography variant="h5" color="text.secondary" sx={{ mb: 2, fontWeight: 400 }}>*/}
                                        {/*    Your details*/}
                                        {/*</Typography>*/}
                                        <Box elevation={0}>
                                            <label htmlFor="Title" className="form-label">Title</label>
                                            <select id="Title" className="form-select" name="Title" value={userProfileForm.values.Title} onChange={userProfileForm.handleChange} placeholder="Title">
                                            <option key={-1} value={-1}>Select</option>
                                            {
                                                titleData && titleData.map((e) => (
                                                    <option key={e.TitleId} value={e.TitleId}>{e.TitleName}</option>
                                                ))
                                            }
                                            </select>
                                            {
                                                userProfileForm.touched.Title && userProfileForm.errors.Title ? (<p className="text-danger">{userProfileForm.errors.Title}</p>) : null
                                            }

                                            </Box>
                                        </Grid>
                                    </Grid>
                                    <Grid item container direction="row" sx={{pt: 2}}>
                                        <Grid item sm={6} sx={{}} >
                                            <Box elevation={0}>
                                                <label htmlFor="FirstName" className="form-label">First Name*</label>
                                                <input type="text" id="FirstName" name="FirstName" placeholder="Enter your first name here" className="form-control" value={userProfileForm.values.FirstName} onChange={userProfileForm.handleChange} />
                                                {
                                                    userProfileForm.touched.FirstName && userProfileForm.errors.FirstName ? (<p className="text-danger">{userProfileForm.errors.FirstName}</p>) : null
                                                }
                                            </Box>
                                        </Grid>
                                        <Grid item sm={6} sx={{}} >
                                            <Box elevation={0} sx={{ml: 3}}>
                                                <label htmlFor="LastName" className="form-label">Last Name*</label>
                                                <input type="text" id="LastName" name="LastName" placeholder="Enter your last name here" className="form-control" value={userProfileForm.values.LastName} onChange={userProfileForm.handleChange} />
                                                {
                                                    userProfileForm.touched.LastName && userProfileForm.errors.LastName ? (<p className="text-danger">{userProfileForm.errors.LastName}</p>) : null
                                                }
                                            </Box>
                                        </Grid>
                                    </Grid>
                                    <Grid item container direction="row" sx={{ pt: 2}}>
                                        <Grid item sm={6} sx={{}} >
                                            <Box elevation={0}>
                                                <label htmlFor="Gender" className="form-label">Gender</label>
                                                    <select className="form-select" id="Gender" name="Gender" value={userProfileForm.values.Gender} onChange={userProfileForm.handleChange} >
                                                    <option key={-1} value={-1}>Select</option>
                                                    <option value="70">Male</option>
                                                    <option value="71">Female</option>
                                                    <option value="74">Other</option>
                                                </select>
                                                {
                                                    userProfileForm.touched.Gender && userProfileForm.errors.Gender ? (<p className="text-danger">{userProfileForm.errors.Gender}</p>) : null
                                                }
                                            </Box>
                                        </Grid>
                                        <Grid item sm={6} sx={{}} >
                                            <Paper elevation={0} sx={{ ml: 3 }}>
                                                <label htmlFor="DOB" className="form-label">Date Of Birth</label>
                                                        <input type="date" min="1900-01-01" max={today.format('YYYY-MM-DD')} className="form-control" id="DOB" name="DOB" width="0" value={userProfileForm.values.DOB} onChange={userProfileForm.handleChange} />
                                                {
                                                    userProfileForm.touched.DOB && userProfileForm.errors.DOB ? (<p className="text-danger">{userProfileForm.errors.DOB}</p>) : null
                                                }
                                            </Paper>
                                        </Grid>
                                    </Grid>
                                    <Grid item container direction="row" sx={{ pt: 2}}>
                                        <Grid item sm={6} sx={{}} >
                                            <Box elevation={0}>
                                                <label htmlFor="Mobile" className="form-label">Mobile Number*</label>
                                                <input type="text" name="Mobile" id="Mobile" placeholder="Enter your mobile number here" className="form-control" value={userProfileForm.values.Mobile} onChange={userProfileForm.handleChange} />
                                                {
                                                    userProfileForm.touched.Mobile && userProfileForm.errors.Mobile ? (<p className="text-danger">{userProfileForm.errors.Mobile}</p>) : null
                                                }
                                            </Box>
                                        </Grid>
                                        <Grid item sm={6} sx={{}} >
                                            <Box elevation={0} sx={{ ml: 3 }}>
                                                <label htmlFor="Phone" className="form-label">Phone Number</label>
                                                <input type="tel" id="Phone" name="Phone" placeholder="Enter your phone number here" className="form-control" value={userProfileForm.values.Phone} onChange={userProfileForm.handleChange} />
                                                {
                                                    userProfileForm.touched.Phone && userProfileForm.errors.Phone ? (<p className="text-danger">{userProfileForm.errors.Phone}</p>) : null
                                                }
                                            </Box>
                                        </Grid>
                                    <Grid item sm={12} sx={{}} >
                                        <Box sx={{pt: 4}}>
                                                <FormControl variant="outlined" sx={{}}>
                                                    <Button type="submit" variant="contained" size="large" style={{ borderRadius: '20px', padding: '0.3rem 2.6rem', textTransform: 'none'}}>
                                                        Save
                                                    </Button>
                                            </FormControl>
                                        </Box>
                                    </Grid>                              
                                </Grid>
                            </Box>
                        </form>
                        </Grid>
                    </Grid>
                    {showImageDlg ?
                        <ProfilePicUpdDlg open={showImageDlg} onClose={closeImageDlg} onChange={onPicChangeHandle} userId={userId} />
                        : null
                    }
                </LocalizationProvider>
            </React.Fragment>
        );
}
export default PersonalInfoSection;