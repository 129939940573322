import React, { useEffect, useState } from 'react';
import { useNavigate, useLocation } from "react-router-dom";
import { Box, FormControl, TextField, Typography, Button, Paper, Stack, Divider } from '@mui/material';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import OverlaySpinner from '../Controls/OverlaySpinner';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import AlertControl from '../Controls/AlertControl';
import AlertWithTitle from '../Controls/AlertWithTitle';
//import { SysParmGrp, SysParmIdentifier } from '../../../customFunctions/SysParmConfig';
import { CallAPI, IsError } from '../../global/APIUtils';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { PreviewHtmlModal } from '../ParmConfig/PreviewHtmlModal';
import { ErrorMessages } from '../../common/ErrorMessages';
import { goToTopSystemParameter } from '../../global/Helpers';
import { APICall, WriteToActivityLog } from '../../global/Api';
import { ActivityLogEvent } from '../../common/AOBEnum';

const EmailTemplateData = (props) => {
    const location = useLocation();

    const [resetPwdMailBody, setResetPwdMailBody] = useState('');
    const [resetPwdMailSubject, setResetPwdMailSubject] = useState('');
    const [resetPwdMailDesc, setResetPwdMailDesc] = useState('');

    const [invitaionMailBody, setInvitaionMailBody] = useState('');
    const [invitaionMailSubject, setInvitaionMailSubject] = useState('');
    const [invitaionMailDesc, setInvitaionMailDesc] = useState('');

    const [recoveryEmailBody, setRecoveryEmailBody] = useState('');
    const [recoveryEmailSubject, setRecoveryEmailSubject] = useState('');
    const [recoveryEmailDesc, setRecoveryEmailDesc] = useState('');

    const [loginOTPEmailBody, setLoginOTPEmailBody] = useState('');
    const [loginOTPEmailSubject, setLoginOTPEmailSubject] = useState('');
    const [loginOTPEmailDesc, setLoginOTPEmailDesc] = useState('');

    const [changePassword, setChangePassword] = useState('');
    const [changePasswordDesc, setChangePasswordDesc] = useState('');
    const [userActivation, setUserActivation] = useState('');
    const [userActivationDesc, setUserActivationDesc] = useState('');
    const [notification, setNotification] = useState('');
    const [notificationDesc, setNotificationDesc] = useState('');

    const [modalShow, setModalShow] = useState(false);
    const [modalId, setModalId] = useState(0);
    const [htmlContent, setHtmlContent] = useState('');

    const [errorMsg, setErrorMsg] = useState(null);
    const [successMsg, setSuccessMsg] = useState('');
    const [isDataLoading, setIsDataLoading] = useState(false);
    const [submit, setSubmit] = useState(false);

    const dispatch = useDispatch();
    let sessions = useSelector((state) => state.sessionMgmnt);

    const [userId, setUserID] = useState(props.userId || -1);
    const [sessionId, setSessionId] = useState(props.sessionId || '');

    const GetTemplateTypeStr = (resType) => {

        switch (resType) {
            case 2:
                return "Reset password email template";
            case 5:
                return "Invitation email template";
            case 7:
                return "Login OTP email template";
            default:
                return "";
        }
    }

    const loadConfigGroup = async () => {
        try {
            let headers = {
                'Content-Type': 'application/json'
            };

            const apiUrl = new URL(
                process.env.REACT_APP_GET_EMAIL_TEMPLATES,
                process.env.REACT_APP_BASE_URL,
            );

            setIsDataLoading(true);

            let response = await APICall({ url: apiUrl.href, method: 'GET', contentType: 'application/json', responseType: '' }, userId, sessionId);

            if (response !== null && response !== undefined) {
                if (response["data"] && response["data"].length > 0) {

                    const resetPwd = response["data"].filter(m => { return parseInt(m['EmailTemplateId']) === 2; });

                    if (resetPwd != null) {
                        setResetPwdMailBody(resetPwd[0].EmailTemplateVal);
                        setResetPwdMailSubject(resetPwd[0].EmailSubject);
                        setResetPwdMailDesc(resetPwd[0].EmailTemplateDesc);
                    }

                    const invMail = response["data"].filter(m => { return parseInt(m['EmailTemplateId']) === 5; });

                    if (invMail != null) {
                        setInvitaionMailBody(invMail[0].EmailTemplateVal);
                        setInvitaionMailSubject(invMail[0].EmailSubject);
                        setInvitaionMailDesc(invMail[0].EmailTemplateDesc);
                    }

                    const loginOTP = response["data"].filter(m => { return parseInt(m['EmailTemplateId']) === 7; });

                    if (loginOTP != null) {
                        setLoginOTPEmailBody(loginOTP[0].EmailTemplateVal);
                        setLoginOTPEmailSubject(loginOTP[0].EmailSubject);
                        setLoginOTPEmailDesc(loginOTP[0].EmailTemplateDesc);
                    }

                    const recovUser = response["data"].filter(m => { return parseInt(m['EmailTemplateId']) === 6; });

                    if (recovUser != null) {
                        setRecoveryEmailBody(recovUser[0].EmailTemplateVal);
                        setRecoveryEmailSubject(recovUser[0].EmailSubject);
                        setRecoveryEmailDesc(recovUser[0].EmailTemplateDesc);
                    }
                }
                else {
                    setErrorMsg(ErrorMessages.UnknownError);
                }
            }
            else {
                console.error("Response is null or undefined while fetching Email Template information.");
                setErrorMsg(ErrorMessages.UnknownError);
            }
        }
        catch (err) {
            setErrorMsg(ErrorMessages.UnknownError);
        }
        finally {
            setIsDataLoading(false);
        }
        return null;
    }

    useEffect(() => {
        loadConfigGroup();
    }, []);


    const resetPwdTemplateForm = useFormik({
        enableReinitialize: true,
        initialValues: {
            ResetPwdTemplateDesc: resetPwdMailDesc,
            ResetPwdMailBody: resetPwdMailBody,
            ResetPwdMailSubject: resetPwdMailSubject,
        },
        validationSchema: yup.object({
            ResetPwdTemplateDesc: yup.string()
                .required('Description - required'),
            ResetPwdMailBody: yup.string()
                .required('Mail Body - required'),
            ResetPwdMailSubject: yup.string()
                .required('Mail Subject - required'),
        }),
        onSubmit: values => {
            saveEmailTemplate(2, values.ResetPwdTemplateDesc, values.ResetPwdMailSubject, values.ResetPwdMailBody, 'Reset password email template updated successfully.');
        }
    })

    const invitationTemplateForm = useFormik({
        enableReinitialize: true,
        initialValues: {
            InvMailTemplateDesc: invitaionMailDesc,
            InvMailBody: invitaionMailBody,
            InvMailSubject: invitaionMailSubject,
        },
        validationSchema: yup.object({
            InvMailTemplateDesc: yup.string()
                .required('Description - required'),
            InvMailBody: yup.string()
                .required('Mail Body - required'),
            InvMailSubject: yup.string()
                .required('Mail Subject - required'),
        }),
        onSubmit: values => {
            saveEmailTemplate(5, values.InvMailTemplateDesc, values.InvMailSubject, values.InvMailBody, 'Invitation email template updated successfully.');
        }
    })

    const loginOTPTemplateForm = useFormik({
        enableReinitialize: true,
        initialValues: {
            loginOTPMailTemplateDesc: loginOTPEmailDesc,
            loginOTPMailBody: loginOTPEmailBody,
            loginOTPMailSubject: loginOTPEmailSubject,
        },
        validationSchema: yup.object({
            loginOTPMailTemplateDesc: yup.string()
                .required('Description - required'),
            loginOTPMailBody: yup.string()
                .required('Mail Body - required'),
            loginOTPMailSubject: yup.string()
                .required('Mail Subject - required'),
        }),
        onSubmit: values => {
            saveEmailTemplate(7, values.loginOTPMailTemplateDesc, values.loginOTPMailSubject, values.loginOTPMailBody, 'Login OTP email template updated successfully.');
        }
    })

    const saveEmailTemplate = async (id, desc, subject, body, msg) => {

        setErrorMsg('');
        setSuccessMsg('');

        try {
            setIsDataLoading(true);

            let inputJson = {
                EmailTemplateId: id,
                EmailTemplateDesc: desc,
                EmailSubject: subject,
                EmailTemplateVal: body
            };

            const apiUrl = new URL(
                process.env.REACT_APP_SAVE_EMAIL_TEMPLATES,
                process.env.REACT_APP_BASE_URL,
            );

            let response = await APICall({ url: apiUrl.href, method: 'POST', payload: inputJson, contentType: 'application/json', responseType: '' }, userId, sessionId);

            if (response !== null && response !== undefined) {
                if (response.meta.Updated === "1") {
                    await WriteToActivityLog(ActivityLogEvent.SystemOptions, "Saved " + GetTemplateTypeStr(id) + ".", userId, sessionId);
                    setSuccessMsg(msg);
                    loadConfigGroup();
                }
                else {
                    setErrorMsg(response.Errors[0].Message);
                }
            }
        }
        catch (err) {
            setErrorMsg(ErrorMessages.UnknownError);
        }
        finally {
            setIsDataLoading(false);
        }
    }

    const previewResetPwdEmailTemplate = () => {
        setHtmlContent(resetPwdTemplateForm.values.ResetPwdMailBody);
        setModalId(1);
        setModalShow(true);
    }
    const previewInvEmailTemplate = () => {
        setHtmlContent(invitationTemplateForm.values.InvMailBody);
        setModalId(1);
        setModalShow(true);
    }
    const previewLoginOTPEmailTemplate = () => {
        setHtmlContent(loginOTPTemplateForm.values.loginOTPMailBody);
        setModalId(1);
        setModalShow(true);
    }
    const onRegistrationEmailChanged = (e) => {
        setSuccessMsg('');
        setErrorMsg('');
    }
    return (
        <>
            <Box component="main" sx={{ flex: 1, height: '100%', pt: 0, pl: 6 }} >
                <Box sx={{ }}>
                    <Typography variant='body2' color="text.blueText" sx={{ fontWeight: 500, pb: 1, lineHeight: 1.5, borderBottom: '0px solid #9FA4A8' }}>Email Template</Typography>
                    <Divider variant="fullWidth" component="div" sx={{ background: '#BBBFBF', }} />
                    <Box sx={{ pt: 2 }}>
                        
                            <OverlaySpinner disappear={isDataLoading} />
                            <AlertControl Message={errorMsg} severity="error" color="error" icon={true} />
                            <AlertWithTitle Message={successMsg} severity="success" color="success" icon={true} Title="Email Template" />
                            <Accordion elevation={0} sx={{ '&:before': { height: '0px' } }}  >
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls="panel1a-content"
                                    id="panel1a-header"
                                sx={{  }}
                                >
                                    <Typography variant="body1" color='text.primary' sx={{ fontWeight: 500, }}>Reset Password</Typography>
                                </AccordionSummary>
                            <AccordionDetails sx={{ }}>
                                <form onSubmit={resetPwdTemplateForm.handleSubmit} id="resetPwdForm">
                                    <Box>
                                        <label htmlFor="ResetPwdMail" className="form-label">Description</label>
                                        <textarea style={{ minHeight: '50px' }} name="ResetPwdTemplateDesc" id="ResetPwdTemplateDesc" className="form-control" value={resetPwdTemplateForm.values.ResetPwdTemplateDesc} onChange={resetPwdTemplateForm.handleChange} />
                                        {
                                            resetPwdTemplateForm.touched.ResetPwdTemplateDesc && resetPwdTemplateForm.errors.ResetPwdTemplateDesc ? (<p className="text-danger">{resetPwdTemplateForm.errors.ResetPwdTemplateDesc}</p>) : null
                                        }
                                    </Box>
                                    <Box>
                                        <label htmlFor="ResetPwdMailSubject" className="form-label">Mail Subject</label>
                                        <textarea style={{ minHeight: '70px' }} name="ResetPwdMailSubject" id="ResetPwdMailSubject" className="form-control" value={resetPwdTemplateForm.values.ResetPwdMailSubject} onChange={resetPwdTemplateForm.handleChange} />
                                        {
                                            resetPwdTemplateForm.touched.ResetPwdMailSubject && resetPwdTemplateForm.errors.ResetPwdMailSubject ? (<p className="text-danger">{resetPwdTemplateForm.errors.ResetPwdMailSubject}</p>) : null
                                        }
                                    </Box>
                                    <Box>
                                        <label htmlFor="ResetPwdMailBody" className="form-label">Mail Body</label>
                                        <textarea style={{ minHeight: '200px' }} name="ResetPwdMailBody" id="ResetPwdMailBody" className="form-control" value={resetPwdTemplateForm.values.ResetPwdMailBody} onChange={resetPwdTemplateForm.handleChange} />
                                        {
                                            resetPwdTemplateForm.touched.ResetPwdMailBody && resetPwdTemplateForm.errors.ResetPwdMailBody ? (<p className="text-danger">{resetPwdTemplateForm.errors.ResetPwdMailBody}</p>) : null
                                        }
                                    </Box>
                                    <Box sx={{ pt: 4, pl: 0 }}>
                                        <Stack direction="row" spacing={2}>
                                            <Button onClick={previewResetPwdEmailTemplate} variant="contained" sx={{ boxShadow: 'none', border: 0, borderRadius: '20px', padding: '0.3rem 2rem', outline: 'none', textTransform: 'none' }}>
                                                Preview
                                            </Button>
                                            <Button type="submit" variant="contained" sx={{ boxShadow: 'none', border: 0, borderRadius: '20px', padding: '0.3rem 2.8rem', outline: 'none', textTransform: 'none' }}>
                                                Save
                                            </Button>
                                        </Stack>
                                    </Box>
                                </form>
                            </AccordionDetails>
                            </Accordion>
                            <Accordion elevation={0} sx={{
                                mt: 2,
                                '&:before': {
                                    display: 'none',
                                }
                            }} >
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls="panel1a-content"
                                    id="panel1a-header"
                                sx={{  }}
                                >
                                <Typography variant="body1" color='text.primary' sx={{ fontWeight: 500, }}>Invitaion Email</Typography>
                                </AccordionSummary>
                                <AccordionDetails sx={{ }}>
                                <form onSubmit={invitationTemplateForm.handleSubmit} id="invMailForm">
                                        <Paper elevation={0} sx={{}}>
                                            <label htmlFor="InvMailTemplateDesc" className="form-label">Description</label>
                                            <textarea style={{ minHeight: '50px' }} name="InvMailTemplateDesc" id="InvMailTemplateDesc" className="form-control" value={invitationTemplateForm.values.InvMailTemplateDesc} onChange={invitationTemplateForm.handleChange} />
                                            {
                                                invitationTemplateForm.touched.InvMailTemplateDesc && invitationTemplateForm.errors.InvMailTemplateDesc ? (<p className="text-danger">{invitationTemplateForm.errors.InvMailTemplateDesc}</p>) : null
                                            }
                                    </Paper>
                                    
                                    <Paper elevation={0} sx={{ pt: 2 }}>
                                            <label htmlFor="InvMailSubject" className="form-label">Mail Subject</label>
                                            <textarea style={{ minHeight: '70px' }} name="InvMailSubject" id="InvMailSubject" className="form-control" value={invitationTemplateForm.values.InvMailSubject} onChange={invitationTemplateForm.handleChange} />
                                            {
                                                invitationTemplateForm.touched.InvMailSubject && invitationTemplateForm.errors.InvMailSubject ? (<p className="text-danger">{invitationTemplateForm.errors.InvMailSubject}</p>) : null
                                            }
                                        </Paper>
                                    
                                        <Paper elevation={0} sx={{ pt: 2 }}>
                                            <label htmlFor="InvMailBody" className="form-label">Mail Body</label>
                                            <textarea style={{ minHeight: '200px' }} name="InvMailBody" id="InvMailBody" className="form-control" value={invitationTemplateForm.values.InvMailBody} onChange={invitationTemplateForm.handleChange} />
                                            {
                                                invitationTemplateForm.touched.InvMailBody && invitationTemplateForm.errors.InvMailBody ? (<p className="text-danger">{invitationTemplateForm.errors.InvMailBody}</p>) : null
                                            }
                                        </Paper>
                                        <Box sx={{ pt: 4, pl: 0 }}>
                                            <Stack direction="row" spacing={2}>
                                            <Button onClick={previewInvEmailTemplate} variant="contained" sx={{ boxShadow: 'none', border: 0, borderRadius: '20px', padding: '0.3rem 2rem', outline: 'none', textTransform: 'none'}}>
                                                    Preview
                                                </Button>
                                            <Button type="submit" variant="contained" sx={{ boxShadow: 'none', border: 0, borderRadius: '20px', padding: '0.3rem 2.8rem', outline: 'none', textTransform: 'none'}}>
                                                    Save
                                                </Button>
                                            </Stack>
                                        </Box>
                                    </form>
                                </AccordionDetails>
                            </Accordion>
                            <Accordion elevation={0} sx={{
                                mt: 2,
                                '&:before': {
                                    display: 'none',
                                }
                            }}>
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls="panel1a-content"
                                    id="panel1a-header"
                                    sx={{  }}
                                >
                                <Typography variant="body1" color='text.primary' sx={{ fontWeight: 500, }}>Login OTP</Typography>
                                </AccordionSummary>
                            <AccordionDetails sx={{ }}>
                                <form onSubmit={loginOTPTemplateForm.handleSubmit} id="loginOTPForm">
                                    <Paper elevation={0} sx={{}}>
                                        <label htmlFor="loginOTPMailTemplateDesc" className="form-label">Description</label>
                                        <textarea style={{ minHeight: '50px' }} name="loginOTPMailTemplateDesc" id="loginOTPMailTemplateDesc" className="form-control" value={loginOTPTemplateForm.values.loginOTPMailTemplateDesc} onChange={loginOTPTemplateForm.handleChange} />
                                        {
                                            loginOTPTemplateForm.touched.loginOTPMailTemplateDesc && loginOTPTemplateForm.errors.loginOTPMailTemplateDesc ? (<p className="text-danger">{loginOTPTemplateForm.errors.loginOTPMailTemplateDesc}</p>) : null
                                        }
                                    </Paper>
                                    <Paper elevation={0} sx={{ pt: 2 }}>
                                        <label htmlFor="loginOTPMailSubject" className="form-label">Mail Subject</label>
                                        <textarea style={{ minHeight: '70px' }} name="loginOTPMailSubject" id="loginOTPMailSubject" className="form-control" value={loginOTPTemplateForm.values.loginOTPMailSubject} onChange={loginOTPTemplateForm.handleChange} />
                                        {
                                            loginOTPTemplateForm.touched.loginOTPMailSubject && loginOTPTemplateForm.errors.loginOTPMailSubject ? (<p className="text-danger">{loginOTPTemplateForm.errors.loginOTPMailSubject}</p>) : null
                                        }
                                    </Paper>
                                    <Paper elevation={0} sx={{ pt: 2 }}>
                                        <label htmlFor="loginOTPMailBody" className="form-label">Mail Body</label>
                                        <textarea style={{ minHeight: '200px' }} name="loginOTPMailBody" id="loginOTPMailBody" className="form-control" value={loginOTPTemplateForm.values.loginOTPMailBody} onChange={loginOTPTemplateForm.handleChange} />
                                        {
                                            loginOTPTemplateForm.touched.loginOTPMailBody && loginOTPTemplateForm.errors.loginOTPMailBody ? (<p className="text-danger">{loginOTPTemplateForm.errors.loginOTPMailBody}</p>) : null
                                        }
                                    </Paper>
                                    <Box sx={{ pt: 4, pl: 0 }}>
                                        <Stack direction="row" spacing={2}>
                                            <Button onClick={previewLoginOTPEmailTemplate} variant="contained" sx={{ boxShadow: 'none', border: 0, borderRadius: '20px', padding: '0.3rem 2rem', outline: 'none', textTransform: 'none'}}>
                                                Preview
                                            </Button>
                                            <Button type="submit" variant="contained" sx={{ boxShadow: 'none', border: 0, borderRadius: '20px', padding: '0.3rem 2.8rem', outline: 'none', textTransform: 'none'}}>
                                                Save
                                            </Button>
                                        </Stack>
                                    </Box>
                                </form>
                                </AccordionDetails>
                            </Accordion>
                    </Box>
                </Box>
            </Box >
            {
                modalShow &&
                <PreviewHtmlModal
                    show={modalShow}
                    modalId={modalId}
                    backdrop="static"
                    keyboard={false}
                    onHide={() => setModalShow(false)}
                    htmlcontent={htmlContent}
                />
            }
        </>
    )
}
export default EmailTemplateData;