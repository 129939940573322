import React, { useCallback, useState, useEffect, useMemo, useRef, } from 'react';
import { renderToString } from 'react-dom/server';
import { useNavigate, useLocation } from "react-router-dom";
import PostLoginMenu from './PostLoginMenu'
import OverlaySpinner from './Controls/OverlaySpinner';
import Footer from './Footer';
import { useSelector, useDispatch } from 'react-redux';
import { CallAPI, IsError } from '../global/APIUtils';
import { FormControlLabel, Stack, Paper, FormControl, Grid, InputLabel, Select, TextField, IconButton, Box, Tooltip, Menu, Typography, MenuItem, InputBase, Card, CardContent, bull, CardActions, Button } from '@mui/material';
import { CardMedia, CardHeader } from '@mui/material';
import Checkbox, { checkboxClasses } from "@mui/material/Checkbox";
import { ErrorMessages } from '../common/ErrorMessages';
import { goToTop, LoadProfilePic, CanApprove, CanSubmitForEnrollment } from '../global/Helpers';
import { AlertDialog } from '../global/AlertDialog';
import { FormDialog } from '../global/FormDialog';
import { DrawerAppBar } from './AppNavbar'
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import { deepOrange } from '@mui/material/colors';
import AlertControl from './Controls/AlertControl';
import AlertWithTitle from './Controls/AlertWithTitle';
import Divider from '@mui/material/Divider';
import Container from '@mui/material/Container';
import EnrollmentHeader from './EnrollmentHeader'
import profimg from '../assets/images/profimg.jpg';
import { GetApplicantImage } from '../global/EnrollmentHelper';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import pwdimg from '../assets/images/45325.png';
import Image from 'material-ui-image'
import { useFormik } from 'formik';
import * as yup from 'yup';
import dayjs from 'dayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import { makeStyles } from "@material-ui/core/styles";
import moment from "moment";
import { APICall, WriteToActivityLog } from '../global/Api';
import ArrowCircleLeftOutlinedIcon from '@mui/icons-material/ArrowCircleLeftOutlined';
import AutoLagoutTimer from '../global/IdleMonitor';
import { ActivityLogEvent } from '../common/AOBEnum';

const User = () => {

    const dispatch = useDispatch();
    let sessions = useSelector((state) => state.sessionMgmnt);
    const location = useLocation();

    const [expanded, setExpanded] = useState(true);
    const [errorMsg, setErrorMsg] = useState(null);
    const [successMsg, setSuccessMsg] = useState(null);
    const [successMsgTitle, setSuccessMsgTitle] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [data, setData] = useState(null);
    const [titleData, setTitleData] = useState(null);
    const [roleData, setRoleData] = useState(null);
    const [imageUrl, setImageUrl] = useState(null);
    const [disActiveUser, setDisActiveUser] = useState(false);
    const [disUserLocked, setDisUserLocked] = useState(false);
    const [disNotActivatedAfterReg, setDisNotActivatedAfterReg] = useState(false);
    const [userId, setUserId] = useState(parseInt(location.state.userid) || -1);
    const [userEmail, setUserEmail] = useState(location.state.userEmail || '');
    const [userStatusId, setUserStatusId] = useState(parseInt(location.state.userStatusId) || 36);
    const [tabValue, setTabValue] = React.useState(0);
    const [disableControl, setdisableControl] = React.useState(false);
    const [disableTab, setDisableTab] = React.useState(true);
    const [showStrength, setShowStrength] = useState("none");
    const [strength, setStrength] = useState("");
    const [strengthColor, setStrengthColor] = useState("");
    const [showResetPwdStrength, setShowResetPwdStrength] = useState("none");
    const [resetPwdStrength, setResetPwdStrength] = useState("");
    const [resetPwdStrengthColor, setResetPwdStrengthColor] = useState("");
    const [pwdLenMin, setPwdLenMin] = useState(10);
    const [loggedInUserId, setLoggedInUserId] = useState(location.state.loggedInUserId || -1);
    const [sessionId, setSessionId] = useState(location.state.sessionId || '');

    let navigate = useNavigate();

    const handleTabChange = (event, newValue) => {
        setTabValue(newValue);
    };

    const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;
    const today = dayjs();
    const cntCtId = useRef(null);
    const pwdRegExp = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/;

    const tabStyles = makeStyles({
        indicator: {
            border: "1px solid #16191f"
        },
        //tabs: {
        //    "& button[aria-selected='true']": {
        //        border: "3px solid red"
        //    }
        //}
    });

    const tabClasses = tabStyles();


    const userForm = useFormik({
        enableReinitialize: true,

        initialValues: {
            Password: data ? "Aa1*******" : "",
            RenteredPassword: data ? "Aa1*******" : "",
            ChkNewUserChngPwdNxtLogin: data ? data.PwdChangeAtLogon : false,
            FirstName: data ? data["FirstName"] : "",
            LastName: data ? data["LastName"] : "",
            email: data ? data["EmailAddress"] : "",
            Phone: data ? data["PhoneNbr"] : "",
            Mobile: data ? data["MobileNbr"] : "",
            Title: data ? data["Title"] : "",
            Gender: data ? data["Gender"] : "",
            userrole: data ? data["RoleTypeId"] : "",
            chkActiveUser: data ? data["ActiveInd"] : true,
            chkUserLocked: data ? data["UserStatus"] === 37 ? true : false : false,
            //IsActive: false,
            UserStatus: data ? data["UserStatus"] : 36,
            DOB: data ? data["DOB"] && data["DOB"] != "" ? data["DOB"] : '' : ''
        },

        validationSchema: yup.object({
            Password: yup.string()
                .max(20, "Should have less than or equal to 20 characters")
                .min(pwdLenMin, "Should have more than or equal to " + pwdLenMin + " characters")
                .matches(pwdRegExp,
                    "Should have one uppercase, one lowercase, one number and one special character"
                )
                .required('Password - required.'),
            RenteredPassword: yup.string()
                .max(20, "Should have less than or equal to 20 characters")
                .min(pwdLenMin, "Should have more than or equal to " + pwdLenMin + " characters")
                .matches(pwdRegExp,
                    "Should have one uppercase, one lowercase, one number and one special character"
                )
                .when("Password", {
                    is: psswrd => (psswrd && psswrd.length > 0 ? true : false),
                    then: yup.string().oneOf(
                        [yup.ref("Password")],
                        "Password and confirm password does not match")
                })
                .required('Confirm Password - required.'),

            FirstName: yup.string()
                .max(40, "First Name - must be a string with a maximum length of '40'.")
                .trim('First Name - cannot contain leading and trailing spaces')
                .required('First Name - required.'),
            LastName: yup.string()
                .max(40, "Last Name - must be a string with a maximum length of '40'.")
                .trim('Last Name - cannot contain leading and trailing spaces')
                .required('Last Name - required.'),
            email: yup.string()
                .max(80, "Email Address - must be a string with a maximum length of 80")
                .email('Email Address - Invalid email address')
                .required('Email Address - required'),
            Mobile: yup.string()
                .required('Mobile Number - required.'),
            userrole: yup.number()
                .required("Role - required.")
                .min(1, "Role - required."),
            Title: yup.number(),
            Gender: yup.number(),
            DOB: yup
                .date()
                .nullable()
            //.required("Date Of Birth - required")
            //        .max(today, "Date Of Birth that you entered is invalid")
            //        .min("1900-01-01", "Date is too early"),
        }),
        onSubmit: async values => {
            submitClick(values);
        },
    });

    const submitClick = async (values) => {

        setErrorMsg(null);
        setSuccessMsgTitle(null);
        setSuccessMsg(null);

        var birthDate = values.DOB !== '' ? moment(values.DOB, 'YYYY-MM-DD') : null;

        let inputJson = {
            UserId: parseInt(userId),
            Title: parseInt(values.Title),
            FirstName: values.FirstName,
            LastName: values.LastName,
            DOB: birthDate !== null ? birthDate.format('MM/DD/YYYY') : null,
            GenderId: parseInt(values.Gender),
            EmailAddress: values.email,
            PhoneNbr: values.Phone,
            MobileNbr: values.Mobile,
            RoleTypeId: parseInt(values.userrole),
            Password: values.Password,
            CPassword: values.RenteredPassword,
            PwdChangeAtLogon: values.ChkNewUserChngPwdNxtLogin
        };

        let endPoint;
        if (userId != -1)
            endPoint = process.env.REACT_APP_MODIFY_USER;
        else
            endPoint = process.env.REACT_APP_CREATE_USER_PROFILE;

        const apiUrl = new URL(
            endPoint,
            process.env.REACT_APP_BASE_URL,
        );

        try {
            setIsLoading(true);

            let response = await APICall({ url: apiUrl.href, method: 'POST', payload: inputJson, contentType: 'application/json', responseType: '' }, loggedInUserId, sessionId);

            if (response !== null && response !== undefined) {

                setIsLoading(false);

                let error = IsError(response);

                if (error != 0) {
                    setErrorMsg(response.Errors[0].Message);
                    goToTop();
                    return;
                }

                if (response.meta.UserUpdated == "Yes") {

                    if (parseInt(userId) != -1) {
                        await WriteToActivityLog(ActivityLogEvent.UserManagement, "User modified. User: '" + values.email + "'", loggedInUserId, sessionId);
                    }
                    else {
                        await WriteToActivityLog(ActivityLogEvent.UserManagement, "User created. User: '" + values.email + "'", loggedInUserId, sessionId);
                    }


                    setUserId(parseInt(response.meta.NewContactId));
                    setUserEmail(values.email);
                    setUserStatusId(36);

                    setShowStrength('none');
                    setStrength('');
                    //userPwdForm.resetForm();

                    setdisableControl(true);
                    setDisableTab(false);
                    loadUser(response.meta.NewContactId);
                    setSuccessMsgTitle("User profile saved");
                    setSuccessMsg('User profile information saved successfuly.');
                }
                else
                    setErrorMsg(ErrorMessages.UnknownError);
            }
        }
        catch (err) {
            setErrorMsg(ErrorMessages.UnknownError);
            setIsLoading(false);
        }
        finally {

        }

        goToTop();
    }

    const userPwdForm = useFormik({
        enableReinitialize: true,

        initialValues: {
            NewPassword: "",
            ConfirmPassword: "",
            ChkResetPwdChngPwdNxtLogin: data ? data.PwdChangeAtLogon : false,
        },
        validationSchema: yup.object({
            NewPassword: yup.string()
                .max(20, "Should have less than or equal to 20 characters")
                .min(pwdLenMin, "Should have more than or equal to " + pwdLenMin + " characters")
                .matches(pwdRegExp,
                    "Should have one uppercase, one lowercase, one number and one special character"
                )
                .required('Password - required.'),
            ConfirmPassword: yup.string()
                .max(20, "Should have less than or equal to 20 characters")
                .min(pwdLenMin, "Should have more than or equal to " + pwdLenMin + " characters")
                .matches(pwdRegExp,
                    "Should have one uppercase, one lowercase, one number and one special character"
                )
                .when("NewPassword", {
                    is: psswrd => (psswrd && psswrd.length > 0 ? true : false),
                    then: yup.string().oneOf(
                        [yup.ref("NewPassword")],
                        "New password and confirm password does not match")
                })
                .required('Confirm Password - required.')
        }),
        onSubmit: async values => {
            userPwdFormSubmitClick(values);
        },
    });

    const userPwdFormSubmitClick = async (values) => {

        setErrorMsg(null);
        setSuccessMsgTitle(null);
        setSuccessMsg(null);

        let inputJson = {
            EmailAddress: userEmail,
            NPassword: values.NewPassword,
            CPassword: values.ConfirmPassword,
            UserId: parseInt(userId),
            PwdChangeAtLogon: values.ChkResetPwdChngPwdNxtLogin
        };

        const apiUrl = new URL(
            process.env.REACT_APP_CREATE_PASSWORD,
            process.env.REACT_APP_BASE_URL,
        );

        try {
            setIsLoading(true);

            let response = await APICall({ url: apiUrl.href, method: 'POST', payload: inputJson, contentType: 'application/json', responseType: '' }, loggedInUserId, sessionId);

            if (response !== null && response !== undefined) {

                setIsLoading(false);

                let error = IsError(response);

                if (error != 0) {
                    setErrorMsg(response.Errors[0].Message);
                    return;
                }
                if (response.meta.Success == "Yes") {

                    setdisableControl(true);
                    setDisableTab(false);

                    setShowResetPwdStrength('none');
                    setResetPwdStrength('');
                    userPwdForm.resetForm();

                    await loadFormData(userId);

                    await WriteToActivityLog(ActivityLogEvent.UserManagement, "Password has been reset. User: '" + userEmail + "'", loggedInUserId, sessionId);

                    setSuccessMsgTitle("Password updated");
                    setSuccessMsg('Password has been reset successfully.');
                }
                else
                    setErrorMsg(ErrorMessages.UnknownError);
            }
        }
        catch (err) {
            setErrorMsg(ErrorMessages.UnknownError);
            setIsLoading(false);
        }
        finally {
            //setIsLoading(false);
        }
    };

    const userOtherParamForm = useFormik({
        enableReinitialize: true,

        initialValues: {
            chkActiveUser: data ? data["ActiveInd"] : true,
            chkUserLocked: data ? data["UserStatus"] === 37 ? true : false : false,
            chkActivationPending: data ? data["UserStatus"] === 72 ? true : false : false,
            chkChngPwdNxtLogin: data ? data["PwdChangeAtLogon"] : false
        },
        onSubmit: async values => {
            UserOtherParamFormSubmitClick(values);
        },
    });

    const UserOtherParamFormSubmitClick = async (values) => {

        setErrorMsg(null);
        setSuccessMsgTitle(null);
        setSuccessMsg(null);

        let userStatus = userStatusId;

        if (!values.chkUserLocked) {
            if (userStatusId === 37)
                userStatus = 36;
        }

        if (!values.chkActivationPending) {
            if (userStatusId === 72)
                userStatus = 36;
        }

        if (values.chkUserLocked) {
            userStatus = 37;
        }

        let inputJson = {
            ActiveInd: values.chkActiveUser,
            PwdChangeAtLogon: values.chkChngPwdNxtLogin,
            UserStatus: userStatus,
            UserId: parseInt(userId)
        };

        const apiUrl = new URL(
            process.env.REACT_APP_SAVE_USER_OTHER_INFO,
            process.env.REACT_APP_BASE_URL,
        );

        try {
            setIsLoading(true);

            let response = await APICall({ url: apiUrl.href, method: 'POST', payload: inputJson, contentType: 'application/json', responseType: '' }, loggedInUserId, sessionId);

            if (response !== null && response !== undefined) {

                setIsLoading(false);

                let error = IsError(response);

                if (error != 0) {
                    setErrorMsg(response.Errors[0].Message);
                    return;
                }
                if (response.meta.UserUpdated == "Yes") {
                    if (userStatus != 72) {
                        setDisUserLocked(false);
                        setDisNotActivatedAfterReg(true);
                    }

                    await WriteToActivityLog(ActivityLogEvent.UserManagement, "Updated user's others information. User: '" + userEmail + "'.", loggedInUserId, sessionId);

                    setSuccessMsgTitle("User updated");
                    setSuccessMsg('User information updated successfully.');
                    loadFormData(userId);
                }
                else
                    setErrorMsg(ErrorMessages.UnknownError);
            }
        }
        catch (err) {
            setErrorMsg(ErrorMessages.UnknownError);
            setIsLoading(false);
        }
        finally {
        }
    };

    const GetPasswordPolicy = async () => {
        try {

            let headers = {
                'Content-Type': 'application/json'
            };

            const apiUrl = new URL(
                process.env.REACT_APP_GET_PWD_POLICY,
                process.env.REACT_APP_BASE_URL,
            );

            setIsLoading(true);

            let response = await APICall({ url: apiUrl.href, method: 'GET', contentType: 'application/json', responseType: '' }, loggedInUserId, sessionId);

            if (response !== null && response !== undefined) {
                if (response["data"] && response["data"].length > 0) {
                    setPwdLenMin(parseInt(response.data[1].ParmValue));
                }
            }
            else {
                setErrorMsg(ErrorMessages.UnknownError);
            }
        }
        catch (err) {
            setErrorMsg(ErrorMessages.UnknownError);
        }
        finally {
            setIsLoading(false);
        }
    }

    const loadFormData = async () => {

        //let apiUrl = process.env.REACT_APP_BASE_URL + process.env.REACT_APP_GET_USER_DETAIL + (cntCtId.current);

        try {

            setIsLoading(true);

            let apiUrl;
            let response;

            apiUrl = new URL(
                process.env.REACT_APP_USER_TITLES,
                process.env.REACT_APP_BASE_URL,
            );

            response = await APICall({ url: apiUrl.href, method: 'GET', contentType: 'application/json', responseType: '' }, loggedInUserId, sessionId);

            if (response !== undefined && response !== null) {
                setTitleData(response["data"]);
            }


            apiUrl = new URL(
                process.env.REACT_APP_GET_ALL_USERROLES,
                process.env.REACT_APP_BASE_URL,
            );

            response = await APICall({ url: apiUrl.href, method: 'GET', contentType: 'application/json', responseType: '' }, loggedInUserId, sessionId);

            if (response !== undefined && response !== null) {
                setRoleData(response["data"]);
            }



            //let imageURL = await LoadProfilePic(cntCtId.current, sessions, dispatch);
            //setImageUrl(imageURL);
        }
        catch (err) {
            setErrorMsg(ErrorMessages.UnknownError);
        }
        finally {
            setIsLoading(false);
        }

        await loadUser(userId);
        await GetPasswordPolicy();
    };

    const loadUser = async (pUserId) => {
        try {

            setIsLoading(true);

            if (pUserId != -1) {

                setdisableControl(true);
                setDisableTab(false);

                let apiUrl = new URL(
                    process.env.REACT_APP_GET_USER_DETAIL,
                    process.env.REACT_APP_BASE_URL,
                );

                apiUrl.searchParams.set('CntCtId', `${parseInt(pUserId)}`);

                let response = await APICall({ url: apiUrl.href, method: 'GET', contentType: 'application/json', responseType: '' }, loggedInUserId, sessionId);

                if (response !== undefined && response !== null) {

                    setData(response["data"][0]);
                    if (parseInt(response["data"][0].UserStatus) === 72)
                        setDisUserLocked(true);

                    if (parseInt(response["data"][0].UserStatus) !== 72) {
                        setDisNotActivatedAfterReg(true);
                    }

                    await WriteToActivityLog(ActivityLogEvent.UserManagement, "Viewed user detail. User: '" + response["data"][0].EmailAddress + "'", loggedInUserId, sessionId);
                }
            }
            else {
                await WriteToActivityLog(ActivityLogEvent.UserManagement, "Opened new user page.", loggedInUserId, sessionId);
            }
        }
        catch (err) {
            setErrorMsg(ErrorMessages.UnknownError);
        }
        finally {
            setIsLoading(false);
        }
    }

    useEffect(() => {
        loadFormData();
    }, []);

    const handleAccordianChange = () => {
        if (expanded) {
            setExpanded(false);
        }
        else {
            setExpanded(true);
        }
    }

    const handleBackClick = () => {
        navigate('/UserManagement', { state: { userId: loggedInUserId, sessionId: sessionId }, replace: true });
    }

    function evaluatePasswordStrength(password) {
        let score = 0;

        if (!password) {
            setShowStrength('none');
            return '';
        }

        // Check password length
        if (password.length > 11) score += 1;
        // Contains lowercase
        if (/[a-z]/.test(password)) score += 1;
        // Contains uppercase
        if (/[A-Z]/.test(password)) score += 1;
        // Contains numbers
        if (/\d/.test(password)) score += 1;
        // Contains special characters
        if (/[^A-Za-z0-9]/.test(password)) score += 1;

        setShowStrength('inline-block');

        switch (score) {
            case 0:
            case 1:
            case 2:
                setStrengthColor('#f00');
                return "Weak";
            case 3:
            case 4:
                setStrengthColor('#ffa500');
                return "Medium";
            case 5:
                setStrengthColor('#228b22');
                return "Strong";
        }
    }

    function evaluateResetPasswordStrength(password) {
        let score = 0;

        if (!password) {
            setShowResetPwdStrength('none');
            return '';
        }

        // Check password length
        if (password.length > 11) score += 1;
        // Contains lowercase
        if (/[a-z]/.test(password)) score += 1;
        // Contains uppercase
        if (/[A-Z]/.test(password)) score += 1;
        // Contains numbers
        if (/\d/.test(password)) score += 1;
        // Contains special characters
        if (/[^A-Za-z0-9]/.test(password)) score += 1;

        setShowResetPwdStrength('inline-block');

        switch (score) {
            case 0:
            case 1:
            case 2:
                setResetPwdStrengthColor('#f00');
                return "Weak";
            case 3:
            case 4:
                setResetPwdStrengthColor('#ffa500');
                return "Medium";
            case 5:
                setResetPwdStrengthColor('#228b22');
                return "Strong";
        }
    }

    return (

        <React.Fragment>
            <AutoLagoutTimer userId={userId} sessionId={sessionId} />
            <Container maxWidth="lg" sx={{}} className="">
                <OverlaySpinner disappear={isLoading} />
                <Paper className="back-grid">
                    <PostLoginMenu userId={loggedInUserId} sessionId={sessionId} />
                    <Grid item md={12} id="aobhead">
                        <Box sx={{
                            pr: 0,
                            pl: 0,
                        }}>
                            <EnrollmentHeader />
                        </Box>
                    </Grid>
                    <Grid item sx={{ pr: 1 }}>
                        <DrawerAppBar userId={loggedInUserId} sessionId={sessionId} />
                    </Grid>
                    <Grid item container direction="row" spacing={2} sx={{ pt: 0, pb: 0 }}>
                        <Grid item md={12} sx={{}}>
                            <Box sx={{ display: 'flex', pb: 2, pt: 0, ml: 6.5, mr: 6.5, justifyContent: 'space-between', alignItems: 'flex-end', borderBottom: '0px solid #898F94' }}>
                                <Typography variant="h6" color="text.primary" sx={{ pl: 0, pb: 0, lineHeight: 1, fontWeight: 500, pb: 0, letterSpacing: '0px' }} >System User Details</Typography>
                            </Box>
                            <Box sx={{ }}>
                                <Divider variant="fullWidth" component="div" sx={{ }} />
                            </Box>
                        </Grid>
                    </Grid>
                    <Grid item sm={10} sx={{ pl: 6.5, pr: 6.5, pt: 2.5 }}>
                        <Stack direction="row" alignItems="center">
                            <Tooltip arrow title="Back" onClick={() => handleBackClick()}>
                                <span>
                                    <IconButton>
                                        <ArrowCircleLeftOutlinedIcon sx={{ fontSize: 32 }} color="#61676B" />
                                    </IconButton>
                                </span>
                            </Tooltip>
                            <Typography variant="body4" color="text.primary" sx={{ ml: 0, mb: 0, fontWeight: 400 }}>
                                {userId === -1 ? 'User details - New User' : 'User details - Edit User'}
                            </Typography>
                        </Stack>
                    </Grid>
                    <Grid item sm={12}>
                        <Grid item xs container direction="row" spacing={4} sx={{ pl: 6.5, pr: 6.5, pt: 1 }}>
                            <Grid item md={8}>
                                <Box sx={{ width: '100%', pb: 0 }}>
                                    <AlertControl Message={errorMsg} severity="error" color="error" icon={true} />
                                    <AlertWithTitle Message={successMsg} variant="body2" severity="success" color="success" icon={true} Title={successMsgTitle} />
                                </Box>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item sm={12} sx={{pb: 6}}>
                        <Grid item xs container direction="row" spacing={0} sx={{ pt: 0, pl: 6.5, pr: 6.5 }}>
                            <Grid item md={10}>
                                <Box sx={{ borderTop: '1px solid #D5D9D9', boxShadow: '0 1px 1px 0 #D5D9D9,1px 1px 1px 0 #D5D9D9,-1px 1px 1px 0 #D5D9D9' }}>
                                    <Box sx={{ borderBottom: 0, borderColor: 'rgba(0, 0, 0, 0.19)' }}>
                                        <Tabs value={tabValue} TabIndicatorProps={{
                                            sx: {
                                                backgroundColor: 'rgb(31, 107, 230)',
                                            },
                                        }} onChange={handleTabChange} aria-label="basic tabs example" sx={{ mt: 0, height: '34px', borderBottom: '0px solid #B3B9BC' }}>
                                            <Tab label="User Profile" sx={{ pl: 3, height: '34px', textTransform: 'none', fontSize: '15px', fontWeight: 400 }} />
                                            <Tab label="Password" sx={{ pl: 3, height: '34px', textTransform: 'none', fontSize: '15px', fontWeight: 400 }} disabled={disableTab} />
                                            <Tab label="Others" sx={{ pl: 3, height: '34px', textTransform: 'none', fontSize: '15px', fontWeight: 400 }} disabled={disableTab} />
                                        </Tabs>
                                    </Box>
                                    {tabValue === 0 && (
                                        <Grid item container sm={11} direction="row" sx={{ pt: 4, pb: 5, pl: 6.5, pr: 0 }}>
                                            <Box sx={{ width: '100%', pb: 2, pt: 0 }}>
                                                <Typography sx={{ fontWeight: 500 }} variant="body4" color="text.blueText">
                                                    Profile information
                                                </Typography>
                                            </Box>
                                            <form onSubmit={userForm.handleSubmit} id="frmUser">
                                                <Box>
                                                    <Grid item container direction="row" spacing={0} sx={{}}>
                                                        <Grid item sm={8} sx={{}}>
                                                            <Box elevation={0}>
                                                                <label htmlFor="Title" className="form-label">Title</label>
                                                                <select id="Title" className="form-select" name="Title" value={userForm.values.Title} onChange={userForm.handleChange} placeholder="Title">
                                                                    <option key={-1} value={-1}>Select</option>
                                                                    {
                                                                        titleData && titleData.map((e) => (
                                                                            <option key={e.TitleId} value={e.TitleId}>{e.TitleName}</option>
                                                                        ))
                                                                    }
                                                                </select>
                                                                {
                                                                    userForm.touched.Title && userForm.errors.Title ? (<p className="text-danger">{userForm.errors.Title}</p>) : null
                                                                }
                                                            </Box>
                                                        </Grid>
                                                        <Grid item container xs={12} direction="row" spacing={0} sx={{ pt: 2 }}>
                                                            <Grid item sm={6} sx={{}} >
                                                                <Box elevation={0}>
                                                                    <label htmlFor="FirstName" className="form-label">First Name*</label>
                                                                    <input type="text" id="FirstName" name="FirstName" placeholder="Enter first name here" className="form-control" value={userForm.values.FirstName} onChange={userForm.handleChange} />
                                                                    {
                                                                        userForm.touched.FirstName && userForm.errors.FirstName ? (<p className="text-danger">{userForm.errors.FirstName}</p>) : null
                                                                    }
                                                                </Box>
                                                            </Grid>
                                                            <Grid item sm={6} sx={{}} >
                                                                <Box elevation={0} sx={{ ml: 3 }}>
                                                                    <label htmlFor="LastName" className="form-label">Last Name*</label>
                                                                    <input type="text" id="LastName" name="LastName" placeholder="Enter last name here" className="form-control" value={userForm.values.LastName} onChange={userForm.handleChange} />
                                                                    {
                                                                        userForm.touched.LastName && userForm.errors.LastName ? (<p className="text-danger">{userForm.errors.LastName}</p>) : null
                                                                    }
                                                                </Box>
                                                            </Grid>
                                                        </Grid>
                                                        <Grid item container xs={12} direction="row" spacing={0} sx={{ pt: 2 }}>
                                                            <Grid item sm={6} sx={{}} >
                                                                <Box elevation={0}>
                                                                    <label htmlFor="Gender" className="form-label">Gender</label>
                                                                    <select className="form-select" id="Gender" name="Gender" value={userForm.values.Gender} placeholder={'Gender *'} onChange={userForm.handleChange} >
                                                                        <option key={-1} value={-1}>Select</option>
                                                                        <option value="70">Male</option>
                                                                        <option value="71">Female</option>
                                                                        <option value="74">Other</option>
                                                                    </select>
                                                                    {
                                                                        userForm.touched.Gender && userForm.errors.Gender ? (<p className="text-danger">{userForm.errors.Gender}</p>) : null
                                                                    }
                                                                </Box>
                                                            </Grid>
                                                            <Grid item sm={6} sx={{}} >

                                                                <Box elevation={0} sx={{ ml: 3 }}>
                                                                    <label htmlFor="DOB" className="form-label">Date Of Birth</label>
                                                                    <input type="date" className="form-control" id="DOB" name="DOB" value={userForm.values.DOB} onChange={userForm.handleChange} />
                                                                    {
                                                                        userForm.touched.DOB && userForm.errors.DOB ? (<p className="text-danger">{userForm.errors.DOB}</p>) : null
                                                                    }
                                                                </Box>
                                                            </Grid>
                                                        </Grid>
                                                        <Grid item sm={8} sx={{ pt: 2 }} >
                                                            <Box elevation={0}>
                                                                <label htmlFor="email" className="form-label">Email Address*</label>
                                                                <input type="email" id="email" disabled={disableControl} name="email" placeholder="Enter email here" className="form-control" value={userForm.values.email} onChange={userForm.handleChange} />
                                                                {
                                                                    userForm.touched.email && userForm.errors.email ? (<p className="text-danger">{userForm.errors.email}</p>) : null
                                                                }
                                                            </Box>
                                                        </Grid>
                                                        <Grid item container xs={12} direction="row" spacing={0} sx={{ pt: 2 }}>
                                                            <Grid item sm={6} sx={{}} >
                                                                <Box elevation={0}>
                                                                    <label htmlFor="Mobile" className="form-label">Mobile Number*</label>
                                                                    <input type="text" name="Mobile" id="Mobile" placeholder="Enter mobile number here" className="form-control" value={userForm.values.Mobile} onChange={userForm.handleChange} />
                                                                    {
                                                                        userForm.touched.Mobile && userForm.errors.Mobile ? (<p className="text-danger">{userForm.errors.Mobile}</p>) : null
                                                                    }
                                                                </Box>
                                                            </Grid>
                                                            <Grid item sm={6} sx={{}} >
                                                                <Box elevation={0} sx={{ ml: 3 }}>
                                                                    <label htmlFor="Phone" className="form-label">Phone Number</label>
                                                                    <input type="tel" id="Phone" name="Phone" placeholder="Enter phone number here" className="form-control" value={userForm.values.Phone} onChange={userForm.handleChange} />
                                                                    {
                                                                        userForm.touched.Phone && userForm.errors.Phone ? (<p className="text-danger">{userForm.errors.Phone}</p>) : null
                                                                    }
                                                                </Box>
                                                            </Grid>
                                                        </Grid>
                                                        <Grid item container xs={12} direction="row" spacing={0} sx={{ pt: 2 }}>
                                                            <Grid item sm={8} sx={{ pr: 1 }} >

                                                                <Box elevation={0}>
                                                                    <label htmlFor="userrole" className="form-label">Role*</label>
                                                                    <select id="userrole" className="form-select" name="userrole" value={userForm.values.userrole} onChange={userForm.handleChange} placeholder="Title">
                                                                        <option key={-1} value={-1}>Select</option>
                                                                        {
                                                                            roleData && roleData.map((e) => (
                                                                                <option key={e.RoleTypeId} value={e.RoleTypeId}>{e.RoleName}</option>
                                                                            ))
                                                                        }
                                                                    </select>
                                                                    {
                                                                        userForm.touched.userrole && userForm.errors.userrole ? (<p className="text-danger">{userForm.errors.userrole}</p>) : null
                                                                    }
                                                                </Box>
                                                            </Grid>
                                                        </Grid>
                                                        <Grid item container xs={12} direction="row" spacing={0} sx={{ pt: 2 }}>
                                                            <Grid item sm={8} sx={{ pr: 1 }} >
                                                                <Box elevation={0}>
                                                                    <label htmlFor="Password" className="form-label">Password*</label>
                                                                    <input type="password" autoComplete="off" disabled={disableControl} id="Password" name="Password" placeholder="Enter password" className="form-control" value={userForm.values.Password} onChange={(event) => { userForm.handleChange(event); setStrength(evaluatePasswordStrength(event.target.value)) }} />
                                                                    {
                                                                        userForm.touched.Password && userForm.errors.Password ? (<p className="text-danger">{userForm.errors.Password}</p>) : null
                                                                    }
                                                                    <Stack direction="row" spacing={1}>
                                                                        <Typography variant="body2" color="text.secondary" sx={{ display: showStrength, pt: 1, pb: 0, fontWeight: 500, }} >Password strength: </Typography>
                                                                        <Typography variant="body2" color={strengthColor} sx={{ pt: 1, pb: 0, fontWeight: 700, }} >{strength}</Typography>
                                                                    </Stack>
                                                                </Box>
                                                            </Grid>
                                                        </Grid>
                                                        <Grid item container xs={12} direction="row" spacing={0} sx={{ pt: 2 }}>
                                                            <Grid item sm={8} sx={{ pr: 1 }} >
                                                                <Box elevation={0}>
                                                                    <label htmlFor="RenteredPassword" className="form-label">Confirm Password*</label>
                                                                    <input type="password" autoComplete="off" disabled={disableControl} id="RenteredPassword" name="RenteredPassword" placeholder="Re-enter password" className="form-control" value={userForm.values.RenteredPassword} onChange={userForm.handleChange} />
                                                                    {
                                                                        userForm.touched.RenteredPassword && userForm.errors.RenteredPassword ? (<p className="text-danger">{userForm.errors.RenteredPassword}</p>) : null
                                                                    }
                                                                </Box>
                                                            </Grid>
                                                        </Grid>
                                                        <Grid item container xs={12} direction="row" spacing={0} sx={{ pt: 2 }}>
                                                            <Grid item sm={8} sx={{ pr: 1 }} >
                                                                <FormControl variant="outlined" sx={{ width: '100%' }} disabled={disableControl} >
                                                                    <FormControlLabel color="text.primary" control={<Checkbox sx={{
                                                                        [`&, &.${checkboxClasses.checked}`]: {
                                                                            color: 'Check.main',
                                                                        },
                                                                    }} name="ChkNewUserChngPwdNxtLogin" id="ChkNewUserChngPwdNxtLogin" checked={userForm.values.ChkNewUserChngPwdNxtLogin} onChange={userForm.handleChange} />} label={<Typography sx={{ fontWeight: 400 }} variant="body4" color="text.primary">User must change password at next logon</Typography>} />
                                                                </FormControl>
                                                                {
                                                                    userForm.touched.ChkNewUserChngPwdNxtLogin && userForm.errors.ChkNewUserChngPwdNxtLogin ? (<p className="text-danger">{userForm.errors.ChkNewUserChngPwdNxtLogin}</p>) : null
                                                                }
                                                            </Grid>
                                                        </Grid>
                                                        <Grid item sm={12} sx={{ pt: 2 }} >
                                                            <Box sx={{ pt: 2 }}>
                                                                <Button type="submit" variant="contained" size="large" style={{ borderRadius: '20px', padding: '0.3rem 2.5rem', textTransform: 'none'}} >
                                                                    Save
                                                                </Button>

                                                                {/*        <Button type="submit" variant="outlined" size="small" sx={{ padding: '0.4rem 3rem', border: 2, borderColor: '#002d72' }}>*/}
                                                                {/*            <Typography variant="body2" color="#212121" sx={{ textTransform: 'none', fontWeight: 500 }} >Save</Typography>*/}
                                                                {/*        </Button>*/}
                                                            </Box>
                                                        </Grid>
                                                    </Grid>
                                                </Box>
                                            </form>
                                        </Grid>
                                    )}
                                    {tabValue === 1 && (
                                        <Grid item container sm={12} direction="column" sx={{ pt: 4, pb: 7, pl: 6.5, pr: 7 }}>
                                            <form onSubmit={userPwdForm.handleSubmit} id="userPwdForm">
                                                <Box sx={{ minHeight: '300px' }}>
                                                    <Box sx={{ width: '100%', pb: 1 }}>
                                                        <Typography sx={{ fontWeight: 500 }} variant="body4" color="text.primary">
                                                            Reset password
                                                        </Typography>
                                                    </Box>
                                                    <Grid item sm={12} container direction="row" spacing={2} sx={{}}>
                                                        <Grid item sm={8}>
                                                            <Paper elevation={0} sx={{ pt: 2 }}>
                                                                <label htmlFor="NewPassword" className="form-label">New Password*</label>
                                                                <input type="password" id="NewPassword" autoComplete="off" name="NewPassword" placeholder="Enter password" className="form-control" value={userPwdForm.values.NewPassword} onChange={(event) => { userPwdForm.handleChange(event); setResetPwdStrength(evaluateResetPasswordStrength(event.target.value)) }} />
                                                                {
                                                                    userPwdForm.touched.NewPassword && userPwdForm.errors.NewPassword ? (<p className="text-danger">{userPwdForm.errors.NewPassword}</p>) : null
                                                                }
                                                                <Stack direction="row" spacing={1}>
                                                                    <Typography variant="body2" color="text.secondary" sx={{ display: showResetPwdStrength, pt: 1, pb: 0, fontWeight: 500, }} >Password strength: </Typography>
                                                                    <Typography variant="body2" color={resetPwdStrengthColor} sx={{ pt: 1, pb: 0, fontWeight: 700, }} >{resetPwdStrength}</Typography>
                                                                </Stack>
                                                            </Paper>
                                                        </Grid>
                                                    </Grid>
                                                    <Grid item container direction="row" spacing={0} sx={{}}>
                                                        <Grid item sm={8} sx={{}} >
                                                            <Paper elevation={0} sx={{ pt: 2 }}>
                                                                <label htmlFor="ConfirmPassword" className="form-label">Confirm Password*</label>
                                                                <input type="password" id="ConfirmPassword" autoComplete="off" name="ConfirmPassword" placeholder="Enter password" className="form-control" value={userPwdForm.values.ConfirmPassword} onChange={userPwdForm.handleChange} />
                                                                {
                                                                    userPwdForm.touched.ConfirmPassword && userPwdForm.errors.ConfirmPassword ? (<p className="text-danger">{userPwdForm.errors.ConfirmPassword}</p>) : null
                                                                }
                                                            </Paper>
                                                        </Grid>
                                                    </Grid>
                                                    <Grid item container direction="row" spacing={0} sx={{ pt: 1 }}>
                                                        <Grid item sm={8} sx={{}} >
                                                            <FormControl variant="outlined" sx={{ width: '100%' }}>
                                                                <FormControlLabel color="text.primary" control={<Checkbox sx={{
                                                                    [`&, &.${checkboxClasses.checked}`]: {
                                                                        color: 'Check.main',
                                                                    },
                                                                }} name="ChkResetPwdChngPwdNxtLogin" id="ChkResetPwdChngPwdNxtLogin" checked={userPwdForm.values.ChkResetPwdChngPwdNxtLogin} onChange={userPwdForm.handleChange} />} label={<Typography sx={{ fontWeight: 400 }} variant="body4" color="text.primary">User must change password at next logon</Typography>} />
                                                            </FormControl>
                                                            {
                                                                userPwdForm.touched.ChkResetPwdChngPwdNxtLogin && userPwdForm.errors.ChkResetPwdChngPwdNxtLogin ? (<p className="text-danger">{userPwdForm.errors.ChkResetPwdChngPwdNxtLogin}</p>) : null
                                                            }
                                                        </Grid>
                                                    </Grid>

                                                    <Grid item sm={12} sx={{ pt: 4 }} >
                                                        <Box sx={{ pt: 0 }}>
                                                            <Button type="submit" variant="contained" size="large" style={{ textTransform: 'none', borderRadius: '20px', padding: '0.3rem 2.5rem', }}>
                                                                Save
                                                            </Button>

                                                            {/*    <Button type="submit" variant="outlined" size="small" sx={{ padding: '0.4rem 2.5rem', border: 2, borderColor: '#002d72' }}>*/}
                                                            {/*        <Typography variant="body2" color="#212121" sx={{ textTransform: 'none', fontWeight: 500 }} >Save</Typography>*/}
                                                            {/*    </Button>*/}
                                                        </Box>
                                                    </Grid>
                                                </Box>
                                            </form>
                                        </Grid>
                                    )}
                                    {tabValue === 2 && (
                                        <Grid item container direction="row" sx={{ pt: 5, pb: 5, pl: 6.5, pr: 7 }}>
                                            <form onSubmit={userOtherParamForm.handleSubmit} id="frmUser">
                                                <Box sx={{ minHeight: '300px' }}>
                                                    <Grid item container direction="row" spacing={2} sx={{}}>
                                                        <Grid item sm={12} sx={{}} >
                                                            <Stack direction="column">
                                                                {/*<FormControlLabel sx={{ pb: 1 }}*/}
                                                                {/*    label={<Typography sx={{ fontWeight: 400 }} variant="body2" color="text.primary">{item.FileName}</Typography>}*/}
                                                                {/*    control={<Checkbox defaultChecked onChange={(e) => handleChange(e, item.AttachementId)} />}*/}
                                                                {/*/>*/}
                                                                <FormControl variant="outlined" sx={{ width: '100%' }} disabled={disActiveUser}>
                                                                    <FormControlLabel color="text.primary" control={<Checkbox sx={{
                                                                        [`&, &.${checkboxClasses.checked}`]: {
                                                                            color: 'Check.main',
                                                                        },
                                                                    }} name="chkActiveUser" id="chkActiveUser" checked={userOtherParamForm.values.chkActiveUser} onChange={userOtherParamForm.handleChange} />} label={<Typography sx={{ fontWeight: 400 }} variant="body4" color="text.primary">Active user</Typography>} />
                                                                </FormControl>
                                                                {
                                                                    userOtherParamForm.touched.chkActiveUser && userOtherParamForm.errors.chkActiveUser ? (<p className="text-danger">{userOtherParamForm.errors.chkActiveUser}</p>) : null
                                                                }
                                                                <FormControl variant="outlined" sx={{ width: '100%' }} disabled={disUserLocked}>
                                                                    <FormControlLabel color="text.primary" control={<Checkbox sx={{
                                                                        [`&, &.${checkboxClasses.checked}`]: {
                                                                            color: 'Check.main',
                                                                        },
                                                                    }} name="chkUserLocked" id="chkUserLocked" checked={userOtherParamForm.values.chkUserLocked} onChange={userOtherParamForm.handleChange} />} label={<Typography sx={{ fontWeight: 400 }} variant="body4" color="text.primary">User locked</Typography>} />
                                                                </FormControl>
                                                                {
                                                                    userOtherParamForm.touched.chkUserLocked && userOtherParamForm.errors.chkUserLocked ? (<p className="text-danger">{userOtherParamForm.errors.chkUserLocked}</p>) : null
                                                                }
                                                                <FormControl variant="outlined" sx={{ width: '100%' }} disabled={disNotActivatedAfterReg}>
                                                                    <FormControlLabel color="text.primary" control={<Checkbox sx={{
                                                                        [`&, &.${checkboxClasses.checked}`]: {
                                                                            color: 'Check.main',
                                                                        },
                                                                    }} name="chkActivationPending" id="chkActivationPending" checked={userOtherParamForm.values.chkActivationPending} onChange={userOtherParamForm.handleChange} />} label={<Typography sx={{ fontWeight: 400 }} variant="body4" color="text.primary">Pending Email activation after registration</Typography>} />
                                                                </FormControl>
                                                                {
                                                                    userOtherParamForm.touched.chkActivationPending && userOtherParamForm.errors.chkActivationPending ? (<p className="text-danger">{userOtherParamForm.errors.chkActivationPending}</p>) : null
                                                                }

                                                                <FormControl variant="outlined" sx={{ width: '100%' }}>
                                                                    <FormControlLabel color="text.primary" control={<Checkbox sx={{
                                                                        [`&, &.${checkboxClasses.checked}`]: {
                                                                            color: 'Check.main',
                                                                        },
                                                                    }} name="chkChngPwdNxtLogin" id="chkChngPwdNxtLogin" checked={userOtherParamForm.values.chkChngPwdNxtLogin} onChange={userOtherParamForm.handleChange} />} label={<Typography sx={{ fontWeight: 400 }} variant="body4" color="text.primary">User must change password at next logon</Typography>} />
                                                                </FormControl>
                                                                {
                                                                    userOtherParamForm.touched.chkChngPwdNxtLogin && userOtherParamForm.errors.chkChngPwdNxtLogin ? (<p className="text-danger">{userOtherParamForm.errors.chkChngPwdNxtLogin}</p>) : null
                                                                }

                                                            </Stack>
                                                        </Grid>
                                                    </Grid>
                                                    <Grid item sm={12} sx={{}} >
                                                        <Box sx={{ pt: 4 }}>
                                                            <Button type="submit" variant="contained" size="large" style={{ textTransform: 'none', borderRadius: '20px', padding: '0.3rem 2.5rem', }}>
                                                                Save
                                                            </Button>

                                                            {/*    <Button type="submit" variant="outlined" size="small" sx={{ padding: '0.4rem 2.5rem', border: 2, borderColor: '#002d72' }}>*/}
                                                            {/*        <Typography variant="body2" color="#212121" sx={{ textTransform: 'none', fontWeight: 500 }} >Save</Typography>*/}
                                                            {/*    </Button>*/}
                                                        </Box>
                                                    </Grid>
                                                </Box>
                                            </form>
                                        </Grid>
                                    )}
                                </Box>
                            </Grid>
                            <Grid item xs={12} md={2} sx={{}}>
                                <Box sx={{ pl: 2.5 }}>
                                    <Stack direction="column">
                                        <Typography variant="body4" color="text.blueText" sx={{ mt: 2, fontWeight: 500 }}>
                                            {data ? 'Last login' : null}
                                        </Typography>
                                        <Typography variant="caption" color="text.primary" sx={{ mt: 0.5, }}>
                                            {
                                                data ? data.LastLogin : null
                                            }
                                        </Typography>
                                        <Typography variant="body4" color="text.blueText" sx={{ mt: 2, fontWeight: 500 }}>
                                            {data ? 'Last password changed' : null}
                                        </Typography>
                                        <Typography variant="caption" color="text.primary" sx={{ mt: 0.5, }}>
                                            {
                                                data ? data.LastPasswordChanged : null
                                            }
                                        </Typography>
                                    </Stack>
                                </Box>
                            </Grid>
                            <Grid item md={1}>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Footer userId={userId} />
                </Paper>
            </Container>
        </React.Fragment>
    );
};

function UserBreadcrumb() {
    return (
        <React.Fragment >

            <Box sx={{ borderBottom: '1px solid #cdcfd2', pt: 0, pb: 3 }}>
                <Typography variant="h4" color="text.primary" sx={{ lineHeight: 1, fontWeight: 300, letterSpacing: '-1px' }} >System User Details</Typography>
            </Box>

            {/*<Box sx={{ ml: 1, mr: 2, border: '0px solid #000', pt: 0 }}>*/}
            {/*    <Typography variant="h4" color="text.primary" sx={{ borderBottom: '1px solid #ccc', lineHeight: 2.5, fontWeight: 500, letterSpacing: '-1px' }} >System User Details</Typography>*/}
            {/*</Box>*/}

            {/*<Box sx={{ borderLeft: '4px solid #9e9e9e', mt: 0, pt: 0, pb: 2, pl: 1, backgroundColor: '#fff', borderTop: '0px solid #dee2e6' }}>*/}
            {/*    <Typography variant="h5" sx={{ fontWeight: 400, lineHeight: 1.1, pl: 2, textTransform: 'none' }} color="text.primary">System User Details</Typography>*/}
            {/*</Box>*/}
            {/*<Box sx={{ mt: 1, pt: 3, pb: 3, pl: 1, backgroundColor: '#f7f7fa' }}>*/}
            {/*    <Typography variant="h5" sx={{ fontWeight: 400, lineHeight: 1.1, pl: 1, textTransform: 'uppercase' }} color="#616161">SYSTEM USER DETAILS</Typography>*/}
            {/*</Box>*/}
        </React.Fragment>
    );
}
export default User;