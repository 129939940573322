export const EnrollmentApplicationSts = {
    SubmitApplication: 19,
    CancelApplication: 20,
    UnderReview: 21,
    RejectApplication: 22,
    RequestCancellation: 23,
    ReviewPass: 24,
    ReviewFail: 25,
    KYCAMLInitiated: 26,
    KYCAMLPassed: 27,
    KYCAMLFailed: 28,
    SubmittedEnrollment: 29,
    DocuSignRequired: 30,
    DocuSignCompleted: 31,
    AccountActivated: 32,
    InComplete: 35,
    Complete: 56
};

export const MessageFollowup =
{
    None: 53,
    Followup: 54,
    Complete: 55
};

export const MessageResponseType = {
    None: 50,
    Reply: 51,
    Forward: 52
};

export const DocuSignStatus = {
    Complete: 57,
    Cancel: 58,
    Decline: 59,
    Initiate: 60,
    Timeout: 61
};

export const SysParmGrp = {
    'Bank Information':1,
    'GWES API End Point':2,
    'Email Configuartion':3,
    'System Parameter': 4,
    'Docusign Configuartion': 999980,
    'Email Template': 999981
};

export const SysParmIdentifier = {
    'CodeValue':1,
	'SysParm':	2,
	'DocuSign':3,
	'EmailTemplate':4
};
export const AuthErrorCode = {
    'TimeOutExpired': 1001,
    'Invalid_User': 1002,
    'Invalid_Token_Expection':1003,
    'Invalid_LifeTime_Expection': 1004,
    'Invalid_JwtSignature': 1005,
    'UnhandledTokenExpection': 1006,
    'Auth_Token_Missing': 1007
};

//export const ContentType = {
//    'image/png': 'png',
//    'image/tiff':'tif',
//    'image/x-icon':'ico',
//    'image/x-jng':'jng',
//    'image/x-ms-bmp':'bmp',
//    'image/svg+xml':'svg',
//    'image/jpeg':'jpeg',
//    'image/jpeg': 'jpg',
//    'application/pdf':'pdf'    
//}

export const ContentType = {
    'png': 'image/png',
    'tif': 'image/tiff',
    'ico': 'image/x-icon',
    'jng': 'image/x-jng',
    'bmp': 'image/x-ms-bmp',
    'svg': 'image/svg+xml',
    'jpeg': 'image/jpeg',
    'jpg': 'image/jpeg',
    'pdf': 'application/pdf'
}

export const PrcsList = {
    "Add_Edit_DeleteApplication": 1,
    "ViewApplication": 2,
    "ExportApplication": 3,
    "ApproveApplication": 4,
    "PublishApplication": 5,
    "DocumentuploadforDocuSign": 6,
    "Add_Edit_DeleteTemplate": 7,
    "PublishTemplate": 8,
    "Add_Edit_DeleteUser": 9,
    "Add_Edit_DeleteRole": 10,
    "SystemParametersMaintenance": 11,
    "ViewActivityLog": 12,
    "ViewErrorLog": 13,
    "ViewTraceLog": 14
}