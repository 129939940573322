import { createTheme, PaletteMode } from "@mui/material";
import React from "react";
import theme, { getDesignTokens } from "./theme";
import { getDarkThemeDesignTokens } from "./DarkTheme";

export const useColorTheme = () => {
    const [mode, setMode] = React.useState("light");

    //const toggleColorMode = () =>
    //    setMode((prevMode) => (prevMode === "light" ? "dark" : "light"));

    const toggleColorMode = (mode) =>
        setMode(mode);

    // const modifiedTheme = React.useMemo(
    //   () =>
    //     createTheme({
    //       ...theme,
    //       palette: {
    //         ...theme.palette,
    //         mode,
    //       },
    //     }),
    //   [mode]
    // );

    const modifiedTheme = React.useMemo(
        () => createTheme(mode === "light" ? getDesignTokens(mode) : getDarkThemeDesignTokens(mode)),
        [mode]
    );

    return {
        theme: modifiedTheme,
        mode,
        toggleColorMode,
    };
};