import React from 'react';
import axios from 'axios';
import { decode as base64_decode, encode as base64_encode } from 'base-64';
import moment from 'moment';
import { APIResponseStatus } from '../common/AOBEnum';
import { GetOSPlatform, GetBrowserName, GetCountryOfUser } from './Helpers';
import { ErrorMessages } from '../common/ErrorMessages';

let refreshingFunc = undefined;

axios.interceptors.response.use(
    (res) => res,
    async (error) => {

        const originalConfig = error.config;
        const userId = originalConfig.headers.UserId;
        const sessionId = originalConfig.headers.SessionId;

        let token = localStorage.getItem(userId + '_AOBIDENTITYA');

        if (error.response.data != 'Authentication token expired') {
            if (error.response) {
                console.log(error.response.data);
                console.log(error.response.status);
                console.log(error.response.headers);
            } else if (error.request) {
                console.log(error.request);
            } else {
                console.log('Error', error.message);
            }

            console.log(error.config);
        }

        if (!token || !isUnauthorizedError(error)) {
            return Promise.reject(error);
        }

        try {

            if (error.response.headers.aobstatus && error.response.headers.aobstatus == APIResponseStatus.SessionMismatch) {
                window.location.replace('/AppLogin/?reason=SESSION_INVALID');
                return;
            }

            if (error.response.headers.aobstatus && error.response.headers.aobstatus == APIResponseStatus.SessonExisted) {
                window.location.replace('/AppLogin/?reason=SESSION_EXISTED');
                return;
            }

            if (error.response.headers.aobstatus && error.response.headers.aobstatus == APIResponseStatus.SessonExpired
                || error.response.headers.aobstatus == APIResponseStatus.TokenMissing || error.response.headers.aobstatus == APIResponseStatus.InvalidToken ) {
                window.location.replace('/AppLogout/?reason=SESSION_NOTFOUND&diu=' + `${userId}` + '&dis=' + `${sessionId}`);
                return;
            }

            if (!refreshingFunc)
                refreshingFunc = renewToken(userId, sessionId);

            const [ErrorCode, newAccessToken, newAccessTokenVer, newRefreshToken, newRefreshTokenVer] = await refreshingFunc;

            if (ErrorCode == APIResponseStatus.TokenMissing || ErrorCode == APIResponseStatus.InvalidToken || ErrorCode == APIResponseStatus.TokenExpired) {
                window.location.replace('/AppLogout/?reason=SESSION_NOTFOUND&diu=' + `${ userId }` + '&dis=' + `${ sessionId }`);
                return;
            }

            if (ErrorCode == APIResponseStatus.SessionMismatch) {
                window.location.replace('/AppLogin/?reason=SESSION_INVALID');
                return;
            }

            localStorage.setItem(userId + '_AOBIDENTITYA', newAccessToken);
            localStorage.setItem(userId + '_AOBIDENTITYAV', newAccessTokenVer);

            localStorage.setItem(userId + '_AOBIDENTITYR', newRefreshToken);
            localStorage.setItem(userId + '_AOBIDENTITYRV', newRefreshTokenVer);

            originalConfig.headers.Authorization = `Bearer ${newAccessToken}`;

            try {
                return await axios.request(originalConfig);
            } catch (innerError) {
                if (isUnauthorizedError(innerError)) {
                    throw innerError;
                }
            }

        } catch (err) {
            console.log(err);
            window.location = '/AppLogin';

        } finally {
            refreshingFunc = undefined;
        }
    },
)
function isUnauthorizedError(error) {
    const {
        response: { status, statusText },
    } = error;
    return status === 401;
}
export async function authenticate(userName, password, isForceLogin) {

    const loginPayload = {
        EmailAddress: base64_encode(userName),
        Password: base64_encode(password),
        ForceLogin: isForceLogin
    };

    const apiUrl = new URL(
        process.env.REACT_APP_LOGINUSER,
        process.env.REACT_APP_BASE_URL,
    );

    const response = await axios.post(apiUrl.href, loginPayload);

    if (response) {
        const data = response.data;

        //if (response.data.data[0].Access_Token !== null && response.data.data[0].Access_Token !== undefined) {
        //    AddToSession(data, userName);
        //}

        return data;
    }

    return response;
}
export async function APICall(requestConfig, userId, sessionId, atoken) {

    let headers = withAuth(userId, sessionId, atoken);

    let response = null;

    headers = { ...headers, "x-api-key": process.env.REACT_APP_ABCD };
    headers = { ...headers, "Content-Type": requestConfig.contentType };

    if (requestConfig.method === 'POST') {
        response = await axios.post(requestConfig.url, requestConfig.payload, {
            headers: headers
        });
    }
    else if (requestConfig.method === 'GET') {
        response = await axios.get(requestConfig.url, {
            responseType: requestConfig.responseType,
            headers: headers
        });
    }

    if (response) {
        const data = response.data;
        return data;
    }

    return response;
}
export async function ServerAPICall(requestConfig) {

    let headers = {};

    let response = null;

    headers = { ...headers, "x-api-key": process.env.REACT_APP_ABCD };
    headers = { ...headers, "Content-Type": requestConfig.contentType };

    if (requestConfig.method === 'POST') {
        response = await axios.post(requestConfig.url, requestConfig.payload, {
            headers: headers
        });
    }
    else if (requestConfig.method === 'GET') {
        response = await axios.get(requestConfig.url, {
            responseType: requestConfig.responseType,
            headers: headers
        });
    }

    if (response) {
        const data = response.data;
        return data;
    }

    return response;
}
function withAuth(userId, sessionId, atoken) {

    let userID = 0;
    let token = null;

    if (atoken !== null && atoken !== undefined)
        token = atoken;
    else 
        token = localStorage.getItem(userId + '_AOBIDENTITYA');

    if (!token) {
        window.location = '/AppLogin/?reason=SESSION_NOTFOUND';
        return;
    }

    let headers = {};

    headers.Authorization = `Bearer ${token}`;
    headers.UserId = userId;
    headers.SessionId = sessionId;

    return headers
}
export async function renewToken(userId, sessionId) {

    const refreshToken = localStorage.getItem(userId + '_AOBIDENTITYR');

    if (!refreshToken)
        throw new Error('refresh token does not exist');

    const apiUrl = new URL(
        process.env.REACT_APP_REFRESHUSER,
        process.env.REACT_APP_BASE_URL,
    );

    let headers = {"x-api-key": process.env.REACT_APP_ABCD};

    headers.Authorization = `Bearer ${refreshToken}`;
    headers.UserId = userId;
    headers.SessionId = sessionId;

    const response = await axios.post(apiUrl.href, {}, {
        headers: headers
    });

    if (response.data.Errors.length > 0) {
        return [response.data.Errors[0].Code];
    }

    const newAccessToken = response.data.data[0].Access_Token;
    const newAccessTokenVer = response.data.data[0].Access_Token_Version;

    const newRefreshToken = response.data.data[0].Refresh_Token;
    const newRefreshTokenVer = response.data.data[0].Refresh_Token_Version;

    return [0, newAccessToken, newAccessTokenVer, newRefreshToken, newRefreshTokenVer];
}

export function AddToSession(responseMsg, email, atoken, rtoken) {
    let newDate = new Date()

    let userId = parseInt(responseMsg.data[0].UserID);
    let sessionId = responseMsg.data[0].SessionID;

    let isProspectUser = responseMsg.data[0].ProspectUser;

    if (atoken != null && atoken != undefined) {
        localStorage.setItem(userId + '_AOBIDENTITYA', atoken);
    }
    else {
        localStorage.setItem(userId + '_AOBIDENTITYA', responseMsg.data[0].Access_Token);
    }

    localStorage.setItem(userId + '_AOBIDENTITYAV', responseMsg.data[0].Access_Token_Version);

    if (rtoken != null && rtoken != undefined) {
        localStorage.setItem(userId + '_AOBIDENTITYR', rtoken);
    }
    else {
        localStorage.setItem(userId + '_AOBIDENTITYR', responseMsg.data[0].Refresh_Token);
    }

    localStorage.setItem(userId + '_AOBIDENTITYRV', responseMsg.data[0].Refresh_Token_Version);

    localStorage.setItem('LoginUserId', responseMsg.data[0].UserID);
    localStorage.setItem(userId + '_LoginUserEmail', email);
    localStorage.setItem(userId + '_UserName', responseMsg.data[0].UserName);
    localStorage.setItem(userId + '_AccessControl', responseMsg.data[0].AccessControl);
    localStorage.setItem('SessionID', responseMsg.data[0].SessionID);
    localStorage.setItem(userId + '_LoginTime', moment(newDate).format("MM/DD/YYYY HH:mm:ss"));
    localStorage.setItem(userId + '_ProspectUser', isProspectUser);
    localStorage.setItem(userId + '_RoleName', responseMsg.data[0].RoleName);
}
export function RemoveSessionInfo(userId, sessionId) {

    let loginUserId = localStorage.getItem('LoginUserId');
    let loginSessionId = localStorage.getItem('SessionID');

    if (sessionId !== loginSessionId) {
        return;
    }

    if (loginUserId != null && loginUserId != undefined) {
        if (parseInt(loginUserId) == parseInt(userId)) {
            localStorage.removeItem('LoginUserId');
        }
    }

    localStorage.removeItem(userId + '_AOBIDENTITYA');
    localStorage.removeItem(userId + '_AOBIDENTITYAV');

    localStorage.removeItem(userId + '_AOBIDENTITYR');
    localStorage.removeItem(userId + '_AOBIDENTITYRV');

    localStorage.removeItem(userId + '_LoginUserEmail');
    localStorage.removeItem(userId + '_UserName');
    localStorage.removeItem(userId + '_AccessControl');
    localStorage.removeItem(userId + '_SessionID');
    localStorage.removeItem(userId + '_LoginTime');
    localStorage.removeItem(userId + '_ProspectUser');
    localStorage.removeItem(userId + '_RoleName');

    sessionStorage.removeItem(userId + 'ProspectHomeText');
    sessionStorage.removeItem('BankName');
    sessionStorage.removeItem('CopyrightText');
}

export async function WriteToActivityLog(activityId, activityDetail, userId, sessionId) {


    const apiUrl = new URL(
        process.env.REACT_APP_WRITE_USER_ACTIVITY_HISTORY,
        process.env.REACT_APP_BASE_URL,
    );

    const inputJson = {
        ActivityId: parseInt(activityId),
        AdditionalInformation: activityDetail       
    };

    let response = await APICall({ url: apiUrl.href, method: 'POST', payload: inputJson, contentType: 'application/json', responseType: '' }, userId, sessionId);

    if (response == null || response == undefined) {
        throw Error(ErrorMessages.UnknownError);
    }
}

export async function WriteToActivityLogWL(activityId, activityDetail) {


    const apiUrl = new URL(
        process.env.REACT_APP_WRITE_USER_ACTIVITY_HISTORY_WL,
        process.env.REACT_APP_BASE_URL,
    );

    const inputJson = {
        ActivityId: parseInt(activityId),
        AdditionalInformation: activityDetail
    };

    let response = await ServerAPICall({ url: apiUrl.href, method: 'POST', payload: inputJson, contentType: 'application/json', responseType: '' });

    if (response == null || response == undefined) {
        throw Error(ErrorMessages.UnknownError);
    }
}