import React, { useState } from 'react';
import { useNavigate } from "react-router-dom";
import { Nav, Navbar, Container, NavDropdown } from 'react-bootstrap';
import logo from '../assets/images/BankLogo.png';
import { Button, Link, Typography, Box, Stack } from '@mui/material';
import { ChevronRight } from 'react-bootstrap-icons';
import { Paper } from '@mui/material';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import SearchIcon from '@mui/icons-material/Search';
import CallIcon from '@mui/icons-material/Call';

export function PreLoginMenu(props)
{
    const sections = [
        { title: 'Find Branch', url: '#' },
        { title: 'Contact Us', url: '#' },
        { title: 'Help', url: '#' },
        { title: 'Chat', url: '#' },
    ];

    let navigate = useNavigate();

    const gotoHomePath = (event) => {
        event.preventDefault();
        navigate.push({ pathname: '/' });
    }

    return (
        <React.Fragment>
            <Toolbar
                component="nav"
                variant="dense"
                sx={{ pl: 0, ml: 0, justifyContent: 'space-between', overflowX: 'auto', border: 0, minHeight: '30px'}}
            >
 
                {/*<Typography sx={{ m: -3 }} variant="body1" color="#fff"> <CallIcon sx={{ color: '#fff'}} />&nbsp;123-456-7890 </Typography>*/}
                <Box>
{/*                {sections.map((section) => (*/}
                    {/*<Link color="#fff" underline="hover" noWrap key={0} variant="body1" href="#" sx={{ pl: 4, pr: 0, flexShrink: 0, fontWeight: 400, letterSpacing: '1px' }}>*/}
                    {/*    Find Branch*/}
                    {/*</Link>*/}
                    {/*<Link color="#fff" noWrap key={1} variant="body2" href="#" sx={{ pl: 2, pr: 0, flexShrink: 0 }}>*/}
                    {/*    Contact Us*/}
                    {/*</Link>*/}
                    {/*<Link color="#fff" underline="hover" noWrap key={2} variant="body1" href="#" sx={{ pl: 4, pr: 0, flexShrink: 0, letterSpacing: '1px' }}>*/}
                    {/*    Help*/}
                    {/*</Link>*/}
                    {/*<Link color="#fff" noWrap key={3} variant="body2" href="#" sx={{ pl: 2, pr: 0, flexShrink: 0 }}>*/}
                    {/*    Chat*/}
                    {/*</Link>*/}

{/*                ))}*/}
                    </Box>
            </Toolbar>

        </React.Fragment>
    );
}
