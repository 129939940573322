import { Power } from 'react-bootstrap-icons';
import React, { useEffect, useState } from 'react';
import { Nav, Navbar, Container, NavDropdown } from 'react-bootstrap';
import logo from '../assets/images/BankLogo-circle.png'
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from 'react-redux';
import { deleteSession } from '../redux/sessionMgmnt';
import { Logout } from '../global/Logout';
import { Button, Link, Typography, Paper, Box, Stack } from '@mui/material';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import CallIcon from '@mui/icons-material/Call';
import { GetLoggedInUserName, GetLoggedInUserEmail, GetLoggedInUserId } from '../global/APIUtils';
import PersonOutlineIcon from '@mui/icons-material/PersonOutline';
import LogoutIcon from '@mui/icons-material/Logout';

export default function PostLoginMenu(props) {

    let navigate = useNavigate();

    const [userInfo, setUserInfo] = useState('');

    const [userId, setUserId] = useState(props.userId);
    const [sessionId, setSessionId] = useState(props.sessionId);

    const sections = [
        { title: 'Logout', url: '#' },
    ];

    const logoutClick = async () => {

        //await Logout(userId, null);

        navigate('/AppLogout', { state: {userId: userId, sessionId: sessionId}, replace: true });
    }

    useEffect(() => {

        let uname = GetLoggedInUserName(userId, sessionId);
        let umail = GetLoggedInUserEmail(userId, sessionId);

        setUserInfo(uname + ' [' + umail + ']');

    }, []);

    return (
        <React.Fragment>
            <Toolbar 
                sx={{ p: 0, m: 0, pt: 1, pr: 2, pb: 1.5, justifyContent: 'flex-end', borderTop: '4px solid #4C4C4C', overflowX: 'auto', minHeight: '32px' }}
            >
                 <Stack direction="row" spacing={0} alignItems="center" sx={{pl: 2}}>
                    <PersonOutlineIcon sx={{ fontSize: 16 }} />
                    <Typography sx={{ pl: 0.5, }} variant="body2" color="text.primary">{userInfo}</Typography>
                </Stack>
                <Box sx={{pl: 2}}>
                    {/*<Typography variant="caption" color="#4C4C4C" sx={{ pl: 2, pb: 0, lineHeight: 1, fontWeight: 500, pb: 0 }} >{userInfo}</Typography>*/}
                    {/*<Link color="#000" noWrap key={2} variant="body1" href="#" sx={{ pl: 4, pr: 0, flexShrink: 0 }}>*/}
                    {/*    Help*/}
                    {/*</Link>*/}
                    {/*<Link color="#000" noWrap key={2} variant="body1" href="#" sx={{ pl: 4, pr: 0, flexShrink: 0 }}>*/}
                    {/*    Locations*/}
                    {/*</Link>*/}
                    {/*<Link color="#000" noWrap key={3} variant="body2" href="#" sx={{ pl: 2, pr: 0, flexShrink: 0 }}>*/}
                    {/*    Chat*/}
                    {/*</Link>*/}
                    <LogoutIcon sx={{ fontSize: 18}} />
                    <Link underline="hover" color="text.primary" noWrap key={0} variant="body2" sx={{ pl: 0.5, pr: 2, flexShrink: 0 }} onClick={logoutClick}>
                        Logout
                    </Link>
                </Box>
            </Toolbar>
        </React.Fragment>
    );
};