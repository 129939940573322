import React, { useEffect, useState, useRef } from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { Typography, IconButton, Grid, FormControl, Box, FormGroup, FormControlLabel, InputLabel, Select, MenuItem, Paper } from '@mui/material';
import { useFormik } from 'formik';
import * as yup from 'yup';
import LoadingOverlay from 'react-loading-overlay';
import RiseLoader from 'react-spinners/RiseLoader'
import { ErrorMessages } from '../common/ErrorMessages';
import { CallAPI, IsError } from '../global/APIUtils';
import AlertWithTitle from '../components/Controls/AlertWithTitle';
import AlertControl from '../components/Controls/AlertControl';
import { APICall, WriteToActivityLog } from '../global/Api';
import { ActivityLogEvent } from '../common/AOBEnum';
import Checkbox, { checkboxClasses } from "@mui/material/Checkbox";

const InvitationDlg = (props) => {
    const { onClose, open } = props;
    const pwdRegExp = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/;
    const [titleData, setTitleData] = useState(null);
    const [roleData, setRoleData] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [errorMsg, setErrorMsg] = useState('');
    const [successMsg, setSuccessMsg] = useState(null);
    const [successMsgTitle, setSuccessMsgTitle] = useState(null);
    const [disableInviteButton, setDisableInviteButton] = useState(false);
    const [userId, setUserId] = useState(props.userId);
    const [sessionId, setSessionId] = useState(props.sessionId);

    LoadingOverlay.propTypes = undefined;

    const handleClose = (event) => {
        event.preventDefault();
        onClose();
    };

    const userForm = useFormik({
        enableReinitialize: true,

        initialValues: {
            FirstName: "",
            LastName: "",
            email: "",
            Phone: "",
            Mobile: "",
            userrole: -1,
            ChkChngPwdNxtLogin: true
        },

        validationSchema: yup.object({
            FirstName: yup.string()
                .max(80, "First Name - must be a string with a maximum length of '40'.")
                .trim('First Name - cannot contain leading and trailing spaces')
                .required('First Name - required.'),
            LastName: yup.string()
                .max(80, "Last Name - must be a string with a maximum length of '40'.")
                .trim('Last Name - cannot contain leading and trailing spaces')
                .required('Last Name - required.'),
            email: yup.string()
                .max(80, "Email Address - must be a string with a maximum length of 80")
                .email('Email Address - Invalid email address')
                .required('Email Address - required'),
            Mobile: yup.string()
                .required('Mobile Number - required.'),
            userrole: yup.number()
                .required("Role - required.")
                .min(1, "Role - required.")
        }),
        onSubmit: async values => {
            submitClick(values);
        },
    });

    const submitClick = async (values) => {

        setErrorMsg(null);
        setSuccessMsgTitle(null);
        setSuccessMsg(null);

        try {

            setIsLoading(true);

            let inputJson = {
                Title: -1,
                FirstName: values.FirstName,
                LastName: values.LastName,
                DOB: null,
                GenderId: -1,
                EmailAddress: values.email,
                PhoneNbr: values.Phone,
                MobileNbr: values.Mobile,
                RoleTypeId: parseInt(values.userrole),
                PwdChangeAtLogon: values.ChkChngPwdNxtLogin,
            };

            const apiUrl = new URL(
                process.env.REACT_APP_INVITE_USER,
                process.env.REACT_APP_BASE_URL,
            );

            //let response = await CallAPI({ url: apiUrl.href, method: 'POST', headers: {}, body: inputJson });
            let response = await APICall({ url: apiUrl.href, method: 'POST', payload: inputJson, contentType: 'application/json', responseType: '' }, userId, sessionId);

            if (response !== null && response !== undefined) {

                setIsLoading(false);
                let error = IsError(response);

                if (error != 0) {
                    setErrorMsg(response.Errors[0].Message);
                    return;
                }

                if (response.meta.EmailSent == "Yes") {

                    await WriteToActivityLog(ActivityLogEvent.UserManagement, "Email invitation sent. User: '" + values.email + "'", userId, sessionId);

                    setSuccessMsgTitle("Invitation sent");
                    setSuccessMsg('Email invitation successfully sent to ' + values.email);
                    setDisableInviteButton(true);
                }
                else
                    setErrorMsg(ErrorMessages.UnknownError);
            }
        }
        catch (err) {
            setErrorMsg(ErrorMessages.UnknownError);
            setIsLoading(false);
        }
        finally {
            
        }
    }

    const loadFormData = async () => {

        try {

            setIsLoading(true);

            let apiUrl = new URL(
                process.env.REACT_APP_GET_ALL_USERROLES,
                process.env.REACT_APP_BASE_URL,
            );

            //let response = await CallAPI({ url: apiUrl.href, method: 'GET', headers: {} });
            let response = await APICall({ url: apiUrl.href, method: 'GET', contentType: 'application/json', responseType: '' }, userId, sessionId);

            if (response !== undefined && response !== null) {
                setRoleData(response["data"]);
                setIsLoading(false);
            }
        }
        catch (err) {
            setErrorMsg(ErrorMessages.UnknownError);
            setIsLoading(false);
        }
        finally {
            
        }
    };

    useEffect(() => {
        loadFormData();
    }, []);

    return (
        <Dialog open={open} onClose={handleClose} keepMounted fullWidth maxWidth="md">
            <LoadingOverlay active={isLoading} spinner={<RiseLoader color={'#1D50AF'} />} >
                <DialogTitle>
                    <Typography variant="body1" color="text.primary" sx={{ fontWeight: 500 }}>
                        Invite User
                    </Typography>                    
                </DialogTitle>
                <DialogContent dividers>
                    <Box sx={{ pt: 1 }}>
                        <AlertWithTitle Message={successMsg} variant="body1" severity="success" color="success" icon={true} Title={successMsgTitle} />
                        <AlertControl Message={errorMsg} severity="error" color="error" icon={true} />
                    </Box>
                    <Grid item container sm={12} direction="row" sx={{ pt: 0, pb: 2, pl: 4, pr: 6 }}>
                        <form onSubmit={userForm.handleSubmit} id="frmUser">
                            <Box>
                                <Grid item container direction="row" spacing={2} sx={{}}>
                                    <Grid item container xs={12} direction="row" spacing={0} sx={{ marginTop: '0px' }}>
                                        <Grid item sm={6} sx={{ pr: 2 }} >
                                            <Box elevation={0}>
                                                <label htmlFor="FirstName" className="form-label">First Name*</label>
                                                <input type="text" id="FirstName" name="FirstName" placeholder="Enter first name here" className="form-control" value={userForm.values.FirstName} onChange={userForm.handleChange} />
                                                {
                                                    userForm.touched.FirstName && userForm.errors.FirstName ? (<p className="text-danger">{userForm.errors.FirstName}</p>) : null
                                                }
                                            </Box>
                                        </Grid>
                                        <Grid item sm={6} sx={{ pl: 2 }} >
                                            <Box elevation={0}>
                                                <label htmlFor="LastName" className="form-label">Last Name*</label>
                                                <input type="text" id="LastName" name="LastName" placeholder="Enter last name here" className="form-control" value={userForm.values.LastName} onChange={userForm.handleChange} />
                                                {
                                                    userForm.touched.LastName && userForm.errors.LastName ? (<p className="text-danger">{userForm.errors.LastName}</p>) : null
                                                }
                                            </Box>
                                        </Grid>
                                    </Grid>
                                    <Grid item sm={12} sx={{}} >
                                        <Box elevation={0}>
                                            <label htmlFor="email" className="form-label">Email Address*</label>
                                            <input type="email" id="email" name="email" placeholder="Enter email here" className="form-control" value={userForm.values.email} onChange={userForm.handleChange} />
                                            {
                                                userForm.touched.email && userForm.errors.email ? (<p className="text-danger">{userForm.errors.email}</p>) : null
                                            }
                                        </Box>
                                    </Grid>
                                    <Grid item container xs={12} direction="row" spacing={0} sx={{}}>
                                        <Grid item sm={6} sx={{ pr: 2 }} >
                                            <Box elevation={0}>
                                                <label htmlFor="Mobile" className="form-label">Mobile Number*</label>
                                                <input type="text" name="Mobile" id="Mobile" placeholder="Enter mobile number here" className="form-control" value={userForm.values.Mobile} onChange={userForm.handleChange} />
                                                {
                                                    userForm.touched.Mobile && userForm.errors.Mobile ? (<p className="text-danger">{userForm.errors.Mobile}</p>) : null
                                                }
                                            </Box>
                                        </Grid>
                                        <Grid item sm={6} sx={{ pl: 2 }} >
                                            <Box elevation={0}>
                                                <label htmlFor="Phone" className="form-label">Phone Number</label>
                                                <input type="tel" id="Phone" name="Phone" placeholder="Enter phone number here" className="form-control" value={userForm.values.Phone} onChange={userForm.handleChange} />
                                                {
                                                    userForm.touched.Phone && userForm.errors.Phone ? (<p className="text-danger">{userForm.errors.Phone}</p>) : null
                                                }
                                            </Box>
                                        </Grid>
                                    </Grid>
                                    <Grid item container xs={12} direction="row" spacing={0} sx={{}}>
                                        <Grid item sm={9} sx={{ pt: 0 }} >
                                            <Box elevation={0}>
                                                <label htmlFor="userrole" className="form-label">Role*</label>
                                                <select id="userrole" className="form-select" name="userrole" value={userForm.values.userrole} onChange={userForm.handleChange} placeholder="Title">
                                                    <option key={-1} value={-1}>Select</option>
                                                    {
                                                        roleData && roleData.map((e) => (
                                                            <option key={e.RoleTypeId} value={e.RoleTypeId}>{e.RoleName}</option>
                                                        ))
                                                    }
                                                </select>
                                                {
                                                    userForm.touched.userrole && userForm.errors.userrole ? (<p className="text-danger">{userForm.errors.userrole}</p>) : null
                                                }
                                            </Box>
                                        </Grid>
                                    </Grid>
                                    <Grid item container xs={12} direction="row" spacing={0} sx={{ pt: 2 }}>
                                        <Grid item sm={8} sx={{ pr: 1 }} >
                                            <FormControl variant="outlined" sx={{ width: '100%' }} >
                                                <FormControlLabel color="text.primary" control={<Checkbox sx={{
                                                    [`&, &.${checkboxClasses.checked}`]: {
                                                        color: 'Check.main',
                                                    },
                                                }} name="ChkChngPwdNxtLogin" id="ChkChngPwdNxtLogin" checked={userForm.values.ChkChngPwdNxtLogin} onChange={userForm.handleChange} />} label={<Typography sx={{ fontWeight: 400 }} variant="body4" color="text.primary">User must change password at next logon</Typography>} />
                                            </FormControl>
                                            {
                                                userForm.touched.ChkChngPwdNxtLogin && userForm.errors.ChkChngPwdNxtLogin ? (<p className="text-danger">{userForm.errors.ChkChngPwdNxtLogin}</p>) : null
                                            }
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Box>
                        </form>
                    </Grid>
                </DialogContent>
                <DialogActions sx={{ mr: 2 }}>
                    <Button variant="contained" onClick={handleClose} sx={{ mt: 1, mb: 1, mr: 1, borderRadius: '20px', padding: '0.3rem 2.2rem', textTransform: 'none' }}>
                        Cancel
                    </Button>
                    <Button variant="contained" onClick={userForm.handleSubmit} disabled={disableInviteButton} sx={{ mt: 1, mb: 1, borderRadius: '20px', padding: '0.3rem 2.5rem', textTransform: 'none' }}>
                        Invite
                    </Button>
                </DialogActions>
            </LoadingOverlay>
        </Dialog>
    );
}

export default InvitationDlg;