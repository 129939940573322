import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { deleteSession } from '../redux/sessionMgmnt';
import { CallLogoutAPI, RemoveLoginInfoFromSession } from './APIUtils';
import { useNavigate } from "react-router-dom";

export async function Logout(userID, reason) {

    try {

        if (userID === null || userID === undefined) {
            return;
        }

        let headers = {
            'Content-Type': 'application/json'
        };

        let bodyData = {
            CntctId: parseInt(userID)
        }

        const apiUrl = new URL(
            process.env.REACT_APP_LOGOUTUSER,
            process.env.REACT_APP_BASE_URL,
        );

        if (userID != 0) {

            let response = await CallLogoutAPI({ url: apiUrl.href, method: 'POST', headers: headers, body: bodyData });
        }

        RemoveLoginInfoFromSession(userID);

        return true;
    }
    catch (err) {
        console.error(err.message);
        return false;
    }
}

