import React, { useState, useEffect, useRef } from 'react';
import Container from '@mui/material/Container';
import { Box, Typography, Stack, Button, Badge, Paper, Alert, AlertTitle, Divider, Grid, Toolbar, Card } from '@mui/material';
import OverlaySpinner from './Controls/OverlaySpinner';
import PostLoginMenu from './PostLoginMenu'
import Footer from './Footer';
import EnrollmentHeader from './EnrollmentHeader'
import { DrawerAppBar } from './AppNavbar'
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import pwdimg from '../assets/images/Online protection_Monochromatic.png';
import Image from 'material-ui-image'
import { useFormik } from 'formik';
import * as yup from 'yup';
import AlertControl from './Controls/AlertControl';
import { FormControl, TextField } from '@mui/material';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormGroup from '@mui/material/FormGroup';
import { RedirectToHome } from '../global/Helpers';
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from 'react-redux';
import { ErrorMessages } from '../common/ErrorMessages';
import { CallAPI, IsError, IsLoggedInUserProspectUser, GetAccessControl, GetLoggedInUserId, GetLoggedInUserEmail } from '../global/APIUtils';
import { ReactComponent as ReactLogo } from '../assets/images/ssb-horizontal-new.svg';
import { useLocation } from "react-router-dom";
import { APICall, WriteToActivityLog } from '../global/Api';
import clientlogo from '../assets/images/ssb-logo-lpl-blue.png';
//import clientlogo from '../assets/images/demo_bank_logo.jpg';
import ClientLogoHeader from './ClientLogoHead'
import { ActivityLogEvent } from '../common/AOBEnum';
import Checkbox, { checkboxClasses } from "@mui/material/Checkbox";

const CreatePassword = (props) => {

    const location = useLocation();

    const [isLoading, setIsLoading] = useState(false);
    const [expanded, setExpanded] = useState(true);
    const [errorMsg, setErrorMsg] = useState('');
    const [successMsg, setSuccessMsg] = useState('');
    const [checked, setChecked] = React.useState(true);
    const [isProspect, setProspect] = useState(true);
    const [role, setRole] = useState('');
    const [CntCtId, setCntCtId] = useState(-1);
    const emailAdrs = useRef(null);
    const dispatch = useDispatch();
    const [strength, setStrength] = useState("");
    const [strengthColor, setStrengthColor] = useState("");
    const [showStrength, setShowStrength] = useState("none");

    const [userId, setUserId] = useState(location.state.userId || -1);
    const [sessionId, setSessionId] = useState(location.state.sessionId || '');
    const [forceChange, setForceChange] = useState(location.state.forceChange || false);
    const [changeReason, setChangeReason] = useState(location.state.reason || '');

    const [pwdLenMin, setPwdLenMin] = useState(10);

    const pwdRegExp = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/;
    let navigate = useNavigate();
    const sessions = useSelector((state) => state.sessionMgmnt);

    const handleAccordianChange = () => {
        if (expanded) {
            setExpanded(false);
        }
        else {
            setExpanded(true);
        }
    };

    const handleChange = (event) => {
        setChecked(event.target.checked);
        showPassword();
    };

    function evaluatePasswordStrength(password) {
        let score = 0;

        if (!password) {
            setShowStrength('none');
            return '';
        }

        // Check password length
        if (password.length > 11) score += 1;
        // Contains lowercase
        if (/[a-z]/.test(password)) score += 1;
        // Contains uppercase
        if (/[A-Z]/.test(password)) score += 1;
        // Contains numbers
        if (/\d/.test(password)) score += 1;
        // Contains special characters
        if (/[^A-Za-z0-9]/.test(password)) score += 1;

        setShowStrength('inline-block');

        switch (score) {
            case 0:
            case 1:
            case 2:
                setStrengthColor('#f00');
                return "Weak";
            case 3:
            case 4:
                setStrengthColor('#ffa500');
                return "Medium";
            case 5:
                setStrengthColor('#228b22');
                return "Strong";
        }
    }

    const createPwdForm = useFormik({

        enableReinitialize: true,
        initialValues: {
            NewPassword: "",
            ConfirmPassword: ""
        },

        validationSchema: yup.object({
            NewPassword: yup.string()
                .max(20, "Should have less than or equal to 20 characters")
                .min(pwdLenMin, "Should have more than or equal to " + pwdLenMin + " characters")
                .matches(pwdRegExp,
                    "Should have one uppercase, one lowercase, one number and one special character"
                )
                .required('New Password - required.'),
            ConfirmPassword: yup.string()
                .max(20, "Should have less than or equal to 20 characters")
                .min(pwdLenMin, "Should have more than or equal to " + pwdLenMin + " characters")
                .matches(pwdRegExp,
                    "Should have one uppercase, one lowercase, one number and one special character"
                )
                .when("NewPassword", {
                    is: psswrd => (psswrd && psswrd.length > 0 ? true : false),
                    then: yup.string().oneOf(
                        [yup.ref("NewPassword")],
                        "New password and confirm password does not match")
                })
                .required('Confirm Password - required.')
        }),
        onSubmit: values => {
            submitClick(values);
        },
    });

    const submitClick = async (values) => {

        setErrorMsg('');

        let chngModel = {
            EmailAddress: emailAdrs.current,
            NPassword: values.NewPassword,
            CPassword: values.ConfirmPassword,
            UserId: parseInt(userId)
        };

        //let headers = {
        //    'Content-Type': 'application/json'
        //};

        //let endpoint = process.env.REACT_APP_BASE_URL + process.env.REACT_APP_CREATE_PASSWORD;

        try {
            setIsLoading(true);

            const apiUrl = new URL(
                process.env.REACT_APP_CREATE_PASSWORD,
                process.env.REACT_APP_BASE_URL,
            );

            //let response = await CallAPI({ url: apiUrl.href, method: 'POST', headers: headers, body: chngModel }, userId, sessionId);

            let response = await APICall({ url: apiUrl.href, method: 'POST', payload: chngModel, contentType: 'application/json', responseType: '' }, userId, sessionId);

            if (response != null && response != undefined) {
                let error = IsError(response);

                if (error != 0) {
                    setErrorMsg(response.Errors[0].Message);
                }
                else {
                    await WriteToActivityLog(ActivityLogEvent.ForgotPassword, "Password changed.", userId, sessionId);
                    navigate('/UserHome', { state: { userId: userId, sessionId: sessionId }, replace: true });
                }
            }
        }
        catch (err) {
            setErrorMsg(ErrorMessages.UnknownError);
        }
        finally {
            setIsLoading(false);
        }
    }

    function showPassword() {

        var np = document.getElementById("NewPassword");
        var cp = document.getElementById("ConfirmPassword");

        if (np.type === "password") {
            np.type = "text";
        } else {
            np.type = "password";
        }

        if (cp.type === "password") {
            cp.type = "text";
        } else {
            cp.type = "password";
        }
    }

    const ContinueHome = (event) => {
        event.preventDefault();
        navigate('/UserHome', { state: { userId: userId, sessionId: sessionId }, replace: true });
    };

    const GetPasswordPolicy = async () => {
        try {

            let headers = {
                'Content-Type': 'application/json'
            };

            const apiUrl = new URL(
                process.env.REACT_APP_GET_PWD_POLICY,
                process.env.REACT_APP_BASE_URL,
            );

            setIsLoading(true);

            let response = await APICall({ url: apiUrl.href, method: 'GET', contentType: 'application/json', responseType: '' }, userId, sessionId);

            if (response !== null && response !== undefined) {
                if (response["data"] && response["data"].length > 0) {
                    setPwdLenMin(parseInt(response.data[1].ParmValue));
                }
            }
            else {
                setErrorMsg(ErrorMessages.UnknownError);
            }
        }
        catch (err) {
            setErrorMsg(ErrorMessages.UnknownError);
        }
        finally {
            setIsLoading(false);
        }
    }

    useEffect(() => {

        //let isProspectUser = IsLoggedInUserProspectUser(userId, sessionId);
        //let accessControl = GetAccessControl(userId, sessionId);

        let email = GetLoggedInUserEmail(userId, sessionId);
        emailAdrs.current = email;

        GetPasswordPolicy();

        //setRole(accessControl);
        //setCntCtId(userId);
        //
        //setProspect(isProspectUser);

    }, []);

    return (
        <React.Fragment>
            <Container maxWidth="lg" sx={{}}>
                <OverlaySpinner disappear={isLoading} />
                
                <Paper>
                    <PostLoginMenu userId={userId} sessionId={sessionId} />
                    <Grid item md={12} >
                        <EnrollmentHeader />
                        {/*<Toolbar disableGutters={true} sx={{ borderBottom: '0px solid #e0e0e0', justifyContent: 'space-between', backgroundColor: '#fff', pb: 2.5, pt: 1 }}>*/}
                        {/*    <Box sx={{*/}
                        {/*        pr: 8,*/}
                        {/*        pl: 5, pt: 0*/}
                        {/*    }}>*/}
                        {/*        <img src={clientlogo} height="44" loading="lazy" />*/}
                        {/*    </Box>*/}
                        {/*</Toolbar>*/}
                    </Grid>
                    {/*<Toolbar sx={{ borderBottom: 1, borderColor: 'divider', justifyContent: 'space-between', backgroundColor: '#fff', pt: 0, pb: 0 }}>*/}

                    {/*    <Box sx={{ display: 'flex', alignItems: 'center', ml: 1, mt: 4, mb: 1 }}>*/}
                    {/*        <img src={clientlogo} height="44" loading="lazy" />*/}
                    {/*    </Box>*/}
                    {/*</Toolbar>*/}
                    <Grid sx={12} container justifyContent="center" spacing={0} sx={{ pt: 5, }}>
                        <Grid item md={2}>
                        </Grid>
                        <Grid item md={8} sx={{ pr: 2, pt: 2, pb: 6 }}>
                            <Card elevation={3}  sx={{ mx: 'auto', border: 0, borderColor: '#9FA4A8', borderRadius: '6px', pt: 1, pl: 7, pr: 7}}>
                                <Box sx={{ pt: 3 }}>
                                <Stack direction="column">
                                        <Typography variant="h4" gutterBottom sx={{ fontSize: '18px', pt: 1, fontWeight: 500 }} color="text.secondary">Change password</Typography>
                                        <Box sx={{pt: 2}}>
                                            <Typography variant="body1" sx={{fontWeight: 500}} color="#228b22">{changeReason}</Typography>
                                        </Box>
                                    <Typography sx={{ mb: 1, mt: 4, fontWeight: 400 }} variant="body2" color="text.primary">
                                        Create a strong password
                                    </Typography>
                                        <Typography sx={{ mb: 3, mt: 1, fontWeight: 500 }} variant="body3" color="text.secondary">
                                        Use a new, strong password that you don't use for other websites. Don't use a variation of an old password or any personal information.
                                    </Typography>
                                </Stack>
                                <form className="w-100" onSubmit={createPwdForm.handleSubmit} id="frmChangePwd">
                                    <Grid item sx={{ mb: 1 }} >
                                        <AlertControl Message={errorMsg} severity="error" color="error" icon={true} />
                                        <AlertControl Message={successMsg} severity="success" color="success" icon={true} />
                                    </Grid>
                                    <Grid item sx={{ mb: 1 }} >
                                            <Box>
                                            <label htmlFor="NewPassword" className="form-label">New Password*</label>
                                                <input type="password" id="NewPassword" autoComplete="off" name="NewPassword" placeholder="Enter your password here" className="form-control" value={createPwdForm.values.NewPassword} onChange={(event) => { createPwdForm.handleChange(event); setStrength(evaluatePasswordStrength(event.target.value)) }} />
                                            {
                                                createPwdForm.touched.NewPassword && createPwdForm.errors.NewPassword ? (<p className="text-danger">{createPwdForm.errors.NewPassword}</p>) : null
                                            }
                                            <Stack direction="row" spacing={1}>
                                                <Typography variant="body2" color="text.secondary" sx={{ display: showStrength, pt: 1, pb: 0, fontWeight: 500, }} >Password strength: </Typography>
                                                <Typography variant="body2" color={strengthColor} sx={{ pt: 1, pb: 0, fontWeight: 700, }} >{strength}</Typography>
                                            </Stack>
                                            </Box>
                                    </Grid>
                                    <Grid item sx={{ mb: 1, pt: 1 }} >
                                        <Box>
                                            <label htmlFor="ConfirmPassword" className="form-label">Confirm Password*</label>
                                            <input type="password" id="ConfirmPassword" autoComplete="off" name="ConfirmPassword" placeholder="Re-enter your password here" className="form-control" value={createPwdForm.values.ConfirmPassword} onChange={createPwdForm.handleChange} />
                                            {
                                                createPwdForm.touched.ConfirmPassword && createPwdForm.errors.ConfirmPassword ? (<p className="text-danger">{createPwdForm.errors.ConfirmPassword}</p>) : null
                                            }
                                        </Box>
                                    </Grid>
                                    <Grid item sx={{}} >
                                        <FormGroup>
                                            <FormControlLabel control={<Checkbox sx={{
                                                [`&, &.${checkboxClasses.checked}`]: {
                                                    color: 'Check.main',
                                                },
                                            }} onChange={handleChange} inputProps={{ 'aria-label': 'controlled' }} />} label={<Typography variant="body3" color="text.primary">Show password</Typography>} />
                                        </FormGroup>
                                    </Grid>
                                    <Grid item sm={12} sx={{}} >
                                        <Box
                                            display="flex"
                                            justifyContent="space-between"
                                            sx={{ pl: 0, pr: 0, pb: 6, pt: 2 }}
                                        >
                                                <Button variant="contained" sx={{ display: forceChange == true ? 'none' : 'inline-block', borderRadius: '20px', padding: '0.25rem 2rem', textTransform: 'none'}} onClick={ContinueHome} >
                                                Skip
                                            </Button>
                                                <Box sx={{}}>
                                                    <Button type="submit" variant="contained" size="large" style={{ borderRadius: '20px', padding: '0.25rem 2rem', textTransform: 'none' }}>
                                                        Save password
                                                    </Button>
                                                </Box>
                                        </Box>
                                    </Grid>
                                    </form>
                                </Box>
                            </Card>
                        </Grid>
                        <Grid item md={2}>
                        </Grid>
                    </Grid>
                    <Footer />
                </Paper>
            </Container>
        </React.Fragment>
    );
}

export default CreatePassword;