import React, { useState, useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useLocation } from "react-router-dom";
import Container from '@mui/material/Container';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import PostLoginMenu from './PostLoginMenu'
import Footer from './Footer';
import { CallAPI, CallServerAPI, IsError } from '../global/APIUtils';
import OverlaySpinner from './Controls/OverlaySpinner';
import { TextField, Grid, Paper, FormControl, Stack, Box, Divider, Typography, Button, List, ListItem, ListItemButton, ListItemText, ListSubheader, Tooltip, IconButton, FormLabel, FormGroup, FormControlLabel, RadioGroup, Radio } from '@mui/material';
import Checkbox, { checkboxClasses } from "@mui/material/Checkbox";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import EnrollmentHeader from './EnrollmentHeader'
import { DrawerAppBar } from './AppNavbar'
import { useFormik } from 'formik';
import * as yup from 'yup';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import { useNavigate } from "react-router-dom";
import AlertControl from './Controls/AlertControl';
import AlertWithTitle from './Controls/AlertWithTitle';
import { ErrorMessages } from '../common/ErrorMessages';
import { APICall, WriteToActivityLog } from '../global/Api';
import ArrowCircleLeftOutlinedIcon from '@mui/icons-material/ArrowCircleLeftOutlined';
import AutoLagoutTimer from '../global/IdleMonitor';
import { ActivityLogEvent } from '../common/AOBEnum';

const RoleDefinition = (props) => {

    const location = useLocation();

    const [expanded, setExpanded] = useState(true);
    const [checked, setChecked] = React.useState([]);
    const [errorMsg, setErrorMsg] = useState('');
    const [successMsg, setSuccessMsg] = useState(null);
    const [successMsgTitle, setSuccessMsgTitle] = useState(null);
    const [isAllProcesses, setIsAllProcesses] = useState(false);
    const [isProspectUser, setIsProspectUser] = useState(false);
    const [disIsProspectUser, setDisIsProspectUser] = useState(false);
    const [disIsAllProcess, setDisIsAllProcess] = useState(false);
    const [disProcessList, setDisProcessList] = useState(false);
    const [isLoading, setIsLoading] = useState('');

    const [readOnly, setReadOnly] = useState(location.state.readOnly || false);

    const [userId, setUserId] = useState(location.state.userId || -1);
    const [sessionId, setSessionId] = useState(location.state.sessionId || '');

    const dispatch = useDispatch();
    let sessions = useSelector((state) => state.sessionMgmnt);
    const [userProcess, setUserProcess] = useState(null);
    let navigate = useNavigate();

    const [roleTypeId, setRoleTypeId] = useState(parseInt(location.state.roleTypId) || -1);


    const handleToggle = (value) => () => {

        let found = userProcess.find(element => element.PrcsName === value.PrcsName);

        if (found != undefined && found != null) {
            found.IsSelected = !found.IsSelected;
        }

        const newSet = [...userProcess];
        setUserProcess(newSet);
    };

    const handleAccordianChange = () => {
        if (expanded) {
            setExpanded(false);
        }
        else {
            setExpanded(true);
        }
    };

    const roleDefnForm = useFormik({
        initialValues: {
            RoleName: location.state.roleName || '',
            RoleDesc: location.state.roleDesc || ''
        },
        validationSchema: yup.object({
            RoleName: yup.string()
                .max(80, "Role name - must be a string with a maximum length of '80'charectors.")
                .required('Role name - required.'),
            RoleDesc: yup.string()
                .max(255, "Description - must be a string with a maximum length of '255'charectors.")
        }),
        onSubmit: values => {
            handleSubmit(values);
        }
    });

    useEffect(() => {
        GetFormData();
    }, []);

    const EnableDisableAllProcess = (isAllProcessCheckState) => {
        setDisIsProspectUser(isAllProcessCheckState == false);
        setDisProcessList(isAllProcessCheckState == false)
        ClearAllProcess();
    }

    const AllProcessClicked = (e) => {
        setIsAllProcesses(!isAllProcesses);
        EnableDisableAllProcess(isAllProcesses);
    }

    const EnableDisableIsProspectUser = (isProspectCheckState) => {
        setDisIsAllProcess(isProspectCheckState == false);
        setDisProcessList(isProspectCheckState == false)
        ClearAllProcess();
    }

    const IsProspectUserClicked = (e) => {
        setIsProspectUser(!isProspectUser);
        EnableDisableIsProspectUser(isProspectUser);
    }

    const ClearAllProcess = () => {
        if (userProcess != null) {
            userProcess.forEach((ProcessDetail, index) => {
                ProcessDetail.IsSelected = 0;
            });
            const newSet = [...userProcess];
            setUserProcess(newSet);
        }
    }

    const GetFormData = async () => {

        try {

            setIsLoading(true);

            let apiUrl = new URL(
                process.env.REACT_APP_GET_USER_PROCESSES,
                process.env.REACT_APP_BASE_URL,
            );

            let allProcess = null;

            //let response = await CallAPI({ url: apiUrl.href, method: 'GET', headers: {} }, userId, sessionId);
            let response = await APICall({ url: apiUrl.href, method: 'GET', contentType: 'application/json', responseType: '' }, userId, sessionId);

            if (response !== null && response !== undefined) {
                allProcess = response.data;
                //setUserProcess(response.data);
            }

            if (roleTypeId == -1) {
                setUserProcess(allProcess);
                await WriteToActivityLog(ActivityLogEvent.RoleManagement, "Opened new role page.", userId, sessionId);
                setIsLoading(false);
                return;
            }

            apiUrl = new URL(
                process.env.REACT_APP_GET_ROLE_DETAIL,
                process.env.REACT_APP_BASE_URL,
            );

            apiUrl.searchParams.set('roleId', `${roleTypeId}`);

            //response = await CallAPI({ url: apiUrl.href, method: 'GET', headers: {} }, userId, sessionId);
            response = await APICall({ url: apiUrl.href, method: 'GET', contentType: 'application/json', responseType: '' }, userId, sessionId);

            if (response !== null && response !== undefined) {

                await WriteToActivityLog(ActivityLogEvent.RoleManagement, "Viewed role detail. Role name: '" + response["data"][0].RoleName + "'", userId, sessionId);

                setIsLoading(false);
                setIsAllProcesses(response.data[0].IsAllPrcs);
                setIsProspectUser(response.data[0].IsProspect);

                if (response.data[0].IsAllPrcs) {
                    setDisIsProspectUser(true);
                    setDisProcessList(true);
                }
                else if (response.data[0].IsProspect) {
                    setDisIsAllProcess(true);
                    setDisProcessList(true);
                }

                if (response.data[0].IsAllPrcs || response.data[0].IsProspect) {
                    setUserProcess(allProcess);
                    return;
                }

                let found;
                let selectedProcess = response.data[0].Processes;

                if (allProcess != null & allProcess != undefined && allProcess.length > 0) {
                    if (selectedProcess != null && selectedProcess != undefined && selectedProcess.length > 0) {
                        for (let i = 0; i < selectedProcess.length; i++) {
                            found = allProcess.find(element => element.PrcsName === selectedProcess[i].PrcsName);
                            if (found != undefined && found != null) {
                                found.IsSelected = true;
                            }
                        }
                    }

                    setUserProcess(allProcess);
                }
            }
        }
        catch (err) {
            setErrorMsg(ErrorMessages.UnknownError);
        }
        finally {
            setIsLoading(false);
        }
    }

    const handleBackClick = () => {
        navigate('/RoleManagement', { state: { userId: userId, sessionId: sessionId }, replace: true });
    }

    const handleSubmit = async (values) => {

        setIsLoading(true);

        let prcsIds = [];

        if (userProcess != null && userProcess.length > 0) {
            userProcess.forEach((value, key) => {
                prcsIds.push({ PrcsId: value.PrcsId, IsSelected: value.IsSelected ? 1 : 0 });
            });
        }

        let inputJSON = {
            "RoleTypeId": parseInt(roleTypeId),
            "RoleName": values.RoleName,
            "RoleDesc": values.RoleDesc,
            "IsAllPrcs": isAllProcesses,
            "IsProspect": isProspectUser,
            "Processes": prcsIds
        }
        let headers = {
            'Content-Type': 'application/json'
        };

        try {

            const apiUrl = new URL(
                process.env.REACT_APP_SAVE_ROLE_DETAIL,
                process.env.REACT_APP_BASE_URL,
            );

            //let response = await CallAPI({ url: apiUrl.href, method: 'POST', headers: headers, body: inputJSON }, userId, sessionId);
            let response = await APICall({ url: apiUrl.href, method: 'POST', payload: inputJSON, contentType: 'application/json', responseType: '' }, userId, sessionId);

            if (response != null && response != undefined) {
                setIsLoading(false);
                let error = IsError(response);

                if (error != 0) {
                    setErrorMsg(response.Errors[0].Message);
                }
                else {
                    await WriteToActivityLog(ActivityLogEvent.RoleManagement, "Modified role. Role name: '" + values.RoleName + "'", userId, sessionId);
                    setRoleTypeId(parseInt(response.data[0].RoleTypeId));
                    setSuccessMsgTitle("Role saved");
                    setSuccessMsg("Role information has been saved successfully.");
                }
            }
        }
        catch (err) {
            setErrorMsg(ErrorMessages.UnknownError);
            setIsLoading(false);
        }
        finally {

        }
    }

    return (

        <React.Fragment>
            <AutoLagoutTimer userId={userId} sessionId={sessionId} />
            <Container maxWidth="lg" sx={{}} className="">
                <OverlaySpinner disappear={isLoading} />
                <Paper className="back-grid">
                    <PostLoginMenu userId={userId} sessionId={sessionId} />
                    <Grid item md={12} id="aobhead" >
                        <Box sx={{
                            pr: 0,
                            pl: 0,
                        }}>
                            <EnrollmentHeader />
                        </Box>
                    </Grid>
                    <Grid item sx={{ pr: 1 }}>
                        <DrawerAppBar userId={userId} sessionId={sessionId} />
                    </Grid>
                    <Grid item container direction="row" spacing={2} sx={{ pt: 0, pb: 0, }}>
                        <Grid item md={12} sx={{}}>
                            <Box sx={{ display: 'flex', pb: 2, pt: 0, ml: 6.5, mr: 6.5, justifyContent: 'space-between', alignItems: 'flex-end', borderBottom: '0px solid #898F94' }}>
                                <Typography variant="h6" color="text.primary" sx={{ pl: 0, pb: 0, lineHeight: 1, fontWeight: 500, pb: 0, letterSpacing: '0px' }} >Role Details</Typography>
                            </Box>
                            <Box sx={{ }}>
                                <Divider variant="fullWidth" component="div" />
                            </Box>
                            <Box sx={{ mt: 0, pt: 3, pb: 1, ml: 6.5, mr: 6.5, borderTop: '0px solid #A8B3B3', borderBottom: '0px solid #d2d2d2' }}>
                                <Stack direction="column" sx={{ width: '90%' }}>
                                    <Typography sx={{ pl: 0, fontWeight: 400, }} variant="body1" color="text.primary">
                                        A role is given to each person who can log into the website to define what they can and can’t do. User access is controlled by using processes.
                                    </Typography>
                                </Stack>
                            </Box>
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <Grid container sx={{ pt: 0, pl: 6.5 }}>
                            <Grid item md={8} justifyContent="center">
                                <Box>
                                    <AlertControl Message={errorMsg} severity="error" color="error" icon={true} />
                                    <AlertWithTitle Message={successMsg} variant="body1" severity="success" color="success" icon={true} Title={successMsgTitle} />
                                </Box>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} sx={{ pt: 0, pb: 5 }}>
                        <form id="frmRoleDefinition" onSubmit={roleDefnForm.handleSubmit} sx={{ pl: 0, m: 0 }}>
                            <Grid container sx={{ pt: 0, pl: 6.5 }}>
                                <Grid item md={10} sx={{ pb: 1 }}>
                                    <Stack direction="row" alignItems="center">
                                        <Box>
                                            <Tooltip arrow title="Back" onClick={() => handleBackClick()}>
                                                <span>
                                                    <IconButton>
                                                        <ArrowCircleLeftOutlinedIcon sx={{ fontSize: 32 }} color="#61676B" />
                                                    </IconButton>
                                                </span>
                                            </Tooltip>
                                        </Box>
                                        <Typography variant="body4" color="text.primary" sx={{ textTransform: 'none', fontWeight: 400, pl: 0 }} >{roleTypeId == -1 ? 'New role' : 'Edit role'}</Typography>
                                    </Stack>
                                </Grid>
                                <Grid item md={8} justifyContent="center" sx={{}}>
                                    <Grid container sx={{ borderTop: '1px solid #D5D9D9', borderRadius: '6px', pt: 3, pb: 4, ml: 1, pl: 3, pr: 3, boxShadow: '0 1px 3px 0 #D5D9D9, 0 0 0 1px #D5D9D9' }}>
                                        <Grid item md={6} >
                                            <Grid container justifyContent="center" sx={{ pr: 4 }} alignItems="center">
                                                <Grid item xs={11} sx={{}} >
                                                    <Paper elevation={0}>
                                                        <label htmlFor="RoleName" className="form-label">Role Name*</label>
                                                        <input disabled={readOnly} type="text" id="RoleName" name="RoleName" placeholder="Enter role name here" className="form-control" value={roleDefnForm.values.RoleName} onChange={roleDefnForm.handleChange} />
                                                        {
                                                            roleDefnForm.touched.RoleName && roleDefnForm.errors.RoleName ? (<p className="text-danger">{roleDefnForm.errors.RoleName}</p>) : null
                                                        }
                                                    </Paper>
                                                </Grid>
                                                <Grid item xs={11} sx={{ pt: 2 }}>
                                                    <Paper elevation={0}>
                                                        <label htmlFor="RoleDesc" className="form-label">Role Description</label>
                                                        <input disabled={readOnly} type="text" id="RoleDesc" name="RoleDesc" placeholder="Enter role description here" className="form-control" value={roleDefnForm.values.RoleDesc} onChange={roleDefnForm.handleChange} />
                                                        {
                                                            roleDefnForm.touched.RoleDesc && roleDefnForm.errors.RoleDesc ? (<p className="text-danger">{roleDefnForm.errors.RoleDesc}</p>) : null
                                                        }
                                                    </Paper>
                                                </Grid>
                                                <Grid item xs={11} sx={{}} >
                                                    <FormGroup>
                                                        <FormControl sx={{ pt: 2 }}>
                                                            <FormControlLabel disabled={disIsAllProcess || readOnly}
                                                                control={
                                                                    <Checkbox sx={{
                                                                        [`&, &.${checkboxClasses.checked}`]: {
                                                                            color: 'Check.main',
                                                                        },
                                                                    }} id="IsAllProcess" checked={isAllProcesses} name="IsAllProcess" onChange={AllProcessClicked}>
                                                                        Allow All Process?
                                                                    </Checkbox>
                                                                }
                                                                label={<Typography variant="body4" color="text.primary">All Process</Typography>}
                                                                style={{ color: '#61676B' }}
                                                            />
                                                        </FormControl>
                                                        <FormControl>
                                                            <FormControlLabel disabled={disIsProspectUser || readOnly}
                                                                control={
                                                                    <Checkbox sx={{
                                                                        [`&, &.${checkboxClasses.checked}`]: {
                                                                            color: 'Check.main',
                                                                        },
                                                                    }} id="IsProspect" name="IsProspect" checked={isProspectUser} name="IsProspect" onChange={IsProspectUserClicked}>
                                                                    </Checkbox>
                                                                }
                                                                label={<Typography variant="body4" color="text.primary">Guest Users</Typography>}
                                                                style={{ color: '#61676B' }}
                                                            />
                                                        </FormControl>
                                                    </FormGroup>
                                                </Grid>
                                                <Grid item xs={11} sx={{}} >
                                                    <Box sx={{ pb: 1, pt: 2 }}>
                                                        <Button disabled={readOnly} type="submit" variant="contained" size="large" style={{ boxShadow: 'none', border: 0, borderRadius: '20px', padding: '0.3rem 2.5rem', outline: 'none', }} >
                                                            SAVE
                                                        </Button>

                                                        {/*    <Button type="submit" variant="outlined" size="small" sx={{ padding: '0.4rem 3rem', border: 2, borderColor: '#616161' }}>*/}
                                                        {/*        <Typography variant="body2" color="#212121" sx={{ textTransform: 'none', fontWeight: 500 }} >Save</Typography>*/}
                                                        {/*    </Button>*/}
                                                    </Box>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                        <Grid item md={6} sx={{ pr: 2 }}>
                                            <Typography variant="body4" color="text.primary" sx={{ textTransform: 'none', paddingBottom: '4px', fontWeight: 500, pb: 2, lineHeight: 2 }} >Processes</Typography>
                                            <List dense disabled={true} sx={{ pt: 1, border: 1, borderColor: '#B3B9BC', borderRadius: '6px', bgcolor: 'background.paper', maxHeight: '350px', minHeight: '200px', overflow: 'auto' }} label="Templates">
                                                {userProcess !== null ?
                                                    userProcess.map((value) => {
                                                        const labelId = `checkbox-list-secondary-label-${value}`;
                                                        return (
                                                            <ListItem disabled={disProcessList || readOnly}
                                                                key={value.PrcsId}
                                                                secondaryAction={
                                                                    <Checkbox sx={{
                                                                        [`&, &.${checkboxClasses.checked}`]: {
                                                                            color: 'Check.main',
                                                                        },
                                                                    }} disabled={disProcessList}
                                                                        edge="end"
                                                                        inputProps={{ 'aria-labelledby': labelId }}
                                                                        key={value.PrcsId}
                                                                        checked={value.IsSelected ? value.IsSelected : false}
                                                                        onChange={handleToggle(value)}
                                                                    />
                                                                }
                                                                disablePadding
                                                            >
                                                                <ListItemButton>
                                                                    <ListItemText id={value.PrcsId} primary={<Typography variant="body4" color="text.primary" key={value.PrcsId}>{value.PrcsName}</Typography>} />
                                                                </ListItemButton>
                                                            </ListItem>
                                                        );
                                                    }) : ''}
                                            </List>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </form>
                    </Grid>
                    <Footer userId={userId} />
                </Paper>
            </Container>
        </React.Fragment>

        //<React.Fragment>
        //    <Container maxWidth="xl" sx={{}}>
        //        <OverlaySpinner disappear={isLoading} />
        //        <PostLoginMenu userId={userId} sessionId={sessionId} />
        //        <Paper className=""
        //            sx={{
        //                position: 'relative',
        //                backgroundColor: '#fff',
        //                color: '#fff',
        //                backgroundSize: 'cover',
        //                backgroundRepeat: 'no-repeat',
        //                backgroundPosition: 'center',
        //                pb: 12
        //            }}
        //        >
        //                <Grid item>
        //                <Box sx={{
        //                    pr: { xs: 3, md: 2 },
        //                    pl: { xs: 3, md: 2 }
        //                }}>
        //                        <Accordion spacing={0} elevation={0} expanded={expanded} onChange={handleAccordianChange} sx={{ p: 0, m: 0, width: '100%' }}>
        //                            <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header" sx={{ mr: 2 }}>
        //                            </AccordionSummary>
        //                            <AccordionDetails sx={{ pr: 3, pl: 3, pb: 0 }}>
        //                                <EnrollmentHeader />
        //                                <DrawerAppBar userId={userId} sessionId={sessionId} />
        //                        </AccordionDetails>
        //                        <Box sx={{ pr: 4, pl: 4 }}>
        //                            <Grid item xs container direction="row" spacing={2} sx={{ pt: 0, pb: 0 }}>
        //                                <Grid item md={9} sx={{ pr: 10 }} justifyContent="center">
        //                                    <Box sx={{ pt: 0, pr: 2, ml: 2 }}>
        //                                        <RoleDefBreadcrumb />
        //                                        <Typography sx={{ ml: 1, mr: 1, mb: 3, mt: 3, fontWeight: 500 }} variant="h6" color="text.secondary">
        //                                            A role is given to each person who can log into the website to define what they can and can’t do. User access is controlled by using processes.
        //                                        </Typography>
        //                                    </Box>
        //                                </Grid>
        //                            </Grid>
        //                        </Box>

        //                        </Accordion>
        //                </Box>
        //                </Grid>
        //                <Grid item xs={12}>
        //                    <Grid container sx={{ pt: 0, pl: 7 }}>
        //                        <Grid item md={8} justifyContent="center">
        //                            <Box>
        //                                <AlertControl Message={errorMsg} severity="error" color="error" icon={true} />
        //                                <AlertWithTitle Message={successMsg} variant="body1" severity="success" color="success" icon={true} Title={successMsgTitle} />
        //                            </Box>
        //                        </Grid>
        //                    </Grid>
        //                </Grid>                       
        //            <Grid item xs={12} sx={{pt: 2}}>
        //                <form id="frmRoleDefinition" onSubmit={roleDefnForm.handleSubmit} sx={{pl: 0, m: 0}}>
        //                    <Grid container sx={{ pt: 0, pl: 8 }}>
        //                        <Grid item md={8} sx={{pb: 1}}>
        //                            <Stack direction="row" alignItems="center">
        //                                <Box>
        //                                    <Tooltip arrow title="Back" onClick={() => handleBackClick()}>
        //                                        <span>
        //                                            <IconButton>
        //                                                <KeyboardBackspaceIcon sx={{ fontSize: 36 }} color="#ccc" />
        //                                            </IconButton>
        //                                        </span>
        //                                    </Tooltip>
        //                                </Box>
        //                                <Typography variant="h6" color="text.primary" sx={{ textTransform: 'none', fontWeight: 500, pl: 1 }} >{roleTypeId == -1 ? 'New Role' : 'Edit Role'}</Typography>
        //                            </Stack>
        //                        </Grid>
        //                        <Grid item md={8} justifyContent="center" sx={{}}>
        //                            <Grid container sx={{ borderTop: '3px solid #232f3e', borderRadius: '6px', pt: 5, pb: 8, ml: 1, pl: 5, pr: 5, boxShadow: '0 1px 3px 0 rgba(0,0,0,.3), 0 0 0 1px rgba(0,0,0,.04)'}}>
        //                                <Grid item md={6} >
        //                                    <Grid container justifyContent="center" sx={{ pr: 4}} alignItems="center">
        //                                        <Grid item xs={11} sx={{}} >
        //                                                <Paper elevation={0}>
        //                                                    <label htmlFor="RoleName" className="form-label">Role Name*</label>
        //                                                    <input type="text" id="RoleName" name="RoleName" placeholder="Enter role name here" className="form-control" value={roleDefnForm.values.RoleName} onChange={roleDefnForm.handleChange} />
        //                                                    {
        //                                                        roleDefnForm.touched.RoleName && roleDefnForm.errors.RoleName ? (<p className="text-danger">{roleDefnForm.errors.RoleName}</p>) : null
        //                                                    }
        //                                                </Paper>
        //                                            </Grid>
        //                                        <Grid item xs={11} sx={{ pt: 2 }}>
        //                                            <Paper elevation={0}>
        //                                                <label htmlFor="RoleDesc" className="form-label">Role Description</label>
        //                                                <input type="text" id="RoleDesc" name="RoleDesc" placeholder="Enter role description here" className="form-control" value={roleDefnForm.values.RoleDesc} onChange={roleDefnForm.handleChange} />
        //                                                {
        //                                                    roleDefnForm.touched.RoleDesc && roleDefnForm.errors.RoleDesc ? (<p className="text-danger">{roleDefnForm.errors.RoleDesc}</p>) : null
        //                                                }
        //                                            </Paper>
        //                                            </Grid>
        //                                        <Grid item xs={11} sx={{}} >
        //                                            <FormGroup>
        //                                                <FormControl sx={{pt: 2}}>
        //                                                    <FormControlLabel disabled={disIsAllProcess}
        //                                                        control={
        //                                                            <Checkbox id="IsAllProcess" checked={isAllProcesses} name="IsAllProcess" onChange={AllProcessClicked}>
        //                                                                Allow All Process?
        //                                                            </Checkbox>
        //                                                        }
        //                                                        label={<Typography variant="body2" color="textSecondary">All Process</Typography>}
        //                                                        style={{ color: 'black' }}
        //                                                        />
        //                                                </FormControl>
        //                                                <FormControl>
        //                                                    <FormControlLabel disabled={disIsProspectUser}
        //                                                        control={
        //                                                            <Checkbox id="IsProspect" name="IsProspect" checked={isProspectUser} name="IsProspect" onChange={IsProspectUserClicked}>
        //                                                            </Checkbox>
        //                                                        }
        //                                                        label={<Typography variant="body2" color="textSecondary">Guest Users</Typography>}
        //                                                        style={{ color: 'black' }}
        //                                                    />
        //                                                </FormControl>
        //                                            </FormGroup>
        //                                    </Grid>
        //                                        <Grid item xs={11} sx={{}} >
        //                                            <Box sx={{ pb: 1, pt: 4 }}>
        //                                                <Button type="submit" variant="outlined" size="small" sx={{ padding: '0.4rem 3rem', border: 2, borderColor: '#616161' }}>
        //                                                    <Typography variant="body2" color="#212121" sx={{ textTransform: 'none', fontWeight: 500 }} >Save</Typography>
        //                                                </Button>
        //                                            </Box>
        //                                        </Grid>
        //                                    </Grid>
        //                                </Grid>
        //                                <Grid item md={6} sx={{ pr: 2 }}>
        //                                    <Typography variant="body1" color="text.secondary" sx={{ textTransform: 'none', paddingBottom: '4px'}} >Processes</Typography>
        //                                    <List dense disabled={true} sx={{ border: 1, borderColor: '#7d8998', borderRadius: '6px', bgcolor: 'background.paper', maxHeight: '350px', minHeight: '200px', overflow: 'auto' }} label="Templates">
        //                                            {userProcess !== null ?
        //                                                userProcess.map((value) => {
        //                                                const labelId = `checkbox-list-secondary-label-${value}`;
        //                                                return (
        //                                                    <ListItem disabled={disProcessList}
        //                                                        key={value.PrcsId}
        //                                                        secondaryAction={
        //                                                            <Checkbox disabled={disProcessList}
        //                                                                edge="end"
        //                                                                inputProps={{ 'aria-labelledby': labelId }}
        //                                                                key={value.PrcsId}
        //                                                                checked={value.IsSelected ? value.IsSelected : false}
        //                                                                onChange={handleToggle(value)}
        //                                                            />
        //                                                        }
        //                                                        disablePadding
        //                                                    >
        //                                                        <ListItemButton>
        //                                                            <ListItemText id={value.PrcsId} primary={<Typography variant="body2" color="text.primary" key={value.PrcsId}>{value.PrcsName}</Typography>} />
        //                                                        </ListItemButton>
        //                                                    </ListItem>
        //                                                );
        //                                                }):''}
        //                                        </List>                                           
        //                                </Grid>                                            
        //                            </Grid>
        //                        </Grid>
        //                    </Grid>
        //                    </form>
        //                </Grid>
        //        </Paper>
        //        <Footer />
        //    </Container>
        //</React.Fragment>
    );
};

function RoleDefBreadcrumb() {
    return (
        <React.Fragment >
            <Box sx={{ borderBottom: '1px solid #cdcfd2', pt: 0, pb: 3 }}>
                <Typography variant="h5" color="text.primary" sx={{ lineHeight: 1, fontWeight: 400, letterSpacing: '-1px' }} >Role Details</Typography>
            </Box>

            {/*    <Box sx={{ ml: 1, mr: 2, border: '0px solid #000', pt: 0 }}>*/}
            {/*        <Typography variant="h4" color="text.primary" sx={{ borderBottom: '1px solid #ccc', lineHeight: 2.5, fontWeight: 500, letterSpacing: '-1px' }} >Role Details</Typography>*/}
            {/*    </Box>*/}
        </React.Fragment>
    );
}
export default RoleDefinition;