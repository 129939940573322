import { PaletteMode } from "@mui/material";
import { amber, deepOrange, grey } from "@mui/material/colors";

const theme = {
    palette: {
        primary: amber,
    },
};

export const getDesignTokens = (mode) => ({
    palette: {
        mode,
        primary: {
            main: 'rgb(31, 107, 230)'
        },
        secondary: {
            main: '#FFF'
        },
        primaryButton: {
            main: 'rgb(31, 107, 230)',
            contrastText: "#fff"
        },
        Icon: {
            main: grey[700]
        },
        Link: {
            main: '#002d72'
        },
        divider: '#BBBFBF',
        text: {
            primary: '#000',
            secondary: '#000',
        },
    },
    typography: {
        fontFamily: [
            '-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,Oxygen-Sans,Ubuntu,Cantarell,"Helvetica Neue",sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol"'
        ].join(','),
        body1: {
            lineHeight: 1.6,
        },
    },
    components: {
        // Name of the component
        MuiButton: {
            styleOverrides: {
                // Name of the slot
                root: ({ ownerState }) => ({
                    '&:hover': {
                        opacity: 0.5,
                    },
                    fontSize: '0.8rem',
                }),
            },
        },
        MuiContainer: {
            styleOverrides: {
                root: {
                    "&.MuiContainer-maxWidthXl": {
                        maxWidth: "1200px",
                    },
                    // Add other classes as needed
                },
            },
        },
        MuiTab: {
            styleOverrides: {
                root: {
                    "&.Mui-selected": {
                        color: 'rgb(31, 107, 230)',
                        borderRadius: "25px"
                    }
                }
            }
        },
    },
});

export default theme;