import React, { useState } from 'react';
import { useNavigate } from "react-router-dom";
import { useFormik } from 'formik';
import * as yup from 'yup';
import { PreLoginMenu } from './PreLoginMenu';
import { Typography, Link, Stack, Toolbar, Box, Alert, FormControl, TextField, Card, Divider } from '@mui/material';
import Container from '@mui/material/Container';
import { Paper } from '@mui/material';
import OverlaySpinner from './Controls/OverlaySpinner';
import Button from '@mui/material/Button';
import Footer from './Footer';
import Grid from '@mui/material/Grid';
import { CallServerAPI, IsError } from '../global/APIUtils';
import AlertControl from './Controls/AlertControl';
import { ErrorMessages } from '../common/ErrorMessages';
import { ServerAPICall, WriteToActivityLogWL } from '../global/Api';
//import clientlogo from '../assets/images/ssb-logo-lpl-blue.png';
//import clientlogo from '../assets/images/demo_bank_logo.jpg';
import ClientLogoHeader from './ClientLogoHead'
import { ActivityLogEvent } from '../common/AOBEnum';

const ForgetPassword = () => {

    const [isLoading, setIsLoading] = useState(false);
    const [submitting, setSubmitting] = useState(false);
    const [errorMsg, setErrorMsg] = useState('');
    const [email, setEmail] = useState('');

    let navigate = useNavigate();

    const forgetPwdForm = useFormik({
        initialValues: {
            EmailAddress: ''
        },
        validationSchema: yup.object({
            EmailAddress: yup.string()
                .email('Email - invalid expression.')
                .max(80, "Email - must be a string with a maximum length of 80")
                .required('Email Address - required.'),
        }),
        onSubmit: values => {
            setEmail(values.EmailAddress)
            handleSubmit(values);
        }
    });

    const handleSubmit = async (values) => {
        //API Call

        let resetPwdModel = {
            EmailAddress: values.EmailAddress
        };

        setErrorMsg('');

        const apiUrl = new URL(
            process.env.REACT_APP_FORGET_PASSWORD,
            process.env.REACT_APP_BASE_URL,
        );

        try {
            setIsLoading(true);

            //let endpoint = process.env.REACT_APP_BASE_URL  + process.env.REACT_APP_FORGET_PASSWORD;

            //let response = await CallServerAPI({ url: apiUrl.href, method: 'POST', headers: headers, body: resetPwdModel });

            let response = await ServerAPICall({ url: apiUrl.href, method: 'POST', payload: resetPwdModel, contentType: 'application/json', responseType: '' });

            if (response != null && response != undefined) {

                let error = IsError(response);

                if (error != 0) {
                    setErrorMsg(response.Errors[0].Message);
                }
                else {
                    if (response.meta.EmailSent === "Yes") {
                        await WriteToActivityLogWL(ActivityLogEvent.ForgotPassword, "Reset code for forgot password sent. User: '" + values.EmailAddress + "'");
                        navigate('/VerifyResetCode', { state: { emailAddress: values.EmailAddress, }, replace: true });
                    }
                    else {
                        setErrorMsg(ErrorMessages.UnknownError);
                    }
                }
            }
        }
        catch (err) {
            console.log(err);
            setErrorMsg(ErrorMessages.UnknownError);
        }
        finally {
            setIsLoading(false);
        }
    }

    //const resendEmail = (event) => {
    //    event.preventDefault();
    //    setSubmitting(false);

    //    let emailModel = {
    //        EmailAddress: email
    //    };

    //    handleSubmit(emailModel);
    //}

    const login = (event) => {
        event.preventDefault();
        navigate('/AppLogin', { state: {}, replace: true });
    }

    //const register = (event) => {
    //    event.preventDefault();
    //    history.push({
    //        pathname: '/Registration'
    //    })
    //}

    return (
        <React.Fragment>

            <Container maxWidth="lg">
                <OverlaySpinner disappear={isLoading} />
                <PreLoginMenu />
                <Paper>
                    <ClientLogoHeader />
                    {/*<Toolbar sx={{ borderBottom: 1, borderColor: '#f5f5f5', justifyContent: 'space-between', backgroundColor: '#fff', pt: 1, pb: 1, boxShadow: '0 1px 5px 0 rgba(32, 33, 36, 0.28)' }}>*/}
                    {/*    <Box sx={{ ml: 2, pt: 2, pb: 2 }}>*/}
                    {/*        <img src={clientlogo} height="42" loading="lazy" />*/}
                    {/*    </Box>*/}
                    {/*</Toolbar>*/}
                    {/*<Toolbar sx={{ borderBottom: 1, borderColor: 'divider', justifyContent: 'space-between', backgroundColor: '#fff', pt: 0, pb: 0 }}>*/}

                    {/*    <Box id="aobhead" sx={{ display: 'flex', alignItems: 'center', ml: 1, mt: 4, mb: 1, pb: 1 }}>*/}
                    {/*        <img src={clientlogo} height="44" loading="lazy" />*/}
                    {/*        */}{/*<ReactLogo />*/}
                    {/*    </Box>*/}
                    {/*</Toolbar>*/}
                    <Grid container spacing={0} sx={{ pt: 6 }}>
                        <Grid item md={12} sx={{ pr: 2, }} justifyContent="center">
                            {/*<Box sx={{display: 'flex', justifyContent: 'center'}}>*/}
                            <Card elevation={3} sx={{ mt: 1.5, width: '400px', mx: 'auto', border: 0, borderColor: '#16191f', borderRadius: '6px', pt: 4, pb: 12, pl: 4, pr: 4 }}>
                                <Typography variant="h4" color="text.secondary" sx={{ textAlign: 'center', fontSize: '18px', fontWeight: 500, pb: 1.5, borderBottom: '0px solid #898F94', letterSpacing: '0px' }}>Forgot your password?</Typography>
                                <Box sx={{ ml: 0, mr: 0, }}>
                                    <Divider variant="fullWidth" component="div" sx={{ background: '#BBBFBF' }} />
                                </Box>
                                <AlertControl Message={errorMsg} severity="error" color="error" icon={true} />
                                <Typography variant="body1" color="text.primary" sx={{ pl: 2, pr: 2, textAlign: 'center', pt: 2, fontWeight: 400 }}>
                                    Enter your registered email address and we'll send you an email with instructions to reset your password.
                                </Typography>
                                <Box sx={{ ml: 0, mr: 0, mt: 4 }}>
                                    <Divider variant="fullWidth" component="div" sx={{ background: '#BBBFBF' }} />
                                </Box>
                                <Box sx={{ pt: 3, display: 'flex', justifyContent: 'center' }}>
                                    <form onSubmit={forgetPwdForm.handleSubmit} id="forgetPassForm">
                                        {/*<Box sx={{ pt: 3, display: 'flex', justifyContent: 'center' }}>*/}
                                        <Box>
                                            <label htmlFor="EmailAddress" className="form-label">Email Address*</label>
                                            <input type="text" id="EmailAddress" style={{ width: '300px' }} name="EmailAddress" autoComplete="off" placeholder="Enter your email address here" className="form-control" value={forgetPwdForm.values.EmailAddress} onChange={forgetPwdForm.handleChange} />
                                            {
                                                forgetPwdForm.touched.EmailAddress && forgetPwdForm.errors.EmailAddress ? (<p className="text-danger">{forgetPwdForm.errors.EmailAddress}</p>) : null
                                            }
                                        </Box>
                                        <Box sx={{ mt: 4.5, display: 'flex', justifyContent: 'center' }}>
                                            <Button type="submit" variant="contained" size="large" style={{ borderRadius: '20px', padding: '0.4rem 1.5rem', textTransform: 'none' }} >
                                                Reset Password
                                            </Button>

                                            {/*    <Button type="submit" variant="outlined" size="small" sx={{ padding: '0.4rem 1.5rem', border: 2, borderColor: '#616161' }}>*/}
                                            {/*        <Typography variant="body2" color="#212121" sx={{ textTransform: 'none', fontWeight: 500 }} >Reset Password</Typography>*/}
                                            {/*    </Button>*/}
                                        </Box>
                                        <Box sx={{ pt: 2, display: 'flex', justifyContent: 'center' }}>
                                            <Link underline="hover" onClick={login} sx={{ textTransform: 'none', color: 'Link.main' }}>Back to Login</Link>
                                        </Box>
                                        {/*</Box>*/}
                                    </form>
                                </Box>
                            </Card>
                            {/*</Box>*/}
                        </Grid>
                        <Grid item md={2} sx={{pb: 6}}>
                        </Grid>
                    </Grid>
                    <Footer />
                </Paper>
            </Container>
        </React.Fragment>
    );
}

export default ForgetPassword;