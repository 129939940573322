import { createSlice } from '@reduxjs/toolkit';
import produce from 'immer'

export const sessionMgmnt = createSlice({
    name: 'sessionMgmnt',
    initialState: [],
    reducers: {
        addSession: (state, action) => {
            const todo = {
                key: action.payload.key,
                value: action.payload.value
            };
            state.push(todo);
        },
        modifySession: (state, action) => {
            const index = state.findIndex((todo) => todo.key === action.payload.key);
            if (index !== -1) {
                state[index].value = action.payload.value;
            }
        },
        deleteSession: (state, action) => {
            return state.filter((todo) => todo.key !== action.payload.key);
        },
    }
});

export const { addSession, modifySession, deleteSession } = sessionMgmnt.actions;

export default sessionMgmnt.reducer;

/*
 * { key: 'AppName', value: 'Account OnBoarding' }
initialState:[
        //{key:s, value:'abc'},
        //{key:'userId', value:0},
        //{key:'userName', value:'abc'}
    ],
*/