import { Power } from 'react-bootstrap-icons';
import React, { useEffect, useState } from 'react';
import { Box, Divider } from '@mui/material';
import Toolbar from '@mui/material/Toolbar';
import clientlogo from '../assets/images/demo_bank_logo.jpg';
import { ServerAPICall } from '../global/Api';
import { ErrorMessages } from '../common/ErrorMessages';

const ClientLogoHeader = () => {

    const [imageUrl, setImageUrl] = useState(null);

    const loadLoginHeroImage = async () => {
        try {

            let apiImageUrl = new URL(
                process.env.REACT_APP_GET_BANK_RESOURCE,
                process.env.REACT_APP_BASE_URL,
            );

            apiImageUrl.searchParams.set('resourceType', 1);

            let response = await ServerAPICall({ url: apiImageUrl.href, method: 'GET', contentType: 'application/json', responseType: 'blob' });

            if (response) {
                if (response.type == 'image/jpeg') {
                    setImageUrl(response);
                }
                else {
                    setImageUrl(null);
                }
            }
        }
        catch (err) {
            console.error(ErrorMessages.UnknownError);
        }
        finally {
        }
    };

    useEffect(() => {
        loadLoginHeroImage();
    }, []);

    return (
        <React.Fragment>
            <Toolbar sx={{ borderBottom: '1px solid rgba(81, 81, 81, 1)', pt: 1, pb: 1, boxShadow: '0 2px 2px 0 #9EA0A9' }}>
                <Box sx={{ ml: 1, pt: 2, pb: 1 }}>
                    {imageUrl ?
                        <img src={URL.createObjectURL(imageUrl)} height="40" loading="lazy" />
                        :
                        null
                    }
                    {/*<img sx={{}} src={clientlogo} height="38" loading="lazy" />*/}
                </Box>
                {/*<Divider variant="fullWidth" component="div" sx={{ background: '#BBBFBF', width: '100%' }} />*/}
            </Toolbar>

        </React.Fragment>
    );
};

export default ClientLogoHeader;