import React, { useState, useRef, useEffect } from 'react';
import ReactHtmlParser from 'react-html-parser';
import { Box, Typography, Paper, Grid, Container, Divider, Card, CardMedia, Stack, Link, Snackbar } from '@mui/material';
import profimg from '../assets/images/profimg.jpg';
import DownloadIcon from '@mui/icons-material/Download';
import { GetAttachedFile, GetApplicantImage } from './EnrollmentHelper';
import { ErrorMessages } from '../common/ErrorMessages';
import AlertControl from '../components/Controls/AlertControl';
import dayjs from 'dayjs';
import { goToTop } from '../global/Helpers'
import { WriteToActivityLog } from './Api';
import { ActivityLogEvent } from '../common/AOBEnum';
import { amber, deepOrange, grey } from "@mui/material/colors";

const AppViewControl = (props) => {

    const [enrollData, setEnrollData] = useState(JSON.parse(props.enrollData).slice() || null);
    const [enrmntid, setEnrmntid] = useState(props.enrmntid || -1);
    const [errorMsg, setErrorMsg] = useState(null);
    const [imageUrl, setImageUrl] = useState(null);
    const [isLoading, setIsLoading] = useState(false);

    const [userId, setUserId] = useState(props.userId);
    const [sessionId, setSessionId] = useState(props.sessionId);

    useEffect(() => {
        //loadApplicantImage();
    }, []);

    const GetHeader = ({ item, index }) => {

        if (item.className === "no-space") {
            return (
                <React.Fragment>
                    <div>
                        <Divider component="div" sx={{ mt: 4, mb: 4, }} />
                        {/*{item.label}*/}
                    </div>
                </React.Fragment>
            );
        }
        else {
            return (
                <React.Fragment>
                    <Divider component="div" sx={{ mt: 4, mb: 4, }} />
                    <Grid item xs={12} sx={{ pb: 0 }}><Typography sx={{ fontWeight: 600, }} variant="h4" key={"hdr" + "_" + index}>{item.label}</Typography></Grid>
                </React.Fragment>
            );
        }
    }

    const GetDateControlValue = ({ item, values, fieldValue, fieldType, label, enrmntid, session, dispatch }) => {

        let d; // = new Date("2022-03-25")
        let s = '';

        if (item.userData != null && item.userData != undefined && item.userData.length > 0) {
            s = item.userData[0];
            //s = new Date(item.userData[0]);
            //s = d.toDateString();
        }

        return (
            <React.Fragment>
                <Grid item xs container direction="row" sx={{ mt: 2.5, mb: 1 }}>
                    <Grid item xs={12} sx={{ pr: 4 }}>
                        <Typography variant="body1" sx={{ fontWeight: 500 }}>{label}</Typography>
                    </Grid>
                    <Grid item xs={12} sx={{ pl: 0 }}>
                        <Typography variant="body1">{s}</Typography>
                    </Grid>
                </Grid>
            </React.Fragment>
        );
    };

    const GetOtherControlValue = ({ item, values, fieldValue, fieldType, label, enrmntid, session, dispatch }) => {
        return (
            <React.Fragment>
                <Grid item xs container direction="row" sx={{ mt: 2.5, mb: 1 }}>
                    <Grid item xs={12} sx={{ pr: 4 }}>
                        <Typography variant="body1" sx={{ fontWeight: 500 }}>{label}</Typography>
                    </Grid>
                    <Grid item xs={12} sx={{ pl: 0 }}>
                        <Typography variant="body1">{item.userData}</Typography>
                    </Grid>
                </Grid>
            </React.Fragment>
        );
    };

    const GetFileControlValue = ({ item, values, fieldValue, fieldType, label, enrmntid, userId, sessionId }) => {

        const [open, setOpen] = React.useState(false);
        const [errorMsg, setErrorMsg] = useState(null);

        const handleClick = () => {
            setOpen(true);
        };

        const handleClose = (event, reason) => {
            setOpen(false);
        };

        const handleDownloadFile = async (fileName, controlName, enrollmentid) => {
            try {

                const apiUrl = new URL(
                    process.env.REACT_APP_DOWNLOAD_ENROLLMENT_DOCUMENT,
                    process.env.REACT_APP_BASE_URL,
                );
                apiUrl.searchParams.set('enrmntId', `${parseInt(enrollmentid)}`);
                apiUrl.searchParams.set('fname', `${fileName}`);
                apiUrl.searchParams.set('tag', `${controlName}`);
                apiUrl.searchParams.set('attachmentId', '-1');

                await GetAttachedFile(apiUrl.href, fileName, userId, sessionId);

                await WriteToActivityLog(ActivityLogEvent.ApplicationDashboard, "Downloaded file attachment from application. Application name: '" + props.applicantName + "'. File name: '" + fileName + "'", userId, sessionId);
            }
            catch (ex) {
                setErrorMsg(ErrorMessages.UnknownError);
            }
            finally {
            }
        };


        return (
            <React.Fragment>
                <Grid item xs container direction="row" sx={{ mt: 2.5, mb: 1 }}>
                    <Grid item xs={12} sx={{}}>
                        <AlertControl Message={errorMsg} severity="error" color="error" icon={true} />
                    </Grid>
                    <Grid item xs={12} sx={{ pr: 4 }}>
                        <Typography variant="body1" sx={{ fontWeight: 500 }}>{label} </Typography>
                    </Grid>
                    <Grid item xs={12} sx={{ pl: 0, pt: 1 }}>
                        {
                            (item.value && item.value !== '') ?
                                <Link component="button" sx={{color: 'Link.main'}} underline="hover" onClick={() => handleDownloadFile(item.value, item.name, enrmntid)}>
                                    <Stack direction="row">
                                        <DownloadIcon width="24px" height="24px" color="#ccc" />
                                        <Typography variant="body1" sx={{ fontWeight: 500 }}>{item.value}</Typography>
                                    </Stack>
                                </Link>
                                //<a className="col-8 m-b-0" controlname={item.name} onClick={handleDownloadFile} filename={item.value} title={item.value}><i className="fa fa-download"></i>&nbsp;{item.value}</a>
                                : ''
                        }
                    </Grid>
                </Grid>
            </React.Fragment>
        );
    }

    const GetApplControlValue = ({ item, values, fieldValue, fieldType, label, enrmntid, session, dispatch }) => {

        if (!fieldValue)
            fieldValue = [{}];
        if (!values)
            values = [{}];

        let selvalue = fieldValue.map(item => (item));

        let valuelst = values.filter((item) => { return item.value === selvalue.toString() });

        let ddval = valuelst.map((item) => { return item.label })

        if (fieldType === "checkbox-group") {
            let selectedData = '';

            return (<React.Fragment>
                <Grid item xs container direction="row" sx={{ mt: 3, mb: 1 }}>
                    <Grid item xs={12} sx={{ pr: 4 }}>
                        <Typography variant="body1" sx={{ fontWeight: 500 }}>{label}</Typography>
                    </Grid>
                    {
                        (fieldValue !== null && fieldValue.length > 0) ?
                            (
                                fieldValue.forEach((value, key) => {
                                    let filterData = values.filter((item) => { return item.value === value.toString() })
                                    filterData.length > 0 ?
                                        selectedData = selectedData ? selectedData + (filterData ? ', ' + filterData[0].label : '') : (filterData ? filterData[0].label : '')
                                        : selectedData = selectedData + ''
                                })
                            ) : ''
                    }
                    <Grid item xs={12} sx={{ pl: 0 }}>
                        <Typography variant="body1">{selectedData}</Typography>
                    </Grid>
                </Grid>
            </React.Fragment>);
        }
        else
            return (
                <React.Fragment>
                    <Grid item xs container direction="row" sx={{ mt: 3, mb: 1 }}>
                        <Grid item xs={12} sx={{ pr: 4 }}>
                            <Typography variant="body1" sx={{ fontWeight: 500 }}>{label}</Typography>
                        </Grid>
                        <Grid item xs={12} sx={{ pl: 0 }}>
                            <Typography variant="body1">{(ddval !== null && ddval.length > 0) ? ddval[0] : ''}</Typography>
                        </Grid>
                    </Grid>
                </React.Fragment>
            );
    }

    return (
        <React.Fragment >

            <Container maxWidth="lg" sx={{}}>
                <Box sx={{ border: 2, borderColor: '#ccc', mt: 4, mb: 5, pl: 10, pr: 10, pt: 3, pb: 6}}>
                <Grid item xs={12} sx={{}}>
                    <AlertControl Message={errorMsg} severity="error" color="error" icon={true} />
                    <Typography variant="h6" sx={{ textAlign: 'center', fontWeight: 400, lineHeight: 1.5, }}>
                        ACCOUNT OPENING APPLICATION
                    </Typography>
                    <Typography variant="h6" sx={{ textAlign: 'center', fontWeight: 400 }}>
                        {'For a new ' + props.acctType}
                    </Typography>
                        <Divider component="div" sx={{ mt: 2, mb: 5, background: grey[300] }} />
                </Grid>
                <Grid item xs={12} sx={{}}>
                    <Grid item xs container direction="row">
                        <Grid item xs={8} sx={{}}>
                                <Typography variant="body1" sx={{ fontWeight: 500, lineHeight: 1.5 }}>
                                    Name 
                                </Typography>
                                <Typography variant="body1" sx={{ fontWeight: 400, lineHeight: 1.5 }}>
                                    {props.applicantName}
                                </Typography>
                                <Typography variant="body1" sx={{ fontWeight: 500, lineHeight: 1.5, pt: 2 }}>
                                    Email Address
                                </Typography>
                                <Typography variant="body1" sx={{ fontWeight: 400, lineHeight: 1.5 }}>
                                    {props.applicantEmail}
                                </Typography>
                                <Typography variant="body1" sx={{ fontWeight: 500, lineHeight: 1.5, pt: 2 }}>
                                    Joint Applicant
                                </Typography>
                                <Typography variant="body1" sx={{ fontWeight: 400, lineHeight: 1.5 }}>
                                    {props.JointOwnerName == '' ? 'NA' : props.JointOwnerName}
                                </Typography>
                                <Typography variant="body1" sx={{ fontWeight: 500, lineHeight: 1.5, pt: 2 }}>
                                    Joint Applicant's Email
                                </Typography>
                                <Typography variant="body1" sx={{ fontWeight: 400, lineHeight: 1.5 }}>
                                    {props.JointOwnerEmail == '' ? 'NA' : props.JointOwnerEmail}
                                </Typography>
                        </Grid>
                        <Grid item xs={4} sx={{}}>
                                <Stack direction="column" justifyContent="center" alignItems="center">
                                <img style={{ width: '170px' }} src={props.applicantImage ? URL.createObjectURL(props.applicantImage) : props.avtarImage} alt="Prifile Picture" className="prof-pic-prvw" />
                                    <Typography variant="body1" sx={{ textAlign: 'center', fontWeight: 400, lineHeight: 1.5, pt: 2 }}>
                                    Photograph
                                </Typography>
                            </Stack>
                        </Grid>
                    </Grid>
                </Grid>
                        {
                            enrollData.map((e, index) => (
                                (e.type === "header") ? //Header & Line Break Control
                                    (
                                        <GetHeader item={e} index={index} key={index} />
                                    )
                                    :
                                    (e.type === "button") ? '' //Ignore Control
                                        : (e.type === "radio-group" || e.type === "select" || e.type === "checkbox-group") ? //For LookUp type Control
                                            <GetApplControlValue item={e} values={e.values} key={index} fieldValue={e.userData} fieldType={e.type} label={e.label} enrmntid={enrmntid} session={props.session} dispatch={props.dispatch} />
                                            : (e.type === "file") ?
                                                <GetFileControlValue item={e} values={e.values} key={index} fieldValue={e.userData} fieldType={e.type} label={e.label} enrmntid={enrmntid} userId={userId} sessionId={sessionId} />
                                            : (e.type === "paragraph") ?
                                                    
                                        <div key={"divParagraph_" + index} id={"divParagraph_" + index}
                                            dangerouslySetInnerHTML={{
                                                __html: e.label
                                            }}
                                                        /> 
                                            : (e.type === "date") ?
                                                <GetDateControlValue item={e} values={e.values} key={index} fieldValue={e.userData} fieldType={e.type} label={e.label} enrmntid={enrmntid} session={props.session} dispatch={props.dispatch} />
                                            :
                                                    (
                                                        <GetOtherControlValue item={e} values={e.values} key={index} fieldValue={e.userData} fieldType={e.type} label={e.label} enrmntid={enrmntid} session={props.session} dispatch={props.dispatch} />
                                            )
                                )
                            )
                    }
                </Box>
            </Container>
        </React.Fragment>
    );
};

export default AppViewControl;