import React, { useEffect, useState } from 'react';
import { useNavigate, useLocation } from "react-router-dom";
import { useSelector, useDispatch } from 'react-redux';
import { ApiHelper } from '../customFunctions/apiHelper';
import { ArrowRight, BorderTop, Download, FileEarmarkPdfFill, Right, Check2Circle } from 'react-bootstrap-icons';
import axios from 'axios';
import Modal from 'react-bootstrap/Modal';
import AlertText from './Controls/AlertText';
import LoaderControl from './Controls/LoaderControl';
import { Container, Divider, Box, Button, Typography, Grid, Toolbar, Paper } from '@mui/material';
import OverlaySpinner from './Controls/OverlaySpinner';
import PostLoginMenu from './PostLoginMenu'
import Footer from './Footer';
import EnrollmentHeader from './EnrollmentHeader'
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { DrawerAppBar } from './AppNavbar'
import { ErrorMessages } from '../common/ErrorMessages';
import { IsError, CallAPI } from '../global/APIUtils';
import AlertControl from './Controls/AlertControl';
import AlertWithTitle from './Controls/AlertWithTitle';
import { DocuSignStatus } from '../common/AOBEnum';
import { APICall } from '../global/Api';

export default function DocuSign() {
    const sessions = useSelector((state) => state.sessionMgmnt);
    const dispatch = useDispatch();
    const [show] = useState(true);
    const [loading, setLoading] = useState(false);
    const [envolopeId, setEnvolopeId] = useState('');    
    const { search } = useLocation();
    let navigate = useNavigate();
    let searchParams = new URLSearchParams(search);
    const [statusMsg, setStatusMsg] = useState('');
    const [statusDetailMsg, setStatusDetailMsg] = useState('');

    const [signState, setSignState] = useState('');
    const [signStatus, setSignStatus] = useState('');
    const [expanded, setExpanded] = useState(true);
    const [errorMsg, setErrorMsg] = useState('');
    const [userId, setUserId] = useState(-1);
    const [sessionId, setSessionId] = useState('');

    useEffect(() => {

        setSignState(searchParams.get("state"));
        setSignStatus(searchParams.get("event"));

        let fileName = searchParams.get("fname");
        let enrmntId = searchParams.get("enid");
        let attachmentId = searchParams.get("aid");
        let uid = searchParams.get("uid");
        let sid = searchParams.get("dis");

        setUserId(uid);
        setSessionId(sid);

        //setEnvolopeId(localStorage.getItem("EnvolopeId"));
        if (signStatus === 'cancel') {
            setStatusMsg('Not signed');
            setStatusDetailMsg('You have opted for signing the \'' + fileName + '\' document later. Kindly sign the document at your earliest convenience. We will not be able to process your application further if document signing is not completed');
            SaveDocuSignStatus(enrmntId, attachmentId, fileName, DocuSignStatus.Cancelled, uid, sid);
        }
        else if (signStatus === 'decline') {
            SaveDocuSignStatus(enrmntId, attachmentId, fileName, DocuSignStatus.Declined, uid, sid);
            setStatusMsg('Sign declined');
            setStatusDetailMsg('You have not signed the \'' + fileName + '\' document. Kindly sign the document at your earliest convenience. We will not be able to process your application further if document signing is not completed');
        }
        if (signStatus === 'signing_complete') {
            saveSignedDoc(enrmntId, attachmentId, uid, sid);
        }

    //    window.addEventListener('beforeunload', refreshDashboard)

    //    return () => {
    //        window.removeEventListener('beforeunload', refreshDashboard)
    //    }
    }, [signStatus]);

    //const refreshDashboard = (event) => {
    //    window.localStorage.setItem("isDocSignComplete", "1");
    //}

    const handleAccordianChange = () => {
        if (expanded) {
            setExpanded(false);
        }
        else {
            setExpanded(true);
        }
    };

    const SaveDocuSignStatus = async (enrmntId, attachmentId, fileName, statusId, userId, sessionId) => {

        try {

            setLoading(true);

            const apiUrl = new URL(
                process.env.REACT_APP_UPDATE_DOCUSIGN_STATUS,
                process.env.REACT_APP_BASE_URL,
            );

            const inputJson = {
                enrmntId: parseInt(enrmntId),
                AttchmntId: parseInt(attachmentId),
                SignStsId: statusId
            };

            //let response = await CallAPI({ url: apiUrl.href, method: 'POST', body: inputJson }, userId, '');
            let response = await APICall({ url: apiUrl.href, method: 'POST', payload: inputJson, contentType: 'application/json', responseType: '' }, userId, sessionId);

            if (response !== null && response !== undefined) {
                let error = IsError(response);

                if (error != 0) {
                    setErrorMsg(response.Errors[0].Message);
                    return;
                }
                console.log(response.meta.Updated);
                if (response.meta.Updated !== "1") {
                    setErrorMsg(ErrorMessages.UnknownError);
                }
            }
        }
        catch (err) {
            setErrorMsg(ErrorMessages.UnknownError);
        }
        finally {
            setLoading(false);
        }

        return;
    }

    const closeDocSignWindow = () => {
        //setLoading(true);

        window.localStorage.setItem("isDocSignComplete", "1");
        window.close();
        
        //setShow(false);
    }

    //const downloadDoc = () => {
    //    //alert("Download");
    //    //var EnvId = envolopeId; //document.getElementById("txtEnvId").value;
    //    setLoading(true);
    //    let apiUrl = process.env.REACT_APP_BASE_URL + process.env.REACT_APP_API_STAGE + process.env.REACT_APP_VIEW_SIGN_DOCU;
       
    //    try {
            
    //        axios({
    //            url: apiUrl, //your url
    //            method: 'GET',
    //            responseType: 'blob', // important
    //            headers: {
    //                'Content-Type': 'application/pdf',
    //                'x-api-key': process.env.REACT_APP_API_KEY,
    //                'Accept': 'application/pdf'
    //            }
    //        }).then((response) => {

    //            var binaryData = [];
    //            binaryData.push(response.data);

    //            let blob = new Blob(binaryData, { type: 'application/pdf' })
    //            // create file link in browser's memory
    //            const href = URL.createObjectURL(blob);

    //            // create "a" HTML element with href to file & click
    //            const link = document.createElement('a');
    //            link.href = href;
    //            link.setAttribute('download', 'file.pdf'); //or any other extension
    //            document.body.appendChild(link);
    //            link.click();

    //            // clean up "a" element & remove ObjectURL
    //            document.body.removeChild(link);
    //            URL.revokeObjectURL(href);
    //            setLoading(false);
    //        });

    //        //alert(selectedFile.name + ' uploaded successfully');
    //    } catch (ex) {
    //        console.log(ex);
    //        setLoading(false);
    //    }
    //}

    const saveSignedDoc = async (enrmntId, attachmentId, userId, sessionId) => {

        try {

            setLoading(true);

            const apiUrl = new URL(
                process.env.REACT_APP_SAVE_SIGNED_DOC,
                process.env.REACT_APP_BASE_URL,
            );

            const inputJson = {
                enrmntId: parseInt(enrmntId),
                AttchmntId: parseInt(attachmentId)
            };

            //let response = await CallAPI({ url: apiUrl.href, method: 'POST', body: inputJson }, userId, sessionId);
            let response = await APICall({ url: apiUrl.href, method: 'POST', payload: inputJson, contentType: 'application/json', responseType: '' }, userId, sessionId);

            if (response !== null && response !== undefined) {
                let error = IsError(response);

                if (error != 0) {
                    setErrorMsg(response.Errors[0].Message);
                    return;
                }
                console.log(response.meta.Updated);
                if (response.meta.Saved !== "1") {
                    setErrorMsg(ErrorMessages.UnknownError);
                }
                else {
                    setStatusMsg('Sign completed');
                    setStatusDetailMsg('Thank you for signing the document');
                }
            }
        }
        catch (err) {
            setErrorMsg(ErrorMessages.UnknownError);
        }
        finally {
            setLoading(false);
        }

        return;
    }

    //const updateSignedStatus = (docStatus) => {

    //    let headers = {
    //        'Content-Type': 'application/json'
    //    };

    //    let docStatusId = 0;

    //    if (docStatus === 'cancel') {
    //        docStatusId = DocuSignStatus.Cancel;
    //    }
    //    else if (docStatus === 'decline') {
    //        docStatusId = DocuSignStatus.Decline
    //    }
    //    else if (docStatus === 'signing_complete') {
    //        docStatusId = DocuSignStatus.Complete;
    //    }
    //    else if (docStatus === 'session_timeout') {
    //        docStatusId = DocuSignStatus.Timeout;
    //    }
    //    else {
    //        docStatusId = DocuSignStatus.Initiate;
    //    }

    //    let enrmntId = window.localStorage.getItem("enrollmentId");
    //    let attchmntId = window.localStorage.getItem("attchmntId");
    //    let usrId = window.localStorage.getItem("loginUsrId");

    //    let bodyContent = {
    //        "EnrmntId": parseInt(enrmntId),
    //        "AttchmntId": parseInt(attchmntId),
    //        "SignerUserId": parseInt(usrId),
    //        "SignStatus": docStatusId
    //    }

    //    setLoading(true);
    //    ApiHelper({ url: process.env.REACT_APP_SAVE_SIGN_DOCUMENT_STATUS, method: 'POST', headers: headers, body: bodyContent, sessions: sessions, dispatch: dispatch })
    //        .then(resposnse => {
    //            setLoading(false);
    //        });
    //};

    const ShowProspectDashboard = (e) => {
        navigate('/ProspectDashboard', { state: { userId: userId, sessionId: sessionId }, replace: true });
    };

    return (
        <React.Fragment>
            <Container maxWidth="xl">
                <OverlaySpinner disappear={loading} />
                <PostLoginMenu />
                <Paper
                    sx={{
                        position: 'relative',
                        backgroundSize: 'cover',
                        backgroundRepeat: 'no-repeat',
                        backgroundPosition: 'center',
                        pb: { xs: 7, sm: 10 },
                    }}
                >
                    <Grid item>
                        <Box sx={{
                            pr: { xs: 3, md: 0 },
                            pl: { xs: 3, md: 0 }
                        }}>
                            <Accordion spacing={0} elevation={0} expanded={expanded} onChange={handleAccordianChange} sx={{ p: 0, m: 0, width: '100%' }}>
                                <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header" sx={{ mr: 2 }}>
                                </AccordionSummary>
                                <AccordionDetails sx={{ pl: { xs: 3, md: 6 }, pr: { xs: 3, md: 6 }, }}>
                                    <EnrollmentHeader />
                                </AccordionDetails>
                            </Accordion>
                        </Box>
                    </Grid>
                    <Grid container justifyContent="center">
                        <Grid item md={7} sx={{ mt: 5 }}>
                            <Box sx={{ ml: { xs: 3, md: 5 }, mr: { xs: 3, md: 5 }, mt: 2 }}>
                                <AlertControl Message={errorMsg} severity="error" color="error" icon={true} />
                            </Box>
                            <Typography component="h2" variant="h4" color="text.primary" align="center" sx={{ pt: 1 }} >
                                {statusMsg}
                            </Typography>
                            <Typography component="h2" variant="body1" color="text.primary" sx={{ pl: 1, pr: 1, mt: 2, pt: 2, pb: 2, textAlign: 'center', backgroundColor: '#f7f7fa' }} >
                                {statusDetailMsg}
                            </Typography>
                            <Box textAlign='center' sx={{ pt: 3, pb: 3,}}>
                                <Button type="submit" variant="contained" size="large" style={{ padding: '0.6rem 3.5rem', textTransform: 'none', }} onClick={(e) => ShowProspectDashboard(e)}>
                                    <Typography variant="body1">Ok</Typography>
                                </Button>
                            </Box>

                        </Grid>
                    </Grid>
                </Paper>
                <Footer />
            </Container>
        </React.Fragment>
    );
}

//export default DocuSign;