import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import Modal from 'react-bootstrap/Modal';
import axios from 'axios';
import AlertText from '../../Controls/AlertText';

const reader = new FileReader();

export function PicUploadModal(props) {
    const [show] = useState(false);
    const [invalidImage, setInvalidImage] = useState(true);
    const [alertMsg, setAlertMsg] = useState('');
    const [isSuccessMsg, setIsSuccess] = useState(false);
    const [imageUrl, setImageUrl] = useState(null);
    const [picUpload, setPicUpload] = useState(null);
    const [loginUsrId, setLoginUsrId] = useState(parseInt(null));

    let sessions = useSelector((state) => state.sessionMgmnt);

    useEffect(() => {
        setAlertMsg("");
        setIsSuccess(false);
        let loguserId = null;
        sessions.map((sn) => {
            if (sn.key === 'LoginUserId') {
                setLoginUsrId(parseInt(sn.value));
                loguserId = parseInt(sn.value);
            }
        });

        if (!loguserId) {
            window.location.href = '/?status=session_out';
        }

    }, []);
    const handleOnExitClick = () => {
        props.onExit();
    }

    const onChangeFile = (event) => {
        const imageFile = event.target.files[0];

        if (!imageFile) {
            //setInvalidImage("Please select image.");
            setAlertMsg("Please select image.");
            setIsSuccess(false);
            setInvalidImage(true);
            return false;
        }

        if (!imageFile.name.match(/\.(jpg|jpeg|png)$/)) {
            //setInvalidImage("Please select valid image.");
            setAlertMsg("Please select valid image file.");
            setIsSuccess(false);
            setInvalidImage(true);
            return false;
        }

        
        reader.onload = (e) => {
            const img = new Image();
            img.onload = () => {
                ///this.setState({ selectedFile: imageFile, invalidImage: null });
                setAlertMsg("");
                setIsSuccess(true);
                setPicUpload(imageFile);
                setInvalidImage(false);
            };
            img.onerror = () => {
                //this.setState({ invalidImage: 'Invalid image content.' });
                setAlertMsg("Invalid image content.");
                setIsSuccess(false);
                setInvalidImage(true);
                return false;
            };
            //debugger
            //console.log(e.target.result);
            setImageUrl(e.target.result);
            img.src = e.target.result;
        };
        reader.readAsDataURL(imageFile);

        
    }
    //const verifyImage = (img) => {
    //    return new Promise((resolve, reject) => {
    //        const reader = new FileReader();
    //        reader.addEventListener('load', event => {
    //            let picFile = event.target
    //            let imgNew = new Image();
    //            imgNew.addEventListener('load', () => {
    //                resolve(reader.result);
    //            })
    //            imgNew.addEventListener('error', () => {
    //                reject();
    //                setAlertMsg("Please select valid image.");
    //                setIsSuccess(false);
    //                return false;
    //            })
    //            imgNew.src = picFile.result
    //        })
    //        reader.readAsDataURL(img)
    //    })
    //}
    const handleUploadPic = (event) => {
        if (!invalidImage) {
            const formData = new FormData();
            formData.append("UserId", parseInt(loginUsrId));

            formData.append(`ProfilePic`, picUpload)

            //Need to add Authorization
            const headers = {
                "x-api-key": process.env.REACT_APP_API_KEY
            };

            let endPoint = process.env.REACT_APP_BASE_URL + process.env.REACT_APP_PROFILE_PICTURE_LOAD;

            try {
                setAlertMsg("Uploading...");
                setIsSuccess(true);

                axios.post(endPoint, formData, {
                    headers: headers
                }).then((response) => {
                    if (response.data === "Successfully Updated." || response.data === "Successfully Inserted.") {
                        setAlertMsg(response.data);
                        setIsSuccess(true);
                        //props.onHide();
                    } else {
                        setAlertMsg(response.data.Message || 'Upload failed!');
                        setIsSuccess(false);
                    }
                });

            } catch (ex) {
                console.log(ex);
            }
        }
    }

    return (
        <Modal show={show} animation={false} scrollable={true}
            {...props}
            size="xl"
            aria-labelledby="contained-modal-title-vcenter"
        >
            <Modal.Header>
                <Modal.Title id="contained-modal-title-vcenter  modal-header" className="m-s-5 m-e-10 m-t-10">
                    {props.title}
                </Modal.Title>
                <button type="button" className="btn-close m-e-9" data-bs-dismiss="modal" aria-label="Close" onClick={handleOnExitClick}></button>
            </Modal.Header>
            <Modal.Body className="m-s-5 m-e-5">
                <div>
                    {alertMsg ?
                        <AlertText Message={alertMsg} isSuccess={isSuccessMsg} />
                        : ''
                    }
                    <p>Choose Image</p>
                    <div style={{ marginBottom: 10 }}>
                        <input type="file" onChange={onChangeFile} accept=".jpg,.jpeg,.png"/>
                    </div>

                    {!invalidImage ?
                        <div>
                            <p className="title" style={{ marginTop: 30 }}>Preview</p>
                            {imageUrl && <img src={imageUrl} alt="Uploaded File" height="100" width="100" />}
                        </div>
                        : 'File Formats: jpg, jpeg & png.'
                    }
                </div>
            </Modal.Body>
            <Modal.Footer>
                <button onClick={handleUploadPic} type="button" className="btn btn-secondary" aria-label="Close"><i className="fa fa-upload px-2"></i>Upload</button>
                <button onClick={handleOnExitClick} type="button" className="btn btn-secondary" aria-label="Close"><i className="fa fa-times px-2"></i>Close</button>
            </Modal.Footer>
        </Modal>
    );
}