import React, { useCallback, useState, useEffect, useMemo, useRef, } from 'react';
import { useNavigate, useLocation } from "react-router-dom";
import PostLoginMenu from './PostLoginMenu'
import OverlaySpinner from './Controls/OverlaySpinner';
import Footer from './Footer';
import { QueryClient, QueryClientProvider, useInfiniteQuery, } from '@tanstack/react-query';
import { useSelector, useDispatch } from 'react-redux';
import { CallAPI, IsInRole } from '../global/APIUtils';
import { Virtualizer } from '@tanstack/react-virtual';
import { Grid, Stack, Paper, IconButton, Box, Tooltip, Menu, Typography, MenuItem, InputBase, Card, CardContent, CardMedia, bull, CardActions, Button, Divider } from '@mui/material';
import Container from '@mui/material/Container';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import EnrollmentHeader from './EnrollmentHeader'
import { DrawerAppBar } from './AppNavbar'
import pwdimg from '../assets/images/45357.jpg';
import Image from 'material-ui-image'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import MaterialReactTable, { MRT_ShowHideColumnsButton, MRT_ToggleGlobalFilterButton, MRT_ToggleFiltersButton, } from 'material-react-table';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import NoteAltIcon from '@mui/icons-material/NoteAlt';
import profimg from '../assets/images/profimg.jpg';
import { UserListSortAction } from '../common/AOBEnum';
import ListItemIcon from '@mui/material/ListItemIcon';
import CheckIcon from '@mui/icons-material/Check';
import { ErrorMessages } from '../common/ErrorMessages';
import GroupIcon from '@mui/icons-material/Group';
import PrintIcon from '@mui/icons-material/Print';
import { Search } from 'react-bootstrap-icons';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { AlertDialog } from '../global/AlertDialog';
import AlertWithTitle from './Controls/AlertWithTitle';
import AlertControl from './Controls/AlertControl';
import { LoadProfilePic } from '../global/Helpers';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import InvitationDlg from '../dialogs/InvitationDlg';
import { makeStyles } from "@material-ui/core/styles";
import { AOBSystemProcess, ActivityLogEvent } from '../common/AOBEnum';
import { APICall, WriteToActivityLog } from '../global/Api';
import PeopleAltOutlinedIcon from '@mui/icons-material/PeopleAltOutlined';
import AutoLagoutTimer from '../global/IdleMonitor';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import NoteAltOutlinedIcon from '@mui/icons-material/NoteAltOutlined';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import FileDownload from '@mui/icons-material/FileDownload';
import { GetAttachedFile } from '../global/EnrollmentHelper';
import moment from "moment";
import { jsPDF } from 'jspdf'; //or use your library of choice here
import autoTable from 'jspdf-autotable';

const UserList = (props) => {

    const location = useLocation();
    const [isDataLoading, setIsDataLoading] = useState(false);
    const [rowSelection, setRowSelection] = useState({});
    const [disEditApp, setDisEditApp] = useState(true);
    const dispatch = useDispatch();
    let sessions = useSelector((state) => state.sessionMgmnt);
    const [imageUrl, setImageUrl] = useState(null);
    const [applicantsName, setApplicantsName] = useState('Applicant');
    const [openDelCnfrmDlg, setOpenDelCnfrmDlg] = useState(false);
    const [errorMsg, setErrorMsg] = useState('');
    const [successMsg, setSuccessMsg] = useState(null);
    const [successMsgTitle, setSuccessMsgTitle] = useState(null);
    const [showInviteDlg, setShowInviteDlg] = useState(false);
    const [disDeleteApp, setDisDeleteApp] = useState(true);
    const [disEditUser, setDisEditUser] = useState(true);
    const [disNewUser, setDisNewUser] = useState(true);
    const [disExportToCSV, setDisExportToCSV] = useState(true);

    const [userId, setUserId] = useState(props.userId);
    const [sessionId, setSessionId] = useState(props.sessionId);

    const sortMenuAction = useRef(UserListSortAction.Newest);

    const cnfrmDelDlgHdr = useRef('');
    const cnfrmDelDlgBLine1 = useRef('');
    const cnfrmDelDlgBLine2 = useRef('');
    const cnfrmDelDlgBLine3 = useRef('');
    const cnfrmDelDlgBLine4 = useRef('');

    const tickSrtDtNewDisp = useRef('block');
    const tickSrtDtOldDisp = useRef('none');
    const tickSrtFirstNameDisp = useRef('none');
    const tickSrtLastNameDisp = useRef('none');
    const tickSrtEmailDisp = useRef('none');
    const tickSrtRoleDisp = useRef('none');

    const selUserId = useRef(0);
    const selUserNm = useRef('');
    const selEmail = useRef('');
    const selRoleNm = useRef('');
    const selUserStatusId = useRef(36);

    const [moreMnuAnchorEl, setMoreMnuAnchorEl] = useState(null);
    const openMoreMenu = Boolean(moreMnuAnchorEl);

    const handleMoreMenuClose = () => {
        setMoreMnuAnchorEl(null);
    };

    const closeInviteDlg = () => {
        setShowInviteDlg(false);
        refetch();
    }

    const handleMoreMenuClick = (event) => {
        setMoreMnuAnchorEl(event.currentTarget);
    };

    let navigate = useNavigate();

    const [sortMnuAnchorEl, setSortMnuAnchorEl] = useState(null);
    const openSort = Boolean(sortMnuAnchorEl);

    const handleSortMnuClick = (event) => {
        setSortMnuAnchorEl(event.currentTarget);
    };
    const handleSortMnuClose = () => {
        setSortMnuAnchorEl(null);
    };

    const handleSortDtNewMenuClick = async (event) => {
        setSortMnuAnchorEl(null);
        sortMenuAction.current = UserListSortAction.Newest;
        refetch();
        await WriteToActivityLog(ActivityLogEvent.UserManagement, "Sorted user list by 'Date: Newest on top'.", userId, sessionId);

        SetSortMenuTick(UserListSortAction.Newest);
    }

    const handleSortDtOldMenuClick = async (event) => {
        setSortMnuAnchorEl(null);
        sortMenuAction.current = UserListSortAction.Oldest;
        refetch();
        await WriteToActivityLog(ActivityLogEvent.UserManagement, "Sorted user list by 'Date: Oldest on top'.", userId, sessionId);

        SetSortMenuTick(UserListSortAction.Oldest);
    }

    const firstNameSortMenuClick = async (event) => {
        setSortMnuAnchorEl(null);
        sortMenuAction.current = UserListSortAction.FirstName;
        refetch();
        await WriteToActivityLog(ActivityLogEvent.UserManagement, "Sorted user list by 'First name'.", userId, sessionId);

        SetSortMenuTick(UserListSortAction.FirstName);
    }

    const lastNameSortMenuClick = async (event) => {
        setSortMnuAnchorEl(null);
        sortMenuAction.current = UserListSortAction.LastName;
        refetch();
        await WriteToActivityLog(ActivityLogEvent.UserManagement, "Sorted user list by 'Last name'.", userId, sessionId);

        SetSortMenuTick(UserListSortAction.LastName);
    }

    const emailSortMenuClick = async (event) => {
        setSortMnuAnchorEl(null);
        sortMenuAction.current = UserListSortAction.Email;
        refetch();
        await WriteToActivityLog(ActivityLogEvent.UserManagement, "Sorted user list by 'Email address'.", userId, sessionId);

        SetSortMenuTick(UserListSortAction.Email);
    }

    const roleSortMenuClick = async (event) => {
        setSortMnuAnchorEl(null);
        sortMenuAction.current = UserListSortAction.RoleName;
        refetch();
        await WriteToActivityLog(ActivityLogEvent.UserManagement, "Sorted user list by 'Role'.", userId, sessionId);

        SetSortMenuTick(UserListSortAction.RoleName);
    }

    function SetSortMenuTick(item) {

        tickSrtDtNewDisp.current = 'none';
        tickSrtDtOldDisp.current = 'none';
        tickSrtFirstNameDisp.current = 'none';
        tickSrtLastNameDisp.current = 'none';
        tickSrtEmailDisp.current = 'none';
        tickSrtRoleDisp.current = 'none';

        if (item == UserListSortAction.Newest)
            tickSrtDtNewDisp.current = 'block';

        if (item == UserListSortAction.Oldest)
            tickSrtDtOldDisp.current = 'block';

        if (item == UserListSortAction.FirstName)
            tickSrtFirstNameDisp.current = 'block';

        if (item == UserListSortAction.LastName)
            tickSrtLastNameDisp.current = 'block';

        if (item == UserListSortAction.Email)
            tickSrtEmailDisp.current = 'block';

        if (item == UserListSortAction.RoleName)
            tickSrtRoleDisp.current = 'block';

    }

    const columns = [
        {
            accessorKey: 'TitleName',
            header: 'Title',
            muiTableBodyCellProps: ({ cell }) => ({
                sx: (theme) => ({
                    color: 'text.primary',
                    fontSize: theme.typography.body1,
                    borderLeft: '1px #BBBFBF solid',
                    borderBottom: '1px #BBBFBF solid',
                    pl: 2, fontWeight: 400, textAlign: 'center', pt: 1, pb: 1,
                }),
            }),
            muiTableHeadCellProps: ({ cell }) => ({
                sx: (theme) => ({
                    color: 'text.primary',
                    fontSize: theme.typography.body2,
                    borderTop: '1px #BBBFBF solid',
                    borderLeft: '1px #BBBFBF solid',
                    borderBottom: '1px #BBBFBF solid',
                    fontWeight: 400,
                    pl: 2, pr: 2,
                    letterSpacing: '1px',
                    //borderRadius: '6px',
                    pt: 1, pb: 1,
                }),
            }),
            size: 100
        },
        {
            accessorKey: 'FirstName',
            header: 'First Name',
            Cell: ({ cell, column }) => (
                <Box>
                    {cell.getValue() && cell.getValue().length > 25 ? cell.getValue().substring(0, 25) + '...' : cell.getValue()}
                </Box>
            ),
            size: 230
        },
        {
            accessorKey: 'LastName',
            header: 'Last Name',
            Cell: ({ cell, column }) => (
                <Box>
                    {cell.getValue() && cell.getValue().length > 25 ? cell.getValue().substring(0, 25) + '...' : cell.getValue()}
                </Box>
            ),
            size: 230
        },
        {
            accessorKey: 'Gender',
            header: 'Gender',
            size: 150
        },
        {
            accessorKey: 'DateOfBirth',
            header: 'Date Of Birth',
            Cell: ({ cell, column }) => (
                <Box>
                    {cell.getValue() && cell.getValue().length > 25 ? cell.getValue().substring(0, 25) + '...' : cell.getValue()}
                </Box>
            ),
            size: 230
        },
        {
            accessorKey: 'EmailAddress',
            header: 'Email Address',
            Cell: ({ cell, column }) => (
                <Box>
                    {cell.getValue() && cell.getValue().length > 25 ? cell.getValue().substring(0, 25) + '...' : cell.getValue()}
                </Box>
            ),
            size: 230
        },
        {
            accessorKey: 'PhoneNbr',
            header: 'Phone Number',
            Cell: ({ cell, column }) => (
                <Box>
                    {cell.getValue() && cell.getValue().length > 25 ? cell.getValue().substring(0, 25) + '...' : cell.getValue()}
                </Box>
            ),
            size: 230
        },
        {
            accessorKey: 'MobileNbr',
            header: 'Mobile Number',
            Cell: ({ cell, column }) => (
                <Box>
                    {cell.getValue() && cell.getValue().length > 25 ? cell.getValue().substring(0, 25) + '...' : cell.getValue()}
                </Box>
            ),
            size: 230
        },
        {
            accessorKey: 'RoleName',
            header: 'Role',
            Cell: ({ cell, column }) => (
                <Box>
                    {cell.getValue() && cell.getValue().length > 25 ? cell.getValue().substring(0, 25) + '...' : cell.getValue()}
                </Box>
            ),
            size: 230
        },
        {
            accessorKey: 'ActiveUser',
            header: 'Active',
            size: 100
        },
        {
            accessorKey: 'Locked',
            header: 'Locked',
            size: 100
        },
        {
            accessorKey: 'LastLoginDate',
            header: 'Last Login',
            Cell: ({ cell, column }) => (
                <Box>
                    {cell.getValue() && cell.getValue().length > 25 ? cell.getValue().substring(0, 25) + '...' : cell.getValue()}
                </Box>
            ),
            size: 230
        },
        {
            accessorKey: 'LastPasswordChanged',
            header: 'Password Changed',
            Cell: ({ cell, column }) => (
                <Box>
                    {cell.getValue() && cell.getValue().length > 25 ? cell.getValue().substring(0, 25) + '...' : cell.getValue()}
                </Box>
            ),
            size: 230
        },
        {
            accessorKey: 'CreateDate',
            header: 'Created At',
            Cell: ({ cell, column }) => (
                <Box>
                    {cell.getValue() && cell.getValue().length > 25 ? cell.getValue().substring(0, 25) + '...' : cell.getValue()}
                </Box>
            ),
            size: 230
        },
        {
            accessorKey: 'CreateUser',
            header: 'Created By',
            Cell: ({ cell, column }) => (
                <Box>
                    {cell.getValue() && cell.getValue().length > 25 ? cell.getValue().substring(0, 25) + '...' : cell.getValue()}
                </Box>
            ),
            size: 230
        },
        {
            accessorKey: 'FailedLoginAttempt',
            header: 'Failed Login Attempt',
            size: 230
        },
    ];

    const fetchSize = 25;

    const tableContainerRef = useRef(null); //we can get access to the underlying TableContainer element and react to its scroll events
    const virtualizerInstanceRef = useRef < Virtualizer > null; //we can get access to the underlying Virtualizer instance and call its scrollToIndex method

    const [columnFilters, setColumnFilters] = useState([]);
    const [globalFilter, setGlobalFilter] = useState('');
    const [sorting, setSorting] = useState([]);
    const [searchText, setSearchText] = useState(null);

    const { data, fetchNextPage, isError, isFetching, isLoading, refetch } =
        useInfiniteQuery(
            ['table-data', columnFilters, globalFilter, sorting],
            async ({ pageParam = 0 }) => {

                const apiUrl = new URL(
                    process.env.REACT_APP_GET_USER_LIST,
                    process.env.REACT_APP_BASE_URL,
                );

                apiUrl.searchParams.set('start', `${pageParam * fetchSize}`);
                apiUrl.searchParams.set('size', `${fetchSize}`);

                apiUrl.searchParams.set('globalFilter', searchText ?? '');
                apiUrl.searchParams.set('sorting', sortMenuAction.current);

                let response = await APICall({ url: apiUrl.href, method: 'GET', contentType: 'application/json', responseType: '' }, userId, sessionId);

                if (response !== null && response !== undefined) {
                    return response;
                }
                else {
                    throw Error(ErrorMessages.UnknownError);
                }
            },
            {
                getNextPageParam: (_lastGroup, groups) => groups.length,
                keepPreviousData: true,
                refetchOnWindowFocus: false,
            },
        );

    const flatData = useMemo(
        () => data?.pages.flatMap((page) => page.data) ?? [],
        [data, sortMenuAction],
    );

    const totalDBRowCount = data?.pages?.[0]?.meta?.totalRowCount ?? 0;
    const totalFetched = flatData.length;
    const fetchMoreOnBottomReached = useCallback(
        (containerRefElement) => {
            if (containerRefElement) {
                const { scrollHeight, scrollTop, clientHeight } = containerRefElement;
                if (
                    scrollHeight - scrollTop - clientHeight < 400 &&
                    !isFetching &&
                    totalFetched < totalDBRowCount
                ) {
                    fetchNextPage();
                }
            }
        },
        [fetchNextPage, isFetching, totalFetched, totalDBRowCount],
    );

    useEffect(() => {

        if (virtualizerInstanceRef.current) {
            virtualizerInstanceRef.current.scrollToIndex(0);
        }
    }, [sorting, columnFilters]);

    useEffect(() => {

        fetchMoreOnBottomReached(tableContainerRef.current);
    }, [fetchMoreOnBottomReached]);

    useEffect(() => {

        let canAdd = IsInRole(AOBSystemProcess.UserMaintenance, userId, sessionId);
        if (canAdd)
            setDisNewUser(false);
        else
            setDisNewUser(true);

        loadForm();

    }, []);

    const loadForm = async () => {
        await WriteToActivityLog(ActivityLogEvent.UserManagement, "User list viewed.", userId, sessionId);
    };

    const inviteUser = (event) => {
        setShowInviteDlg(true);
    };

    const exportToCSV = async () => {

        try {

            props.loadingChange(true);

            const apiUrl = new URL(
                process.env.REACT_APP_EXPORTTOCSV_USERDATA,
                process.env.REACT_APP_BASE_URL,
            );
            let fileNm = 'UserData.csv';
            let ret = await GetAttachedFile(apiUrl.href, fileNm, userId, sessionId);

            if (!ret) {
                setErrorMsg(ErrorMessages.UnknownError);
                return;
            }
            await WriteToActivityLog(ActivityLogEvent.UserManagement, "User list exported to CSV: File name: '" + fileNm + "'", userId, sessionId);
        }
        catch (ex) {
            setErrorMsg(ErrorMessages.UnknownError);
        }
        finally {
            props.loadingChange(false);
        }
    }

    const exportToPDF = async () => {

        try {

            props.loadingChange(true);

            const tableData = await getAllSysUser();;

            if (tableData.data.length == 0) {
                setErrorMsg('No data to export');
                return;
            }

            let fileNm = 'UserData.pdf';

            const doc = new jsPDF({
                orientation: "landscape",
                unit: 'pt',
                format: 'a4'
            });

            var x = new Array(tableData.data.length);
            /*
                TitleName = AOBDataUtility.GetStringValue(dr, "Title"),
                FirstName = AOBDataUtility.GetStringValue(dr, "FirstName"),
                LastName = AOBDataUtility.GetStringValue(dr, "LastName"),
                Gender = AOBDataUtility.GetStringValue(dr, "Gender"),
                DateOfBirth = AOBDataUtility.GetStringValue(dr, "DateOfBirth"),
                EmailAddress = AOBDataUtility.GetStringValue(dr, "EmailAdrs"),
                PhoneNbr = AOBDataUtility.GetStringValue(dr, "PhoneNbr"),
                MobileNbr = AOBDataUtility.GetStringValue(dr, "MobileNbr"),
                RoleName = AOBDataUtility.GetStringValue(dr, "RoleName"),
                ActiveInd = AOBDataUtility.GetBooleanValue(dr, "ActiveInd"),
                ActiveUser = AOBDataUtility.GetStringValue(dr, "ActiveUser"),
                Locked = AOBDataUtility.GetStringValue(dr, "Locked"),
                UserStatusText = AOBDataUtility.GetStringValue(dr, "UserStatus"),
                UserStatus = AOBDataUtility.GetIntValue(dr, "UserStatusId").Value,
                CreateUser = AOBDataUtility.GetStringValue(dr, "CrtUsr"),
             */


            for (var i = 0; i < x.length; i++) {
                x[i] = new Array(11);
                x[i][0] = tableData.data[i].TitleName;
                x[i][1] = tableData.data[i].FirstName;
                x[i][2] = tableData.data[i].LastName;
                x[i][3] = tableData.data[i].Gender;
                x[i][4] = tableData.data[i].DateOfBirth;
                x[i][5] = tableData.data[i].EmailAddress;
                x[i][6] = tableData.data[i].PhoneNbr;
                x[i][7] = tableData.data[i].MobileNbr;
                x[i][8] = tableData.data[i].RoleName;
                x[i][9] = tableData.data[i].ActiveUser;
                x[i][10] = tableData.data[i].Locked;
                x[i][11] = tableData.data[i].UserStatusText;
            }

            autoTable(doc, {
                head: [['Title', 'First Name', 'Last Name', 'Gender', 'DOB', 'Email', 'Phone', 'Mobile', 'Role', 'Active', 'Locked', 'Status']],
                columnStyles: { 0: { cellWidth: 50 }, 1: { cellWidth: 100 }, 2: { cellWidth: 100 }, 3: { cellWidth: 50 }, 4: { cellWidth: 70 }, 5: { cellWidth: 100 }, 6: { cellWidth: 50 }, 7: { cellWidth: 70 }, 8: { cellWidth: 50 }, 9: { cellWidth: 50 }, 10: { cellWidth: 50 }, },
                margin: { left: 10, right: 10 },
                body: x,
            });

            doc.setFontSize(11);

            const pageCount = doc.internal.getNumberOfPages();

            for (let i = 1; i <= pageCount; i++) {
                doc.setPage(i);
                const pageSize = doc.internal.pageSize;
                const pageWidth = pageSize.width ? pageSize.width : pageSize.getWidth();
                const pageHeight = pageSize.height ? pageSize.height : pageSize.getHeight();
                const header = 'User Management';
                const footer = `Page ${i} of ${pageCount}`;

                // Header
                doc.text(header, 40, 15, { baseline: 'top' });

                // Footer
                doc.text(footer, pageWidth / 2 - (doc.getTextWidth(footer) / 2), pageHeight - 15, { baseline: 'bottom' });
            }

            doc.save(fileNm);

            await WriteToActivityLog(ActivityLogEvent.ApplicationDashboard, "User list exported to PDF: File name: '" + fileNm + "'", userId, sessionId);
        }
        catch (ex) {
            setErrorMsg(ErrorMessages.UnknownError);
        }
        finally {
            props.loadingChange(false);
        }
    }


    const getAllSysUser = async () => {

        const apiUrl = new URL(
            process.env.REACT_APP_EXPORTTOPDF_USERDATA,
            process.env.REACT_APP_BASE_URL,
        );

        let response = await APICall({ url: apiUrl.href, method: 'GET', contentType: 'application/json', responseType: '' }, userId, sessionId);

        if (response !== null && response !== undefined) {

            return response;
        }
        else {
            throw Error(ErrorMessages.UnknownError);
        }
    };

    const handleSearchChange = (event) => {
        setSearchText(event.target.value);
    };

    const handleSearchClick = async (event) => {
        refetch();
        await WriteToActivityLog(ActivityLogEvent.UserManagement, "Searched user list.", userId, sessionId);
    };

    const handleEditClick = (table) => {

        setMoreMnuAnchorEl(null);

        if (table.getSelectedRowModel().rows.length > 0) {
            let row = table.getSelectedRowModel().rows[0];
            navigate('/User', { state: { userid: parseInt(selUserId.current), userEmail: selEmail.current, userStatusId: selUserStatusId.current, loggedInUserId: userId, sessionId: sessionId }, replace: true });
        }
    };

    const handleDownloadToCSVClick = (table) => {

        setMoreMnuAnchorEl(null);

        exportToCSV();
    };

    const handleDownloadToPDFClick = (table) => {

        setMoreMnuAnchorEl(null);

        exportToPDF();
    };

    const handleDeleteClick = (table) => {

        setMoreMnuAnchorEl(null);

        if (table.getSelectedRowModel().rows.length > 0) {
            let row = table.getSelectedRowModel().rows[0];
            cnfrmDelDlgBLine1.current = 'User Name: ' + row.original.FirstName + ' ' + row.original.LastName;
            cnfrmDelDlgBLine2.current = 'Email address: ' + row.original.EmailAddress;
            cnfrmDelDlgBLine3.current = 'Note: All applications (if any) of this user will also be deleted.';
        }
        cnfrmDelDlgHdr.current = 'Are you sure you want to delete the following user?';
        setOpenDelCnfrmDlg(true);
    };

    const handleDelCnfrmDlgClose = async (nvalue, svalue) => {

        setOpenDelCnfrmDlg(false);

        try {

            if (nvalue === null || nvalue === undefined) {
                return;
            }

            props.loadingChange(true);

            const apiUrl = new URL(
                process.env.REACT_APP_DELETE_USER,
                process.env.REACT_APP_BASE_URL,
            );

            const inputJson = {
                UserId: nvalue
            };

            let response = await APICall({ url: apiUrl.href, method: 'POST', payload: inputJson, contentType: 'application/json', responseType: '' }, userId, sessionId);

            if (response !== null && response !== undefined) {

                if (response.Errors !== undefined && response.Errors.length > 0) {
                    setErrorMsg(response.Errors[0].Message);
                }
                else {

                    await WriteToActivityLog(ActivityLogEvent.UserManagement, "User deleted. User name: '" + selUserNm.current + "'", userId, sessionId);

                    setSuccessMsgTitle("User deleted");
                    setSuccessMsg('User named as \'' + selUserNm.current + '\' deleted successfully');
                    setRowSelection({});
                    refetch();
                }

                props.loadingChange(false);
            }
        }
        catch (err) {
            setErrorMsg(ErrorMessages.UnknownError);
            props.loadingChange(false);
        }
        finally {
            setIsDataLoading(false);
        }
    };

    const HandleRowClick = async (row) => {

        selUserId.current = row.original.UserId;
        selUserNm.current = row.original.FirstName + ' ' + row.original.LastName;
        selEmail.current = row.original.EmailAddress;
        selUserStatusId.current = row.original.UserStatus;
        selRoleNm.current = 'Role: ' + row.original.RoleName;

        let isAdmin = IsInRole(AOBSystemProcess.AllProcess, userId, sessionId);

        let canDelete = IsInRole(AOBSystemProcess.DeleteUser, userId, sessionId) || isAdmin;;
        if (canDelete) {
            setDisDeleteApp(false);
        }
        else {
            setDisDeleteApp(true);
        }

        let canEdit = IsInRole(AOBSystemProcess.UserMaintenance, userId, sessionId) || isAdmin;;
        if (canEdit) {
            setDisEditUser(false);
        }
        else {
            setDisEditUser(true);
        }

        try {

            props.loadingChange(true);

            const apiUrl = new URL(
                process.env.REACT_APP_GET_PROFILE_IMAGE,
                process.env.REACT_APP_BASE_URL,
            );

            apiUrl.searchParams.set('cntctId', `${parseInt(selUserId.current)}`);

            let response = await APICall({ url: apiUrl.href, method: 'GET', contentType: 'application/json', responseType: 'blob' }, userId, sessionId);

            if (response) {
                if (response.type == 'image/jpeg') {
                    setImageUrl(response);
                }
                else {
                    setImageUrl(null);
                }
                props.loadingChange(false);
            }
        }
        catch (err) {
            setErrorMsg(ErrorMessages.UnknownError);
            props.loadingChange(false);
        }
    }

    return (
        <React.Fragment>
            <Grid item sx={{}}>
                <Grid item xs container direction="row" spacing={2} sx={{ pb: 3, }}>
                    <Grid item md={10} sx={{}} justifyContent="center">
                        <Box sx={{ pt: 1 }}>
                            <AlertWithTitle Message={successMsg} variant="body1" severity="success" color="success" icon={true} Title={successMsgTitle} />
                            <AlertControl Message={errorMsg} severity="error" color="error" icon={true} />
                        </Box>
                        <Box sx={{ display: 'flex', pl: 0, pt: 1, pb: 1, display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                            <Paper elevation={0}
                                sx={{ display: 'flex', alignItems: 'center', width: 600, border: 1, borderColor: '#BFC3C5', }}
                            >
                                <InputBase className="form-control" sx={{ color: '#161616', border: '0px !important'  }}
                                    placeholder="Search First name, Last name, Email address" onChange={handleSearchChange}
                                />
                                <IconButton type="button" sx={{ p: '6px' }} aria-label="search" onClick={(event) => handleSearchClick(event)}>
                                    <Search sx={{ color: '#0A0A0A' }} />
                                </IconButton>
                            </Paper>
                            <Box sx={{ pr: 4 }}>
                                <Button variant="contained" disabled={disNewUser} size="small" style={{ borderRadius: '20px', padding: '0.3rem 1.7rem', textTransform: 'none'}} onClick={(e) => inviteUser(e)}>
                                    Invite User
                                </Button>
                            </Box>

                            {/*<Box sx={{ pr: 2 }}>*/}
                            {/*    <Button variant="contained" size="large" style={{ boxShadow: 'none', border: 0, background: '#ffbf3f', borderRadius: '20px', padding: '0.3rem 2.5rem', outline: 'none', }} onClick={(e) => inviteUser(e)}>*/}
                            {/*        <Typography variant="caption" color="#002d72" sx={{ textTransform: 'none', fontWeight: 600, letterSpacing: '2px' }} >Invite</Typography>*/}
                            {/*    </Button>*/}
                            {/*</Box>*/}
                        </Box>
                        <Box sx={{ pt: 1 }}>
                            <MaterialReactTable
                                columns={columns}
                                data={flatData}
                                displayColumnDefOptions={{
                                }}
                                enableColumnActions={false}
                                enableSorting={false}
                                enableExpandAll={false}
                                enablePagination={false}
                                enableRowNumbers={false}
                                enableRowVirtualization
                                enableHiding={false}
                                enableColumnFilters={false}
                                enableColumnOrdering={false}
                                muiTableBodyRowProps={({ row }) => ({
                                    onClick: () => {
                                        setRowSelection((prev) => ({
                                            [row.id]: true,
                                        }));
                                        HandleRowClick(row);
                                    },
                                    sx: {
                                        cursor: 'pointer',
                                    },
                                })}
                                positionActionsColumn="last"
                                enableColumnResizing={false}
                                enableDensityToggle={false}
                                enableFullScreenToggle={false}
                                muiTableBodyCellProps={({ column }) => ({
                                    sx: (theme) => ({
                                        color: 'text.primary',
                                        fontSize: theme.typography.body1,
                                        borderBottom: '1px #BBBFBF solid',
                                        pl: 2, fontWeight: 400, pt: 1, pb: 1,
                                    }),
                                })
                                }

                                muiTableHeadCellProps={{
                                    sx: (theme) => ({
                                        color: 'text.primary',
                                        fontSize: theme.typography.body2,
                                        borderTop: '1px #BBBFBF solid',
                                        borderBottom: '1px #BBBFBF solid',
                                        fontWeight: 400,
                                        pl: 2, pr: 2,
                                        pt: 1, pb: 1,
                                    }),
                                }}

                                muiTableContainerProps={{
                                    ref: tableContainerRef,
                                    sx: { maxHeight: '550px', minHeight: '400px' },
                                    onScroll: (
                                        event,
                                    ) => fetchMoreOnBottomReached(event.target),
                                }}
                                muiToolbarAlertBannerProps={
                                    isError
                                        ? {
                                            color: 'error',
                                            children: ErrorMessages.UnknownError,
                                        }
                                        : undefined
                                }
                                onColumnFiltersChange={setColumnFilters}
                                onSortingChange={setSorting}
                                renderBottomToolbarCustomActions={() => (
                                    <Box sx={{ mt: 0.5, pl: 0 }}>
                                        <Typography variant="body2" color="text.blueText" sx={{ pl: 1, fontWeight: 400 }}>
                                            {
                                                !isError && data
                                                    ? totalFetched + ' of ' + totalDBRowCount + ' user(s)'
                                                    : ''
                                            }
                                        </Typography>
                                    </Box>
                                )}
                                onRowSelectionChange={setRowSelection}
                                state={{
                                    columnFilters,
                                    isLoading,
                                    showAlertBanner: isError,
                                    showProgressBars: isFetching,
                                    showSkeletons: isFetching,
                                    rowSelection,
                                    sorting,
                                }}
                                initialState={{
                                }}
                                muiTablePaperProps={{
                                    elevation: 0,
                                }}
                                rowVirtualizerProps={{ overscan: 4 }}
                                localization={{
                                    noRecordsToDisplay: 'No user to display',
                                    selectedCountOfRowCountRowsSelected: '',
                                }}
                                renderTopToolbarCustomActions={({ table }) => {
                                    return (
                                        <Box sx={{
                                            display: 'flex', alignItems: 'center', flexDirection: 'row'
                                        }}>
                                            <Typography variant="body1" color="text.blueText" sx={{ pt: 0, pr: 0, fontWeight: 500, pl: 0.5, borderRadius: '4px', }}>Active users</Typography>
                                            <Typography variant="body1" color="text.primary" sx={{ pt: 0, pr: 1, fontWeight: 400, pl: 1, borderRadius: '4px', ml: 2, }}>Sort</Typography>
                                            <IconButton sx={{ ml: 1, border: 0, borderColor: '#235ec9', p: 0, pr: 2 }} onClick={(event) => handleSortMnuClick(event)}>
                                                <ExpandMoreIcon sx={{ fontSize: 24, color: 'Icon.main' }} />
                                            </IconButton>
                                            <Menu
                                                id="basic-menu"
                                                anchorEl={sortMnuAnchorEl}
                                                open={openSort}
                                                onClose={handleSortMnuClose}
                                                MenuListProps={{
                                                    'aria-labelledby': 'basic-button',
                                                }}
                                            >
                                                <MenuItem key={0} sx={{ mt: 2, ml: 1, mr: 1 }} onClick={(event) => handleSortDtNewMenuClick(event)}>
                                                    <ListItemIcon>
                                                        <CheckIcon sx={{ display: tickSrtDtNewDisp.current, fontSize: 24, color: 'Icon.main' }} />
                                                    </ListItemIcon>
                                                    <Typography variant="body1" sx={{ textTransform: 'none', mr: 1 }} color="text.primary">Date: Newest on top</Typography>
                                                </MenuItem>
                                                <MenuItem sx={{ ml: 1, mr: 1 }} key={1} onClick={(event) => handleSortDtOldMenuClick(event)}>
                                                    <ListItemIcon>
                                                        <CheckIcon sx={{ display: tickSrtDtOldDisp.current, fontSize: 24, color: 'Icon.main' }} />
                                                    </ListItemIcon>
                                                    <Typography variant="body1" sx={{ textTransform: 'none' }} color="text.primary">Date: Oldtest on top</Typography>
                                                </MenuItem>
                                                <Divider variant="fullWidth" component="div" sx={{}} />
                                                <MenuItem key={0} sx={{ ml: 1, mr: 1 }} onClick={(event) => firstNameSortMenuClick(event)}>
                                                    <ListItemIcon>
                                                        <CheckIcon sx={{ display: tickSrtFirstNameDisp.current, fontSize: 24, color: 'Icon.main' }} />
                                                    </ListItemIcon>
                                                    <Typography variant="body1" sx={{ textTransform: 'none' }} color="text.primary">Fist name</Typography>
                                                </MenuItem>
                                                <MenuItem key={1} sx={{ ml: 1, mr: 1 }} onClick={(event) => lastNameSortMenuClick(event)}>
                                                    <ListItemIcon>
                                                        <CheckIcon sx={{ display: tickSrtLastNameDisp.current, fontSize: 24, color: 'Icon.main' }} />
                                                    </ListItemIcon>
                                                    <Typography variant="body1" sx={{ textTransform: 'none' }} color="text.primary">Last name</Typography>
                                                </MenuItem>
                                                <MenuItem key={2} sx={{ ml: 1, mr: 1 }} onClick={(event) => emailSortMenuClick(event)}>
                                                    <ListItemIcon>
                                                        <CheckIcon sx={{ display: tickSrtEmailDisp.current, fontSize: 24, color: 'Icon.main' }} />
                                                    </ListItemIcon>
                                                    <Typography variant="body1" sx={{ textTransform: 'none' }} color="text.primary">Email address</Typography>
                                                </MenuItem>
                                                <Divider variant="fullWidth" component="div" sx={{}} />
                                                <MenuItem key={3} sx={{ mb: 3.5, ml: 1, mr: 1 }} onClick={(event) => roleSortMenuClick(event)}>
                                                    <ListItemIcon>
                                                        <CheckIcon sx={{ display: tickSrtRoleDisp.current, fontSize: 24, color: 'Icon.main' }} />
                                                    </ListItemIcon>
                                                    <Typography variant="body1" sx={{ textTransform: 'none' }} color="text.primary">Role</Typography>
                                                </MenuItem>
                                            </Menu>
                                        </Box>
                                    );
                                }}
                                renderToolbarInternalActions={({ table }) => {
                                    return (
                                        <Box>
                                            <Tooltip arrow title="More options">
                                                <span>
                                                    <IconButton size="small" sx={{ border: 0, borderColor: '#d7d8d6', borderRadius: '5px', ml: 0, mr: 1, mb: 0 }} onClick={handleMoreMenuClick}>
                                                        <MoreVertIcon sx={{ fontSize: 26, color: 'Icon.main' }} />
                                                    </IconButton>
                                                </span>
                                            </Tooltip>
                                            <Menu
                                                id="more-menu"
                                                anchorEl={moreMnuAnchorEl}
                                                open={openMoreMenu}
                                                onClose={handleMoreMenuClose}
                                            >
                                                <MenuItem sx={{ ml: 1, mt: 1, mr: 5 }} key={0} disabled={disEditUser} onClick={() => handleEditClick(table)}>
                                                    <ListItemIcon>
                                                        <NoteAltOutlinedIcon sx={{ fontSize: 22, color: 'Icon.main' }} />
                                                    </ListItemIcon>
                                                    <Typography variant="body1" sx={{ textTransform: 'none', fontWeight: 400 }} color="text.primary">Edit user</Typography>
                                                </MenuItem>
                                                <Divider variant="fullWidth" component="div" sx={{}} />
                                                <MenuItem sx={{ mb: 0, ml: 1, mr: 5 }} key={4} disabled={disDeleteApp} onClick={() => handleDeleteClick(table)}>
                                                    <ListItemIcon>
                                                        <DeleteForeverIcon sx={{ fontSize: 24, color: 'Icon.main' }} />
                                                    </ListItemIcon>
                                                    <Typography variant="body1" sx={{ textTransform: 'none', fontWeight: 400 }} color="text.primary">Delete user</Typography>
                                                </MenuItem>
                                                <Divider variant="fullWidth" component="div" sx={{}} />
                                                <MenuItem sx={{ mb: 0, ml: 1, mr: 5 }} key={4} onClick={() => handleDownloadToCSVClick(table)}>
                                                    <ListItemIcon>
                                                        <FileDownload sx={{ fontSize: 24, color: 'Icon.main' }} />
                                                    </ListItemIcon>
                                                    <Typography variant="body1" sx={{ textTransform: 'none', fontWeight: 400 }} color="text.primary">Export to CSV</Typography>
                                                </MenuItem>
                                                <MenuItem sx={{ mb: 1, ml: 1, mr: 5, mb: 2 }} key={4} onClick={() => handleDownloadToPDFClick(table)}>
                                                    <ListItemIcon>
                                                        <FileDownload sx={{ fontSize: 24, color: 'Icon.main' }} />
                                                    </ListItemIcon>
                                                    <Typography variant="body1" sx={{ textTransform: 'none', fontWeight: 400 }} color="text.primary">Export to PDF</Typography>
                                                </MenuItem>
                                            </Menu>
                                        </Box>
                                    );
                                }}
                            />
                        </Box>
                    </Grid>
                    <Grid item md={2} sx={{}} justifyContent="center">
                        <Box sx={{ ml: 1, mt: 17, }}>
                            <Card elevation={0} sx={{ Width: '100%', }}>
                                {imageUrl ?
                                    <CardMedia component="img" sx={{ maxWidth: 345 }} image={URL.createObjectURL(imageUrl)} />
                                    :
                                    <img src={profimg} sx={{ maxWidth: 345 }} alt="Prifile Picture" className="prof-pic-prvw" />
                                }
                            </Card>
                            <Stack direction="column">
                                <Box sx={{ mt: 2 }}>
                                    <Typography variant="body2" color="text.primary" sx={{ fontWeight: 500, mt: 2 }}>
                                        {selUserNm.current}
                                    </Typography>
                                </Box>
                                <Typography variant="body2" color="text.primary" sx={{ pt: 1 }}>
                                    {selEmail.current}
                                </Typography>
                                <Typography variant="body2" color="text.primary" sx={{ pt: 1 }}>
                                    {selRoleNm.current}
                                </Typography>
                            </Stack>
                        </Box>
                    </Grid>
                </Grid>
            </Grid>
            <AlertDialog
                id="del-cnfrm-dlg"
                keepMounted
                open={openDelCnfrmDlg}
                onClose={handleDelCnfrmDlgClose}
                nvalue={selUserId.current}
                alertheader={cnfrmDelDlgHdr.current}
                bmsgline1={cnfrmDelDlgBLine1.current}
                bmsgline2={cnfrmDelDlgBLine2.current}
                bmsgline3={cnfrmDelDlgBLine3.current}
            />
            {showInviteDlg ?
                <InvitationDlg open={showInviteDlg} onClose={closeInviteDlg} userId={userId} sessionId={sessionId} />
                : null
            }
        </React.Fragment>
    );
};

const PendingActivationUserList = (props) => {

    const [isDataLoading, setIsDataLoading] = useState(false);
    const [rowSelection, setRowSelection] = useState({});
    const [disEditApp, setDisEditApp] = useState(true);
    const dispatch = useDispatch();
    let sessions = useSelector((state) => state.sessionMgmnt);
    const [imageUrl, setImageUrl] = useState(null);
    const [applicantsName, setApplicantsName] = useState('Applicant');
    const [openDelCnfrmDlg, setOpenDelCnfrmDlg] = useState(false);
    const [errorMsg, setErrorMsg] = useState('');
    const [successMsg, setSuccessMsg] = useState(null);
    const [successMsgTitle, setSuccessMsgTitle] = useState(null);
    const [showInviteDlg, setShowInviteDlg] = useState(false);
    const sortMenuAction = useRef(UserListSortAction.Newest);

    const [userId, setUserId] = useState(props.userId);
    const [sessionId, setSessionId] = useState(props.sessionId);

    const tickSrtDtNewDisp = useRef('block');
    const tickSrtDtOldDisp = useRef('none');
    const tickSrtFirstNameDisp = useRef('none');
    const tickSrtLastNameDisp = useRef('none');
    const tickSrtEmailDisp = useRef('none');
    const tickSrtRoleDisp = useRef('none');


    const [sortMnuAnchorEl, setSortMnuAnchorEl] = useState(null);
    const openSort = Boolean(sortMnuAnchorEl);

    const handleSortMnuClick = (event) => {
        setSortMnuAnchorEl(event.currentTarget);
    };
    const handleSortMnuClose = () => {
        setSortMnuAnchorEl(null);
    };

    const handleSortDtNewMenuClick = async (event) => {
        setSortMnuAnchorEl(null);
        sortMenuAction.current = UserListSortAction.Newest;
        refetch();
        await WriteToActivityLog(ActivityLogEvent.UserManagement, "Sorted pending users list by 'Date: Newest on top'.", userId, sessionId);

        SetSortMenuTick(UserListSortAction.Newest);
    }

    const handleSortDtOldMenuClick = async (event) => {
        setSortMnuAnchorEl(null);
        sortMenuAction.current = UserListSortAction.Oldest;
        refetch();
        await WriteToActivityLog(ActivityLogEvent.UserManagement, "Sorted pending users list by 'Date: Oldest on top'.", userId, sessionId);

        SetSortMenuTick(UserListSortAction.Oldest);
    }

    const firstNameSortMenuClick = async (event) => {
        setSortMnuAnchorEl(null);
        sortMenuAction.current = UserListSortAction.FirstName;
        refetch();
        await WriteToActivityLog(ActivityLogEvent.UserManagement, "Sorted pending users list by 'First name'.", userId, sessionId);

        SetSortMenuTick(UserListSortAction.FirstName);
    }

    const lastNameSortMenuClick = async (event) => {
        setSortMnuAnchorEl(null);
        sortMenuAction.current = UserListSortAction.LastName;
        refetch();
        await WriteToActivityLog(ActivityLogEvent.UserManagement, "Sorted pending users list by 'Last name'.", userId, sessionId);

        SetSortMenuTick(UserListSortAction.LastName);
    }

    const emailSortMenuClick = async (event) => {
        setSortMnuAnchorEl(null);
        sortMenuAction.current = UserListSortAction.Email;
        refetch();
        await WriteToActivityLog(ActivityLogEvent.UserManagement, "Sorted pending users list by 'Email address'.", userId, sessionId);

        SetSortMenuTick(UserListSortAction.Email);
    }

    const roleSortMenuClick = async (event) => {
        setSortMnuAnchorEl(null);
        sortMenuAction.current = UserListSortAction.RoleName;
        refetch();
        await WriteToActivityLog(ActivityLogEvent.UserManagement, "Sorted pending users list by 'Role'.", userId, sessionId);

        SetSortMenuTick(UserListSortAction.RoleName);
    }

    function SetSortMenuTick(item) {

        tickSrtDtNewDisp.current = 'none';
        tickSrtDtOldDisp.current = 'none';
        tickSrtFirstNameDisp.current = 'none';
        tickSrtLastNameDisp.current = 'none';
        tickSrtEmailDisp.current = 'none';
        tickSrtRoleDisp.current = 'none';

        if (item == UserListSortAction.Newest)
            tickSrtDtNewDisp.current = 'block';

        if (item == UserListSortAction.Oldest)
            tickSrtDtOldDisp.current = 'block';

        if (item == UserListSortAction.FirstName)
            tickSrtFirstNameDisp.current = 'block';

        if (item == UserListSortAction.LastName)
            tickSrtLastNameDisp.current = 'block';

        if (item == UserListSortAction.Email)
            tickSrtEmailDisp.current = 'block';

        if (item == UserListSortAction.RoleName)
            tickSrtRoleDisp.current = 'block';

    }

    const columns = [
        {
            accessorKey: 'TitleName',
            header: 'Title',
            muiTableBodyCellProps: ({ cell }) => ({
                sx: (theme) => ({
                    color: 'text.primary',
                    fontSize: theme.typography.body1,
                    borderLeft: '1px #BBBFBF solid',
                    borderBottom: '1px #BBBFBF solid',
                    //borderBottom: '1px #BBBFBF solid',
                    pl: 2, fontWeight: 400, textAlign: 'center', pt: 1, pb: 1,
                }),
            }),
            muiTableHeadCellProps: ({ cell }) => ({
                sx: (theme) => ({
                    color: 'text.primary',
                    fontSize: theme.typography.body2,
                    borderTop: '1px #BBBFBF solid',
                    borderLeft: '1px #BBBFBF solid',
                    borderBottom: '1px #BBBFBF solid',
                    fontWeight: 400,
                    pl: 2, pr: 2,
                    //background: '#f7f7f7',
                    //letterSpacing: '1px',
                    //borderRadius: '6px',
                    pt: 1, pb: 1,
                }),
            }),
            size: 100
        },
        {
            accessorKey: 'FirstName',
            header: 'First Name',
            Cell: ({ cell, column }) => (
                <Box>
                    {cell.getValue() && cell.getValue().length > 25 ? cell.getValue().substring(0, 25) + '...' : cell.getValue()}
                </Box>
            ),
            size: 230
        },
        {
            accessorKey: 'LastName',
            header: 'Last Name',
            Cell: ({ cell, column }) => (
                <Box>
                    {cell.getValue() && cell.getValue().length > 25 ? cell.getValue().substring(0, 25) + '...' : cell.getValue()}
                </Box>
            ),
            size: 230
        },
        {
            accessorKey: 'Gender',
            header: 'Gender',
            size: 150
        },
        {
            accessorKey: 'DateOfBirth',
            header: 'Date Of Birth',
            Cell: ({ cell, column }) => (
                <Box>
                    {cell.getValue() && cell.getValue().length > 25 ? cell.getValue().substring(0, 25) + '...' : cell.getValue()}
                </Box>
            ),
            size: 230
        },
        {
            accessorKey: 'EmailAddress',
            header: 'Email Address',
            Cell: ({ cell, column }) => (
                <Box>
                    {cell.getValue() && cell.getValue().length > 25 ? cell.getValue().substring(0, 25) + '...' : cell.getValue()}
                </Box>
            ),
            size: 230
        },
        {
            accessorKey: 'PhoneNbr',
            header: 'Phone Number',
            Cell: ({ cell, column }) => (
                <Box>
                    {cell.getValue() && cell.getValue().length > 25 ? cell.getValue().substring(0, 25) + '...' : cell.getValue()}
                </Box>
            ),
            size: 230
        },
        {
            accessorKey: 'MobileNbr',
            header: 'Mobile Number',
            Cell: ({ cell, column }) => (
                <Box>
                    {cell.getValue() && cell.getValue().length > 25 ? cell.getValue().substring(0, 25) + '...' : cell.getValue()}
                </Box>
            ),
            size: 230
        },
        {
            accessorKey: 'RoleName',
            header: 'Role',
            Cell: ({ cell, column }) => (
                <Box>
                    {cell.getValue() && cell.getValue().length > 25 ? cell.getValue().substring(0, 25) + '...' : cell.getValue()}
                </Box>
            ),
            size: 230
        },
        {
            accessorKey: 'CreateDate',
            header: 'Created At',
            Cell: ({ cell, column }) => (
                <Box>
                    {cell.getValue() && cell.getValue().length > 25 ? cell.getValue().substring(0, 25) + '...' : cell.getValue()}
                </Box>
            ),
            size: 230
        },
        {
            accessorKey: 'CreateUser',
            header: 'Created By',
            Cell: ({ cell, column }) => (
                <Box>
                    {cell.getValue() && cell.getValue().length > 25 ? cell.getValue().substring(0, 25) + '...' : cell.getValue()}
                </Box>
            ),
            size: 230
        }
    ];

    const fetchSize = 25;

    const tableContainerRef = useRef(null); //we can get access to the underlying TableContainer element and react to its scroll events
    const virtualizerInstanceRef = useRef < Virtualizer > null; //we can get access to the underlying Virtualizer instance and call its scrollToIndex method

    const [columnFilters, setColumnFilters] = useState([]);
    const [globalFilter, setGlobalFilter] = useState('');
    const [sorting, setSorting] = useState([]);
    const [searchText, setSearchText] = useState(null);

    const { data, fetchNextPage, isError, isFetching, isLoading, refetch } =
        useInfiniteQuery(
            ['table-data', columnFilters, globalFilter, sorting],
            async ({ pageParam = 0 }) => {

                const apiUrl = new URL(
                    process.env.REACT_APP_GET_USER_LIST,
                    process.env.REACT_APP_BASE_URL,
                );

                apiUrl.searchParams.set('start', `${pageParam * fetchSize}`);
                apiUrl.searchParams.set('size', `${fetchSize}`);

                apiUrl.searchParams.set('globalFilter', searchText ?? '');
                apiUrl.searchParams.set('sorting', sortMenuAction.current);
                apiUrl.searchParams.set('userstatus', 72);

                let response = await APICall({ url: apiUrl.href, method: 'GET', contentType: 'application/json', responseType: '' }, userId, sessionId);

                if (response !== null && response !== undefined) {
                    return response;
                }
                else {
                    throw Error(ErrorMessages.UnknownError);
                }
            },
            {
                getNextPageParam: (_lastGroup, groups) => groups.length,
                keepPreviousData: true,
                refetchOnWindowFocus: false,
            },
        );

    const flatData = useMemo(
        () => data?.pages.flatMap((page) => page.data) ?? [],
        [data, sortMenuAction],
    );

    const totalDBRowCount = data?.pages?.[0]?.meta?.totalRowCount ?? 0;
    const totalFetched = flatData.length;
    const fetchMoreOnBottomReached = useCallback(
        (containerRefElement) => {
            if (containerRefElement) {
                const { scrollHeight, scrollTop, clientHeight } = containerRefElement;
                if (
                    scrollHeight - scrollTop - clientHeight < 400 &&
                    !isFetching &&
                    totalFetched < totalDBRowCount
                ) {
                    fetchNextPage();
                }
            }
        },
        [fetchNextPage, isFetching, totalFetched, totalDBRowCount],
    );

    useEffect(() => {

        if (virtualizerInstanceRef.current) {
            virtualizerInstanceRef.current.scrollToIndex(0);
        }
    }, [sorting, columnFilters]);

    useEffect(() => {

        fetchMoreOnBottomReached(tableContainerRef.current);
    }, [fetchMoreOnBottomReached]);

    useEffect(() => {

        loadForm();

    }, []);

    const loadForm = async () => {
        await WriteToActivityLog(ActivityLogEvent.UserManagement, "Pending users list viewed.", userId, sessionId);
    };

    const handleSearchChange = (event) => {
        setSearchText(event.target.value);
    };

    const handleSearchClick = async (event) => {
        refetch();
        await WriteToActivityLog(ActivityLogEvent.UserManagement, "Searched pending users list.", userId, sessionId);
    };

    return (
        <React.Fragment>

            <Grid item>
                <Grid item xs container direction="row" spacing={2} sx={{ pt: 0, pb: 3, }}>
                    <Grid item md={12} sx={{}} justifyContent="center">
                        <Box sx={{ pt: 1 }}>
                            <AlertControl Message={errorMsg} severity="error" color="error" icon={true} />
                        </Box>
                        <Box sx={{ pb: 3, pl: 0, pt: 1, display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                            <Paper elevation={0}
                                sx={{ display: 'flex', alignItems: 'center', width: 600, border: 1, borderColor: '#bdbdbd', }}
                            >
                                <InputBase className="form-control" sx={{ color: '#161616', border: '0px !important' }}
                                    placeholder="Search First name, Last name, Email address" onChange={handleSearchChange}
                                />
                                <IconButton type="button" sx={{ p: '6px' }} aria-label="search" onClick={(event) => handleSearchClick(event)}>
                                    <Search sx={{ color: '#0A0A0A' }} />
                                </IconButton>
                            </Paper>
                        </Box>

                        <Box sx={{ minHeight: '500px' }}>
                            <MaterialReactTable sx={{ minHeight: '500px' }}
                                columns={columns}
                                data={flatData}
                                displayColumnDefOptions={{
                                    'mrt-row-select': {
                                        maxSize: 12,
                                        header: '',
                                    },
                                    'mrt-row-actions': {
                                        header: '',
                                        maxSize: 30,
                                    },
                                    'mrt-row-expand': {
                                        header: '',
                                        maxSize: 12,
                                        muiTableBodyCellProps: {
                                            sx: { borderLeft: '1px solid rgba(224, 224, 224, 1)', borderRight: '1px solid rgba(224, 224, 224, 1)', borderBottom: '1px solid rgba(224, 224, 224, 1)', pr: 5 },
                                        },
                                        muiTableHeadCellProps: {
                                            sx: {
                                                borderLeft: '1px solid rgba(224, 224, 224, 1)',
                                                borderTop: '1px solid rgba(224, 224, 224, 1)',
                                                backgroundColor: '#00477b', pr: 5
                                            },
                                        },
                                    },
                                }}
                                enableColumnActions={false}
                                enableSorting={false}
                                enableExpandAll={false}
                                enablePagination={false}
                                enableRowNumbers={false}
                                enableRowVirtualization
                                enableHiding={false}
                                enableColumnFilters={false}
                                enableColumnOrdering={false}
                                muiTableBodyRowProps={({ row }) => ({
                                    onClick: () => {
                                        setRowSelection((prev) => ({
                                            [row.id]: true,
                                        }));
                                        //HandleRowClick(row);
                                    },
                                    sx: {
                                        cursor: 'pointer',
                                    },
                                })}
                                positionActionsColumn="last"
                                enableColumnResizing={false}
                                enableDensityToggle={false}
                                enableFullScreenToggle={false}
                                muiTableBodyCellProps={({ column }) => ({
                                    sx: (theme) => ({
                                        color: 'text.primary',
                                        fontSize: theme.typography.body1,
                                        //borderLeft: '1px #BBBFBF solid',
                                        //borderTop: '1px #BBBFBF solid',
                                        borderBottom: '1px #BBBFBF solid',
                                        pl: 2, fontWeight: 400, textAlign: 'center', pt: 1, pb: 1,
                                    }),
                                })
                                }

                                muiTableHeadCellProps={{
                                    sx: (theme) => ({
                                        color: 'text.primary',
                                        fontSize: theme.typography.body2,
                                        borderTop: '1px #BBBFBF solid',
                                        borderBottom: '1px #BBBFBF solid',
                                        //borderLeft: '1px #BBBFBF solid',
                                        fontWeight: 400,
                                        pl: 2, pr: 2,
                                        //letterSpacing: '1px',
                                        //borderRadius: '6px',
                                        pt: 1, pb: 1,
                                    }),
                                }}

                                muiTableContainerProps={{
                                    ref: tableContainerRef,
                                    sx: { maxHeight: '550px', minHeight: '300px' },
                                    onScroll: (
                                        event,
                                    ) => fetchMoreOnBottomReached(event.target),
                                }}
                                muiToolbarAlertBannerProps={
                                    isError
                                        ? {
                                            color: 'error',
                                            children: ErrorMessages.UnknownError,
                                        }
                                        : undefined
                                }
                                onColumnFiltersChange={setColumnFilters}
                                onSortingChange={setSorting}
                                renderBottomToolbarCustomActions={() => (
                                    <Box sx={{ mt: 0.5, pl: 0 }}>
                                        <Typography variant="body2" color="text.blueText" sx={{ pl: 1, fontWeight: 400 }}>
                                            {
                                                !isError && data
                                                    ? totalFetched + ' of ' + totalDBRowCount + ' user(s)'
                                                    : ''
                                            }
                                        </Typography>
                                    </Box>
                                )}
                                onRowSelectionChange={setRowSelection}
                                enableGlobalFilter={false}
                                state={{
                                    columnFilters,
                                    isLoading,
                                    showAlertBanner: isError,
                                    showProgressBars: isFetching,
                                    showSkeletons: isFetching,
                                    rowSelection,
                                    sorting,
                                }}
                                initialState={{
                                }}
                                muiTablePaperProps={{
                                    elevation: 0,
                                }}
                                rowVirtualizerProps={{ overscan: 4 }}
                                localization={{
                                    noRecordsToDisplay: 'No user to display',
                                    selectedCountOfRowCountRowsSelected: '',
                                }}
                                renderTopToolbarCustomActions={({ table }) => {
                                    return (
                                        <Box sx={{
                                            display: 'flex', alignItems: 'center', flexDirection: 'row', pb: 1
                                        }}>
                                            {/*<PeopleAltOutlinedIcon sx={{ fontSize: 24, color: '#61676B', ml: 1 }} />*/}
                                            <Typography variant="body1" color="text.blueText" sx={{ pt: 0, pr: 0, fontWeight: 500, pl: 0.5, borderRadius: '4px' }}>Pending users</Typography>
                                            <Typography variant="body1" color="text.primary" sx={{ pt: 0, pr: 1, fontWeight: 400, pl: 1, borderRadius: '4px', ml: 2 }}>Sort</Typography>
                                            <IconButton sx={{ ml: 1, p: 0, pr: 2 }} onClick={(event) => handleSortMnuClick(event)}>
                                                <ExpandMoreIcon sx={{ fontSize: 24, color: 'Icon.main' }} />
                                            </IconButton>
                                            <Menu
                                                id="basic-menu"
                                                anchorEl={sortMnuAnchorEl}
                                                open={openSort}
                                                onClose={handleSortMnuClose}
                                                MenuListProps={{
                                                    'aria-labelledby': 'basic-button',
                                                }}
                                            >
                                                <MenuItem key={0} sx={{ mt: 1, ml: 1, mr: 1 }} onClick={(event) => handleSortDtNewMenuClick(event)}>
                                                    <ListItemIcon >
                                                        <CheckIcon sx={{ display: tickSrtDtNewDisp.current, fontSize: 24, color: 'Icon.main' }} />
                                                    </ListItemIcon>
                                                    <Typography variant="body1" sx={{ textTransform: 'none' }} color="text.primary">Date: Newest on top</Typography>
                                                </MenuItem>
                                                <MenuItem key={1} sx={{ ml: 1, mr: 1 }} onClick={(event) => handleSortDtOldMenuClick(event)}>
                                                    <ListItemIcon>
                                                        <CheckIcon sx={{ display: tickSrtDtOldDisp.current, fontSize: 24, color: 'Icon.main' }} />
                                                    </ListItemIcon>
                                                    <Typography variant="body1" sx={{ textTransform: 'none' }} color="text.primary">Date: Oldtest on top</Typography>
                                                </MenuItem>
                                                <Divider variant="fullWidth" component="div" sx={{}} />
                                                <MenuItem key={0} sx={{ ml: 1, mr: 1 }} onClick={(event) => firstNameSortMenuClick(event)}>
                                                    <ListItemIcon>
                                                        <CheckIcon sx={{ display: tickSrtFirstNameDisp.current, fontSize: 24, color: 'Icon.main' }} />
                                                    </ListItemIcon>
                                                    <Typography variant="body1" sx={{ textTransform: 'none' }} color="text.primary">Fist name</Typography>
                                                </MenuItem>
                                                <MenuItem key={1} sx={{ ml: 1, mr: 1 }} onClick={(event) => lastNameSortMenuClick(event)}>
                                                    <ListItemIcon>
                                                        <CheckIcon sx={{ display: tickSrtLastNameDisp.current, fontSize: 24, color: 'Icon.main' }} />
                                                    </ListItemIcon>
                                                    <Typography variant="body1" sx={{ textTransform: 'none' }} color="text.primary">Last name</Typography>
                                                </MenuItem>
                                                <MenuItem key={2} sx={{ ml: 1, mr: 1 }} onClick={(event) => emailSortMenuClick(event)}>
                                                    <ListItemIcon>
                                                        <CheckIcon sx={{ display: tickSrtEmailDisp.current, fontSize: 24, color: 'Icon.main' }} />
                                                    </ListItemIcon>
                                                    <Typography variant="body1" sx={{ textTransform: 'none' }} color="text.primary">Email address</Typography>
                                                </MenuItem>
                                                <Divider variant="fullWidth" component="div" sx={{}} />
                                                <MenuItem key={3} sx={{ mb: 2, ml: 1, mr: 1 }} onClick={(event) => roleSortMenuClick(event)}>
                                                    <ListItemIcon>
                                                        <CheckIcon sx={{ display: tickSrtRoleDisp.current, fontSize: 24, color: 'Icon.main' }} />
                                                    </ListItemIcon>
                                                    <Typography variant="body1" sx={{ textTransform: 'none' }} color="text.primary">Role</Typography>
                                                </MenuItem>
                                            </Menu>
                                        </Box>
                                    );
                                }}
                            />
                        </Box>
                    </Grid>

                </Grid>
            </Grid>
        </React.Fragment>
    );
};

const DeactivatedUserList = (props) => {

    const [isDataLoading, setIsDataLoading] = useState(false);
    const [rowSelection, setRowSelection] = useState({});
    const [disEditApp, setDisEditApp] = useState(true);
    const dispatch = useDispatch();
    let sessions = useSelector((state) => state.sessionMgmnt);
    const [imageUrl, setImageUrl] = useState(null);
    const [applicantsName, setApplicantsName] = useState('Applicant');
    const [openDelCnfrmDlg, setOpenDelCnfrmDlg] = useState(false);
    const [errorMsg, setErrorMsg] = useState('');
    const [successMsg, setSuccessMsg] = useState(null);
    const [successMsgTitle, setSuccessMsgTitle] = useState(null);
    const [showInviteDlg, setShowInviteDlg] = useState(false);
    const sortMenuAction = useRef(UserListSortAction.Newest);

    const [userId, setUserId] = useState(props.userId);
    const [sessionId, setSessionId] = useState(props.sessionId);

    const tickSrtDtNewDisp = useRef('block');
    const tickSrtDtOldDisp = useRef('none');
    const tickSrtFirstNameDisp = useRef('none');
    const tickSrtLastNameDisp = useRef('none');
    const tickSrtEmailDisp = useRef('none');
    const tickSrtRoleDisp = useRef('none');


    const [sortMnuAnchorEl, setSortMnuAnchorEl] = useState(null);
    const openSort = Boolean(sortMnuAnchorEl);

    const handleSortMnuClick = (event) => {
        setSortMnuAnchorEl(event.currentTarget);
    };
    const handleSortMnuClose = () => {
        setSortMnuAnchorEl(null);
    };

    const handleSortDtNewMenuClick = async (event) => {
        setSortMnuAnchorEl(null);
        sortMenuAction.current = UserListSortAction.Newest;
        refetch();
        await WriteToActivityLog(ActivityLogEvent.UserManagement, "Sorted deactivated users list by 'Date: Newest on top'.", userId, sessionId);

        SetSortMenuTick(UserListSortAction.Newest);
    }

    const handleSortDtOldMenuClick = async (event) => {
        setSortMnuAnchorEl(null);
        sortMenuAction.current = UserListSortAction.Oldest;
        refetch();
        await WriteToActivityLog(ActivityLogEvent.UserManagement, "Sorted deactivated users list by 'Date: Oldest on top'.", userId, sessionId);

        SetSortMenuTick(UserListSortAction.Oldest);
    }

    const firstNameSortMenuClick = async (event) => {
        setSortMnuAnchorEl(null);
        sortMenuAction.current = UserListSortAction.FirstName;
        refetch();
        await WriteToActivityLog(ActivityLogEvent.UserManagement, "Sorted deactivated users list by 'First name'.", userId, sessionId);

        SetSortMenuTick(UserListSortAction.FirstName);
    }

    const lastNameSortMenuClick = async (event) => {
        setSortMnuAnchorEl(null);
        sortMenuAction.current = UserListSortAction.LastName;
        refetch();
        await WriteToActivityLog(ActivityLogEvent.UserManagement, "Sorted deactivated users list by 'Last name'.", userId, sessionId);

        SetSortMenuTick(UserListSortAction.LastName);
    }

    const emailSortMenuClick = async (event) => {
        setSortMnuAnchorEl(null);
        sortMenuAction.current = UserListSortAction.Email;
        refetch();
        await WriteToActivityLog(ActivityLogEvent.UserManagement, "Sorted deactivated users list by 'Email address'.", userId, sessionId);

        SetSortMenuTick(UserListSortAction.Email);
    }

    const roleSortMenuClick = async (event) => {
        setSortMnuAnchorEl(null);
        sortMenuAction.current = UserListSortAction.RoleName;
        refetch();
        await WriteToActivityLog(ActivityLogEvent.UserManagement, "Sorted deactivated users list by 'Role'.", userId, sessionId);

        SetSortMenuTick(UserListSortAction.RoleName);
    }

    function SetSortMenuTick(item) {

        tickSrtDtNewDisp.current = 'none';
        tickSrtDtOldDisp.current = 'none';
        tickSrtFirstNameDisp.current = 'none';
        tickSrtLastNameDisp.current = 'none';
        tickSrtEmailDisp.current = 'none';
        tickSrtRoleDisp.current = 'none';

        if (item == UserListSortAction.Newest)
            tickSrtDtNewDisp.current = 'block';

        if (item == UserListSortAction.Oldest)
            tickSrtDtOldDisp.current = 'block';

        if (item == UserListSortAction.FirstName)
            tickSrtFirstNameDisp.current = 'block';

        if (item == UserListSortAction.LastName)
            tickSrtLastNameDisp.current = 'block';

        if (item == UserListSortAction.Email)
            tickSrtEmailDisp.current = 'block';

        if (item == UserListSortAction.RoleName)
            tickSrtRoleDisp.current = 'block';

    }

    const columns = [
        {
            accessorKey: 'TitleName',
            header: 'Title',
            muiTableBodyCellProps: ({ cell }) => ({
                sx: (theme) => ({
                    color: 'text.primary',
                    fontSize: theme.typography.body1,
                    borderLeft: '1px #BBBFBF solid',
                    borderBottom: '1px #BBBFBF solid',
                    pl: 2, fontWeight: 400, textAlign: 'center', pt: 1, pb: 1,
                }),
            }),
            muiTableHeadCellProps: ({ cell }) => ({
                sx: (theme) => ({
                    color: 'text.primary',
                    fontSize: theme.typography.body2,
                    borderTop: '1px #BBBFBF solid',
                    borderLeft: '1px #BBBFBF solid',
                    borderBottom: '1px #BBBFBF solid',
                    fontWeight: 400,
                    pl: 2, pr: 2,
                    //letterSpacing: '1px',
                    //borderRadius: '6px',
                    pt: 1, pb: 1,
                }),
            }),
            size: 100
        },
        {
            accessorKey: 'FirstName',
            header: 'First Name',
            Cell: ({ cell, column }) => (
                <Box>
                    {cell.getValue() && cell.getValue().length > 25 ? cell.getValue().substring(0, 25) + '...' : cell.getValue()}
                </Box>
            ),
            size: 230
        },
        {
            accessorKey: 'LastName',
            header: 'Last Name',
            Cell: ({ cell, column }) => (
                <Box>
                    {cell.getValue() && cell.getValue().length > 25 ? cell.getValue().substring(0, 25) + '...' : cell.getValue()}
                </Box>
            ),
            size: 230
        },
        {
            accessorKey: 'Gender',
            header: 'Gender',
            size: 150
        },
        {
            accessorKey: 'DateOfBirth',
            header: 'Date Of Birth',
            Cell: ({ cell, column }) => (
                <Box>
                    {cell.getValue() && cell.getValue().length > 25 ? cell.getValue().substring(0, 25) + '...' : cell.getValue()}
                </Box>
            ),
            size: 230
        },
        {
            accessorKey: 'EmailAddress',
            header: 'Email Address',
            Cell: ({ cell, column }) => (
                <Box>
                    {cell.getValue() && cell.getValue().length > 25 ? cell.getValue().substring(0, 25) + '...' : cell.getValue()}
                </Box>
            ),
            size: 230
        },
        {
            accessorKey: 'PhoneNbr',
            header: 'Phone Number',
            Cell: ({ cell, column }) => (
                <Box>
                    {cell.getValue() && cell.getValue().length > 25 ? cell.getValue().substring(0, 25) + '...' : cell.getValue()}
                </Box>
            ),
            size: 230
        },
        {
            accessorKey: 'MobileNbr',
            header: 'Mobile Number',
            Cell: ({ cell, column }) => (
                <Box>
                    {cell.getValue() && cell.getValue().length > 25 ? cell.getValue().substring(0, 25) + '...' : cell.getValue()}
                </Box>
            ),
            size: 230
        },
        {
            accessorKey: 'RoleName',
            header: 'Role',
            Cell: ({ cell, column }) => (
                <Box>
                    {cell.getValue() && cell.getValue().length > 25 ? cell.getValue().substring(0, 25) + '...' : cell.getValue()}
                </Box>
            ),
            size: 230
        },
        {
            accessorKey: 'CreateDate',
            header: 'Created At',
            Cell: ({ cell, column }) => (
                <Box>
                    {cell.getValue() && cell.getValue().length > 25 ? cell.getValue().substring(0, 25) + '...' : cell.getValue()}
                </Box>
            ),
            size: 230
        },
        {
            accessorKey: 'CreateUser',
            header: 'Created By',
            Cell: ({ cell, column }) => (
                <Box>
                    {cell.getValue() && cell.getValue().length > 25 ? cell.getValue().substring(0, 25) + '...' : cell.getValue()}
                </Box>
            ),
            size: 230
        }
    ];

    const fetchSize = 25;

    const tableContainerRef = useRef(null); //we can get access to the underlying TableContainer element and react to its scroll events
    const virtualizerInstanceRef = useRef < Virtualizer > null; //we can get access to the underlying Virtualizer instance and call its scrollToIndex method

    const [columnFilters, setColumnFilters] = useState([]);
    const [globalFilter, setGlobalFilter] = useState('');
    const [sorting, setSorting] = useState([]);
    const [searchText, setSearchText] = useState(null);

    const { data, fetchNextPage, isError, isFetching, isLoading, refetch } =
        useInfiniteQuery(
            ['table-data', columnFilters, globalFilter, sorting],
            async ({ pageParam = 0 }) => {

                const apiUrl = new URL(
                    process.env.REACT_APP_GET_USER_LIST,
                    process.env.REACT_APP_BASE_URL,
                );

                apiUrl.searchParams.set('start', `${pageParam * fetchSize}`);
                apiUrl.searchParams.set('size', `${fetchSize}`);

                apiUrl.searchParams.set('globalFilter', searchText ?? '');
                apiUrl.searchParams.set('sorting', sortMenuAction.current);
                apiUrl.searchParams.set('isActive', 0);

                let response = await APICall({ url: apiUrl.href, method: 'GET', contentType: 'application/json', responseType: '' }, userId, sessionId);

                if (response !== null && response !== undefined) {
                    return response;
                }
                else {
                    throw Error(ErrorMessages.UnknownError);
                }
            },
            {
                getNextPageParam: (_lastGroup, groups) => groups.length,
                keepPreviousData: true,
                refetchOnWindowFocus: false,
            },
        );

    const flatData = useMemo(
        () => data?.pages.flatMap((page) => page.data) ?? [],
        [data, sortMenuAction],
    );

    const totalDBRowCount = data?.pages?.[0]?.meta?.totalRowCount ?? 0;
    const totalFetched = flatData.length;
    const fetchMoreOnBottomReached = useCallback(
        (containerRefElement) => {
            if (containerRefElement) {
                const { scrollHeight, scrollTop, clientHeight } = containerRefElement;
                if (
                    scrollHeight - scrollTop - clientHeight < 400 &&
                    !isFetching &&
                    totalFetched < totalDBRowCount
                ) {
                    fetchNextPage();
                }
            }
        },
        [fetchNextPage, isFetching, totalFetched, totalDBRowCount],
    );

    useEffect(() => {

        if (virtualizerInstanceRef.current) {
            virtualizerInstanceRef.current.scrollToIndex(0);
        }
    }, [sorting, columnFilters]);

    useEffect(() => {

        fetchMoreOnBottomReached(tableContainerRef.current);
    }, [fetchMoreOnBottomReached]);

    useEffect(() => {

        loadForm();

    }, []);

    const loadForm = async () => {
        await WriteToActivityLog(ActivityLogEvent.UserManagement, "Deactivated users list viewed.", userId, sessionId);
    };

    const handleSearchChange = (event) => {
        setSearchText(event.target.value);
    };

    const handleSearchClick = async (event) => {
        refetch();
        await WriteToActivityLog(ActivityLogEvent.UserManagement, "Searched deactivated users list.", userId, sessionId);
    };

    return (
        <React.Fragment>

            <Grid item>
                <Grid item xs container direction="row" spacing={2} sx={{ pt: 0, pb: 3, }}>
                    <Grid item md={12} sx={{}} justifyContent="center">
                        <Box sx={{ pt: 1 }}>
                            <AlertControl Message={errorMsg} severity="error" color="error" icon={true} />
                        </Box>
                        <Box sx={{ pl: 0, pt: 1, pb: 3, display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                            <Paper elevation={0}
                                sx={{ display: 'flex', alignItems: 'center', width: 600, border: 1, borderColor: '#d5dbdb', }}
                            >
                                <InputBase className="form-control" sx={{ color: '#161616', border: '0px !important' }}
                                    placeholder="Search First name, Last name, Email address" onChange={handleSearchChange}
                                />
                                <IconButton type="button" sx={{ p: '6px' }} aria-label="search" onClick={(event) => handleSearchClick(event)}>
                                    <Search sx={{ color: '#0A0A0A' }} />
                                </IconButton>
                            </Paper>
                        </Box>

                        <Box sx={{ minHeight: '500px' }}>
                            <MaterialReactTable sx={{ minHeight: '500px' }}
                                columns={columns}
                                data={flatData}
                                displayColumnDefOptions={{
                                    'mrt-row-select': {
                                        maxSize: 12,
                                        header: '',
                                    },
                                    'mrt-row-actions': {
                                        header: '',
                                        maxSize: 30,
                                    },
                                    'mrt-row-expand': {
                                        header: '',
                                        maxSize: 12,
                                        muiTableBodyCellProps: {
                                            sx: { borderLeft: '1px solid rgba(224, 224, 224, 1)', borderRight: '1px solid rgba(224, 224, 224, 1)', borderBottom: '1px solid rgba(224, 224, 224, 1)', pr: 5 },
                                        },
                                        muiTableHeadCellProps: {
                                            sx: {
                                                borderLeft: '1px solid rgba(224, 224, 224, 1)',
                                                borderTop: '1px solid rgba(224, 224, 224, 1)',
                                                backgroundColor: '#00477b', pr: 5
                                            },
                                        },
                                    },
                                }}
                                enableColumnActions={false}
                                enableSorting={false}
                                enableExpandAll={false}
                                enablePagination={false}
                                enableRowNumbers={false}
                                enableRowVirtualization
                                enableHiding={false}
                                enableColumnFilters={false}
                                enableColumnOrdering={false}
                                muiTableBodyRowProps={({ row }) => ({
                                    onClick: () => {
                                        setRowSelection((prev) => ({
                                            [row.id]: true,
                                        }));
                                        //HandleRowClick(row);
                                    },
                                    sx: {
                                        cursor: 'pointer',
                                    },
                                })}
                                positionActionsColumn="last"
                                enableColumnResizing={false}
                                enableDensityToggle={false}
                                enableFullScreenToggle={false}
                                muiTableBodyCellProps={({ column }) => ({
                                    sx: (theme) => ({
                                        color: 'text.primary',
                                        fontSize: theme.typography.body1,
                                        borderBottom: '1px #BBBFBF solid',
                                        pl: 2, fontWeight: 400, textAlign: 'center', pt: 1, pb: 1,
                                    }),
                                })
                                }
                                muiTableHeadCellProps={{
                                    sx: (theme) => ({
                                        color: 'text.primary',
                                        fontSize: theme.typography.body2,
                                        borderTop: '1px #BBBFBF solid',
                                        borderBottom: '1px #BBBFBF solid',
                                        fontWeight: 400,
                                        pl: 2, pr: 2,
                                        pt: 1, pb: 1,
                                    }),
                                }}
                                muiTableContainerProps={{
                                    ref: tableContainerRef,
                                    sx: { maxHeight: '450px', minHeight: '300px' },
                                    onScroll: (
                                        event,
                                    ) => fetchMoreOnBottomReached(event.target),
                                }}
                                muiToolbarAlertBannerProps={
                                    isError
                                        ? {
                                            color: 'error',
                                            children: ErrorMessages.UnknownError,
                                        }
                                        : undefined
                                }
                                onColumnFiltersChange={setColumnFilters}
                                onSortingChange={setSorting}
                                renderBottomToolbarCustomActions={() => (
                                    <Box sx={{ mt: 0.5, pl: 0 }}>
                                        <Typography variant="body2" color="text.blueText" sx={{ pl: 1, fontWeight: 400 }}>
                                            {
                                                !isError && data
                                                    ? totalFetched + ' of ' + totalDBRowCount + ' user(s)'
                                                    : ''
                                            }
                                        </Typography>
                                    </Box>
                                )}
                                onRowSelectionChange={setRowSelection}
                                enableGlobalFilter={false}
                                state={{
                                    columnFilters,
                                    isLoading,
                                    showAlertBanner: isError,
                                    showProgressBars: isFetching,
                                    showSkeletons: isFetching,
                                    rowSelection,
                                    sorting,
                                }}
                                initialState={{
                                }}
                                muiTablePaperProps={{
                                    elevation: 0,
                                }}
                                rowVirtualizerProps={{ overscan: 4 }}
                                localization={{
                                    noRecordsToDisplay: 'No user to display',
                                    selectedCountOfRowCountRowsSelected: '',
                                }}
                                renderTopToolbarCustomActions={({ table }) => {
                                    return (
                                        <Box sx={{
                                            display: 'flex', alignItems: 'center', flexDirection: 'row', pb: 1
                                        }}>
                                            {/*<PeopleAltOutlinedIcon sx={{ fontSize: 24, color: '#61676B', ml: 1 }} />*/}
                                            <Typography variant="body1" color="text.blueText" sx={{ pt: 0, pr: 0, fontWeight: 500, pl: 0.5, borderRadius: '4px' }}>Deactivated users</Typography>

                                            {/*<Typography variant="body3" color="text.primary" sx={{ pr: 1, fontWeight: 500, pl: 2, borderRadius: '4px' }}>Deactivated users</Typography>*/}

                                            {/*<Typography variant="body3" color="text.primary" sx={{ pl: 1, pr: 4, fontWeight: 500, letterSpacing: '-1px', ml: 1 }}>Deactivated users</Typography>*/}
                                            {/*<Typography variant="body1" color="text.primary" sx={{ pl: 1, pr: 4, fontWeight: 500, letterSpacing: '-1px' }}>Deactivated users</Typography>*/}
                                            <Typography variant="body1" color="text.primary" sx={{ pt: 0, pr: 1, fontWeight: 400, pl: 1, borderRadius: '4px', ml: 2 }}>Sort</Typography>

                                            {/*<Typography variant="body3" color="text.primary" sx={{ pr: 1, fontWeight: 400, pl: 1, borderRadius: '4px', ml: 2  }}>Sort</Typography>*/}
                                            <IconButton sx={{ ml: 1, p: 0, pr: 2 }} onClick={(event) => handleSortMnuClick(event)}>
                                                <ExpandMoreIcon sx={{ fontSize: 24, color: 'Icon.main' }} />
                                            </IconButton>
                                            <Menu
                                                id="basic-menu"
                                                anchorEl={sortMnuAnchorEl}
                                                open={openSort}
                                                onClose={handleSortMnuClose}
                                                MenuListProps={{
                                                    'aria-labelledby': 'basic-button',
                                                }}
                                            >
                                                <MenuItem key={0} sx={{ mt: 1, ml: 1, mr: 1 }} onClick={(event) => handleSortDtNewMenuClick(event)}>
                                                    <ListItemIcon>
                                                        <CheckIcon sx={{ display: tickSrtDtNewDisp.current, fontSize: 24, color: 'Icon.main' }} />
                                                    </ListItemIcon>
                                                    <Typography variant="body1" sx={{ textTransform: 'none' }} color="text.primary">Date: Newest on top</Typography>
                                                </MenuItem>
                                                <MenuItem key={1} sx={{ ml: 1, mr: 1 }} onClick={(event) => handleSortDtOldMenuClick(event)}>
                                                    <ListItemIcon>
                                                        <CheckIcon sx={{ display: tickSrtDtOldDisp.current, fontSize: 24, color: 'Icon.main' }} />
                                                    </ListItemIcon>
                                                    <Typography variant="body1" sx={{ textTransform: 'none' }} color="text.primary">Date: Oldtest on top</Typography>
                                                </MenuItem>
                                                <Divider variant="fullWidth" component="div" sx={{}} />
                                                <MenuItem key={0} sx={{ ml: 1, mr: 1 }} onClick={(event) => firstNameSortMenuClick(event)}>
                                                    <ListItemIcon>
                                                        <CheckIcon sx={{ display: tickSrtFirstNameDisp.current, fontSize: 24, color: 'Icon.main' }} />
                                                    </ListItemIcon>
                                                    <Typography variant="body1" sx={{ textTransform: 'none' }} color="text.primary">Fist name</Typography>
                                                </MenuItem>
                                                <MenuItem key={1} sx={{ ml: 1, mr: 1 }} onClick={(event) => lastNameSortMenuClick(event)}>
                                                    <ListItemIcon>
                                                        <CheckIcon sx={{ display: tickSrtLastNameDisp.current, fontSize: 24, color: 'Icon.main' }} />
                                                    </ListItemIcon>
                                                    <Typography variant="body1" sx={{ textTransform: 'none' }} color="text.primary">Last name</Typography>
                                                </MenuItem>
                                                <MenuItem key={2} sx={{ ml: 1, mr: 1 }} onClick={(event) => emailSortMenuClick(event)}>
                                                    <ListItemIcon>
                                                        <CheckIcon sx={{ display: tickSrtEmailDisp.current, fontSize: 24, color: 'Icon.main' }} />
                                                    </ListItemIcon>
                                                    <Typography variant="body1" sx={{ textTransform: 'none' }} color="text.primary">Email address</Typography>
                                                </MenuItem>
                                                <Divider variant="fullWidth" component="div" sx={{}} />
                                                <MenuItem key={3} sx={{ mb: 2, ml: 1, mr: 1 }} onClick={(event) => roleSortMenuClick(event)}>
                                                    <ListItemIcon>
                                                        <CheckIcon sx={{ display: tickSrtRoleDisp.current, fontSize: 24, color: 'Icon.main' }} />
                                                    </ListItemIcon>
                                                    <Typography variant="body1" sx={{ textTransform: 'none' }} color="text.primary">Role</Typography>
                                                </MenuItem>
                                            </Menu>
                                        </Box>
                                    );
                                }}
                            />
                        </Box>
                    </Grid>

                </Grid>
            </Grid>
        </React.Fragment>
    );
};
function AllUsers(props) {

    const queryClient = new QueryClient();

    return (
        <React.Fragment>
            <QueryClientProvider client={queryClient}>
                <UserList userId={props.userId} sessionId={props.sessionId} loadingChange={props.loadingChange} />
            </QueryClientProvider>
        </React.Fragment>
    );
}

function AllPendingActivationUsers(props) {

    const queryClient = new QueryClient();

    return (
        <React.Fragment>
            <QueryClientProvider client={queryClient}>
                <PendingActivationUserList userId={props.userId} sessionId={props.sessionId} loadingChange={props.loadingChange} />
            </QueryClientProvider>
        </React.Fragment>
    );
}

function AllDeactivatedUsers(props) {

    const queryClient = new QueryClient();

    return (
        <React.Fragment>
            <QueryClientProvider client={queryClient}>
                <DeactivatedUserList userId={props.userId} sessionId={props.sessionId} loadingChange={props.loadingChange} />
            </QueryClientProvider>
        </React.Fragment>
    );
}

function UserManagement() {

    const location = useLocation();

    const [userId, setUserId] = useState(location.state.userId || -1);
    const [sessionId, setSessionId] = useState(location.state.sessionId || '');
    const [isDataLoading, setIsDataLoading] = useState(false);
    const [disNewUser, setDisNewUser] = useState(true);

    const [expanded, setExpanded] = useState(true);
    const [tabValue, setTabValue] = React.useState(0);

    let navigate = useNavigate();

    const tabStyles = makeStyles({
        indicator: {
            border: "1px solid #16191f",
            backgroundColor: '#0000ff'
        },
    });

    const tabClasses = tabStyles();

    const handleTabChange = (event, newValue) => {
        setTabValue(newValue);
    };

    function handleLoadingState(isLoading) {
        setIsDataLoading(isLoading);
    }

    function a11yProps(index) {
        return {
            id: `simple-tab-${index}`,
            'aria-controls': `simple-tabpanel-${index}`,
        };
    }

    useEffect(() => {

        let canAdd = IsInRole(AOBSystemProcess.UserMaintenance, userId, sessionId);
        if (canAdd)
            setDisNewUser(false);
        else
            setDisNewUser(true);
    }, []);

    function TabPanel(props) {
        const { children, value, index, ...other } = props;

        return (
            <div
                role="tabpanel"
                hidden={value !== index}
                id={`simple-tabpanel-${index}`}
                aria-labelledby={`simple-tab-${index}`}
                {...other}
            >
                {value === index && (
                    <Box sx={{ p: 0 }}>
                        {children}
                    </Box>
                )}
            </div>
        );
    }

    const handleAccordianChange = () => {
        if (expanded) {
            setExpanded(false);
        }
        else {
            setExpanded(true);
        }
    }


    const newUser = (event) => {
        navigate('/User', { state: { userid: -1, loggedInUserId: userId, sessionId: sessionId }, replace: true });
    };


    return (
        <React.Fragment>
            <AutoLagoutTimer userId={userId} sessionId={sessionId} />
            <Container maxWidth="lg" sx={{}} className="">
                <OverlaySpinner disappear={isDataLoading} />
                <Paper className="back-grid">
                    <PostLoginMenu userId={userId} sessionId={sessionId} />
                    <Grid item md={12} id="aobhead">
                        <Box sx={{
                            pr: 0,
                            pl: 0, pt: 0
                        }}>
                            <EnrollmentHeader />
                        </Box>
                    </Grid>
                    <Grid item sx={{ pr: 1 }}>
                        <DrawerAppBar userId={userId} sessionId={sessionId} />
                    </Grid>
                    <Grid item xs container direction="row" spacing={0} sx={{ pt: 0 }}>
                        <Grid item xs={12} sx={{}}>
                            <Box sx={{ display: 'flex', pb: 2, pt: 0, ml: 6.5, mr: 6.5, justifyContent: 'space-between', alignItems: 'flex-end', borderBottom: '0px solid #898F94' }}>
                                <Typography variant="h6" color="text.primary" sx={{ pl: 0, pb: 0, lineHeight: 1, fontWeight: 500, pb: 0, letterSpacing: '0px' }} >User Management</Typography>
                            </Box>
                            <Box sx={{ }}>
                                <Divider variant="fullWidth" component="div" />
                            </Box>
                            <Box sx={{ mt: 0, pt: 3, pb: 2, ml: 6.5, mr: 6.5, borderTop: '0px solid #A8B3B3', borderBottom: '0px solid #d2d2d2' }}>
                                <Stack direction="column" sx={{}}>
                                    <Typography sx={{ pl: 0, fontWeight: 400, }} variant="body4" color="text.primary">
                                        User management will enable admins to control user access and on-board and off-board users to and from this application.
                                    </Typography>
                                </Stack>
                            </Box>
                        </Grid>
                        <Grid item xs={12} sx={{pt: 1}}>
                            <Box sx={{ mt: 0, ml: 6.5 }}>
                                <Stack direction="column" spacing={1.5} sx={{}}>
                                    <Box>
                                        <Button variant="contained" size="small" disabled={disNewUser} style={{ borderRadius: '20px', padding: '0.3rem 1.8rem', textTransform: 'none'}} onClick={(e) => newUser(e)}>
                                            New User
                                        </Button>
                                    </Box>
                                    {/*    <Box>*/}
                                    {/*        <Button variant="contained" disabled={disNewUser} size="small" style={{ boxShadow: 'none', border: 0, borderRadius: '20px', padding: '0.4rem 2.7rem', outline: 'none', }} onClick={(e) => inviteUser(e)}>*/}
                                    {/*            INVITE USER*/}
                                    {/*        </Button>*/}
                                    {/*    </Box>*/}
                                </Stack>
                            </Box>
                        </Grid>
                    </Grid>
                    <Grid item>
                        <Box sx={{ ml: 6.5, mr: 6.5, mb: 0, mt: 2, borderTop: '0px solid #D5D9D9', }}>
                            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                                <Tabs value={tabValue} TabIndicatorProps={{
                                    sx: {
                                        backgroundColor: 'rgb(31, 107, 230)',
                                    },
                                }} onChange={handleTabChange} sx={{ height: '50px' }}>
                                    <Tab label="Users" {...a11yProps(0)} sx={{ height: '50px', textTransform: 'none', fontSize: '1rem', fontWeight: 500 }} />
                                    <Tab label="Pending" {...a11yProps(1)} sx={{ height: '50px', textTransform: 'none', fontSize: '1rem', fontWeight: 500 }} />
                                    <Tab label="Deactivated" {...a11yProps(2)} sx={{ height: '50px', textTransform: 'none', fontSize: '1rem', fontWeight: 500 }} />
                                </Tabs>
                            </Box>
                            {tabValue === 0 && (
                                <AllUsers userId={userId} sessionId={sessionId} loadingChange={handleLoadingState} />
                            )}
                            {tabValue === 1 && (
                                <AllPendingActivationUsers userId={userId} sessionId={sessionId} loadingChange={handleLoadingState} />
                            )}
                            {tabValue === 2 && (
                                <AllDeactivatedUsers userId={userId} sessionId={sessionId} loadingChange={handleLoadingState} />
                            )}
                        </Box>
                    </Grid>
                    <Footer userId={userId} />
                </Paper>
            </Container>
        </React.Fragment>
    );
}

function UserListBreadcrumb() {
    return (
        <React.Fragment >

            <Box sx={{ borderBottom: '1px solid #cdcfd2', pt: 0, pb: 3 }}>
                <Typography variant="h4" color="text.primary" sx={{ lineHeight: 1, fontWeight: 300, letterSpacing: '-1px' }} >User Management</Typography>
            </Box>


            {/*    <Box sx={{ ml: 1, mr: 2, border: '0px solid #000', pt: 0 }}>*/}
            {/*        <Typography variant="h4" color="text.primary" sx={{ borderBottom: '1px solid #ccc', lineHeight: 2.5, fontWeight: 500, letterSpacing: '-1px' }} >User Management</Typography>*/}
            {/*    </Box>*/}
        </React.Fragment>
    );
}

export default UserManagement;