import React, { useEffect, useState, useRef } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, Typography, IconButton, Grid, Paper, Box, label, Switch } from '@mui/material';
import LoadingOverlay from 'react-loading-overlay';
import RiseLoader from 'react-spinners/RiseLoader'
import CloseIcon from '@mui/icons-material/Close';
import AlertControl from '../components/Controls/AlertControl';
import { ErrorMessages } from '../common/ErrorMessages';
import { APICall, WriteToActivityLog } from '../global/Api';
import { ActivityLogEvent } from '../common/AOBEnum';
import Checkbox, { checkboxClasses } from "@mui/material/Checkbox";
import { FormGroup, FormControlLabel } from '@mui/material';

const TwoStepVerifyConfigDlg = (props) => {

    const { onClose, open, onChange, enrmntid } = props;
    const [isLoading, setIsLoading] = useState(false);
    const [errorMsg, setErrorMsg] = useState('');
    const [checked, setChecked] = React.useState(false);
    const [checkedInDB, setCheckedInDB] = React.useState(false);
    const [userId, setUserId] = useState(props.userId);
    const [sessionId, setSessionId] = useState(props.sessionId);
    const [okButtonEnabled, setOkButtonEnabled] = useState(false);

     const handleChange = (evt) => {
        setChecked(evt.target.checked);
        if (evt.target.checked != checkedInDB)
            setOkButtonEnabled(true);
        else
            setOkButtonEnabled(false);
    };

    const handleClose = (event) => {
        event.preventDefault();
        onClose();
    };

    const handleOk = async (event) => {

        try {

            const inputJson = {
                IsTwoStepVerifySet: checked,
            };

            setIsLoading(true);

            const apiUrl = new URL(
                process.env.REACT_APP_UPDATE_USER_TWOSTEP_CONFIG,
                process.env.REACT_APP_BASE_URL,
            );

            let response = await APICall({ url: apiUrl.href, method: 'POST', payload: inputJson, contentType: 'application/json', responseType: '' }, userId, sessionId);

            if (response !== null && response !== undefined) {

                await WriteToActivityLog(ActivityLogEvent.ProfileScreen, "2-Step Verification turned " + (checked ? "on." : "off."), userId, sessionId);

                onClose();
                onChange();
            }
        }
        catch (err) {
            setErrorMsg(ErrorMessages.UnknownError);
        }
        finally {
            setIsLoading(false);
        }
    }

    const loadScreen = async () => {

        try {

            setIsLoading(true);

            let apiUrl = new URL(
                process.env.REACT_APP_GET_USER_TWOSTEP_CONFIG,
                process.env.REACT_APP_BASE_URL,
            );

            let response = await APICall({ url: apiUrl.href, method: 'GET', contentType: 'application/json', responseType: '' }, userId, sessionId);

            if (response) {
                setCheckedInDB(response.data[0].IsTwoStepVerifySet);
                setChecked(response.data[0].IsTwoStepVerifySet);
            }
        }
        catch (err) {
            setErrorMsg(ErrorMessages.UnknownError);
        }
        finally {
            setIsLoading(false);
        }
    }

    useEffect(() => {

        loadScreen();

    }, []);

    return (
        <React.Fragment>
            <Dialog onClose={handleClose} open={open} keepMounted>
                <LoadingOverlay active={isLoading} spinner={<RiseLoader color={'#1D50AF'} />} >

                    <DialogTitle>
                        <Typography variant="body1" color="text.primary" sx={{ fontWeight: 500 }}>
                            2-Step Verification
                        </Typography>
                        
                        <IconButton
                            aria-label="close"
                            onClick={handleClose}
                            sx={{
                                position: 'absolute',
                                right: 8,
                                top: 8,
                                color: (theme) => theme.palette.Icon.main,
                            }}
                        >
                            <CloseIcon />
                        </IconButton>
                    </DialogTitle>
                    <DialogContent dividers>
                        <AlertControl Message={errorMsg} severity="error" color="error" icon={true} />
                        <Grid container>
                            <Grid item md={12} sx={{}}>
                                <Box sx={{ ml: 3, mr: 3 }}>
                                    <Typography sx={{ fontWeight: 400, mt: 1 }} gutterBottom variant="body1" color="text.primary">
                                        Protect your account with 2-Step verification.
                                    </Typography>
                                    <Box sx={{pt: 1.5}}>
                                        <Typography sx={{ fontWeight: 400, }} gutterBottom variant="body4" color="text.primary">
                                            In addition to your password, 2-Step verification adds a quick second step to verify that it’s you.
                                        </Typography>
                                    </Box>
                                </Box>
                            </Grid>
                            <Grid item md={10} sx={{ pt: 2, ml: -1 }}>
                                <Box sx={{ ml: 4, mr: 4 }}>
                                    {/*<Switch sx={{}} checked={checked} onChange={handleChange} />*/}
                                    <FormGroup sx={{ pb: 1 }}>
                                        <FormControlLabel control={<Checkbox checked={checked} sx={{
                                            [`&, &.${checkboxClasses.checked}`]: {
                                                color: 'Check.main',
                                            },
                                        }} onChange={handleChange} />} label={<Typography variant="body1" color="text.primary" >2-Step Verification</Typography>} />
                                    </FormGroup>
                                </Box>
                            </Grid>
                            <Grid item md={11} sx={{ pt: 3, pb: 2 }}>
                            </Grid>
                        </Grid>
                    </DialogContent>
                    <DialogActions sx={{ mr: 2 }}>
                        <Button autoFocus onClick={handleClose} variant="contained" sx={{ mt: 1, mb: 1, borderRadius: '20px', padding: '0.25rem 2.2rem', mr: 1.5, textTransform: 'none' }}>
                                Cancel
                        </Button>
                        <Button onClick={handleOk} disabled={!okButtonEnabled} variant="contained" sx={{ mt: 1, mb: 1, borderRadius: '20px', padding: '0.25rem 2.5rem', mr: 1.5, textTransform: 'none' }}>
                                Ok
                        </Button>
                    </DialogActions>
                </LoadingOverlay>
            </Dialog>
        </React.Fragment>
    );
}

export default TwoStepVerifyConfigDlg;


