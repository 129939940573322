import React, { useEffect, useState } from 'react';
import { FormControlLabel, Box, FormControl, TextField, Typography, Button, Paper, Divider } from '@mui/material';
import OverlaySpinner from '../Controls/OverlaySpinner';
import AlertControl from '../Controls/AlertControl';
import AlertWithTitle from '../Controls/AlertWithTitle';
import { ErrorMessages } from '../../common/ErrorMessages';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { APICall, WriteToActivityLog } from '../../global/Api';
import { ActivityLogEvent } from '../../common/AOBEnum';
import Checkbox, { checkboxClasses } from "@mui/material/Checkbox";

const DocusignConfiguration = (props) => {

    const [docuSignIntegrationKey, setDocuSignIntegrationKey] = useState('');
    const [docuSignUserId, setDocuSignUserId] = useState('');
    const [authServer, setAuthServer] = useState('');
    const [apiType, setApiType] = useState('');
    const [privateKey, setPrivateKey] = useState('');
    const [applicationBasePath, setApplicationBasePath] = useState('');
    const [docuSignAccountId, setDocuSignAccountId] = useState('');
    const [docuSignIdVerificationType, setdocuSignIdVerificationType] = useState('');
    const [docuSignIdVerificationReq, setdocuSignIdVerificationReq] = useState(false);
    const [docuSignIdVerificationDef, setdocuSignIdVerificationDef] = useState(false);
    const [intitialRequired, setIntitialRequired] = useState(true);
    const [bankSignRequired, setBankSignRequired] = useState(true);

    const [errorMsg, setErrorMsg] = useState(null);
    const [successMsg, setSuccessMsg] = useState('');
    const [isDataLoading, setIsDataLoading] = useState(false);
    const [submit, setSubmit] = useState(false);

    const [userId, setUserID] = useState(props.userId  || -1);
    const [sessionId, setSessionId] = useState(props.sessionId  || '');

    const loadConfigGroup = async () => {
        try {

            const apiUrl = new URL(
                process.env.REACT_APP_GET_DOCUSIGN_CONFIG_SCREEN,
                process.env.REACT_APP_BASE_URL,
            );

            setIsDataLoading(true);

            let response = await APICall({ url: apiUrl.href, method: 'GET', contentType: 'application/json', responseType: '' }, userId, sessionId);

            if (response !== null && response !== undefined) {
                if (response["data"] && response["data"].length > 0) {
                    setDocuSignIntegrationKey(response.data[0].ClientId);
                    setDocuSignUserId(response.data[0].UserId);
                    setAuthServer(response.data[0].AuthServer);
                    setApiType(response.data[0].APIType);
                    setPrivateKey(response.data[0].PrivateKey);
                    setApplicationBasePath(response.data[0].AppBasePath);
                    setDocuSignAccountId(response.data[0].AccountId);
                    setdocuSignIdVerificationType(response.data[0].IdentityVerificationType);
                    setdocuSignIdVerificationReq(response.data[0].IdentityVerificationRequired);
                    setdocuSignIdVerificationDef(response.data[0].IdentityVerificationDefault);
                    setIntitialRequired(response.data[0].InitialRequired);
                    setBankSignRequired(response.data[0].BankSignRequired);
                }
                else {
                    setErrorMsg(ErrorMessages.UnknownError);
                }
            }
            else {
                console.error("Response is null or undefined while fetching Docusign Configuration information.");
                setErrorMsg(ErrorMessages.UnknownError);
            }
        }
        catch (err) {
            setErrorMsg(ErrorMessages.UnknownError);
        }
        finally {
            setIsDataLoading(false);
        }
        return null;
    }

    useEffect(() => {
        setIsDataLoading(true);
        loadConfigGroup();
    }, []);


    const docusignConfigurationForm = useFormik({
        enableReinitialize: true,
        initialValues: {
            DocuSignIntegrationKey: docuSignIntegrationKey,
            DocuSignUserId: docuSignUserId,
            AuthServer: authServer,
            ApiType: apiType,
            PrivateKey: privateKey,
            ApplicationBasePath: applicationBasePath,
            DocuSignAccountId: docuSignAccountId,
            DocuSignIdVerificationType: docuSignIdVerificationType,
            DocuSignIdVerificationReq: docuSignIdVerificationReq,
            DocuSignIdVerificationDef: docuSignIdVerificationDef,
            IntitialRequired: intitialRequired,
            BankSignRequired: bankSignRequired
        },
        validationSchema: yup.object({
            DocuSignIntegrationKey: yup.string()
                .trim('Integration Key - cannot contain leading and trailing spaces')
                .required('Integration Key - required'),
            DocuSignUserId: yup.string()
                .trim('DocuSign User Id - cannot contain leading and trailing spaces')
                .required('DocuSign User Id - required'),
            AuthServer: yup.string()
                .trim('Authorization Server - cannot contain leading and trailing spaces')
                .required('Authorization Server - required'),
            ApiType: yup.string()
                .trim('DocuSign API Type - cannot contain leading and trailing spaces')
                .required('DocuSign API Type - required'),
            PrivateKey: yup.string()
                .trim('Private Key - cannot contain leading and trailing spaces')
                .required('Private Key - required'),
            ApplicationBasePath: yup.string()
                .trim('Application Base Path - cannot contain leading and trailing spaces')
                .required('Application Base Path - required'),
            DocuSignAccountId: yup.string()
                .trim('DocuSign Account Id - cannot contain leading and trailing spaces')
                .required('DocuSign Account Id - required')
        }),
        onSubmit: values => {
            submitClick(values);
        }
    })

    const submitClick = async (values) => {

        setErrorMsg('');
        setSuccessMsg('');

        try {

            setIsDataLoading(true);

            let inputJson = {
                ClientId: values.DocuSignIntegrationKey,
                UserId: values.DocuSignUserId,
                AuthServer: values.AuthServer,
                APIType: values.ApiType,
                PrivateKey: values.PrivateKey,
                AppBasePath: values.ApplicationBasePath,
                AccountId: values.DocuSignAccountId,
                IdentityVerificationType: values.DocuSignIdVerificationType,
                IdentityVerificationRequired: values.DocuSignIdVerificationReq,
                IdentityVerificationDefault: values.DocuSignIdVerificationDef,
                InitialRequired: values.IntitialRequired,
                BankSignRequired: values.BankSignRequired,
            };

            const apiUrl = new URL(
                process.env.REACT_APP_Save_DOCUSIGN_CONFIG,
                process.env.REACT_APP_BASE_URL,
            );

            let response = await APICall({ url: apiUrl.href, method: 'POST', payload: inputJson, contentType: 'application/json', responseType: '' }, userId, sessionId);

            if (response !== null && response !== undefined) {
                if (response.meta.Updated === "1") {
                    await WriteToActivityLog(ActivityLogEvent.SystemOptions, "Saved DocuSign configuration.", userId, sessionId);
                    setSuccessMsg("DocuSign configuration has been successfully saved.");
                }
                else {
                    setErrorMsg(response.Errors[0].Message);
                }
            }
        }
        catch (err) {
            setErrorMsg(ErrorMessages.UnknownError);
        }
        finally {
            setIsDataLoading(false);
        }

    }

    return (
        <>
            <Box component="main" sx={{ flex: 1, height: '100%', pt: 0, pl: 6 }} >
                <Box >
                    <Typography variant='body2' color="text.blueText" sx={{ fontWeight: 500, pb: 1.5, lineHeight: 1, borderBottom: '0px solid #9FA4A8' }}>Docusign Configuration</Typography>
                    <Divider variant="fullWidth" component="div" sx={{ }} />
                    <div >
                        <form onSubmit={docusignConfigurationForm.handleSubmit} id="docuSignInfoForm">
                            <OverlaySpinner disappear={isDataLoading} />
                            <AlertControl Message={errorMsg} severity="error" color="error" icon={true} />
                            <AlertWithTitle Message={successMsg} severity="success" color="success" icon={true} Title="Docusign Configuration" />
                            <Box sx={{pt: 2}}>
                                <label htmlFor="DocuSignIntegrationKey" className="form-label">DocuSign Integration Key</label>
                                <input type="text" id="DocuSignIntegrationKey" name="DocuSignIntegrationKey" className="form-control" value={docusignConfigurationForm.values.DocuSignIntegrationKey} onChange={docusignConfigurationForm.handleChange} />
                                {
                                    docusignConfigurationForm.touched.DocuSignIntegrationKey && docusignConfigurationForm.errors.DocuSignIntegrationKey ? (<p className="text-danger">{docusignConfigurationForm.errors.DocuSignIntegrationKey}</p>) : null
                                }
                            </Box>
                            <Box sx={{ pt: 2 }}>
                                <label htmlFor="DocuSignUserId" className="form-label">DocuSign User</label>
                                <input type="text" id="DocuSignUserId" name="DocuSignUserId" className="form-control" value={docusignConfigurationForm.values.DocuSignUserId} onChange={docusignConfigurationForm.handleChange} />
                                {
                                    docusignConfigurationForm.touched.DocuSignUserId && docusignConfigurationForm.errors.DocuSignUserId ? (<p className="text-danger">{docusignConfigurationForm.errors.DocuSignUserId}</p>) : null
                                }
                            </Box>
                            <Box sx={{ pt: 2 }}>
                                <label htmlFor="DocuSignAccountId" className="form-label">DocuSign Account</label>
                                <input type="text" id="DocuSignAccountId" name="DocuSignAccountId" className="form-control" value={docusignConfigurationForm.values.DocuSignAccountId} onChange={docusignConfigurationForm.handleChange} />
                                {
                                    docusignConfigurationForm.touched.DocuSignAccountId && docusignConfigurationForm.errors.DocuSignAccountId ? (<p className="text-danger">{docusignConfigurationForm.errors.DocuSignAccountId}</p>) : null
                                }
                            </Box>
                            <Box sx={{ pt: 2 }}>
                                <label htmlFor="AuthServer" className="form-label">DocuSign Authentication Server</label>
                                <input type="text" id="AuthServer" name="AuthServer" className="form-control" value={docusignConfigurationForm.values.AuthServer} onChange={docusignConfigurationForm.handleChange} />
                                {
                                    docusignConfigurationForm.touched.AuthServer && docusignConfigurationForm.errors.AuthServer ? (<p className="text-danger">{docusignConfigurationForm.errors.AuthServer}</p>) : null
                                }
                            </Box>

                            <Box sx={{ pt: 2 }}>
                                <label htmlFor="ApiType" className="form-label">DocuSign API Type</label>
                                <input type="text" id="ApiType" name="ApiType" className="form-control" value={docusignConfigurationForm.values.ApiType} onChange={docusignConfigurationForm.handleChange} />
                                {
                                    docusignConfigurationForm.touched.ApiType && docusignConfigurationForm.errors.ApiType ? (<p className="text-danger">{docusignConfigurationForm.errors.ApiType}</p>) : null
                                }
                            </Box>
                            <Box sx={{ pt: 2 }}>
                                <label htmlFor="ApplicationBasePath" className="form-label">Application Base Path</label>
                                <input type="text" id="ApplicationBasePath" name="ApplicationBasePath" className="form-control" value={docusignConfigurationForm.values.ApplicationBasePath} onChange={docusignConfigurationForm.handleChange} />
                                {
                                    docusignConfigurationForm.touched.ApplicationBasePath && docusignConfigurationForm.errors.ApplicationBasePath ? (<p className="text-danger">{docusignConfigurationForm.errors.ApplicationBasePath}</p>) : null
                                }
                            </Box>
                            <Box sx={{ pt: 2 }}>
                                <label htmlFor="PrivateKey" className="form-label">Private Key</label>
                                <textarea style={{ minHeight: '300px' }} name="PrivateKey" id="PrivateKey" className="form-control" value={docusignConfigurationForm.values.PrivateKey} onChange={docusignConfigurationForm.handleChange} />
                                {
                                    docusignConfigurationForm.touched.PrivateKey && docusignConfigurationForm.errors.PrivateKey ? (<p className="text-danger">{docusignConfigurationForm.errors.PrivateKey}</p>) : null
                                }
                            </Box>
                            <Box sx={{ pt: 2, pb: 1 }}>
                                <label htmlFor="DocuSignIdVerificationType" className="form-label">DocuSign Identity Verification</label>
                                <input type="text" id="DocuSignIdVerificationType" name="DocuSignIdVerificationType" className="form-control" value={docusignConfigurationForm.values.DocuSignIdVerificationType} onChange={docusignConfigurationForm.handleChange} />
                                {
                                    docusignConfigurationForm.touched.DocuSignIdVerificationType && docusignConfigurationForm.errors.DocuSignIdVerificationType ? (<p className="text-danger">{docusignConfigurationForm.errors.DocuSignIdVerificationType}</p>) : null
                                }
                            </Box>
                            <FormControl variant="outlined" sx={{ width: '100%' }} >
                                <FormControlLabel color="text.primary" control={<Checkbox sx={{
                                    [`&, &.${checkboxClasses.checked}`]: {
                                        color: 'Check.main',
                                    },
                                }} name="DocuSignIdVerificationReq" id="DocuSignIdVerificationReq" checked={docusignConfigurationForm.values.DocuSignIdVerificationReq} onChange={docusignConfigurationForm.handleChange} />} label={<Typography sx={{ fontWeight: 400 }} variant="body4" color="text.primary">DocuSign Identity Verification Required</Typography>} />
                            </FormControl>
                            {
                                docusignConfigurationForm.touched.DocuSignIdVerificationReq && docusignConfigurationForm.errors.DocuSignIdVerificationReq ? (<p className="text-danger">{docusignConfigurationForm.errors.DocuSignIdVerificationReq}</p>) : null
                            }
                            <FormControl variant="outlined" sx={{ width: '100%' }} >
                                <FormControlLabel color="text.primary" control={<Checkbox sx={{
                                    [`&, &.${checkboxClasses.checked}`]: {
                                        color: 'Check.main',
                                    },
                                }} name="DocuSignIdVerificationDef" id="DocuSignIdVerificationDef" checked={docusignConfigurationForm.values.DocuSignIdVerificationDef} onChange={docusignConfigurationForm.handleChange} />} label={<Typography sx={{ fontWeight: 400 }} variant="body4" color="text.primary">DocuSign Identity Verification Default</Typography>} />
                            </FormControl>
                            {
                                docusignConfigurationForm.touched.DocuSignIdVerificationDef && docusignConfigurationForm.errors.DocuSignIdVerificationDef ? (<p className="text-danger">{docusignConfigurationForm.errors.DocuSignIdVerificationDef}</p>) : null
                            }
                            <FormControl variant="outlined" sx={{ width: '100%' }} >
                                <FormControlLabel color="text.primary" control={<Checkbox sx={{
                                    [`&, &.${checkboxClasses.checked}`]: {
                                        color: 'Check.main',
                                    },
                                }} name="IntitialRequired" id="IntitialRequired" checked={docusignConfigurationForm.values.IntitialRequired} onChange={docusignConfigurationForm.handleChange} />} label={<Typography sx={{ fontWeight: 400 }} variant="body4" color="text.primary">DocuSign Initial Required</Typography>} />
                            </FormControl>
                            {
                                docusignConfigurationForm.touched.IntitialRequired && docusignConfigurationForm.errors.IntitialRequired ? (<p className="text-danger">{docusignConfigurationForm.errors.IntitialRequired}</p>) : null
                            }
                            <FormControl variant="outlined" sx={{ width: '100%' }} >
                                <FormControlLabel color="text.primary" control={<Checkbox sx={{
                                    [`&, &.${checkboxClasses.checked}`]: {
                                        color: 'Check.main',
                                    },
                                }} name="BankSignRequired" id="BankSignRequired" checked={docusignConfigurationForm.values.BankSignRequired} onChange={docusignConfigurationForm.handleChange} />} label={<Typography sx={{ fontWeight: 400 }} variant="body4" color="text.primary">Bank Signature Required</Typography>} />
                            </FormControl>
                            {
                                docusignConfigurationForm.touched.BankSignRequired && docusignConfigurationForm.errors.BankSignRequired ? (<p className="text-danger">{docusignConfigurationForm.errors.BankSignRequired}</p>) : null
                            }
                            <Box sx={{pt: 2}}>
                                <Button type="submit" variant="contained" size="large" style={{ boxShadow: 'none', border: 0, borderRadius: '20px', padding: '0.3rem 2.5rem', outline: 'none', }}>
                                    Save
                                </Button>
                            </Box>
                        </form>
                    </div>
                </Box>
            </Box >
        </>
    )
}
export default DocusignConfiguration;