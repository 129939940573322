////import 'bootstrap/dist/css/bootstrap.css';
import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import { CookiesProvider } from "react-cookie";
import store from './redux/sessionStore';
import { Provider } from 'react-redux';
import AOBApp from './AOBApp';
import { ThemeContextProvider } from "./Theme/ThemeContextProvider";

//import registerServiceWorker from './registerServiceWorker';

const baseUrl = document.getElementsByTagName('base')[0].getAttribute('href');
//const rootElement = document.getElementById('root');


const rootElement = ReactDOM.createRoot(document.getElementById('root'));

rootElement.render(
    <Provider store={store}>
        <ThemeContextProvider>
            <CookiesProvider>
                <BrowserRouter basename={baseUrl}>
                    <AOBApp />
                </BrowserRouter>
            </CookiesProvider>
        </ThemeContextProvider>
    </Provider>);

if ('serviceWorker' in navigator) {
    navigator.serviceWorker.ready.then(registration => {
        registration.unregister();

        if (caches) {
            // Service worker cache should be cleared with caches.delete()
            caches.keys().then(async (names) => {
                await Promise.all(names.map(name => caches.delete(name)));
            });
        }
    });
}

