import React, { useEffect, useState, useRef } from 'react';
import { Dialog, DialogTitle, DialogContent, Box, DialogActions, Button, Typography, IconButton, Grid, Toolbar } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import AppViewControl from '../global/AppViewCtrl';
import AppBar from '@mui/material/AppBar';

const AppViewDlg = (props) => {

    const { onClose, open } = props;
    const [userId, setUserId] = useState(props.userId);
    const [sessionId, setSessionId] = useState(props.sessionId);

    return (
        <Dialog
            fullScreen
            open={open}
            onClose={onClose}
            scroll="paper"
        >
            <AppBar sx={{ position: 'relative' }}>
                <Toolbar>
                    <IconButton
                        edge="start"
                        color="inherit"
                        onClick={onClose}
                        aria-label="close"
                    >
                        <CloseIcon />
                    </IconButton>
                    <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
                        Application
                    </Typography>
                    {/*<Button autoFocus color="inherit">*/}
                    {/*    View*/}
                    {/*</Button>*/}
                </Toolbar>
            </AppBar>
            <AppViewControl enrollData={props.enrollData} enrmntid={props.enrmntid} acctType={props.acctType} applicantImage={props.applicantImage} avtarImage={props.avtarImage} applicantName={props.applicantName} applicantEmail={props.applicantEmail} applicantMobile={props.applicantMobile} JointOwnerName={props.JointOwnerName} JointOwnerEmail={props.JointOwnerEmail} userId={userId} sessionId={sessionId} />
        </Dialog>
    );
};

export default AppViewDlg;
