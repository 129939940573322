//import Cookies from "js-cookie";
import * as $ from 'jquery';
import React, { Component, createRef } from "react";
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate, useLocation } from "react-router-dom";
import 'reactjs-popup/dist/index.css';
import { ApiHelper } from '../customFunctions/apiHelper';
import BreadCumControl from './Controls/BreadcumControl';
import LoaderControl from './Controls/LoaderControl';
import EnrollmentBanner from './EnrollmentBanner';
import Footer from './Footer';
import './Modal.css';
import { NavMenu } from './NavMenu';
import './TemplateDesignPreview.css';

//JQuery alias
window.jQuery = $;
window.$ = $;

//For Element Drag and Drop
require("jquery-ui-sortable");
// For FormBuilder
require("formBuilder");

require('formBuilder/dist/form-render.min.js')

//For Loading isMandatory Elements.

//document.body.style.margin = "30px"; //For add margin in HTML body

const showPreview = (formData) => {

    //var fbTemplate = document.getElementById("build-wrap");
    toggleEdit(false);

    var renderOpts = {
        //controlConfig: {
        //    'break': { }
        //},
        i18n: {
            location: process.env.REACT_APP_I18N_LANG
        },
        formData: formData,
        dataType: 'json',
        render: true
    };
   
    $(".render-wrap").formRender(renderOpts);
    //formData.unshift(renderOpts);
    //$(".render-wrap").formRender({ formData });
}
const toggleEdit = (editing) => {
    document.body.classList.toggle("form-rendered", !editing); 
    var acontionBtnDiv = document.getElementById("divActionBtngrp");
    var objhFrmEle = document.getElementById("hFrmEle");
    if (acontionBtnDiv !== null && objhFrmEle !== null)
    {
        if (!editing) {

            acontionBtnDiv.style.visibility = "hidden";
            objhFrmEle.style.visibility = "hidden";
        }
        else {
            acontionBtnDiv.style.visibility = "visible";
            objhFrmEle.style.visibility = "visible";
        }
    }
}


const saveData = (templateId, formData, history, templateStsId, token, saveEdit, sessions, dispatch) => {
   
    const alertContent = document.getElementById('divTemplateAlert');
    const templateNmContent = document.getElementById('txtTemplateName');
    const templateDescContent = document.getElementById('txtTemplateDesc');
    alertContent.style.display = 'none';

    let templateNm = templateNmContent.value;
    let templateDesc = templateDescContent.value;

    if (templateNm === '' || templateNm === null) {
        alertContent.innerHTML = 'Template Name - required.';
        alertContent.style.display = '';
        alertContent.scrollIntoView();
        templateNmContent.focus();
        return;
    }
    let logInUsrId = parseInt(null);
    sessions.map((sn) => { if (sn.key === 'LoginUserId') { logInUsrId = parseInt(sn.value); } });

    templateId = parseInt(templateId);

    let headers = {
        'Content-Type': 'application/json'
    };

    const inputJson = {
        ['templateId']: templateId,
        ['templateName']: templateNm,
        ['templateDesc']: templateDesc,
        ['templateValue']: formData,
        ['crtUsrId']: logInUsrId,
        ['modUsrId']: logInUsrId,
        ['templateStatusId']: templateStsId
    };

    ApiHelper({ url: process.env.REACT_APP_CREATEMODIFY_TEMPLATE, method: 'POST', headers: headers, body: inputJson, sessions: sessions, dispatch: dispatch })
        .then(resposnse => {
            if (resposnse !== null && resposnse != "undefined") {
                //templateId = parseInt(resposnse.templateId);
                if (parseInt(resposnse.templateId) > 0) {
                    if (!saveEdit) {
                        history.push({
                            pathname: '/DashBoard'
                        });
                    }
                    //templateId = parseInt(resposnse.templateId);
                    //history.push({
                    //    pathname: '/TemplateUI',
                    //    state: { templateid: templateId }
                    //});
                } else {
                    alertContent.innerHTML = resposnse.StatusMsg;
                    alertContent.style.display = '';
                    alertContent.scrollIntoView();
                    templateNmContent.focus();
                }

            }
        });
}

//Custom user attribute

let emptyStyleOptions = {
    '': ''
    //'form-control row-1 col-md-6 x2': 'Second Column'
    //'float-md-right row-1 col-md-6': 'Second Column'
    //'offset-md-6 offset-xl-6 offset-lg-6 col-sm-12 col-md-6 col-xl-6 col-lg-6 form-control': 'Second Column'
};

let colStyleOptions = {
    'form-control': '',
    'form-control row-1 col-md-6 x1': 'First Column/Second Column'
    //'form-control row-1 col-md-6 x2': 'Second Column'
    //'float-md-right row-1 col-md-6': 'Second Column'
    //'offset-md-6 offset-xl-6 offset-lg-6 col-sm-12 col-md-6 col-xl-6 col-lg-6 form-control': 'Second Column'
};

let colSelectStyleOptions = {
    'form-select': '',
    'form-select row-1 col-md-6 x1': 'First Column/Second Column'
    //'form-control row-1 col-md-6 x2': 'Second Column'
    //'float-md-right row-1 col-md-6': 'Second Column'
    //'offset-md-6 offset-xl-6 offset-lg-6 col-sm-12 col-md-6 col-xl-6 col-lg-6 form-control': 'Second Column'
};
let colSelectStyleClass = {
    label: 'Style',
    multiple: false, // optional, omitting generates normal <select>    
    options: colSelectStyleOptions
}

let colStyleClass = {
    label: 'Style',
    multiple: false, // optional, omitting generates normal <select>    
    options: colStyleOptions
}

const templateSts = {
    inprogress: 33,
    publish:34
}

const typeUserAttrs = {
    text: {       
        //className: colStyleClass,
        maxlength: 20000,
        onclick: {
            label: 'onClick Event',
            value: '',
            type: 'textarea'
        },
        onkeyup: {
            label: 'onKeyup Event',
            value: '',
            type: 'textarea'
        },
        onload: {
            label: 'onLoad Event',
            value: '',
            type: 'textarea'
        },
        disabled: {
            label: 'Disabled',
            value: false,
            type: 'checkbox'
        }
    },
    select: {
        onchange: {
            label: 'onChange Event',
            value: '',
            type: 'textarea'
        }
    },
    file: {
        //className: colStyleClass,
        disabledAttrs: [
            //'multiple',
            'subtype',
            'access'//,            
        ],
        'maxfilesize': {
            'label': 'Max File Size (MB)',
            'value': '4',
            'type': 'number'
        }
    },
    button: {
        //className: colStyleClass,
    },
    'checkbox-group': {
        onclick: {
            label: 'onClick Event',
            value: '',
            type: 'textarea'
        },
        onchange: {
            label: 'onChange Event',
            value: '',
            type: 'textarea'
        },
        onload: {
            label: 'onLoad Event',
            value: '',
            type: 'textarea'
        }
    },
    number: {
        //className: colStyleClass,
    },
    //header: {
    //    className: '',
    //    title: {
    //        label: 'Title',
    //        value: 'Field Title',
    //    }
    //},
    //"radio-group": {
    //    className: colStyleClass,
    //},
    textarea: {
        //className: colStyleClass,
    },
    date: {
        //className: colStyleClass,
    }
};
const typeUserDisabledAttrs =  {
    'file': [
        'subtype',
        'type',
        'access'
    ]
}

const stickyControl = {
    stickyControls: {
        enable: true,
        offset: {
            top: 20,
            right: 20,
            left: 'auto'
        }
    }
}
//Custom Attribute End

//For Initialize formBuilder
class FormBuilder extends Component {    
    fb = createRef();

    constructor(props) {
        super(props);
        toggleEdit(true);
        this.state = { loading: false, templateName: '', templateId: '0' };
    }

    componentDidMount() {  

        //let jsonObj = Cookies.get("token");
        let authToken = this.props.token;
        let accessToken = authToken["Access_Token"];
        let tokenType = authToken["Token_Type"];
        
        let headers = {
            'Content-Type': 'application/json'
        };

        let templateId = this.props.templateid;
        //let templateNm = this.props.templatename;
        //let templateDesc = this.props.templatedesc;
        let history = this.props.history;
        let hasSavedData = false;
        if ((templateId !== null || templateId !== 'undefined'))
            hasSavedData = parseInt(templateId) > 0;

        let apiUrl = process.env.REACT_APP_GET_FORMCONTROL + (hasSavedData ? this.props.templateid : 'null');

        this.setState({ loading: true });

        ApiHelper({ url: apiUrl, method: 'GET', headers: headers, sessions: this.props.sessions, dispatch: this.props.dispatch })
            .then(resposnse => {

                if (resposnse !== null && resposnse["FormControls"] !== 'undefined') {
                    let controlOrder = resposnse["FormControls"];
                    let delControlOrder = resposnse["DeletedFormControls"];
                    let formData = hasSavedData ? (resposnse["TemplateValues"])[0].templateValue: resposnse["FormDataGenrics"];
                    let templateData = (resposnse["TemplateDatas"]);
                    let lastModDt = hasSavedData ? '<p><small>Last modified on ' + (resposnse["TemplateValues"])[0].lastModifiedOn + '</small></p>' : '';
                    let templateNm = hasSavedData ? (resposnse["TemplateValues"])[0].templateName : '';
                    let templateDesc = hasSavedData ? (resposnse["TemplateValues"])[0].templateDesc : '';
                    //let allAcctType = '<input type="checkbox" id="allAcctType" name="allAcctType" checked> </input><label for= "allAcctType" > All Account Type</label >'
                    //this.setState({ templateName: this.props.templatename });

                    //Template Control => Name & Desc
                    let templateCntrlDivStrt = '<div class="template-design">'
                    let alertText = '<div class="alert alert-custom alert-danger" role="alert" id="divTemplateAlert" style="display: none;"></div>'
                    let templateLabelCntrl = '<label class="template-name">Template Name<span class="required-asterisk" style="display:inline"> *</span></label>'
                    let templateNameCntrl = '<input type="text" id="txtTemplateName" required class="form-control" placeholder="Template Name" autoComplete="off" value="' + templateNm + '" />'
                    let templateDescLabelCntrl = '<label class="template-desc">Description</label>'
                    let templateDescCntrl = '<textarea id="txtTemplateDesc" class = "form-control" rows = "2" placeholder = "Description" autoComplete = "off" >' + templateDesc + '</textarea>'
                    //let templateCntrlDivEnd = '</div>'

                    let options = {
                        inputSets: templateData,
                        controlPosition: 'left',
                        controlOrder: controlOrder,
                        disableFields: delControlOrder,
                        prepend: templateCntrlDivStrt + alertText + templateLabelCntrl + templateNameCntrl + templateDescLabelCntrl + templateDescCntrl,// + templateCntrlDivEnd,
                        //'<h4>' + this.props.templatename + '</h4>' + lastModDt + '<hr/>',
                        stickyControls: stickyControl.stickyControls,
                        typeUserAttrs: typeUserAttrs,
                        typeUserDisabledAttrs: typeUserDisabledAttrs,
                        disableHTMLLabels: true,
                        fieldRemoveWarn: true,
                        scrollToFieldOnAdd: true,
                        stickyControls: {
                            enable: true
                        },
                        disabledAttrs: [
                            'access'//,
                            //'name'
                            //,'className'
                        ],
                        i18n: {
                            location: process.env.REACT_APP_I18N_LANG
                        },
                        formData: formData,
                        disabledActionButtons: ['clear', 'data','save'],
                        //onSave: function (evt, formData) {
                        //    saveData(templateId, formData, history, templateSts.inprogress, authToken);
                        //}
                        //actionButtons: [
                        //    //{
                        //    //    id: 'btnPublish',
                        //    //    className: 'btn btn-secondary',
                        //    //    label: 'Publish',
                        //    //    type: 'button',
                        //    //    events: {
                        //    //        click: function (evt, formData) {
                        //    //            saveData(templateId, formBuilder.formData, history, templateSts.publish, authToken);
                        //    //        }
                        //    //    }
                        //    //},
                        //    {
                        //    id: 'btnPreview',
                        //    className: 'btn btn-secondary',
                        //    label: 'Preview',
                        //    type: 'button',
                        //    events: {
                        //        click: function (evt, formData) {                                    
                        //            showPreview(formBuilder.formData);
                        //        }
                        //    }
                        //}]                                             
                    };                   
                    var formBuilder = $(this.fb.current).formBuilder(options);
                    document.getElementById("extrnlsaveDataEdit").addEventListener("click", () => {
                        //console.log("external save clicked");
                        const result = formBuilder.actions.save();
                        saveData(templateId, formBuilder.formData, history, templateSts.inprogress, authToken, true, this.props.sessions, this.props.dispatch );
                    });
                    document.getElementById("extrnlsaveData").addEventListener("click", () => {
                        //console.log("external save clicked");
                        const result = formBuilder.actions.save();
                        saveData(templateId, formBuilder.formData, history, templateSts.inprogress, authToken, false, this.props.sessions, this.props.dispatch );
                    });
                    document.getElementById("extrnlpublishData").addEventListener("click", () => {
                        //console.log("external save clicked");
                        const result = formBuilder.actions.save();
                        saveData(templateId, formBuilder.formData, history, templateSts.publish, authToken, false, this.props.sessions, this.props.dispatch );
                    });
                    document.getElementById("extrnlpreviewData").addEventListener("click", () => {
                        //console.log("external save clicked");
                        const result = formBuilder.actions.save();
                        showPreview(formBuilder.formData);
                    });
                    this.setState({ loading: false });
                }
            });
    }

    render() {
        return (
            <>
                <EnrollmentBanner />
                <section className="text-left form-designer">
                    <div className="container pb-5 pt-2">
                        <div className="row">
                            <NavMenu />
                        </div>
                        <div className="row">
                            <BreadCumControl pathname="Form Template" />
                        </div>
                        <div className="row">
                            <div className="col-lg-10">                                
                                <LoaderControl show={this.state.loading} />                                                           
                                <div className="heading m-t-10">
                                    <h5 className="text-center p-t-0 p-b-1" style={{fontSize: '30px', fontWeight: 400}}>Form Designer</h5>
                                    <p className="text-center p-b-1" style={{fontSize:'16px'}}>Design your Account Form using this designer</p>
                                </div>
                                <button id="edit-form" onClick={() => toggleEdit(true)} className="btn btn-default btn-sm"> ✖ </button>   
                                <div className="render-wrap"></div>
                                <div id="divActionBtngrp" className="m-t-7 m-b-7" style={{ float: 'right' }}>
                                    <button id="extrnlsaveDataEdit" className="btn btn-info lh-1  m-e-2 p-s-3 p-e-3 p-t-1 p-b-1"><i className="far fa-save mx-1"></i>Save & Edit</button>
                                    <button id="extrnlsaveData" className="btn btn-info lh-1  m-e-2 p-s-3 p-e-3 p-t-1 p-b-1"><i className="fa fa-save mx-1"></i>Save & Close</button>
                                    <button id="extrnlpublishData" className="btn btn-info lh-1 m-e-2 p-s-3 p-e-3 p-t-1 p-b-1"><i className="fa fa-upload mx-1"></i>Publish</button>
                                    <button id="extrnlpreviewData" className="btn btn-info lh-1 p-s-3 p-e-3 p-t-1 p-b-1"><i className="fa fa-search mx-1"></i>Preview</button>
                                </div>
                                <h6 className="m-t-14" id="hFrmEle">Form Elements</h6>
                                
                                <div className="m-t-0" id="fb-editor" ref={this.fb} />
                            </div>
                        </div>
                    </div>
                </section>
                <Footer />
            </>);
    }
}

export function TemplateDesign() {    
    const location = useLocation();
    const history = useNavigate();
    const sessions = useSelector((state) => state.sessionMgmnt);
    const dispatch = useDispatch();

    let token = '';
    //sessions.map((sn) => (sn.key === 'AuthToken' ? token = sn.value : token = ''));
    sessions.map((sn) => { if (sn.key === 'AuthToken') { token = sn.value; } });
   
    return (
        <FormBuilder templatename={location.state.templatename} templatedesc={location.state.templatedesc} templateid={location.state.templateid} history={history} token={token} sessions={sessions} dispatch={dispatch}/>
    );
}
