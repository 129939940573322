import React, { useEffect, useState, useRef } from 'react';
import { Box, TableContainer, paper, TableHead, Dialog, Divider, Paper, Checkbox, DialogTitle, Stack, DialogContent, DialogActions, Button, Typography, IconButton, Grid, FormControl, FormControlLabel } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import AlertControl from '../components/Controls/AlertControl';
import { useDispatch, useSelector } from 'react-redux';
import { ErrorMessages } from '../common/ErrorMessages';
import { LoadProfilePic } from '../global/Helpers';
import profimg from '../assets/images/profimg.jpg';
import { UploadUserFiles } from '../global/APIUtils';
import LoadingOverlay from 'react-loading-overlay';
import RiseLoader from 'react-spinners/RiseLoader'
import { APICall, WriteToActivityLog } from '../global/Api';
import { GetLoggedInUserEmail } from '../global/APIUtils';
import { EnrollmentStatus, ActivityLogEvent } from '../common/AOBEnum';
import CheckIcon from '@mui/icons-material/Check';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';

const DocuSignHistDlg = (props) => {

    const { onClose, open, onResend, ...other } = props;
    const [errorMsg, setErrorMsg] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [enrmntid, setEnrmntid] = useState(props.enrmntid || -1);
    const [userId, setUserId] = useState(props.userId);
    const [sessionId, setSessionId] = useState(props.sessionId);
    const [warningMsg, setWarningMsg] = useState('');
    const [attachmentid, setAttachmentid] = useState(props.attachmentid || -1);

    const [docuSignHist, setDocuSignHist] = useState(null);

    const handleClose = (event) => {
        onClose();
    };

    const loadDetails = async () => {

        try {

            setIsLoading(true);

            await WriteToActivityLog(ActivityLogEvent.DocuSign, "'DocuSign History' dialog opened. Application name '" + props.enrmntNm + "'.", userId, sessionId);

            let apiUrl = new URL(
                process.env.REACT_APP_GET_DOCUSIGN_HIST,
                process.env.REACT_APP_BASE_URL,
            );

            apiUrl.searchParams.set('enrmntId', `${parseInt(enrmntid)}`);
            apiUrl.searchParams.set('attachmentId', `${parseInt(attachmentid)}`);

            let response = await APICall({ url: apiUrl.href, method: 'GET', contentType: 'application/json', responseType: '' }, userId, sessionId);

            if (response !== null && response !== undefined) {

                if (response["data"] && response["data"].length > 0) {

                    setDocuSignHist(response["data"]);
                }
                else {

                    let apiErrors = response["Errors"];

                    if (apiErrors != undefined && apiErrors.length > 0) {
                        setErrorMsg(apiErrors[0].Message);
                    }
                    else {
                        setDocuSignHist(null);
                        setWarningMsg('No DocuSign activity occurred till date.');
                    }
                }
            }
        }
        catch (err) {
            setErrorMsg(ErrorMessages.UnknownError);
        }
        finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        loadDetails();
    }, []);

    return (
        <Dialog onClose={handleClose} open={open} keepMounted fullWidth maxWidth="md" scroll="paper">
            <DialogTitle sx={{ }}>
                <Typography variant="body1" color="text.primary" sx={{ fontWeight: 500 }}>
                    DocuSign History
                </Typography>
                <IconButton
                    aria-label="close"
                    onClick={handleClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
            </DialogTitle>
            <DialogContent dividers={true}>
                <LoadingOverlay active={isLoading} spinner={<RiseLoader color={'#1D50AF'} />} >

                    <AlertControl Message={errorMsg} severity="error" color="error" icon={true} />
                    <Box sx={{ pl: 2, pr: 2, pb: 1 }}>
                    <Grid container>
                        <Grid item md={11} sx={{}}>
                            <Typography variant="body1" color="text.primary" sx={{ fontWeight: 400, pb: 1 }}>
                                The History provides document name and a list of sender and recipient activities on that document to date.
                            </Typography>
                                <Typography variant="body1" color="#f00" sx={{ pt: 1, fontWeight: 400, pb: 1 }}>
                                {warningMsg}
                            </Typography>
                            {/*<Divider light />*/}
                        </Grid>
                        <Grid item md={11} sx={{}}>
                            {
                                docuSignHist ?
                                        <TableContainer component={Paper} sx={{ minWidth: '800px' }}>
                                        <Table aria-label="simple table" sx={{ border: '0px solid #e0e0e0', pt: 2, pl: 1, }}>
                                            <TableBody>
                                                {
                                                    docuSignHist.map((item) => (
                                                        <React.Fragment>
                                                            <TableRow sx={{}}>
                                                                <TableCell colSpan="2" scope="row" sx={{ pt: 1, pb: 1.5, pl: 0, borderBottom: '1px solid #cdcfd2' }}>
                                                                    <Typography className="" variant="body1" color="text.primary" sx={{ fontWeight: 500 }}>
                                                                        {item.FileName}
                                                                    </Typography>
                                                                </TableCell>
                                                            </TableRow>
                                                            <TableRow sx={{}}>
                                                                <TableCell scope="row" sx={{ width: '150px', pt: 0, pb: 0, pl: 2, border: '1px solid #cdcfd2' }}>
                                                                    <Typography className="" variant="body1" color="text.primary" sx={{ fontWeight: 500 }}>
                                                                        Signer1:
                                                                    </Typography>
                                                                </TableCell>
                                                                <TableCell scope="row" sx={{ pt: 0, pb: 0, pl: 1, border: '1px solid #cdcfd2' }}>
                                                                    <Typography className="" variant="body1" color="text.primary" sx={{ fontWeight: 400 }}>
                                                                        {item.Signer1Email}
                                                                    </Typography>
                                                                </TableCell>
                                                            </TableRow>
                                                            {
                                                                item.Signer2Email !== '' ?
                                                                    <React.Fragment>
                                                                        <TableRow sx={{}}>
                                                                            <TableCell scope="row" sx={{ width: '100px', pt: 0, pb: 0, pl: 2, border: '1px solid #cdcfd2' }}>
                                                                                <Typography className="" variant="body1" color="text.primary" sx={{ fontWeight: 500 }}>
                                                                                    Signer2:
                                                                                </Typography>
                                                                            </TableCell>
                                                                            <TableCell scope="row" sx={{ pt: 0, pb: 0, pl: 1, border: '1px solid #cdcfd2' }}>
                                                                                <Typography className="" variant="body1" color="text.primary" sx={{ fontWeight: 400 }}>
                                                                                    {item.Signer2Email}
                                                                                </Typography>
                                                                            </TableCell>
                                                                        </TableRow>
                                                                    </React.Fragment>
                                                                    : ''
                                                            }
                                                            {
                                                                item.Signer3Email !== '' ?
                                                                    <React.Fragment>
                                                                        <TableRow sx={{}}>
                                                                            <TableCell scope="row" sx={{ width: '100px', pt: 0, pb: 0, pl: 2, border: '1px solid #cdcfd2' }}>
                                                                                <Typography className="" variant="body1" color="text.primary" sx={{ fontWeight: 500 }}>
                                                                                    Signer{item.Signer2Email !== '' ? 3 : 2}:
                                                                                </Typography>
                                                                            </TableCell>
                                                                            <TableCell scope="row" sx={{ pt: 0, pb: 0, pl: 1, border: '1px solid #cdcfd2' }}>
                                                                                <Typography className="" variant="body1" color="text.primary" sx={{ fontWeight: 400 }}>
                                                                                    {item.Signer3Email}
                                                                                </Typography>
                                                                            </TableCell>
                                                                        </TableRow>
                                                                    </React.Fragment>
                                                                    : ''
                                                            }
                                                            {
                                                                item.EnvelopeHistories !== null && item.EnvelopeHistories.length > 0 ?
                                                                <TableRow sx={{}}>
                                                                    <TableCell colSpan="2" scope="row" sx={{ pt: 1, pb: 1, pl: 0, borderBottom: '1px solid #cdcfd2' }}>
                                                                            {/*<TableContainer component={paper} sx={{}}>*/}
                                                                            <Table aria-label="simple table" sx={{ border: '0px solid #e0e0e0', pt: 2, pl: 1 }}>
                                                                                <TableHead>
                                                                                    <TableRow sx={{}}>
                                                                                        <TableCell sx={{ p: 0, pl: 1, fontSize: '15px', border: '1px solid #cdcfd2' }}>
                                                                                            <div style={{ whiteSpace: 'nowrap', fontSize: '15px' }}>
                                                                                                Time
                                                                                            </div>
                                                                                        </TableCell>
                                                                                        <TableCell sx={{ p: 0, pl: 1, fontSize: '15px', border: '1px solid #cdcfd2' }}>
                                                                                            <div style={{ whiteSpace: 'nowrap', fontSize: '15px' }}>
                                                                                                Action
                                                                                            </div>
                                                                                        </TableCell>
                                                                                        <TableCell sx={{ p: 0, pl: 1, fontSize: '15px', border: '1px solid #cdcfd2' }}>
                                                                                            <div style={{ whiteSpace: 'nowrap', fontSize: '15px' }}>
                                                                                                Activity
                                                                                            </div>
                                                                                        </TableCell>
                                                                                        <TableCell sx={{ p: 0, pl: 1, fontSize: '15px', border: '1px solid #cdcfd2' }}>
                                                                                            <div style={{ whiteSpace: 'nowrap', fontSize: '15px' }}>
                                                                                                User
                                                                                            </div>
                                                                                        </TableCell>
                                                                                        <TableCell sx={{ p: 0, pl: 1, fontSize: '15px', border: '1px solid #cdcfd2' }}>
                                                                                            <div style={{ whiteSpace: 'nowrap', fontSize: '15px' }}>
                                                                                                Status
                                                                                            </div>
                                                                                        </TableCell>
                                                                                    </TableRow>
                                                                                </TableHead>
                                                                                <TableBody>
                                                                                    {
                                                                                        item.EnvelopeHistories.map((envItem) => (
                                                                                            <TableRow sx={{}}>
                                                                                                <TableCell scope="row" sx={{ pt: 0, pb: 0, pl: 2, border: '1px solid #cdcfd2' }}>
                                                                                                    <div style={{ whiteSpace: 'nowrap', fontSize: '15px'}}>
                                                                                                        {envItem.LogTime}
                                                                                                    </div>
                                                                                                </TableCell>
                                                                                                <TableCell scope="row" sx={{ pt: 0, pb: 0, pl: 1, border: '1px solid #cdcfd2' }}>
                                                                                                    <div style={{ whiteSpace: 'nowrap', fontSize: '15px' }}>
                                                                                                        {envItem.Action}
                                                                                                    </div>
                                                                                                </TableCell>
                                                                                                <TableCell scope="row" sx={{ pt: 0, pb: 0, pl: 1, border: '1px solid #cdcfd2' }}>
                                                                                                    <div style={{ whiteSpace: 'nowrap', fontSize: '15px' }}>
                                                                                                        {envItem.Message}
                                                                                                    </div>
                                                                                                </TableCell>
                                                                                                <TableCell scope="row" sx={{ pt: 0, pb: 0, pl: 1, border: '1px solid #cdcfd2' }}>
                                                                                                    <div style={{ whiteSpace: 'nowrap', fontSize: '15px' }}>
                                                                                                        {envItem.UserName}
                                                                                                    </div>
                                                                                                </TableCell>
                                                                                                <TableCell scope="row" sx={{ pt: 0, pb: 0, pl: 1, border: '1px solid #cdcfd2' }}>
                                                                                                    <div style={{ whiteSpace: 'nowrap', fontSize: '15px' }}>
                                                                                                        {envItem.EnvelopeStatus}
                                                                                                    </div>
                                                                                                </TableCell>
                                                                                            </TableRow>
                                                                                        ))
                                                                                    }
                                                                                </TableBody>
                                                                            </Table>
                                                                        {/*</TableContainer>*/}
                                                                    </TableCell>
                                                                    </TableRow>
                                                                : ''
                                                            }
                                                        </React.Fragment>
                                                    ))
                                                }
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                    :
                                    ''
                            }
                        </Grid>
                        </Grid>
                    </Box>
                </LoadingOverlay>

            </DialogContent>
            <DialogActions sx={{ mr: 2 }}>
                <Button onClick={handleClose} variant="contained" sx={{ mt: 1, mb: 1, borderRadius: '20px', padding: '0.25rem 2.5rem', mr: 1.5, textTransform: 'none' }}>
                    Ok
                </Button>
            </DialogActions>
        </Dialog>
    );
}

export default DocuSignHistDlg;
