import React, { useState, useRef, useEffect } from 'react';
import { useLocation } from "react-router-dom";
import { Paper, Grid, Box, Container, Typography, Stack, Link, IconButton, Button, Divider } from '@mui/material';
import OverlaySpinner from './Controls/OverlaySpinner';
import PostLoginMenu from './PostLoginMenu'
import EnrollmentHeader from './EnrollmentHeader'
import { DrawerAppBar } from './AppNavbar'
import AlertControl from './Controls/AlertControl';
import Footer from './Footer';
import AutoLagoutTimer from '../global/IdleMonitor';
import BankInfo from './SystemParameters/BankInfo';
import EmailTemplateData from './SystemParameters/EmailTemplateData';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import LocalPostOfficeOutlinedIcon from '@mui/icons-material/LocalPostOfficeOutlined';
import AssignmentTurnedInOutlinedIcon from '@mui/icons-material/AssignmentTurnedInOutlined';
import DocusignConfiguration from './SystemParameters/DocuSignConfig';
import PasswordPolicy from './SystemParameters/PasswordPolicy';
import DocusignSigners from './SystemParameters/DocuSignSigners';
import SupportGroup from './SystemParameters/SupportGroup';
import { ActivityLogEvent } from '../common/AOBEnum';
import { WriteToActivityLog } from '../global/Api';

const SystemConifg = () => {

    let sections = {
        bankInfo: 1,
        emailTemplate: 2,
        docuSignConfig: 3,
        PasswordPolicy: 4,
        DocuSignSigner: 5,
        SupportGroup: 6
    };

    const location = useLocation();
    const [selectedSection, setSelectedSection] = useState(sections.bankInfo);

    const [userId, setUserId] = useState(location.state.userId || -1);
    const [sessionId, setSessionId] = useState(location.state.sessionId || '');
    const [isLoading, setIsLoading] = useState(false);
    const [errorMsg, setErrorMsg] = useState('');

    useEffect(() => {
        loadForm();

    }, []);

    const loadForm = async () => {
        await WriteToActivityLog(ActivityLogEvent.SystemOptions, "Viewed system options page.", userId, sessionId);
    };

    return (
        <React.Fragment>
            <AutoLagoutTimer userId={userId} sessionId={sessionId} />
            <Container maxWidth="lg" sx={{}} className="">
                <OverlaySpinner disappear={isLoading} />
                <Paper className="back-grid">
                    <PostLoginMenu userId={userId} sessionId={sessionId} />
                    <Grid item md={12} id="aobhead">
                        <Box sx={{
                            pr: 0,
                            pl: 0, pt: 0
                        }}>
                            <EnrollmentHeader />
                        </Box>
                    </Grid>
                    <Grid item sx={{ pr: 1 }}>
                        <DrawerAppBar userId={userId} sessionId={sessionId} />
                    </Grid>
                    <Grid item container direction="row" spacing={0} sx={{ pt: 0, pb: 0, }}>
                        <Grid item md={12} sx={{ pr: 0 }}>
                            <Box sx={{ pl: 0, pt: 0 }}>
                                <Box sx={{ display: 'flex', pb: 2, pt: 0, ml: 6.5, mr: 6.5, justifyContent: 'space-between', alignItems: 'flex-end', borderBottom: '0px solid #9FA4A8' }}>
                                    <Typography variant="h6" color="text.primary" sx={{ pl: 0, pb: 0, lineHeight: 1, fontWeight: 500, pb: 0, letterSpacing: '0px' }} >System Options</Typography>
                                </Box>
                                <Box sx={{  }}>
                                    <Divider variant="fullWidth" component="div" sx={{ }} />
                                </Box>
                                <Box sx={{ mt: 0, pt: 3, pb: 2.5, ml: 6.5, mr: 6.5, borderTop: '0px solid #A8B3B3', borderBottom: '0px solid #d2d2d2' }}>
                                    <Stack direction="column" sx={{}}>
                                        <Typography sx={{ pl: 0, fontWeight: 400, }} variant="body1" color="text.primary">
                                            The System options enables you to configure different system parameters to your needs.
                                        </Typography>
                                    </Stack>
                                </Box>
                                <Box sx={{}}>
                                    <AlertControl Message={errorMsg} severity="error" color="error" icon={true} />
                                </Box>
                            </Box>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} sx={{ pl: 0, pb: 4 }}>
                        <Grid item xs container direction="row" spacing={0} sx={{ pt: 0.5 }}>
                            <Grid item xs={12} md={3} sx={{}}>
                                <Box sx={{ ml: 6.5, minHeight: '600px', border: '1px solid #EBEBEB', borderTop: '2px solid #EBEBEB' }}>
                                    <Stack direction="column">
                                        <Divider variant="fullWidth" component="div" sx={{ }} />
                                        <Box sx={{ ml: 0, mr: 4, mt: 1, mb: 1, pl: 2 }} >
                                            <Button sx={{}} onClick={() => setSelectedSection(sections.bankInfo)} >
                                                <Typography variant="body1" sx={{ textTransform: 'none', fontWeight: 400 }} color="text.primary">Bank Information</Typography>
                                            </Button>
                                        </Box>
                                        <Divider variant="fullWidth" component="div" sx={{ ml: 0, mt: 1, mb: 1, mr: 0 }} />
                                        <Box sx={{ ml: 0, mr: 4, pl: 2, }} >
                                            <Button sx={{ mr: 0, }} onClick={() => setSelectedSection(sections.emailTemplate)} >
                                                <Typography variant="body1" sx={{ textTransform: 'none', fontWeight: 400 }} color="text.primary">Email Template</Typography>
                                            </Button>
                                        </Box>
                                        <Divider variant="fullWidth" component="div" sx={{ ml: 0, mt: 2, mb: 1, mr: 0 }} />

                                        <Box sx={{ ml: 0, mr: 4, pl: 2 }} >
                                            <Button sx={{ mr: 0, }} onClick={() => setSelectedSection(sections.docuSignConfig)} >
                                                <Typography variant="body1" sx={{ textTransform: 'none', fontWeight: 400 }} color="text.primary">DocuSign Settings</Typography>
                                            </Button>
                                        </Box>
                                        <Divider variant="fullWidth" component="div" sx={{ ml: 0, mt: 2, mb: 2, mr: 0 }} />
                                        <Box sx={{ ml: 0, mr: 4, pl: 2 }} >
                                            <Button sx={{ mr: 0, }} onClick={() => setSelectedSection(sections.DocuSignSigner)} >
                                                <Typography variant="body1" sx={{ textTransform: 'none', fontWeight: 400 }} color="text.primary">DocuSign Signers</Typography>
                                            </Button>
                                        </Box>
                                        <Divider variant="fullWidth" component="div" sx={{ ml: 0, mt: 2, mb: 2, mr: 0 }} />
                                        <Box sx={{ ml: 0, mr: 4, pl: 2 }} >
                                            <Button sx={{ mr: 0, }} onClick={() => setSelectedSection(sections.PasswordPolicy)} >
                                                <Typography variant="body1" sx={{ textTransform: 'none', fontWeight: 400 }} color="text.primary">Password Policy</Typography>
                                            </Button>
                                        </Box>
                                        <Divider variant="fullWidth" component="div" sx={{ ml: 0, mt: 2, mb: 2, mr: 0 }} />
                                    {/*    <Box sx={{ ml: 0, mr: 4, pl: 2 }} >*/}
                                    {/*        <Button sx={{ mr: 0, }} onClick={() => setSelectedSection(sections.SupportGroup)} >*/}
                                    {/*            <Typography variant="body4" sx={{ textTransform: 'none', fontWeight: 400 }} color="text.primary">Support Group</Typography>*/}
                                    {/*        </Button>*/}
                                    {/*    </Box>*/}
                                    {/*    <Divider variant="fullWidth" component="div" sx={{ background: '#D4D4D8', ml: 0, mt: 2, mb: 2, mr: 0 }} />*/}
                                    </Stack>
                                </Box>
                            </Grid>
                            <Grid item xs={12} md={7} sx={{ pr: 3, pt: 2 }}>
                                {
                                    (selectedSection === sections.bankInfo) ?
                                        <BankInfo userId={userId} sessionId={sessionId} />
                                        :
                                        (selectedSection === sections.emailTemplate) ?
                                            <EmailTemplateData userId={userId} sessionId={sessionId} />
                                            :
                                            (selectedSection === sections.docuSignConfig) ?
                                                <DocusignConfiguration userId={userId} sessionId={sessionId} />
                                                :
                                                (selectedSection === sections.DocuSignSigner) ?
                                                    <DocusignSigners userId={userId} sessionId={sessionId} />
                                                    :
                                                    (selectedSection === sections.PasswordPolicy) ?
                                                        <PasswordPolicy userId={userId} sessionId={sessionId} />
                                                        :
                                                        //(selectedSection === sections.SupportGroup) ?
                                                        //    <SupportGroup userId={userId} sessionId={sessionId} /> :
                                                        <div><h5>Invalid operation!!</h5></div>

                                }
                                {/*<BankInfo userId={userId} sessionId={sessionId} />*/}
                            </Grid>
                        </Grid>
                    </Grid>
                    <Footer userId={userId} />
                </Paper>
            </Container>
        </React.Fragment>
    );
}

export default SystemConifg;