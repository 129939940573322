import React, { useState, useEffect, useRef } from 'react';
import { useNavigate, useLocation } from "react-router-dom";
import moment from "moment";
import LoaderControl from './Controls/LoaderControl';
import DashBoardCreateApplication from './DashBoard/DashBoardCreateApplication';
import { AddEditEnrollment } from './DashBoard/Modal/AddEditEnrollment';
import { EnrollmentReviewModel } from './DashBoard/Modal/EnrollmentReviewModel';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { ApiHelper } from '../customFunctions/apiHelper';
import AlertControl from './Controls/AlertControl';
import PostLoginMenu from './PostLoginMenu'
import EnrollmentHeader from './EnrollmentHeader'
import { MainNavMenu } from './MainNavMenu'
import Footer from './Footer';
import { CallAPI } from '../global/APIUtils';
import { ErrorMessages } from '../common/ErrorMessages';
import OverlaySpinner from './Controls/OverlaySpinner';
import { CheckUserSession } from '../global/Helpers';
import { EnrollmentStatus, ActivityLogEvent } from '../common/AOBEnum';
import { ApplicationAllDocumentDownload } from './ApplicationDocumentDownload';
import { DrawerAppBar } from './AppNavbar'
//import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Box, Typography, Stack, Card, CardHeader, Button, Badge, Paper, Alert, AlertTitle, Divider, IconButton, Link, Tooltip, Menu, MenuItem, Stepper, Step, StepLabel } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import DoneIcon from '@mui/icons-material/Done';
import Avatar from '@mui/material/Avatar';
import { deepOrange, deepPurple } from '@mui/material/colors';
import MessageAlert from '../global/MessageAlert';
import CssBaseline from '@mui/material/CssBaseline';
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import { styled } from '@mui/material/styles';
//import MuiAccordion from '@mui/material/Accordion';
//import pwdimg from '../assets/images/75789.jpg';
import pwdimg from '../assets/images/home.jpg';
//import pwdimg from '../assets/images/home.jpg';
import placeimg from '../assets/images/35678.jpg';
import Image from 'material-ui-image'
import ListItemIcon from '@mui/material/ListItemIcon';
import CheckIcon from '@mui/icons-material/Check';
import ArticleOutlinedIcon from '@mui/icons-material/ArticleOutlined';
import DownloadIcon from '@mui/icons-material/Download';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import AvTimerIcon from '@mui/icons-material/AvTimer';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import { grey } from '@mui/material/colors';
import GradingIcon from '@mui/icons-material/Grading';
import SaveOutlinedIcon from '@mui/icons-material/SaveOutlined';
import CancelPresentationIcon from '@mui/icons-material/CancelPresentation';
import PrintIcon from '@mui/icons-material/Print';
import ReplyOutlinedIcon from '@mui/icons-material/ReplyOutlined';
import DescriptionIcon from '@mui/icons-material/Description';
import FileCopyOutlinedIcon from '@mui/icons-material/FileCopyOutlined';
import { ConfirmDialog } from '../global/ConfirmDialog';
import { GetAttachedFile } from '../global/EnrollmentHelper';
import AlertWithTitle from './Controls/AlertWithTitle';
import { IsError, GetLoggedInUserId, GetLoggedInUserEmail, GetLoggedInUserName } from '../global/APIUtils';
import AppViewDlg from '../dialogs/ApplViewDlg';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import profimg from '../assets/images/profimg.jpg';
import { GetApplicantImage } from '../global/EnrollmentHelper';
import { APICall, WriteToActivityLog, ServerAPICall } from '../global/Api';
import BLogo from '../assets/images/ssb-logo-lpl-blue.png';
//import BLogo from '../assets/images/BLogo.jpg';
import { goToTop } from '../global/Helpers'
import ProspectDocListDlg from '../dialogs/ProspectDocListDlg';
import Snackbar from '@mui/material/Snackbar';
import CloseIcon from '@mui/icons-material/Close';
import MuiAlert from '@mui/material/Alert';
import AutoLagoutTimer from '../global/IdleMonitor';
import MuiAccordion, { AccordionProps } from '@mui/material/Accordion';

//const { countries, zones } = require("moment-timezone/data/meta/latest.json");
//const timeZoneToCountry = {};

const Accordion = styled((props) => (
    <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
    border: 0,
    '&:not(:last-child)': {
        borderBottom: 0,
    },
    '&:before': {
        display: 'none',
    },
}));

const ProspectDashboard = () => {

    //var moment = require('moment-timezone');
    //var platform = require('platform');

    const location = useLocation();

    const [modalShow, setModalShow] = useState(false);
    const [reviewModalShow, setReviewModalShow] = useState(false);
    const [alertMsg, setAlertMsg] = useState('');
    const [prospectHomeText, setProspectHomeText] = useState('');
    const [alertSucStatus, setAlertSucStatus] = useState(false);
    const [modalTitle, setModalTitle] = useState('');
    const [expanded, setExpanded] = useState(true);
    const [appCount, setAppCount] = useState(0);

    const [disCancelMenu, setDisCancelMenu] = useState(false);
    const [disReqCancelMenu, setDisReqCancelMenu] = useState(false);
    const [disDelMenu, setDisDelMenu] = useState(false);

    const [errorMsg, setErrorMsg] = useState('');
    const [infoMsg, setInfoMsg] = useState('');
    const [showDocListDlg, setShowDocListDlg] = useState(false);
    const [popWin, setPopWin] = useState(false);

    const [userId, setUserID] = useState(location.state.userId || -1);
    const [sessionId, setSessionId] = useState(location.state.sessionId || '');

    const [jointOwnerName, setJointOwnerName] = useState('NA');
    const [jointOwnerEmail, setJointOwnerEmail] = useState('NA');

    const [homeImageUrl, setHomeImageUrl] = useState(null);

    const [state, setState] = useState({
        enrmntId: 0,
        templateId: 0,
        name: '',
        templateNm: '',
        ownerId: -1
    })
    const [data, setData] = useState(null);
    const [signingDocdata, setSigningDocData] = useState(null);
    const [isLoading, setIsLoading] = useState(false);

    const selectedEnrollment = useRef(null);
    const selectedEnrollmentNm = useRef(null);
    const selectedTemplateNm = useRef(null);
    const selectedEnrollmentData = useRef(null);
    const selectedOwnerNm = useRef(null);
    const selectedOwnerEmail = useRef(null);
    const selectedOwnerMobile = useRef(null);

    const [openConfirmDlg, setOpenConfirmDlg] = useState(false);
    const [openCancelConfirmDlg, setOpenCancelConfirmDlg] = useState(false);
    const [confirmDlgNValue, setConfirmDlgNValue] = useState(null);
    const [confirmDlgSValue, setConfirmDlgSValue] = useState(null);
    const [openSnackBar, setOpenSnackBar] = React.useState(false);

    const [openReqCancelConfirmDlg, setOpenReqCancelConfirmDlg] = useState(false);

    const [successMsg, setSuccessMsg] = useState(null);
    const [successMsgTitle, setSuccessMsgTitle] = useState(null);

    const [showAppViewDlg, setShowAppViewDlg] = useState(false);

    const steps = ['New', 'Submit', 'Bank Review',];
    const [activeStep, setActiveStep] = React.useState(0);

    const steps2 = ['Verify KYC/AML', 'Sign doc', 'A/C activated',];
    const [activeStep2, setActiveStep2] = React.useState(-1);
    const [imageUrl, setImageUrl] = useState(null);
    const [logo2Url, setLogo2Url] = useState(null);

    //const userId = useRef(-1);
    const loginUserEmail = useRef('');
    const loginUserName = useRef('');

    const confirmDlgHdr = useRef('');
    const confirmDlgBLine1 = useRef('');

    const dispatch = useDispatch();

    let sessions = useSelector((state) => state.sessionMgmnt);
    let history = useNavigate();
    
    const [applMoreMenuAnchorEl, setApplMoreMenuAnchorEl] = useState(null);
    const openApplMoreMenu = Boolean(applMoreMenuAnchorEl);

    const handleSnackBarClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setOpenSnackBar(false);
    };
    const SnackAlert = React.forwardRef(function Alert(props, ref) {
        return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
    });
    const snackBarAction = (
        <React.Fragment>
            <IconButton
                size="small"
                aria-label="close"
                color="inherit"
                onClick={handleSnackBarClose}
            >
                <CloseIcon fontSize="small" />
            </IconButton>
        </React.Fragment>
    );


    const closeAppViewDlg = () => {
        setShowAppViewDlg(false);
    }

    const closeDocListDlg = () => {
        setShowDocListDlg(false);
    }

    const GetActiveState1 = (enrmntStatusId) => {

        switch (enrmntStatusId) {
            case EnrollmentStatus.InComplete:
                return 0;
            case EnrollmentStatus.Complete:
                return 0;
            case EnrollmentStatus.SubmitApplication:
                return 1;
            case EnrollmentStatus.UnderReview:
                return 2;
            case EnrollmentStatus.ReviewPass:
                return 2;
            case EnrollmentStatus.ReviewFail:
                return 2;
            default:
                return 4;
        }
    }

    const GetActiveState2 = (enrmntStatusId) => {

        switch (enrmntStatusId) {
            case EnrollmentStatus.KYCAMLInitiated:
                return 3;
            case EnrollmentStatus.KYCAMLPassed:
                return 3;
            case EnrollmentStatus.KYCAMLFailed:
                return 3;
            case EnrollmentStatus.DocuSignRequired:
                return 4;
            case EnrollmentStatus.DocuSignCompleted:
                return 4;
            case EnrollmentStatus.AccountActivated:
                return 6;
            default:
                return -1;
        }
    }

    const isStepCompleted1 = (stepLabel, enrmntStatusId) => {
        if (stepLabel === 'New') {
            //if (enrmntStatusId)
        }
    }

    const handleApplMoreMenuClick = (event, enrmntId, enrmntStatusId, enrmntNm, templateNm, cancelRequest) => {

        setApplMoreMenuAnchorEl(event.currentTarget);

        selectedEnrollment.current = enrmntId;
        selectedEnrollmentNm.current = enrmntNm;
        selectedTemplateNm.current = templateNm;

        if (enrmntStatusId == EnrollmentStatus.Complete || enrmntStatusId == EnrollmentStatus.InComplete
            || enrmntStatusId == EnrollmentStatus.CancelApplication || enrmntStatusId == EnrollmentStatus.RejectApplication
            || enrmntStatusId == EnrollmentStatus.ReviewFail || enrmntStatusId == EnrollmentStatus.KYCAMLFailed) {
            setDisDelMenu(false);
        }
        else {
            setDisDelMenu(true);
        }

        if (enrmntStatusId == EnrollmentStatus.Complete || enrmntStatusId == EnrollmentStatus.InComplete) {
            setDisCancelMenu(false);
        }
        else {
            setDisCancelMenu(true);
        }

        if ((enrmntStatusId == EnrollmentStatus.SubmitApplication || enrmntStatusId == EnrollmentStatus.UnderReview
            || enrmntStatusId == EnrollmentStatus.ReviewPass || enrmntStatusId == EnrollmentStatus.KYCAMLInitiated
            || enrmntStatusId == EnrollmentStatus.KYCAMLPassed || enrmntStatusId == EnrollmentStatus.DocuSignRequired
            || enrmntStatusId == EnrollmentStatus.DocuSignCompleted) &&  !cancelRequest) {
            setDisReqCancelMenu(false);
        }
        else {
            setDisReqCancelMenu(true);
        }
    };
    const handleApplMoreMenuClose = () => {
        setApplMoreMenuAnchorEl(null);
    };

    const handleApplViewMenuClick = (event) => {
        setApplMoreMenuAnchorEl(null);
    }

    const handleDelMenuClick = (event) => {
        setApplMoreMenuAnchorEl(null);

        confirmDlgHdr.current = 'Delete Application';
        confirmDlgBLine1.current = "Do you want to delete the application named as '" + selectedEnrollmentNm.current + "' ?"
        setOpenConfirmDlg(true);
    }
    const handleConfirmDelDialogClose = async (nvalue, svalue) => {

        setErrorMsg('');
        setSuccessMsgTitle('');
        setSuccessMsg('');

        setOpenConfirmDlg(false);

        if (nvalue == null)
            return;

        try {

            setIsLoading(true);

            const apiUrl = new URL(
                process.env.REACT_APP_DELETE_ENROLLMENT,
                process.env.REACT_APP_BASE_URL,
            );

            const inputJson = {
                enrmntId: parseInt(selectedEnrollment.current),
            };

            //let response = await CallAPI({ url: apiUrl.href, method: 'POST', body: inputJson }, userId, sessionId);
            let response = await APICall({ url: apiUrl.href, method: 'POST', payload: inputJson, contentType: 'application/json', responseType: '' }, userId, sessionId);

            if (response !== null && response !== undefined) {

                let error = IsError(response);

                if (error != 0) {
                    setErrorMsg(response.Errors[0].Message);
                    return;
                }

                await WriteToActivityLog(ActivityLogEvent.ProspectDashboard, "Application deleted. Application name '" + selectedEnrollmentNm.current + "'", userId, sessionId);

                setSuccessMsgTitle("Application deleted");
                setSuccessMsg('Your application named \'' + selectedEnrollmentNm.current + '\' deleted successfully.');
                loadUserEnrollments();
            }
        }
        catch (err) {
            setErrorMsg(ErrorMessages.UnknownError);
        }
        finally {
            setIsLoading(false);
        }

        goToTop();
    };

    const handleCancelApplication = async (event) => {

        setApplMoreMenuAnchorEl(null);

        confirmDlgHdr.current = 'Application cancellation';
        confirmDlgBLine1.current = "Do you want to cancel the application named as '" + selectedEnrollmentNm.current + "' ?"

        setOpenCancelConfirmDlg(true);
    }

    const loadApplicantImage = async () => {

        const apiUrl = new URL(
            process.env.REACT_APP_GET_APPLICANT_IMAGE,
            process.env.REACT_APP_BASE_URL,
        );

        apiUrl.searchParams.set('enrmntId', `${parseInt(selectedEnrollment.current)}`);
        apiUrl.searchParams.set('tag', 'ApplicantImage');

        let imageURL = await GetApplicantImage(apiUrl.href, userId, sessionId);

        setImageUrl(imageURL);

    }

    const makeCancelRequest = async (event) => {

        setApplMoreMenuAnchorEl(null);

        confirmDlgHdr.current = 'Cancellation Request';
        confirmDlgBLine1.current = "Do you want to make a request to cancel the application named as '" + selectedEnrollmentNm.current + "' ?"

        setOpenReqCancelConfirmDlg(true);
    }

    const reqCancelConfirmDlgClose = async (nvalue, svalue) => {

        setErrorMsg('');
        setSuccessMsgTitle('');
        setSuccessMsg('');

        setOpenReqCancelConfirmDlg(false);

        if (nvalue == null)
            return;

        try {

            let apiUrl = new URL(
                process.env.REACT_APP_MAKE_ENROLMENT_CANCEL_REQUEST,
                process.env.REACT_APP_BASE_URL,
            );

            const inputJson = {
                enrmntId: parseInt(selectedEnrollment.current),
                enrmntStatusId: EnrollmentStatus.CancelApplication
            };

            setIsLoading(true);

            //let response = await CallAPI({ url: apiUrl.href, method: 'POST', headers: {}, body: inputJson }, userId, sessionId);
            let response = await APICall({ url: apiUrl.href, method: 'POST', payload: inputJson, contentType: 'application/json', responseType: '' }, userId, sessionId);

            if (response !== null && response !== undefined) {

                if (response.Errors.length > 0) {
                    setErrorMsg(response.Errors[0].Message);
                }
                else if (response.meta.Updated === "1") {

                    await WriteToActivityLog(ActivityLogEvent.ProspectDashboard, "Requested cancellation of the application. Application name '" + selectedEnrollmentNm.current + "'", userId, sessionId);

                    setSuccessMsgTitle('Request For Cancellation');
                    setSuccessMsg("You application cancellation request is received. You can track the status of your cancellation request in the following application list.");
                    loadUserEnrollments();
                }
            }
        }
        catch (err) {
            setErrorMsg(ErrorMessages.UnknownError);
        }
        finally {
            setIsLoading(false);
        }

        goToTop();
    };

    const handleViewApplication = async (event, enrmntId, enrmntName ) => {

        try {

            selectedEnrollment.current = enrmntId;
            setIsLoading(true);

            setApplMoreMenuAnchorEl(null);

            let result = await loadEnrollment();
            await loadApplicantImage();

            if (result) {
                await WriteToActivityLog(ActivityLogEvent.ProspectDashboard, "Reviewed application. Application name '" + enrmntName + "'", userId, sessionId);
                setShowAppViewDlg(true);
            }
        }
        catch (err) {
            console.error(err.message);
            setErrorMsg(ErrorMessages.UnknownError);
        }
        finally {
            setIsLoading(false);
        }
        goToTop();
    }

    const loadEnrollment = async () => {

        const apiUrl = new URL(
            process.env.REACT_APP_GET_ENROLLMENT_BY_ID,
            process.env.REACT_APP_BASE_URL,
        );
        apiUrl.searchParams.set('enrmntId', `${parseInt(selectedEnrollment.current)}`);

        let response = await APICall({ url: apiUrl.href, method: 'GET', contentType: 'application/json', responseType: '' }, userId, sessionId);

        if (response !== null && response !== undefined) {

            let error = IsError(response);

            if (error != 0) {
                setErrorMsg(response.Errors[0].Message);
                return false;
            }

            selectedEnrollmentData.current = response.data[0].enrmntData;
            selectedTemplateNm.current = response.data[0].templateNm;

            selectedOwnerNm.current = response.data[0].ownerName;
            selectedOwnerEmail.current = response.data[0].ownerEmail;
            selectedOwnerMobile.current = response.data[0].mobileNbr;
            setJointOwnerName(response.data[0].JointOwnerName);
            setJointOwnerEmail(response.data[0].JointOwnerEmail);

            return true;
        }

        return false;
    }

    const handleCancelConfirmDialogClose = async (nvalue, svalue) => {

        setErrorMsg('');
        setSuccessMsgTitle('');
        setSuccessMsg('');

        setOpenCancelConfirmDlg(false);

        if (nvalue == null)
            return;

        try {

            let apiUrl = new URL(
                process.env.REACT_APP_UPDATE_USER_ENROLMENT_STATUS,
                process.env.REACT_APP_BASE_URL,
            );

            const inputJson = {
                enrmntId: parseInt(selectedEnrollment.current),
                enrmntStatusId: EnrollmentStatus.CancelApplication
            };

            setIsLoading(true);

            //let response = await CallAPI({ url: apiUrl.href, method: 'POST', headers: {}, body: inputJson }, userId, sessionId);
            let response = await APICall({ url: apiUrl.href, method: 'POST', payload: inputJson, contentType: 'application/json', responseType: '' }, userId, sessionId);

            if (response !== null && response !== undefined) {

                if (response.Errors.length > 0) {
                    setErrorMsg(response.Errors[0].Message);
                }
                else if (response.meta.Updated === "1") {
                    await WriteToActivityLog(ActivityLogEvent.ProspectDashboard, "Application cancelled. Application name '" + selectedEnrollmentNm.current + "'", userId, sessionId);
                    setSuccessMsgTitle('Application cancelled');
                    setSuccessMsg("You application has been cancelled. We will not be moving your application forward.");
                    loadUserEnrollments();
                }
            }
        }
        catch (err) {
            setErrorMsg(ErrorMessages.UnknownError);
        }
        finally {
            setIsLoading(false);
        }

        goToTop();
    };
    //const handleMoreMenuClick = (event) => {
    //    setMoreMenuAnchorEl(event.currentTarget);
    //};

    const loadUserEnrollments = async () => {

        const apiUrl = new URL(
            process.env.REACT_APP_GET_ENROLLMENTS,
            process.env.REACT_APP_BASE_URL,
        );

        //let apiUrl = process.env.REACT_APP_GET_ENROLLMENTS;

        try {

            setIsLoading(true);

            //let response = await CallAPI({ url: apiUrl.href, method: 'GET', headers: {} }, userId, sessionId);
            let response = await APICall({ url: apiUrl.href, method: 'GET', contentType: 'application/json', responseType: '' }, userId, sessionId);

            if (response !== null && response !== undefined) {

                let enrollmentData = response["data"];
                let apiErrors = response["Errors"];

                if (apiErrors.length > 0)
                    setErrorMsg(apiErrors[0].Message);

                if (enrollmentData)
                    setAppCount(enrollmentData.length);

                //if (enrollmentData.length > 0)
                setData(enrollmentData)

                let docuSignStatus = enrollmentData.filter(el => {
                    return el['enrmntStatusId'] === EnrollmentStatus.DocuSignRequired;
                });

                if (docuSignStatus && docuSignStatus.length > 0) {
                    setAlertMsg('Your document signing is pending. Please complete your document signing process.');
                    setAlertSucStatus(false);
                }

                await WriteToActivityLog(ActivityLogEvent.ProspectDashboard, "Application list viewed.", userId, sessionId);
            }
        }
        catch (err) {
            setErrorMsg(ErrorMessages.UnknownError);
        }
        finally {
            setIsLoading(false);
        }
        return;
    }

    const onPageClick = (e) => {
        if (typeof (e.target.text) !== "undefined") {
        }
    }

    //const delHandelClick = (enrmntId, templateId) => {
    //    let inputJson = {
    //        "enrmntId": enrmntId,
    //        "templateId": templateId,
    //        "isDeleted": true
    //    };

    //    let headers = {
    //        'Content-Type': 'application/json'
    //    };

    //    let userprompt = false;
    //    userprompt = window.confirm("Do you want to remove the application?");
    //    if (userprompt) {
    //        ApiHelper({
    //            url: process.env.REACT_APP_CREATE_MODIFY_ENROLLMENT_DATA, method: 'POST', headers: headers, body: inputJson, sessions: sessions, dispatch: dispatch
    //        })
    //            .then(resposnse => {
    //                if (resposnse !== null && resposnse != "undefined") {
    //                    alert('Application removed successfully.');
    //                    loadUserEnrollments();
    //                }
    //            });
    //    }
    //}


    const handleClick = (enrmntId, templateId, name, templateNm, ownerId) => {

        if (enrmntId !== null && enrmntId !== 'undefined') {
            setState({
                enrmntId: enrmntId,
                templateId: templateId,
                name: name,
                templateNm: templateNm,
                ownerId: ownerId
            })
            setModalTitle("Edit your saved application");
            setModalShow(true)
        }
    };

    const OpenReadOnly = (enrmntId, templateId, name, templateNm, ownerId, templatenm) => {
        history('/Enrollment', { state: { templateid: parseInt(templateId), name: name, enrmntid: enrmntId, ownerid: ownerId, templatenm: templatenm, appreadonly: true, userId: userId, sessionId: sessionId } })

    };
    const openAccountClick = (event) => {
        event.preventDefault();
        setState({
            enrmntId: 0,
            templateId: 0,
            name: null,
            templateNm: ''
        })
        setModalTitle("Application for opening an account");
        setModalShow(true);
    };

    const signDocumentClick = async (enrmntid, attachmentid) => {

        const apiUrl = new URL(
            process.env.REACT_APP_GET_SIGNING_URL,
            process.env.REACT_APP_BASE_URL,
        );
        apiUrl.searchParams.set('enrmntId', `${parseInt(enrmntid)}`);
        apiUrl.searchParams.set('attchmentid', `${parseInt(attachmentid)}`);
        apiUrl.searchParams.set('signerUserId', `${parseInt(userId)}`);
        apiUrl.searchParams.set('signerName', loginUserName.current);
        apiUrl.searchParams.set('signerEmail', loginUserEmail.current);

        try {
            setIsLoading(true);

            //let response = await CallAPI({ url: apiUrl.href, method: 'GET', headers: {} }, userId, sessionId);
            let response = await APICall({ url: apiUrl.href, method: 'GET', contentType: 'application/json', responseType: '' }, userId, sessionId);

            if (response !== null && response !== undefined) {
                if (response.data.length > 0) {

                    let width = window.screen.width - 10;
                    let height = window.screen.height - 10;
                    window.open(response.data[0].SigningURL, "_self")
                    //let p = window.open(response.data[0].SigningURL, "_blank", "toolbar=no,location=no,status=no,menubar=no,scrollbars=yes,resizable=yes,width=" + width + ",height=" + height);
                    //p.onunload = function () { console.log('Child window closed'); };
                    //setPopWin(p);
                }
                else if (response.meta.Completed === "1")
                {
                    //await loadUserEnrollments();
                    setSigningDocData(null);
                    setOpenSnackBar(true);

                }
                else {
                    setErrorMsg(ErrorMessages.UnknownError);
                    goToTop();
                }
            }
        }
        catch (err) {
            setErrorMsg(ErrorMessages.UnknownError);
            goToTop();
        }
        finally {
            setIsLoading(false);
        }
    }

    const documenthandleClick = (enrmntId, enrmntStatusId) => {
        history('/Document', { state: { enrmntId: enrmntId, isProspect: true, loginUsrId: 0, loginEmail: '', loginUsrNm: '', applicationStatus: enrmntStatusId } })
    };

    const reviewhandleClick = (enrmntId, templateId, name, templateNm) => {
        if (enrmntId !== null && enrmntId !== 'undefined') {
            setState({
                enrmntId: enrmntId,
                templateId: templateId,
                name: name,
                templateNm: templateNm
            })
            setReviewModalShow(true);
        }
    };

    const loadLogo2Image = async () => {
        try {

            let apiImageUrl = new URL(
                process.env.REACT_APP_GET_BANK_RESOURCE,
                process.env.REACT_APP_BASE_URL,
            );

            apiImageUrl.searchParams.set('resourceType', 2);

            let response = await APICall({ url: apiImageUrl.href, method: 'GET', responseType: 'blob' }, userId, sessionId);

            if (response) {
                if (response.type == 'image/jpeg') {
                    setLogo2Url(response);
                }
                else {
                    setLogo2Url(null);
                }
            }
        }
        catch (err) {
            setErrorMsg(ErrorMessages.UnknownError);
        }
        finally {
        }
    }

    const handleDownloadClick = async (event) => {

        try {
            setErrorMsg('');
            setIsLoading(true);
            setApplMoreMenuAnchorEl(null);

            let result = await loadEnrollment();
            await loadApplicantImage();

            let userInfo = {
                ApplicantsName: selectedOwnerNm.current,
                ApplicantEmail: selectedOwnerEmail.current,
                JointOwnerName: jointOwnerName,
                JointOwnerEmail: jointOwnerEmail,
                ApplicantsImg: imageUrl,
                AvtarImage: profimg,
                BankLogo: logo2Url
            }

            let errorMsg = await ApplicationAllDocumentDownload(selectedEnrollment.current, userId, sessionId, userInfo);
            if (errorMsg !== '')
                setErrorMsg(errorMsg);
        }
        catch (err) {
            setErrorMsg(ErrorMessages.UnknownError);
            goToTop();
        }
        finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {

        //let timeZone = moment.tz.guess(Boolean);

        //timeZone = moment.tz.guess(Boolean);
        //if (timeZone == 'Asia/Calcutta')
        //    timeZone = 'Asia/Kolkata';

        //Object.keys(zones).forEach(z => {
        //    timeZoneToCountry[z] = countries[zones[z].countries[0]].name;
        //});


        //let country = countries[zones[timeZone].countries[0]].name

        //Object.keys(zones).forEach(z => {
        //    timeZoneToCountry[z] = countries[zones[z].countries[0]].name;
        //});

        //console.log(JSON.stringify(timeZoneToCountry, null, 2))
        //let x = window.navigator.userAgent;
        //let y = Intl.DateTimeFormat().resolvedOptions().timeZone;

        loginUserEmail.current = GetLoggedInUserEmail(userId, sessionId);
        loginUserName.current = GetLoggedInUserName(userId, sessionId);

        try {
            setIsLoading(true);
            loadProspectHomeText();
            loadProspectHomeImage();
            loadLogo2Image();
        }
        finally {
            setIsLoading(false);
        }

        loadUserEnrollments();

    }, [openSnackBar]);

    const loadProspectHomeText = async () => {
        try {

            /*setIsLoading(true);*/

            const storedValue = sessionStorage.getItem(userId + 'ProspectHomeText');
            setProspectHomeText(storedValue);

            if (storedValue == null) {
                const apiUrl = new URL(
                    process.env.REACT_APP_GET_BANK_INFO,
                    process.env.REACT_APP_BASE_URL,
                );

                let response = await ServerAPICall({ url: apiUrl.href, method: 'GET', contentType: 'application/json', responseType: '' });

                if (response !== null && response !== undefined) {
                    if (response["data"] && response["data"].length > 0) {
                        sessionStorage.setItem(userId + 'ProspectHomeText', response.data[0].ProspectHomeText);
                        setProspectHomeText(response.data[0].ProspectHomeText);
                    }
                }
            }
        }
        catch (err) {
            console.error(err);
        }
        finally {
            //setIsLoading(false);
        }
    }

    const loadProspectHomeImage = async () => {
        try {

            //setIsLoading(true);

            let apiImageUrl = new URL(
                process.env.REACT_APP_GET_BANK_RESOURCE,
                process.env.REACT_APP_BASE_URL,
            );

            apiImageUrl.searchParams.set('resourceType', 4);

            let response = await APICall({ url: apiImageUrl.href, method: 'GET', responseType: 'blob' }, userId, sessionId);

            if (response) {
                if (response.type == 'image/jpeg') {
                    setHomeImageUrl(response);
                }
                else {
                    setHomeImageUrl(null);
                }
            }
        }
        catch (err) {
            //setLoginStatusMsg(ErrorMessages.UnknownError);
        }
        finally {
            //setIsLoading(false);
        }
    }

    const onReceiveDocuSignEvent = (e) => {

        loadUserEnrollments();
    }

    const handleAccordianChange = () => {
        if (expanded) {
            setExpanded(false);
        }
        else {
            setExpanded(true);
        }
    };
    const getApplInfoMsg = (enrmntStatusId) => {

        if (enrmntStatusId == EnrollmentStatus.InComplete) {
            return 'Complete and submit your application';
        }

        if (enrmntStatusId == EnrollmentStatus.Complete) {
            return 'Submit your application';
        }

        if (enrmntStatusId == EnrollmentStatus.ReviewFail) {
            return 'Review of your application failed. See reason below:';
        }

        if (enrmntStatusId == EnrollmentStatus.KYCAMLInitiated) {
            return 'Conducting mandatory background check. The status of the application will change once done.';
        }

        if (enrmntStatusId == EnrollmentStatus.KYCAMLPassed) {
            return 'Mandatory background check completed.';
        }

        if (enrmntStatusId == EnrollmentStatus.KYCAMLFailed) {
            return 'Mandatory background check failed. See reason below:';
        }

        return 'Processing of your application is in progress. Check the status of your application here.';
    };

    const getApplInfoAlertVariant = (enrmntStatusId) => {

        if (enrmntStatusId == EnrollmentStatus.InComplete) {
            return 'info';
        }

        if (enrmntStatusId == EnrollmentStatus.ReviewFail || enrmntStatusId == EnrollmentStatus.KYCAMLFailed) {
            return 'error';
        }

        return 'info';
    };

    const getApplStatAlertVariant = (enrmntStatusId) => {

        if (enrmntStatusId == EnrollmentStatus.InComplete) {
            return 'info';
        }

        if (enrmntStatusId == EnrollmentStatus.ReviewFail || enrmntStatusId == EnrollmentStatus.KYCAMLFailed) {
            return 'error';
        }

        return 'info';
    };

    const getStatusTextColor = (enrmntStatusId) => {

        if (enrmntStatusId == EnrollmentStatus.ReviewFail || enrmntStatusId == EnrollmentStatus.KYCAMLFailed) {
            return '#ef5350';
        }

        return 'text.primary';
    };

    const IsDisplayReason = (enrmntStatusId) => {

        if (enrmntStatusId == EnrollmentStatus.ReviewFail || enrmntStatusId == EnrollmentStatus.KYCAMLFailed) {
            return 'block';
        }

        return 'none';
    };

    const GetSigningDocs = async (enrmntid) => {

        try {

            setIsLoading(true);

            const apiUrl = new URL(
                process.env.REACT_APP_GET_SIGNING_DOCS,
                process.env.REACT_APP_BASE_URL,
            );

            apiUrl.searchParams.set('enrmntId', `${parseInt(enrmntid)}`);

            //let response = await CallAPI({ url: apiUrl.href, method: 'GET', headers: {} }, userId, sessionId);
            let response = await APICall({ url: apiUrl.href, method: 'GET', contentType: 'application/json', responseType: '' }, userId, sessionId);

            if (response !== null && response !== undefined) {

                let error = IsError(response);

                if (error != 0) {
                    setErrorMsg(response.Errors[0].Message);
                    return;
                }

                setSigningDocData(response.data[0])
            }
        }
        catch (err) {
            setErrorMsg(ErrorMessages.UnknownError);
        }
        finally {
            setIsLoading(false);
        }
    }

    const downloadAttachment = async (enrmntid, attachmentId, fileNm) => {
        
        try {

            setIsLoading(true);

            const apiUrl = new URL(
                process.env.REACT_APP_DOWNLOAD_SIGNED_DOCUMENT,
                process.env.REACT_APP_BASE_URL,
            );
            apiUrl.searchParams.set('enrmntId', `${parseInt(enrmntid)}`);
            apiUrl.searchParams.set('attachmentId', `${parseInt(attachmentId)}`);
            apiUrl.searchParams.set('fname', fileNm);
            apiUrl.searchParams.set('tag', 'None');

            await GetAttachedFile(apiUrl.href, fileNm, userId, sessionId);
        }
        catch (ex) {
            //setErrorMsg(ErrorMessages.UnknownError);
        }
        finally {
            setIsLoading(false);
        }
    }

    const handleViewDocClick = async () => {

        setApplMoreMenuAnchorEl(null);
        setShowDocListDlg(true);
    };

    return (

        <React.Fragment>
            <AutoLagoutTimer userId={userId} sessionId={sessionId} />
            <Container maxWidth="lg" sx={{}} className="">
                <OverlaySpinner disappear={isLoading} />
                <Paper className="back-grid">
                    <PostLoginMenu userId={userId} sessionId={sessionId} />
                    <Grid item md={12} id="aobhead">
                        <Box sx={{
                            pr: 0,
                            pl: 0, 
                        }}>
                            <EnrollmentHeader />
                        </Box>
                    </Grid>
                    <Grid item sx={{ pr: 4 }}>
                        <DrawerAppBar userId={userId} sessionId={sessionId} />
                    </Grid>
                    <Grid item container direction="row" spacing={2} sx={{ pt: 1, pb: 0, pl: 6.5, pr: 6.5 }}>
                        <Grid item md={6} sx={{ pr: 5 }}>
                            <Box sx={{ display: 'flex', pb: 2, pt: 0, justifyContent: 'space-between', alignItems: 'flex-end', borderBottom: '0px solid #898F94' }}>
                                <Typography variant="h6" color="text.primary" sx={{ pl: 0, pb: 0, lineHeight: 1, fontWeight: 500, pb: 0, letterSpacing: '0px' }} >Fully digital account opening</Typography>
                            </Box>
                            <Box sx={{ ml: 0, mr: 0, }}>
                                <Divider variant="fullWidth" component="div" sx={{ }} />
                            </Box>
                            <Box sx={{ mt: 3 }}>
                                <Typography sx={{ fontWeight: 400 }} variant="body1" color="text.primary">
                                    {prospectHomeText}
                                </Typography>
                            </Box>
                            <Box sx={{ mt: 4, border: 1, borderColor: '#e5e5e5' }}>
                                <Stack direction="row" spacing={3} alignItems="center" sx={{ pl: 2.5, pt: 2, pb: 2, fontWeight: 700, }}>
                                    <Typography className="" variant="h5" sx={{ fontWeight: 400, lineHeight: 2, pt: 0 }} color="text.primary">Take the next step</Typography>
                                    <Box>
                                        <Button type="button" variant="contained" size="small" sx={{ borderRadius: '20px', padding: '0.25rem 1.5rem', textTransform: 'none' }} onClick={(e) => openAccountClick(e)}>
                                            Open An Account
                                        </Button>
                                    </Box>
                                </Stack>
                            </Box>
                        </Grid>
                        <Grid item md={6}>
                            <Grid container>
                                <Grid item md={12} sx={{ pl: 0, p: 1, background: '#f4f4f4' }} >

                                    {homeImageUrl ?
                                        <img src={URL.createObjectURL(homeImageUrl)} height="410" loading="lazy" />
                                        :
                                        <img src={pwdimg} height="410" loading="lazy" placeholdersrc={placeimg} />
                                    }
                                    {/*<img src={pwdimg} height="410" loading="lazy" />*/}
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} sx={{ pl: 6.5, pr: 6.5 }}>
                        <Box>
                            <AlertControl Message={errorMsg} severity="error" color="error" icon={true} />
                            <AlertWithTitle Message={successMsg} variant="body1" severity="success" color="success" icon={true} Title={successMsgTitle} />
                        </Box>
                        <Box>
                            <Typography variant="body1" sx={{ borderBottom: '0px solid #898F94', fontWeight: 400, textTransform: 'none', pb: 2, lineHeight: 2.5 }} color="text.primary">Your existing applications</Typography>
                        </Box>
                        <Box sx={{ ml: 0, mr: 0, }}>
                            <Divider variant="fullWidth" component="div" sx={{ background: '#BBBFBF' }} />
                        </Box>
                        <Typography variant="body1" color="text.primary" sx={{ mt: 3, mb: 2, lineHeight: 3 }}>
                            You applied for <strong>{data == null ? 0 : data.length}</strong> account(s).
                        </Typography>
                    </Grid>
                    <Grid item xs container direction="row" spacing={5} sx={{ pl: 6.5, pr: 4, pt: 1, }}>
                                {
                                    (data != null) ? data.map((item, index) => (
                                        <Grid item xs={12} md={6} key={index}>
                                            <Card sx={{ border: 1, borderColor: '#e5e5e5', borderRadius: 6, pl: 2, pb: 1, pr: 2, pt: 0 }}>
                                                    <CardHeader
                                                    title={<Typography sx={{ pt: 3 }} gutterBottom variant="body1" >Application for opening bank account
                                                    </Typography>}
                                                    subheader={<Typography sx={{}} gutterBottom variant="caption" component="div">{item.modDate}
                                                    </Typography>}
                                                    />
                                                <Box display="flex" justifyContent="space-between" alignItems="center" sx={{pr: 4}}>
                                                    <Link component="button" href="#" sx={{}} underline="hover" onClick={(e) => OpenReadOnly(item.enrmntId, item.templateId, item.name, item.templateNm, item.ownerId, item.templateNm)}>
                                                        <Typography variant="h5" color="text.primary" sx={{ fontWeight: 500, pl: 2, mt: 0 }}>{item.templateNm}</Typography>
                                                    </Link>
                                                    <Tooltip arrow title="More options">
                                                        <span>
                                                                <IconButton onClick={(e) => handleApplMoreMenuClick(e, item.enrmntId, item.enrmntStatusId, item.name, item.templateNm, item.CancelRequest)}>
                                                                <MoreHorizIcon sx={{ fontSize: 36, color: 'Icon.main' }} />
                                                            </IconButton>
                                                        </span>
                                                    </Tooltip>
                                                </Box>
                                                <Stack direction="row">
                                                    <Typography sx={{ pl: 2, pt: 0, fontWeight: 400 }} variant="body1" color="text.primary">Application name: </Typography>
                                                    <Typography sx={{ pl: 1, }} variant="body1" color="text.primary">{item.name}</Typography>
                                                </Stack>
                                                <Stack direction="row">
                                                    <Typography sx={{ pl: 2, pt: 0, fontWeight: 400 }} variant="body1" color="text.primary">Owner: </Typography>
                                                    <Typography sx={{ pl: 1, }} variant="body1" color="text.primary">{item.ownerName}</Typography>
                                                </Stack>
                                                <Stack direction="row">
                                                    <Typography sx={{ pl: 2, pt: 0, fontWeight: 400 }} variant="body1" color="text.primary">Joint owner name: </Typography>
                                                    <Typography sx={{ pl: 1, }} variant="body1" color="text.primary">{item.JointOwnerName == '' ? 'NA' : item.JointOwnerName}</Typography>
                                                </Stack>
                                                <Stack direction="row">
                                                    <Typography sx={{ pl: 2, pt: 0, fontWeight: 400 }} variant="body1" color="text.primary">Joint owner email: </Typography>
                                                    <Typography sx={{ pl: 1, }} variant="body1" color="text.primary">{item.JointOwnerEmail == '' ? 'NA' : item.JointOwnerEmail}</Typography>
                                                </Stack>
                                                <Box sx={{ ml: 1, mr: 1, mt: 3 }}>
                                                    <Divider sx={{ background: '#9e9e9e' }} />
                                                    <MessageAlert sx={{pt: 1, pb: 1}} severity={getApplInfoAlertVariant(item.enrmntStatusId)}
                                                        Message={getApplInfoMsg(item.enrmntStatusId)} />
                                                    <Divider sx={{ background: '#9e9e9e' }} />
                                                </Box>
                                                <Box sx={{ ml: 1, mr: 3, mt: 2 }}>
                                                   <MessageAlert Message={item.stsReason} severity="error" color="error" />
                                                </Box>
                                                    <Stepper activeStep={GetActiveState1(item.enrmntStatusId)} sx={{ pl: 1, pr: 1, pt: 2 }}>
                                                    {steps.map((label, ind) => {
                                                        const stepProps = {};
                                                        const labelProps = {};
                                                        if (ind == 2 && item.enrmntStatusId == EnrollmentStatus.ReviewFail) {
                                                            labelProps.optional = (
                                                                <Typography variant="caption" color="error">
                                                                    Failed
                                                                </Typography>
                                                            );

                                                            labelProps.error = true;
                                                        }

                                                            return (
                                                                <Step key={ind} {...stepProps}>
                                                                    <StepLabel {...labelProps}>{label}</StepLabel>
                                                                </Step>
                                                            );
                                                        })}
                                                    </Stepper>
                                                    <Stepper activeStep={GetActiveState2(item.enrmntStatusId)} sx={{pl: 1, pr: 1, pt: 3, pb: 4}}>
                                                        {steps2.map((label, index) => {
                                                            const stepProps = {};
                                                            const labelProps = {};
                                                            if (index == 0 && item.enrmntStatusId == EnrollmentStatus.KYCAMLFailed) {
                                                                labelProps.optional = (
                                                                    <Typography variant="caption" color="error">
                                                                        Failed
                                                                    </Typography>
                                                                );

                                                                labelProps.error = true;
                                                            }
                                                            return (
                                                                <Step index={index+3} key={index} {...stepProps}>
                                                                    <StepLabel {...labelProps}>{label}</StepLabel>
                                                                </Step>
                                                            );
                                                        })}
                                                    </Stepper>
                                                
                                                <Box sx={{ mb: 4, ml: 3, mr: 3, mt: 1 }}>
                                                    <Box sx={{pb: 1}}>
                                                        <Typography sx={{ fontWeight: 600, mb: 2, mt: 0, lineHeight: 1 }} variant="body1" color="text.primary">Application Status</Typography>
                                                    </Box>
                                                        <Divider sx={{ background: '#757575' }} />
                                                        <Alert severity={getApplStatAlertVariant(item.enrmntStatusId)} color="warning" >
                                                            <Stack direction="row" alignItems="center">
                                                                <Typography variant="body1" sx={{fontWeight: 400}} color={getStatusTextColor(item.enrmntStatusId)}>{item.enrmntStatus + (item.CancelRequest === true ? ' (Requested For Cancellation)' : '')}</Typography>
                                                            </Stack>
                                                        </Alert>

                                                        {
                                                            signingDocdata && signingDocdata.length > 0 && signingDocdata[0].EnrmntId == item.enrmntId && (item.enrmntStatusId == EnrollmentStatus.DocuSignRequired || EnrollmentStatus.DocuSignCompleted) ?
                                                                <Box sx={{ pt: 2 }}>
                                                                    <Table aria-label="simple table" sx={{ border: '1px solid #CCCCCC' }} elevation={0}>
                                                                        <TableBody>
                                                                            {
                                                                                signingDocdata.map((row) => (
                                                                                    <TableRow
                                                                                        key={row.AttchmntId}
                                                                                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                                                    >
                                                                                        <TableCell component="th" scope="row" sx={{width: '50%'}}>
                                                                                            <Link component="button" href="#" sx={{}} onClick={(e) => downloadAttachment(item.enrmntId, row.AttchmntId, row.FileName)}>
                                                                                                <Typography variant="body1" sx={{ fontWeight: 400, pl: 1 }}>{row.FileName}</Typography>
                                                                                            </Link>
                                                                                        </TableCell>
                                                                                        <TableCell component="th" scope="row" sx={{ width: '30%' }}>
                                                                                            <Typography className="" variant="body2" color="text.primary">
                                                                                                {row.SignStatus}
                                                                                            </Typography>
                                                                                        </TableCell>
                                                                                        <TableCell align="right" color="text.primary" sx={{ opacity: '0.7' }} sx={{ width: '20%' }}>
                                                                                            <Button variant="contained" disabled={row.IsSignCompleted} size="small" sx={{ width: '100%', padding: '0.2rem 1rem', }} onClick={(e) => signDocumentClick(row.EnrmntId, row.AttchmntId)}>
                                                                                                <Typography variant="body2" sx={{ textTransform: 'none' }} >Sign</Typography>
                                                                                            </Button>
                                                                                        </TableCell>
                                                                                    </TableRow>
                                                                                ))}
                                                                        </TableBody>
                                                                    </Table>
                                                                </Box>
                                                                : ''
                                                        }
                                                        <Divider sx={{ background: '#757575' }} />
                                                    </Box>
                                                    <Stack sx={{ mt: 2, ml: 2, mr: 3, mb: 4 }} direction="row" spacing={2} color="primary">
                                                    <Button variant="contained" size="small" sx={{ borderRadius: '20px', padding: '0.25rem 2.5rem', textTransform: 'none' }} disabled={item.enrmntStatusId == EnrollmentStatus.InComplete || item.enrmntStatusId == EnrollmentStatus.Complete || item.enrmntStatusId == EnrollmentStatus.ReviewFail ? false : true} onClick={() => handleClick(item.enrmntId, item.templateId, item.name, item.templateNm, item.ownerId)} >
                                                            Edit
                                                        </Button>
                                                    <Button variant="contained" size="small" sx={{ borderRadius: '20px', padding: '0.25rem 2.5rem', textTransform: 'none' }} onClick={(event) => handleViewApplication(event, item.enrmntId, item.name)}>
                                                            View
                                                        </Button>
                                                    </Stack>
                                                </Card >
                                            {/*</Paper>*/}
                                        </Grid>
                                    )) : null
                                }
                                <Menu 
                                    id="basic-menu"
                                    anchorEl={applMoreMenuAnchorEl}
                                    open={openApplMoreMenu}
                                    onClose={handleApplMoreMenuClose}
                                    MenuListProps={{
                                        'aria-labelledby': 'basic-button',
                                    }}
                                >
                                    <MenuItem sx={{ ml: 2 }} onClick={(event) => handleDownloadClick(event)}>
                                        <ListItemIcon>
                                        <DownloadIcon width="24px" height="24px" color="#ccc" />
                                        </ListItemIcon>
                                        <Typography variant="body1" sx={{ mt: 1, mb: 1, ml: 1, textTransform: 'none', fontWeight: 400 }} color="text.primary">Download</Typography>
                                    </MenuItem>
                                    <Divider variant="fullWidth" component="div" sx={{}} />
                                    <MenuItem sx={{ ml: 2 }} onClick={() => handleViewDocClick()}>
                                        <ListItemIcon>
                                        <FileCopyOutlinedIcon width="24px" height="24px" color="#ccc" />
                                        </ListItemIcon>
                                        <Typography className="" variant="body1" sx={{ ml: 1, textTransform: 'none', fontWeight: 400 }} color="text.primary">Attached Documents</Typography>
                                    </MenuItem>
                                    <Divider variant="fullWidth" component="div" sx={{}} />
                                    <MenuItem sx={{ ml: 2 }} disabled={disCancelMenu} onClick={(event) => handleCancelApplication(event)}>
                                        <ListItemIcon>
                                        <CancelPresentationIcon width="24px" height="24px" color="#ccc" />
                                        </ListItemIcon>
                                        <Typography className="" variant="body1" sx={{ ml: 1, textTransform: 'none', fontWeight: 400 }} color="text.primary">Cancel</Typography>
                                    </MenuItem>
                                    <MenuItem sx={{ ml: 2 }} disabled={disReqCancelMenu} onClick={(event) => makeCancelRequest(event)}>
                                        <ListItemIcon>
                                            <ReplyOutlinedIcon width="24px" height="24px" color="#ccc" />
                                        </ListItemIcon>
                                        <Typography className="" variant="body1" sx={{ ml: 1, textTransform: 'none', fontWeight: 400, mr: 2 }} color="text.primary">Request for cancellation</Typography>
                                    </MenuItem>
                                    <Divider variant="fullWidth" component="div" sx={{}} />
                                    <MenuItem sx={{ ml: 2 }} disabled={disDelMenu} onClick={(event) => handleDelMenuClick(event)}>
                                        <ListItemIcon>
                                        <DeleteForeverIcon width="24px" height="24px" color="#ccc" />
                                        </ListItemIcon>
                                        <Typography variant="body1" sx={{ mt: 1, mb: 1, ml: 1, textTransform: 'none', fontWeight: 400 }} color="text.primary">Delete</Typography>
                                    </MenuItem>
                                </Menu>
                            <ConfirmDialog
                                keepMounted
                                open={openConfirmDlg}
                                onClose={handleConfirmDelDialogClose}
                                alertheader={confirmDlgHdr.current}
                                bmsgline1={confirmDlgBLine1.current}
                                nvalue={selectedEnrollment.current} // {delFileControl.current}
                                svalue={selectedEnrollmentNm.current}
                            />
                            <ConfirmDialog
                                keepMounted
                                open={openCancelConfirmDlg}
                                onClose={handleCancelConfirmDialogClose}
                                alertheader={confirmDlgHdr.current}
                                bmsgline1={confirmDlgBLine1.current}
                                nvalue={selectedEnrollment.current}
                                svalue={selectedEnrollmentNm.current}
                            />
                            <ConfirmDialog
                                keepMounted
                                open={openReqCancelConfirmDlg}
                                onClose={reqCancelConfirmDlgClose}
                                alertheader={confirmDlgHdr.current}
                                bmsgline1={confirmDlgBLine1.current}
                                nvalue={selectedEnrollment.current}
                                svalue={selectedEnrollmentNm.current}
                            />
                            </Grid>
                    <Footer userId={userId} />
                </Paper>
                {
                    openSnackBar ?
                        <Snackbar
                            open={openSnackBar}
                            autoHideDuration={6000}
                            onClose={handleSnackBarClose}
                            message="Note archived"
                            action={snackBarAction}
                        >
                            <SnackAlert onClose={handleSnackBarClose} severity="success" sx={{ width: '100%' }}>
                                Document signing is completed.
                            </SnackAlert>
                        </Snackbar>
                        : null
                }
                {
                    modalShow ?
                        <AddEditEnrollment
                            key={state.enrmntId}
                            show={modalShow}
                            backdrop="static"
                            keyboard={false}
                            onHide={() => setModalShow(false)}
                            title={modalTitle}
                            templateid={state.templateId}
                            enrmntid={state.enrmntId}
                            name={state.name}
                            templatenm={state.templateNm}
                            userId={userId}
                            sessionId={sessionId}
                        />
                        : <div />
                }
                {showAppViewDlg ?
                    <AppViewDlg open={showAppViewDlg} onClose={closeAppViewDlg} enrollData={selectedEnrollmentData.current} enrmntid={selectedEnrollment.current} acctType={selectedTemplateNm.current} avtarImage={profimg} applicantImage={imageUrl} applicantName={selectedOwnerNm.current} applicantEmail={selectedOwnerEmail.current} applicantMobile={selectedOwnerMobile.current} JointOwnerName={jointOwnerName} JointOwnerEmail={jointOwnerEmail} userId={userId} sessionId={sessionId} />
                    : null
                }
                {showDocListDlg ?
                    <ProspectDocListDlg open={showDocListDlg} onClose={closeDocListDlg} enrmntid={selectedEnrollment.current} ownerName={selectedOwnerNm.current} enrolNm={selectedEnrollmentNm.current} templateNm={selectedTemplateNm.current} userId={userId} sessionId={sessionId} />
                    : null
                }
            </Container>
        </React.Fragment>
    );
}

function ProspectDashboardBreadcrumb() {
    return (
        <React.Fragment >
            <Box sx={{ borderBottom: '1px solid #cdcfd2', pt: 0, pb: 3 }}>
                <Typography variant="h4" color="text.primary" sx={{ lineHeight: 1, fontWeight: 300, letterSpacing: '-1px' }} >Fully digital account opening</Typography>
            </Box>
        </React.Fragment>
    );
}

export default ProspectDashboard;