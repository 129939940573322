import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';
import CheckIcon from '@mui/icons-material/Check';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import DescriptionIcon from '@mui/icons-material/Description';
import DownloadIcon from '@mui/icons-material/Download';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import FileCopyOutlinedIcon from '@mui/icons-material/FileCopyOutlined';
import ImportExportIcon from '@mui/icons-material/ImportExport';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import NoteAddOutlinedIcon from '@mui/icons-material/NoteAddOutlined';
import NoteAddIcon from '@mui/icons-material/NoteAdd';
import NoteAltIcon from '@mui/icons-material/NoteAlt';
import UploadIcon from '@mui/icons-material/Upload';
import { FormGroup, FormControlLabel, Badge, Button, Box, Card, CardMedia, Grid, IconButton, Menu, MenuItem, Paper, Stack, Typography, Tooltip, TableContainer, Chip, Link } from '@mui/material';
import Checkbox, { checkboxClasses } from "@mui/material/Checkbox";
//import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Avatar from '@mui/material/Avatar';
import Container from '@mui/material/Container';
import Divider from '@mui/material/Divider';
import ListItemIcon from '@mui/material/ListItemIcon';
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import { QueryClient, QueryClientProvider, useInfiniteQuery } from '@tanstack/react-query';
import { Virtualizer } from '@tanstack/react-virtual';
import MaterialReactTable, { MRT_ToggleGlobalFilterButton } from 'material-react-table';
import Image from 'material-ui-image';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { renderToString } from 'react-dom/server';
import { useDispatch, useSelector } from 'react-redux';
import pwdimg from '../assets/images/12543.jpg';
//import BLogo from '../assets/images/BLogo.jpg';
//import BLogo from '../assets/images/ssb-logo.png';
import BLogo from '../assets/images/ssb-logo-lpl-blue.png';
import profimg from '../assets/images/profimg_whtbg.jpg';
import { ApplicationSortAction, EnrollmentStatus, AOBSystemProcess, APIResponseStatus, ActivityLogEvent, EnrollmentStatusText } from '../common/AOBEnum';
import { ErrorMessages } from '../common/ErrorMessages';
import AppViewDlg from '../dialogs/ApplViewDlg';
import AppHistoryDlg from '../dialogs/AppHistory';
import DocListDlg from '../dialogs/DocListDlg';
import DocUpldDlg from '../dialogs/DocUpldDlg';
import ExportToGWESLoaderDlg from '../dialogs/ExportToGWESLoaderDlg';
import { AlertDialog } from '../global/AlertDialog';
import { CallAPI, IsError, IsInRole, GetLoggedInUserId, GetAPIErrorAction, GetAxiosErrorAction, CallAPIUsingAxios, GetLoggedInUserEmail } from '../global/APIUtils';
import { ConfirmDialog } from '../global/ConfirmDialog';
import { GetApplicantImage } from '../global/EnrollmentHelper';
import { FormDialog } from '../global/FormDialog';
import { ApplicationAllDocumentDownload } from './ApplicationDocumentDownload';
import { ApplicationEnrollmentPrint } from './ApplicationEnrollmentPrint';
import { DrawerAppBar } from './AppNavbar';
import AlertControl from './Controls/AlertControl';
import AlertWithTitle from './Controls/AlertWithTitle';
import OverlaySpinner from './Controls/OverlaySpinner';
import { AddEditEnrollment } from './DashBoard/Modal/AddEditEnrollment';
import EnrollmentHeader from './EnrollmentHeader';
import Footer from './Footer';
import PostLoginMenu from './PostLoginMenu';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import { withRouter, GetAppSatusStr } from '../global/Helpers';
import { useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { APICall, WriteToActivityLog  } from '../global/Api';
import DocuSignDlg from '../dialogs/DocuSignDlg';
import DocuSignMailDlg from '../dialogs/DocuSignMail';
import DocuSignStatusDlg from '../dialogs/DocuSignStatus';
import DocuSignHistDlg from '../dialogs/DocuSignHist';
import MuiAccordion, { AccordionProps } from '@mui/material/Accordion';
import HistoryToggleOffIcon from '@mui/icons-material/HistoryToggleOff';
import { goToTop } from '../global/Helpers'
import TaskOutlinedIcon from '@mui/icons-material/TaskOutlined';
import ArrowRightOutlinedIcon from '@mui/icons-material/ArrowRightOutlined';
import LockOpenIcon from '@mui/icons-material/LockOpen';
import AutoLagoutTimer from '../global/IdleMonitor';
import moment from "moment";
import PostAddOutlinedIcon from '@mui/icons-material/PostAddOutlined';
import NoteAltOutlinedIcon from '@mui/icons-material/NoteAltOutlined';
import DescriptionOutlinedIcon from '@mui/icons-material/DescriptionOutlined';

const Accordion = styled((props) => (
    <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
    border: 0,
    '&:not(:last-child)': {
        borderBottom: 0,
    },
    '&:before': {
        display: 'none',
    },
}));

const UserEnrollments = () => {

    const location = useLocation();
    let navigate = useNavigate();

    const queryClient = new QueryClient();
    const dispatch = useDispatch();
    let sessions = useSelector((state) => state.sessionMgmnt);
    const [openConfirmDlg, setOpenConfirmDlg] = useState(false);
    const [openFormDlg, setOpenFormDlg] = useState(false);
    const [enrolModalShow, setEnrolModalShow] = useState(false);
    const [expanded, setExpanded] = useState(true);
    const [errorMsg, setErrorMsg] = useState(null);
    const [successMsg, setSuccessMsg] = useState(null);
    const [successMsgTitle, setSuccessMsgTitle] = useState(null);

    const [DSSuccessMsg, setDSSuccessMsg] = useState(null);
    const [DSSuccessMsgTitle, setDSSuccessMsgTitle] = useState(null);

    const [appStatusErrorMsg, setAppStatusErrorMsg] = useState('');
    const [isDataLoading, setIsDataLoading] = useState(false);
    const [enrollmentCount, setEnrollmentCount] = useState(0);
    const [attachmentCount, setAttachmentCount] = useState(0);
    const [docuSignRequiresCount, setDocuSignRequiresCount] = useState(0);
    const [docuSignCompletedCount, setDocuSignCompletedCount] = useState(0);
    const [docuSignDeclineCount, setDocuSignDeclineCount] = useState(0);
    const [gwesRefId, setGwesRefId] = useState(0);
    const [GWESEnrollId, setGWESEnrollId] = useState(0);
    const [submittedDate, setSubmittedDate] = useState(null);
    const [openDelConfirmDlg, setOpenDelConfirmDlg] = useState(false);
    const [statusExpanded, setStatusExpanded] = useState(false);
    const [showDocuSignDlg, setShowDocuSignDlg] = useState(false);
    const [showDocuSignMailDlg, setShowDocuSignMailDlg] = useState(false);
    const [showDocuSignStatusDlg, setShowDocuSignStatusDlg] = useState(false);
    const [showDocuSignHistDlg, setShowDocuSignHistDlg] = useState(false);
    const [searchText, setSearchText] = useState('');
    const [fromDate, setFromDate] = useState(new Date(new Date().setDate(new Date().getDate() - 180)).toLocaleDateString('en-CA'));
    const [toDate, setToDate] = useState(new Date().toLocaleDateString('en-CA'));
    const [disFromDate, setDisFromDate] = useState(false);
    const [disToDate, setDisToDate] = useState(false);
    const [ignoreDate, setIgnoreDate] = useState(false);

    const [userId, setUserId] = useState(location.state ? location.state.userId : (GetLoggedInUserId() != null && GetLoggedInUserId() != undefined) ? GetLoggedInUserId() : -1);
    const [sessionId, setSessionId] = useState(location.state ? location.state.sessionId : '');

    const confirmDlgHdr = useRef('');
    const confirmDlgBLine1 = useRef('');
    const confirmDlgBLine2 = useRef('');
    const confirmDlgBLine3 = useRef('');
    const confirmDlgBLine4 = useRef('');
    const confirmDlgBLine5 = useRef('');

    const confirmDelDlgHdr = useRef('');
    const confirmDelDlgBLine1 = useRef('');

    const selEnrolStatus = useRef(0);
    const newEnrolStatus = useRef(0);
    const selectedEnrolId = useRef(0);
    const templateId = useRef(0);
    const enrolNm = useRef(null);
    const templateNm = useRef(null);
    const selOwnerId = useRef(null);

    const emailAdrs = useRef(null);

    const selectedEnrolmntValue = useRef('');
    const selectedTemplateNm = useRef('');

    const [imageUrl, setImageUrl] = useState(null);
    const [optMnuAnchorEl, setOptMnuAnchorEl] = useState(null);
    const open = Boolean(optMnuAnchorEl);

    const [moreMnuAnchorEl, setMoreMnuAnchorEl] = useState(null);
    const openMoreMenu = Boolean(moreMnuAnchorEl);

    const [userEnrolmlSts, setUserEnrolmlSts] = useState(null);
    const [docuSignEmailSts, setDocuSignEmailSts] = useState(null);
    const [rowSelection, setRowSelection] = useState({});
    const [rowId, setRowId] = useState({});
    const [isFormLoading, setIsFormLoading] = useState(false);
    const [openAppView, setOpenAppView] = useState(false);
    const [printApp, setPrintApp] = useState(false);

    const [disRevOpt, setDisRevOpt] = useState(true);
    const [disRevPassOpt, setDisRevPassOpt] = useState(true);
    const [disRevFailOpt, setDisRevFailOpt] = useState(true);
    const [disKYCAMLCheckOpt, setDisKYCAMLCheckOpt] = useState(true);
    const [disKYCAMLPassOpt, setDisKYCAMLPassOpt] = useState(true);
    const [disKYCAMLFailOpt, setDisKYCAMLFailOpt] = useState(true);
    const [disDocuSignOpt, setDisDocuSignOpt] = useState(true);
    const [disDocuSignCompletedOpt, setDisDocuSignCompletedOpt] = useState(true);
    const [disSubmitForEnrollOpt, setDisSubmitForEnrollOpt] = useState(true);
    const [disAccountActivatedOpt, setDisAccountActivatedOpt] = useState(true);
    const [disRejectOpt, setDisRejectOpt] = useState(true);
    const [disCancelOpt, setDisCancelOpt] = useState(true);
    const [applicantsName, setApplicantsName] = useState('Applicant');
    const [applicantEmail, setApplicantEmail] = useState('NA');

    const [jointOwnerName, setJointOwnerName] = useState('NA');
    const [jointOwnerEmail, setJointOwnerEmail] = useState('NA');

    const [ownerAge, setOwnerAge] = useState('NA');
    const [ownerMobileNbr, setOwnerMobileNbr] = useState('NA');

    const [disNewApp, setDisNewApp] = useState(true);
    const [disViewApp, setDisViewApp] = useState(true);
    const [disUploadDoc, setDisUploadDoc] = useState(true);
    const [disDownloadApp, setDisDownloadApp] = useState(true);
    const [disAppHist, setDisAppHist] = useState(true);
    const [disPrintApp, setDisPrintApp] = useState(true);
    const [disViewDoc, setDisViewDoc] = useState(true);
    const [disEditApp, setDisEditApp] = useState(true);
    const [disUnlockApp, setDisUnlockApp] = useState(true);
    const [disDeleteApp, setDisDeleteApp] = useState(true);
    const [disDSignResendMail, setDisDSignResendMail] = useState('none');
    const [disDSignStatus, setDisDSignStatus] = useState('none');

    const [disDSignHist, setDisDSignHist] = useState('none');

    const tickSrtDtNewDisp = useRef('block');
    const tickSrtDtOldDisp = useRef('none');
    const tickSrtOwnerDisp = useRef('none');
    const tickSrtAccTypeDisp = useRef('none');
    const tickSrtApplNameDisp = useRef('none');
    const tickSrtStatusDisp = useRef('none');

    const sortMenuAction = useRef(ApplicationSortAction.Newest);

    const [showAppViewDlg, setShowAppViewDlg] = useState(false);
    const [showAppHistDlg, setShowAppHistDlg] = useState(false);

    const [showDocUpldDlg, setShowDocUpldDlg] = useState(false);
    const [showDocListDlg, setShowDocListDlg] = useState(false);
    const [disStatusDiv, setDisStatusDiv] = useState(true);
    const [showExportToGWESDlg, setShowExportToGWESDlg] = useState(false);
    const [loaderText, setLoaderText] = useState('');
    const [logo2Url, setLogo2Url] = useState(null);
    const [isAppReadOnly, setIsAppReadOnly] = useState(true);

    const closeDocuSignDlg = () => {
        setShowDocuSignDlg(false);
        refetch();
        setRowSelection({ rowId: false }); //removing row selection 
    }

    const closeDocuSignMailDlg = () => {
        setShowDocuSignMailDlg(false);
    }

    const DocuSignResendMailDlg = async (msg, enrmntid) => {
        setShowDocuSignMailDlg(false);
        setDSSuccessMsg(msg);
        await LoadDocuSignEmailStatus(enrmntid);
    //    ResetRow();
    //    goToTop();
    }

    const closeDocuSignStatusDlg = () => {
        setShowDocuSignStatusDlg(false);
    }

    const closeDocuSignHistDlg = () => {
        setShowDocuSignHistDlg(false);
    }

    const onOkDocuSignDlg = async (msg) => {
        setShowDocuSignDlg(false);
        refetch();
        setRowSelection({ rowId: false }); //removing row selection 
        setSuccessMsgTitle('Requires DocuSign');
        setSuccessMsg(msg);
    }

    const handleStatusAccordianChange = () => {
        if (statusExpanded) {
            setStatusExpanded(false);
        }
        else {
            //if (userEnrolmlSts == null) {
            //    getEnrollmentStatus();
            //}
            setStatusExpanded(true);
        }
    };

    const closeAppViewDlg = () => {
        setShowAppViewDlg(false);
    }

    const closeAppHistDlg = () => {
        setShowAppHistDlg(false);
    }

    const closeDocUpldDlg = () => {
        setShowDocUpldDlg(false);
    }

    const closeDocListDlg = () => {
        ResetRow();
    }

    const ResetRow = () => {
        setRowSelection({});
        refetch();
        setApplicantsName('Applicant');
        setApplicantEmail(null);
        setOwnerAge(null);
        setOwnerMobileNbr(null);
        setAttachmentCount(null);
        setDocuSignRequiresCount(null);
        setDocuSignCompletedCount(null);
        setSubmittedDate(null);
        setImageUrl(null);
        setShowDocListDlg(false);
        disableAlltoolbarButton();
        DisableAllActions();
        setDisDSignStatus('none');
        setDisDSignResendMail('none');
        setDisDSignHist('none');
        setDocuSignEmailSts(null);
        setDSSuccessMsg('');
    }

    const disableAlltoolbarButton = () => {
        setDisViewDoc(true);
        setDisUploadDoc(true);
        setDisEditApp(true);
        setDisViewApp(true);
        setDisDownloadApp(true);
        setDisDeleteApp(true);
        setDisAppHist(true);
    }

    const onFileChangeHandle = async () => {
        setShowDocUpldDlg(false);
        setRowSelection({});
        refetch();
        setApplicantsName('Applicant');
        setApplicantEmail(null);
        setOwnerAge(null);
        setOwnerMobileNbr(null);
        setAttachmentCount(0);
        setDocuSignRequiresCount(0);
        setDocuSignCompletedCount(0);
    }

    const handleOptMenuClick = (event) => {
        setOptMnuAnchorEl(event.currentTarget);
    };
    const handleOptMenuClose = () => {
        setOptMnuAnchorEl(null);
    };

    const handleMoreMenuClick = (event) => {
        setMoreMnuAnchorEl(event.currentTarget);
    };
    const handleMoreMenuClose = () => {
        setMoreMnuAnchorEl(null);
    };

    const [sortMnuAnchorEl, setSortMnuAnchorEl] = useState(null);
    const openSort = Boolean(sortMnuAnchorEl);

    const handleSortMnuClick = (event) => {
        setSortMnuAnchorEl(event.currentTarget);
    };
    const handleSortMnuClose = () => {
        setSortMnuAnchorEl(null);
    };
    const handleReviewClick = (table) => {
        setOptMnuAnchorEl(null);
        newEnrolStatus.current = EnrollmentStatus.UnderReview;
        
        if (table.getSelectedRowModel().rows.length > 0) {
            let row = table.getSelectedRowModel().rows[0];

            navigate('/Enrollment', { state: { templateid: parseInt(templateId.current), name: enrolNm.current, enrmntid: selectedEnrolId.current, ownerid: selOwnerId.current, templatenm: templateNm.current, userId: userId, sessionId: sessionId, appreadonly: row.original.enrmntStatusId == EnrollmentStatus.SubmitApplication ? true : false, AdminView: true, EnrollmentStatus: selEnrolStatus.current }, replace: true })
        }
    };

    const handleReviewPassClick = (table) => {
        setOptMnuAnchorEl(null);
        newEnrolStatus.current = EnrollmentStatus.ReviewPass;

        if (table.getSelectedRowModel().rows.length > 0) {
            let row = table.getSelectedRowModel().rows[0];
            selectedEnrolId.current = row.original.enrmntId;
            confirmDlgBLine1.current = 'Application Owner: ' + row.original.ownerName;
            confirmDlgBLine2.current = 'Application Name: ' + row.original.name;
            confirmDlgBLine3.current = 'Applied For: ' + row.original.templateNm;
            confirmDlgBLine4.current = 'Applied: ' + row.original.crtDate;
        }
        confirmDlgHdr.current = 'Change the status from \'Under Review\' to \'Review Passed\' of the following application';
        setOpenConfirmDlg(true);
    };

    const handleKYCAMLClick = (table) => {
        setOptMnuAnchorEl(null);
        newEnrolStatus.current = EnrollmentStatus.KYCAMLInitiated;

        if (table.getSelectedRowModel().rows.length > 0) {
            let row = table.getSelectedRowModel().rows[0];
            selectedEnrolId.current = row.original.enrmntId;
            confirmDlgBLine1.current = 'Application Owner: ' + row.original.ownerName;
            confirmDlgBLine2.current = 'Application Name: ' + row.original.name;
            confirmDlgBLine3.current = 'Applied For: ' + row.original.templateNm;
            confirmDlgBLine4.current = 'Applied: ' + row.original.crtDate;
        }
        confirmDlgHdr.current = 'Change the status from \'Review passed\' to \'KYC/AML Initiated\' of the following application';
        setOpenConfirmDlg(true);
    };

    const handleKYCAMLPassClick = (table) => {
        setOptMnuAnchorEl(null);
        newEnrolStatus.current = EnrollmentStatus.KYCAMLPassed;

        if (table.getSelectedRowModel().rows.length > 0) {
            let row = table.getSelectedRowModel().rows[0];
            selectedEnrolId.current = row.original.enrmntId;
            confirmDlgBLine1.current = 'Application Owner: ' + row.original.ownerName;
            confirmDlgBLine2.current = 'Application Name: ' + row.original.name;
            confirmDlgBLine3.current = 'Applied For: ' + row.original.templateNm;
            confirmDlgBLine4.current = 'Applied: ' + row.original.crtDate;
        }
        confirmDlgHdr.current = 'Change the status from \'KYC/AML Initiated\' to \'KYC/AML Passed\' of the following application';
        setOpenConfirmDlg(true);
    };

    const handleDocuSignReqClick = (table) => {

        setOptMnuAnchorEl(null);
        if (table.getSelectedRowModel().rows.length > 0) {

            setErrorMsg('');
            setSuccessMsg('');

            let row = table.getSelectedRowModel().rows[0];

            if (row.original.DocuSignCount == 0) {
                setErrorMsg('No documents uploaded for signing.');
                return;
            }

            selectedEnrolId.current = row.original.enrmntId;
            confirmDlgBLine1.current = 'This will change the status of the application to \'Docusign Required\' and also send DocuSign Email to the DocuSign signer(s).';
        }
        setShowDocuSignDlg(true);
    };

    const handleDocuSignCompletedClick = (table) => {
        setOptMnuAnchorEl(null);

        if (table.getSelectedRowModel().rows.length > 0) {
            let row = table.getSelectedRowModel().rows[0];
            if (row.original.DocuSignCount > 0) {
                setErrorMsg('Document signing is not completed.');
                return;
            }
            selectedEnrolId.current = row.original.enrmntId;
            confirmDlgBLine1.current = 'Application Owner: ' + row.original.ownerName;
            confirmDlgBLine2.current = 'Application Name: ' + row.original.name;
            confirmDlgBLine3.current = 'Applied For: ' + row.original.templateNm;
            confirmDlgBLine4.current = 'Applied: ' + row.original.crtDate;
        }
        confirmDlgHdr.current = 'Change the status from \'Docusign Required\' to \'Docusign Completed\' of the following application';
        newEnrolStatus.current = EnrollmentStatus.DocuSignCompleted;
        setOpenConfirmDlg(true);
    };

    const handleSubmitForEnrollClick = (table) => {
        setOptMnuAnchorEl(null);
        newEnrolStatus.current = EnrollmentStatus.SubmittedEnrollment;
        let curEnrollSts = 'docusign complete';
        if (table.getSelectedRowModel().rows.length > 0) {
            let row = table.getSelectedRowModel().rows[0];
            selectedEnrolId.current = row.original.enrmntId;
            confirmDlgBLine1.current = 'Application Owner: ' + row.original.ownerName;
            confirmDlgBLine2.current = 'Application Name: ' + row.original.name;
            confirmDlgBLine3.current = 'Applied For: ' + row.original.templateNm;
            confirmDlgBLine4.current = 'Applied: ' + row.original.crtDate;
            curEnrollSts = row.original.enrmntStatus;
        }
        confirmDlgHdr.current = 'Change the status from \'' + curEnrollSts + '\' to \'Submitted for Enrollment\' of the following application';
        setOpenConfirmDlg(true);
    };

    const handleAccountActivateClick = (table) => {
        setOptMnuAnchorEl(null);
        newEnrolStatus.current = EnrollmentStatus.AccountActivated;
        
        if (table.getSelectedRowModel().rows.length > 0) {
            let row = table.getSelectedRowModel().rows[0];
            selectedEnrolId.current = row.original.enrmntId;
            confirmDlgBLine1.current = 'Application Owner: ' + row.original.ownerName;
            confirmDlgBLine2.current = 'Application Name: ' + row.original.name;
            confirmDlgBLine3.current = 'Applied For: ' + row.original.templateNm;
            confirmDlgBLine4.current = 'Applied: ' + row.original.crtDate;
        }
        confirmDlgHdr.current = 'Change the status from \'Submitted for Enrollment\' to \'Account Activated\' of the following application';
        setOpenConfirmDlg(true);
    };

    const handleReviewFailClick = (table) => {
        setOptMnuAnchorEl(null);
        newEnrolStatus.current = EnrollmentStatus.ReviewFail;

        if (table.getSelectedRowModel().rows.length > 0) {
            let row = table.getSelectedRowModel().rows[0];
            selectedEnrolId.current = row.original.enrmntId;
            confirmDlgBLine1.current = 'Application Owner: ' + row.original.ownerName;
            confirmDlgBLine2.current = 'Application Name: ' + row.original.name;
            confirmDlgBLine3.current = 'Applied For: ' + row.original.templateNm;
            confirmDlgBLine4.current = 'Applied: ' + row.original.crtDate;
            //confirmDlgBLine5.current = 'Please enter the reason for failure the review status.';
        }
        confirmDlgHdr.current = 'Change the status from \'Under Review\' to \'Review Failed\' of the following application';
        confirmDlgBLine5.current = 'Please enter the reason:';
        setOpenFormDlg(true);
    };

    const handleKYCAMLFailClick = (table) => {
        setOptMnuAnchorEl(null);
        newEnrolStatus.current = EnrollmentStatus.KYCAMLFailed;

        if (table.getSelectedRowModel().rows.length > 0) {
            let row = table.getSelectedRowModel().rows[0];
            selectedEnrolId.current = row.original.enrmntId;
            confirmDlgBLine1.current = 'Application Owner: ' + row.original.ownerName;
            confirmDlgBLine2.current = 'Application Name: ' + row.original.name;
            confirmDlgBLine3.current = 'Applied For: ' + row.original.templateNm;
            confirmDlgBLine4.current = 'Applied: ' + row.original.crtDate;            
        }
        confirmDlgHdr.current = 'Change the status from \'KYC/AML Initiated\' to \'KYC/AML Failed\' of the following application';
        confirmDlgBLine5.current = 'Please enter the reason for failure of KYC/AML:';
        setOpenFormDlg(true);
    };

    const handleCancelClick = (table) => {
        setOptMnuAnchorEl(null);
        newEnrolStatus.current = EnrollmentStatus.CancelApplication;
        let curEnrollSts = 'current status';
        if (table.getSelectedRowModel().rows.length > 0) {
            let row = table.getSelectedRowModel().rows[0];
            selectedEnrolId.current = row.original.enrmntId;
            confirmDlgBLine1.current = 'Application Owner: ' + row.original.ownerName;
            confirmDlgBLine2.current = 'Application Name: ' + row.original.name;
            confirmDlgBLine3.current = 'Applied For: ' + row.original.templateNm;
            confirmDlgBLine4.current = 'Applied: ' + row.original.crtDate;
            curEnrollSts = row.original.enrmntStatus;
        }
        confirmDlgHdr.current = 'Change the status from \'' + curEnrollSts + '\' to \'Canceled\' of the following application';
        confirmDlgBLine5.current = 'Please enter the reason for cancellation:';
        setOpenFormDlg(true);
    };

    const handleRejectClick = (table) => {

        setOptMnuAnchorEl(null);
        newEnrolStatus.current = EnrollmentStatus.RejectApplication;
        let curEnrollSts = 'current status';
        if (table.getSelectedRowModel().rows.length > 0) {
            let row = table.getSelectedRowModel().rows[0];
            selectedEnrolId.current = row.original.enrmntId;
            confirmDlgBLine1.current = 'Application Owner: ' + row.original.ownerName;
            confirmDlgBLine2.current = 'Application Name: ' + row.original.name;
            confirmDlgBLine3.current = 'Applied For: ' + row.original.templateNm;
            confirmDlgBLine4.current = 'Applied: ' + row.original.crtDate;
            curEnrollSts = row.original.enrmntStatus;
        }
        confirmDlgHdr.current = 'Change the status from \'' + curEnrollSts+ '\' to \'Rejected\' of the following application';
        confirmDlgBLine5.current = 'Please enter the reason for rejection:';
        setOpenFormDlg(true);
    };

    const handleConfirmDialogClose = (nvalue, svalue) => {
        setOpenConfirmDlg(false);
        if (nvalue) {
            updateEnrollmentStatus(nvalue, null);
        }
        setErrorMsg('');
    };
    const handleFormDialogClose = (nvalue, reason) => {
        setOpenFormDlg(false);
        if (nvalue && reason) {
            updateEnrollmentStatus(nvalue, reason);
        }
    };
    const handleEnrollmentDialogClose = () => {
        setOpenAppView(false);
        setPrintApp(false);

        //setSelectedEnrolmntValue(null);
    };

    const handleExportToGWESDlgClose = () => {
        setShowExportToGWESDlg(false);
    };
    const handleAPIError = async (response) => {

        let action = await GetAPIErrorAction(response);
        if (action.redirectUrl != null) {

            const path = {
                pathname: action.redirectUrl,
                search: action.searchParam
            };

            navigate(path, { state: { userId: userId, sessionId: sessionId }, replace: true, search: action.searchParam });

            //navigate({
            //    pathname: action.redirectUrl,
            //    search: action.searchParam,
            //    state: {},
            //    replace: true
            //});
        }
        else {
            setErrorMsg(action.Message);
        }
    }

    const exportToGwes = async () => {

        try {

            setErrorMsg('');
            setSuccessMsg('');

            setShowExportToGWESDlg(true);
            setLoaderText('Submitting Enrollment to GWES is in progress..');

            let approverEmail = GetLoggedInUserEmail(userId, sessionId);

            let inputJson = {
                "enrmntId": selectedEnrolId.current,                
                "approverEmail": approverEmail
            }

            setIsDataLoading(true);

            let apiUrl = new URL(
                process.env.REACT_APP_EXPORT_ENROLLMENT,
                process.env.REACT_APP_BASE_URL,
            );

            let response = await APICall({ url: apiUrl.href, method: 'POST', payload: inputJson, contentType: 'application/json', responseType: '' }, userId, sessionId);

            if (response !== null && response !== undefined) {

                let error = IsError(response);

                if (error != 0) {
                    setErrorMsg(response.Errors[0].Message);
                }
                else {

                    let hasFile = response.data[0].HasFile;
                    let gwesRefId = response.data[0].GWESRefId;
                    let enrollmentAcctNo = response.data[0].EnrollmentAcctNo;
                    if (!hasFile) {
                        setShowExportToGWESDlg(false);
                        ResetRow();
                        setSuccessMsgTitle("Export to GWES");
                        setSuccessMsg("Enrollment exported to GWES sucessfully. GWES reference number " + enrollmentAcctNo);
                        return true;
                    }
                    else {
                        //File Transferring to GWES                       
                        //await exportFileToGwes(selectedEnrolId.current, gwesRefId, enrollmentAcctNo);

                        setLoaderText('File upload in progress..');
                        inputJson = {
                            "enrmntId": selectedEnrolId.current,
                            "gwesRefId": gwesRefId
                        }

                        apiUrl = new URL(
                            process.env.REACT_APP_EXPORT_ATTACHMENT_ENROLLMENT,
                            process.env.REACT_APP_BASE_URL,
                        );

                        let response = await APICall({ url: apiUrl.href, method: 'POST', payload: inputJson, contentType: 'application/json', responseType: '' }, userId, sessionId);

                        if (response !== null && response !== undefined) {

                            setShowExportToGWESDlg(false);

                            let error = IsError(response);

                            if (error != 0) {
                                setErrorMsg(response.Errors[0].Message);
                            }
                            else {
                                ResetRow();
                                setSuccessMsgTitle("Export to GWES");
                                setSuccessMsg("Enrollment exported to GWES sucessfully. GWES reference number " + enrollmentAcctNo);
                                return true;
                            }

                        }
                    }
                }

            }
        }
        catch (err) {
            setErrorMsg(ErrorMessages.UnknownError);
        }
        finally {
            setIsDataLoading(false);
            setShowExportToGWESDlg(false);
        }

        return false;
    }

    const exportFileToGwes = async (enrollmentId, gwesRefId, enrollmentAcctNo) => {
        try {

            setErrorMsg('');
            setSuccessMsg('');

            setIsDataLoading(false);
            setShowExportToGWESDlg(true);
            setLoaderText('File upload in progress..');

            let inputJson = {
                "enrmntId": enrollmentId,
                "gwesRefId": gwesRefId
            }

            const apiUrl = new URL(
                process.env.REACT_APP_EXPORT_ATTACHMENT_ENROLLMENT,
                process.env.REACT_APP_BASE_URL,
            );

            let response = await APICall({ url: apiUrl.href, method: 'POST', payload: inputJson, contentType: 'application/json', responseType: '' }, userId, sessionId);

            if (response !== null && response !== undefined) {

                let error = IsError(response);

                if (error != 0) {                   
                    setErrorMsg(response.Errors[0].Message);
                }
                else {
                    refetch();
                    setRowSelection({ rowId: false }); //removing row selection 
                    DisableApplOptCntrl();
                    setSuccessMsgTitle("Export to GWES");
                    setSuccessMsg("Enrollment exported to GWES sucessfully. GWES reference number " + enrollmentAcctNo);
                    return true;
                }

            }
        
        }
        catch (err) {
            setErrorMsg(ErrorMessages.UnknownError);
        }
        finally {           
            setShowExportToGWESDlg(false);
        }

        return false;

    }


    const handleConfirmDelDialogClose = async (nvalue, svalue) => {

        setOpenDelConfirmDlg(false);

        if (nvalue == null)
            return;

        try {

            setIsDataLoading(true);

            const apiUrl = new URL(
                process.env.REACT_APP_DELETE_ENROLLMENT,
                process.env.REACT_APP_BASE_URL,
            );

            const inputJson = {
                enrmntId: parseInt(nvalue),
                name: enrolNm.current
            };

            //let response = await CallAPI({ url: apiUrl.href, method: 'POST', body: inputJson }, userId, sessionId);

            let response = await APICall({ url: apiUrl.href, method: 'POST', payload: inputJson, contentType: 'application/json', responseType: '' }, userId, sessionId);

            if (response !== null && response !== undefined) {

                setIsDataLoading(false);

                let error = IsError(response);

                if (error != 0) {
                    setErrorMsg(response.Errors[0].Message);
                    return;
                }

                await WriteToActivityLog(ActivityLogEvent.ApplicationDashboard, "Application deleted. Application name '" + enrolNm.current + "'", userId, sessionId);

                setSuccessMsgTitle("Application deleted");
                setSuccessMsg('Application named \'' + enrolNm.current + '\' deleted successfully.');
                ResetRow();
            }
        }
        catch (err) {
            setErrorMsg(ErrorMessages.UnknownError);
            setIsDataLoading(false);
        }
        finally {
            
        }
    };

    const updateEnrollmentStatus = async (enrmntStatusId, reason) => {

        try {

            let isDevEnv = 0;
            let devEnv = process.env.REACT_APP_DEV_ENV;

            if (devEnv != undefined && devEnv != null) {
                isDevEnv = parseInt(devEnv);
            }

            if (enrmntStatusId == EnrollmentStatus.SubmittedEnrollment && isDevEnv == 0) {
                await exportToGwes();
                return;
            }

            setErrorMsg('');
            setSuccessMsg('');
            const inputJson = {
                enrmntId: selectedEnrolId.current,
                enrmntStatusId: enrmntStatusId,
                stsReason: reason
            };

            setIsDataLoading(true);

            const apiUrl = new URL(
                process.env.REACT_APP_UPDATE_USER_ENROLMENT_STATUS,
                process.env.REACT_APP_BASE_URL,
            );


            let response = await APICall({ url: apiUrl.href, method: 'POST', payload: inputJson, contentType: 'application/json', responseType: '' }, userId, sessionId);

            if (response !== null && response !== undefined) {

                if (response.Errors.length > 0) {
                    setErrorMsg(response.Errors[0].Message);
                }
                else if (response.meta.Updated === "1") {
                    refetch();
                    setRowSelection({ rowId: false }); //removing row selection 
                    DisableApplOptCntrl();

                    await WriteToActivityLog(ActivityLogEvent.ApplicationDashboard, "Application status updated. Application name '" + enrolNm.current + "'. New status: '" + GetAppSatusStr(enrmntStatusId) + "'", userId, sessionId);
                    setSuccessMsgTitle("Application status updated");
                    setSuccessMsg("Application status updated successfully.");
                }

                setIsDataLoading(false);

                if (enrmntStatusId == EnrollmentStatus.UnderReview) {
                    navigate('/Enrollment', { state: { templateid: parseInt(templateId.current), name: enrolNm.current, enrmntid: selectedEnrolId.current, ownerid: selOwnerId.current, templatenm: templateNm.current, userId: userId, sessionId: sessionId, appreadonly: false, AdminView: true }, replace: true })
                }
            }
        }
        catch (err) {
            setErrorMsg(ErrorMessages.UnknownError);
            setIsDataLoading(false);
        }
        finally {
        }
    }

    const getEnrollmentStatus = async () => {

        const apiUrl = new URL(
            process.env.REACT_APP_GET_USER_ENROLMENT_STATUS,
            process.env.REACT_APP_BASE_URL,
        );

        try {

            //setIsDataLoading(true);

            await WriteToActivityLog(ActivityLogEvent.ApplicationDashboard, "Application list viewed.", userId, sessionId);

            let response = await APICall({ url: apiUrl.href, method: 'GET', contentType: 'application/json', responseType: '' }, userId, sessionId);

            if (response !== null && response !== undefined) {
                setUserEnrolmlSts(response.data)
            }
        }
        catch (err) {
            setAppStatusErrorMsg("ERROR while getting application information");
        }
        finally {
            //setIsDataLoading(false);
        }
    }
    const columns = [
        {
            accessorKey: 'AttachmentCount',
            header: '',
            muiTableBodyCellProps: ({ cell }) => ({
                sx: (theme) => ({
                    borderLeft: 1,
                    //borderTop: '1px #BBBFBF solid',
                    borderBottom: 1,
                    borderColor: 'divider',
                    //mt: 1, mb: 1,
                    //borderRadius: '6px',
                    pt: 1, pb: 0.5,
                    boxShadow: 'rgba(99, 99, 99, 0.2) 0px 2px 8px 0px'
                }),
            }),
            muiTableHeadCellProps: ({ cell }) => ({
                sx: (theme) => ({
                    borderLeft: 1,
                    borderTop: 1,
                    borderBottom: 1,
                    borderColor: 'divider',
                    //borderBottom: '1px #b6b7b6 solid',
                    //background: '#f7f7f7',
                    //borderRadius: '6px',
                    //mt: 1, mb: 1,
                    //borderRadius: '6px',
                    pt: 1, pb: 1
                }),
            }),
            Cell: ({ renderedCellValue, row }) =>
                row.original.AttachmentCount > 0 ? (
                    <AttachFileIcon sx={{ fontSize: 14, color: 'Icon.main', p: 0, mr: 3}} />
                ): (
                        ''
                ),
            size: 50
        },
        {
            accessorKey: 'ownerName',
            header: 'Owner',
            muiTableHeadCellProps: ({ cell }) => ({
                sx: (theme) => ({
                    color: 'text.secondary',
                    fontSize: theme.typography.body2,
                    borderTop: 1,
                    //borderLeft: '1px #BBBFBF solid',
                    borderBottom: 1,
                    borderColor: 'divider',
                    pl: 2, pr: 2,
                    //background: '#f7f7f7',
                    //letterSpacing: '1px',
                    //borderRadius: '6px',
                    pt: 1, pb: 1,
                }),
            }),
            Cell: ({ cell, column }) => (
                <Box>
                    {cell.getValue() && cell.getValue().length > 25 ? cell.getValue().substring(0, 25) + '...' : cell.getValue()}
                </Box>
            ),
            size: 250
        },
        {
            accessorKey: 'templateNm',
            header: 'Applied For',
            muiTableHeadCellProps: ({ cell }) => ({
                sx: (theme) => ({
                    color: 'text.secondary',
                    fontSize: theme.typography.body2,
                    borderTop: 1,
                    borderBottom: 1,
                    borderColor: 'divider',
                    fontWeight: 700,
                    pl: 2, pr: 2,
                    pt: 1, pb: 1,
                }),
            }),
            Cell: ({ cell, column }) => (
                <Box>
                    {cell.getValue() && cell.getValue().length > 25 ? cell.getValue().substring(0, 25) + '...' : cell.getValue()}
                </Box>
            ),
            size: 230
        },
        {
            accessorKey: 'name',
            header: 'Name',
            Cell: ({ cell, column }) => (
                <Box>
                    {cell.getValue() && cell.getValue().length > 25 ? cell.getValue().substring(0, 25) + '...' : cell.getValue()}
                </Box>
            ),
            size: 280
        },
        {
            accessorKey: 'enrmntStatus',
            header: 'Status',
            Cell: ({ cell, column }) => (
                <Box>
                    {cell.getValue() && cell.getValue().length > 25 ? cell.getValue().substring(0, 25) + '...' : cell.getValue()}
                </Box>
            ),
            size: 230
        },
        {
            accessorKey: 'JointOwnerName',
            header: 'Joint Owner Name',
            Cell: ({ cell, column }) => (
                <Box>
                    {cell.getValue() && cell.getValue().length > 25 ? cell.getValue().substring(0, 25) + '...' : cell.getValue()}
                </Box>
            ),
            size: 280
        },
        {
            accessorKey: 'JointOwnerEmail',
            header: 'Joint Owner Email',
            Cell: ({ cell, column }) => (
                <Box>
                    {cell.getValue() && cell.getValue().length > 25 ? cell.getValue().substring(0, 25) + '...' : cell.getValue()}
                </Box>
            ),
            size: 280
        },
        {
            accessorKey: 'CancelRequest',
            header: 'Cancellation Request',
            Cell: ({ cell, column }) => (
                <Box
                    sx={{
                        backgroundColor: cell.getValue() === true ? '#ff6d00' : 'transparent',
                        color: cell.getValue() === true ? '#fff' : '#565959', p: 0.5
                    }}
                >
                    {cell.getValue() === true ? 'Yes' : 'No'}
                </Box>
            ),
            size: 270
        },
        {
            accessorKey: 'GWESEnrollId',
            header: 'GWES Enrollment Id',
            size: 270
        },
        {
            accessorKey: 'CreatedBy',
            header: 'Created By',
            Cell: ({ cell, column }) => (
                <Box>
                    {cell.getValue() && cell.getValue().length > 25 ? cell.getValue().substring(0, 25) + '...' : cell.getValue()}
                </Box>
            ),
            size: 250
        },
        {
            accessorKey: 'modDate',
            header: 'Last Modified',
            Cell: ({ cell, column }) => (
                <Box>
                    {cell.getValue() && cell.getValue().length > 25 ? cell.getValue().substring(0, 25) + '...' : cell.getValue()}
                </Box>
            ),
            size: 230
        },
    ];

    const fetchSize = 25;

    const tableContainerRef = useRef(null); //we can get access to the underlying TableContainer element and react to its scroll events
    const virtualizerInstanceRef = useRef < Virtualizer > null; //we can get access to the underlying Virtualizer instance and call its scrollToIndex method

    const [columnFilters, setColumnFilters] = useState([]);
    const [globalFilter, setGlobalFilter] = useState();
    const [sorting, setSorting] = useState([]);

    const { data, fetchNextPage, isError, isFetching, isLoading, refetch } =
        useInfiniteQuery(
            ['table-data', columnFilters, globalFilter, sorting],
            async ({ pageParam = 0 }) => {

                const apiUrl = new URL(
                    process.env.REACT_APP_GET_USER_ENROLLMENTS,
                    process.env.REACT_APP_BASE_URL,
                );

                apiUrl.searchParams.set('start', `${pageParam * fetchSize}`);
                apiUrl.searchParams.set('size', `${fetchSize}`);

                var date1 = fromDate !== null ? moment(fromDate, 'YYYY-MM-DD') : null;
                var date2 = toDate !== null ? moment(toDate, 'YYYY-MM-DD') : null;

                if (!ignoreDate) {
                    apiUrl.searchParams.set('startTime', date1 !== null ? date1.format('MM/DD/YYYY') : ``);
                    apiUrl.searchParams.set('endTime', date2 !== null ? date2.format('MM/DD/YYYY') : ``);
                }

                apiUrl.searchParams.set('filters', JSON.stringify(columnFilters ?? []));
                apiUrl.searchParams.set('globalFilter', `${searchText}`);
                apiUrl.searchParams.set('sorting', sortMenuAction.current);

                let response = await APICall({ url: apiUrl.href, method: 'GET', contentType: 'application/json', responseType: '' }, userId, sessionId);

                if (response !== null && response !== undefined) {

                    if (response.data.length > 0) {
                        setDisStatusDiv(false);
                    }
                    return response;
                }
            },
            {
                getNextPageParam: (_lastGroup, groups) => groups.length,
                keepPreviousData: true,
                refetchOnWindowFocus: false,
            },
        );

    const flatData = useMemo(
        () => data?.pages.flatMap((page) => page.data) ?? [],
        [data],
    );

    const totalDBRowCount = data?.pages?.[0]?.meta?.totalRowCount ?? 0;
    const totalFetched = flatData.length;
    const fetchMoreOnBottomReached = useCallback(
        (containerRefElement) => {
            if (containerRefElement) {
                const { scrollHeight, scrollTop, clientHeight } = containerRefElement;
                if (
                    scrollHeight - scrollTop - clientHeight < 400 &&
                    !isFetching &&
                    totalFetched < totalDBRowCount
                ) {
                    fetchNextPage();
                }
            }
        },
        [fetchNextPage, isFetching, totalFetched, totalDBRowCount],
    );

    const printAppData = async (enrmntId) => {
        //console.log('print');  
        //return;
        let response = await getEnrollment(enrmntId);

        if (response && response.length > 0) {
            let enrolData = response[0].enrmntData;
            var divText = '<div>'
            divText = divText.concat('<div style="position: absolute; width: 100%;"><h4 style="text-align: center;">Review Application</h4></div>');
            /* divText = divText.concat('<hr />');*/
            //divText = divText.concat(renderToString(<ApplicationEnrollmentPrint id="ringtone-from-menu" value={enrolData} />));  //document.getElementById("printablediv").innerHTML;
            divText = divText.concat(renderToString(<ApplicationEnrollmentPrint id="ringtone-from-menu" enrollData={response[0].enrmntData} enrmntid={enrmntId} acctType={response[0].templateNm} applicantImage={imageUrl} avtarImage={profimg} session={sessions} dispatch={dispatch} />));  //document.getElementById("printablediv").innerHTML;
            divText = divText.concat('</div>');

            let width = window.screen.width - 10;
            let height = window.screen.height - 10;
            var win = window.open('', '_blank', "toolbar=no,location=no,status=no,menubar=no,scrollbars=yes,resizable=yes,width=" + width + ",height=" + height);

            let printerIcon = '<svg fill="#000" height="36px" width="36px" version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" ';
            printerIcon += 'viewBox="-23.68 -23.68 111.36 111.36" enable-background="new 0 0 64 64" xml:space="preserve" stroke="#0000" stroke-width="1.6"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_iconCarrier"> <g id="Printer"> <path d="M57.7881012,14.03125H52.5v-8.0625c0-2.2091999-1.7909012-4-4-4h-33c-2.2091999,0-4,1.7908001-4,4v8.0625H6.2119002 C2.7871001,14.03125,0,16.8183498,0,20.2431507V46.513649c0,3.4248009,2.7871001,6.2119026,6.2119002,6.2119026h2.3798995 c0.5527,0,1-0.4472008,1-1c0-0.5527-0.4473-1-1-1H6.2119002C3.8896,50.7255516,2,48.8359489,2,46.513649V20.2431507 c0-2.3223,1.8896-4.2119007,4.2119002-4.2119007h51.5762024C60.1102982,16.03125,62,17.9208508,62,20.2431507V46.513649 c0,2.3223-1.8897018,4.2119026-4.2118988,4.2119026H56c-0.5527992,0-1,0.4473-1,1c0,0.5527992,0.4472008,1,1,1h1.7881012 C61.2128983,52.7255516,64,49.9384499,64,46.513649V20.2431507C64,16.8183498,61.2128983,14.03125,57.7881012,14.03125z M13.5,5.96875c0-1.1027999,0.8971996-2,2-2h33c1.1027985,0,2,0.8972001,2,2v8h-37V5.96875z"></path> <path d="M44,45.0322495H20c-0.5517998,0-0.9990005,0.4472008-0.9990005,0.9990005S19.4482002,47.0302505,20,47.0302505h24 c0.5517006,0,0.9990005-0.4472008,0.9990005-0.9990005S44.5517006,45.0322495,44,45.0322495z"></path> <path d="M44,52.0322495H20c-0.5517998,0-0.9990005,0.4472008-0.9990005,0.9990005S19.4482002,54.0302505,20,54.0302505h24 c0.5517006,0,0.9990005-0.4472008,0.9990005-0.9990005S44.5517006,52.0322495,44,52.0322495z"></path> <circle cx="7.9590998" cy="21.8405495" r="2"></circle> <circle cx="14.2856998" cy="21.8405495" r="2"></circle> <circle cx="20.6121998" cy="21.8405495" r="2"></circle> <path d="M11,62.03125h42v-26H11V62.03125z M13.4036999,38.4349518h37.1925964v21.1925964H13.4036999V38.4349518z"></path> </g> </g></svg>';

            //let closeIcon = '<svg fill="#000000" height="36px" width="36px" version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 36 36" xml:space="preserve">';
            //closeIcon += '<g><g><polygon points="512,59.076 452.922,0 256,196.922 59.076,0 0,59.076 196.922,256 0,452.922 59.076,512 256,315.076 452.922,512 512,452.922 315.076,256 		"/></g></g></svg>';

            win.document.open();
            win.document.write('<html>');
            win.document.write('<head>');
            win.document.write('<title>Application Review</title>');
            //win.document.write('<link href="https://cdn.jsdelivr.net/npm/bootstrap@5.0.2/dist/css/bootstrap.min.css" rel="stylesheet" integrity="sha384-EVSTQN3/azprG1Anm3QDgpJLIm9Nao0Yz1ztcQTwFspd3yD65VohhpuuCOmLASjC" crossorigin="anonymous">');
            //win.document.write('<script src="https://cdn.jsdelivr.net/npm/bootstrap@5.0.2/dist/js/bootstrap.bundle.min.js" integrity="sha384-MrcW6ZMFYlzcLA8Nl+NtUVF0sA7MsXsP1UyJoMp4YLEuNSfAP+JcXn/tWtIaxVXM" crossorigin="anonymous"></script>');
            win.document.write('<style type="text/css" media="print">.no-print {display: none !important; }</style>');

            win.document.write('</head>');
            win.document.write('<body onload="window.print()">');
            win.document.write('<p style="display: flex;justify-content: right; text-decoration-style: solid;font-weight: bold; margin-top:20px;margin-right:50px;" class="no-print">');
            win.document.write('<span class="no-print" style="cursor: pointer;" onclick="{window.print();}" title="Print">' + printerIcon + '</span>');
            win.document.write('<span class="no-print" style="cursor: pointer;margin-top:5px;margin-left:5px;" onclick="{window.close();}" title="Close">&#10006;</span>');
            win.document.write('</p>');

            win.document.write(divText);
            win.document.write('</body></html>');
            win.document.close();
            win.open();

            setPrintApp(false);
        }
        //setTimeout(() => {
        //    win.print();
        //}, 1000);
    }

    useEffect(() => {

        if (virtualizerInstanceRef.current) {
            virtualizerInstanceRef.current.scrollToIndex(0);
        }
    }, [sorting, columnFilters, globalFilter]);

    useEffect(() => {

        fetchMoreOnBottomReached(tableContainerRef.current);        
    }, [fetchMoreOnBottomReached]);

    useEffect(() => {

        let isAdmin = IsInRole(AOBSystemProcess.AllProcess, userId, sessionId);
        let canAdd = IsInRole(AOBSystemProcess.ApplicationMaintenance, userId, sessionId) || isAdmin;
        if (canAdd) {
            setDisNewApp(false);
        }

        getEnrollmentStatus();
        loadLogo2Image();
    }, []);

    const handleCreateNewUser = () => {

    };
    const handleRemoveUsers = () => {
    };

    const handleChange = (event) => {
        setRowSelection('500', true);
    };

    const handleAxiosError = async (ex) => {

        let action = await GetAxiosErrorAction(ex);
        if (action.redirectUrl != null) {

            navigate({
                pathname: action.redirectUrl,
                search: action.searchParam,
                state: {},
                replace: true
            });
        }
        else if (action.Message != ''){
            setErrorMsg(action.Message);
        }
    }

    const LoadDocuSignEmailStatus = async (enrmntId) => {

        try {

            let apiUrl = new URL(
                process.env.REACT_APP_GET_DOCUSIGN_EMAIL_STATUS,
                process.env.REACT_APP_BASE_URL,
            );

            apiUrl.searchParams.set('enrmntId', `${parseInt(enrmntId)}`);

            let response = await APICall({ url: apiUrl.href, method: 'GET', contentType: 'application/json', responseType: '' }, userId, sessionId);
            if (response !== null && response !== undefined) {
                if (response["data"] && response["data"].length > 0) {
                    setDocuSignEmailSts(response["data"]);
                }
                else {

                    let apiErrors = response["Errors"];

                    if (apiErrors != undefined && apiErrors.length > 0) {
                        setErrorMsg(apiErrors[0].Message);
                    }
                    else {
                        setDocuSignEmailSts(null);
                    }
                }
            }
        }
        catch (err) {
            setErrorMsg(ErrorMessages.UnknownError);
        }
        finally {
        }
    }

    const HandleRowClick = async (row) => {
        setRowId(row.id);
        selectedEnrolId.current = row.original.enrmntId;
        setApplicantsName(row.original.ownerName ? (row.original.ownerTitle ? row.original.ownerTitle + ' ' + row.original.ownerName : row.original.ownerName) : 'Applicant');
        setApplicantEmail(row.original.ownerEmail);

        setJointOwnerName(row.original.JointOwnerName);
        setJointOwnerEmail(row.original.JointOwnerEmail);

        setOwnerAge(row.original.ownerAge ? (row.original.ownerAge > 1 ? row.original.ownerAge + ' Years' : row.original.ownerAge + ' Year' ) : '0 Year');
        setOwnerMobileNbr(row.original.mobileNbr ? row.original.mobileNbr : 'NA');
        setAttachmentCount(row.original.AttachmentCount);
        setDocuSignRequiresCount(row.original.DocuSignCount);
        setDocuSignCompletedCount(row.original.DocuSignCompletedCount);
        setDocuSignDeclineCount(row.original.DocuSignDeclineCount);
        setGwesRefId(row.original.GWESRefId);
        setGWESEnrollId(row.original.GWESEnrollId);
        setSubmittedDate(row.original.SubmittedDate);
        enrolNm.current = row.original.name;
        templateId.current = row.original.templateId;
        templateNm.current = row.original.templateNm;
        selOwnerId.current = row.original.ownerId;
        selEnrolStatus.current = row.original.enrmntStatusId;
        setDSSuccessMsg('');

        try {

            setErrorMsg('');
            setIsDataLoading(true);

            let apiUrl = new URL(
                process.env.REACT_APP_GET_APPLICANT_IMAGE,
                process.env.REACT_APP_BASE_URL,
            );

            apiUrl.searchParams.set('enrmntId', `${parseInt(row.original.enrmntId)}`);
            apiUrl.searchParams.set('tag', 'ApplicantImage');

            //let imageURL = await GetApplicantImage(apiUrl.href, userId, sessionId);

            let response = await APICall({ url: apiUrl.href, method: 'GET', responseType: 'blob' }, userId, sessionId);

            if (response) {
                if (response.type == 'image/jpeg') {
                    setImageUrl(response);
                }
                else {
                    setImageUrl(null);
                }
            }

            if (selEnrolStatus.current == EnrollmentStatus.DocuSignRequired
                || selEnrolStatus.current == EnrollmentStatus.DocuSignCompleted
                || selEnrolStatus.current == EnrollmentStatus.AccountActivated) {

                await LoadDocuSignEmailStatus(row.original.enrmntId);
            }
            else {
                setDocuSignEmailSts(null);
            }
        }
        catch (err) {
            setImageUrl(null);
            setErrorMsg(ErrorMessages.UnknownError);
            setIsDataLoading(false);
        }
        finally {
            setIsDataLoading(false);
        }

        await EnableDisableApplOpt(row);
        await EnableDisableApplCntrl(row);
    }
    const DisableAllActions = async () => {
        setDisRevOpt(true);
        setDisRevPassOpt(true);
        setDisRevFailOpt(true);
        setDisKYCAMLCheckOpt(true);
        setDisKYCAMLPassOpt(true);
        setDisKYCAMLFailOpt(true);
        setDisDocuSignOpt(true);
        setDisDocuSignCompletedOpt(true);
        setDisSubmitForEnrollOpt(true);
        setDisAccountActivatedOpt(true);
        setDisRejectOpt(true);
        setDisCancelOpt(true);
    }
    const EnableDisableApplOpt = async (row) => {
        
        DisableAllActions();

        let isAdmin = IsInRole(AOBSystemProcess.AllProcess, userId, sessionId);
        let canApprove = IsInRole(AOBSystemProcess.ApproveApplication, userId, sessionId) || isAdmin;
        let canPublish = IsInRole(AOBSystemProcess.PublishApplication, userId, sessionId) || isAdmin;

        if (row.original.enrmntStatusId !== EnrollmentStatus.InComplete && row.original.enrmntStatusId !== EnrollmentStatus.Complete
            && row.original.enrmntStatusId !== EnrollmentStatus.AccountActivated && parseInt(row.original.crtUsrId) == parseInt(userId)) {
            setDisCancelOpt(false);
        }

        if (!canApprove && !canPublish) {
            return;
        }

        if (row.original.CancelRequest == true) {
            setDisCancelOpt(false);
        }

        switch (row.original.enrmntStatusId) {
            case EnrollmentStatus.SubmitApplication:
                if (canApprove) {
                    setDisRevOpt(false);
                }

                break;
            case EnrollmentStatus.UnderReview:
                if (canApprove) {
                    setDisRevOpt(false);
                    setDisRejectOpt(false);
                }
                break;
            case EnrollmentStatus.ReviewPass:
                if (canApprove) {
                    setDisKYCAMLCheckOpt(false);
                    setDisRejectOpt(false);
                }
                break;
            case EnrollmentStatus.KYCAMLInitiated:
                if (canApprove) {
                    setDisKYCAMLPassOpt(false);
                    setDisKYCAMLFailOpt(false);
                    setDisRejectOpt(false);
                }
                break;
            case EnrollmentStatus.KYCAMLPassed:
                if (canPublish) {
                    setDisSubmitForEnrollOpt(false);
                }
                if (canApprove) {
                    setDisRejectOpt(false);
                }
                break;
            case EnrollmentStatus.SubmittedEnrollment:
                if (canApprove) {
                    setDisDocuSignOpt(false);
                    setDisRejectOpt(false);
                }
                break;
            case EnrollmentStatus.DocuSignRequired:
                if (canApprove) {
                    setDisDocuSignCompletedOpt(false);
                    setDisRejectOpt(false);
                }
                break;
            case EnrollmentStatus.DocuSignCompleted:
                //setDisDocuSignOpt(false);
                if (canApprove) {
                    setDisAccountActivatedOpt(false);
                    setDisRejectOpt(false);
                }
                break;
            default:
            // code block
        }
    }

    const EnableDisableApplCntrl = async (row) => {
        //setDisNewApp(true);
        setDisViewApp(true);
        setDisUploadDoc(true);
        setDisDownloadApp(true);
        setDisPrintApp(true);
        setDisViewDoc(true);
        setDisEditApp(true);
        setDisUnlockApp(true);
        setDisDeleteApp(true);
        setIsAppReadOnly(false);
        setDisAppHist(true);
        setDisDSignStatus('none');
        setDisDSignResendMail('none');
        setDisDSignHist('none');

        //let userID = GetLoggedInUserId();

        let isAdmin = IsInRole(AOBSystemProcess.AllProcess, userId, sessionId);
        let canApprove = IsInRole(AOBSystemProcess.ApproveApplication, userId, sessionId) || isAdmin;
        let canEdit = IsInRole(AOBSystemProcess.ApplicationMaintenance, userId, sessionId) || isAdmin;
        let canDelete = IsInRole(AOBSystemProcess.DeleteApplication, userId, sessionId) || isAdmin;
        let canView = IsInRole(AOBSystemProcess.ViewApplication, userId, sessionId) || isAdmin;

        if (row.original.enrmntStatusId === EnrollmentStatus.DocuSignRequired || row.original.enrmntStatusId === EnrollmentStatus.DocuSignCompleted) {
            setDisDSignStatus('block');
            setDisDSignHist('block');
        }
        if (row.original.enrmntStatusId === EnrollmentStatus.DocuSignRequired) {
            setDisDSignResendMail('block');
        }


        if (row.original.enrmntStatusId !== EnrollmentStatus.InComplete && row.original.enrmntStatusId !== EnrollmentStatus.Complete
            && row.original.enrmntStatusId !== EnrollmentStatus.CancelApplication && row.original.enrmntStatusId !== EnrollmentStatus.RejectApplication
            && row.original.enrmntStatusId !== EnrollmentStatus.DocuSignRequired && (canApprove || (parseInt(row.original.crtUsrId) == parseInt(userId)))) {
            setDisUploadDoc(false);
        }

        if (row.original.enrmntStatusId === EnrollmentStatus.SubmitApplication || row.original.enrmntStatusId === EnrollmentStatus.ReviewPass
            || row.original.enrmntStatusId === EnrollmentStatus.KYCAMLInitiated || row.original.enrmntStatusId === EnrollmentStatus.KYCAMLPassed)
        {
            setIsAppReadOnly(true);
        }


        if (canEdit) {
            if (((row.original.enrmntStatusId === EnrollmentStatus.InComplete || row.original.enrmntStatusId === EnrollmentStatus.Complete
                || row.original.enrmntStatusId === EnrollmentStatus.ReviewFail))) {
                setDisEditApp(false);
            }
        }

        if (row.original.enrmntStatusId !== EnrollmentStatus.InComplete && row.original.enrmntStatusId !== EnrollmentStatus.Complete
            && row.original.enrmntStatusId !== EnrollmentStatus.SubmitApplication && row.original.enrmntStatusId !== EnrollmentStatus.UnderReview
            && (canApprove || (parseInt(row.original.crtUsrId) == parseInt(userId)))) {
            setDisUnlockApp(false);
        }

        //if (!canDelete) {
        //    if (parseInt(row.original.ownerId) === parseInt(userId) || parseInt(row.original.crtUsrId) === parseInt(userId) || isAdmin)
        //        canDelete = true;
        //}

        if (row.original.enrmntStatusId === EnrollmentStatus.InComplete || row.original.enrmntStatusId === EnrollmentStatus.Complete
            || row.original.enrmntStatusId === EnrollmentStatus.CancelApplication || row.original.enrmntStatusId === EnrollmentStatus.RejectApplication
            || row.original.enrmntStatusId === EnrollmentStatus.KYCAMLFailed || row.original.enrmntStatusId === EnrollmentStatus.AccountActivated
            && (canDelete || (parseInt(row.original.crtUsrId) == parseInt(userId)))) {
            setDisDeleteApp(false);
        }

        if (row.original.enrmntStatusId !== EnrollmentStatus.InComplete && row.original.enrmntStatusId !== EnrollmentStatus.Complete
            && (canView || (parseInt(row.original.crtUsrId) == parseInt(userId)))) {
            setDisViewApp(false);
            setDisViewDoc(false);
            setDisDownloadApp(false);
            setDisAppHist(false);
        }

        //if (canView) {
        //    setDisViewDoc(false);
        //    setDisDownloadApp(false);
        //}

        //if (canView) {
        //    if (row.original.enrmntStatusId !== EnrollmentStatus.InComplete && row.original.enrmntStatusId !== EnrollmentStatus.Complete) {
        //        setDisAppHist(false);
        //    }
        //}
    }

    const DisableApplOptCntrl = () => {
        setDisRevOpt(true);
        setDisRevPassOpt(true);
        setDisRevFailOpt(true);
        setDisKYCAMLCheckOpt(true);
        setDisKYCAMLPassOpt(true);
        setDisKYCAMLFailOpt(true);
        setDisDocuSignOpt(true);
        setDisSubmitForEnrollOpt(true);
        setDisRejectOpt(true);
        setDisAccountActivatedOpt(true);
        setDisUnlockApp(true);

        setDisViewApp(true);
        setDisUploadDoc(true);
        setDisDownloadApp(true);
        setDisPrintApp(true);
        setDisViewDoc(true);
        setDisEditApp(true);
        setDisAppHist(true);
    }

    function createData(
        name,
        calories,
        fat,
        carbs,
        protein,
    ) {
        return { name, calories, fat, carbs, protein };
    }

    const rows = [
        createData('Submitted', 159, 6.0, 24, 4.0),
        createData('Cancelled', 237, 9.0, 37, 4.3),
        createData('Under Review', 262, 16.0, 24, 6.0),
        createData('Rejected', 305, 3.7, 67, 4.3),
        createData('Request for Cancellation', 356, 16.0, 49, 3.9),
        createData('Review Passed', 356, 16.0, 49, 3.9),
        createData('Review Failed', 356, 16.0, 49, 3.9),
        createData('KYC/AML Initiated', 356, 16.0, 49, 3.9),
        createData('KYC/AML Passed', 356, 16.0, 49, 3.9),
        createData('KYC/AML Failed', 356, 16.0, 49, 3.9),
        createData('Submitted for Enrollment', 356, 16.0, 49, 3.9),
        createData('DocuSign Required', 356, 16.0, 49, 3.9),
        createData('DocuSign Completed', 356, 16.0, 49, 3.9),
        createData('Account Activated', 356, 16.0, 49, 3.9),

    ];

    const handleEditAppClick = async (table) => {

        setMoreMnuAnchorEl(null);

        if (table.getSelectedRowModel().rows.length > 0) {
            let enrmntId = table.getSelectedRowModel().rows[0].original.enrmntId;
            selectedEnrolId.current = enrmntId;
            var resposne = await getEnrollment(enrmntId);
            if (resposne && resposne.length > 0) {
                templateId.current = resposne[0]["templateId"];
                enrolNm.current = resposne[0]["name"];
                templateNm.current = resposne[0]["templateNm"];
                setEnrolModalShow(true);
            }
        }
    }

    const unlockAppClick = async (table) => {
        setOptMnuAnchorEl(null);

        if (table.getSelectedRowModel().rows.length > 0) {
            try {

                setIsDataLoading(true);
                let row = table.getSelectedRowModel().rows[0];
                await WriteToActivityLog(ActivityLogEvent.ApplicationDashboard, "Unlocked application. Application name '" + enrolNm.current + "'", userId, sessionId);
                navigate('/Enrollment', { state: { templateid: parseInt(templateId.current), name: enrolNm.current, enrmntid: selectedEnrolId.current, ownerid: selOwnerId.current, templatenm: templateNm.current, userId: userId, sessionId: sessionId, appreadonly: false, AdminView: true, EnrollmentStatus: selEnrolStatus.current, unlockApp: true }, replace: true })
            }
            catch (err) {
                setErrorMsg(ErrorMessages.UnknownError);
            }
            finally {
                setIsDataLoading(false);
            }
        }
    }

    const handleAddAppClick = () => {
        setMoreMnuAnchorEl(null);
        selectedEnrolId.current = 0;
        templateId.current = -1;
        enrolNm.current = null;
        templateNm.current = null;
        setIsAppReadOnly(false);
        setEnrolModalShow(true);
    }

    const handleReviewAppClick = async (table) => {
        setMoreMnuAnchorEl(null);
        if (table.getSelectedRowModel().rows.length > 0) {

            let enrmntId = table.getSelectedRowModel().rows[0].original.enrmntId;
            await reviewApp(enrmntId);
        }
    }

    const handlePrintAppClick = async (table) => {

        setMoreMnuAnchorEl(null);
        if (table.getSelectedRowModel().rows.length > 0) {
            let enrmntId = table.getSelectedRowModel().rows[0].original.enrmntId;
            await printAppData(enrmntId);
        }
    }

    const handleViewDocClick = async (table) => {

        setMoreMnuAnchorEl(null);

        if (table.getSelectedRowModel().rows.length > 0) {
            templateNm.current = table.getSelectedRowModel().rows[0].original.templateNm;
            enrolNm.current = table.getSelectedRowModel().rows[0].original.name;
            setShowDocListDlg(true);
        }
    };
    const handleUploadDoc = async (table) => {

        setMoreMnuAnchorEl(null);

        if (table.getSelectedRowModel().rows.length > 0) {
            let enrmntId = table.getSelectedRowModel().rows[0].original.enrmntId;
            selectedEnrolId.current = enrmntId;
            templateNm.current = table.getSelectedRowModel().rows[0].original.templateNm;
            enrolNm.current = table.getSelectedRowModel().rows[0].original.name;
            setShowDocUpldDlg(true); 
        }
    }

    const handleDeleteAppClick = async (table) => {
        setMoreMnuAnchorEl(null);

        confirmDelDlgHdr.current = 'Delete application';
        confirmDelDlgBLine1.current = "Do you want to delete the application named as '" + enrolNm.current + "' that is created by '" + applicantsName + "' ?";
        setOpenDelConfirmDlg(true);
    }

    const handleAppHistClick = async (table) => {
        setMoreMnuAnchorEl(null);
        if (table.getSelectedRowModel().rows.length < 1)
            return;

        setShowAppHistDlg(true);
    }

    const loadLogo2Image = async () => {
        try {

            let apiImageUrl = new URL(
                process.env.REACT_APP_GET_BANK_RESOURCE,
                process.env.REACT_APP_BASE_URL,
            );

            apiImageUrl.searchParams.set('resourceType', 2);

            let response = await APICall({ url: apiImageUrl.href, method: 'GET', responseType: 'blob' }, userId, sessionId);

            if (response) {
                if (response.type == 'image/jpeg') {
                    setLogo2Url(response);
                }
                else {
                    setLogo2Url(null);
                }
            }
        }
        catch (err) {
            setErrorMsg(ErrorMessages.UnknownError);
        }
        finally {
        }
    }

    const handleDownloadAppClick = async (table) => {

        setMoreMnuAnchorEl(null);
        if (table.getSelectedRowModel().rows.length < 1)
            return;

        setShowExportToGWESDlg(true);
        setLoaderText('File download in progress..');

        let enrmntId = table.getSelectedRowModel().rows[0].original.enrmntId;

        let userInfo = {
            ApplicantsName: applicantsName,
            ApplicantEmail: applicantEmail,
            JointOwnerName: jointOwnerName,
            JointOwnerEmail: jointOwnerEmail,
            ApplicantsImg: imageUrl,
            AvtarImage: profimg,
            BankLogo: logo2Url
        }

        try {
            let errorMsg = await ApplicationAllDocumentDownload(enrmntId, userId, sessionId, userInfo);
            if (errorMsg !== '')
                setErrorMsg(errorMsg);

            await WriteToActivityLog(ActivityLogEvent.ApplicationDashboard, "Downloaded application. Application name '" + enrolNm.current + "'", userId, sessionId);
        }
        catch (err) {
            setErrorMsg(ErrorMessages.UnknownError);
            setShowExportToGWESDlg(false);
        }
        finally {
            setIsFormLoading(false);
            setShowExportToGWESDlg(false);
        }
    }

    const reviewApp = async (enrmntId) => {

        setMoreMnuAnchorEl(null);

        let response = await getEnrollment(enrmntId);

        try {
        if (response && response.length > 0) {
            setIsDataLoading(true);
            await WriteToActivityLog(ActivityLogEvent.ApplicationDashboard, "Reviewed application. Application name '" + response[0].name + "'", userId, sessionId);
            selectedEnrolmntValue.current = response[0].enrmntData;
            selectedEnrolId.current = enrmntId;
            selectedTemplateNm.current = enrmntId;
            setJointOwnerName(response[0].JointOwnerName);
            setJointOwnerEmail(response[0].JointOwnerEmail);
            setShowAppViewDlg(true);
            }
        }
        catch (err) {
            setErrorMsg(ErrorMessages.UnknownError);
        }
        finally {
            setIsDataLoading(false);
        }
    }

    const getEnrollment = async (enrmntId) => {

        try {

            setIsDataLoading(true);

            const apiUrl = new URL(
                process.env.REACT_APP_GET_ENROLLMENT_BY_ID,
                process.env.REACT_APP_BASE_URL,
            );
            apiUrl.searchParams.set('enrmntId', `${parseInt(enrmntId)}`);

            let response = await APICall({ url: apiUrl.href, method: 'GET', contentType: 'application/json', responseType: '' }, userId, sessionId);

            if (response !== null && response !== undefined) {
                setIsDataLoading(false);
                if (response["data"] && response["data"].length > 0) {
                    return response["data"]
                }
                else {

                    let apiErrors = response["Errors"];

                    if (apiErrors != undefined && apiErrors.length > 0) {
                        setErrorMsg(apiErrors[0].Message);
                    }
                    else {
                        setErrorMsg(ErrorMessages.UnknownError);
                    }
                }

            }
        }
        catch (err) {
            setErrorMsg(ErrorMessages.UnknownError);
            setIsDataLoading(false);
        }
        finally {
            
        }

        return null;
    }

    const handleAccordianChange = () => {
        if (expanded) {
            setExpanded(false);
        }
        else {
            setExpanded(true);
        }
    }

    const handleSortDtNewMenuClick = async (event) => {
        setSortMnuAnchorEl(null);
        sortMenuAction.current = ApplicationSortAction.Newest;
        await WriteToActivityLog(ActivityLogEvent.ApplicationDashboard, "Sorted application list by 'Date: Newest on top'", userId, sessionId);
        refetch();

        SetSortMenuTick(ApplicationSortAction.Newest);
    }

    const handleSortDtOldMenuClick = async (event) => {
        setSortMnuAnchorEl(null);
        sortMenuAction.current = ApplicationSortAction.Oldest;
        await WriteToActivityLog(ActivityLogEvent.ApplicationDashboard, "Sorted application list by 'Date: Oldest on top'", userId, sessionId);
        refetch();

        SetSortMenuTick(ApplicationSortAction.Oldest);
    }

    const handleSortOwnerMenuClick = async (event) => {
        setSortMnuAnchorEl(null);
        sortMenuAction.current = ApplicationSortAction.Owner;
        await WriteToActivityLog(ActivityLogEvent.ApplicationDashboard, "Sorted application list by 'Owner'", userId, sessionId);
        refetch();

        SetSortMenuTick(ApplicationSortAction.Owner);
    }

    const handleSortAcctTypeMenuClick = async (event) => {
        setSortMnuAnchorEl(null);
        sortMenuAction.current = ApplicationSortAction.AccountType;
        await WriteToActivityLog(ActivityLogEvent.ApplicationDashboard, "Sorted application list by 'Account type'", userId, sessionId);
        refetch();

        SetSortMenuTick(ApplicationSortAction.AccountType);
    }

    const handleSortApplNameMenuClick = async (event) => {
        setSortMnuAnchorEl(null);
        sortMenuAction.current = ApplicationSortAction.ApplName;
        await WriteToActivityLog(ActivityLogEvent.ApplicationDashboard, "Sorted application list by 'Application name'", userId, sessionId);
        refetch();

        SetSortMenuTick(ApplicationSortAction.ApplName);
    }

    const handleSortStatusMenuClick = async (event) => {
        setSortMnuAnchorEl(null);
        sortMenuAction.current = ApplicationSortAction.Status;
        await WriteToActivityLog(ActivityLogEvent.ApplicationDashboard, "Sorted application list by 'Status'", userId, sessionId);
        refetch();

        SetSortMenuTick(ApplicationSortAction.Status);
    }

    const DocuSignMailClick = (event) => {
        event.preventDefault();
        setShowDocuSignMailDlg(true);
    };

    const DocuSignStatusClick = (event) => {
        event.preventDefault();
        setShowDocuSignStatusDlg(true);
    };

    const DocuSignHistClick = (event) => {
        event.preventDefault();
        setShowDocuSignHistDlg(true);
    };

    function SetSortMenuTick(item) {
        tickSrtDtNewDisp.current = 'none';
        tickSrtDtOldDisp.current = 'none';
        tickSrtOwnerDisp.current = 'none';
        tickSrtAccTypeDisp.current = 'none';
        tickSrtApplNameDisp.current = 'none';
        tickSrtStatusDisp.current = 'none';

        if (item == ApplicationSortAction.Newest)
            tickSrtDtNewDisp.current = 'block';

        if (item == ApplicationSortAction.Oldest)
            tickSrtDtOldDisp.current = 'block';

        if (item == ApplicationSortAction.Owner)
            tickSrtOwnerDisp.current = 'block';

        if (item == ApplicationSortAction.AccountType)
            tickSrtAccTypeDisp.current = 'block';

        if (item == ApplicationSortAction.ApplName)
            tickSrtApplNameDisp.current = 'block';

        if (item == ApplicationSortAction.Status)
            tickSrtStatusDisp.current = 'block';
    }

    const onDSStatusOkClick = (updated) => {
        //setShowDocuSignStatusDlg(false);
        if (updated) {
            ResetRow();
        }
    }

    const FindClick = async (event) => {

        if (!ignoreDate) {
            if (fromDate == '') {
                setErrorMsg('Invalid from date');
                return;
            }

            if (toDate == '') {
                setErrorMsg('Invalid to date');
                return;
            }

            let date1 = fromDate !== '' ? moment(fromDate, 'YYYY-MM-DD') : null;
            let date2 = toDate !== '' ? moment(toDate, 'YYYY-MM-DD') : new Date().setHours(0, 0, 0, 0);

            if (date1 > date2) {
                setErrorMsg('The To Date must be greater than the From Date');
                return;
            }
        }

        setErrorMsg('');
        setRowSelection({ rowId: false }); //removing row selection 
        DisableApplOptCntrl();

        await WriteToActivityLog(ActivityLogEvent.ApplicationDashboard, "Searched application list.", userId, sessionId);

        refetch();
    };

    const handleSearchTextChange = (event) => {
        setSearchText(event.target.value);
    };

    const handleStartDateChange = (event) => {
        setFromDate(event.target.value);
    };

    const handleEndDateChange = (event) => {
        setToDate(event.target.value);
    };

    const handleIgnoreDateChange = (event) => {

        setDisFromDate(event.target.checked);
        setDisToDate(event.target.checked);
        setIgnoreDate(event.target.checked);

        //setChecked(event.target.checked);
    };

    return (

        <React.Fragment>
            <AutoLagoutTimer userId={userId} sessionId={sessionId} />
            <Container maxWidth="lg" sx={{ background: '#4C4C4C'}}>
                <OverlaySpinner disappear={isDataLoading} />
                <Paper>
                    <Grid item md={12} >
                        <PostLoginMenu userId={userId} sessionId={sessionId} />
                    </Grid>
                    <Grid item md={12} id="aobhead" >
                        <Box sx={{
                            pr: 0,
                            pl: 0, pt: 0
                        }}>
                            <EnrollmentHeader />
                        </Box>
                    </Grid>
                    <Grid item sx={{ pr: 1 }}>
                        <DrawerAppBar userId={userId} sessionId={sessionId} />
                    </Grid>
                    <Grid item xs container direction="row" spacing={0} sx={{ pt: 0}}>
                        <Grid item container xs={12} md={12}>
                            <Grid item xs={12}>
                                <Box sx={{ borderRight: '0px solid #A8B3B3'}}>
                                    <Box sx={{ display: 'flex', pb: 2, ml: 6.5, mr: 0, justifyContent: 'space-between', alignItems: 'flex-end', borderBottom: '0px solid #BBBFBF' }}>
                                        <Stack direction="row">
                                            <Typography variant="h6" color="text.primary" sx={{ pl: 0, pb: 0, lineHeight: 1, fontWeight: 500, pb: 0, letterSpacing: '0px' }} >Account Opening Applications</Typography>
                                        </Stack>
                                    </Box>
                                    <Box sx={{ ml: 0, mr: 0, }}>
                                        <Divider variant="fullWidth" component="div" />
                                    </Box>
                                    <Box sx={{ mt: 0, pt: 2, pb: 0, ml: 6.5, mr: 6.5, borderTop: '0px solid #A8B3B3', borderBottom: '0px solid #d2d2d2' }}>
                                    <Stack direction="column" sx={{width: '100'}}>
                                            <Typography sx={{ fontWeight: 400, }} variant="body1" color="text.primary">
                                            In this page, view and manage all the account opening applications from clients that is accessible to you.
                                            You can take different actions to these applications depending on the status of the application.
                                        </Typography>
                                            <Typography sx={{ pt: 2, mt: 0, fontWeight: 400 }} variant="body1" color="text.primary">
                                            You can download any application as a pdf file. You can view the photo of the applicant by clicking on an application.
                                        </Typography>
                                    </Stack>
                                    </Box>
                                </Box>
                            </Grid>
                        </Grid>
                        <Grid item xs={12}>
                            <Box sx={{ ml: 0, mr: 0, pt: 3.5, ml: 6.5, mr: 6.5 }}>
                                <Divider variant="fullWidth" component="div" sx={{ }} />
                            </Box>
                        </Grid>
                        <Grid item xs container direction="row" spacing={2} sx={{ pt: 0, pb: 0, }}>
                            <Grid item xs={5} sx={{ pt: 2.5, pb: 1 }}>
                                <Box sx={{ ml: 6.5, pt: 2.5 }}>
                                    <Stack direction="row" spacing={2}>
                                        <Box>
                                            <label htmlFor="DateFrom" className="form-label">From Date</label>
                                            <input type="date" id="DateFrom" style={{ width: '210px' }} disabled={disFromDate} name="DateFrom" placeholder="Enter start date here" className="form-control" onChange={handleStartDateChange} value={fromDate} />
                                        </Box>
                                        <Box>
                                            <label htmlFor="DateTo" className="form-label">To Date</label>
                                            <input type="date" id="DateTo" name="DateTo" style={{ width: '210px' }} disabled={disToDate} placeholder="Enter end date here" className="form-control" onChange={handleEndDateChange} value={toDate} />
                                        </Box>
                                    </Stack>
                                    <Box sx={{ mr: 0, mt: 2 }}>
                                        <input type="text" style={{ width: '480px' }} id="LastName" name="LastName" placeholder="Search owner, application name, applied for, status" onChange={handleSearchTextChange} value={searchText} className="form-control" />
                                    </Box>
                                </Box>
                            </Grid>
                            <Grid item xs={7} sx={{}}>
                                <Box sx={{ display: 'flex', pb: 1, pt: 4, ml: 0, pr: 0, justifyContent: 'center', alignItems: 'flex-start', borderBottom: '0px solid #d5dbdb' }}>
                                    <Box sx={{ border: 1, borderColor: 'divider', p: 1, pl: 3, pr: 3, pt: 2, pb: 3, borderRadius: '4px', }}>
                                        {
                                            userEnrolmlSts ?
                                                <Stack direction="column" spacing={1}>
                                                    <Box sx={{pb: 0}}>
                                                        <Typography variant="body2" color="text.primary" sx={{ pl: 0, fontWeight: 400, pb: 0, pr: 2, }} > All Applications Status</Typography>
                                                    </Box>
                                                    <Stack direction="row" spacing={0}>
                                                        {
                                                            userEnrolmlSts.map((row) => (
                                                                <Tooltip arrow title={row.enrlmntStatusTooltip} key={row.RowId}>
                                                                    <span>
                                                                        <Avatar variant="square" sx={{ bgcolor: row.enrlmntStatusBgColor, height: 17, width: 24, m: 0.1, opacity: 0.8, border: '1px solid #000' }}>
                                                                            <Typography className="" variant="caption" sx={{ fontSize: '10px', fontWeight: 700 }} color={row.enrlmntStatusTxtColor}>
                                                                                {
                                                                                    row.enrlmntCnt > 99 ? row.enrlmntCnt.toString().substring(0, 2) + '..' : row.enrlmntCnt
                                                                                }
                                                                            </Typography>
                                                                        </Avatar>
                                                                    </span>
                                                                </Tooltip>
                                                            ))
                                                        }
                                                    </Stack>
                                                </Stack>
                                                : ''
                                        }
                                    </Box>
                                </Box>
                            </Grid>
                            <Grid item xs={12} sx={{ ml: 6.5, pb: 0 }}>
                                <Stack direction="row" spacing={4}>
                                    <Box sx={{ pl: 0 }}>
                                        <Button type="submit" onClick={(e) => FindClick(e)} variant="contained" size="large" style={{ borderRadius: '20px', padding: '0.3rem 2.6rem', textTransform: 'none' }} >
                                            Find
                                        </Button>
                                    </Box>
                                    <Box sx={{  }}>
                                        <FormGroup sx={{ }}>
                                            <FormControlLabel control={<Checkbox sx={{
                                                [`&, &.${checkboxClasses.checked}`]: {
                                                    color: 'Check.main',
                                                },
                                            }} onChange={handleIgnoreDateChange} />} label={<Typography variant="body1" color="text.primary" >Ignore dates</Typography>} />
                                        </FormGroup>
                                    </Box>
                                </Stack>
                            </Grid>
                        </Grid>
                        <Grid item xs={12} md={12}>
                            <Box sx={{ ml: 6.5, mr: 6.5 }}>
                                <Paper elevation={0}>
                                    <AlertControl Message={errorMsg} severity="error" color="error" icon={true} />
                                </Paper>
                                <Paper elevation={0}>
                                    <AlertWithTitle Message={successMsg} variant="body3" severity="success" color="success" icon={true} Title={successMsgTitle} />
                                </Paper>
                            </Box>
                            <Box sx={{ ml: 0, mr: 0 }}>
                                <Divider variant="fullWidth" component="div" sx={{ ml: 6.5, mr: 6.5 }} />
                            </Box>
                            <Box sx={{ mt: 0, ml: 6.5, mr: 6.5, pt: 0}}>
                                <MaterialReactTable
                                    columns={columns}
                                    data={flatData}
                                    enableColumnActions={false}
                                    enableSorting={false}
                                    enableExpandAll={false}
                                    enablePagination={false}
                                    enableRowNumbers={false}
                                    enableRowVirtualization
                                    enableHiding={false}
                                    enableColumnOrdering={false}
                                    muiTableBodyRowProps={({ row }) => ({
                                        onClick: () => {
                                            setRowSelection((prev) => ({
                                                [row.id]: true,
                                            }));
                                            HandleRowClick(row);
                                        },
                                        sx: {
                                            cursor: 'pointer',
                                            fontWeight: row.getIsSelected() ? 'bold' : 'normal',
                                        },
                                    })}
                                    positionActionsColumn="last"
                                    enableColumnResizing={false}
                                    enableDensityToggle={false}
                                    enableFullScreenToggle={false}
                                    muiTableBodyCellProps={({ column }) => ({
                                        sx: (theme) => ({
                                            color: 'text.primary',
                                            fontSize: theme.typography.body1,
                                            borderBottom: 1,
                                            borderColor: 'divider',
                                            pl: 2, fontWeight: 400, textAlign: 'center', pt: 1, pb: 0.5,
                                        }),
                                    })
                                    }

                                    muiTableHeadCellProps={{
                                        sx: (theme) => ({
                                            color: 'text.secondary',
                                            fontSize: theme.typography.body2,
                                            borderTop: 1,
                                            borderBottom: 1,
                                            borderColor: 'divider',
                                            pl: 2, pr: 2,
                                            pt: 1, pb: 1,
                                        }),
                                    }}

                                    muiTableContainerProps={{
                                        ref: tableContainerRef,
                                        sx: { maxHeight: '550px', minHeight: '450px' },
                                        onScroll: (
                                            event,
                                        ) => fetchMoreOnBottomReached(event.target),
                                    }}
                                    muiToolbarAlertBannerProps={
                                        isError
                                            ? {
                                                color: 'error',
                                                children: ErrorMessages.UnknownError,
                                            }
                                            : undefined
                                    }
                                    onColumnFiltersChange={setColumnFilters}
                                    onGlobalFilterChange={setGlobalFilter}
                                    onSortingChange={setSorting}
                                    renderBottomToolbarCustomActions={() => (
                                        <Box sx={{ mt: 0.5, mb: 0 }}>
                                            <Typography variant="body2" color="text.blueText" sx={{ pl: 1, fontWeight: 400}}>
                                                {
                                                    !isError && data
                                                        ? totalFetched + ' of ' + totalDBRowCount + ' application(s)'
                                                        : ''
                                                }
                                            </Typography>
                                        </Box>
                                    )}
                                    onRowSelectionChange={setRowSelection}
                                    state={{
                                        columnFilters,
                                        globalFilter,
                                        isLoading,
                                        showAlertBanner: isError,
                                        showProgressBars: isFetching,
                                        showSkeletons: isFetching,
                                        rowSelection,
                                        sorting,
                                    }}
                                    initialState={{
                                    }}
                                    muiTablePaperProps={{
                                        elevation: 0,
                                    }}
                                    rowVirtualizerProps={{ overscan: 2 }}
                                    localization={{
                                        noRecordsToDisplay: 'No application to display',
                                        selectedCountOfRowCountRowsSelected: '',
                                    }}
                                    renderTopToolbarCustomActions={({ table }) => {
                                        return (
                                            <Box sx={{
                                                display: 'flex', pl: 0, pt: 2, pb: 0, pr: 5, flexDirection: 'row', 
                                            }}>
                                                {/*<TaskOutlinedIcon sx={{ fontSize: 32, color: '#61676B', ml: 0, pl: 0}} />*/}
                                                <Typography variant="body1" color="text.blueText" sx={{ pt: 0, pr: 1, fontWeight: 500, pl: 0.5, borderRadius: '4px',  }}>Existing applications</Typography>
                                                <Typography variant="body1" color="text.primary" sx={{ pt: 0, pr: 1, fontWeight: 400, pl: 1, borderRadius: '4px', ml: 2, }}>Sort</Typography>
                                                <IconButton sx={{ ml: 1, border: 0, borderColor: '#292929', p: 0, pr: 2,  }} onClick={handleSortMnuClick}>
                                                    <ExpandMoreIcon sx={{ fontSize: 24, color: 'Icon.main' }} />
                                                </IconButton>
                                                <Menu
                                                    id="basic-menu"
                                                    anchorEl={sortMnuAnchorEl}
                                                    open={openSort}
                                                    onClose={handleSortMnuClose}
                                                    MenuListProps={{
                                                        'aria-labelledby': 'basic-button',
                                                    }}
                                                >
                                                    <MenuItem key={0} sx={{mt: 1, ml: 1, mr: 1}} onClick={(event) => handleSortDtNewMenuClick(event)}>
                                                        <ListItemIcon>
                                                            <CheckIcon sx={{ display: tickSrtDtNewDisp.current, fontSize: 20, color: 'Icon.main' }} />
                                                        </ListItemIcon>
                                                        <Typography variant="body1" sx={{ textTransform: 'none', fontWeight: 400, mr: 1 }} color="text.primary">Date: Newest on top</Typography>
                                                    </MenuItem>
                                                    <MenuItem key={1} sx={{ ml: 1, mr: 1 }} onClick={(event) => handleSortDtOldMenuClick(event)}>
                                                        <ListItemIcon>
                                                            <CheckIcon sx={{ display: tickSrtDtOldDisp.current, fontSize: 24, color: 'Icon.main' }} />
                                                        </ListItemIcon>
                                                        <Typography variant="body1" sx={{ textTransform: 'none', fontWeight: 400 }} color="text.primary">Date: Oldest on top</Typography>
                                                    </MenuItem>
                                                    <Divider variant="fullWidth" component="div" sx={{}} />
                                                    <MenuItem key={2} sx={{ ml: 1, mr: 1 }} onClick={(event) => handleSortOwnerMenuClick(event)}>
                                                        <ListItemIcon>
                                                            <CheckIcon sx={{ display: tickSrtOwnerDisp.current, fontSize: 24, color: 'Icon.main' }}  />
                                                        </ListItemIcon>
                                                        <Typography variant="body1" sx={{ textTransform: 'none', fontWeight: 400 }} color="text.primary">Owner</Typography>
                                                    </MenuItem>
                                                    <MenuItem key={3} sx={{ ml: 1, mr: 1 }} onClick={(event) => handleSortAcctTypeMenuClick(event)}>
                                                        <ListItemIcon >
                                                            <CheckIcon sx={{ display: tickSrtAccTypeDisp.current, fontSize: 24, color: 'Icon.main' }} />
                                                        </ListItemIcon>
                                                        <Typography variant="body1" sx={{ textTransform: 'none', fontWeight: 400 }} color="text.primary">Account type</Typography>
                                                    </MenuItem>
                                                    <MenuItem key={4} sx={{ ml: 1, mr: 1 }} onClick={(event) => handleSortApplNameMenuClick(event)}>
                                                        <ListItemIcon>
                                                            <CheckIcon sx={{ display: tickSrtApplNameDisp.current, fontSize: 24, color: 'Icon.main' }} />
                                                        </ListItemIcon>
                                                        <Typography variant="body1" sx={{ textTransform: 'none', fontWeight: 400 }} color="text.primary">Application name</Typography>
                                                    </MenuItem>
                                                    <MenuItem sx={{ mb: 1, ml: 1, mr: 1}} key={5} onClick={(event) => handleSortStatusMenuClick(event)}>
                                                        <ListItemIcon>
                                                            <CheckIcon sx={{ display: tickSrtStatusDisp.current, fontSize: 24, color: 'Icon.main' }} />
                                                        </ListItemIcon>
                                                        <Typography variant="body1" sx={{ textTransform: 'none', fontWeight: 400 }} color="text.primary">Status</Typography>
                                                    </MenuItem>
                                                </Menu>

                                                <Typography variant="body1" color="text.primary" sx={{ pt: 0, pr: 1, fontWeight: 400, pl: 2, }}>Action</Typography>
                                                <IconButton sx={{ ml: 1, border: 0, borderColor: '#292929', padding: '0px', pt: 0 }} onClick={handleOptMenuClick}>
                                                    <ExpandMoreIcon sx={{ fontSize: 24, color: 'Icon.main' }} />
                                                </IconButton>
                                                <Menu
                                                    id="action-menu"
                                                    anchorEl={optMnuAnchorEl}
                                                    open={open}
                                                    onClose={handleOptMenuClose}
                                                >
                                                    <MenuItem sx={{ ml: 3, mt: 2, mb: 0, mr: 4}} key={0} disabled={disRevOpt} onClick={() => handleReviewClick(table)}>
                                                        <Typography sx={{}} variant="body1" sx={{ textTransform: 'none', fontWeight: 400 }} color="text.primary">Review</Typography>
                                                    </MenuItem>
                                                    <Divider variant="fullWidth" component="div" sx={{}} />
                                                    <MenuItem sx={{ ml: 3, mr: 4 }} key={3} disabled={disKYCAMLCheckOpt} onClick={() => handleKYCAMLClick(table)}>
                                                        <Typography sx={{}} variant="body1" sx={{ textTransform: 'none', fontWeight: 400 }} color="text.primary">Initiated KYC/AML</Typography>
                                                    </MenuItem>
                                                    <MenuItem sx={{ ml: 3, mr: 4 }} key={4} disabled={disKYCAMLPassOpt} onClick={() => handleKYCAMLPassClick(table)}>
                                                        <Typography sx={{}} variant="body1" sx={{ textTransform: 'none', fontWeight: 400 }} color="text.primary">KYC/AML passed</Typography>
                                                    </MenuItem>
                                                    <MenuItem sx={{ ml: 3, mr: 4 }} key={5} disabled={disKYCAMLFailOpt} onClick={() => handleKYCAMLFailClick(table)}>
                                                        <Typography sx={{}} variant="body1" sx={{ textTransform: 'none', fontWeight: 400 }} color="text.primary">KYC/AML failed</Typography>
                                                    </MenuItem>
                                                    <Divider variant="fullWidth" component="div" sx={{}} />
                                                    <MenuItem sx={{ ml: 3, mr: 4 }} key={8} disabled={disSubmitForEnrollOpt} onClick={() => handleSubmitForEnrollClick(table)}>
                                                        <Typography sx={{}} variant="body1" sx={{ textTransform: 'none', fontWeight: 400 }} color="text.primary">Submit for enrollment</Typography>
                                                    </MenuItem>
                                                    <Divider variant="fullWidth" component="div" sx={{}} />
                                                    <MenuItem sx={{ ml: 3, mr: 4 }} key={6} disabled={disDocuSignOpt} onClick={() => handleDocuSignReqClick(table)}>
                                                        <Typography sx={{}} variant="body1" sx={{ textTransform: 'none', fontWeight: 400 }} color="text.primary">DocuSign required</Typography>
                                                    </MenuItem>
                                                    <MenuItem sx={{ ml: 3, mr: 4 }} key={7} disabled={disDocuSignCompletedOpt} onClick={() => handleDocuSignCompletedClick(table)}>
                                                        <Typography sx={{}} variant="body1" sx={{ textTransform: 'none', fontWeight: 400 }} color="text.primary">DocuSign completed</Typography>
                                                    </MenuItem>
                                                    <Divider variant="fullWidth" component="div" sx={{}} />
                                                    <MenuItem sx={{ ml: 3, mr: 4 }} key={9} disabled={disAccountActivatedOpt} onClick={() => handleAccountActivateClick(table)}>
                                                        <Typography sx={{}} variant="body1" sx={{ textTransform: 'none', fontWeight: 400 }} color="text.primary">Account activated</Typography>
                                                    </MenuItem>
                                                    <Divider variant="fullWidth" component="div" sx={{}} />
                                                    <MenuItem sx={{ ml: 3, mr: 4 }} key={10} disabled={disCancelOpt} onClick={() => handleCancelClick(table)}>
                                                        <Typography sx={{}} variant="body1" sx={{ textTransform: 'none', fontWeight: 400 }} color="text.primary">Cancel</Typography>
                                                    </MenuItem>
                                                    <MenuItem sx={{ ml: 3, mr: 4, mb: 2 }} key={11} disabled={disRejectOpt} onClick={() => handleRejectClick(table)}>
                                                        <Typography sx={{}} variant="body1" sx={{ textTransform: 'none', fontWeight: 400 }} color="text.primary">Reject</Typography>
                                                    </MenuItem>
                                                </Menu>
                                            </Box>
                                        );
                                    }}
                                    renderToolbarInternalActions={({ table }) => {
                                        return (
                                            <Box sx={{ pr: 1, display: 'flex', pt: 1, pb: 0, flexDirection: 'row', }}>
                                                <Tooltip arrow title="More options">
                                                    <span>
                                                        <IconButton size="small" sx={{ border: 0, borderColor: '#d7d8d6', borderRadius: '5px', ml: 0, mr: 1, mb: 0 }} onClick={handleMoreMenuClick}>
                                                            <MoreVertIcon sx={{ fontSize: 26, color: 'Icon.main' }} />
                                                        </IconButton>
                                                    </span>
                                                </Tooltip>
                                                <Menu
                                                    id="more-menu"
                                                    anchorEl={moreMnuAnchorEl}
                                                    open={openMoreMenu}
                                                    onClose={handleMoreMenuClose}
                                                >
                                                    <MenuItem sx={{ ml: 3, mt: 2, mb: 0 }} key={0} disabled={disNewApp} onClick={() => handleAddAppClick()}>
                                                        <ListItemIcon>
                                                            <PostAddOutlinedIcon sx={{ fontSize: 24, color: 'Icon.main' }} />
                                                        </ListItemIcon>
                                                        <Typography variant="body1" sx={{ pl: 1.5, mr: 4, textTransform: 'none', fontWeight: 400 }} color="text.primary">New application</Typography>
                                                    </MenuItem>
                                                    <MenuItem sx={{ ml: 3, mt: 0.5, mb: 0 }} key={0} disabled={disEditApp} onClick={() => handleEditAppClick(table)}>
                                                        <ListItemIcon>
                                                            <NoteAltOutlinedIcon sx={{ fontSize: 24, color: 'Icon.main' }} />
                                                        </ListItemIcon>
                                                        <Typography variant="body1" sx={{ pl: 1.5, mr: 4, textTransform: 'none', fontWeight: 400 }} color="text.primary">Edit application</Typography>
                                                    </MenuItem>
                                                    <MenuItem sx={{ ml: 3, mt: 0.5, mb: 0 }} key={3} disabled={disUnlockApp} onClick={() => unlockAppClick(table)}>
                                                        <ListItemIcon>
                                                            <LockOpenIcon sx={{ fontSize: 24, color: 'Icon.main' }} />
                                                        </ListItemIcon>
                                                        <Typography variant="body1" sx={{ pl: 1.5, mr: 4, textTransform: 'none', fontWeight: 400 }} color="text.primary">Unlock application</Typography>
                                                    </MenuItem>
                                                    <Divider variant="fullWidth" component="div" sx={{}} />
                                                    <MenuItem sx={{ ml: 3, mt: 0.5 }} key={1} disabled={disViewApp} onClick={() => handleReviewAppClick(table)}>
                                                        <ListItemIcon>
                                                            <DescriptionOutlinedIcon sx={{ fontSize: 24, color: 'Icon.main' }} />
                                                        </ListItemIcon>
                                                        <Typography variant="body1" sx={{ pl: 1.5, mr: 4, textTransform: 'none', fontWeight: 400 }} color="text.primary">View application</Typography>
                                                    </MenuItem>
                                                    <MenuItem sx={{ ml: 3, mt: 0.5 }} key={2} disabled={disDownloadApp} onClick={() => handleDownloadAppClick(table)}>
                                                        <ListItemIcon>
                                                            <DownloadIcon sx={{ fontSize: 24, color: 'Icon.main' }} />
                                                        </ListItemIcon>
                                                        <Typography variant="body1" sx={{ pl: 1.5, mr: 4, textTransform: 'none', fontWeight: 400 }} color="text.primary">Download application</Typography>
                                                    </MenuItem>
                                                    <MenuItem sx={{ ml: 3, mt: 0.5 }} key={3} disabled={disAppHist} onClick={() => handleAppHistClick(table)}>
                                                        <ListItemIcon>
                                                            <HistoryToggleOffIcon sx={{ fontSize: 24, color: 'Icon.main' }} />
                                                        </ListItemIcon>
                                                        <Typography variant="body1" sx={{ pl: 1.5, mr: 4, textTransform: 'none', fontWeight: 400 }} color="text.primary">History</Typography>
                                                    </MenuItem>
                                                    <Divider variant="fullWidth" component="div" sx={{}} />
                                                    <MenuItem sx={{ ml: 3, mt: 0.5 }} key={3} disabled={disViewDoc} onClick={() => handleViewDocClick(table)}>
                                                        <ListItemIcon>
                                                            <FileCopyOutlinedIcon sx={{ fontSize: 24, color: 'Icon.main' }} />
                                                        </ListItemIcon>
                                                        <Typography variant="body1" sx={{ pl: 1.5, mr: 4, textTransform: 'none', fontWeight: 400 }} color="text.primary">View documents</Typography>
                                                    </MenuItem>
                                                    <MenuItem sx={{ ml: 3, mt: 0.5 }} key={3} disabled={disUploadDoc} onClick={() => handleUploadDoc(table)}>
                                                        <ListItemIcon>
                                                            <UploadIcon sx={{ fontSize: 24, color: 'Icon.main' }} />
                                                        </ListItemIcon>
                                                        <Typography variant="body1" sx={{ pl: 1.5, mr: 4, textTransform: 'none', fontWeight: 400 }} color="text.primary">Upload document</Typography>
                                                    </MenuItem>
                                                    <Divider variant="fullWidth" component="div" sx={{}} />
                                                    <MenuItem sx={{ ml: 3, mt: 0.5, mb: 2 }} key={4} disabled={disDeleteApp} onClick={() => handleDeleteAppClick(table)}>
                                                        <ListItemIcon>
                                                            <DeleteForeverIcon sx={{ fontSize: 24, color: 'Icon.main' }} />
                                                        </ListItemIcon>
                                                        <Typography variant="body1" sx={{ pl: 1.5, mr: 4, textTransform: 'none', fontWeight: 400 }} color="text.primary">Delete application</Typography>
                                                    </MenuItem>
                                                    {/*<Divider />*/}
                                                {/*    <MenuItem sx={{ ml: 2, mt: 1, mb: 1 }} key={5} disabled={true} onClick={() => handleKYCAMLFailClick(table)}>*/}
                                                {/*        <ListItemIcon>*/}
                                                {/*            <ImportExportIcon width="28px" height="24px" color="#333333" />*/}
                                                {/*        </ListItemIcon>*/}
                                                {/*        <Typography className="me-5" variant="body3" sx={{ textTransform: 'none', fontWeight: 400 }} color="text.primary">Export to file</Typography>*/}
                                                {/*    </MenuItem>*/}
                                                </Menu>
                                            </Box>
                                        );
                                    }}
                                />
                            </Box>
                        </Grid>
                    </Grid>
                    <Grid item xs container direction="row" spacing={0} sx={{ mt: 0.5, pt: 3, pb: 6,  pl: { xs: 1, md: 7 }, pr: { xs: 1, md: 7 }, borderTop: 1, borderColor: 'divider'}}>
                        <Grid item xs={12} md={4} sx={{ borderTop: 1, borderLeft: 1, borderBottom: 1, borderColor: 'divider'}}>
                            <Box sx={{ borderLeft: '0px solid rgba(0,0,0,0.1)', pl: 3, pt: 2, mt: 0, }}>
                                <Typography variant="body1" color="text.primary" sx={{ pl: 0, fontWeight: 400, pb: 0 }}>
                                    {applicantsName}
                                </Typography>
                                <Stack direction="row" sx={{pt: 1, pb: 1}}>
                                    <Card elevation={0} sx={{ background: '#e7e9ed', mt: 0, border: 1, borderColor: 'divider', p: 0, borderRadius: 0 }}>
                                        {imageUrl ?
                                            <CardMedia sx={{ border: 1, borderColor: 'divider', maxWidth: 180 }}>
                                                <img src={URL.createObjectURL(imageUrl)} alt="Prifile Picture" />
                                            </CardMedia>
                                            :
                                            <CardMedia sx={{ border: 1, borderColor: 'divider', maxWidth: 180 }}>
                                                <img src={profimg} alt="Prifile Picture" />
                                            </CardMedia>
                                        }
                                    </Card>
                                    <Stack direction="column" spacing={0} sx={{pl: 1.5, pt: 2}}>
                                        <Typography variant="body1" color="text.primary" sx={{ fontWeight: 400, pb: 0.5, }}>
                                            Age
                                        </Typography>
                                        <Typography variant="caption" color="text.primary" sx={{ fontWeight: 500, pb: 1 }}>
                                            {ownerAge}
                                        </Typography>
                                        <Typography variant="body1" color="text.primary" sx={{ fontWeight: 400, pb: 0.5, }}>
                                            Phone
                                        </Typography>
                                        <Typography variant="caption" color="text.primary" sx={{ fontWeight: 500, pb: 1 }}>
                                            {ownerMobileNbr}
                                        </Typography>
                                    </Stack>
                                </Stack>
                                <Typography variant="body1" color="text.primary" sx={{ pl: 0, fontWeight: 400, pb: 0 }}>
                                    {applicantEmail}
                                </Typography>
                            </Box>
                        </Grid>
                        <Grid item xs={12} md={8} sx={{ pb: 4, borderTop: 1, borderRight: 1, borderBottom: 1, borderColor: 'divider', }}>
                            <Box sx={{ pl: 1, pb: 5, mt: 2.5, ml: 0, mr: 3, border: 1, borderColor: 'divider' }}>
                                <Grid item xs container direction="row">
                                    <Grid item xs={12} md={4} sx={{ mt: 2, }}>
                                        <Box sx={{ pl: 2}}>
                                            {submittedDate != null && submittedDate != undefined && submittedDate != '' ?
                                                <Box sx={{ pt: 0, borderTop: '0px solid #757575' }}>
                                                    <Stack direction="row" spacing={1} alignItems="center">
                                                        {/*<ArrowRightAltIcon sx={{ color: '#282c47' }} />*/}
                                                        <Typography variant="body1" color="text.primary" sx={{ fontWeight: 500 }}>
                                                            Submitted On
                                                        </Typography>
                                                    </Stack>
                                                </Box>
                                                : null
                                            }
                                            <Typography variant="caption" sx={{fontWeight: 400, pt: 1, fontSize: '11px'}} color="text.primary">
                                                {submittedDate}
                                            </Typography>
                                            <Table aria-label="simple table" sx={{ border: '0px solid #e0e0e0',  }} size="small">
                                                <TableBody>
                                                    <TableRow sx={{}}>
                                                        <TableCell component="th" scope="row" sx={{ maxWidth: 80, pt: 3, pb: 1, pl: 0, borderBottom: 1, borderColor: 'divider' }}>
                                                            <Stack direction="row" spacing={0} alignItems="center">
                                                                {/*<ArrowRightIcon sx={{ color: '#0960bb', fontSize: 24 }} />*/}
                                                                <Typography className="" variant="body4" color="text.primary" sx={{ fontWeight: 400 }}>
                                                                    Attachments
                                                                </Typography>
                                                            </Stack>
                                                        </TableCell>
                                                        <TableCell align="right" color="text.primary" sx={{ pt: 3, borderBottom: 1, borderColor: 'divider' }}>
                                                            <Typography className="" variant="body4" color="text.primary" sx={{ fontWeight: 400 }}>
                                                                    {attachmentCount}
                                                                </Typography>
                                                        </TableCell>
                                                    </TableRow>
                                                    <TableRow sx={{}}>
                                                        <TableCell component="th" scope="row" sx={{ maxWidth: 80, pt: 1, pb: 1, pl: 0, borderBottom: 1, borderColor: 'divider' }}>
                                                            <Stack direction="row" spacing={0} alignItems="center">
                                                                {/*<ArrowRightIcon sx={{ color: '#0960bb', fontSize: 24 }} />*/}
                                                                <Typography className="" variant="body4" color="text.primary" sx={{ fontWeight: 400 }}>
                                                                    Requires DocuSign
                                                                </Typography>
                                                            </Stack>
                                                        </TableCell>
                                                        <TableCell align="right" color="text.primary" sx={{ borderBottom: 1, borderColor: 'divider' }}>
                                                            <Typography className="" variant="body4" color="text.primary" sx={{ fontWeight: 400 }}>
                                                                    {docuSignRequiresCount}
                                                                </Typography>
                                                        </TableCell>
                                                    </TableRow>
                                                    <TableRow sx={{}}>
                                                        <TableCell component="th" scope="row" sx={{ maxWidth: 80, pt: 1, pb: 1, pl: 0, borderBottom: 1, borderColor: 'divider' }}>
                                                            <Stack direction="row" spacing={0} alignItems="center">
                                                                {/*<ArrowRightIcon sx={{ color: '#0960bb', fontSize: 24 }} />*/}
                                                                <Typography className="" variant="body4" color="text.primary" sx={{ fontWeight: 400 }}>
                                                                    DocuSign completed
                                                                </Typography>
                                                            </Stack>
                                                        </TableCell>
                                                        <TableCell align="right" color="text.primary" sx={{ borderBottom: 1, borderColor: 'divider' }}>
                                                            <Typography className="" variant="body4" color="text.primary" sx={{ fontWeight: 400 }}>
                                                                {docuSignCompletedCount}
                                                            </Typography>
                                                        </TableCell>
                                                    </TableRow>
                                                    <TableRow sx={{}}>
                                                        <TableCell component="th" scope="row" sx={{ maxWidth: 80, pt: 1, pb: 1, pl: 0, borderBottom: 1, borderColor: 'divider' }}>
                                                            <Stack direction="row" spacing={0} alignItems="center">
                                                                {/*<ArrowRightIcon sx={{ color: '#0960bb', fontSize: 24 }} />*/}
                                                                <Typography className="" variant="body4" color="text.primary" sx={{ fontWeight: 400 }}>
                                                                    DocuSign declined
                                                                </Typography>
                                                            </Stack>
                                                        </TableCell>
                                                        <TableCell align="right" color="text.primary" sx={{ borderBottom: 1, borderColor: 'divider' }}>
                                                            <Typography className="" variant="body4" color="text.primary" sx={{ fontWeight: 400 }}>
                                                                    {docuSignDeclineCount}
                                                                </Typography>
                                                        </TableCell>
                                                    </TableRow>
                                                    <TableRow sx={{}}>
                                                        <TableCell component="th" scope="row" sx={{ maxWidth: 80, maxWidth: 80, pt: 1, pb: 1, pl: 0, borderBottom: 1, borderColor: 'divider' }}>
                                                            <Stack direction="row" spacing={0} alignItems="center">
                                                                <Typography className="" variant="body4" color="text.primary" sx={{ fontWeight: 400 }}>
                                                                    GWES enrollment id
                                                                </Typography>
                                                            </Stack>
                                                        </TableCell>
                                                        <TableCell align="right" color="text.primary" sx={{ borderBottom: 1, borderColor: 'divider' }}>
                                                            <Typography className="" variant="body4" color="text.primary" sx={{ fontWeight: 400 }}>
                                                                {GWESEnrollId}
                                                                </Typography>
                                                        </TableCell>
                                                    </TableRow>
                                                </TableBody>
                                            </Table>
                                            </Box>
                                    </Grid>
                                    <Grid item xs={12} md={8} sx={{ mt: 3, }}>
                                        <Box sx={{ pl: 4, mt: 0, ml: 0, mr: 2 }}>
                                            <Paper elevation={0}>
                                                <AlertWithTitle Message={DSSuccessMsg} variant="caption" severity="success" color="success" icon={true} Title={DSSuccessMsgTitle} />
                                            </Paper>
                                            <Stack direction="row" justifyContent="space-between" sx={{ pb: 1 }}>
                                                <Link variant="body1" href="#" sx={{ display: disDSignHist, color: 'Link.main' }} onClick={(e) => DocuSignStatusClick(e)}>DocuSign Status</Link>
                                                <Link variant="body1" href="#" sx={{ display: disDSignHist, color: 'Link.main' }} onClick={(e) => DocuSignMailClick(e)}>Resend DocuSign Mail</Link>
                                            </Stack>
                                            <Link variant="body1" href="#" sx={{ display: disDSignHist, color: 'Link.main', mt: 1, mb: 2, fontSize: '16px', fontWeight: 300, }} onClick={(e) => DocuSignHistClick(e)}>DocuSign History</Link>
                                            {
                                                docuSignEmailSts ?
                                                    <React.Fragment>
                                                        <TableContainer sx={{ border: 1, borderColor: 'divider', maxHeight: 300 }}>
                                                        <Table aria-label="simple table" sx={{ cellSpacing: 0, cellPadding: 0, pb: 2, pt: 3, pl: 1,  Overflow: 'scroll' }} size="small">
                                                            <TableBody>{ }
                                                                {
                                                                    docuSignEmailSts.map((item) => (
                                                                        <React.Fragment>
                                                                            <TableRow sx={{}} key={1}>
                                                                                <TableCell colSpan="2" scope="row" sx={{ pl: 1.5, pt: 1 }}>
                                                                                    <Typography variant="body1" color="text.primary" sx={{ fontWeight: 700 }}>
                                                                                        {item.FileName}
                                                                                    </Typography>
                                                                                </TableCell>
                                                                            </TableRow>
                                                                            <TableRow sx={{}} key={2}>
                                                                                <TableCell scope="row" sx={{ minWidth: 80, pl: 1.5 }}>
                                                                                    <Typography variant="caption" color="text.primary" sx={{ fontWeight: 500 }}>
                                                                                        Signer1:
                                                                                    </Typography>
                                                                                </TableCell>
                                                                                <TableCell scope="row" sx={{ pl: 1 }}>
                                                                                    <Typography variant="caption" color="text.primary" sx={{ fontWeight: 400 }}>
                                                                                        {item.Signer1Email}
                                                                                    </Typography>
                                                                                </TableCell>
                                                                            </TableRow>
                                                                            <TableRow sx={{}} key={2}>
                                                                                <TableCell scope="row" sx={{ minWidth: 80, pl: 1.5 }}>
                                                                                    <Typography variant="caption" color="text.primary" sx={{ fontWeight: 500 }}>
                                                                                        DocuSign Email:
                                                                                    </Typography>
                                                                                </TableCell>
                                                                                <TableCell scope="row" sx={{ pl: 1 }}>
                                                                                    {
                                                                                        item.Signer1EmailSentAt === '' ?
                                                                                        <Typography className="" variant="caption" color="#f00" sx={{ fontWeight: 500 }}>
                                                                                            not sent
                                                                                        </Typography>
                                                                                        :
                                                                                            <Typography className="" variant="caption" color="#4caf50" sx={{ fontWeight: 500 }}>
                                                                                                Last sent at {item.Signer1EmailSentAt}
                                                                                        </Typography>
                                                                                    }
                                                                                </TableCell>
                                                                            </TableRow>
                                                                            {
                                                                                item.Signer2Email !== '' ?
                                                                                    <React.Fragment>
                                                                                        <TableRow sx={{}} key={2}>
                                                                                            <TableCell scope="row" sx={{ minWidth: 80, pl: 1.5 }}>
                                                                                                <Typography variant="caption" color="text.primary" sx={{ fontWeight: 500 }}>
                                                                                                    Signer2:
                                                                                                </Typography>
                                                                                            </TableCell>
                                                                                            <TableCell scope="row" sx={{ pl: 1 }}>
                                                                                                <Typography variant="caption" color="text.primary" sx={{ fontWeight: 400 }}>
                                                                                                    {item.Signer2Email}
                                                                                                </Typography>
                                                                                            </TableCell>
                                                                                        </TableRow>
                                                                                        <TableRow sx={{}} key={2}>
                                                                                            <TableCell scope="row" sx={{ minWidth: 80, pl: 1.5 }}>
                                                                                                <Typography variant="caption" color="text.primary" sx={{ fontWeight: 500 }}>
                                                                                                    DocuSign Email:
                                                                                                </Typography>
                                                                                            </TableCell>
                                                                                            <TableCell scope="row" sx={{ pl: 1 }}>
                                                                                                {
                                                                                                    item.Signer2EmailSentAt === '' ?
                                                                                                        <Typography className="" variant="caption" color="#f00" sx={{ fontWeight: 500 }}>
                                                                                                            not sent
                                                                                                        </Typography>
                                                                                                        :
                                                                                                        <Typography className="" variant="caption" color="#4caf50" sx={{ fontWeight: 500 }}>
                                                                                                            Last sent at item.Signer2EmailSentAt
                                                                                                        </Typography>
                                                                                                }

                                                                                            </TableCell>
                                                                                        </TableRow>
                                                                                    </React.Fragment>
                                                                                    : null
                                                                            }
                                                                        </React.Fragment>
                                                                    ))
                                                                }
                                                            </TableBody>
                                                            </Table>
                                                    </TableContainer>
                                                        <Box sx={{ background: '#fff3cd', mt: 2, pb: 1, pl: 1 }}>
                                                            <Typography variant="caption" color="#696969" sx={{ pl: 0, pb: 0, lineHeight: 1, fontWeight: 500, pb: 0, letterSpacing: '0px' }} >Click on 'DocuSign Status' link to see current DocuSign status.</Typography>
                                                        </Box>
                                                    </React.Fragment>
                                                : ''
                                            }
                                        </Box>
                                    </Grid>
                                </Grid>
                            </Box>
                        </Grid>
                    </Grid>
                    <Footer userId={userId} />
                </Paper>
                {
                    enrolModalShow ?
                        <AddEditEnrollment
                            key={selectedEnrolId.current}
                            show={enrolModalShow}
                            backdrop="static"
                            keyboard={false}
                            onHide={() => setEnrolModalShow(false)}
                            title={selectedEnrolId.current > 0 ? 'Edit your saved application' : 'Application for opening an account'}
                            templateid={templateId.current}
                            enrmntid={selectedEnrolId.current}
                            name={enrolNm.current}
                            templatenm={templateNm.current}
                            userId={userId}
                            sessionId={sessionId}
                            appreadonly={isAppReadOnly}
                            AdminView={true}
                        />
                        : <div />
                }
                <ConfirmDialog
                    keepMounted
                    open={openDelConfirmDlg}
                    onClose={handleConfirmDelDialogClose}
                    alertheader={confirmDelDlgHdr.current}
                    bmsgline1={confirmDelDlgBLine1.current}
                    nvalue={selectedEnrolId.current}
                    svalue={selectedEnrolId.current}
                />
                <AlertDialog
                    id="ringtone-menu"
                    keepMounted
                    open={openConfirmDlg}
                    onClose={handleConfirmDialogClose}
                    enrolmentid={newEnrolStatus.current}
                    nvalue={newEnrolStatus.current}
                    alertheader={confirmDlgHdr.current}
                    bmsgline1={confirmDlgBLine1.current}
                    bmsgline2={confirmDlgBLine2.current}
                    bmsgline3={confirmDlgBLine3.current}
                    bmsgline4={confirmDlgBLine4.current}
                />
                <FormDialog
                    id="ringtone-from-menu"
                    keepMounted
                    open={openFormDlg}
                    onClose={handleFormDialogClose}
                    enrolmentid={selectedEnrolId.current}
                    nvalue={newEnrolStatus.current}
                    alertheader={confirmDlgHdr.current}
                    bmsgline1={confirmDlgBLine1.current}
                    bmsgline2={confirmDlgBLine2.current}
                    bmsgline3={confirmDlgBLine3.current}
                    bmsgline4={confirmDlgBLine4.current}
                    bmsgline5={confirmDlgBLine5.current}
                />
                {showAppHistDlg ?
                    <AppHistoryDlg open={showAppHistDlg} onClose={closeAppHistDlg} enrmntid={selectedEnrolId.current} enrmntNm={enrolNm.current} userId={userId} sessionId={sessionId} />
                    : null
                }
                {showAppViewDlg ?
                    <AppViewDlg open={showAppViewDlg} onClose={closeAppViewDlg} enrollData={selectedEnrolmntValue.current} enrmntid={selectedEnrolId.current} acctType={selectedTemplateNm.current} applicantImage={imageUrl} avtarImage={profimg} applicantName={applicantsName} applicantEmail={applicantEmail} applicantMobile={ownerMobileNbr} JointOwnerName={jointOwnerName} JointOwnerEmail={jointOwnerEmail} userId={userId} sessionId={sessionId} />
                    : null
                }
                {showDocUpldDlg ?
                    <DocUpldDlg open={showDocUpldDlg} onClose={closeDocUpldDlg} onChange={onFileChangeHandle} enrmntid={selectedEnrolId.current} ownerName={applicantsName} enrolNm={enrolNm.current} templateNm={templateNm.current} userId={userId} sessionId={sessionId} />
                    : null
                }
                {showDocListDlg ?
                    <DocListDlg open={showDocListDlg} onClose={closeDocListDlg} enrmntid={selectedEnrolId.current} ownerName={applicantsName} enrolNm={enrolNm.current} templateNm={templateNm.current} userId={userId} sessionId={sessionId} />
                    : null
                }
                {
                    showExportToGWESDlg ?
                        <ExportToGWESLoaderDlg id="exportTofWES" open={showExportToGWESDlg} onClose={handleExportToGWESDlgClose} loaderText={loaderText} keepMounted /> : null

                }
                {
                    showDocuSignDlg ?
                        <DocuSignDlg open={true} onClose={closeDocuSignDlg} onChange={onOkDocuSignDlg} userId={userId} sessionId={sessionId} enrmntid={selectedEnrolId.current} bmsgline1={confirmDlgBLine1.current} enrmntNm={enrolNm.current} />
                        : null
                }
                {
                    showDocuSignMailDlg ?
                        <DocuSignMailDlg open={true} onResend={DocuSignResendMailDlg} onClose={closeDocuSignMailDlg} userId={userId} sessionId={sessionId} enrmntid={selectedEnrolId.current} enrmntNm={enrolNm.current} />
                        : null
                }
                {
                    showDocuSignStatusDlg ?
                        <DocuSignStatusDlg open={true} onOkClick={onDSStatusOkClick} onClose={closeDocuSignStatusDlg} userId={userId} sessionId={sessionId} enrmntid={selectedEnrolId.current} enrmntNm={enrolNm.current} />
                        : null
                }
                {
                    showDocuSignHistDlg ?
                        <DocuSignHistDlg open={true} onClose={closeDocuSignHistDlg} userId={userId} sessionId={sessionId} enrmntid={selectedEnrolId.current} enrmntNm={enrolNm.current} />
                        : null
                }
            </Container>
        </React.Fragment>
    );
};

const queryClient = new QueryClient();

const ApplicationDashboard = () => (
    <QueryClientProvider client={queryClient}>
        <UserEnrollments />
    </QueryClientProvider>
);

function ApplicationDashboardBreadcrumb() {
    return (
        <React.Fragment >
            <Box sx={{ borderBottom: '0px solid #cdcfd2', pt: 4, pb: 4, pl: 0,  }}>
            {/*    <Typography variant="h4" color="#2E1A47" sx={{ lineHeight: 1, fontWeight: 300 }} >Applications from prospects</Typography>*/}
            {/*    <Typography sx={{ mb: 0, fontWeight: 400, pt: 1 }} variant="body2" color="text.secondary">*/}
            {/*        In this page, view and manage all the applications from clients that is accessible by you.*/}
            {/*        You can take different actions to these applications depending on the status of the application.*/}
            {/*    </Typography>*/}
            {/*    <Typography sx={{ mb: 0, mt: 2, fontWeight: 400 }} variant="body2" color="text.secondary">*/}
            {/*        You can ownload any application as a pdf file. You can view the photo of the applicant by clicking on an application.*/}
            {/*    </Typography>*/}

            </Box>
        </React.Fragment>
    );
}
export default ApplicationDashboard;