import React, { useEffect, useState, useRef, useMemo } from 'react';
import { Link, Stack, Container, Dialog, TableContainer, Divider, DialogTitle, DialogContent, Box, DialogActions, Button, Typography, IconButton, Grid, Toolbar, Tooltip } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import AppBar from '@mui/material/AppBar';
import { useSelector, useDispatch } from 'react-redux';
import AlertControl from '../components/Controls/AlertControl';
import MaterialReactTable, { MRT_ShowHideColumnsButton, MRT_ToggleGlobalFilterButton, MRT_ToggleFiltersButton, } from 'material-react-table';
import { CallAPI, IsLoggedInUserProspectUser } from '../global/APIUtils';
import DownloadIcon from '@mui/icons-material/Download';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import { GetAttachedFile } from '../global/EnrollmentHelper';
import LoadingOverlay from 'react-loading-overlay';
import RiseLoader from 'react-spinners/RiseLoader'
import { ErrorMessages } from '../common/ErrorMessages';
import { APICall, WriteToActivityLog } from '../global/Api';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import DocuSignStatusDlg from './DocuSignStatus';
import DocuSignHistDlg from '../dialogs/DocuSignHist';
import DocuSignMailDlg from '../dialogs/DocuSignMail';
import EventRepeatIcon from '@mui/icons-material/EventRepeat';
import { ActivityLogEvent } from '../common/AOBEnum';

const DocListDlg = (props) => {

    const { onClose, open, enrmntid, ownerName, enrolNm, templateNm } = props;
    const [isLoading, setIsLoading] = useState(false);

    return (
        <Dialog
            fullScreen
            open={open}
            onClose={onClose}
            scroll="paper"
        >
            {/*<LoadingOverlay active={isLoading} spinner={<RiseLoader color={'#1D50AF'} />} >*/}
            <AppBar sx={{ position: 'relative' }}>
                <Toolbar>
                    <IconButton
                        edge="start"
                        color="inherit"
                        onClick={onClose}
                        aria-label="close"
                    >
                        <CloseIcon />
                    </IconButton>
                    <Typography sx={{ ml: 2, flex: 1, fontWeight: 300 }} variant="h6" component="div">
                        Uploaded documents
                    </Typography>
                    <Typography sx={{fontWeight: 300 }} variant="h6" component="div">
                        Applicant : {ownerName}
                    </Typography>
                </Toolbar>
            </AppBar>
            <DocListControl enrmntid={enrmntid} acctType={props.acctType} enrolNm={enrolNm} templateNm={templateNm} userId={props.userId} sessionId={props.sessionId} />
            {/*</LoadingOverlay>*/}
       </Dialog>
    );
};

const DocListControl = (props) => {

    const [enrmntid, setEnrmntid] = useState(props.enrmntid || -1);
    const [enrolNm, setEnrolNm] = useState(props.enrolNm || -1);
    const [errorMsg, setErrorMsg] = useState(null);
    const [isDataLoading, setIsDataLoading] = useState(false);
    const [data, setData] = useState([]);
    const [successMsg, setSuccessMsg] = useState(null);
    const isProspectUser = useRef(false);
    const [docuSignEmailSts, setDocuSignEmailSts] = useState(null);
    const [rowSelection, setRowSelection] = useState({});
    const [showDocuSignStatusDlg, setShowDocuSignStatusDlg] = useState(false);

    const [disResendMail, setDisResendMail] = useState(true);
    const [disDSStatus, setDisDSStatus] = useState(true);
    const [disDSHist, setDisDSHist] = useState(true);
    const [disRefreshDS, setDisRefreshDS] = useState(true);

    const [userId, setUserId] = useState(props.userId);
    const [sessionId, setSessionId] = useState(props.sessionId);
    const [showDocuSignHistDlg, setShowDocuSignHistDlg] = useState(false);
    const [showDocuSignMailDlg, setShowDocuSignMailDlg] = useState(false);

    const selAttachmentId = useRef(-1);

    const closeDocuSignStatusDlg = () => {
        setShowDocuSignStatusDlg(false);
    }

    const closeDocuSignMailDlg = () => {
        setShowDocuSignMailDlg(false);
    }

    const closeDocuSignHistDlg = () => {
        setShowDocuSignHistDlg(false);
    }

    const loadAttachments = async () => {

        try {

            setIsDataLoading(true);

            const apiUrl = new URL(
                process.env.REACT_APP_GET_ATTACHMENTS,
                process.env.REACT_APP_BASE_URL,
            );

            apiUrl.searchParams.set('enrmntId', `${parseInt(enrmntid)}`);

            let response = await APICall({ url: apiUrl.href, method: 'GET', contentType: 'application/json', responseType: '' }, userId, sessionId);

            if (response !== null && response !== undefined) {
                await WriteToActivityLog(ActivityLogEvent.ApplicationDashboard, "Viewed application's document list. Application name '" + enrolNm + "'", userId, sessionId);
                setIsDataLoading(false);
                setData(response.data[0]);
            }
        }
        catch (err) {
            setErrorMsg(ErrorMessages.UnknownError);
            setIsDataLoading(false);
        }
        finally {
            
        }
    }

    const downloadAttachment = async (attachmentId, fileNm) => {


        try {

            setIsDataLoading(true);

            const apiUrl = new URL(
                process.env.REACT_APP_DOWNLOAD_ENROLLMENT_DOCUMENT,
                process.env.REACT_APP_BASE_URL,
            );
            apiUrl.searchParams.set('enrmntId', `${parseInt(enrmntid)}`);
            apiUrl.searchParams.set('attachmentId', `${parseInt(attachmentId)}`);
            apiUrl.searchParams.set('fname', fileNm);
            apiUrl.searchParams.set('tag', 'None');

            await GetAttachedFile(apiUrl.href, fileNm, userId, sessionId);

            await WriteToActivityLog(ActivityLogEvent.ApplicationDashboard, "Downloaded file attachment from application. Application name: '" + enrolNm + "'. File name: '" + fileNm + "'", userId, sessionId);
        }
        catch (ex) {
            setErrorMsg(ErrorMessages.UnknownError);
        }
        finally {
            setIsDataLoading(false);
        }
    }

    const downloadDocusignFile = async (e, attachmentId, fileNane) => {

        try {

            let inputJson = {
                EnrmntId: enrmntid,
                AttchmntId: attachmentId
            };

            setIsDataLoading(true);

            let apiUrl = new URL(
                process.env.REACT_APP_DOWNLOAD_DOCUSIGN_FILE,
                process.env.REACT_APP_BASE_URL,
            );


            let response = await APICall({ url: apiUrl.href, method: 'POST', payload: inputJson, contentType: 'application/json', responseType: '' }, userId, sessionId);

            if (response !== null && response !== undefined) {

                if (response.Errors.length > 0) {
                    setErrorMsg(response.Errors[0].Message);
                }
                else if (response.meta.DocumentSaved === "1") {
                    await WriteToActivityLog(ActivityLogEvent.ApplicationDashboard, "Downloaded DocuSigned file from application. Application name: '" + enrolNm + "'. File name: '" + fileNane + "'", userId, sessionId);
                    await loadAttachments();
                    setSuccessMsg('Signed file named as ' + fileNane + ' downloaded successfully.');
                }
            }
        }
        catch (err) {
            setErrorMsg(ErrorMessages.UnknownError);
        }
        finally {
            setIsDataLoading(false);
        }
    }

    const downloadSignedAttachment = async (attachmentId, fileNm) => {

        try {

            setIsDataLoading(true);

            const apiUrl = new URL(
                process.env.REACT_APP_DOWNLOAD_SIGNED_DOCUMENT,
                process.env.REACT_APP_BASE_URL,
            );
            apiUrl.searchParams.set('enrmntId', `${parseInt(enrmntid)}`);
            apiUrl.searchParams.set('attachmentId', `${parseInt(attachmentId)}`);
            apiUrl.searchParams.set('fname', fileNm);
            apiUrl.searchParams.set('tag', 'None');

            await GetAttachedFile(apiUrl.href, fileNm, userId, sessionId);
            await WriteToActivityLog(ActivityLogEvent.ApplicationDashboard, "Downloaded signed file attachment from application. Application name: '" + enrolNm + "'. File name: '" + fileNm + "'", userId, sessionId);
        }
        catch (ex) {
            setErrorMsg(ErrorMessages.UnknownError);
        }
        finally {
            setIsDataLoading(false);
        }
    }

    const deleteAttachment = async (enrmntId, attachmentId, fileNm) => {

        const inputJson = {
            EnrmntId: enrmntId,
            AttchmntId: attachmentId
        };

        const apiUrl = new URL(
            process.env.REACT_APP_DELETE_ENROLLMENT_DOC,
            process.env.REACT_APP_BASE_URL,
        );
        try {

            setIsDataLoading(true);

            let response = await APICall({ url: apiUrl.href, method: 'POST', payload: inputJson, contentType: 'application/json', responseType: '' }, userId, sessionId);

            if (response !== null && response !== undefined) {
                await WriteToActivityLog(ActivityLogEvent.ApplicationDashboard, "Deleted attachment from application. Application name: '" + enrolNm + "'. File name: '" + fileNm + "'", userId, sessionId);
                setSuccessMsg('File \'' + fileNm + '\' deleted successfully' );
                loadAttachments();
                setIsDataLoading(false);
            }
        }
        catch (err) {
            setErrorMsg(ErrorMessages.UnknownError);
            setIsDataLoading(false);
        }
        finally {
        }
    }

    const canDeleteAttachment = async (CntrlNm) => {

        if (CntrlNm === '') {
            if (isProspectUser.current) {
                return true;
            }
        }

        return false;
    }

    useEffect(() => {
        isProspectUser.current = IsLoggedInUserProspectUser();
        loadAttachments();
    }, []);

    const HandleRowClick = async (row) => {

        selAttachmentId.current = row.original.AttchmntId;

        setDisResendMail(true);
        setDisDSStatus(true);
        setDisDSHist(true);
        setDisRefreshDS(true);

        if (row.original.IsSignReq) {
            setDisDSStatus(false);
            setDisDSHist(false);
        }
        if (row.original.IsSignReq && row.original.SignStatus !== 'completed') {
            setDisRefreshDS(false);
            setDisResendMail(false);
        }
    }

    const columns = [
        {
            accessorKey: 'AttchmntId',
            header: '',
            Cell: ({ cell }) => (
                <IconButton title="Download" onClick={() => downloadAttachment(cell.row.original.AttchmntId, cell.row.original.FileName)}>
                    <DownloadIcon sx={{ fontSize: 24, color: 'Icon.main' }} />
                </IconButton>
            ),
            maxSize: 20,
            minSize: 20,
        },
        {
            accessorKey: 'CntrlNm',
            header: '',
            Cell: ({ cell }) => (
                <IconButton title="Delete" disabled={cell.getValue() == '' ? (cell.row.original.IsSignCompleted == true ? true : false) : true} onClick={() => deleteAttachment(enrmntid, cell.row.original.AttchmntId, cell.row.original.FileName)}>
                    <DeleteForeverIcon sx={{ fontSize: 24, color: 'Icon.main' }} />
                </IconButton>
            ),
            maxSize: 20,
            minSize: 20,
        },
        {
            accessorKey: 'FileName',
            header: 'File name',
            Cell: ({ cell, column }) => (
                <Box>
                    {cell.getValue() && cell.getValue().length > 25 ? cell.getValue().substring(0, 25) + '...' : cell.getValue()}
                </Box>
            ),
            size: 230
        },
        {
            accessorKey: 'SignRequire',
            header: 'Requires docusign',
            size: 200,
        },
        {
            accessorKey: 'SignStatus',
            header: 'Signing status',
            Cell: ({ cell }) => (
                <React.Fragment >
                    {/*<Typography color="#616161" sx={{}}>*/}
                    {cell.row.original.SignRequire == 'Yes' ? cell.getValue() : ''}
                    {/*</Typography>*/}
                {/*    <Typography color="#616161" sx={{}} variant="body2" component="body2">*/}
                {/*        {cell.row.original.DeclieReason}*/}
                {/*    </Typography>*/}
                </React.Fragment >
            ),
            size: 200,
        },
        {
            accessorKey: 'AttchmntGUID',
            header: 'Signed attachment',
            Cell: ({ cell }) => (
                cell.row.original.IsSignCompleted ? 
                <IconButton title="Download" onClick={() => downloadSignedAttachment(cell.row.original.AttchmntId, cell.row.original.FileName)}>
                    <DownloadIcon width="8px" height="8px" color="#0f69ff" />
                </IconButton>
                    :
                    cell.row.original.SignStatus == 'completed' ? <Link underline="hover" variant="body3" sx={{ mb: 0, fontWeight: 400, color: '#002d72' }} onClick={(e) => downloadDocusignFile(e, cell.row.original.AttchmntId, cell.row.original.FileName)}>Download signed attachment from DocuSign</Link>
                        : cell.row.original.SignStatus
            ),
            size: 300,
        },
        {
            accessorKey: 'DeclineReason',
            header: 'Decline reason',
            size: 230,
        },
        {
            accessorKey: 'CreatedBy',
            header: 'Uploaded by',
            Cell: ({ cell, column }) => (
                <Box>
                    {cell.getValue() && cell.getValue().length > 25 ? cell.getValue().substring(0, 25) + '...' : cell.getValue()}
                </Box>
            ),
            size: 230
        },
    ];

    const ResendMailClick = async (table) => {
    }

    const DSStatusClick = async (table) => {
        //event.preventDefault();
        setShowDocuSignStatusDlg(true);
    }

    const DSHistClick = async (table) => {
        //event.preventDefault();
        setShowDocuSignHistDlg(true);
    }

    const DSMailClick = (table) => {
        //event.preventDefault();
        setShowDocuSignMailDlg(true);
    };

    const DocuSignResendMailDlg = (msg) => {
        loadAttachments();
        setShowDocuSignMailDlg(false);
        setSuccessMsg(msg);
        //goToTop();
    }

    const onDSStatusOkClick = async (updated) => {
        await loadAttachments();
    }

    const RefreshDSStatus = async () => {

        try {

            setIsDataLoading(true);

            let apiUrl = new URL(
                process.env.REACT_APP_GET_DOCUSIGN_STATUS,
                process.env.REACT_APP_BASE_URL,
            );

            apiUrl.searchParams.set('enrmntId', `${parseInt(enrmntid)}`);
            apiUrl.searchParams.set('attachmentId', `${parseInt(selAttachmentId.current)}`);

            let response = await APICall({ url: apiUrl.href, method: 'GET', contentType: 'application/json', responseType: '' }, userId, sessionId);

            if (response !== null && response !== undefined) {

                if (response["data"] && response["data"].length > 0) {
                    if (response.data[0].SignStatus === 'completed') {
                        setDisResendMail(true);
                        setDisRefreshDS(true);
                    }

                    setSuccessMsg('DocuSign status refreshed');
                    await loadAttachments();
                }
                else {

                    let apiErrors = response["Errors"];

                    if (apiErrors != undefined && apiErrors.length > 0) {
                        setErrorMsg(apiErrors[0].Message);
                    }
                }
            }
        }
        catch (err) {
            setErrorMsg(ErrorMessages.UnknownError);
        }
        finally {
            setIsDataLoading(false);
        }
    };

    return (
        <React.Fragment >
            <Container maxWidth="lg" sx={{ pt: 5 }}>
                <Grid item>
                    <Box alignItems="center" sx={{ display: 'flex', justifyContent: 'space-between', }}>
                        <Typography color="text.primary" sx={{}} variant="body4" component="div">
                            Application name : &nbsp;{enrolNm}
                        </Typography>
                        <Typography color="text.primary" sx={{}} variant="body4" component="div">
                            Applied for : &nbsp;{props.templateNm}
                        </Typography>
                    </Box>
                </Grid>
                <Grid item>
                    <LoadingOverlay active={isDataLoading} spinner={<RiseLoader color={'#1D50AF'} />} >
                        <Box sx={{ pt: 0 }}>
                            <AlertControl Message={errorMsg} severity="error" color="error" icon={true} />
                            <AlertControl Message={successMsg} isSuccess="1" />
                        </Box>
                        <Box sx={{pt: 1}}>
                            <MaterialReactTable
                                columns={columns}
                                data={data}
                                enableRowSelection={false}
                                enableColumnActions={false}
                                enableColumnFilters={false}
                                enablePagination={false}
                                enableSorting={false}
                                enableBottomToolbar={false}
                                muiTableBodyRowProps={({ row }) => ({
                                    onClick: () => {
                                        setRowSelection((prev) => ({
                                            [row.id]: true,
                                        }));
                                        HandleRowClick(row);
                                    },
                                    sx: {
                                        cursor: 'pointer',
                                    },
                                })}
                                muiTableBodyCellProps={({ column }) => ({
                                    sx: (theme) => ({
                                        color: 'text.primary',
                                        fontSize: theme.typography.body1,
                                        borderBottom: '1px #BBBFBF solid',
                                        pl: 2, fontWeight: 400, textAlign: 'center', pt: 1, pb: 0.5,
                                    }),
                                })
                                }
                                onRowSelectionChange={setRowSelection}
                                state={{
                                    rowSelection,
                                }}
                                initialState={{
                                }}
                                localization={{
                                    noRecordsToDisplay: 'No application to display',
                                    selectedCountOfRowCountRowsSelected: '',
                                }}
                                muiTableHeadCellProps={{
                                    sx: (theme) => ({
                                        color: 'text.secondary',
                                        fontSize: theme.typography.body1,
                                        borderTop: '1px #BBBFBF solid',
                                        borderBottom: '1px #BBBFBF solid',
                                        pl: 2, pr: 2,
                                        pt: 1, pb: 1,
                                    }),
                                }}
                                renderTopToolbarCustomActions={({ table }) => {
                                    return (
                                        <Box sx={{
                                            display: 'flex', pl: 1, pt: 0, pb: 0,  
                                        }}>
                                            <Button variant="contained" onClick={() => DSMailClick(table)} disabled={disResendMail} sx={{ borderRadius: '20px', padding: '0.25rem 2rem', textTransform: 'none', mr: 2 }}>
                                                Resend DocuSign mail
                                            </Button>
                                            <Tooltip arrow title="Refresh DocuSign Status">
                                                <span>
                                                    <IconButton onClick={() => RefreshDSStatus()} size="small" disabled={disRefreshDS} sx={{ border: 0, borderColor: '#d7d8d6', borderRadius: '5px', ml: 0, mr: 1, mb: 0 }}>
                                                        <EventRepeatIcon sx={{ fontSize: 24, color: 'Icon.main' }} />
                                                    </IconButton>
                                                </span>
                                            </Tooltip>
                                        </Box>
                                    );
                                }}
                                renderToolbarInternalActions={({ table }) => {
                                    return (
                                        <Box sx={{ pr: 2 }}>
                                            <Button variant="contained" onClick={() => DSStatusClick(table)} disabled={disDSStatus} sx={{ borderRadius: '20px', padding: '0.25rem 2rem', textTransform: 'none', mr: 2 }}>DocuSign status</Button>
                                            <Button variant="contained" onClick={() => DSHistClick(table)} disabled={disDSHist} sx={{ borderRadius: '20px', padding: '0.25rem 2rem', textTransform: 'none', mr: 1 }}>DocuSign history</Button>
                                        </Box>
                                    );
                                }}
                            />
                        </Box>                        
                    </LoadingOverlay>
                </Grid>
            </Container>
            {
                showDocuSignStatusDlg ?
                    <DocuSignStatusDlg open={true} onOkClick={onDSStatusOkClick} onClose={closeDocuSignStatusDlg} userId={userId} sessionId={sessionId} enrmntid={enrmntid} attachmentid={selAttachmentId.current} />
                    : null
            }
            {
                showDocuSignHistDlg ?
                    <DocuSignHistDlg open={true} onClose={closeDocuSignHistDlg} userId={userId} sessionId={sessionId} enrmntid={enrmntid} attachmentid={selAttachmentId.current} />
                    : null
            }
            {
                showDocuSignMailDlg ?
                    <DocuSignMailDlg open={true} onResend={DocuSignResendMailDlg} onClose={closeDocuSignMailDlg} userId={userId} sessionId={sessionId} enrmntid={enrmntid} attachmentid={selAttachmentId.current} />
                    : null
            }
        </React.Fragment>
    );
};

export default DocListDlg;
