import React, { useState } from 'react';
import Loader from "react-loader";
//import Modal from 'react-bootstrap/Modal';

const LoaderControl = (props) => {

    return (
        <>
            <Loader
                loaded={!props.show}
                lines={10}
                length={18}
                width={10}
                radius={25}
                corners={1}
                rotate={0}
                direction={1}
                color="#000"
                speed={1}
                trail={60}
                shadow={false}
                hwaccel={false}
                className="spinner"
                zIndex={2e9}
                top="20%"
                left="50%"
                scale={1.0}
                loadedClassName="loadedContent"
            />
        </>
    );
}
export default LoaderControl;