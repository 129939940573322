import React, { useState, useEffect } from 'react';
import BootstrapTable from 'react-bootstrap-table-next';
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from 'react-redux';
import { Plus } from 'react-bootstrap-icons';
import Dropdown from 'react-bootstrap/Dropdown';
import { ApiHelper } from '../../customFunctions/apiHelper';
import LoaderControl from '../Controls/LoaderControl';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import '../../assets/css/message-table.css';
import { MessageFollowup } from '../../customFunctions/SysParmConfig';

const DashBoardMessages = (props) => {

    const sessions = useSelector((state) => state.sessionMgmnt);
    const dispatch = useDispatch();
    const [inbox, setInbox] = useState(true);
    const [mailFolder, setMailFolder] = useState('Inbox');
    const [loading, setLoading] = useState(false);

    const [mailData, setMailData] = useState([]);

    const responseType = {
        None : 50,
        Reply : 51,
        Forward : 52
    }


    const handleMessageClick = (msgId) => {        
        setLoading(true);

        let headers = {
            'Content-Type': 'application/json'
        };

        let apiUrl = process.env.REACT_APP_GET_MESSAGE_DETAIL + msgId;

        ApiHelper({ url: apiUrl, method: 'GET', headers: headers, sessions: sessions, dispatch: dispatch})
            .then(resposnse => {
                if (resposnse !== null) {                    
                    setLoading(false);
                    //orgMsgId should be Current Message Id while replying or Forwared a message....

                    /*If tab is Inbox, it should be reply and when tab is Sent box it should be forward*/
                    let responseTypId = responseType.None;
                    let subject = resposnse.Subject;
                    let followupId = resposnse.FollowTypId;

                    if (mailFolder === "Sent Items") {
                        responseTypId = responseType.Forward;
                        subject = resposnse.Subject.search('FW:') === 0 ? resposnse.Subject: 'FW: ' + resposnse.Subject;
                    }
                    else if (mailFolder === "Inbox") {
                        responseTypId = responseType.Reply;
                        subject = resposnse.Subject.search('RE:') === 0 ? resposnse.Subject : 'RE: ' + resposnse.Subject;
                    }
                        
                    history.push({
                        pathname: '/Message',
                        state: { loginusrId: props.loginusrId, data: resposnse, orgMsgId: msgId, subject: subject, responseTypId: responseTypId, followupId: followupId }
                    });

                }
                else {
                    setLoading(false);
                }
            });


    }
    //MessageId	PriorityType	Subject	SendDt	FlagShowSendMail	FromContact	ToContact
    const columns = [
        {
            dataField: 'FollowUp',
            text: '',
            sort: true,
            headerClasses: 'header-fixed-width',
            style: {
                textAlign: 'center'
            },
            formatter: followupFormatter,
            events: {
                onClick: (row) => {
                    handleMessageClick(row.MessageId);
                }
            }
        },
        {
            dataField: 'FromContact',
            text: 'From',
            style: {
                fontWeight: 'bold',
                cursor: 'pointer'
            },
            sort: true,
            hidden: mailFolder === "Inbox" ? false : true,
            events: {
                onClick: (e, column, columnIndex, row, rowIndex) => {
                    handleMessageClick(row.MessageId);
                }
            }
        },
        {
            dataField: 'ToContact',
            text: 'To',
            sort: true,
            style: {
                fontWeight: 'bold',
                cursor: 'pointer'
            },
            hidden: mailFolder === "Sent Items" ? false : true,
            events: {
                onClick: (e, column, columnIndex, row, rowIndex) => {
                    handleMessageClick(row.MessageId);
                }
            }
        }
        ,
        {
            dataField: 'Subject',
            text: 'Subject',
            sort: true,
            formatter: subjectFormatter
        },        
        {
            dataField: 'SendDt',
            text: 'Received',
            align: 'right',
            sort: true,
            headerClasses: 'header-right-align',
            style: {
                'whiteSpace': 'nowrap'
            }
        }];

    function followupFormatter(cell, row) {
        if (row.FollowTypId === MessageFollowup.Complete ) {
            return (
                <a style={{ textAlign: 'center' }} tite={row.FollowUp} data-toggle="tooltip" >
                    <span>
                        <strong><i className="fa fa-check px-1" aria-hidden="true"></i></strong>
                    </span>
                </a>
            );
        }
        else if (row.FollowTypId === MessageFollowup.Followup) {
            return (
                <a style={{ textAlign: 'center' }} tite={row.FollowUp} data-toggle="tooltip" >
                    <span tite={row.FollowUp} data-toggle="tooltip" >
                        <strong style={{ color: '#cd9820' }}><i className="fa fa-flag px-1"></i></strong>
                    </span>
                </a>
            );
        }

        return (
            <a style={{ textAlign: 'center' }} tite={row.FollowUp} data-toggle="tooltip" >
                <span tite={row.FollowUp} data-toggle="tooltip" ><i className="fa fa-envelope-open-text px-1"></i></span>
            </a>
        );
    }

    function subjectFormatter (cell, row) {
        if (row.PriorityType === 'High') {
            return (
                <span tite={row.PriorityType}>
                    <strong style={{ color: '#cc0226' }}><i className="fa fa-exclamation" aria-hidden="true"></i></strong> {cell}
                </span>
            );
        }
        else if (row.PriorityType === 'Medium') {
            return (
                <span tite={row.PriorityType}>
                    <strong style={{ color: '#0a3a67' }}><i className="fa fa-arrow-down"></i></strong> {cell}
                </span>
            );
        }

        return (
            <span tite={row.PriorityType}>{cell}</span>
        );
    }


    const data = [
        {
            from: "Tom Bresnan",
            subject: "Invest in the present to fulfill your dreams of tomorrow",
            received: "07 Sep 2022"
        },
        {
            from: "Mary Mathews",
            subject: "Welcome to Account onboarding system",
            received: "02 Sep 2022"
        }];
    /*<i class="fas fa-reply"></i>*/
    const onChangeValue = (event)=> {        
        setInbox(event.target.id === 'btnInbox');
    }

    let history = useNavigate();

    const handleComposeMessageClick = () => {
        history.push({
            pathname: '/Message',
            state: { loginusrId: props.loginusrId, responseTypId: responseType.None}
        });
    }
    const folderDropdownClick = () => {
        document.getElementById("folderDropdown").classList.toggle("show");
    }
    const toolMoreDropdownClick = () => {
        document.getElementById("toolMoreDropdown").classList.toggle("show");
    }
    const mailSortDropdownClick = () => {
        document.getElementById("mailSortDropdown").classList.toggle("show");
    }
    const selectMailDropdownClick = () => {
        document.getElementById("selectMailDropdown").classList.toggle("show");
    }

    const setActiveTab = (e, folder) => {
        e.preventDefault();
        document.getElementById("folderDropdown").classList.remove('show');
        setMailFolder(folder);

        if (folder === "Sent Items")
            loadEmails(messageType.sent)
        else if (folder === "Inbox")
            loadEmails(messageType.inbox)
        else
            setMailData([]);

    }

    const reload = () => {
        if (mailFolder === "Sent Items")
            loadEmails(messageType.sent)
        else if (mailFolder === "Inbox")
            loadEmails(messageType.inbox)
        else
            setMailData([]);
    }

    let messageType = {
        inbox: 1,
        sent: 2,
        archives:3
    }

    const loadEmails = (mailType) => {
        setLoading(true);
        //setAlertMsg('');

        let headers = {
            'Content-Type': 'application/json'
        };


        let apiUrl = process.env.REACT_APP_GET_MESSAGE_LIST + 'cntctId/' + props.loginusrId + '/messageType/' + mailType;

        ApiHelper({ url: apiUrl, method: 'GET', headers: headers, sessions: sessions, dispatch: dispatch})
            .then(resposnse => {
                if (resposnse !== null) {
                    setMailData(resposnse);
                    setLoading(false);
                }
                else {
                    setLoading(false);
                }
            });
    }

    useEffect(() => {
        loadEmails(messageType.inbox);
    }, []);


    return (
        <>
            <LoaderControl show={loading} />
            <div className="m-t-15">
                <button type="button" onClick={handleComposeMessageClick} className="btn p-t-1 p-b-1 p-s-5 p-e-5" style={{ background: '#17479e', borderRadius: '50px'}}>
                    <svg xmlns="http://www.w3.org/2000/svg" shapeRendering="geometricPrecision" textRendering="geometricPrecision" imageRendering="optimizeQuality" fillRule="evenodd" clipRule="evenodd" width="24" height="24" viewBox="0 0 512 512">
                        <path className="cls-1000" fillRule="nonzero" d="M75.01 0h361.98c20.64 0 39.4 8.44 52.98 22.03C503.56 35.61 512 54.37 512 75.01v361.98c0 20.64-8.44 39.4-22.03 52.98-13.58 13.59-32.34 22.03-52.98 22.03H75.01c-20.62 0-39.36-8.44-52.95-22.03l-.03-.03C8.44 476.35 0 457.61 0 436.99V75.01c0-20.62 8.44-39.36 22.03-52.95l.03-.03C35.65 8.44 54.39 0 75.01 0zm77.23 301.8 55.97 56.47 121.14-121.14-56.2-56.24L152.24 301.8zm39.77 64.55-47.81-48.23-14.71 52.36c-1.87 6.67-3.29 12.16-4.27 16.48 17.65-5.27 35.16-10.81 52.73-16.31l13.91-4.25.15-.05zm148.17-242.21c-.81-.8-1.63-1.38-2.45-1.72-.75-.32-1.65-.48-2.71-.48-1.04 0-1.94.16-2.68.48-.77.32-1.57.91-2.39 1.72l-19.3 19.3 56.22 56.47 19.57-19.57c.64-.64 1.11-1.34 1.39-2.06.34-.87.51-1.91.51-3.07s-.15-2.18-.48-3c-.29-.74-.75-1.45-1.38-2.07l-46.3-46zm4.22-17.56c2.91 1.23 5.54 3.02 7.9 5.37l46.3 46c2.33 2.31 4.07 4.99 5.23 7.92 1.19 3 1.76 6.15 1.76 9.34 0 3.24-.56 6.38-1.72 9.33a23.212 23.212 0 0 1-5.23 7.99l-25.7 25.67c-3.37 3.35-8.82 3.33-12.16-.04l-68.39-68.65a8.625 8.625 0 0 1 0-12.2l25.36-25.36c2.32-2.32 4.96-4.11 7.92-5.37 2.96-1.25 6.08-1.89 9.35-1.89 3.27 0 6.4.63 9.38 1.89zM210.24 378.85 183 387.08c-17.51 5.51-34.93 10.92-52.47 16.29-6.08 1.84-10.12 3.03-12.07 3.57l-.89.2c-.82.15-1.58.19-2.31.14l-1.23-.18c-4.29-.77-6.77-3.6-7.46-8.46l-.06-.48c-.15-1.44-.1-3.2.13-5.23.61-5.31 2.7-14.35 6.27-27.1l18.57-66.07a8.457 8.457 0 0 1 2.2-3.77l133.36-133.38a8.625 8.625 0 0 1 12.2 0l68.38 68.48c3.35 3.36 3.33 8.79-.02 12.14L214.3 376.57a8.571 8.571 0 0 1-4.06 2.28zm226.75-361.6H75.01c-15.89 0-30.33 6.5-40.8 16.96-10.46 10.47-16.96 24.91-16.96 40.8v361.98c0 15.89 6.5 30.33 16.96 40.8 10.47 10.46 24.91 16.96 40.8 16.96h361.98c15.88 0 30.32-6.5 40.79-16.97 10.47-10.47 16.97-24.91 16.97-40.79V75.01c0-15.88-6.5-30.32-16.97-40.79-10.47-10.47-24.91-16.97-40.79-16.97z" />
                    </svg>
                    <span className="p-s-2" style={{color: '#fff'}}>Compose</span>
                </button>
            </div>
            <div className="input-group m-t-12" style={{width: '60%'}}>
                <input className="form-control search-txtbox" type="text" placeholder="Find messages, documents" aria-label="Search" />
                <button type="button" className="btn btn-secondary">
                    <i className="fas fa-search"></i>
                </button>
            </div>
            <div className="row p-t-4">
                <div className="col-sm-6">
                    <div className="dropdown mail-folder">
                        <button onClick={folderDropdownClick} className="dropbtn">
                            <span className="m-e-2">{mailFolder}</span>
                            <svg role="img" xmlns="http://www.w3.org/2000/svg" width="32px" height="32px" viewBox="0 0 24 24" aria-labelledby="chevronsDownIconTitle" stroke="#000" strokeWidth="1" strokeLinecap="square" strokeLinejoin="miter" fill="none" color="#2329D6">
                                <title id="chevronsDownIconTitle">Chevrons Down</title>
                                <polyline points="7 13 12 18 17 13" /> <polyline points="7 7 12 12 17 7" />
                            </svg>
                        </button>
                        <div id="folderDropdown" className="dropdown-content">
                            <a onClick={(e) => setActiveTab(e, 'Inbox')}>Inbox</a>
                            <a onClick={(e) => setActiveTab(e, 'Sent Items')}>Sent Items</a>
                            <a onClick={(e) => setActiveTab(e, 'Drafts')}>Drafts</a>
                            <a onClick={(e) => setActiveTab(e, 'Archive')}>Archive</a>
                            <a onClick={(e) => setActiveTab(e, 'Deleted Items')}>Deleted Items</a>
                        </div>
                        </div>
                </div>
                <div className="col-sm-6">
                    <div className="mail-pagination p-t-3">
                        {
                            mailData && mailData.length > 0?
                            <span className="p-e-4" >1-{mailData.length} of {mailData.length}</span>
                            :
                            <span className="p-e-4" >0 of 0</span>
                        }
                        
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" shapeRendering="geometricPrecision" textRendering="geometricPrecision" imageRendering="optimizeQuality" fillRule="evenodd" clipRule="evenodd" viewBox="0 0 298 511.93">
                            <path fillRule="nonzero" d="M285.77 441c16.24 16.17 16.32 42.46.15 58.7-16.16 16.24-42.45 16.32-58.69.16l-215-214.47c-16.24-16.16-16.32-42.45-.15-58.69L227.23 12.08c16.24-16.17 42.53-16.09 58.69.15 16.17 16.24 16.09 42.54-.15 58.7l-185.5 185.04L285.77 441z" />
                        </svg>
                        &nbsp;&nbsp;&nbsp;&nbsp;
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" shapeRendering="geometricPrecision" textRendering="geometricPrecision" imageRendering="optimizeQuality" fillRule="evenodd" clipRule="evenodd" viewBox="0 0 298 511.93">
                            <path fillRule="nonzero" d="M70.77 499.85c-16.24 16.17-42.53 16.09-58.69-.15-16.17-16.25-16.09-42.54.15-58.7l185.5-185.03L12.23 70.93c-16.24-16.16-16.32-42.45-.15-58.7 16.16-16.24 42.45-16.32 58.69-.15l215.15 214.61c16.17 16.25 16.09 42.54-.15 58.7l-215 214.46z" />
                        </svg>
                    </div>
                </div>
            </div>
            
            <div className="row p-t-5 p-b-2">
                <div className="col-sm-2">
                    <div className="form-check p-t-1-1" style={{ height: '32px' }}>
                        {/*<input className="form-check-input" type="checkbox" value="" id="flexCheckDefault" />*/}
                        {/*<div className="dropdown select-mail">*/}
                        {/*    <button onClick={selectMailDropdownClick} className="dropbtn" style={{ height: '32px' }}>*/}
                        {/*        <svg className="p-b-1" xmlns="http://www.w3.org/2000/svg" shapeRendering="geometricPrecision" textRendering="geometricPrecision" imageRendering="optimizeQuality" fillRule="evenodd" clipRule="evenodd" width="16" height="16" viewBox="0 0 512 312.36">*/}
                        {/*            <path fillRule="nonzero" d="m32.66 5.71 220.49 241.11L479.35 0 512 29.87 253.12 312.36 0 35.58z" />*/}
                        {/*        </svg>*/}
                        {/*    </button>*/}
                        {/*    <div id="selectMailDropdown" className="dropdown-content">*/}
                        {/*        <a href="#">All</a>*/}
                        {/*        <a href="#">None</a>*/}
                        {/*        <a href="#">Read</a>*/}
                        {/*        <a href="#">Unread</a>*/}
                        {/*        <a href="#">Starred</a>*/}
                        {/*        <a href="#">Unstarred</a>                               */}
                        {/*    </div>                            */}
                        {/*</div>*/}
                        <button type="button" title="Reload Messages" className="mail-toolbar-button" style={{ height: '32px' }} onClick={reload}>
                            <svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 119.4 122.88">
                                <title>reload</title>
                                <path d="M83.91,26.34a43.78,43.78,0,0,0-22.68-7,42,42,0,0,0-24.42,7,49.94,49.94,0,0,0-7.46,6.09,42.07,42.07,0,0,0-5.47,54.1A49,49,0,0,0,30,94a41.83,41.83,0,0,0,18.6,10.9,42.77,42.77,0,0,0,21.77.13,47.18,47.18,0,0,0,19.2-9.62,38,38,0,0,0,11.14-16,36.8,36.8,0,0,0,1.64-6.18,38.36,38.36,0,0,0,.61-6.69,8.24,8.24,0,1,1,16.47,0,55.24,55.24,0,0,1-.8,9.53A54.77,54.77,0,0,1,100.26,108a63.62,63.62,0,0,1-25.92,13.1,59.09,59.09,0,0,1-30.1-.25,58.45,58.45,0,0,1-26-15.17,65.94,65.94,0,0,1-8.1-9.86,58.56,58.56,0,0,1,7.54-75,65.68,65.68,0,0,1,9.92-8.09A58.38,58.38,0,0,1,61.55,2.88,60.51,60.51,0,0,1,94.05,13.3l-.47-4.11A8.25,8.25,0,1,1,110,7.32l2.64,22.77h0a8.24,8.24,0,0,1-6.73,9L82.53,43.31a8.23,8.23,0,1,1-2.9-16.21l4.28-.76Z" />
                            </svg>
                            <span className="m-s-2 p-t-3">Refresh</span>
                        </button>
                    </div>
                </div>
                <div className="col-sm-8" style={{ textAlign: 'center', opacity: 0.5 }}>                    
                    <button type="button" title="Archive Messages" className="mail-toolbar-button" style={{ height: '32px' }}>
                        <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="24" height="24" viewBox="0 0 122.878 110.041" enableBackground="new 0 0 122.878 110.041">
                            <g>
                                <path fillRule="evenodd" clipRule="evenodd" d="M1.149,0h120.583c0.631,0,1.146,0.518,1.146,1.149v28.383 c0,0.634-0.516,1.149-1.146,1.149H1.149C0.518,30.681,0,30.166,0,29.532V1.149C0,0.518,0.518,0,1.149,0L1.149,0z M7.224,36.787 h108.433c0.526,0,0.962,0.43,0.962,0.961v71.331c0,0.529-0.436,0.962-0.962,0.962H7.224c-0.528,0-0.961-0.433-0.961-0.962V37.749 C6.263,37.217,6.695,36.787,7.224,36.787L7.224,36.787z M45.005,48.526h32.87c3.529,0,6.419,2.888,6.419,6.417l0,0 c0,3.529-2.89,6.416-6.419,6.416h-32.87c-3.532,0-6.419-2.887-6.419-6.416l0,0C38.586,51.414,41.474,48.526,45.005,48.526 L45.005,48.526z" />
                            </g>
                        </svg>
                        <span className="m-s-2 p-t-3">Archive</span>
                    </button>
                    <button type="button" title="Delete the selected messages" className="mail-toolbar-button m-s-2" style={{ height: '32px' }}>
                        <svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 110.61 122.88">
                            <title>Delete the selected messages</title>
                            <path d="M39.27,58.64a4.74,4.74,0,1,1,9.47,0V93.72a4.74,4.74,0,1,1-9.47,0V58.64Zm63.6-19.86L98,103a22.29,22.29,0,0,1-6.33,14.1,19.41,19.41,0,0,1-13.88,5.78h-45a19.4,19.4,0,0,1-13.86-5.78l0,0A22.31,22.31,0,0,1,12.59,103L7.74,38.78H0V25c0-3.32,1.63-4.58,4.84-4.58H27.58V10.79A10.82,10.82,0,0,1,38.37,0H72.24A10.82,10.82,0,0,1,83,10.79v9.62h23.35a6.19,6.19,0,0,1,1,.06A3.86,3.86,0,0,1,110.59,24c0,.2,0,.38,0,.57V38.78Zm-9.5.17H17.24L22,102.3a12.82,12.82,0,0,0,3.57,8.1l0,0a10,10,0,0,0,7.19,3h45a10.06,10.06,0,0,0,7.19-3,12.8,12.8,0,0,0,3.59-8.1L93.37,39ZM71,20.41V12.05H39.64v8.36ZM61.87,58.64a4.74,4.74,0,1,1,9.47,0V93.72a4.74,4.74,0,1,1-9.47,0V58.64Z" />
                        </svg>
                        <span className="m-s-2 p-t-2">Delete</span>
                    </button>
                    <div className="dropdown mail-tool-more m-s-2">
                        <button onClick={toolMoreDropdownClick} className="dropbtn" style={{ height: '32px' }}>
                            <svg className="p-b-1" id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 122.88 29.96">
                                <defs>
                                </defs>
                                <title>More options</title>
                                <path className="cls-1" d="M0,15A15,15,0,1,1,15,30,15,15,0,0,1,0,15Zm92.93,0a15,15,0,1,1,15,15,15,15,0,0,1-15-15ZM46.46,15a15,15,0,1,1,15,15,15,15,0,0,1-15-15Z" />
                            </svg>
                        </button>
                        <div id="toolMoreDropdown" className="dropdown-content" style={{ textAlign: 'left' }}>
                            <a href="#">Mark as read</a>
                            <a href="#">Mark as unread</a>
                            <a href="#">Star</a>
                            <a href="#">Clear star</a>
                        </div>
                    </div>
                </div>
                <div className="col-sm-2" style={{ textAlign: 'right' }}>
                    <div className="dropdown select-mail">
                        <button onClick={mailSortDropdownClick} className="dropbtn" style={{ height: '32px' }}>
                            <span className="m-e-3 p-t-3">Sort</span>
                            <svg className="p-b-1-2" xmlns="http://www.w3.org/2000/svg" shapeRendering="geometricPrecision" textRendering="geometricPrecision" imageRendering="optimizeQuality" fillRule="evenodd" clipRule="evenodd" width="16" height="16" viewBox="0 0 512 312.36">
                                <path fillRule="nonzero" d="m32.66 5.71 220.49 241.11L479.35 0 512 29.87 253.12 312.36 0 35.58z" />
                            </svg>
                        </button>
                        <div id="mailSortDropdown" className="dropdown-content" style={{ textAlign: 'left' }}>
                            <a href="#">Date: Newest on top</a>
                            <a href="#">Date: Oldest on top</a>
                            <a href="#">Unread</a>
                            <a href="#">Starred</a>
                            <a href="#">Sender</a>
                            <a href="#">Subject</a>
                            <a href="#">Attachment</a>
                        </div>
                    </div>
                </div>
            </div>
            <div className="row" style={{borderTop: '1px solid rgba(51, 51, 51, 0.3'}} >
                {/*<div className="col-sm-12">*/}
                {/*    <div className="alert alert-light div-height" role="alert">*/}
                {/*        <button type="button" className="btn btn-outline-dark" onClick={handleComposeMessageClick}><i className="fa fa-plus px-1"></i>&nbsp;Compose Message</button>*/}
                {/*    </div>*/}
                {/*</div>*/}
                {/*<div className="col-sm-12">*/}
                {/*    <div className="p-t-6 p-b-3">*/}
                {/*        <p>If you require any further information, feel free to contact us.</p>*/}
                {/*        <span className="m-e-2 f-size-14">2 &nbsp;Messages</span><button type="button" className="pill-button m-s-1" onClick={handleComposeMessageClick} ><Plus className="m-s-4 m-e-4 m-b-1 m-t-1" title="Compose Message" color="#3D3D3D" size={22} /></button>*/}
                {/*    </div>*/}
                {/*</div>*/}
                {/*<div className="col-sm-12">*/}
                {/*    <div className="btn-group" role="group" aria-label="Basic radio toggle button group" style={{ paddingBottom: 5 }} onChange={onChangeValue}>*/}
                {/*        <input type="radio" className="btn-check" name="btnradio" id="btnInbox" autoComplete="off" defaultChecked={inbox} />*/}
                {/*        <label className="btn btn-outline-secondary" htmlFor="btnInbox" ><i className="fa fa-mail-bulk px-1"></i>Inbox</label>*/}

                {/*        <input type="radio" className="btn-check" name="btnradio" id="btnSent" autoComplete="off" defaultChecked={!inbox} />*/}
                {/*        <label className="btn btn-outline-secondary" htmlFor="btnSent" ><i className="fa fa-paper-plane px-1"></i>Sent Items</label>*/}
                {/*    </div>*/}
                {/*</div>*/}
                <div className="col-sm-12">
                    <div className="table-responsive">
                        <BootstrapTable keyField='MessageId' data={mailData} columns={columns}
                            hover
                            bordered={false}
                            selectRow={{ mode: 'checkbox' }}
                            defaultSortDirection="asc"
                            id="msgTbl"
                            wrapperClasses="table-responsive"                        
                            noDataIndication="Folder is Empty"
                        />
                        {/* headerClasses="header-class"*/}
                    </div>
                </div>
            </div>
        </>
    );
}
export default DashBoardMessages;