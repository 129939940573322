import * as $ from 'jquery';
import React, { Component, createRef, useRef, useEffect, useState, Card, CardMedia } from "react";
import { Container, Box, Typography, Paper, Grid, Button, Divider, Stack, Tooltip, Link } from '@mui/material';
import AlertControl from '../components/Controls/AlertControl';

//JQuery alias
window.jQuery = $;
window.$ = $;

//For Element Drag and Drop
require("jquery-ui-sortable");
// For FormBuilder
require("formBuilder");

require('formBuilder/dist/form-render.min.js')

const DispalyApplicationForm = (props) => {

    const [errorMsg, setErrorMsg] = useState('');
    const sectionHeaders = useRef([]);
    const [curSecHdrIdx, setCurSecHdrIdx] = useState(0);
    const [nextId, setNextId] = useState('');
    const [curId, setCurId] = useState('');
    const [prevId, setPrevId] = useState('');
    const sections = useRef([]);

    const templateDetail = useRef('');

    const handleScrollIntoView = (event) => {
        event.preventDefault();
        let id = event.target.id.replace('btn_', '');
        const myArray = id.split("_");
        let nmPrfx = myArray[1];

        sectionDivider(sectionHeaders.current, templateDetail.current, false, nmPrfx);
    }

    const sectionDivider = (headerTypes, actualData, isInital, sectionId) => {

        let nmPrfx = '';
        let curRowIndex = 0;

        if (!isInital) {
            curRowIndex = headerTypes.findIndex(item => item.name.toString().endsWith(sectionId));
        }

        setCurSecHdrIdx(curRowIndex);

        if (headerTypes[curRowIndex].type.toString() === "header" && headerTypes[curRowIndex].className == "section-group") {
            const myArray = headerTypes[curRowIndex].name.split("_");
            nmPrfx = myArray[1];

            if (curRowIndex === 0)
                setPrevId('');
            else {
                const myArray = headerTypes[curRowIndex - 1].name.split("_");
                let prevNmPrfx = myArray[1];
                setPrevId(prevNmPrfx);
            }

            let cardObj = {
                id: null,
                visibile: true,
                values: []
            };

            let data = [];
            try {
                data = JSON.parse(actualData).filter((section) => {
                    return section.sectionIdentifier.toString().endsWith(nmPrfx);
                });
            } catch (e) {
                data = (actualData).filter((section) => {
                    return section.sectionIdentifier.toString().endsWith(nmPrfx)
                });
            }

            cardObj.id = nmPrfx;
            cardObj.visibile = true;
            cardObj.values = data;

            setCurId(nmPrfx);
            sections.current = [cardObj];
            if (curRowIndex === headerTypes.length - 1) {
                setNextId('');
            }
            else {
                const myArray = headerTypes[curRowIndex + 1].name.split("_");
                let nextNmPrfx = myArray[1];
                setNextId(nextNmPrfx);
            }

            $('.fb-render-preview').formRender({
                dataType: 'json',
                formData: JSON.stringify(sections.current[0]["values"]),
                i18n: {
                    location: process.env.REACT_APP_I18N_LANG
                }
            });
        }
    };

    useEffect(() => {

        let templateData = JSON.parse(props.data).slice();

        let cur_ref = '';
        let count = 0;

        for (let i = 0; i < templateData.length; i++) {

            if (templateData[i].type == "header" && templateData[i].className == "section-group") {
                cur_ref = 'sec_ref' + count;
                templateData[i].name = cur_ref;
                templateData[i].sectionIdentifier = cur_ref;
                count++;
            }
            else {
                templateData[i].sectionIdentifier = cur_ref;
            }
        }

        templateDetail.current = templateData;

        const headerTypes = templateDetail.current.filter((headerType) => {
            return headerType.type === "header" && headerType.className == "section-group";

        });

        sectionDivider(headerTypes, templateDetail.current, true, '')

        if (headerTypes.length > 0) {
            sectionHeaders.current = headerTypes;
        }

    }, []);

    const TestTemplateClick = (event) => {

        let requiredField = JSON.parse(props.data).filter(ed => { return ed.required });

        let validtnMsg = "";
        let msgCount = 0;
        let isDisabled = false;

        if (requiredField) {
            for (var i = 0; i < requiredField.length; i++) {

                isDisabled = $('#' + requiredField[i].name).prop('disabled');
                if (isDisabled)
                    continue;

                if ((requiredField[i].userData === undefined || requiredField[i].userData === null || requiredField[i].userData.length == 0 ||
                    requiredField[i].userData[0] === "" || requiredField[i].userData[0].length === 0) && requiredField[i].value == null) {
                    msgCount++;
                    validtnMsg += msgCount > 1 ? ", " : ""
                    validtnMsg += "'" + requiredField[i].label + "'";
                }
            }
        }

        if (validtnMsg.length > 0) {
            setErrorMsg("Fill up " + validtnMsg + " inormation.");
        }
    };

    return (
        <React.Fragment>
            <Container maxWidth="xl" sx={{ pt: 8 }}>
                {/*<Paper elevation={2} sx={{ mb: 4 }}>*/}
                    <Grid item sx={{}}>
                        {/*<Box sx={{ pl: 0, pt: 4, ml: 4 }}>*/}
                        {/*    <Button variant="outlined" size="small" sx={{ padding: '0.4rem 1.2rem', border: 2, borderColor: '#002d72' }} onClick={(e) => TestTemplateClick(e)}>*/}
                        {/*        <Typography variant="body2" color="#212121" sx={{ textTransform: 'none', fontWeight: 600 }} >Test Template</Typography>*/}
                        {/*    </Button>*/}
                        {/*</Box>*/}
                        <Box sx={{ pt: 2, pl: 2, pr: 2 }}>
                            <AlertControl Message={errorMsg} severity="error" color="error" icon={true} />
                        </Box>
                    </Grid>
                    <Grid item container spacing={0} sx={{ pt: 3, pb: 10 }}>
                        <Grid item xs={12} md={3} sx={{}}>
                            <ApplicationSections sectionHeaders={sectionHeaders.current} CurrentIndex={curSecHdrIdx} handleClick={(event) => handleScrollIntoView(event)} />
                        </Grid>
                        <Grid item xs={12} md={8}>
                            {/*<Paper elevation={0}>*/}
                                <Box sx={{ borderLeft: 1, borderColor: '#ccc', pl: 7, pr: 5, pb: 4 }}>
                                    <div className="fb-render-preview" id='fb-render-preview-id'>
                                    </div>
                                </Box>
                           {/* </Paper>*/}
                        </Grid>
                        {/*<Grid item xs={12} md={1} sx={{}}>*/}
                        {/*</Grid>*/}
                    </Grid>
                {/*</Paper>*/}
            </Container>
        </React.Fragment>
    );
}

const ApplicationSections = (props) => {

    return (
        <>
            <Box className="app-sec-nav" sx={{ pt: 1 }} >
                {
                    props.sectionHeaders.map((elem, i) => (
                        <Stack key={i} direction="row" spacing={2}>
                            <Box className="icon-box" sx={{ pl: 3 }}>
                                <span className={props.CurrentIndex === i ? 'step-number-active display-19' : 'step-number display-19'}><strong>{i + 1}</strong></span>
                            </Box>
                            <Box sx={{ pl: 3 }} className={props.CurrentIndex === i ? 'text-box-active' : 'text-box'}>

                                <Link color={props.CurrentIndex === i ? 'text.primary' : '#bdbdbd'} component="button" href="#" underline="hover" variant="body1" sx={{ mb: 0, fontWeight: 500 }} onClick={(e) => props.handleClick(e)} id={"btn_" + elem.name} >{elem.label}</Link>
                            </Box>
                        </Stack>
                    ))
                }
                <Stack direction="row" spacing={2}>
                    <Box className="icon-box" sx={{ pl: 3 }}>
                        <span className="step-number display-19"><strong>&nbsp;</strong></span>
                    </Box>
                    <Box sx={{ pl: 3 }} className="">

                        <Link color="#bdbdbd" component="button" href="#" underline="hover" variant="body1" sx={{ mb: 0, fontWeight: 500 }}>End</Link>
                    </Box>
                </Stack>

            </Box>
        </>
    );
};

export default DispalyApplicationForm;
