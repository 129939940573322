import React, { useState, useEffect } from 'react';
import { Box, FormControl, TextField, Typography, Divider, Button, FormControlLabel } from '@mui/material';
import OverlaySpinner from '../Controls/OverlaySpinner';
import { CallAPI, IsError } from '../../global/APIUtils';
import { ErrorMessages } from '../../common/ErrorMessages';
import AlertControl from '../Controls/AlertControl';
import { useFormik } from 'formik';
import * as yup from 'yup';
import AlertWithTitle from '../Controls/AlertWithTitle';
import { APICall, WriteToActivityLog } from '../../global/Api';
import { ActivityLogEvent } from '../../common/AOBEnum';
import Checkbox, { checkboxClasses } from "@mui/material/Checkbox";

const PasswordPolicy = (props) => {

    const [isDataLoading, setIsDataLoading] = useState(false);
    const [minLength, setMinLength] = useState(12);
    const [pwdExpiry, setPwdExpiry] = useState(30);
    const [pwdNeverExpires, setPwdNeverExpires] = useState(false);
    const [pwdRegExpression, setPwdRegExpression] = useState(true);
    const [pwdRepeat, setPwdRepeat] = useState(true);

    const [errorMsg, setErrorMsg] = useState(null);
    const [successMsg, setSuccessMsg] = useState('');

    const [userId, setUserID] = useState(props.userId || -1);
    const [sessionId, setSessionId] = useState(props.sessionId || '');

    const loadForm = async () => {
        try {

            let headers = {
                'Content-Type': 'application/json'
            };

            const apiUrl = new URL(
                process.env.REACT_APP_GET_PWD_POLICY,
                process.env.REACT_APP_BASE_URL,
            );

            setIsDataLoading(true);

            let response = await APICall({ url: apiUrl.href, method: 'GET', contentType: 'application/json', responseType: '' }, userId, sessionId);

            if (response !== null && response !== undefined) {
                if (response["data"] && response["data"].length > 0) {
                    setMinLength(response.data[1].ParmValue);
                    setPwdExpiry(response.data[0].ParmValue);

                    if (parseInt(response.data[0].ParmValue) == parseInt(0)) {
                        setPwdNeverExpires(true);
                    }
                }
            }
            else {
                setErrorMsg(ErrorMessages.UnknownError);
            }
        }
        catch (err) {
            setErrorMsg(ErrorMessages.UnknownError);
        }
        finally {
            setIsDataLoading(false);
        }
    }

    useEffect(() => {
        loadForm();
    }, []);


    const pwdPolicyForm = useFormik({
        enableReinitialize: true,
        initialValues: {
            MinLength: minLength,
            PasswordExpiry: pwdExpiry,
            ChkPwdNeverExpires: pwdNeverExpires,
            ChkPwdRegExpression: pwdRegExpression, 
            ChkPwdRepeat: pwdRepeat
        },
        validationSchema: yup.object({
            MinLength: yup.number().integer("You must specify a number without decimal point").max(20, 'Must be less than or equal to 20').min(8, 'Must be greater than or equal to 8')
                .required('Minimum password length - required')
                .typeError('You must specify a number'),
            PasswordExpiry: yup.number().integer("You must specify a number without decimal point").min(0, 'Must be greater than or equal to 0')
                .typeError('You must specify a number'),
        }),
        onSubmit: values => {
            submitClick(values);
        }
    })

    const submitClick = async (values) => {

        setErrorMsg('');
        setSuccessMsg('');

        try {
            //setIsDataLoading(true);

            let inputJson = {
                minPwdLen: parseInt(values.MinLength), 
                pwdExpiry: values.ChkPwdNeverExpires ? 0 : parseInt(values.PasswordExpiry)
            };

            const apiUrl = new URL(
                process.env.REACT_APP_SAVE_PWD_POLICY,
                process.env.REACT_APP_BASE_URL,
            );

            let response = await APICall({ url: apiUrl.href, method: 'POST', payload: inputJson, contentType: 'application/json', responseType: '' }, userId, sessionId);

            if (response !== null && response !== undefined) {

                if (response.meta.Updated == "1") {
                    loadForm();
                    await WriteToActivityLog(ActivityLogEvent.SystemOptions, "Password policy updated.", userId, sessionId);
                    setSuccessMsg("Password policy updated successfully.");
                }
                else {
                    setErrorMsg(ErrorMessages.UnknownError);
                }
            }
        }
        catch (err) {
            setErrorMsg(ErrorMessages.UnknownError);
        }
        finally {
            //setSubmit(false);
            setIsDataLoading(false);
        }
    }

    const HandleNeverExpiresChange = async (evt) => {
        pwdPolicyForm.handleChange(evt);

        if (evt.target.checked) {
            setPwdExpiry('0');
            setPwdNeverExpires(true);
        }
    }

    return (
        <>
            <Box component="main" sx={{ flex: 1, height: '100%', pt: 0, pl: 6 }} >
                <Box >
                    <Typography variant='body2' color="text.secondary" sx={{ fontWeight: 500, pb: 1, lineHeight: 1.5, borderBottom: '0px solid #9FA4A8' }}>Password policy</Typography>
                    <Divider variant="fullWidth" component="div" sx={{ }} />
                    <div >
                        <form onSubmit={pwdPolicyForm.handleSubmit} id="bankInfoForm">
                            <OverlaySpinner disappear={isDataLoading} />
                            <AlertControl Message={errorMsg} severity="error" color="error" icon={true} />
                            <AlertWithTitle Message={successMsg} severity="success" color="success" icon={true} Title="Password policy" />
                            <Box sx={{ pb: 3, pt: 1 }}>
                                <Box sx={{ pt: 0.5, pl: 0, pb: 0 }}>
                                    <FormControl variant="outlined" disabled={true} sx={{ }}>
                                        <FormControlLabel color="text.primary" control={<Checkbox name="ChkPwdRegExpression" id="ChkPwdRegExpression" checked={pwdPolicyForm.values.ChkPwdRegExpression} onChange={pwdPolicyForm.handleChange} />} label={<Typography sx={{ fontWeight: 400 }} variant="body4" color="text.primary">Password must contain one uppercase, one lowercase, one number and one special character</Typography>} />
                                    </FormControl>
                                    {
                                        pwdPolicyForm.touched.ChkPwdRegExpression && pwdPolicyForm.errors.ChkPwdRegExpression ? (<p className="text-danger">{pwdPolicyForm.errors.ChkPwdRegExpression}</p>) : null
                                    }
                                </Box>
                                <Box sx={{ pt: 0.5, pl: 0, pb: 2.5 }}>
                                    <FormControl variant="outlined" disabled={true} sx={{}}>
                                        <FormControlLabel color="text.primary" control={<Checkbox name="ChkPwdRepeat" id="ChkPwdRepeat" checked={pwdPolicyForm.values.ChkPwdRepeat} onChange={pwdPolicyForm.handleChange} />} label={<Typography sx={{ fontWeight: 400 }} variant="body4" color="text.primary">Password cannot be the same as old password (one bacward)</Typography>} />
                                    </FormControl>
                                    {
                                        pwdPolicyForm.touched.ChkPwdRepeat && pwdPolicyForm.errors.ChkPwdRepeat ? (<p className="text-danger">{pwdPolicyForm.errors.ChkPwdRepeat}</p>) : null
                                    }
                                </Box>
                                <Box sx={{width: '60%'}}>
                                    <label htmlFor="MinLength" className="form-label">Minimum password length*</label>
                                    <input type="text" id="MinLength" name="MinLength" placeholder="Enter bank name here" className="form-control" value={pwdPolicyForm.values.MinLength} onChange={pwdPolicyForm.handleChange} />
                                    {
                                        pwdPolicyForm.touched.MinLength && pwdPolicyForm.errors.MinLength ? (<p className="text-danger">{pwdPolicyForm.errors.MinLength}</p>) : null
                                    }
                                </Box>
                                <Box sx={{ pt: 2, width: '60%'}}>
                                    <label htmlFor="MinLength" className="form-label">Password expiry (in days, 0 mean no expiry)</label>
                                    <input type="text" id="PasswordExpiry" disabled={pwdPolicyForm.values.ChkPwdNeverExpires} name="PasswordExpiry" placeholder="Enter bank name here" className="form-control" value={pwdPolicyForm.values.PasswordExpiry} onChange={pwdPolicyForm.handleChange} />
                                    {
                                        pwdPolicyForm.touched.PasswordExpiry && pwdPolicyForm.errors.PasswordExpiry ? (<p className="text-danger">{pwdPolicyForm.errors.PasswordExpiry}</p>) : null
                                    }
                                </Box>
                                <Box sx={{ pt: 0.5, pl: 1 }}>
                                    <FormControl variant="outlined" sx={{ width: '100%' }}>
                                        <FormControlLabel color="text.primary" control={<Checkbox sx={{
                                            [`&, &.${checkboxClasses.checked}`]: {
                                                color: 'Check.main',
                                            },
                                        }} name="ChkPwdNeverExpires" id="ChkPwdNeverExpires" checked={pwdPolicyForm.values.ChkPwdNeverExpires} onChange={pwdPolicyForm.handleChange} />} label={<Typography sx={{ fontWeight: 400 }} variant="body4" color="text.primary">Password never expires</Typography>} />
                                    </FormControl>
                                    {
                                        pwdPolicyForm.touched.ChkPwdNeverExpires && pwdPolicyForm.errors.ChkPwdNeverExpires ? (<p className="text-danger">{pwdPolicyForm.errors.ChkPwdNeverExpires}</p>) : null
                                    }
                                </Box>
                            </Box>
                            <Box sx={{ pl: 0.5, pb: 4, pt: 1, borderBottom: '1px solid #b6b7b6' }}>
                                <Button type="submit" variant="contained" size="large" style={{ boxShadow: 'none', border: 0, borderRadius: '20px', padding: '0.3rem 2.5rem', outline: 'none', }}>
                                    Save
                                </Button>
                            </Box>
                        </form>
                    </div>
                </Box>
            </Box >
        </>
    )
}
export default PasswordPolicy;