import React, { useState, useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import Pagination from 'react-bootstrap/Pagination';
import ApplicationCard from './ApplicationCard';
import LoaderControl from '../Controls/LoaderControl';
import DashBoardCreateApplication from './DashBoardCreateApplication';
import { EnrollmentAddEditModal } from './Modal/EnrollmentAddEditModal';
import { EnrollmentReviewModel } from './Modal/EnrollmentReviewModel';
import { useSelector, useDispatch } from 'react-redux';
import { ApiHelper } from '../../customFunctions/apiHelper';
import AlertText from '../Controls/AlertText';
import { EnrollmentApplicationSts } from '../../customFunctions/SysParmConfig';

const DashBoardApplications = (props) => {
    const sessions = useSelector((state) => state.sessionMgmnt);
    const dispatch = useDispatch();

    const [modalShow, setModalShow] = useState(false);
    const [reviewModalShow, setReviewModalShow] = useState(false);
    const [loginUsrId, setLoginUsrId] = useState(props.loginusrId);
    const [alertMsg, setAlertMsg] = useState('');
    const [alertSucStatus, setAlertSucStatus] = useState(false);
  
    const [state, setState] = useState({
        enrmntId: 0,
        templateId: 0,
        name: '',
        templateNm: '',
        ownerId: -1
    })
    const [curPageNo, setCurPageNo] = useState(1);
    const [data, setData] = useState(null);
    const [loading, setLoading] = useState(true);
        
    let active = 1;
    let items = [];
    let history = useNavigate();

    const loadUserTemplate = () => {
        setLoading(true);
        //let jwtToken = getJWTToken();
        let headers = {
            'Content-Type': 'application/json'
        };

        //let usrId = parseInt(null);
        //sessions.map((sn) => { if (sn.key === 'LoginUserId') { usrId = parseInt(sn.value); } });
        //setLoginUsrId(usrId);

        //Mrityunjoy: We need to introduce OwnerId as input parameter. For Demo purpose, we are using pageNo = OwnerCntctId
        let apiUrl = process.env.REACT_APP_GET_ENROLLMENT_DATA + 'templateId/null/enrmntId/null/isOwnerOnly/true/ownerId/' + loginUsrId;
        ApiHelper({ url: apiUrl, method: 'GET', headers: headers, sessions: sessions, dispatch: dispatch })
            .then(resposnse => {
                if (resposnse !== null) {
                    setData(resposnse)

                    let docuSignStatus = resposnse.filter(el => {
                        return el['enrmntStatusId'] === EnrollmentApplicationSts.DocuSignRequired;
                    });

                    if (docuSignStatus && docuSignStatus.length > 0) {
                        setAlertMsg('Your document signing is pending. Please complete your document signing process.');
                        setAlertSucStatus(false);
                    }

                    //let docuSignStatus = resposnse.filter(el => {
                    //    return el['isDocSignReq'] === true;
                    //});

                    //if (docuSignStatus && docuSignStatus.length) {
                    //    let signPendingStatus = (docuSignStatus.filter(el => {
                    //        return el['isSignComplete'] === false;
                    //    }))

                    //    if (signPendingStatus && signPendingStatus.length > 0) {
                    //        setAlertMsg('Your document signing is pending. Please complete your document signing process.');
                    //        setAlertSucStatus(false);
                    //    }
                    //}

                    setLoading(false);
                }
            });
    }

    //function getJWTToken() {
    //    let token = '';
    //    sessions.map((sn) => { if (sn.key === 'AuthToken') { token = sn.value; } });

    //    let authToken = token;
    //    let accessToken = authToken["Access_Token"];
    //    let tokenType = authToken["Token_Type"];
    //    return tokenType + ' ' + accessToken;
    //}


    const onPageClick = (e) => {
        if (typeof (e.target.text) !== "undefined") {
            setCurPageNo(e.target.text);
            //let apiUrl = process.env.REACT_APP_GET_ENROLLMENT_DATA + 'templateId/null/enrmntId/null/isOwnerOnly/true/pageNo/' + e.target.text;
            //const { loading, data, error } = useHttp({ url: apiUrl })
        }
    }

    const delHandelClick = (enrmntId, templateId) => {
        let inputJson = {
            "enrmntId": enrmntId,
            "templateId": templateId,
            "isDeleted": true
        };
               
        let headers = {
            'Content-Type': 'application/json'
        };

        let userprompt = false;
        userprompt = window.confirm("Do you want to remove the application?");
        if (userprompt) {
            ApiHelper({
                url: process.env.REACT_APP_CREATE_MODIFY_ENROLLMENT_DATA, method: 'POST', headers: headers, body: inputJson, sessions: sessions, dispatch: dispatch
            })
                .then(resposnse => {
                    if (resposnse !== null && resposnse != "undefined") {
                        alert('Application removed successfully.');
                        //history.push('/DashBoard');
                        //setRfrshOnDel({});
                        loadUserTemplate();
                    }
                });
        }
    }

    const handleClick = (enrmntId, templateId, name, templateNm, ownerId) => {

        if (enrmntId !== null && enrmntId !== 'undefined') {
            setState({
                enrmntId: enrmntId,
                templateId: templateId,
                name: name,
                templateNm: templateNm,
                ownerId: ownerId
            })

            setModalShow(true)
        }
    };

    const documenthandleClick = (enrmntId, enrmntStatusId) => {
        history.push({
            pathname: '/Document',
            state: { enrmntId: enrmntId, isProspect: true, loginUsrId: loginUsrId, loginEmail: props.owneremail, loginUsrNm: props.ownername, applicationStatus: enrmntStatusId  }
        });
    };

    const reviewhandleClick = (enrmntId, templateId, name, templateNm, ownerId) => {
        if (enrmntId !== null && enrmntId !== 'undefined') {
            setState({
                enrmntId: enrmntId,
                templateId: templateId,
                name: name,
                templateNm: templateNm,
                ownerId: ownerId
            })
            setReviewModalShow(true);
        }
    }

    const downloadhandleClick = (enrmntId) => {
        if (enrmntId !== null && enrmntId !== 'undefined') {
           
        }
    }




    useEffect(() => {       
        loadUserTemplate();
    }, []);

    if (loading)
        return (<LoaderControl show={loading} />);

    if (data != null) {
        return (
            <>
                <div className="row" >
                    {
                        alertMsg ?
                            <div className="m-t-10" style={{ color: 'rgba(51, 51, 51, 0.9)', fontWeight: 600, fontSize: '16px' }}>
                                <AlertText Message={alertMsg} isSuccess={alertSucStatus} />
                            </div>
                            : ''
                    }

                    <DashBoardCreateApplication showtitle={false} />
                    
                    <div className="m-t-10 m-b-5" style={{ color: 'rgba(51, 51, 51, 0.9)', fontWeight: 600, fontSize: '16px' }}>
                        You applied for {data.length} account(s)
                    </div>

                    {
                        data.map((item, index) => (
                            <div className="col-sm-6" key={item.enrmntId}>
                                <div className="m-b-3 m-t-3 bg-white" key={item.enrmntId}>
                                    <ApplicationCard key={item.enrmntId} title={item.templateNm} enrmntId={item.enrmntId} templateId={item.templateId} ownerId={item.ownerId}
                                        text={item.name} seqno={index + 1} buttontext="Open Application" handleClick={() => handleClick(item.enrmntId, item.templateId, item.name, item.templateNm, item.ownerId)}
                                        reviewhandleClick={() => reviewhandleClick(item.enrmntId, item.templateId, item.name, item.templateNm, item.ownerId)} 
                                        textstatus={item.enrmntStatus} lapse={item.lapse} isdocsignreq={item.isDocSignReq} isdocexported={item.isDocExported}
                                        delHandelClick={() => delHandelClick(item.enrmntId, item.templateId)}
                                        documenthandleClick={() => documenthandleClick(item.enrmntId, item.templateId)}
                                        downloadhandleClick={() => downloadhandleClick(item.enrmntId)} />
                                </div>
                            </div>
                        ))
                    }
                    {/*<div>*/}
                    {/*    <Pagination size="sm" className="pagination justify-content-start">{items}</Pagination>*/}
                    {/*</div>*/}
                </div>
                {
                    modalShow ?
                        <EnrollmentAddEditModal
                            key={state.enrmntId}
                            show={(state.enrmntId > 0 && modalShow ? true : false)}
                            backdrop="static"
                            keyboard={false}
                            onHide={() => setModalShow(false)}
                            title={"Edit your application for opening an account"}
                            templateid={state.templateId}
                            enrmntid={state.enrmntId}
                            name={state.name}
                            templatenm={state.templateNm}
                            ownerid={state.ownerId}
                        />
                        :<div/>
                }
                {
                    reviewModalShow ?
                        <EnrollmentReviewModel
                            key={state.enrmntId + "_" + state.templateId}
                            show={(state.enrmntId > 0 && reviewModalShow ? true : false)}
                            backdrop="static"
                            keyboard={false}
                            onHide={() => setReviewModalShow(false)}
                            title={"Review your application"}
                            templateid={state.templateId}
                            enrmntid={state.enrmntId}
                            name={state.name}
                            templatenm={state.templateNm}
                        />
                        :<div/>
                }
               
            </>
        );
    }
    else {
        return (<LoaderControl show={loading} />);
    }
}
export default DashBoardApplications;