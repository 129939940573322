import React, { useEffect, useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import { IconButton, Box, Typography, Button } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

export function PreviewHtmlModal(props) {
    const [onHide, show] = useState(props);

    const handleOnExitClick = () => {
        props.onHide();
    }

    return (

        <Dialog open={show} onClose={handleOnExitClick} keepMounted fullWidth maxWidth="md">
            <DialogTitle>
                <Typography variant="body1" color="text.primary" sx={{ fontWeight: 500 }}>
                    Preview Template
                </Typography>
            </DialogTitle>
            <DialogContent dividers>
                {
                    props.modalId === 1 || props.modalId === 2 ?
                        <Box sx={{ ml: 3, mr: 3}} >
                            <pre className="prettyprint" style={{ overflow: 'auto' }}
                                dangerouslySetInnerHTML={{
                                    __html: props.htmlcontent
                                }}
                            />
                        </Box>
                        :
                        <Box sx={{ ml: 3, mr: 3, overflow: 'auto'}} >
                            dangerouslySetInnerHTML={{
                                __html: props.htmlcontent
                            }}
                        </Box>
                }
            </DialogContent>
            <DialogActions sx={{ mr: 2 }}>
                <Button onClick={handleOnExitClick}>
                    <Typography variant="body3" color="text.primary" sx={{ fontWeight: 500 }}>
                        Close
                    </Typography>
                </Button>
            </DialogActions>

        </Dialog>
        //<Modal show={show} animation={false} style={{ background: 'red' }}
        //    size="lg"
        //    aria-labelledby="contained-modal-title-vcenter"
        //>
        //    <Modal.Header>
        //        <Modal.Title id="contained-modal-title-vcenter" >
        //            <Typography className="" variant="body1" color="text.primary" sx={{ fontWeight: 500, ml: 2 }}>
        //                Preview Template
        //            </Typography>
        //        </Modal.Title>
        //        <button type="button" className="btn-close m-e-9" data-bs-dismiss="modal" aria-label="Close" onClick={handleOnExitClick}></button>
        //    </Modal.Header>
        //    <Modal.Body>
        //        {props.modalId === 1 || props.modalId === 2 ?
        //            <Box sx={{ ml: 3, mr: 3}} >
        //                <pre className="prettyprint" style={{ overflow: 'auto' }}
        //                    dangerouslySetInnerHTML={{
        //                        __html: props.htmlcontent
        //                    }}
        //                />
        //            </Box>
        //            :
        //            <Box sx={{ ml: 3, mr: 3, overflow: 'auto'}} >
        //                dangerouslySetInnerHTML={{
        //                    __html: props.htmlcontent
        //                }}
        //            </Box>
        //        }
        //    </Modal.Body>
        //    <Modal.Footer>
        //        <Box sx={{ mr: 2 }} >
        //            <Button onClick={handleOnExitClick}>Close</Button>
        //        </Box>
        //    </Modal.Footer>
        //</Modal>
    );
}