import React, { useEffect, useState, useRef } from 'react';
import { Chip, Box, TableContainer, Dialog, Divider, DialogTitle, Stack, DialogContent, DialogActions, Button, Typography, IconButton, Grid, FormControl, FormControlLabel } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import AlertControl from '../components/Controls/AlertControl';
import { useDispatch, useSelector } from 'react-redux';
import { ErrorMessages } from '../common/ErrorMessages';
import { LoadProfilePic } from '../global/Helpers';
import profimg from '../assets/images/profimg.jpg';
import { UploadUserFiles } from '../global/APIUtils';
import LoadingOverlay from 'react-loading-overlay';
import RiseLoader from 'react-spinners/RiseLoader'
import { APICall, WriteToActivityLog } from '../global/Api';
import { GetLoggedInUserEmail } from '../global/APIUtils';
import { EnrollmentStatus, ActivityLogEvent } from '../common/AOBEnum';
import CheckIcon from '@mui/icons-material/Check';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import Checkbox, { checkboxClasses } from "@mui/material/Checkbox";

const DocuSignMailDlg = (props) => {

    const { onClose, open, onResend, ...other } = props;
    const [errorMsg, setErrorMsg] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [enrmntid, setEnrmntid] = useState(props.enrmntid || -1);
    const [ownerId, setOwnerId] = useState(-1);
    const [ownerName, setOwnerName] = useState('');
    const [ownerEmail, setOwnerEmail] = useState('');
    const [isJntAcct, setIsJntAcct] = React.useState(false);
    const [jointOwnerName, setJointOwnerName] = useState('');
    const [jointOwnerEmail, setJointOwnerEmail] = useState('');
    const [successMsg, setSuccessMsg] = useState('');
    const [attachmentid, setAttachmentid] = useState(props.attachmentid || -1);
    const [idvRequired, setIdvRequired] = React.useState('none');
    const [initialReq, setInitialReq] = React.useState(false);
    const [isInitialReq, setIsInitialReq] = React.useState('None');

    const [idvDefault, setIdvDefault] = React.useState(false);
    const [jtOwnerIdvDefault, setJtOwnerIdvDefault] = React.useState(false);

    const [disIV, setDisIV] = React.useState(true);
    const [disInitReq, setDisInitReq] = React.useState(true);

    const [userId, setUserId] = useState(props.userId);
    const [sessionId, setSessionId] = useState(props.sessionId);

    const [bankName, setBankName] = React.useState('');
    const [signerList, setSignerList] = useState(null);
    const [selectedSigner, setSelectedSigner] = useState(-1);
    const [bankSignReq, setBankSignReq] = useState('none');
    const [chkBankSignReq, setChkBankSignReq] = useState(false);
    const [disChkBankSignReq, setDisChkBankSignReq] = useState(false);
    const [disSignerList, setDisSignerList] = useState(true);

    const [docuSignEmailSts, setDocuSignEmailSts] = useState(null);
    const [selectedFiles, setSelectedFiles] = useState([]);
    const [bankRepIdvDefault, setBankRepIdvDefault] = React.useState(false);

    const handleClose = (event) => {
        onClose();
    };

    const handleChkBankSignReqChange = (event) => {
        setChkBankSignReq(event.target.checked);
        setDisSignerList(!event.target.checked);
    };

    const handleSignerChange = (event) => {
        setSelectedSigner(event.target.value);
    };

    const handleBankRepIDVReqCheckChange = (event) => {
        setBankRepIdvDefault(event.target.checked);
    };

    const loadDetails = async () => {

        try {

            setIsLoading(true);

            setBankName(sessionStorage.getItem('BankName'));

            await WriteToActivityLog(ActivityLogEvent.DocuSign, "'Resend DocuSign Mail' dialog opened. Application name '" + props.enrmntNm + "'.", userId, sessionId);

            let apiUrl = new URL(
                process.env.REACT_APP_GET_DOCUSIGN_CONFIG,
                process.env.REACT_APP_BASE_URL,
            );

            let bSignReq = false;
            let response = await APICall({ url: apiUrl.href, method: 'GET', contentType: 'application/json', responseType: '' }, userId, sessionId);
            if (response !== null && response !== undefined) {
                if (response["data"] && response["data"].length > 0) {
                    setIdvRequired(response.data[0].IDVReq != undefined && response.data[0].IDVReq ? 'block' : 'none');
                    bSignReq = response.data[0]?.BankSignRequired ?? false;
                    setBankSignReq(bSignReq ? 'block' : 'none');
                }
            }

            let allSigners = null
            if (bSignReq) {
                apiUrl = new URL(
                    process.env.REACT_APP_GET_DOCUSIGN_SIGNERS_ONLY,
                    process.env.REACT_APP_BASE_URL,
                );

                response = await APICall({ url: apiUrl.href, method: 'GET', contentType: 'application/json', responseType: '' }, userId, sessionId);

                if (response !== undefined && response !== null) {
                    allSigners = response["data"];
                    setSignerList(response["data"]);
                    let found = response["data"].find(element => element.ContactId == userId);
                    if (found) {
                        setSelectedSigner(userId);
                    }
                }
            }

            await GetDocuSignStatus(allSigners);

            apiUrl = new URL(
                process.env.REACT_APP_GET_ENROLLMENT_BY_ID,
                process.env.REACT_APP_BASE_URL,
            );
            apiUrl.searchParams.set('enrmntId', `${parseInt(enrmntid)}`);

            response = await APICall({ url: apiUrl.href, method: 'GET', contentType: 'application/json', responseType: '' }, userId, sessionId);
            if (response !== null && response !== undefined) {
                if (response["data"] && response["data"].length > 0) {
                    setOwnerId(response.data[0].ownerId);
                    setOwnerName(response.data[0].ownerName);
                    setOwnerEmail(response.data[0].ownerEmail);
                    setIsJntAcct(response.data[0].IsJointAccount);
                    setJointOwnerName(response.data[0].JointOwnerName);
                    setJointOwnerEmail(response.data[0].JointOwnerEmail);
                }
                else {

                    let apiErrors = response["Errors"];

                    if (apiErrors != undefined && apiErrors.length > 0) {
                        setErrorMsg(apiErrors[0].Message);
                    }
                    else {
                        setErrorMsg(ErrorMessages.UnknownError);
                    }
                }
            }
        }
        catch (err) {
            setErrorMsg(ErrorMessages.UnknownError);
        }
        finally {
            setIsLoading(false);
        }
    };

    const GetDocuSignStatus = async (allSigners) => {

        let apiUrl = new URL(
            process.env.REACT_APP_GET_DOCUSIGN_STATUS,
            process.env.REACT_APP_BASE_URL,
        );

        apiUrl.searchParams.set('enrmntId', `${parseInt(enrmntid)}`);
        apiUrl.searchParams.set('attachmentId', `${parseInt(attachmentid)}`);

        let response = await APICall({ url: apiUrl.href, method: 'GET', contentType: 'application/json', responseType: '' }, userId, sessionId);

        if (response !== null && response !== undefined) {

            if (response["data"] && response["data"].length > 0) {

                let files = [];

                for (let i = 0; i < response["data"].length; i++) {
                    if (response["data"][i].SignStatus !== 'completed') {
                        files.push(response["data"][i].AttachementId);
                    }
                }
                setIdvDefault(response.data[0].Signer1IVReq);
                setJtOwnerIdvDefault(response.data[0].Signer2IVReq);

                setInitialReq(response.data[0].InitialRequired != undefined && response.data[0].InitialRequired ? true : false);
                setIsInitialReq(response.data[0].InitialRequired != undefined && response.data[0].InitialRequired ? 'block' : 'none')

                if (response.data[0].EnvelopId == '') {
                    setDisIV(false);
                    setDisInitReq(false);
                }

                if (response.data[0].Signer3Email !== '') {

                    if (allSigners != null) {
                        let found = allSigners.find(element => element.Email == response.data[0].Signer3Email);
                        if (found != null && found != undefined) {
                            setSelectedSigner(found.ContactId)
                        }
                    }

                    setChkBankSignReq(true);
                    setDisChkBankSignReq(true);
                    setDisSignerList(true);
                    setBankRepIdvDefault(response.data[0].Signer3IVReq);
                }

                setSelectedFiles(files);
                setDocuSignEmailSts(response["data"]);
            }
            else {

                let apiErrors = response["Errors"];

                if (apiErrors != undefined && apiErrors.length > 0) {
                    setErrorMsg(apiErrors[0].Message);
                }
                else {
                    setDocuSignEmailSts(null);
                }
            }
        }
    }

    const handleChange = (event, attachmentId) => {

        var files_array = [...selectedFiles];
        if (event.target.checked) {
            if (!files_array.includes(attachmentId)) {
                files_array = [...selectedFiles, attachmentId];
            }
        } else {
            files_array.splice(selectedFiles.indexOf(attachmentId), 1);
        }
        setSelectedFiles(files_array);
    };

    const ResendMail = async (event) => {

        try {

            if (selectedFiles.length == 0) {
                setErrorMsg('Signing is completed by the signer(s)');
                return;
            }

            let bankSignerName = '';
            let bankSignerEmail = '';

            if (chkBankSignReq) {
                if (selectedSigner === "-1") {
                    setErrorMsg("Please chosse signer of " + bankName + " for DocuSign");
                    return;
                }
                let found = signerList.find(element => element.ContactId == selectedSigner);
                if (found == null || found == undefined) {
                    setErrorMsg("Please chosse signer of " + bankName + " for DocuSign");
                    return;
                }

                bankSignerName = found.FirstName + ' ' + found.LastName;
                bankSignerEmail = found.Email;
            }

            let inputJson = {
                EnrmntId: enrmntid,
                SignerUserId: ownerId,
                SignerName: ownerName,
                SignerEmail: ownerEmail,
                IsJointAccount: isJntAcct,
                JointSignerName: jointOwnerName,
                JointSignerEmail: jointOwnerEmail,
                IdArray: selectedFiles,
                Signer1IDVReq: idvDefault,
                Signer2IDVReq: jtOwnerIdvDefault,
                InitialRequired: initialReq,
                IsBankSignReq: chkBankSignReq,
                BankSignerName: bankSignerName,
                BankSignerEmail: bankSignerEmail,
                Signer3IDVReq: bankRepIdvDefault,
            };

            setIsLoading(true);

            let apiUrl = new URL(
                process.env.REACT_APP_RESEND_DOCUSIGN_MAIL,
                process.env.REACT_APP_BASE_URL,
            );


            let response = await APICall({ url: apiUrl.href, method: 'POST', payload: inputJson, contentType: 'application/json', responseType: '' }, userId, sessionId);

            if (response !== null && response !== undefined) {

                if (response.Errors.length > 0) {
                    setErrorMsg(response.Errors[0].Message);
                }
                else if (response.meta.DocuSignMailSent === "1") {

                    await WriteToActivityLog(ActivityLogEvent.DocuSign, "DocuSign email re-sent. Application name '" + props.enrmntNm + "'. " + (isJntAcct ? "Signer 1: " : "Signer: ") + ownerEmail + (isJntAcct ? (". Signer 2: " + jointOwnerEmail) : "") + ". Identity verification required" + (isJntAcct ? "(Signer1): " : ": ") + (idvDefault ? "Yes. " : "No. ") + (isJntAcct ? ("Identity verification required(Signer2): " + (jtOwnerIdvDefault ? "Yes. " : "No. ")) : "") + "Requires Initial: " + (initialReq ? "Yes" : "No"), userId, sessionId);
                    await GetDocuSignStatus(signerList);
                    onResend('DocuSign email sent to the recipient(s) successfully.', enrmntid);
                }
                else if (response.meta.DocuSignCompleted === "1") {
                    setSuccessMsg('Docusign is completed for the file(s). No email is sent.');
                }
            }
        }
        catch (err) {
            setErrorMsg(ErrorMessages.UnknownError);
            setIsLoading(false);
        }
        finally {
            setIsLoading(false);
        }
    }

    const handleIDVReqCheckChange = (event) => {
        setIdvDefault(event.target.checked);
    };

    const handleJtOwnerIDVReqCheckChange = (event) => {
        setJtOwnerIdvDefault(event.target.checked);
    };

    const handleInitialReqChange = (event) => {
        setInitialReq(event.target.checked);
    };

    useEffect(() => {
        loadDetails();
    }, []);

    return (
        <Dialog open={open} keepMounted scroll="paper" sx={{
            "& .MuiDialog-container": {
                "& .MuiPaper-root": {
                    width: "100%",
                    maxWidth: "900px",
                },
            },
        }} >
            <DialogTitle sx={{}}>
                <Typography variant="body1" color="text.primary" sx={{ fontWeight: 500 }}>
                    Resend DocuSign Mail
                </Typography>

                    
                    {/*<IconButton*/}
                    {/*    aria-label="close"*/}
                    {/*    onClick={handleClose}*/}
                    {/*    sx={{*/}
                    {/*        position: 'absolute',*/}
                    {/*        right: 8,*/}
                    {/*        top: 8,*/}
                    {/*        color: (theme) => theme.palette.grey[500],*/}
                    {/*    }}*/}
                    {/*>*/}
                    {/*    <CloseIcon />*/}
                    {/*</IconButton>*/}
                </DialogTitle>
            <DialogContent dividers={true}>
                <LoadingOverlay active={isLoading} spinner={<RiseLoader color={'#1D50AF'} />} >
                    <AlertControl Message={successMsg} severity="success" color="success" icon={true} />
                    <AlertControl Message={errorMsg} severity="error" color="error" icon={true} />
                    <Box sx={{ pl: 2, pr: 2, pb: 1 }}>
                        <Grid container>
                        <Grid item md={11} sx={{}}>
                            <Box sx={{pb: 2}}>
                                <Typography variant="body1" color="text.primary" sx={{ fontWeight: 400 }}>DocuSign email will be sent to the signer(s) for the following file(s).</Typography>
                            </Box>
                        </Grid>
                        <Grid item md={11} sx={{}}>
                            {
                                docuSignEmailSts ?
                                    <Table aria-label="simple table" sx={{ border: '0px solid #e0e0e0', pt: 2, pl: 1, Overflow: 'scroll' }}>
                                        <TableBody>
                                            <TableRow key={1} sx={{}}>
                                                <TableCell scope="row" sx={{ width: '150px', pt: 1, pb: 1, pl: 2, border: '1px solid #cdcfd2' }}>
                                                        <Typography className="" variant="body1" color="text.primary" sx={{ fontWeight: 500 }}>
                                                        Signer1:
                                                    </Typography>
                                                </TableCell>
                                                <TableCell scope="row" sx={{ pt: 0, pb: 0, pl: 1, border: '1px solid #cdcfd2' }}>
                                                        <Typography className="" variant="body1" color="text.primary" sx={{ fontWeight: 400 }}>
                                                        {ownerEmail}
                                                    </Typography>
                                                </TableCell>
                                            </TableRow>
                                            <TableRow key={2} sx={{}}>
                                                <TableCell colSpan="2" scope="row" sx={{ width: '150px', pt: 0, pb: 0, pl: 2, border: '1px solid #cdcfd2' }}>
                                                    <Box sx={{ p: 0, display: idvRequired }}>
                                                        <FormControlLabel
                                                            label={<Typography sx={{ fontWeight: 400 }} variant="body1" color="text.Secondary">Requires Identity Verification for signer1</Typography>}
                                                                control={<Checkbox sx={{
                                                                    [`&, &.${checkboxClasses.checked}`]: {
                                                                        color: 'Check.main',
                                                                    },
                                                                }} disabled={disIV} checked={idvDefault} onChange={handleIDVReqCheckChange} />}
                                                        />
                                                    </Box>
                                                </TableCell>
                                            </TableRow>
                                            {
                                                isJntAcct ?
                                                    <React.Fragment>
                                                        <TableRow key={3} sx={{}}>
                                                            <TableCell scope="row" sx={{ width: '100px', pt: 1, pb: 1, pl: 2, border: '1px solid #cdcfd2' }}>
                                                                    <Typography className="" variant="body1" color="text.primary" sx={{ fontWeight: 500 }}>
                                                                    Signer2:
                                                                </Typography>
                                                            </TableCell>
                                                            <TableCell scope="row" sx={{ pt: 0, pb: 0, pl: 1, border: '1px solid #cdcfd2' }}>
                                                                    <Typography className="" variant="body1" color="text.primary" sx={{ fontWeight: 400 }}>
                                                                    {jointOwnerEmail}
                                                                </Typography>
                                                            </TableCell>
                                                        </TableRow>
                                                        <TableRow key={4} sx={{}}>
                                                            <TableCell colSpan="2" scope="row" sx={{ width: '150px', pt: 0, pb: 0, pl: 2, border: '1px solid #cdcfd2' }}>
                                                                <Box sx={{ p: 0, display: idvRequired }}>
                                                                    <FormControlLabel
                                                                        label={<Typography sx={{ fontWeight: 400 }} variant="body1" color="text.Secondary">Requires Identity Verification for signer2</Typography>}
                                                                            control={<Checkbox sx={{
                                                                                [`&, &.${checkboxClasses.checked}`]: {
                                                                                    color: 'Check.main',
                                                                                },
                                                                            }} disabled={disIV} checked={jtOwnerIdvDefault} onChange={handleJtOwnerIDVReqCheckChange} />}
                                                                    />
                                                                </Box>
                                                            </TableCell>
                                                        </TableRow>
                                                    </React.Fragment>
                                                    : ''
                                            }
                                        </TableBody>
                                    </Table>
                                    : ''
                            }
                        </Grid>
                        <Grid item md={12} sx={{}}>
                            <Box sx={{ display: bankSignReq, mb: 3, pt: 2 }}>
                                <Divider light />
                                <Box sx={{ p: 0, pl: 0, pt: 1 }}>
                                    <FormControlLabel
                                        label={<Typography sx={{ fontWeight: 400 }} variant="body1" color="text.Secondary">Mail will be sent to the following signer of {bankName} for DocuSign</Typography>}
                                            control={<Checkbox sx={{
                                                [`&, &.${checkboxClasses.checked}`]: {
                                                    color: 'Check.main',
                                                },
                                            }} checked={chkBankSignReq} disabled={disChkBankSignReq} onChange={handleChkBankSignReqChange} />}
                                    />
                                </Box>
                                <Stack direction="row">
                                    <Box sx={{}}>
                                            <select id="SignerList" disabled={disSignerList} className="form-select" name="SignerList" value={selectedSigner} onChange={handleSignerChange} placeholder="Title">
                                            <option key={-1} value={-1}>Select</option>
                                            {
                                                signerList && signerList.map((e) => (
                                                    <option key={e.ContactId} value={e.ContactId}>{e.FirstName + ' ' + e.LastName + ' (' + e.Email + ' )'}</option>
                                                ))
                                            }
                                        </select>
                                    </Box>
                                    <Box sx={{ p: 0, pl: 4 }}>
                                            <FormControlLabel disabled={disSignerList}
                                            label={<Typography sx={{ fontWeight: 400 }} variant="body1" color="text.Secondary">Requires Identity Verification</Typography>}
                                                control={<Checkbox sx={{
                                                    [`&, &.${checkboxClasses.checked}`]: {
                                                        color: 'Check.main',
                                                    },
                                                }} checked={bankRepIdvDefault} onChange={handleBankRepIDVReqCheckChange} />}
                                        />
                                    </Box>
                                </Stack>
                            </Box>
                        </Grid>
                        <Grid item md={11} sx={{}}>
                            {
                                docuSignEmailSts ?
                                    
                                        <Table aria-label="simple table" sx={{ border: '0px solid #e0e0e0', pt: 2, pl: 1, Overflow: 'scroll' }}>
                                            <TableBody>{ }
                                                {
                                                    docuSignEmailSts.map((item, index) => (
                                                        <React.Fragment key={index} >
                                                            <TableRow sx={{}}>
                                                                <TableCell colSpan="2" scope="row" sx={{ pt: 1, pb: 1, pl: 0, borderBottom: '1px solid #cdcfd2' }}>
                                                                        {
                                                                            item.SignStatus === 'completed' ?
                                                                                <Typography className="" variant="body1" color="text.primary" sx={{ fontWeight: 500 }}>
                                                                                    {item.FileName} (sign completed)
                                                                                </Typography>
                                                                            :
                                                                            <Box>
                                                                                <FormControlLabel sx={{ pb: 1 }}
                                                                                    label={<Typography sx={{ fontWeight: 400 }} variant="body1" color="text.Secondary">{item.FileName}</Typography>}
                                                                                    control={<Checkbox sx={{
                                                                                        [`&, &.${checkboxClasses.checked}`]: {
                                                                                            color: 'Check.main',
                                                                                        },
                                                                                    }} defaultChecked onChange={(e) => handleChange(e, item.AttachementId)} />}
                                                                                />
                                                                                <Chip sx={{ ml: 1 }} label={item.SignStatus} />
                                                                            </Box>
                                                                        }
                                                                    </TableCell>
                                                                </TableRow>                                                               
                                                                <TableRow key={parseInt(item.AttachementId) + 15} sx={{}}>
                                                                        <TableCell scope="row" sx={{ width: '250px', pt: 0, pb: 0, pl: 2, border: '1px solid #cdcfd2' }}>
                                                                    <Typography className="" variant="body1" color="text.primary" sx={{ fontWeight: 400 }}>
                                                                                DocuSign Status (Signer1):
                                                                            </Typography>
                                                                        </TableCell>
                                                                        <TableCell scope="row" sx={{ pt: 0, pb: 0, pl: 1, border: '1px solid #cdcfd2' }}>
                                                                    <Typography className="" variant="body1" color="text.primary" sx={{ fontWeight: 400 }}>
                                                                                {item.Signer1Status}
                                                                            </Typography>
                                                                        </TableCell>
                                                                </TableRow>
                                                            <TableRow key={parseInt(item.AttachementId) + 4} sx={{}}>
                                                                    <TableCell scope="row" sx={{ pt: 0, pb: 0, pl: 2, border: '1px solid #cdcfd2' }}>
                                                                    <Typography className="" variant="body1" color="text.primary" sx={{ fontWeight: 400 }}>
                                                                            DocuSign Mail (Signer1):
                                                                        </Typography>
                                                                    </TableCell>
                                                                    <TableCell scope="row" sx={{ pt: 0, pb: 0, pl: 1, border: '1px solid #cdcfd2' }}>
                                                                    <Typography className="" variant="body1" color="text.primary" sx={{ fontWeight: 400 }}>
                                                                        {item.Signer1EmailSentAt === 'not sent' || item.Signer1EmailSentAt === '' ? 'not sent' : 'Last sent on ' + item.Signer1EmailSentAt}
                                                                        </Typography>
                                                                    </TableCell>
                                                                </TableRow>
                                                                    {
                                                                        item.Signer2Email !== '' ?
                                                                        <React.Fragment>
                                                                        <TableRow sx={{}} key={parseInt(item.AttachementId) + 6} >
                                                                                <TableCell scope="row" sx={{ pt: 0, pb: 0, pl: 2, border: '1px solid #cdcfd2' }}>
                                                                                <Typography className="" variant="body1" color="text.primary" sx={{ fontWeight: 400 }}>
                                                                                        DocuSign Status (Signer2):
                                                                                    </Typography>
                                                                                </TableCell>
                                                                                <TableCell scope="row" sx={{ pt: 0, pb: 0, pl: 1, border: '1px solid #cdcfd2' }}>
                                                                                <Typography className="" variant="body1" color="text.primary" sx={{ fontWeight: 400 }}>
                                                                                        {item.Signer2Status}
                                                                                    </Typography>
                                                                                </TableCell>
                                                                            </TableRow>
                                                                        <TableRow sx={{}} key={parseInt(item.AttachementId) + 7} >
                                                                                <TableCell scope="row" sx={{ pt: 0, pb: 0, pl: 2, border: '1px solid #cdcfd2' }}>
                                                                                <Typography className="" variant="body1" color="text.primary" sx={{ fontWeight: 400 }}>
                                                                                        DocuSign Mail (Signer2):
                                                                                    </Typography>
                                                                                </TableCell>
                                                                                <TableCell scope="row" sx={{ pt: 0, pb: 0, pl: 1, border: '1px solid #cdcfd2' }}>
                                                                                <Typography className="" variant="body1" color="text.primary" sx={{ fontWeight: 400 }}>
                                                                                    {item.Signer2EmailSentAt === 'not sent' || item.Signer2EmailSentAt === '' ? 'not sent' : 'Last sent on ' + item.Signer2EmailSentAt}
                                                                                    </Typography>
                                                                                </TableCell>
                                                                            </TableRow>
                                                                        </React.Fragment>
                                                                            : ''
                                                            }
                                                            {
                                                                item.Signer3Email !== '' ?
                                                                    <React.Fragment>
                                                                        <TableRow sx={{}} key={parseInt(item.AttachementId) + 6} >
                                                                            <TableCell scope="row" sx={{ pt: 0, pb: 0, pl: 2, border: '1px solid #cdcfd2' }}>
                                                                                <Typography className="" variant="body1" color="text.primary" sx={{ fontWeight: 400 }}>
                                                                                    DocuSign Status (Signer{item.Signer2Email !== '' ? 3 : 2}):
                                                                                </Typography>
                                                                            </TableCell>
                                                                            <TableCell scope="row" sx={{ pt: 0, pb: 0, pl: 1, border: '1px solid #cdcfd2' }}>
                                                                                <Typography className="" variant="body1" color="text.primary" sx={{ fontWeight: 400 }}>
                                                                                    {item.Signer3Status}
                                                                                </Typography>
                                                                            </TableCell>
                                                                        </TableRow>
                                                                        <TableRow sx={{}} key={parseInt(item.AttachementId) + 7} >
                                                                            <TableCell scope="row" sx={{ pt: 0, pb: 0, pl: 2, border: '1px solid #cdcfd2' }}>
                                                                                <Typography className="" variant="body1" color="text.primary" sx={{ fontWeight: 400 }}>
                                                                                    DocuSign Mail (Signer{item.Signer2Email !== '' ? 3 : 2}):
                                                                                </Typography>
                                                                            </TableCell>
                                                                            <TableCell scope="row" sx={{ pt: 0, pb: 0, pl: 1, border: '1px solid #cdcfd2' }}>
                                                                                <Typography className="" variant="body1" color="text.primary" sx={{ fontWeight: 400 }}>
                                                                                    {item.Signer3EmailSentAt === 'not sent' || item.Signer3EmailSentAt === '' ? item.Signer3EmailSentAt : 'Last sent on ' + item.Signer3EmailSentAt}
                                                                                </Typography>
                                                                            </TableCell>
                                                                        </TableRow>
                                                                    </React.Fragment>
                                                                    : ''
                                                            }
                                                            </React.Fragment>
                                                    ))
                                                }
                                            </TableBody>
                                        </Table>
                                    : ''
                            }
                        </Grid>
                        <Grid item md={11} sx={{}}>
                            <Box sx={{ display: isInitialReq, mt: 2 }}>
                                    <FormControlLabel sx={{ pb: 1 }} disabled={disInitReq}
                                        label={<Typography sx={{ fontWeight: 400 }} variant="body1" color="text.Secondary">Requires Initial</Typography>}
                                        control={<Checkbox sx={{
                                            [`&, &.${checkboxClasses.checked}`]: {
                                                color: 'Check.main',
                                            },
                                        }} checked={initialReq} onChange={handleInitialReqChange} />}
                                />
                            </Box>
                        </Grid>
                        </Grid>
                    </Box>
                </LoadingOverlay>

                </DialogContent>
            <DialogActions sx={{ mr: 2 }}>
                <Button onClick={ResendMail} variant="contained" sx={{ mt: 1, mb: 1, borderRadius: '20px', padding: '0.25rem 2.2rem', mr: 1.5, textTransform: 'none' }}>
                    Resend
                </Button>
                <Button autoFocus onClick={handleClose} variant="contained" sx={{ mt: 1, mb: 1, borderRadius: '20px', padding: '0.25rem 2.5rem', mr: 1.5, textTransform: 'none' }}>
                    Ok
                </Button>
            </DialogActions>
        </Dialog>
    );
}

export default DocuSignMailDlg;
