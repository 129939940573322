import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import { Box, Button, Grid, IconButton, Stack, Table, Divider, TableBody, TableCell, TableContainer, TableRow, Tooltip, Typography } from '@mui/material';
import { QueryClient, QueryClientProvider, useInfiniteQuery } from '@tanstack/react-query';
import { Virtualizer } from '@tanstack/react-virtual';
import { jsPDF } from 'jspdf'; //or use your library of choice here
import autoTable from 'jspdf-autotable';
import MaterialReactTable from 'material-react-table';
import moment from "moment";
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { ErrorMessages } from '../../common/ErrorMessages';
import { APICall, WriteToActivityLog } from '../../global/Api';
import AlertControl from '../Controls/AlertControl';
import OverlaySpinner from '../Controls/OverlaySpinner';
import dayjs from 'dayjs';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import { ActivityLogEvent } from '../../common/AOBEnum';
import Checkbox, { checkboxClasses } from "@mui/material/Checkbox";

const TemplateHistories = (props) => {

    const [isDataLoading, setIsDataLoading] = useState(false);
    const [rowSelection, setRowSelection] = useState({});
    const [userId, setUserID] = useState(props.userId || -1);
    const [sessionId, setSessionId] = useState(props.sessionId || '');
    const [searchText, setSearchText] = useState('');
    const [fromDate, setFromDate] = useState(new Date(new Date().setDate(new Date().getDate() - 180)).toLocaleDateString('en-CA'));
    const [toDate, setToDate] = useState(new Date().toLocaleDateString('en-CA'));
    const [errorMsg, setErrorMsg] = useState('');
    const [dispArrow, setDispArrow] = useState('none');
    const [actualTotalFetched, setActualTotalFetched] = useState(0);
    const [totalDBRowCount, setTotalDBRowCount] = useState(0);
    const [disFromDate, setDisFromDate] = useState(false);
    const [disToDate, setDisToDate] = useState(false);
    const [ignoreDate, setIgnoreDate] = useState(false);

    const [templateName, setTemplateName] = useState(null);
    const [auditEvent, setAuditEvent] = useState(null);
    const [changedOn, setChangedOn] = useState(null);
    const [changedBy, setChangedBy] = useState(null);
    const [auditItem, setAuditItem] = useState(null);
    const [oldValue, setOldValue] = useState(null);
    const [newValue, setNewValue] = useState(null);

    const today = dayjs();

    const columns = [
        {
            accessorKey: 'TemplateName',
            header: 'Template Name',
            minSize: 20,
            maxSize: 200,
            size: 200,
            muiTableBodyCellProps: ({ cell }) => ({
                sx: (theme) => ({
                    borderLeft: '1px #BBBFBF solid',
                    color: 'text.primary',
                    fontSize: theme.typography.body1,
                    borderBottom: '1px #BBBFBF solid',
                    pl: 2, fontWeight: 400, pt: 1, pb: 1,
                }),
            }),
            muiTableHeadCellProps: ({ cell }) => ({
                sx: (theme) => ({
                    borderLeft: '1px #BBBFBF solid',
                    color: 'text.primary',
                    fontSize: theme.typography.body2,
                    borderTop: '1px #BBBFBF solid',
                    borderBottom: '1px #BBBFBF solid',
                    fontWeight: 400,
                    pl: 2, pr: 2,
                    pt: 1, pb: 1,
                }),
            }),
        },
        {
            accessorKey: 'ChangedOn',
            header: 'Changed On',
            size: 180
        },
        {
            accessorKey: 'ChangeType',
            header: 'Event',
            size: 100
        },
        {
            accessorKey: 'ChangedBy',
            header: 'Changed By',
            size: 300
        },
        {
            accessorKey: 'Item',
            header: 'Item',
            size: 230
        },
        {
            accessorKey: 'OldValue',
            header: 'Old Value',
            size: 230
        },
        {
            accessorKey: 'NewValue',
            header: 'New Value',
            size: 230
        },

    ];

    const fetchSize = 50;

    const tableContainerRef = useRef(null); //we can get access to the underlying TableContainer element and react to its scroll events
    const virtualizerInstanceRef = useRef < Virtualizer > null; //we can get access to the underlying Virtualizer instance and call its scrollToIndex method

    const [columnFilters, setColumnFilters] = useState([]);
    const [globalFilter, setGlobalFilter] = useState();
    const [sorting, setSorting] = useState([]);

    const { data, fetchNextPage, isError, isFetching, isLoading, refetch } =
        useInfiniteQuery(
            ['table-data', columnFilters, globalFilter, sorting],
            async ({ pageParam = 0 }) => {

                return getHistoryData(pageParam, true);

            },
            {
                getNextPageParam: (_lastGroup, groups) => groups.length,
                keepPreviousData: true,
                refetchOnWindowFocus: false,
            },
        );

    const flatData = useMemo(
        () => data?.pages.flatMap((page) => page.data) ?? [],
        [data],
    );

    //const totalDBRowCount = data?.pages?.[0]?.meta?.totalRowCount ?? 0;
    const totalFetched = actualTotalFetched;
    const fetchMoreOnBottomReached = useCallback(
        (containerRefElement) => {
            if (containerRefElement) {
                const { scrollHeight, scrollTop, clientHeight } = containerRefElement;
                if (
                    scrollHeight - scrollTop - clientHeight < 400 &&
                    !isFetching &&
                    totalFetched < totalDBRowCount
                ) {
                    fetchNextPage();
                }
            }
        },
        [fetchNextPage, isFetching, totalFetched, totalDBRowCount],
    );

    useEffect(() => {
        loadForm();

    }, []);

    const loadForm = async () => {
        await WriteToActivityLog(ActivityLogEvent.AuditTrail, "Viewed 'Template - Change History' page.", userId, sessionId);
    };

    const getHistoryData = async (pageParam, paginate) => {

        const apiUrl = new URL(
            process.env.REACT_APP_GET_TEMPLATE_HISTORY,
            process.env.REACT_APP_BASE_URL,
        );

        apiUrl.searchParams.set('start', `${pageParam * fetchSize}`);
        apiUrl.searchParams.set('size', `${fetchSize}`);

        var date1 = fromDate !== null ? moment(fromDate, 'YYYY-MM-DD') : null;
        var date2 = toDate !== null ? moment(toDate, 'YYYY-MM-DD') : null;

        if (!ignoreDate) {
            apiUrl.searchParams.set('startTime', date1 !== null ? date1.format('MM/DD/YYYY') : ``);
            apiUrl.searchParams.set('endTime', date2 !== null ? date2.format('MM/DD/YYYY') : ``);
        }

        apiUrl.searchParams.set('globalFilter', `${searchText}`);
        apiUrl.searchParams.set('paginate', `${paginate}`);

        let response = await APICall({ url: apiUrl.href, method: 'GET', contentType: 'application/json', responseType: '' }, userId, sessionId);

        if (response !== null && response !== undefined) {

            setTotalDBRowCount(parseInt(response.meta.totalRowCount));
            setActualTotalFetched(parseInt(pageParam) * parseInt(fetchSize) + parseInt(response.meta.actualRows));

            if (parseInt(response.meta.actualRows) < fetchSize) {
                setDispArrow('none');
            }
            else {
                setDispArrow('inline-block');
            }

            return response;
        }
        else {
            throw Error(ErrorMessages.UnknownError);
        }
    };

    useEffect(() => {

        if (virtualizerInstanceRef.current) {
            virtualizerInstanceRef.current.scrollToIndex(0);
        }
    }, [sorting, columnFilters, globalFilter]);

    useEffect(() => {

        fetchMoreOnBottomReached(tableContainerRef.current);
    }, [fetchMoreOnBottomReached]);

    useEffect(() => {

        //setFromDate(new Date(new Date().setDate(new Date().getDate() - 2)).toLocaleDateString('en-CA'));
        //setFromDate(new Date());
        //setFromDate(new Date().toLocaleDateString('en-CA'));

    }, []);

    const FindClick = async (event) => {

        if (!ignoreDate) {
            if (fromDate == '') {
                setErrorMsg('Invalid from date');
                return;
            }

            if (toDate == '') {
                setErrorMsg('Invalid to date');
                return;
            }

            let date1 = fromDate !== '' ? moment(fromDate, 'YYYY-MM-DD') : null;
            let date2 = toDate !== '' ? moment(toDate, 'YYYY-MM-DD') : new Date().setHours(0, 0, 0, 0);

            if (date1 > date2) {
                setErrorMsg('The To Date must be greater than the From Date');
                return;
            }
        }

        setErrorMsg('');
        setTemplateName('');
        setAuditEvent('');
        setChangedOn('');
        setChangedBy('');
        setAuditItem('');
        setOldValue('');
        setNewValue('');

        refetch();

        await WriteToActivityLog(ActivityLogEvent.AuditTrail, "Searched 'Template - Change History' list.", userId, sessionId);

    };

    const HandleRowClick = async (row) => {
        setTemplateName(row.original.TemplateName);
        setAuditEvent(row.original.ChangeType);
        setChangedOn(row.original.ChangedOn);
        setChangedBy(row.original.ChangedBy);
        setAuditItem(row.original.Item);
        setOldValue(row.original.OldValue);
        setNewValue(row.original.NewValue);
    }

    const handleSearchTextChange = (event) => {
        setSearchText(event.target.value);
    };

    const handleStartDateChange = (event) => {
        setFromDate(event.target.value);
    };

    const handleEndDateChange = (event) => {
        setToDate(event.target.value);
    };

    const exportToPDF = async () => {

        try {

            let msg = 'You can export a maximum of 180 days of historical data or search history data by template name, template description';

            if (ignoreDate) {
                setErrorMsg(msg);
                return;
            }

            if (fromDate == '') {
                setErrorMsg('Invalid from date');
                return;
            }

            if (toDate == '') {
                setErrorMsg('Invalid to date');
                return;
            }

            let date1 = fromDate !== '' ? moment(fromDate, 'YYYY-MM-DD') : null;
            let date2 = toDate !== '' ? moment(toDate, 'YYYY-MM-DD') : new Date().setHours(0, 0, 0, 0);

            if (date1 > date2) {
                setErrorMsg('The To Date must be greater than the From Date');
                return;
            }

            let diffDays;
            let Difference_In_Days;

            if (searchText.length == 0) {

                if (date1 == null) {
                    setErrorMsg(msg);
                    return;
                }

                diffDays = date2 - date1;
                Difference_In_Days = Math.round(diffDays / (1000 * 3600 * 24));

                if (Difference_In_Days > 180) {
                    setErrorMsg(msg);
                    return;
                }
            }

            setIsDataLoading(true);

            setErrorMsg('');

            const doc = new jsPDF({
                orientation: "landscape",
                unit: 'pt',
                format: 'a4'
            });
            //doc.text("Audit Trail Report - User", 5, 10);
            const tableData = await getHistoryData(0, false);;

            if (tableData.data.length == 0) {
                setErrorMsg('No data to export');
                return;
            }

            var x = new Array(tableData.data.length);

            for (var i = 0; i < x.length; i++) {
                x[i] = new Array(7);
                x[i][0] = tableData.data[i].TemplateName;
                x[i][1] = tableData.data[i].ChangedOn;
                x[i][2] = tableData.data[i].ChangeType;
                x[i][3] = tableData.data[i].ChangedBy;
                x[i][4] = tableData.data[i].Item;
                x[i][5] = tableData.data[i].OldValue;
                x[i][6] = tableData.data[i].NewValue;
            }

            autoTable(doc, {
                head: [['Template Name', 'Changed On', 'Event', 'Changed By', 'Item', 'Old Value', 'New Value']],
                columnStyles: { 0: { cellWidth: 120 }, 1: { cellWidth: 75 }, 2: { cellWidth: 75 }, 3: { cellWidth: 75 }, 4: { cellWidth: 100 }, 5: { cellWidth: 125 } },
                body: x,
            });

            doc.setFontSize(12);
            const pageCount = doc.internal.getNumberOfPages();
            for (let i = 1; i <= pageCount; i++) {
                doc.setPage(i);
                const pageSize = doc.internal.pageSize;
                const pageWidth = pageSize.width ? pageSize.width : pageSize.getWidth();
                const pageHeight = pageSize.height ? pageSize.height : pageSize.getHeight();
                const header = 'Audit Trail Report - Template';
                const footer = `Page ${i} of ${pageCount}`;

                // Header
                doc.text(header, 40, 15, { baseline: 'top' });

                // Footer
                doc.text(footer, pageWidth / 2 - (doc.getTextWidth(footer) / 2), pageHeight - 15, { baseline: 'bottom' });
            }

            doc.save('Template.pdf');

            await WriteToActivityLog(ActivityLogEvent.AuditTrail, "Exported 'Template - Change History' information to PDF.", userId, sessionId);

        }
        catch (err) {
            setErrorMsg(ErrorMessages.UnknownError);
        }
        finally {
            setIsDataLoading(false);
        }

    };

    const handleIgnoreDateChange = (event) => {

        setDisFromDate(event.target.checked);
        setDisToDate(event.target.checked);
        setIgnoreDate(event.target.checked);
    };

    return (
        <React.Fragment>
            <OverlaySpinner disappear={isDataLoading} />
            <Box component="main" sx={{ flex: 1, pt: 1, pl: 6 }} >
                <Box >
                    <Typography variant='body2' color="text.blueText" sx={{ fontWeight: 500, pb: 1, lineHeight: 1.5, borderBottom: '0px solid #898F94' }}>Template - Change History</Typography>
                    <Box sx={{ }}>
                        <Divider variant="fullWidth" component="div" sx={{ }} />
                    </Box>

                    <AlertControl Message={errorMsg} severity="error" color="error" icon={true} />

                    <Box sx={{ width: '100%', pb: 2, pt: 2 }}>
                        <Grid container spacing={2}>
                            <Grid item xs={9}>
                                <Stack direction="row" spacing={2}>
                                    <Box>
                                        <label htmlFor="DateFrom" className="form-label">From Date</label>
                                        <input type="date" id="DateFrom" style={{ width: '190px' }} disabled={disFromDate} name="DateFrom" placeholder="Enter start date here" className="form-control" onChange={handleStartDateChange} value={fromDate} />
                                    </Box>
                                    <Box>
                                        <label htmlFor="DateTo" className="form-label">To Date</label>
                                        <input type="date" id="DateTo" style={{ width: '190px' }} name="DateTo" disabled={disToDate} placeholder="Enter end date here" className="form-control" onChange={handleEndDateChange} value={toDate} />
                                    </Box>
                                    <Box sx={{ pt: 2 }}>
                                        <FormGroup>
                                            <FormControlLabel control={<Checkbox sx={{
                                                [`&, &.${checkboxClasses.checked}`]: {
                                                    color: 'Check.main',
                                                },
                                            }} onChange={handleIgnoreDateChange} />} label={<Typography variant="body3" color="text.primary">Ignore dates</Typography>} />
                                        </FormGroup>
                                    </Box>
                                </Stack>
                                <Box sx={{ pt: 2 }}>
                                    <Stack direction="row">
                                        <input type="text" id="LastName" name="LastName" placeholder="Search template name, template description" onChange={handleSearchTextChange} value={searchText} className="form-control" />
                                    </Stack>
                                </Box>
                            </Grid>
                            {/*<Grid item xs={10}>*/}
                            {/*    <Box sx={{ pt: 1 }}>*/}
                            {/*        <Stack direction="row">*/}
                            {/*            <input type="text" id="LastName" name="LastName" placeholder="Search template name, template description" onChange={handleSearchTextChange} value={searchText} className="form-control" />*/}
                            {/*        </Stack>*/}
                            {/*    </Box>*/}
                            {/*</Grid>*/}
                            <Grid item xs={3} sx={{ pt: 2 }}>
                                <Box sx={{ pl: 2, pt: 1.5, pb: 0.5 }}>
                                    <Stack direction="column" spacing={1.5}>
                                        <Box>
                                            <Button type="submit" onClick={(e) => FindClick(e)} variant="contained" size="large" style={{ boxShadow: 'none', border: 0, borderRadius: '20px', padding: '0.3rem 2.5rem', outline: 'none', textTransform: 'none' }} >
                                                Find
                                            </Button>
                                        </Box>
                                        <Box>
                                            <Button onClick={(e) => exportToPDF(e)} variant="contained" size="large" style={{ boxShadow: 'none', border: 0, borderRadius: '20px', padding: '0.3rem 1.5rem', outline: 'none', textTransform: 'none' }} >
                                                Export to PDF
                                            </Button>
                                        </Box>
                                    </Stack>
                                </Box>
                            </Grid>
                        </Grid>
                    </Box>
                    <Box sx={{ pt: 2 }}>
                        <MaterialReactTable
                            columns={columns}
                            data={flatData}
                            enableTopToolbar={false}
                            enableColumnActions={false}
                            enableSorting={false}
                            enableExpandAll={false}
                            enablePagination={false}
                            enableRowNumbers={false}
                            enableRowVirtualization
                            enableHiding={false}
                            enableColumnFilters={false}
                            enableColumnOrdering={false}
                            enableGlobalFilter={false}
                            muiTableBodyRowProps={({ row }) => ({
                                onClick: () => {
                                    setRowSelection((prev) => ({
                                        [row.id]: true,
                                    }));
                                    HandleRowClick(row);
                                },
                                sx: {
                                    cursor: 'pointer',
                                },
                            })}
                            positionActionsColumn="last"
                            enableColumnResizing={false}
                            enableDensityToggle={false}
                            enableFullScreenToggle={false}
                            muiTableBodyCellProps={({ column }) => ({
                                sx: (theme) => ({
                                    color: 'text.primary',
                                    fontSize: theme.typography.body1,
                                    borderBottom: '1px #BBBFBF solid',
                                    pl: 2, fontWeight: 400, pt: 1, pb: 1,
                                }),
                            })
                            }

                            muiTableHeadCellProps={{
                                sx: (theme) => ({
                                    color: 'text.primary',
                                    fontSize: theme.typography.body2,
                                    borderTop: '1px #BBBFBF solid',
                                    borderBottom: '1px #BBBFBF solid',
                                    fontWeight: 400,
                                    pl: 2, pr: 2,
                                    pt: 1, pb: 1,
                                }),
                            }}

                            muiTableContainerProps={{
                                ref: tableContainerRef,
                                sx: { maxHeight: '500px', minHeight: '400px' },
                                onScroll: (
                                    event,
                                ) => fetchMoreOnBottomReached(event.target),
                            }}
                            muiToolbarAlertBannerProps={
                                isError
                                    ? {
                                        color: 'error',
                                        children: ErrorMessages.UnknownError,
                                    }
                                    : undefined
                            }
                            onColumnFiltersChange={setColumnFilters}
                            onGlobalFilterChange={setGlobalFilter}
                            onSortingChange={setSorting}
                            renderBottomToolbarCustomActions={() => (
                                <Box sx={{ mt: 0.5, mb: 0 }}>
                                    <Stack direction="row" spacing={1}>
                                        <Typography variant="caption" color="text.blueText" sx={{ pl: 1, fontWeight: 400 }}>
                                            {
                                                !isError && data
                                                    ? flatData.length + ' record(s)'
                                                    : ''
                                            }
                                        </Typography>
                                        <Box sx={{ display: dispArrow }}>
                                            <ArrowDownwardIcon sx={{ pb: 1, fontSize: 24, color: 'Icon.main' }} />
                                        </Box>
                                    </Stack>
                                </Box>
                            )}
                            onRowSelectionChange={setRowSelection}
                            state={{
                                columnFilters,
                                globalFilter,
                                isLoading,
                                showAlertBanner: isError,
                                showProgressBars: isFetching,
                                showSkeletons: isFetching,
                                rowSelection,
                                sorting,
                            }}
                            initialState={{
                            }}
                            muiTablePaperProps={{
                                elevation: 0,
                            }}
                            rowVirtualizerProps={{ overscan: 2 }}
                            localization={{
                                noRecordsToDisplay: 'No records to display',
                                selectedCountOfRowCountRowsSelected: '',
                            }}
                        />
                    </Box>
                </Box>
            </Box >
            <Box sx={{ border: '1px solid #BBBFBF', ml: 5, }}>
                <TableContainer sx={{ maxHeight: 400, }}>
                    <Table aria-label="simple table" sx={{ cellSpacing: 0, cellPadding: 0, pt: 3, pl: 1, Overflow: 'scroll' }} size="small">
                        <TableBody>
                            <TableRow sx={{}} key={1}>
                                <TableCell scope="row" sx={{ width: 150, pl: 2, borderBottom: '1px solid #D4D6D9' }}>
                                    <Typography variant="body1" color="text.primary" sx={{ fontWeight: 400 }}>
                                        Template Name
                                    </Typography>
                                </TableCell>
                                <TableCell scope="row" sx={{ pl: 1, borderBottom: '1px solid #D4D6D9' }}>
                                    <Typography variant="body1" color="text.primary" sx={{ fontWeight: 400 }}>
                                        {templateName}
                                    </Typography>
                                </TableCell>
                            </TableRow>
                            <TableRow sx={{}} key={4}>
                                <TableCell scope="row" sx={{ width: 150, pl: 2, borderBottom: '1px solid #D4D6D9' }}>
                                    <Typography variant="body1" color="text.primary" sx={{ fontWeight: 400 }}>
                                        Changed on
                                    </Typography>
                                </TableCell>
                                <TableCell scope="row" sx={{ pl: 1, borderBottom: '1px solid #D4D6D9' }}>
                                    <Typography variant="body1" color="text.primary" sx={{ fontWeight: 400 }}>
                                        {changedOn}
                                    </Typography>
                                </TableCell>
                            </TableRow>
                            <TableRow sx={{}} key={5}>
                                <TableCell scope="row" sx={{ width: 150, pl: 2, borderBottom: '1px solid #D4D6D9' }}>
                                    <Typography variant="body1" color="text.primary" sx={{ fontWeight: 400 }}>
                                        Event
                                    </Typography>
                                </TableCell>
                                <TableCell scope="row" sx={{ pl: 1, borderBottom: '1px solid #D4D6D9' }}>
                                    <Typography variant="body1" color="text.primary" sx={{ fontWeight: 400 }}>
                                        {auditEvent}
                                    </Typography>
                                </TableCell>
                            </TableRow>
                            <TableRow sx={{}} key={6}>
                                <TableCell scope="row" sx={{ width: 150, pl: 2, borderBottom: '1px solid #D4D6D9' }}>
                                    <Typography variant="body1" color="text.primary" sx={{ fontWeight: 400 }}>
                                        Changed by
                                    </Typography>
                                </TableCell>
                                <TableCell scope="row" sx={{ pl: 1, borderBottom: '1px solid #D4D6D9' }}>
                                    <Typography variant="body1" color="text.primary" sx={{ fontWeight: 400 }}>
                                        {changedBy}
                                    </Typography>
                                </TableCell>
                            </TableRow>
                            <TableRow sx={{}} key={7}>
                                <TableCell scope="row" sx={{ width: 150, pl: 2, borderBottom: '1px solid #D4D6D9' }}>
                                    <Typography variant="body1" color="text.primary" sx={{ fontWeight: 400 }}>
                                        Item
                                    </Typography>
                                </TableCell>
                                <TableCell scope="row" sx={{ pl: 1, borderBottom: '1px solid #D4D6D9' }}>
                                    <Typography variant="body1" color="text.primary" sx={{ fontWeight: 400 }}>
                                        {auditItem}
                                    </Typography>
                                </TableCell>
                            </TableRow>
                            <TableRow sx={{}} key={8}>
                                <TableCell scope="row" sx={{ width: 150, pl: 2, borderBottom: '1px solid #D4D6D9' }}>
                                    <Typography variant="body1" color="text.primary" sx={{ fontWeight: 400 }}>
                                        Old value
                                    </Typography>
                                </TableCell>
                                <TableCell scope="row" sx={{ pl: 1, borderBottom: '1px solid #D4D6D9' }}>
                                    <Typography variant="body1" color="text.primary" sx={{ fontWeight: 400 }}>
                                        {oldValue}
                                    </Typography>
                                </TableCell>
                            </TableRow>
                            <TableRow sx={{}} key={9}>
                                <TableCell scope="row" sx={{ width: 150, pl: 2, borderBottom: '1px solid #D4D6D9' }}>
                                    <Typography variant="body1" color="text.primary" sx={{ fontWeight: 400 }}>
                                        New value
                                    </Typography>
                                </TableCell>
                                <TableCell scope="row" sx={{ pl: 1, borderBottom: '1px solid #D4D6D9' }}>
                                    <Typography variant="body1" color="text.primary" sx={{ fontWeight: 400 }}>
                                        {newValue}
                                    </Typography>
                                </TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </TableContainer>
            </Box>
        </React.Fragment>
    );
};

const queryClient = new QueryClient();

const TemplateHistory = (props) => (
    <QueryClientProvider client={queryClient}>
        <TemplateHistories userId={props.userId} sessionId={props.sessionId} />
    </QueryClientProvider>
);

export default TemplateHistory;