import React, { useState, useEffect } from 'react';
import { CallAPI, IsError } from '../global/APIUtils';
import OverlaySpinner from './Controls/OverlaySpinner';
import { Grid, Box, Typography, Link, Stack, Switch, Divider, FormControlLabel, FormControl, Checkbox, IconButton } from '@mui/material';
import PlaceOutlinedIcon from '@mui/icons-material/PlaceOutlined';
import HealthAndSafetyOutlinedIcon from '@mui/icons-material/HealthAndSafetyOutlined';
import ArrowForwardIosOutlinedIcon from '@mui/icons-material/ArrowForwardIosOutlined';
import AodIcon from '@mui/icons-material/Aod';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import ErrorIcon from '@mui/icons-material/Error';
import RecoveryEmailDlg from '../dialogs/RecoveryEmailDlg';
import TwoStepVerifyConfigDlg from '../dialogs/TwoStepVerifyConfigDlg';
import { APICall } from '../global/Api';
import AlertControl from '../components/Controls/AlertControl';
import { ErrorMessages } from '../common/ErrorMessages';

const ProfilePreferences = (props) => {

    const [isLoading, setIsLoading] = useState(false);
    const [showRecoveryEmailDlg, setShowRecoveryEmailDlg] = useState(false);
    const [showTwoStepConfigDlg, setShowTwoStepConfigDlg] = useState(false);
    const [twoStepStatus, setTwoStepStatus] = useState('Off');
    const [recoveryEmail, setRecoveryEmail] = useState('Add an email address');
    const [errorMsg, setErrorMsg] = useState('');

    const [userId, setUserId] = useState(props.userId);
    const [sessionId, setSessionId] = useState(props.sessionId);
    const [email, setEmail] = useState(props.emailadrs);

    //const [checked, setChecked] = React.useState(true);

    //const handleChange = (evt) => {
    //    setChecked(evt.target.checked);
    //};

    const closeRecoveryEmailDlg = () => {
        setShowRecoveryEmailDlg(false);
    }

    const closeTwoStepConfigDlg = () => {
        setShowTwoStepConfigDlg(false);
    }

    const onEmailAddHandle = async () => {
        loadForm();
    }

    const onTwoStepConfigChange = async () => {
        loadForm();
    }

    const handleAddRecoveryEmail = () => {
        setShowRecoveryEmailDlg(true);
    }

    const handleTwoStepClick = async () => {
        setShowTwoStepConfigDlg(true);
    }

    const loadForm = async () => {

        const apiUrl = new URL(
            process.env.REACT_APP_GET_USER_PREFERENCE,
            process.env.REACT_APP_BASE_URL,
        );

        try {

            setIsLoading(true);

            let response = await APICall({ url: apiUrl.href, method: 'GET', contentType: 'application/json', responseType: '' }, userId, sessionId);

            if (response !== null && response !== undefined) {
                if (response.data.length > 0) {
                    if (response.data[0].IsTwoStepVerifySet) {
                        setTwoStepStatus('On');
                    }
                    else {
                        setTwoStepStatus('Off');
                    }
                    setRecoveryEmail(response.data[0].RecoveryEmail);
                }
            }
        }
        catch (err) {
            setErrorMsg(ErrorMessages.UnknownError);
        }
        finally {
            setIsLoading(false);
        }
    }

    useEffect(() => {
        loadForm();
    }, []);

    return (
        <React.Fragment>
            <OverlaySpinner disappear={isLoading} />
            <Grid item xs container direction="row" sx={{ borderLeft: 0, borderColor: '#B4B4B4', pl: 4,  }}>
                <Grid item md={12} sx={{ pr: 10, mt: 2}} justifyContent="center">
                    <Stack direction="column">
                        <Typography align="center" variant="body1" color="text.secondary" sx={{ fontWeight: 500, lineHeight: 2}}>
                            Your Preferences
                        </Typography>
                        <Typography align="center" sx={{ mb: 2, borderBottom: 0, borderColor: 'rgba(0, 0, 0, 0.2)', pb: 3, fontWeight: 400 }} variant="caption" color="text.primary">
                            Settings of your account
                        </Typography>
                        <AlertControl Message={errorMsg} severity="error" color="error" icon={true} />
                    </Stack>
                </Grid>
                <Grid item xs={12} sx={{ pt: 0,  }} justifyContent="center">
                    <Box sx={{ borderRadius: 4, border: 1, borderColor: '#898F94', pl: 3, pt: 1, pb: 8 }}>
                        <Typography gutterBottom variant="body1" color="text.primary" sx={{fontWeight: 400, pt: 1, mb: 1}}>
                            Options
                        </Typography>
                        <Typography sx={{ mb: 5, fontWeight: 400 }} variant="body2" color="text.blueText">
                            See and manage changes made to how you sign in to your account.
                        </Typography>
                        <Grid container>
                            <Grid item md={5} sx={{pt: 4}} >
                                <Stack spacing={1} direction="row">
                                    <HealthAndSafetyOutlinedIcon color="action" sx={{ fontSize: 24 }} />
                                    <Typography gutterBottom variant="body1" color="text.primary" sx={{}}>
                                        2-Step Verification
                                    </Typography>
                                </Stack>
                            </Grid>
                            <Grid item md={5} sx={{ pt: 4}} >
                                <Typography gutterBottom variant="body1" color="text.primary" sx={{}}>
                                    {twoStepStatus}
                                </Typography>
                            </Grid>
                            <Grid item container justifyContent="flex-end" md={2} sx={{ pt: 4}}>
                                <IconButton size="small" sx={{ border: 0, borderColor: '#9e9e9e', borderRadius: '5px', mb: 0}} onClick={() => handleTwoStepClick()}>
                                    <ArrowForwardIosOutlinedIcon color="action" sx={{ fontSize: 22, mr: 1, color: 'Icon.main'}} />
                                </IconButton>                               
                            </Grid>
                            <Grid item md={12} sx={{ pt: 0, pb: 2, pl: 4 }} >
                                <Typography gutterBottom variant="body2" color="text.primary" sx={{fontWeight: 400}}>
                                    Code will be sent to your email address
                                </Typography>
                            </Grid>
                        </Grid>
                    </Box>
                </Grid>
            </Grid>

            {showRecoveryEmailDlg ?
                <RecoveryEmailDlg open={showRecoveryEmailDlg} onClose={closeRecoveryEmailDlg} onChange={onEmailAddHandle} emailadrs={email} userId={userId} sessionId={sessionId} />
                : null
            }

            {showTwoStepConfigDlg ?
                <TwoStepVerifyConfigDlg open={showTwoStepConfigDlg} onClose={closeTwoStepConfigDlg} onChange={onTwoStepConfigChange} userId={userId} sessionId={sessionId} />
                : null
            }
        </React.Fragment>
    );
}

export default ProfilePreferences;