import React, { useEffect, useState } from 'react';
import { useNavigate } from "react-router-dom";
import { Container } from '@mui/material';
import OverlaySpinner from './Controls/OverlaySpinner';
import PostLoginMenu from './PostLoginMenu';
import { GetLoggedInUserId, IsLoggedInUserProspectUser, GetAccessControl } from '../global/APIUtils';
import { RedirectToHome } from '../global/Helpers';
import { AOBSystemProcess } from '../common/AOBEnum';
import { useLocation } from "react-router-dom";
import EnrollmentHeader from './EnrollmentHeader';
import { Box, Grid, Paper } from '@mui/material';
import Footer from './Footer';
import AlertControl from './Controls/AlertControl';
import { ErrorMessages } from '../common/ErrorMessages';
import { APICall } from '../global/Api';

const UserHome = () => {

    const location = useLocation();

    const [userId, setUserId] = useState(location.state ? location.state.userId : (GetLoggedInUserId() != null && GetLoggedInUserId() != undefined) ? GetLoggedInUserId() : -1);
    const [sessionId, setSessionId] = useState(location.state ? location.state.sessionId : '');
    const [errorMsg, setErrorMsg] = useState('');
    const [isLoading, setIsLoading] = useState(false);

    let navigate = useNavigate();

    useEffect(() => {

        if (!userId || userId == -1) {
            navigate('/AppLogin', { state: {}, replace: true });
            return;
        }

        LoadForm();

        //let changePwd = isForcePasswordChange();

        //if (changePwd === true) {
        //    navigate('/Password/CreatePassword', { state: { userId: userId, sessionId: sessionId }, replace: true });
        //    return;
        //}

        //let prospect = IsLoggedInUserProspectUser(userId);
        //let accessControl = GetAccessControl(userId);

        //if (prospect === true) {
        //    navigate('/ProspectDashboard', { state: { userId: userId, sessionId: sessionId }, replace: true });
        //    return;
        //}

        //let accessControlArray = accessControl.split("|");

        //var found = accessControlArray.find(function (element) {
        //    return element == AOBSystemProcess.ApplicationMaintenance
        //        || element == AOBSystemProcess.ViewApplication || element == AOBSystemProcess.ExportApplication
        //        || element == AOBSystemProcess.ApproveApplication || element == AOBSystemProcess.PublishApplication
        //        || element == AOBSystemProcess.DocuSignDocUpload || element == AOBSystemProcess.AllProcess;
        //});

        //if (found !== undefined && found.length > 0)
        //    navigate('/ApplicationDashboard', { state: { userId: userId, sessionId: sessionId }, replace: true });
        //else
        //    navigate('/UserProfileSetting', { state: { userId: userId, sessionId: sessionId }, replace: true });

    }, []);

    const LoadForm = async () => {

        let apiUrl = new URL(
            process.env.REACT_APP_IS_FORCE_PWD_CHANGE,
            process.env.REACT_APP_BASE_URL,
        );

        try {

            setIsLoading(true);

            let response = await APICall({ url: apiUrl.href, method: 'GET', contentType: 'application/json', responseType: '' }, userId, sessionId);

            if (response !== null && response !== undefined) {
                if (response.data.length > 0) {
                    if (response.data[0].IsForceChangePwd) {
                        navigate('/CreatePassword', { state: { forceChange: true, reason: response.data[0].PwdChangeReason, userId: userId, sessionId: sessionId }, replace: true });
                        return true;
                    }
                }
            }

            apiUrl = new URL(
                process.env.REACT_APP_GET_BANK_INFO,
                process.env.REACT_APP_BASE_URL,
            );

            response = await APICall({ url: apiUrl.href, method: 'GET', contentType: 'application/json', responseType: '' }, userId, sessionId);

            if (response !== null && response !== undefined) {
                if (response["data"] && response["data"].length > 0) {
                    sessionStorage.setItem('BankName', response.data[0].BankName);
                }
            }

            let prospect = IsLoggedInUserProspectUser(userId);
            let accessControl = GetAccessControl(userId);

            if (prospect === true) {
                navigate('/ProspectDashboard', { state: { userId: userId, sessionId: sessionId }, replace: true });
                return;
            }

            let accessControlArray = accessControl.split("|");

            var found = accessControlArray.find(function (element) {
                return element == AOBSystemProcess.ApplicationMaintenance
                    || element == AOBSystemProcess.ViewApplication || element == AOBSystemProcess.ExportApplication
                    || element == AOBSystemProcess.ApproveApplication || element == AOBSystemProcess.PublishApplication
                    || element == AOBSystemProcess.DocuSignDocUpload || element == AOBSystemProcess.AllProcess;
            });

            if (found !== undefined && found.length > 0)
                navigate('/ApplicationDashboard', { state: { userId: userId, sessionId: sessionId }, replace: true });
            else
                navigate('/UserProfileSetting', { state: { userId: userId, sessionId: sessionId }, replace: true });

        }
        catch (err) {
            setErrorMsg(ErrorMessages.UnknownError);
        }
        finally {
            setIsLoading(false);
        }

        return false;
    }

    return (
        <React.Fragment>
            <Container maxWidth="xl" sx={{}}>
                <OverlaySpinner disappear={isLoading} />
                <PostLoginMenu userId={userId} sessionId={sessionId} />
                <Box
                    sx={{
                        width: '100%',
                        //position: 'relative',
                        backgroundColor: '#fff',
                        //backgroundSize: 'cover',
                        backgroundRepeat: 'no-repeat',
                        backgroundPosition: 'center',
                        pb: 0, opacity: 1
                    }}
                >
                    <Grid item md={12} id="aobhead" >
                        <Box sx={{
                            pr: 0,
                            pl: 0, pt: 0
                        }}>
                            <EnrollmentHeader />
                        </Box>
                    </Grid>
                    <Grid item md={12} >
                        <Box sx={{ pr: 6, height: '500px', pt: 4, pl: 6 }}>
                            <Paper elevation={0}>
                                <AlertControl Message={errorMsg} severity="error" color="error" icon={true} />
                            </Paper>
                        </Box>
                    </Grid>
                </Box>
                <Footer userId={userId} />
            </Container>
        </React.Fragment>
    );
}
export default UserHome;