import React from 'react';
import { CircleSpinnerOverlay } from 'react-spinner-overlay'

const OverlaySpinner = (props) => {

    return (
        <>
            <CircleSpinnerOverlay size={80} innerBorderWidth={10} outerBorderWidth={2}
                loading={props.disappear}
                overlayColor="rgba(0, 0, 0, 0.4)" color="#1D50AF" />
        </>
    );
}
export default OverlaySpinner;