import React, { useEffect, useState, useRef } from 'react';
import { Box, Dialog, Divider, DialogTitle, Stack, DialogContent, DialogActions, Button, Typography, IconButton, Grid, FormControl, FormControlLabel } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import AlertControl from '../components/Controls/AlertControl';
import { useDispatch, useSelector } from 'react-redux';
import { ErrorMessages } from '../common/ErrorMessages';
import { LoadProfilePic } from '../global/Helpers';
import profimg from '../assets/images/profimg.jpg';
import { UploadUserFiles } from '../global/APIUtils';
import LoadingOverlay from 'react-loading-overlay';
import RiseLoader from 'react-spinners/RiseLoader'
import { APICall, WriteToActivityLog } from '../global/Api';
import { EnrollmentStatus, ActivityLogEvent } from '../common/AOBEnum';
import CheckIcon from '@mui/icons-material/Check';
import { GetLoggedInUserEmail } from '../global/APIUtils';
import Checkbox, { checkboxClasses } from "@mui/material/Checkbox";

const DocuSignDlg = (props) => {
    const { onClose, open, onChange, ...other } = props;
    const [invalidImage, setInvalidImage] = useState(true);
    const [errorMsg, setErrorMsg] = useState('');
    const [imageUrl, setImageUrl] = useState(null);
    const [picUpload, setPicUpload] = useState(null);
    const [isOkBtnEnabled, setIsOkBtnEnabled] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [enrmntid, setEnrmntid] = useState(props.enrmntid || -1);
    const [ownerId, setOwnerId] = useState(-1);
    const [ownerName, setOwnerName] = useState('');
    const [jointOwnerName, setJointOwnerName] = useState('');
    const [ownerEmail, setOwnerEmail] = useState('');
    const [jointOwnerEmail, setJointOwnerEmail] = useState('');
    const [isJntAcct, setIsJntAcct] = React.useState(false);
    const [isJntOwnerSignReq, setIsJntOwnerSignReq] = React.useState(true);
    const [jntOwnerEmailChecked, setJntOwnerEmailChecked] = React.useState(true);
    const [idvRequired, setIdvRequired] = React.useState('none');
    const [idvDefault, setIdvDefault] = React.useState(false);
    const [jtOwnerIdvDefault, setJtOwnerIdvDefault] = React.useState(false);
    const [initialReq, setInitialReq] = React.useState(false);
    const [isInitialReq, setIsInitialReq] = React.useState('None');
    const [bankName, setBankName] = React.useState('');
    const [signerList, setSignerList] = useState(null);
    const [selectedSigner, setSelectedSigner] = useState(-1);
    const [bankSignReq, setBankSignReq] = useState('none');
    const [chkBankSignReq, setChkBankSignReq] = useState(false);
    const [bankRepIdvDefault, setBankRepIdvDefault] = React.useState(false);
    const [disJtOwnerIDVReqChk, setDisJtOwnerIDVReqChk] = React.useState(false);
    const [disOkBtn, setDisOkBtn] = React.useState(true);

    const [userId, setUserId] = useState(props.userId);
    const [sessionId, setSessionId] = useState(props.sessionId);

    const [docSignFiles, setDocSignFiles] = useState([]);
    const [atchmntId, setAtchmntId] = useState([]);

    const handleClose = (event) => {
        onClose();
    };

    const handleOk = async (event) => {

        await updateEnrollmentStatus();
    };

    const onChangeFile = (event) => {
    }

    const loadDetails = async () => {

        let files = [];
        let aid = [];
        try {

            setIsLoading(true);

            setBankName(sessionStorage.getItem('BankName'));

            await WriteToActivityLog(ActivityLogEvent.DocuSign, "'Requires DocuSign action' dialog opened. Application name '" + props.enrmntNm + "'.", userId, sessionId);

            let apiUrl = new URL(
                process.env.REACT_APP_GET_ATTACHMENTS,
                process.env.REACT_APP_BASE_URL,
            );

            apiUrl.searchParams.set('enrmntId', `${parseInt(enrmntid)}`);

            let response = await APICall({ url: apiUrl.href, method: 'GET', contentType: 'application/json', responseType: '' }, userId, sessionId);

            if (response !== null && response !== undefined) {

                for (let i = 0; i < response.data[0].length; i++) {
                    if (response.data[0][i].IsSignReq && !response.data[0][i].IsSignCompleted) {
                        files.push(response.data[0][i].FileName);
                        aid.push(response.data[0][i].AttchmntId);
                    }
                }
                setAtchmntId(aid);
                setDocSignFiles(files);
            }

            apiUrl = new URL(
                process.env.REACT_APP_GET_ENROLLMENT_BY_ID,
                process.env.REACT_APP_BASE_URL,
            );
            apiUrl.searchParams.set('enrmntId', `${parseInt(enrmntid)}`);

            response = await APICall({ url: apiUrl.href, method: 'GET', contentType: 'application/json', responseType: '' }, userId, sessionId);
            if (response !== null && response !== undefined) {
                if (response["data"] && response["data"].length > 0) {
                    setOwnerId(response.data[0].ownerId);
                    setOwnerName(response.data[0].ownerName);
                    setOwnerEmail(response.data[0].ownerEmail);
                    setIsJntAcct(response.data[0].IsJointAccount);
                    setJointOwnerName(response.data[0].JointOwnerName);
                    setJointOwnerEmail(response.data[0].JointOwnerEmail);
                }
                else {

                    let apiErrors = response["Errors"];

                    if (apiErrors != undefined && apiErrors.length > 0) {
                        setErrorMsg(apiErrors[0].Message);
                    }
                    else {
                        setErrorMsg(ErrorMessages.UnknownError);
                    }
                }
            }

            apiUrl = new URL(
                process.env.REACT_APP_GET_DOCUSIGN_CONFIG,
                process.env.REACT_APP_BASE_URL,
            );

            let bSignReq = false;
            response = await APICall({ url: apiUrl.href, method: 'GET', contentType: 'application/json', responseType: '' }, userId, sessionId);
            if (response !== null && response !== undefined) {
                if (response["data"] && response["data"].length > 0) {
                    setIdvRequired(response.data[0].IDVReq != undefined && response.data[0].IDVReq ? 'block' : 'none');
                    setIdvDefault(response.data[0].IDVDefault);
                    //setBankRepIdvDefault(response.data[0].IDVDefault);
                    setJtOwnerIdvDefault(response.data[0].IDVDefault);
                    setInitialReq(response.data[0].InitialRequired != undefined && response.data[0].InitialRequired ? true : false);
                    setIsInitialReq(response.data[0].InitialRequired != undefined && response.data[0].InitialRequired ? 'block' : 'none')
                    bSignReq = response.data[0]?.BankSignRequired ?? false;
                    setBankSignReq(bSignReq ? 'block' : 'none');
                }
            }

            if (bSignReq) {
                apiUrl = new URL(
                    process.env.REACT_APP_GET_DOCUSIGN_SIGNERS_ONLY,
                    process.env.REACT_APP_BASE_URL,
                );

                response = await APICall({ url: apiUrl.href, method: 'GET', contentType: 'application/json', responseType: '' }, userId, sessionId);

                if (response !== undefined && response !== null) {
                    setSignerList(response["data"]);
                    let found = response["data"].find(element => element.ContactId == userId);
                    if (found) {
                        setSelectedSigner(userId);
                    }
                }
            }

            setDisOkBtn(false);
        }
        catch (err) {
            setErrorMsg(ErrorMessages.UnknownError);
            setIsLoading(false);
        }
        finally {
            setIsLoading(false);
        }
    };

    const updateEnrollmentStatus = async (enrmntStatusId, reason) => {

        try {

            let inputJson = {
                enrmntId: enrmntid,
                enrmntStatusId: EnrollmentStatus.DocuSignRequired,
                stsReason: ''
            };

            setIsLoading(true);

            let apiUrl = new URL(
                process.env.REACT_APP_UPDATE_USER_ENROLMENT_STATUS,
                process.env.REACT_APP_BASE_URL,
            );


            let response = await APICall({ url: apiUrl.href, method: 'POST', payload: inputJson, contentType: 'application/json', responseType: '' }, userId, sessionId);

            if (response !== null && response !== undefined) {

                if (response.Errors.length > 0) {
                    setErrorMsg(response.Errors[0].Message);
                }
                else if (response.meta.Updated === "1") {

                    await WriteToActivityLog(ActivityLogEvent.Application, "Application status updated. Application name '" + props.enrmntNm + "'. New status: 'Requires DocuSign'", userId, sessionId);

                    let apiUrl = new URL(
                        process.env.REACT_APP_SEND_DOCUSIGN_EMAIL,
                        process.env.REACT_APP_BASE_URL,
                    );

                    let bankSignerName = '';
                    let bankSignerEmail = '';

                    if (chkBankSignReq) {
                        if (selectedSigner === "-1") {
                            setErrorMsg("Please chosse signer of " + bankName + " for DocuSign");
                            return;
                        }
                        let found = signerList.find(element => element.ContactId == selectedSigner);
                        if (found == null || found == undefined) {
                            setErrorMsg("Please chosse signer of " + bankName + " for DocuSign");
                            return;
                        }

                        bankSignerName = found.FirstName + ' ' + found.LastName;
                        bankSignerEmail = found.Email;
                    }


                    //signerList

                    inputJson = {
                        EnrmntId: enrmntid,
                        AttchmntId: EnrollmentStatus.DocuSignRequired,
                        SignerUserId: ownerId,
                        SignerName: ownerName,
                        SignerEmail: ownerEmail,
                        IsJointAccount: isJntAcct,
                        JointSignerName: jointOwnerName,
                        JointSignerEmail: jointOwnerEmail,
                        IdArray: atchmntId,
                        Signer1IDVReq: idvDefault,
                        Signer2IDVReq: jtOwnerIdvDefault,
                        InitialRequired: initialReq,
                        IsBankSignReq: chkBankSignReq,
                        BankSignerName: bankSignerName,
                        BankSignerEmail: bankSignerEmail,
                        Signer3IDVReq: bankRepIdvDefault,
                    };

                    response = await APICall({ url: apiUrl.href, method: 'POST', payload: inputJson, contentType: 'application/json', responseType: '' }, userId, sessionId);
                    if (response !== null && response !== undefined) {
                        if (response.Errors.length > 0) {
                            setErrorMsg(response.Errors[0].Message);
                        }
                        else if (response.meta.DocuSignMailSent === "1") {

                            await loadActualDSStatus();

                            await WriteToActivityLog(ActivityLogEvent.DocuSign, "DocuSign email sent. Application name '" + props.enrmntNm + "'. " + (isJntAcct ? "Signer 1: " : "Signer: ") + ownerEmail + (isJntAcct ? (". Signer 2: " + jointOwnerEmail) : "") + ". Identity verification required" + (isJntAcct ? "(Signer1): " : ": ") + (idvDefault ? "Yes. " : "No. ") + (isJntAcct ? ("Identity verification required(Signer2): " + (jtOwnerIdvDefault ? "Yes. " : "No. ")) : "") + "Requires Initial: " + (initialReq ? "Yes" : "No"), userId, sessionId);

                            onChange("Application status updated and DocuSign email sent to signer(s).");
                        }
                    }
                }               
            }
        }
        catch (err) {
            setErrorMsg(ErrorMessages.UnknownError);
            setIsLoading(false);
        }
        finally {
            setIsLoading(false);
        }
    }

    const loadActualDSStatus = async () => {

        let apiUrl = new URL(
            process.env.REACT_APP_GET_DOCUSIGN_STATUS,
            process.env.REACT_APP_BASE_URL,
        );

        apiUrl.searchParams.set('enrmntId', `${parseInt(enrmntid)}`);
        apiUrl.searchParams.set('attachmentId', `${-1}`);

        let response = await APICall({ url: apiUrl.href, method: 'GET', contentType: 'application/json', responseType: '' }, userId, sessionId);

        if (response !== null && response !== undefined) {

            if (response["data"] && response["data"].length > 0) {
                return;
            }
            else {

                let apiErrors = response["Errors"];

                if (apiErrors != undefined && apiErrors.length > 0) {
                    setErrorMsg(apiErrors[0].Message);
                }
            }
        }
    }

    const handleJtOwnrSignReqChange = (event) => {
        setJntOwnerEmailChecked(event.target.checked);
        setIsJntOwnerSignReq(event.target.checked);

        if (event.target.checked) {
            setJtOwnerIdvDefault(idvDefault);
            setDisJtOwnerIDVReqChk(false);
        }
        else {
            setDisJtOwnerIDVReqChk(true);
            setJtOwnerIdvDefault(false);
        }
    };

    const handleInitialReqChange = (event) => {
        setInitialReq(event.target.checked);
    };

    const handleIDVReqCheckChange = (event) => {
        setIdvDefault(event.target.checked);
    };

    const handleBankRepIDVReqCheckChange = (event) => {
        setBankRepIdvDefault(event.target.checked);
    };

    const handleJtOwnerIDVReqCheckChange = (event) => {
        setJtOwnerIdvDefault(event.target.checked);
    };

    const handleChkBankSignReqChange = (event) => {
        setChkBankSignReq(event.target.checked);
    };

    const handleSignerChange = (event) => {
        setSelectedSigner(event.target.value);
    };

    useEffect(() => {
        loadDetails();
    //    let email = GetLoggedInUserEmail(userId);
    //    setOwnerEmail(email);
    }, []);

    return (
        <Dialog onClose={handleClose} open={open} keepMounted fullWidth maxWidth="md">
            <LoadingOverlay active={isLoading} spinner={<RiseLoader color={'#1D50AF'} />} >
                <DialogTitle sx={{pl: 3.5}}>
                    Requires DocuSign - Action
                    <IconButton
                        aria-label="close"
                        onClick={handleClose}
                        sx={{
                            position: 'absolute',
                            right: 8,
                            top: 8,
                            color: (theme) => theme.palette.Icon.main,
                        }}
                    >
                        <CloseIcon />
                    </IconButton>
                </DialogTitle>
                <DialogContent dividers sx={{ pl: 6, pr: 6 }}>
                    <AlertControl Message={errorMsg} severity="error" color="error" icon={true} />
                    <Grid container>
                        <Grid item md={12} sx={{}}>
                            <Box sx={{ pb: 1 }}>
                                <Typography variant="body1" color="text.primary" sx={{ fontWeight: 400, pb: 0 }}>{props.bmsgline1}</Typography>
                            </Box>
                        </Grid>
                        <Grid item md={12} sx={{}}>
                            <Stack direction="row">
                                <Box sx={{ display: isJntAcct ? 'block' : 'none' }}>
                                    <FormControlLabel sx={{}}
                                            label={<Typography sx={{ fontWeight: 400 }} variant="body1" color="text.Secondary">Joint owner's sign required</Typography>}
                                        control={<Checkbox sx={{
                                            [`&, &.${checkboxClasses.checked}`]: {
                                                color: 'Check.main',
                                            },
                                        }}  checked={isJntOwnerSignReq ? true : false} disabled={isJntAcct ? false : true} onChange={handleJtOwnrSignReqChange} />}
                                        />
                                </Box>
                            <Box sx={{ display: isInitialReq}}>
                                <FormControlLabel sx={{}}
                                    label={<Typography sx={{ fontWeight: 400 }} variant="body1" color="text.Secondary">Requires Initial of Applicant(s)</Typography>}
                                        control={<Checkbox sx={{
                                            [`&, &.${checkboxClasses.checked}`]: {
                                                color: 'Check.main',
                                            },
                                        }} checked={initialReq} onChange={handleInitialReqChange} />}
                                />
                            </Box>
                        </Stack>
                       
                        </Grid>
                        <Grid item md={12} sx={{ mb: 2, mt: 2 }}>
                            <Divider light />
                            <Box sx={{ pt: 2 }}>
                                <Typography sx={{ fontWeight: 400, pb: 1, pt: 2 }} gutterBottom variant="body1" color="text.primary">
                                    File(s) for DocuSign
                                </Typography>
                            </Box>
                            <Box sx={{ pb: 2, pt: 1 }}>
                            {docSignFiles?.map((item, index) => (
                                <Stack direction="row" key={index}>
                                    <CheckIcon sx={{ fontSize: 24, color: '#0070CC' }} />
                                    <Typography sx={{ fontWeight: 400, pl: 1 }} variant="body1" color="text.Secondary">{item}</Typography>
                                </Stack>
                            ))}
                            </Box>
                            <Divider light />
                        </Grid>

                        <Grid item md={11} sx={{ pt: 0}}>
                            <Typography sx={{ fontWeight: 400, }} gutterBottom variant="body1" color="text.primary">
                                Mail will be sent to the following signer(s) for DocuSign
                            </Typography>
                        </Grid>
                        <Grid item md={6} sx={{ pt: 2 }}>
                            <FormControlLabel
                                label={<Typography sx={{ fontWeight: 400 }} variant="body1" color="text.Secondary">{ownerEmail} (Owner)</Typography>}
                                control={<Checkbox sx={{
                                    [`&, &.${checkboxClasses.checked}`]: {
                                        color: 'Check.main',
                                    },
                                }} disabled checked />}
                            />
                            <Box sx={{ p: 0, pl: 4, display: idvRequired }}>
                                <FormControlLabel
                                    label={<Typography sx={{ fontWeight: 400 }} variant="body1" color="text.Secondary">Requires Identity Verification</Typography>}
                                    control={<Checkbox sx={{
                                        [`&, &.${checkboxClasses.checked}`]: {
                                            color: 'Check.main',
                                        },
                                    }} checked={idvDefault} onChange={handleIDVReqCheckChange} />}
                                />
                            </Box>
                        </Grid>
                        <Grid item md={6} sx={{ pt: 2 }}>
                            {
                                isJntAcct ?
                                    <React.Fragment>
                                        <FormControlLabel
                                            label={<Typography sx={{ fontWeight: 400 }} variant="body1" color="text.Secondary">{jointOwnerEmail} (Joint owner)</Typography>}
                                            control={<Checkbox sx={{
                                                [`&, &.${checkboxClasses.checked}`]: {
                                                    color: 'Check.main',
                                                },
                                            }} disabled checked={jntOwnerEmailChecked} />}
                                        />
                                        <Box sx={{ p: 0, pl: 4, display: idvRequired}}>
                                            <FormControlLabel
                                                label={<Typography sx={{ fontWeight: 400 }} variant="body1" color="text.Secondary">Requires Identity Verification</Typography>}
                                                control={<Checkbox sx={{
                                                    [`&, &.${checkboxClasses.checked}`]: {
                                                        color: 'Check.main',
                                                    },
                                                }} checked={jtOwnerIdvDefault} disabled={disJtOwnerIDVReqChk} onChange={handleJtOwnerIDVReqCheckChange} />}
                                            />
                                        </Box>
                                    </React.Fragment>
                                : null
                            }
                        </Grid>
                        <Grid item md={12} sx={{}}>
                            <Box sx={{ display: bankSignReq, mb: 3, pt: 2 }}>
                                <Divider light />
                                <Box sx={{ p: 0, pl: 0 }}>
                                    <FormControlLabel
                                        label={<Typography sx={{ fontWeight: 400 }} variant="body1" color="text.Secondary">Mail will be sent to the following signer of {bankName} for DocuSign</Typography>}
                                        control={<Checkbox sx={{
                                            [`&, &.${checkboxClasses.checked}`]: {
                                                color: 'Check.main',
                                            },
                                        }} checked={chkBankSignReq} onChange={handleChkBankSignReqChange} />}
                                    />
                                </Box>
                                <Stack direction="row">
                                    <Box sx={{}}>
                                        <select id="SignerList" disabled={!chkBankSignReq} className="form-select" name="SignerList" value={selectedSigner} onChange={handleSignerChange} placeholder="Title">
                                        <option key={-1} value={-1}>Select</option>
                                        {
                                            signerList && signerList.map((e) => (
                                                <option key={e.ContactId} value={e.ContactId}>{e.FirstName + ' ' + e.LastName + ' (' + e.Email + ' )'}</option>
                                            ))
                                        }
                                        </select>
                                    </Box>
                                    <Box sx={{ p: 0, pl: 4 }}>
                                        <FormControlLabel disabled={!chkBankSignReq}
                                            label={<Typography sx={{ fontWeight: 400 }} variant="body1" color="text.Secondary">Requires Identity Verification</Typography>}
                                            control={<Checkbox sx={{
                                                [`&, &.${checkboxClasses.checked}`]: {
                                                    color: 'Check.main',
                                                },
                                            }} checked={bankRepIdvDefault} onChange={handleBankRepIDVReqCheckChange} />}
                                        />
                                    </Box>
                                </Stack>
                            </Box>
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions sx={{ mr: 2 }}>
                    <Button autoFocus onClick={handleClose} variant="contained" sx={{ mr: 1, mt: 1, mb: 1, borderRadius: '20px', padding: '0.25rem 2.2rem', textTransform: 'none' }}>
                        Cancel
                    </Button>
                    <Button disabled={disOkBtn} onClick={handleOk} variant="contained" sx={{ mt: 1, mb: 1, borderRadius: '20px', padding: '0.25rem 2.5rem', textTransform: 'none' }}>
                        Ok
                    </Button>
                </DialogActions>
            </LoadingOverlay>
        </Dialog>
    );
}

export default DocuSignDlg;
