import React, { useState, useEffect } from 'react';
import { CallAPI, IsError } from '../global/APIUtils';
import OverlaySpinner from './Controls/OverlaySpinner';
import { Grid, Box, Typography, Link, Stack } from '@mui/material';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Avatar from '@mui/material/Avatar';
import ComputerOutlinedIcon from '@mui/icons-material/ComputerOutlined';
import PlaceOutlinedIcon from '@mui/icons-material/PlaceOutlined';

const ProfileRecentActivity = (props) => {

    const [isLoading, setIsLoading] = useState(false);

    return (
        <React.Fragment>
            <OverlaySpinner disappear={isLoading} />
            <Grid item xs container direction="row" sx={{ borderLeft: 1, borderColor: '#e0e0e0', pl: 8, pr: 4 }}>
                <Grid item md={9} sx={{ pr: 10, }} justifyContent="center">
                    <Typography gutterBottom variant="h4" color="text.secondary" sx={{fontWeight: 300 }}>
                        Recent Activity
                    </Typography>
                    <Typography sx={{ mb: 4, borderBottom: 1, borderColor: '#e0e0e0', pb: 3 }} variant="h6" color="text.secondary">
                        Here, you can check out any recent changes to how you access your account—from changing your password to connecting a new device. In most cases, you'll see updates that you've made. But if you see something suspicious, mail us.
                    </Typography>
                </Grid>
                <Grid item md={3}>
                    <Grid container>
                        <Grid item md={10} sx={{}} >
                            <span className="">
                                <svg viewBox="0 0 500 500" width="128" height="128">
                                    <title>Task</title>
                                    <g id="Layer_20" dataname="Layer 20">
                                        <polygon points="41.07 191.34 434.69 191.34 434.69 422.79 451.59 424.47 461.72 410.96 458.34 157.56 36 160.93 41.07 191.34" fill="#3fe2fd"></polygon>
                                    </g>
                                    <g id="Layer_11" dataname="Layer 11">
                                        <path d="M447.33,74.45H52.72A21.5,21.5,0,0,0,31.27,95.9V408.59a21.52,21.52,0,0,0,21.4,21.47H447.28a21.49,21.49,0,0,0,21.45-21.47V95.9h0A21.52,21.52,0,0,0,447.33,74.45Zm-.05,341.61H52.7a7.48,7.48,0,0,1-7.43-7.47V166.87H454.73V408.59A7.48,7.48,0,0,1,447.28,416.06ZM52.72,88.45H447.3a7.48,7.48,0,0,1,7.43,7.46v57H45.27v-57A7.47,7.47,0,0,1,52.72,88.45Z" fill="#251ac7"></path>
                                        <path d="M74,118.09a5.9,5.9,0,0,0,.65,1.21,7.33,7.33,0,0,0,.86,1.07,7,7,0,0,0,9.9,0,6.79,6.79,0,0,0,.87-1.07,6.37,6.37,0,0,0,.64-1.21,7.48,7.48,0,0,0,.41-1.32,6.79,6.79,0,0,0,.13-1.35,7,7,0,0,0-.13-1.37,7.48,7.48,0,0,0-.41-1.32,6.69,6.69,0,0,0-.64-1.21,6.22,6.22,0,0,0-.87-1,6,6,0,0,0-1.07-.88,7.75,7.75,0,0,0-1.21-.65,6.49,6.49,0,0,0-1.3-.39,7,7,0,0,0-2.74,0,6.69,6.69,0,0,0-1.3.39,8.23,8.23,0,0,0-1.21.65,6.34,6.34,0,0,0-1.07.88,6.68,6.68,0,0,0-.86,1,6.17,6.17,0,0,0-.65,1.21,8.42,8.42,0,0,0-.41,1.32,7.85,7.85,0,0,0-.13,1.37,7.62,7.62,0,0,0,.13,1.35A8.42,8.42,0,0,0,74,118.09Z" fill="#251ac7"></path>
                                        <path d="M104.14,118.09a7,7,0,0,0,.63,1.21,8.13,8.13,0,0,0,.87,1.07,9,9,0,0,0,1.07.86,7.05,7.05,0,0,0,1.21.65,7.22,7.22,0,0,0,1.3.39,6.46,6.46,0,0,0,1.38.14,6.31,6.31,0,0,0,1.37-.14,7.47,7.47,0,0,0,1.3-.39,7.47,7.47,0,0,0,2.28-1.51,7.4,7.4,0,0,0,.87-1.07,7,7,0,0,0,.64-1.21,7.45,7.45,0,0,0,.4-1.32,6.8,6.8,0,0,0,.14-1.35,7,7,0,0,0-.14-1.37,7.16,7.16,0,0,0-1-2.53,6.74,6.74,0,0,0-.87-1,6,6,0,0,0-1.07-.88,8.23,8.23,0,0,0-1.21-.65,6.69,6.69,0,0,0-1.3-.39,7.07,7.07,0,0,0-2.75,0,6.91,6.91,0,0,0-1.3.39,8.77,8.77,0,0,0-1.21.65,6.34,6.34,0,0,0-1.07.88,6.45,6.45,0,0,0-1.5,2.26,7.48,7.48,0,0,0-.41,1.32,7,7,0,0,0-.13,1.37,6.79,6.79,0,0,0,.13,1.35A7.48,7.48,0,0,0,104.14,118.09Z" fill="#251ac7"></path>
                                        <path d="M134.26,118.09a7,7,0,0,0,.63,1.21,9.1,9.1,0,0,0,.88,1.07,7,7,0,0,0,9.9-9.9,6,6,0,0,0-1.07-.88,8.77,8.77,0,0,0-1.21-.65,6.49,6.49,0,0,0-1.3-.39,7,7,0,0,0-2.74,0,6.69,6.69,0,0,0-1.3.39,8.23,8.23,0,0,0-1.21.65,6,6,0,0,0-1.07.88,8.15,8.15,0,0,0-.88,1,7.33,7.33,0,0,0-.63,1.21,8.42,8.42,0,0,0-.41,1.32,7.85,7.85,0,0,0-.13,1.37,7.62,7.62,0,0,0,.13,1.35A8.42,8.42,0,0,0,134.26,118.09Z" fill="#251ac7"></path>
                                        <path d="M177.57,205.51H87.2a7,7,0,0,0-7,7V351a7,7,0,0,0,7,7h90.37a7,7,0,0,0,7-7V212.51A7,7,0,0,0,177.57,205.51ZM94.2,257h76.37V344H94.2Zm0-14v-23.5h76.37V243Z" fill="#251ac7"></path>
                                        <path d="M299.35,205.51H209a7,7,0,0,0-7,7V351a7,7,0,0,0,7,7h90.37a7,7,0,0,0,7-7V212.51A7,7,0,0,0,299.35,205.51ZM216,257h76.37V344H216Zm0-14v-23.5h76.37V243Z" fill="#251ac7"></path>
                                        <path d="M421.13,205.51H330.76a7,7,0,0,0-7,7V351a7,7,0,0,0,7,7h90.37a7,7,0,0,0,7-7V212.51A7,7,0,0,0,421.13,205.51Zm-7,37.5H337.76v-23.5h76.37Zm-76.37,14h76.37V344H337.76Z" fill="#251ac7"></path>
                                        <path d="M145.85,268.86H118.93a7,7,0,0,0,0,14h26.92a7,7,0,1,0,0-14Z" fill="#251ac7"></path>
                                        <path d="M145.85,291.08H118.93a7,7,0,0,0,0,14h26.92a7,7,0,0,0,0-14Z" fill="#251ac7"></path>
                                        <path d="M145.85,313.87H118.93a7,7,0,0,0,0,14h26.92a7,7,0,1,0,0-14Z" fill="#251ac7"></path>
                                        <path d="M267,268.86H240.07a7,7,0,0,0,0,14H267a7,7,0,0,0,0-14Z" fill="#251ac7"></path>
                                        <path d="M267,291.08H240.07a7,7,0,0,0,0,14H267a7,7,0,0,0,0-14Z" fill="#251ac7"></path>
                                        <path d="M267,313.87H240.07a7,7,0,0,0,0,14H267a7,7,0,0,0,0-14Z" fill="#251ac7"></path>
                                        <path d="M388.12,268.86H361.2a7,7,0,1,0,0,14h26.92a7,7,0,0,0,0-14Z" fill="#251ac7"></path>
                                        <path d="M388.12,291.08H361.2a7,7,0,0,0,0,14h26.92a7,7,0,0,0,0-14Z" fill="#251ac7"></path>
                                        <path d="M388.12,313.87H361.2a7,7,0,1,0,0,14h26.92a7,7,0,0,0,0-14Z" fill="#251ac7"></path>
                                    </g>
                                </svg>
                            </span>

                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12} sx={{ pr: 10, pt: 2 }} justifyContent="center">
                    <Typography gutterBottom variant="h4" color="text.secondary" sx={{fontWeight: 300}}>
                        Review your recent security activity
                    </Typography>
                    <Typography sx={{ mb: 4, borderBottom: 1, borderColor: '#e0e0e0', pb: 3 }} variant="h6" color="text.secondary">
                        See and manage changes made to how you sign in to your account.
                    </Typography>
                </Grid>
                <Grid item xs={12} sx={{ pt: 5, }}>
                    <Box sx={{ bgcolor: '#fafafa', width: '50%', pb: 1, border: 1, borderColor: '#bdbdbd' }}>
                        <Typography gutterBottom variant="h6" color="text.secondary" sx={{ml: 3, pt: 2}}>
                            Recent Activity
                        </Typography>
                        <List sx={{ width: '100%' }}>
                            <ListItem>
                                <ListItemAvatar>
                                    <Avatar sx={{ bgcolor: '#f0f3f5'}}>
                                        <LockOutlinedIcon color="action" />
                                    </Avatar>
                                </ListItemAvatar>
                                <ListItemText sx={{ color: '#424242' }} primary="Password changed" secondary="19 hours ago" />
                            </ListItem>
                            <ListItem>
                                <ListItemAvatar>
                                    <Avatar sx={{ bgcolor: '#f0f3f5' }}>
                                        <LockOutlinedIcon color="action" />
                                    </Avatar>
                                </ListItemAvatar>
                                <ListItemText sx={{ color: '#424242' }} primary="Password changed" secondary="yesterday" />
                            </ListItem>
                            <ListItem>
                                <ListItemAvatar>
                                    <Avatar sx={{ bgcolor: '#f0f3f5' }}>
                                        <LockOutlinedIcon color="action" />
                                    </Avatar>
                                </ListItemAvatar>
                                <ListItemText sx={{ color: '#424242' }} primary="Password changed" secondary="September 26, 2022" />
                            </ListItem>
                        </List>
                        <Box sx={{ borderTop: 1, borderColor: '#bdbdbd', pt: 2}}>
                            <Typography gutterBottom variant="body1" color="text.secondary" sx={{ ml: 3 }}>
                                <Link href="#" underline="hover" variant="body2" sx={{ mb: 2, }}>
                                    View all recent activities
                                </Link>
                            </Typography>
                        </Box>
                    </Box>
                </Grid>
                <Grid item xs={12} sx={{ pr: 10, pt: 6}} justifyContent="center">
                    <Typography gutterBottom variant="h4" color="text.secondary" sx={{fontWeight: 300}}>
                        Review connected devices and apps
                    </Typography>
                    <Typography sx={{ mb: 4, borderBottom: 1, borderColor: '#e0e0e0', pb: 3 }} variant="h6" color="text.secondary">
                        See all of the devices and apps that are connecting to your account.
                        If you see something suspicious, mail us.
                    </Typography>
                </Grid>
                <Grid item xs={12} sx={{ pt: 5, }}>
                    <Box sx={{ bgcolor: '#fafafa', width: '50%', pb: 1, border: 1, borderColor: '#bdbdbd' }}>
                        <Typography gutterBottom variant="h6" color="text.secondary" sx={{ ml: 3, pt: 2 }}>
                            Connected devices
                        </Typography>
                        <List sx={{ width: '100%' }}>
                            <ListItem>
                                <ListItemAvatar>
                                    <Avatar sx={{ bgcolor: '#f0f3f5' }}>
                                        <ComputerOutlinedIcon color="action" />
                                    </Avatar>
                                </ListItemAvatar>
                                <ListItemText sx={{ color: '#424242' }} primary="Chrome, Windows"
                                    secondary={
                                        <React.Fragment>
                                            <Stack direction="row" sx={{ pt: 0 }}>
                                                <PlaceOutlinedIcon color="action" sx={{ fontSize: 20 }} />
                                                <Typography sx={{ pl: 0 }}
                                                    variant="caption"
                                                    color="text.primary"
                                                >
                                                    California, USA
                                                </Typography>
                                            </Stack>
                                            <Typography sx={{ pt: 0 }}
                                                variant="caption"
                                                color="text.primary"
                                            >
                                                19 hours ago
                                            </Typography>
                                        </React.Fragment>
                                    }
                                />
                            </ListItem>
                            <ListItem>
                                <ListItemAvatar>
                                    <Avatar sx={{ bgcolor: '#f0f3f5' }}>
                                        <ComputerOutlinedIcon color="action" />
                                    </Avatar>
                                </ListItemAvatar>
                                <ListItemText sx={{ color: '#424242' }} primary="Edge, Windows"
                                    secondary={
                                        <React.Fragment>
                                            <Stack direction="row" sx={{pt: 0}}>
                                            <PlaceOutlinedIcon color="action" sx={{ fontSize: 20 }} />
                                                <Typography sx={{pl: 0}}
                                                variant="caption"
                                                color="text.primary"
                                            >
                                                California, USA
                                            </Typography>
                                                </Stack>
                                            <Typography sx={{ pt: 0 }}
                                                variant="caption"
                                                color="text.primary"
                                            >
                                                19 hours ago
                                            </Typography>
                                        </React.Fragment>
                                    }
                                />
                            </ListItem>
                            <ListItem>
                                <ListItemAvatar>
                                    <Avatar sx={{ bgcolor: '#f0f3f5' }}>
                                        <ComputerOutlinedIcon color="action" />
                                    </Avatar>
                                </ListItemAvatar>
                                <ListItemText sx={{ color: '#424242' }} primary="Chrome, Windows"
                                    secondary={
                                        <React.Fragment>
                                            <Stack direction="row" sx={{ pt: 0 }}>
                                                <PlaceOutlinedIcon color="action" sx={{ fontSize: 20 }} />
                                                <Typography sx={{ pl: 0 }}
                                                    variant="caption"
                                                    color="text.primary"
                                                >
                                                    California, USA
                                                </Typography>
                                            </Stack>
                                            <Typography sx={{ pt: 0 }}
                                                variant="caption"
                                                color="text.primary"
                                            >
                                                September 26, 2022
                                            </Typography>
                                        </React.Fragment>
                                    }
                                />
                            </ListItem>
                        </List>
                        <Box sx={{ borderTop: 1, borderColor: '#bdbdbd', pt: 2 }}>
                            <Typography gutterBottom variant="body1" color="text.secondary" sx={{ ml: 3 }}>
                                <Link href="#" underline="hover" variant="body2" sx={{ mb: 2, }}>
                                    View all connected devices
                                </Link>
                            </Typography>
                        </Box>
                    </Box>
                </Grid>

            </Grid>
        </React.Fragment>
    );
}

export default ProfileRecentActivity;