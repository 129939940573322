import React, { useEffect, useState } from 'react';
import { useNavigate , useLocation, Redirect } from "react-router-dom";
import { useFormik } from 'formik';
import * as yup from 'yup';
import { PreLoginMenu } from './PreLoginMenu';
import Footer from './Footer';
import AlertText from './Controls/AlertText';
import LoginFormRow1 from './LoginFormRow1';
import LoginFormRow2 from './LoginFormRow2';
import LoginFormRow3 from './LoginFormRow3';
import { ApiHelper } from '../customFunctions/apiHelper';
import { ModalMessage } from './ModalMessage';

const ChangePassword = () => {
    const [submitting, setSubmitting] = useState(false);
    const [alertMsg, setAlertMsg] = useState('');
    const [validUrl, setValidUrl] = useState(false);    
    const [redirctTo, setRedirctTo] = useState(false);

    let history = useNavigate();

    const pwdRegExp = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/;

    const chngPwdForm = useFormik({
        initialValues: {           
            Password: '',
            ConfirmPassword: ''
        },
        validationSchema: yup.object({
            Password: yup.string()
                .max(20, "Password - must be a string with a minimum length of '10' and a maximum length of '20'.")
                .min(10, "Password - must be a string with a minimum length of '10' and a maximum length of '20'.")
                .matches(pwdRegExp,
                    "Password - must be contain '10' characters, one uppercase, one lowercase, one number and one special character."
                )
                .required('Password - required.'),
            ConfirmPassword: yup.string()
                .max(20, "Password - must be a string with a minimum length of '10' and a maximum length of '20'.")
                .min(10, "Password - must be a string with a minimum length of '10' and a maximum length of '20'.")
                .matches(pwdRegExp,
                    "Password - must be contain '10' characters, one uppercase, one lowercase, one number and one special character."
                )
                .when("Password", {
                    is: psswrd => (psswrd && psswrd.length > 0 ? true : false),
                    then: yup.string().oneOf(
                        [yup.ref("Password")],
                        "Both password need to be the same.")
                })
                .required('Confirm Password - required.')
        }),
        onSubmit: values => {
            handleSubmit(values);
        }
    });


    const handleSubmit = (values) => {
        //API Call
        
        let headers = {
            'Content-Type': 'application/json'
        };

        let bodyContent = {
            "EncryptedEmailAddress": email,
            "EncryptedAuthCode": code,
            "Password": values.Password,
            "CPassword": values.ConfirmPassword
        }

        ApiHelper({ url: process.env.REACT_APP_VALIDATE_CHANGE_PASSWORD, method: 'POST', headers: headers, body: bodyContent })
            .then(resposnse => {
                if (resposnse !== null) {
                    if (parseInt(resposnse.CntctId) > 0) {
                        setSubmitting(true);//Disabled                        
                        setAlertMsg('Your password has been changed. The new password is activated. You can log in to your account with the new credential.');
                    }
                    else {
                        setSubmitting(false);//Enabled
                        setAlertMsg(resposnse.Message);
                    }
                } else {
                    setSubmitting(false);//Enabled
                    setAlertMsg(resposnse.Message || 'Error found!!');
                }
            });

    }

    const login = (event) => {
        event.preventDefault();
        history.push({
            pathname: '/'
        })
    }

    const search = useLocation().search;
    let email = new URLSearchParams(search).get("email");
    let code = new URLSearchParams(search).get("code");

    const verifyContent = (emailAdrs, authCode) => {
        let headers = {
            'Content-Type': 'application/json'
        };

        let values = {
            "EncryptedEmailAddress": emailAdrs,
            "EncryptedAuthCode": authCode
        }

        ApiHelper({ url: process.env.REACT_APP_VALIDATE_CHANGE_PASSWORD_REQUEST, method: 'POST', headers: headers, body: values })
            .then(resposnse => {
                if (resposnse !== null) {
                    if (parseInt(resposnse.CntctId) > 0) {
                        setValidUrl(true);//Allow           
                        setRedirctTo(false);
                        //setAlertMsg(resposnse.Message);
                    }
                    else {
                        setValidUrl(false);//NotAllow
                        setAlertMsg(resposnse.Message || 'internal_error');
                        setRedirctTo(true);
                        //setAlertMsg(resposnse.Message);
                    }
                } else {
                    setValidUrl(false);//NotAllow
                    setAlertMsg(resposnse.Message || 'internal_error');
                    setRedirctTo(true);
                }
            });
    }

    useEffect(() => {
        //load OnlyOnce
        (() => {
            verifyContent(email, code);
        })();
    }, []);


    if (redirctTo) {
        return <></>
    } else {
        return (
            <>
                <PreLoginMenu />
                <section className="bg-light text-secondary">
                    <section className="text-muted">
                        <div className="container pb-5 pt-5">
                            <div className="align-items-center gy-5 rounded-3 row justify-content-center">
                                <div className="bg-white p-b-5 p-t-5 p-s-8 p-e-8 rounded-2 shadow" style={{ maxWidth: '450px', minHeight: '400px' }}>
                                    <h4 className="mb-1 text-center text-primary pt-2">Change Password</h4>
                                    <p style={{textAlign: 'center'}}>mrityunjoy@fi-tek.com</p>
                                    <div className="alert alert-custom-info bg-soft-primary border-0 p-s-2 p-e-2" role="alert">
                                        <p className="p-b-0 m-b-0" style={{fontWeight: 'bold'}}>Create a strong password</p>
                                        <p className="p-t-0 p-b-0 m-b-0">Create a new, strong password that you don’t use for other websites</p>
                                    </div>
                                    <form onSubmit={chngPwdForm.handleSubmit} id="forgetPassForm" >
                                        <AlertText Message={alertMsg} />
                                        <div className="mb-3">
                                            <div className="input-group">
                                                <span className="bg-light input-group-text" id="addon-wrapping2">
                                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" width="1em" height="1em">
                                                        <g>
                                                            <path fill="none" d="M0 0h24v24H0z"></path>
                                                            <path d="M10.758 11.828l7.849-7.849 1.414 1.414-1.414 1.415 2.474 2.474-1.414 1.415-2.475-2.475-1.414 1.414 2.121 2.121-1.414 1.415-2.121-2.122-2.192 2.192a5.002 5.002 0 0 1-7.708 6.294 5 5 0 0 1 6.294-7.708zm-.637 6.293A3 3 0 1 0 5.88 13.88a3 3 0 0 0 4.242 4.242z"></path>
                                                        </g>
                                                    </svg>
                                                </span>
                                                <input type="password" className="form-control" placeholder="Password" aria-label="Password" aria-describedby="addon-wrapping2" name="Password" id="Password" onChange={chngPwdForm.handleChange} value={chngPwdForm.values.Password} placeholder="Password" disabled={!validUrl} />
                                            </div>
                                            {chngPwdForm.touched.Password && chngPwdForm.errors.Password ? (
                                                <p className="text-danger">{chngPwdForm.errors.Password}</p>
                                            ) : null}
                                        </div>
                                        <div className="">
                                            <div className="input-group">
                                                <span className="bg-light input-group-text" id="addon-wrapping2">
                                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" width="1em" height="1em">
                                                        <g>
                                                            <path fill="none" d="M0 0h24v24H0z"></path>
                                                            <path d="M10.758 11.828l7.849-7.849 1.414 1.414-1.414 1.415 2.474 2.474-1.414 1.415-2.475-2.475-1.414 1.414 2.121 2.121-1.414 1.415-2.121-2.122-2.192 2.192a5.002 5.002 0 0 1-7.708 6.294 5 5 0 0 1 6.294-7.708zm-.637 6.293A3 3 0 1 0 5.88 13.88a3 3 0 0 0 4.242 4.242z"></path>
                                                        </g>
                                                    </svg>
                                                </span>
                                                <input type="password" className="form-control" aria-label="Password" aria-describedby="addon-wrapping2" name="ConfirmPassword" id="ConfirmPassword" onChange={chngPwdForm.handleChange} value={chngPwdForm.values.ConfirmPassword} placeholder="Confirm Password" disabled={!validUrl}/>
                                                
                                            </div>
                                            {chngPwdForm.touched.ConfirmPassword && chngPwdForm.errors.ConfirmPassword ? (
                                                <p className="text-danger">{chngPwdForm.errors.ConfirmPassword}</p>
                                            ) : null}
                                        </div>
                                        <p className="p-t-1-2 m-t-0 m-b-8"><small>At least 10 characters</small></p>
                                        <button type="submit" className="btn btn-primary d-block fw-bold pb-2 pe-3 ps-3 pt-2 w-100" disabled={!validUrl} >Save password</button>
                                        <div className="p-2 text-center">Remeber password? <span className="text-primary font-weight-bold"><a type="button" id="btnRegister" onClick={(e) => login(e)}>Login</a></span></div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </section>
                </section>       
                <ModalMessage
                    show={submitting}
                    backdrop="static"
                    keyboard={false}
                    onHide={() => setSubmitting(false)}
                    title={"Message"}
                    message={alertMsg}
                    path={"/"}
                />
                <Footer />
            </>
        );
    }
}
export default ChangePassword;