import React, { useCallback, useState, useEffect, useRef, useMemo, } from 'react';
import { useNavigate, useLocation } from "react-router-dom";
import { QueryClient, QueryClientProvider, useInfiniteQuery, } from '@tanstack/react-query';
import { Virtualizer } from '@tanstack/react-virtual';
import MaterialReactTable, { MRT_ShowHideColumnsButton, MRT_ToggleGlobalFilterButton, MRT_ToggleFiltersButton, } from 'material-react-table';
import { ErrorMessages } from '../common/ErrorMessages';
import AlertControl from './Controls/AlertControl';
import Container from '@mui/material/Container';
import { APICall } from '../global/Api';
import AutoLagoutTimer from '../global/IdleMonitor';
import { Box, Typography, Button, Paper, Table, Divider, TableBody, TableRow, TableCell, Stack, Grid, Tooltip, IconButton, TableContainer } from '@mui/material';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import moment from "moment";
import { jsPDF } from 'jspdf'; //or use your library of choice here
import autoTable from 'jspdf-autotable';
import OverlaySpinner from './Controls/OverlaySpinner';
import PostLoginMenu from './PostLoginMenu';
import EnrollmentHeader from './EnrollmentHeader';
import Footer from './Footer';
import { DrawerAppBar } from './AppNavbar';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox, { checkboxClasses } from "@mui/material/Checkbox";
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';

const ActivityHistories = (props) => {

    const location = useLocation();

    const [isDataLoading, setIsDataLoading] = useState(false);
    const [rowSelection, setRowSelection] = useState({});
    const [userId, setUserID] = useState(location.state.userId || -1);
    const [sessionId, setSessionId] = useState(location.state.sessionId || '');
    const [searchText, setSearchText] = useState('');
    const [fromDate, setFromDate] = useState(new Date(new Date().setDate(new Date().getDate() - 30)).toLocaleDateString('en-CA'));
    const [toDate, setToDate] = useState(new Date().toLocaleDateString('en-CA'));
    const [errorMsg, setErrorMsg] = useState('');
    const [ignoreDate, setIgnoreDate] = useState(false);
    const [disFromDate, setDisFromDate] = useState(false);
    const [disToDate, setDisToDate] = useState(false);
    const [dispArrow, setDispArrow] = useState('none');
    const [totalDBRowCount, setTotalDBRowCount] = useState(0);
    const [actualTotalFetched, setActualTotalFetched] = useState(0);

    const [activityDate, setActivityDate] = useState(null);
    const [emailAddress, setEmailAddress] = useState(null);
    const [userName, setUserName] = useState(null);
    const [userActivity, setUserActivity] = useState(null);
    const [activityDetail, setActivityDetail] = useState(null);
    const [userIPAddress, setUserIPAddress] = useState(null);
    const [country, setCountry] = useState(null);
    const [browserName, setBrowserName] = useState(null);
    const [platform, setPlatform] = useState(null);

    const columns = [
        {
            accessorKey: 'ActivityDt',
            header: 'Date',
            muiTableBodyCellProps: ({ cell }) => ({
                sx: (theme) => ({
                    color: 'text.primary',
                    fontSize: theme.typography.body1,
                    borderLeft: '1px #BBBFBF solid',
                    borderBottom: '1px #BBBFBF solid',
                    pl: 2, fontWeight: 400, textAlign: 'center', pt: 1, pb: 1,
                }),
            }),
            muiTableHeadCellProps: ({ cell }) => ({
                sx: (theme) => ({
                    color: 'text.primary',
                    fontSize: theme.typography.body2,
                    borderTop: '1px #BBBFBF solid',
                    borderLeft: '1px #BBBFBF solid',
                    borderBottom: '1px #BBBFBF solid',
                    fontWeight: 400,
                    pl: 2, pr: 2,
                    pt: 1, pb: 1,
                }),
            }),
            size: 200
        },
        {
            accessorKey: 'EmailAddress',
            header: 'Email Address',
            size: 300
        },
        {
            accessorKey: 'Activity',
            header: 'Activity',
            size: 230
        },
        {
            accessorKey: 'AdditionalInformation',
            header: 'Activity Detail',
            size: 300
        },
        {
            accessorKey: 'FullName',
            header: 'Name',
            size: 230
        },
        {
            accessorKey: 'IPAddress',
            header: 'IP Address',
            size: 160
        },
    //    {
    //        accessorKey: 'Country',
    //        header: 'Country',
    //        size: 180
    //    },
    //    {
    //        accessorKey: 'BrowserName',
    //        header: 'Browser',
    //        size: 180
    //    },
    //    {
    //        accessorKey: 'Platform',
    //        header: 'Platform',
    //        size: 180
    //    },
    ];

    const fetchSize = 50;

    const tableContainerRef = useRef(null); //we can get access to the underlying TableContainer element and react to its scroll events
    const virtualizerInstanceRef = useRef < Virtualizer > null; //we can get access to the underlying Virtualizer instance and call its scrollToIndex method

    const [columnFilters, setColumnFilters] = useState([]);
    const [globalFilter, setGlobalFilter] = useState();
    const [sorting, setSorting] = useState([]);

    const { data, fetchNextPage, isError, isFetching, isLoading, refetch } =
        useInfiniteQuery(
            ['table-data', columnFilters, globalFilter, sorting],
            async ({ pageParam = 0 }) => {

                return getHistoryData(pageParam, true);

            },
            {
                getNextPageParam: (_lastGroup, groups) => groups.length,
                keepPreviousData: true,
                refetchOnWindowFocus: false,
            },
        );

    const flatData = useMemo(
        () => data?.pages.flatMap((page) => page.data) ?? [],
        [data],
    );

    //const totalDBRowCount = data?.pages?.[0]?.meta?.totalRowCount ?? 0;
    const totalFetched = actualTotalFetched;
    const fetchMoreOnBottomReached = useCallback(
        (containerRefElement) => {
            if (containerRefElement) {
                const { scrollHeight, scrollTop, clientHeight } = containerRefElement;
                if (
                    scrollHeight - scrollTop - clientHeight < 400 &&
                    !isFetching &&
                    totalFetched < totalDBRowCount
                ) {
                    fetchNextPage();
                }
            }
        },
        [fetchNextPage, isFetching, totalFetched, totalDBRowCount],
    );

    const getHistoryData = async (pageParam, paginate) => {

        const apiUrl = new URL(
            process.env.REACT_APP_GET_USER_ACTIVITY_HISTORY,
            process.env.REACT_APP_BASE_URL,
        );

        apiUrl.searchParams.set('start', `${pageParam * fetchSize}`);
        apiUrl.searchParams.set('size', `${fetchSize}`);

        var date1 = fromDate !== null ? moment(fromDate, 'YYYY-MM-DD') : null;
        var date2 = toDate !== null ? moment(toDate, 'YYYY-MM-DD') : null;

        if (!ignoreDate) {
            apiUrl.searchParams.set('startTime', date1 !== null ? date1.format('MM/DD/YYYY') : ``);
            apiUrl.searchParams.set('endTime', date2 !== null ? date2.format('MM/DD/YYYY') : ``);
        }

        apiUrl.searchParams.set('globalFilter', `${searchText}`);
        apiUrl.searchParams.set('paginate', `${paginate}`);

        let response = await APICall({ url: apiUrl.href, method: 'GET', contentType: 'application/json', responseType: '' }, userId, sessionId);
        if (response !== null && response !== undefined) {

            setTotalDBRowCount(parseInt(response.meta.totalRowCount));
            setActualTotalFetched(parseInt(pageParam) * parseInt(fetchSize) + parseInt(response.meta.actualRows));

            if (parseInt(response.meta.actualRows) < parseInt(fetchSize)) {
                setDispArrow('none');
            }
            else {
                setDispArrow('inline-block');
            }

            return response;
        }
        else {
            throw new Error(ErrorMessages.UnknownError);
        }
    };

    useEffect(() => {

        if (virtualizerInstanceRef.current) {
            virtualizerInstanceRef.current.scrollToIndex(0);
        }
    }, [sorting, columnFilters, globalFilter]);

    useEffect(() => {

        fetchMoreOnBottomReached(tableContainerRef.current);
    }, [fetchMoreOnBottomReached]);

    const FindClick = (event) => {

        if (!ignoreDate) {

            if (fromDate == '') {
                setErrorMsg('Invalid from date');
                return;
            }

            if (toDate == '') {
                setErrorMsg('Invalid to date');
                return;
            }

            var date1 = fromDate !== null ? moment(fromDate, 'YYYY-MM-DD') : null;
            var date2 = toDate !== null ? moment(toDate, 'YYYY-MM-DD') : null;

            if (date1 > date2) {
                setErrorMsg('The To Date must be greater than the From Date');
                return;
            }
        }

        setErrorMsg('');
        setActivityDate(null);
        setEmailAddress(null);
        setUserName(null);
        setUserActivity(null);
        setActivityDetail(null);
        setUserIPAddress(null);
        setCountry(null);
        setBrowserName(null);
        setPlatform(null);

        refetch();
    };

    const HandleRowClick = async (row) => {
        setActivityDate(row.original.ActivityDt);
        setEmailAddress(row.original.EmailAddress);
        setUserName(row.original.FullName);
        setUserActivity(row.original.Activity);
        setActivityDetail(row.original.AdditionalInformation);
        setUserIPAddress(row.original.IPAddress);
        setCountry(row.original.Country);
        setBrowserName(row.original.BrowserName);
        setPlatform(row.original.Platform);

    }

    const handleSearchTextChange = (event) => {
        setSearchText(event.target.value);
    };

    const handleStartDateChange = (event) => {
        setFromDate(event.target.value);
    };

    const handleEndDateChange = (event) => {
        setToDate(event.target.value);
    };

    const exportToPDF = async () => {

        try {

            let msg = 'You can export a maximum of 30 days of historical data or search history data by first name, last name, email address';

            if (ignoreDate) {
                setErrorMsg(msg);
                return;
            }

            if (fromDate == '') {
                setErrorMsg('Invalid from date');
                return;
            }

            if (toDate == '') {
                setErrorMsg('Invalid to date');
                return;
            }

            let date1 = fromDate !== '' ? moment(fromDate, 'YYYY-MM-DD') : null;
            let date2 = toDate !== '' ? moment(toDate, 'YYYY-MM-DD') : new Date().setHours(0, 0, 0, 0);

            if (date1 > date2) {
                setErrorMsg('The To Date must be greater than the From Date');
                return;
            }

            let diffDays;
            let Difference_In_Days;

            if (searchText.length == 0) {

                if (date1 == null) {
                    setErrorMsg(msg);
                    return;
                }

                diffDays = date2 - date1;
                Difference_In_Days = Math.round(diffDays / (1000 * 3600 * 24));

                if (Difference_In_Days > 30) {
                    setErrorMsg(msg);
                    return;
                }
            }

            setIsDataLoading(true);
            setErrorMsg('');

            const doc = new jsPDF({
                orientation: "landscape",
                unit: 'pt',
                format: 'a4'
            });

            const tableData = await getHistoryData(0, false);

            if (tableData.data.length == 0) {
                setErrorMsg('No data to export');
                return;
            }

            var x = new Array(tableData.data.length);

            for (var i = 0; i < x.length; i++) {
                x[i] = new Array(7);
                x[i][0] = tableData.data[i].ActivityDt;
                x[i][1] = tableData.data[i].EmailAddress;
                x[i][2] = tableData.data[i].FullName;
                x[i][3] = tableData.data[i].Activity;
                x[i][4] = tableData.data[i].AdditionalInformation;
            }

            autoTable(doc, {
                head: [['Date', 'Email Address', 'User', 'Activity', 'Activity Detail']],
                columnStyles: { 0: { cellWidth: 100 }, 1: { cellWidth: 110 }, 2: { cellWidth: 150 }, 3: { cellWidth: 100 }},
                body: x,
            });

            doc.setFontSize(12);
            const pageCount = doc.internal.getNumberOfPages();
            for (let i = 1; i <= pageCount; i++) {
                doc.setPage(i);
                const pageSize = doc.internal.pageSize;
                const pageWidth = pageSize.width ? pageSize.width : pageSize.getWidth();
                const pageHeight = pageSize.height ? pageSize.height : pageSize.getHeight();
                const header = 'User activity detail';
                const footer = `Page ${i} of ${pageCount}`;

                // Header
                doc.text(header, 40, 15, { baseline: 'top' });

                // Footer
                doc.text(footer, pageWidth / 2 - (doc.getTextWidth(footer) / 2), pageHeight - 15, { baseline: 'bottom' });
            }

            doc.save('UserActivity.pdf');
        }
        catch (err) {
            setErrorMsg(ErrorMessages.UnknownError);
        }
        finally {
            setIsDataLoading(false);
        }

    };

    const handleIgnoreDateChange = (event) => {

        setDisFromDate(event.target.checked);
        setDisToDate(event.target.checked);
        setIgnoreDate(event.target.checked);
    };

    return (
        <React.Fragment>
            <AutoLagoutTimer userId={userId} sessionId={sessionId} />
            <Container maxWidth="lg" sx={{ background: '#4C4C4C' }} className="">
                <Paper className="back-grid">
                    <PostLoginMenu userId={userId} sessionId={sessionId} />
                    <Grid item md={12} id="aobhead">
                        <Box sx={{
                            pr: 0,
                            pl: 0, pt: 0
                        }}>
                            <EnrollmentHeader />
                        </Box>
                    </Grid>
                    <Grid item sx={{ pr: 1 }}>
                        <DrawerAppBar userId={userId} sessionId={sessionId} />
                    </Grid>
                    <Grid item md={12} xs={12} sx={{}}>
                        <Box sx={{ pl: 0, pt: 0 }}>
                            <Box sx={{ display: 'flex', pb: 2, pt: 0, ml: 6.5, mr: 6.5, justifyContent: 'space-between', alignItems: 'flex-end', borderBottom: '0px solid #898F94' }}>
                                <Typography variant="h6" color="text.primary" sx={{ pl: 0, pb: 0, lineHeight: 1, fontWeight: 500, pb: 0, letterSpacing: '0px' }} >User Activity Log</Typography>
                            </Box>
                            <Box sx={{ }}>
                                <Divider variant="fullWidth" component="div" sx={{ }} />
                            </Box>
                            <Box sx={{ mt: 0, pt: 3, pb: 2, ml: 6.5, mr: 6.5, borderTop: '0px solid #A8B3B3', borderBottom: '0px solid #d2d2d2' }}>
                                <Typography sx={{ fontWeight: 400, }} variant="body1" color="text.primary">
                                    The User activity log displays user activities based on your filter criteria. It shows you when users took different actions in the application.
                                    It can be utilized by administrators for tracking the activity of individual users.
                                </Typography>
                            </Box>
                        </Box>
                    </Grid>
                    <Grid item md={12} xs={12} sx={{}}>
                        <Box sx={{ ml: 6.5, mr: 6.5 }}>
                            <AlertControl Message={errorMsg} severity="error" color="error" icon={true} />
                        </Box>
                    </Grid>
                    <Grid item md={12} xs={12} sx={{}}>
                        <Box sx={{ ml: 6.5, mr: 6.5 }}>
                            <Grid container spacing={2}>
                                <Grid item xs={8}>
                                    <Stack direction="row" spacing={2.5}>
                                        <Box>
                                            <label htmlFor="DateFrom" className="form-label">From Date</label>
                                            <input type="date" id="DateFrom" style={{ width: '250px' }} disabled={disFromDate} name="DateFrom" placeholder="Enter start date here" className="form-control" onChange={handleStartDateChange} value={fromDate} />
                                        </Box>
                                        <Box>
                                            <label htmlFor="DateTo" className="form-label">To Date</label>
                                            <input type="date" id="DateTo" style={{ width: '250px' }} name="DateTo" disabled={disToDate} placeholder="Enter end date here" className="form-control" onChange={handleEndDateChange} value={toDate} />
                                        </Box>
                                        <Box sx={{ pt: 2 }}>
                                            <FormGroup>
                                                <FormControlLabel control={<Checkbox sx={{
                                                    [`&, &.${checkboxClasses.checked}`]: {
                                                        color: 'Check.main',
                                                    },
                                                }} onChange={handleIgnoreDateChange} />} label={<Typography variant="body1" color="text.primary">Ignore dates</Typography>} />
                                            </FormGroup>
                                        </Box>
                                    </Stack>
                                    <Box sx={{ mt: 2.5, mr: 4 }}>
                                        <input type="text" id="FullName" name="FullName" placeholder="Search by name, email address, activity, activity detail, ip address" onChange={handleSearchTextChange} value={searchText} className="form-control" />
                                    </Box>
                                </Grid>
                                {/*<Grid item xs={9}>*/}
                                {/*    <Box sx={{ mt: 0.5 }}>*/}
                                {/*        <input type="text" id="FullName" name="FullName" placeholder="Search by name, email address, activity, activity detail, ip address" onChange={handleSearchTextChange} value={searchText} className="form-control" />*/}
                                {/*    </Box>*/}
                                {/*</Grid>*/}
                                <Grid item xs={4}>
                                    <Box sx={{ pl: 3, pt: 0, pb: 0.5 }}>
                                        <Stack direction="column" spacing={0.5}>
                                            <Box sx={{ pl: 0, pt: 1 }}>
                                                <Button type="submit" onClick={(e) => FindClick(e)} variant="contained" size="large" style={{ borderRadius: '20px', padding: '0.3rem 2.5rem', textTransform: 'none'}} >
                                                    Find
                                                </Button>
                                            </Box>
                                            <Box sx={{ pt: 1 }}>
                                                <Button onClick={(e) => exportToPDF()} variant="contained" size="large" style={{ borderRadius: '20px', padding: '0.3rem 1.5rem', outline: 'none', textTransform: 'none'}} >
                                                    Export to PDF
                                                </Button>
                                            </Box>
                                        </Stack>
                                    </Box>
                                </Grid>
                            </Grid>
                        </Box>
                    </Grid>
                    <Grid item md={12} xs={12} sx={{}}>
                        <Box sx={{ mt: 4 }}>
                            <Divider textAlign="left" variant="fullWidth" component="div" sx={{ "&::before, &::after": { },}}>
                                <Typography sx={{ fontWeight: 400, }} variant="body1" color="text.blueText">
                                    User activities
                                </Typography>
                            </Divider>
                        </Box>
                        <Box sx={{ borderTop: '0px solid #898F94', mt: 2, ml: 6.5, mr: 6.5, pt: 0 }}>
                            <MaterialReactTable
                                columns={columns}
                                data={flatData}
                                enableTopToolbar={false}
                                enableColumnActions={false}
                                enableSorting={false}
                                enableExpandAll={false}
                                enablePagination={false}
                                enableRowNumbers={false}
                                enableRowVirtualization
                                enableHiding={false}
                                enableColumnFilters={false}
                                enableColumnOrdering={false}
                                enableGlobalFilter={false}
                                muiTableBodyRowProps={({ row }) => ({
                                    onClick: () => {
                                        setRowSelection((prev) => ({
                                            [row.id]: true,
                                        }));
                                        HandleRowClick(row);
                                    },
                                    sx: {
                                        cursor: 'pointer',
                                    },
                                })}
                                positionActionsColumn="last"
                                enableColumnResizing={false}
                                enableDensityToggle={false}
                                enableFullScreenToggle={false}
                                muiTableBodyCellProps={({ column }) => ({
                                    sx: (theme) => ({
                                        color: 'text.primary',
                                        fontSize: theme.typography.body1,
                                        borderBottom: '1px #BBBFBF solid',
                                        pl: 2, fontWeight: 400, pt: 1, pb: 1,
                                    }),
                                })
                                }

                                muiTableHeadCellProps={{
                                    sx: (theme) => ({
                                        color: 'text.primary',
                                        fontSize: theme.typography.body2,
                                        borderTop: '1px #BBBFBF solid',
                                        borderBottom: '1px #BBBFBF solid',
                                        fontWeight: 400,
                                        pl: 2, pr: 2,
                                        pt: 1, pb: 1,
                                    }),
                                }}

                                muiTableContainerProps={{
                                    ref: tableContainerRef,
                                    sx: { maxHeight: '550px', minHeight: '450px' },
                                    onScroll: (
                                        event,
                                    ) => fetchMoreOnBottomReached(event.target),
                                }}
                                muiToolbarAlertBannerProps={
                                    isError
                                        ? {
                                            color: 'error',
                                            children: ErrorMessages.UnknownError,
                                        }
                                        : undefined
                                }
                                onColumnFiltersChange={setColumnFilters}
                                onGlobalFilterChange={setGlobalFilter}
                                onSortingChange={setSorting}
                                renderBottomToolbarCustomActions={() => (
                                    <Box sx={{ mt: 0.5, mb: 0 }}>
                                        <Stack direction="row" spacing={1}>
                                        <Typography variant="body2" color="text.blueText" sx={{ pl: 1, fontWeight: 400 }}>
                                            {
                                                !isError && data
                                                        ? flatData.length + ' record(s)'
                                                    : ''
                                            }
                                            </Typography>
                                            <Box sx={{ display: dispArrow }}>
                                                <ArrowDownwardIcon sx={{ pb: 1, fontSize: 24, color: 'Icon.main' }} />
                                            </Box>
                                        </Stack>
                                    </Box>
                                )}
                                onRowSelectionChange={setRowSelection}
                                state={{
                                    columnFilters,
                                    globalFilter,
                                    isLoading,
                                    showAlertBanner: isError,
                                    showProgressBars: isFetching,
                                    showSkeletons: isFetching,
                                    rowSelection,
                                    sorting,
                                }}
                                initialState={{
                                }}
                                muiTablePaperProps={{
                                    elevation: 0,
                                }}
                                rowVirtualizerProps={{ overscan: 2 }}
                                localization={{
                                    noRecordsToDisplay: 'No records to display',
                                    selectedCountOfRowCountRowsSelected: '',
                                }}
                            />
                        </Box>
                    </Grid>
                    <Grid item md={12} xs={12} sx={{ ml: 0, mr: 0, pb: 5 }}>
                        <Box sx={{ p: 4, pl: 7, pr: 7, }}>
                        <TableContainer sx={{ maxHeight: 400 }}>
                            <Table aria-label="simple table" sx={{ cellSpacing: 0, cellPadding: 0, border: '1px solid #D4D6D9', pt: 3, pl: 1, Overflow: 'scroll' }} size="small">
                                <TableBody>
                                        <TableRow sx={{ borderBottom: '1px solid #D4D6D9'}} key={2}>
                                            <TableCell scope="row" sx={{ maxWidth: 80, pl: 3, borderBottom: '1px solid #D4D6D9' }}>
                                                <Typography variant="body4" color="text.primary" sx={{ fontWeight: 400 }}>
                                                IP Address
                                            </Typography>
                                        </TableCell>
                                        <TableCell scope="row" sx={{ pl: 1, borderBottom: '1px solid #D4D6D9' }}>
                                            <Typography variant="body4" color="text.primary" sx={{ fontWeight: 400 }}>
                                                {userIPAddress}
                                            </Typography>
                                        </TableCell>
                                    </TableRow>
                                    <TableRow sx={{}} key={3}>
                                            <TableCell scope="row" sx={{ maxWidth: 80, pl: 3, borderBottom: '1px solid #D4D6D9' }}>
                                                <Typography variant="body4" color="text.primary" sx={{ fontWeight: 400 }}>
                                                Date
                                            </Typography>
                                        </TableCell>
                                            <TableCell scope="row" sx={{ pl: 1, borderBottom: '1px solid #D4D6D9' }}>
                                                <Typography variant="body4" color="text.primary" sx={{ fontWeight: 400 }}>
                                                {activityDate}
                                            </Typography>
                                        </TableCell>
                                    </TableRow>
                                    <TableRow sx={{}} key={4}>
                                            <TableCell scope="row" sx={{ maxWidth: 80, pl: 3, borderBottom: '1px solid #D4D6D9' }}>
                                                <Typography variant="body4" color="text.primary" sx={{ fontWeight: 400 }}>
                                                User email address
                                            </Typography>
                                        </TableCell>
                                            <TableCell scope="row" sx={{ pl: 1, borderBottom: '1px solid #D4D6D9' }}>
                                                <Typography variant="body4" color="text.primary" sx={{ fontWeight: 400 }}>
                                                {emailAddress}
                                            </Typography>
                                        </TableCell>
                                    </TableRow>
                                    <TableRow sx={{}} key={5}>
                                            <TableCell scope="row" sx={{ maxWidth: 80, pl: 3, borderBottom: '1px solid #D4D6D9' }}>
                                                <Typography variant="body4" color="text.primary" sx={{ fontWeight: 400 }}>
                                                User
                                            </Typography>
                                        </TableCell>
                                            <TableCell scope="row" sx={{ pl: 1, borderBottom: '1px solid #D4D6D9' }}>
                                                <Typography variant="body4" color="text.primary" sx={{ fontWeight: 400 }}>
                                                {userName}
                                            </Typography>
                                        </TableCell>
                                        </TableRow>
                                    <TableRow sx={{}} key={6}>
                                            <TableCell scope="row" sx={{ maxWidth: 80, pl: 3, borderBottom: '1px solid #D4D6D9' }}>
                                                <Typography variant="body4" color="text.primary" sx={{ fontWeight: 400 }}>
                                                Activity
                                            </Typography>
                                        </TableCell>
                                            <TableCell scope="row" sx={{ pl: 1, borderBottom: '1px solid #D4D6D9' }}>
                                                <Typography variant="body4" color="text.primary" sx={{ fontWeight: 400 }}>
                                                {userActivity}
                                            </Typography>
                                        </TableCell>
                                    </TableRow>
                                    <TableRow sx={{}} key={7}>
                                            <TableCell scope="row" sx={{ maxWidth: 80, pl: 3, borderBottom: '1px solid #D4D6D9' }}>
                                                <Typography variant="body4" color="text.primary" sx={{ fontWeight: 400 }}>
                                                Activity detail
                                            </Typography>
                                        </TableCell>
                                            <TableCell scope="row" sx={{ pl: 1, borderBottom: '1px solid #D4D6D9' }}>
                                                <Typography variant="body4" color="text.primary" sx={{ fontWeight: 400 }}>
                                                {activityDetail}
                                            </Typography>
                                        </TableCell>
                                        </TableRow>
                                {/*    <TableRow sx={{}} key={8}>*/}
                                {/*            <TableCell scope="row" sx={{ maxWidth: 80, pl: 3, borderBottom: '1px solid #D4D6D9' }}>*/}
                                {/*                <Typography variant="body4" color="text.primary" sx={{ fontWeight: 400 }}>*/}
                                {/*                Country*/}
                                {/*            </Typography>*/}
                                {/*        </TableCell>*/}
                                {/*            <TableCell scope="row" sx={{ pl: 1, borderBottom: '1px solid #D4D6D9' }}>*/}
                                {/*                <Typography variant="body4" color="text.primary" sx={{ fontWeight: 400 }}>*/}
                                {/*                {country}*/}
                                {/*            </Typography>*/}
                                {/*        </TableCell>*/}
                                {/*    </TableRow>*/}
                                {/*    <TableRow sx={{}} key={9}>*/}
                                {/*            <TableCell scope="row" sx={{ maxWidth: 80, pl: 3, borderBottom: '1px solid #D4D6D9' }}>*/}
                                {/*                <Typography variant="body4" color="text.primary" sx={{ fontWeight: 400 }}>*/}
                                {/*                Browser name*/}
                                {/*            </Typography>*/}
                                {/*        </TableCell>*/}
                                {/*            <TableCell scope="row" sx={{ pl: 1, borderBottom: '1px solid #D4D6D9' }}>*/}
                                {/*                <Typography variant="body4" color="text.primary" sx={{ fontWeight: 400 }}>*/}
                                {/*                {browserName}*/}
                                {/*            </Typography>*/}
                                {/*        </TableCell>*/}
                                {/*    </TableRow>*/}
                                {/*        <TableRow sx={{}} key={10}>*/}
                                {/*            <TableCell scope="row" sx={{ maxWidth: 80, pl: 3, borderBottom: '1px solid #D4D6D9' }}>*/}
                                {/*                <Typography variant="body4" color="text.primary" sx={{ fontWeight: 400 }}>*/}
                                {/*                    Platform*/}
                                {/*                </Typography>*/}
                                {/*            </TableCell>*/}
                                {/*            <TableCell scope="row" sx={{ pl: 1, borderBottom: '1px solid #D4D6D9' }}>*/}
                                {/*                <Typography variant="body4" color="text.primary" sx={{ fontWeight: 400 }}>*/}
                                {/*                    {platform}*/}
                                {/*                </Typography>*/}
                                {/*            </TableCell>*/}
                                {/*        </TableRow>*/}
                                </TableBody>
                            </Table>
                        </TableContainer>
                        </Box>
                    </Grid>
                    <Footer userId={userId} />
                </Paper>
            </Container>
        </React.Fragment>
    );
};

const queryClient = new QueryClient();

const ActivityLog = (props) => (
    <QueryClientProvider client={queryClient}>
        <ActivityHistories userId={props.userId} sessionId={props.sessionId} />
    </QueryClientProvider>
);

export default ActivityLog;