import { Power } from 'react-bootstrap-icons';
import React, { useEffect, useState } from 'react';
import { Nav, Navbar, Container, NavDropdown } from 'react-bootstrap';
import logo from '../assets/images/BankLogo-circle.png'
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from 'react-redux';
import { deleteSession } from '../redux/sessionMgmnt';
import { ApiHelper } from '../customFunctions/apiHelper';

export default function EnrollmentBanner() {

    let history = useNavigate();
    const dispatch = useDispatch();
    const sessions = useSelector((state) => state.sessionMgmnt);

    const [state, setState] = useState({
        CntctId: parseInt(null)
    });

    useEffect(() => {
        let cntctId = parseInt(null);    
        sessions.map(
            (sn) => {
                if (sn.key === 'LoginUserId') {
                    cntctId = parseInt(sn.value);
                }
            });

        setState({
            CntctId: cntctId
        });

        if (!cntctId && process.env.REACT_APP_ENVIORNMENT !== 'dev') {
            return logout();
        }


    }, []);

    const logout = () => {

        let headers = {
            'Content-Type': 'application/json'
        };

        let bodyData = {
            "CntctId": state.CntctId
        }

        ApiHelper({ url: process.env.REACT_APP_LOGOUT, method: 'POST', headers: headers, body: bodyData, sessions: sessions, dispatch: dispatch })
            .then(resposnse => {
                if (resposnse !== null) {
                    window.localStorage.clear();
                    clearReduxSession();
                    history.push('/');
                }
                else {
                    window.location.href = '/?status=session_out';
                }
            });
        //window.location.href = '/';
    }

    const clearReduxSession = () => {
        sessions.map(
            (sn) => {
                dispatch(
                    deleteSession({
                        key: sn.key
                    })
                )
            });
    }

    return (
        <>
            <Navbar className="w-100 EnrollmentBanner">
                <Container className="p-s-0">
                    {/*<Navbar.Brand href="#home">Account Onboarding</Navbar.Brand>*/}
                    <Navbar.Brand href="#home">
                        <img className="ban-logo" src={logo} width="190" />
                    </Navbar.Brand>
                    <Nav className="justify-content-end sign-out">
                        <Nav.Link onClick={logout}><button type="button" className="p-s-4 p-e-4"><span className="">Sign out</span></button></Nav.Link>
                    </Nav>
                </Container>
            </Navbar>
        </>
    );
};