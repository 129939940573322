import { PaletteMode } from "@mui/material";
import { amber, deepOrange, grey } from "@mui/material/colors";

export const getDarkThemeDesignTokens = (mode) => ({
    palette: {
        mode,
            // palette values for dark mode
        primary: {
            main: grey[800],
            contrastText: "#E0E0E0"
        },
        secondary: {
            main: '#292929',
            contrastText: "#E0E0E0"
        },
        primaryButton: {
            main: '#141414',
            contrastText: "#E0E0E0"
        },
        Icon: {
            main: '#E0E0E0'
        },
        Check: {
            main: '#E0E0E0'
        },
        Link: {
            main: 'rgba(255, 255, 255, 0.7)'
        },
        divider: 'rgba(81, 81, 81, 1)',
        background: {
            default: '#0A0A0A',
            paper: grey[900],
        },
        text: {
            primary: "#fafafa",
            secondary: grey[400],
            disabled: '#000000',
            blueText: 'rgb(144, 202, 249)',
            greenText: 'rgb(20,184,166)',
            amberText: 'rgb(255, 167, 38)',
        },
    },
    typography: {
        fontFamily: [
            '-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,Oxygen-Sans,Ubuntu,Cantarell,"Helvetica Neue",sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol"'
        ].join(','),
        body1: {
            lineHeight: 1.6,
        },
    },
    components: {
        // Name of the component
        MuiButton: {
            styleOverrides: {
                // Name of the slot
                root: ({ ownerState }) => ({
                    '&:hover': {
                        opacity: 0.5,
                    },
                    fontSize: '0.8rem',
                }),
            },
        },
        MuiContainer: {
            styleOverrides: {
                root: {
                    "&.MuiContainer-maxWidthXl": {
                        maxWidth: "1200px",
                    },
                    // Add other classes as needed
                },
            },
        },
        MuiTab: {
            styleOverrides: {
                root: {
                    "&.Mui-selected": {
                        color: 'rgb(31, 107, 230)',
                        borderRadius: "25px"
                    }
                }
            }
        },
    },
});

