import React, { useCallback, useState, useEffect, useMemo, useRef, } from 'react';
import { useNavigate, useLocation } from "react-router-dom";
import PostLoginMenu from './PostLoginMenu';
import OverlaySpinner from './Controls/OverlaySpinner';
import { QueryClient, QueryClientProvider, useInfiniteQuery, } from '@tanstack/react-query';
import { useSelector, useDispatch } from 'react-redux';
import { Virtualizer } from '@tanstack/react-virtual';
import { Grid, Paper, Box, Typography, Button, Stack, IconButton, Tooltip, Divider } from '@mui/material';
import Container from '@mui/material/Container';
import EnrollmentHeader from './EnrollmentHeader';
import { DrawerAppBar } from './AppNavbar';
import MaterialReactTable from 'material-react-table';
import { ErrorMessages } from '../common/ErrorMessages';
import AlertWithTitle from './Controls/AlertWithTitle';
import AlertControl from './Controls/AlertControl';
import { APICall, WriteToActivityLog } from '../global/Api';
import { ExceptionDialog } from '../global/ExceptionDialog';
import AutoLagoutTimer from '../global/IdleMonitor';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import moment from "moment";
import WysiwygIcon from '@mui/icons-material/Wysiwyg';
import Footer from './Footer';
import { ActivityLogEvent } from '../common/AOBEnum';
import Checkbox, { checkboxClasses } from "@mui/material/Checkbox";

const ManageExceptions = (props) => {

    const location = useLocation();

    const [errorMsg, setErrorMsg] = useState(null);
    const [successMsg, setSuccessMsg] = useState(null);
    const [successMsgTitle, setSuccessMsgTitle] = useState(null);
    const [rowSelection, setRowSelection] = useState({});
    const [disFromDate, setDisFromDate] = useState(false);
    const [disToDate, setDisToDate] = useState(false);
    const [ignoreDate, setIgnoreDate] = useState(false);
    const [searchText, setSearchText] = useState('');
    const [fromDate, setFromDate] = useState(new Date(new Date().setDate(new Date().getDate() - 90)).toLocaleDateString('en-CA'));
    const [toDate, setToDate] = useState(new Date().toLocaleDateString('en-CA'));

    const dispatch = useDispatch();
    let sessions = useSelector((state) => state.sessionMgmnt);
    const tableInstanceRef = useRef(null);

    const [userId, setUserId] = useState(props.userId);
    const [sessionId, setSessionId] = useState(props.sessionId);

    const [openExceptionDlg, setOpenExceptionDlg] = useState(false);

    const exceptionDlgHdr = useRef('');
    const exceptionDlgBLine1 = useRef('');
    const exceptionDlgBLine2 = useRef('');
    const exceptionDlgBLine3 = useRef('');
    const exceptionDlgBLine4 = useRef('');
    const exceptionDlgBLine5 = useRef('');
    const exceptionDlgBLine6 = useRef('');
    const exceptionDlgBLine7 = useRef('');
    const exceptionDlgBLine8 = useRef('');
    const exceptionDlgBLine9 = useRef('');
    const exceptionDlgBLine10 = useRef('');
    const exceptionDlgBLine11 = useRef('');
    const exceptionDlgBLine12 = useRef('');
    const selectedRoleTypId = useRef(0);

    let navigate = useNavigate();


    const onCellClicked = (cell, row, header) => {
        exceptionDlgHdr.current = 'Exception Details';
        exceptionDlgBLine1.current = 'Exception ID : ' + row.original.ErrorId;
        exceptionDlgBLine2.current = '';
        exceptionDlgBLine3.current = header + ' :';
        exceptionDlgBLine4.current = cell.getValue()
        setOpenExceptionDlg(true);
    }

    const handleSearchTextChange = (event) => {
        setSearchText(event.target.value);
    };

    const handleStartDateChange = (event) => {
        setFromDate(event.target.value);
    };

    const handleEndDateChange = (event) => {
        setToDate(event.target.value);
    };

    const handleIgnoreDateChange = (event) => {

        setDisFromDate(event.target.checked);
        setDisToDate(event.target.checked);
        setIgnoreDate(event.target.checked);
    };

    const columns = [
        {
            accessorFn: (row) => row.ErrorId,
            header: 'ID',
            Cell: ({ cell, column }) => (
                <Box>
                    {cell.getValue()}
                </Box>
            ),
            muiTableBodyCellProps: ({ cell }) => ({
                sx: (theme) => ({
                    borderLeft: '1px #BBBFBF solid',
                    color: 'text.primary',
                    fontSize: theme.typography.body1,
                    borderBottom: '1px #BBBFBF solid',
                    pl: 2, fontWeight: 400, pt: 1, pb: 1,
                }),
            }),
            muiTableHeadCellProps: ({ cell }) => ({
                sx: (theme) => ({
                    borderLeft: '1px #BBBFBF solid',
                    color: 'text.primary',
                    fontSize: theme.typography.body2,
                    borderTop: '1px #BBBFBF solid',
                    borderBottom: '1px #BBBFBF solid',
                    fontWeight: 400,
                    pl: 2, pr: 2,
                    pt: 1, pb: 1,
                }),
            }),
            size: 70
        },
        {
            accessorFn: (row) => row.ErrorDt,
            header: 'Date',
            Cell: ({ cell, column }) => (
                <Box>
                    {cell.getValue() && cell.getValue().length > 25 ? cell.getValue().substring(0, 25) : cell.getValue()}
                </Box>
            ),
            size: 200
        },
        {
            accessorFn: (row) => row.ErrorMessage,
            header: 'Error Message',
            Cell: ({ cell, column }) => (
                <Box>
                    {cell.getValue() && cell.getValue().length > 45 ? cell.getValue().substring(0, 45) + '...' : cell.getValue()}
                </Box>
            ),
            size: 300
        },
        {
            accessorFn: (row) => row.DetailException,
            header: 'Detail Exception',
            Cell: ({ cell, column }) => (
                <Box>
                    {cell.getValue() && cell.getValue().length > 45 ? cell.getValue().substring(0, 45) + '...' : cell.getValue()}
                </Box>
            ),
            size: 300
        },
    ];

    const fetchSize = 25;

    const tableContainerRef = useRef(null); //we can get access to the underlying TableContainer element and react to its scroll events
    const virtualizerInstanceRef = useRef < Virtualizer > null; //we can get access to the underlying Virtualizer instance and call its scrollToIndex method

    const { data, fetchNextPage, isError, isFetching, isLoading, refetch } =
        useInfiniteQuery({
            queryKey: ['table-data'],
            queryFn: async ({ pageParam = 0 }) => {

                const apiUrl = new URL(
                    process.env.REACT_APP_GET_ALL_EXCEPTIONS,
                    process.env.REACT_APP_BASE_URL,
                );

                apiUrl.searchParams.set('start', `${pageParam * fetchSize}`);
                apiUrl.searchParams.set('size', `${fetchSize}`);

                var date1 = fromDate !== null ? moment(fromDate, 'YYYY-MM-DD') : null;
                var date2 = toDate !== null ? moment(toDate, 'YYYY-MM-DD') : null;

                if (!ignoreDate) {
                    apiUrl.searchParams.set('startTime', date1 !== null ? date1.format('MM/DD/YYYY') : ``);
                    apiUrl.searchParams.set('endTime', date2 !== null ? date2.format('MM/DD/YYYY') : ``);
                }

                apiUrl.searchParams.set('globalFilter', `${searchText}`);

                //let response = await CallAPI({ url: apiUrl.href, method: 'GET', headers: {} }, userId, sessionId);
                let response = await APICall({ url: apiUrl.href, method: 'GET', contentType: 'application/json', responseType: '' }, userId, sessionId);

                if (response !== null && response !== undefined) {
                    return response;
                }
                else {
                    throw Error(ErrorMessages.UnknownError);
                }
            },
            getNextPageParam: (_lastGroup, groups) => groups.length,
            keepPreviousData: true,
            refetchOnWindowFocus: false,
        });

    const flatData = useMemo(
        () => data?.pages.flatMap((page) => page.data) ?? [],
        [data],
    );

    const totalDBRowCount = data?.pages?.[0]?.meta?.totalRowCount ?? 0;
    const totalFetched = flatData.length;
    const fetchMoreOnBottomReached = useCallback(
        (containerRefElement) => {
            if (containerRefElement) {
                const { scrollHeight, scrollTop, clientHeight } = containerRefElement;
                if (
                    scrollHeight - scrollTop - clientHeight < 400 &&
                    !isFetching &&
                    totalFetched < totalDBRowCount
                ) {
                    fetchNextPage();
                }
            }
        },
        [fetchNextPage, isFetching, totalFetched, totalDBRowCount],
    );

    useEffect(() => {
        if (virtualizerInstanceRef.current) {
            virtualizerInstanceRef.current.scrollToIndex(0);
        }
    }, []);

    useEffect(() => {
        fetchMoreOnBottomReached(tableContainerRef.current);
    }, [fetchMoreOnBottomReached]);

    useEffect(() => {
        loadForm();

    }, []);

    const loadForm = async () => {
        await WriteToActivityLog(ActivityLogEvent.SystemError, "Viewed system error log page.", userId, sessionId);
    };

    const HandleRowClick = async (row) => {
        // tableInstanceRef.resetRowSelection = true;
        //exceptionDlgHdr.current = 'Exception Details';
        //exceptionDlgBLine1.current = 'Exception ID : ' + row.original.ErrorId;
        //exceptionDlgBLine2.current = 'DATE : ' + row.original.ErrorDt;
        //exceptionDlgBLine3.current = '';
        //exceptionDlgBLine4.current = 'LOGGER : ' + row.original.logger;
        //exceptionDlgBLine5.current = '';
        //exceptionDlgBLine6.current = 'CALL SITE :';
        //exceptionDlgBLine7.current = row.original.CallSite;
        //exceptionDlgBLine8.current = 'ERROR MESSAGE :';
        //exceptionDlgBLine9.current = row.original.ErrorMessage;
        //exceptionDlgBLine10.current = 'DETAIL EXCEPTION :';
        //exceptionDlgBLine11.current = row.original.DetailException;
        //setOpenExceptionDlg(true);
    }

    const handleExceptionDialogClose = (nvalue, svalue) => {
        setOpenExceptionDlg(false)
    };

    const FindClick = async (event) => {

        if (!ignoreDate) {
            if (fromDate == '') {
                setErrorMsg('Invalid from date');
                return;
            }

            if (toDate == '') {
                setErrorMsg('Invalid to date');
                return;
            }

            let date1 = fromDate !== '' ? moment(fromDate, 'YYYY-MM-DD') : null;
            let date2 = toDate !== '' ? moment(toDate, 'YYYY-MM-DD') : new Date().setHours(0, 0, 0, 0);

            if (date1 > date2) {
                setErrorMsg('The To Date must be greater than the From Date');
                return;
            }
        }

        setErrorMsg('');

        refetch();

        await WriteToActivityLog(ActivityLogEvent.SystemError, "Searched 'System Error Log' list.", userId, sessionId);

    };

    const handleViewClick = (table) => {

        if (table.getSelectedRowModel().rows.length > 0) {

            let row = table.getSelectedRowModel().rows[0];

            exceptionDlgHdr.current = 'Exception Details';
            exceptionDlgBLine1.current = 'DATE : ' + row.original.ErrorDt;
            exceptionDlgBLine2.current = 'ERROR MESSAGE :';
            exceptionDlgBLine3.current = row.original.ErrorMessage;
            exceptionDlgBLine4.current = 'DETAIL EXCEPTION :';
            exceptionDlgBLine5.current = row.original.DetailException;

            setOpenExceptionDlg(true);
        }
    };

    return (
        <React.Fragment>
            <AutoLagoutTimer userId={userId} sessionId={sessionId} />
            <Container maxWidth="lg" sx={{}} className="">
                <OverlaySpinner disappear={isLoading} />
                <Paper className="back-grid">
                    <PostLoginMenu userId={userId} sessionId={sessionId} />
                    <Grid item md={12} id="aobhead" >
                        <Box sx={{
                            pr: 0,
                            pl: 0,
                        }}>
                            <EnrollmentHeader />
                        </Box>
                    </Grid>
                    <Grid item sx={{ pr: 1 }}>
                        <DrawerAppBar userId={userId} sessionId={sessionId} />
                    </Grid>
                    <Grid item xs container direction="row" spacing={0} sx={{ pt: 0, pb: 3, }}>
                        <Grid item md={12} sx={{}} justifyContent="center">
                            <Box sx={{ display: 'flex', pb: 2, pt: 0, ml: 6.5, mr: 6.5, justifyContent: 'space-between', alignItems: 'flex-end', borderBottom: '0px solid #898F94' }}>
                                <Typography variant="h6" color="text.primary" sx={{ pl: 0, pb: 0, lineHeight: 1, fontWeight: 500, pb: 0, letterSpacing: '0px' }} >System Error Log</Typography>
                            </Box>
                            <Box sx={{ }}>
                                <Divider variant="fullWidth" component="div" sx={{ }} />
                            </Box>
                            <Box sx={{ mt: 0, pt: 3, pb: 1, ml: 6.5, mr: 6.5, borderTop: '0px solid #A8B3B3', borderBottom: '0px solid #d2d2d2' }}>
                                <Stack direction="column" sx={{ }}>
                                    <Typography sx={{ fontWeight: 400, }} variant="body1" color="text.primary">
                                        Error log keeps track of system errors that occur while the application is running. It contains information about the error.
                                    </Typography>
                                </Stack>
                            </Box>
                        </Grid>
                        <Grid item xs container spacing={0}>
                            <Grid item xs={8} sx={{}}>
                                <Box sx={{ ml: 6.5, mr: 0, mt: 2 }}>
                                    <Stack direction="row" spacing={2}>
                                        <Box>
                                            <label htmlFor="DateFrom" className="form-label">From Date</label>
                                            <input type="date" id="DateFrom" style={{width: '250px'}} disabled={disFromDate} name="DateFrom" placeholder="Enter start date here" className="form-control" onChange={handleStartDateChange} value={fromDate} />
                                        </Box>
                                        <Box>
                                            <label htmlFor="DateTo" className="form-label">To Date</label>
                                            <input type="date" id="DateTo" style={{ width: '250px' }} name="DateTo" disabled={disToDate} placeholder="Enter end date here" className="form-control" onChange={handleEndDateChange} value={toDate} />
                                        </Box>
                                        <Box sx={{ pt: 2 }}>
                                            <FormGroup>
                                                <FormControlLabel control={<Checkbox sx={{
                                                    [`&, &.${checkboxClasses.checked}`]: {
                                                        color: 'Check.main',
                                                    },
                                                }} onChange={handleIgnoreDateChange} />} label={<Typography variant="body3" color="text.primary">Ignore dates</Typography>} />
                                            </FormGroup>
                                        </Box>
                                    </Stack>
                                    <Box sx={{ mt: 2, pr: 4 }}>
                                        <Stack direction="row">
                                            <input type="text" id="LastName" name="LastName" placeholder="Search first name, last name, email address" onChange={handleSearchTextChange} value={searchText} className="form-control" />
                                        </Stack>
                                    </Box>
                                </Box>
                            </Grid>
                            {/*<Grid item xs={9}>*/}
                            {/*    <Box sx={{ ml: 6, mr: 15, mt: 0}}>*/}
                            {/*        <Stack direction="row">*/}
                            {/*            <input type="text" id="LastName" name="LastName" placeholder="Search first name, last name, email address" onChange={handleSearchTextChange} value={searchText} className="form-control" />*/}
                            {/*        </Stack>*/}
                            {/*    </Box>*/}
                            {/*</Grid>*/}
                            <Grid item xs={4} sx={{ }}>
                                <Box sx={{ mt: 3.5 }}>
                                    <Button type="submit" onClick={(e) => FindClick(e)} variant="contained" size="large" style={{ boxShadow: 'none', border: 0, borderRadius: '20px', padding: '0.3rem 2.5rem', outline: 'none', textTransform: 'none'}} >
                                        Find
                                    </Button>
                                </Box>
                            </Grid>
                        </Grid>
                        <Grid item md={12} sx={{}}>
                            <Box>
                                <AlertControl Message={errorMsg} severity="error" color="error" icon={true} />
                                <AlertWithTitle Message={successMsg} variant="body1" severity="success" color="success" icon={true} Title={successMsgTitle} />
                            </Box>
                            <Box sx={{ mt: 2 }}>
                                <Divider textAlign="left" variant="fullWidth" component="div" sx={{ "&::before, &::after": { }, }}>
                                    <Typography sx={{ fontWeight: 400, }} variant="body4" color="text.blueText">
                                        System errors
                                    </Typography>
                                </Divider>
                            </Box>
                            <Box sx={{ borderTop: '0px solid #898F94', pb: 0, ml: 6.5, mr: 6.5, pt: 0 }}>
                                <MaterialReactTable
                                    columns={columns}
                                    data={flatData}
                                    displayColumnDefOptions={{
                                        'mrt-row-select': {
                                            maxSize: 12,
                                            header: '',
                                        },
                                        'mrt-row-actions': {
                                            header: '',
                                            maxSize: 30,
                                        },
                                    }}
                                    muiTablePaperProps={{
                                        elevation: 0,
                                    }}
                                    enableColumnActions={false}
                                    enableColumnFilters={false}
                                    enableSorting={false}
                                    enablePagination={false}
                                    enableRowNumbers={false}
                                    enableRowVirtualization
                                    enableHiding={false}
                                    enableGlobalFilter={false}
                                    enableColumnOrdering={false}
                                    muiTableBodyRowProps={({ row }) => ({
                                        onClick: () => {
                                            setRowSelection((prev) => ({
                                                [row.id]: true,
                                            }));
                                            HandleRowClick(row);
                                        },
                                        sx: {
                                            cursor: 'pointer',
                                        },
                                    })}
                                    positionActionsColumn="last"
                                    enableColumnResizing={false}
                                    enableDensityToggle={false}
                                    enableFullScreenToggle={false}
                                    muiTableBodyCellProps={({ column }) => ({
                                        sx: (theme) => ({
                                            color: 'text.primary',
                                            fontSize: theme.typography.body1,
                                            borderBottom: '1px #BBBFBF solid',
                                            pl: 2, fontWeight: 400, pt: 1, pb: 1,
                                        }),
                                    })
                                    }

                                    muiTableHeadCellProps={{
                                        sx: (theme) => ({
                                            color: 'text.primary',
                                            fontSize: theme.typography.body2,
                                            borderTop: '1px #BBBFBF solid',
                                            borderBottom: '1px #BBBFBF solid',
                                            fontWeight: 400,
                                            pl: 2, pr: 2,
                                            pt: 1, pb: 1,
                                        }),
                                    }}

                                    muiTableContainerProps={{
                                        ref: tableContainerRef,
                                        sx: { maxHeight: '550px', minHeight: '450px' },
                                        onScroll: (
                                            event,
                                        ) => fetchMoreOnBottomReached(event.target),
                                    }}
                                    muiToolbarAlertBannerProps={
                                        isError
                                            ? {
                                                color: 'error',
                                                children: ErrorMessages.UnknownError,
                                            }
                                            : undefined
                                    }
                                    renderBottomToolbarCustomActions={() => (
                                        <Box sx={{ mt: 0.5, mb: 0 }}>
                                            <Typography variant="caption" color="text.blueText" sx={{ pl: 1, fontWeight: 400 }}>
                                                {
                                                    !isError && data
                                                        ? totalFetched + ' of ' + totalDBRowCount + ' exception(s).'
                                                        : ''
                                                }
                                            </Typography>
                                        </Box>
                                    )}
                                    onRowSelectionChange={setRowSelection}
                                    state={{
                                        isLoading,
                                        showAlertBanner: isError,
                                        showProgressBars: isFetching,
                                        showSkeletons: isFetching,
                                        rowSelection,
                                    }}
                                    initialState={{
                                    }}
                                    rowVirtualizerProps={{ overscan: 1 }}
                                    localization={{
                                        noRecordsToDisplay: 'No exceptions to display',
                                        selectedCountOfRowCountRowsSelected: '',
                                    }}
                                    renderTopToolbarCustomActions={({ table }) => {
                                        return (
                                            <Box sx={{
                                                display: 'flex', alignItems: 'center', flexDirection: 'row'
                                            }}>
                                                {/*<Typography variant="body4" color="text.primary" sx={{ pt: 1, pr: 2, fontWeight: 400, pl: 0, borderRadius: '4px' }}>Exceptions</Typography>*/}
                                            </Box>
                                        );
                                    }}
                                    tableInstanceRef={tableInstanceRef}
                                    renderToolbarInternalActions={({ table }) => {
                                        return (
                                            <Box sx={{ pr: 1, display: 'flex', pt: 0, pb: 0, flexDirection: 'row', }}>
                                                <Tooltip arrow title="View error details">
                                                    <span>
                                                        <IconButton size="small" sx={{ border: 0, borderColor: '#d7d8d6', borderRadius: '5px', ml: 0, mr: 1, mb: 0 }} disabled={table.getSelectedRowModel().flatRows.length <= 0} onClick={() => handleViewClick(table)}>
                                                            <WysiwygIcon sx={{ fontSize: 26 }} />
                                                        </IconButton>
                                                    </span>
                                                </Tooltip>
                                            </Box>
                                        );
                                    }}
                                />
                            </Box>
                        </Grid>
                    </Grid>
                    {
                        openExceptionDlg ?
                            <ExceptionDialog
                                id="ringtone-menu"
                                keepMounted
                                open={openExceptionDlg}
                                onClose={handleExceptionDialogClose}
                                alertheader={exceptionDlgHdr.current}
                                bmsgline1={exceptionDlgBLine1.current}
                                bmsgline2={exceptionDlgBLine2.current}
                                bmsgline3={exceptionDlgBLine3.current}
                                bmsgline4={exceptionDlgBLine4.current}
                                bmsgline5={exceptionDlgBLine5.current}
                                bmsgline6={exceptionDlgBLine6.current}
                                bmsgline7={exceptionDlgBLine7.current}
                                bmsgline8={exceptionDlgBLine8.current}
                                bmsgline9={exceptionDlgBLine9.current}
                                bmsgline10={exceptionDlgBLine10.current}
                                bmsgline11={exceptionDlgBLine11.current}
                            /> : ''
                    }
                    <Footer userId={userId} />
                </Paper>
            </Container>
        </React.Fragment>
    );
};

function ExceptionDetails() {

    const location = useLocation();
    const queryClient = new QueryClient();

    const [userId, setUserId] = useState(location.state.userId || -1);
    const [sessionId, setSessionId] = useState(location.state.sessionId || '');

    return (
        <React.Fragment>
            <QueryClientProvider client={queryClient}>
                <ManageExceptions userId={userId} sessionId={sessionId} />
            </QueryClientProvider>
        </React.Fragment>
    );
}


export default ExceptionDetails;