import React, { useEffect, useState, useRef, useMemo } from 'react';
import { Container, Dialog, Divider, DialogTitle, DialogContent, Box, DialogActions, Button, Typography, IconButton, Grid, Toolbar, Stack } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import AppBar from '@mui/material/AppBar';
import { useSelector, useDispatch } from 'react-redux';
import AlertControl from '../components/Controls/AlertControl';
import MaterialReactTable, { MRT_ShowHideColumnsButton, MRT_ToggleGlobalFilterButton, MRT_ToggleFiltersButton, } from 'material-react-table';
import { CallAPI, IsLoggedInUserProspectUser } from '../global/APIUtils';
import DownloadIcon from '@mui/icons-material/Download';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import { GetAttachedFile } from '../global/EnrollmentHelper';
import LoadingOverlay from 'react-loading-overlay';
import RiseLoader from 'react-spinners/RiseLoader'
import { ErrorMessages } from '../common/ErrorMessages';
import { ConfirmDialog } from '../global/ConfirmDialog';
import AlertWithTitle from '../components/Controls/AlertWithTitle';
import { APICall, WriteToActivityLog } from '../global/Api';
import AutoLagoutTimer from '../global/IdleMonitor';
import { ActivityLogEvent } from '../common/AOBEnum';

const ProspectDocListDlg = (props) => {

    const { onClose, open, enrmntid, ownerName, enrolNm, templateNm } = props;

    return (
        <Dialog
            fullScreen
            open={open}
            onClose={onClose}
            scroll="paper"
        >
            <AppBar sx={{ position: 'relative' }}>
                <Toolbar>
                    <IconButton
                        edge="start"
                        color="inherit"
                        onClick={onClose}
                        aria-label="close"
                    >
                        <CloseIcon />
                    </IconButton>
                    <Typography sx={{ ml: 2, flex: 1, fontWeight: 300 }} variant="h6" component="div">
                        Uploaded documents
                    </Typography>
                    <Typography sx={{ fontWeight: 300 }} variant="h6" component="div">
                        Application
                    </Typography>
                </Toolbar>
            </AppBar>
            <ProspectDocListControl enrmntid={enrmntid} acctType={props.acctType} enrolNm={enrolNm} templateNm={templateNm} userId={props.userId} sessionId={props.sessionId} />
        </Dialog>
    );
};

const ProspectDocListControl = (props) => {

    const [enrmntid, setEnrmntid] = useState(props.enrmntid || -1);
    const [enrolNm, setEnrolNm] = useState(props.enrolNm || -1);
    const [errorMsg, setErrorMsg] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [data, setData] = useState([]);
    const [successMsg, setSuccessMsg] = useState(null);
    const isProspectUser = useRef(false);
    const [openDelConfirmDlg, setOpenDelConfirmDlg] = useState(false);
    const [successMsgTitle, setSuccessMsgTitle] = useState(null);

    const [userId, setUserId] = useState(props.userId);
    const [sessionId, setSessionId] = useState(props.sessionId);

    const confirmDelDlgHdr = useRef('');
    const confirmDelDlgBLine1 = useRef('');
    const selFileNm = useRef('');
    const selAttachmentId = useRef(-1);

    const dispatch = useDispatch();
    let sessions = useSelector((state) => state.sessionMgmnt);

    //const handleConfirmDelDialogClose = async (nvalue, svalue) => {

    //    setOpenDelConfirmDlg(false);

    //    if (nvalue == null)
    //        return;

    //    const inputJson = {
    //        EnrmntId: enrmntid,
    //        AttchmntId: nvalue
    //    };

    //    const apiUrl = new URL(
    //        process.env.REACT_APP_DELETE_ENROLLMENT_DOC,
    //        process.env.REACT_APP_BASE_URL,
    //    );
    //    try {

    //        setIsLoading(true);

    //        let response = await CallAPI({ url: apiUrl.href, method: 'POST', headers: {}, body: inputJson }, sessions, dispatch);

    //        if (response !== null && response !== undefined) {
    //            setSuccessMsgTitle("Application deleted");
    //            setSuccessMsg('File \'' + fileNm + '\' deleted successfully');
    //            loadAttachments();
    //        }
    //    }
    //    catch (err) {
    //        setErrorMsg(ErrorMessages.UnknownError);
    //    }
    //    finally {
    //        setIsLoading(false);
    //    }
    //};

    const loadAttachments = async () => {

        try {

            setIsLoading(true);

            const apiUrl = new URL(
                process.env.REACT_APP_GET_ATTACHMENTS_PROSPECT,
                process.env.REACT_APP_BASE_URL,
            );

            apiUrl.searchParams.set('enrmntId', `${parseInt(enrmntid)}`);

            //let response = await CallAPI({ url: apiUrl.href, method: 'GET', headers: {} }, userId, sessionId);

            let response = await APICall({ url: apiUrl.href, method: 'GET', contentType: 'application/json', responseType: '' }, userId, sessionId);

            if (response !== null && response !== undefined) {
                await WriteToActivityLog(ActivityLogEvent.ProspectDashboard, "Viewed application's document list. Application name '" + enrolNm + "'", userId, sessionId);
                setData(response.data[0])
            }
        }
        catch (err) {
            setErrorMsg(ErrorMessages.UnknownError);
        }
        finally {
            setIsLoading(false);
        }
    }

    const downloadAttachment = async (attachmentId, fileNm) => {
        //event.preventDefault();

        //var controlName = event.target.getAttribute('controlname');
        //var filename = event.target.getAttribute('filename');


        try {

            setIsLoading(true);

            const apiUrl = new URL(
                process.env.REACT_APP_DOWNLOAD_ENROLLMENT_DOCUMENT,
                process.env.REACT_APP_BASE_URL,
            );
            apiUrl.searchParams.set('enrmntId', `${parseInt(enrmntid)}`);
            apiUrl.searchParams.set('attachmentId', `${parseInt(attachmentId)}`);
            apiUrl.searchParams.set('fname', fileNm);
            apiUrl.searchParams.set('tag', 'None');

            await GetAttachedFile(apiUrl.href, fileNm, userId, sessionId);

            await WriteToActivityLog(ActivityLogEvent.ProspectDashboard, "Downloaded file attachment from application. Application name: '" + enrolNm + "'. File name: '" + fileNm + "'", userId, sessionId);

        }
        catch (ex) {
            //setErrorMsg(ErrorMessages.UnknownError);
        }
        finally {
            setIsLoading(false);
        }
    }

    const downloadSignedAttachment = async (attachmentId, fileNm) => {
        //event.preventDefault();

        //var controlName = event.target.getAttribute('controlname');
        //var filename = event.target.getAttribute('filename');


        try {

            setIsLoading(true);

            const apiUrl = new URL(
                process.env.REACT_APP_DOWNLOAD_SIGNED_DOCUMENT,
                process.env.REACT_APP_BASE_URL,
            );
            apiUrl.searchParams.set('enrmntId', `${parseInt(enrmntid)}`);
            apiUrl.searchParams.set('attachmentId', `${parseInt(attachmentId)}`);
            apiUrl.searchParams.set('fname', fileNm);
            apiUrl.searchParams.set('tag', 'None');

            await GetAttachedFile(apiUrl.href, fileNm, userId, sessionId);

            await WriteToActivityLog(ActivityLogEvent.ProspectDashboard, "Downloaded DocuSigned file from application. Application name: '" + enrolNm + "'. File name: '" + fileNm + "'", userId, sessionId);
        }
        catch (ex) {
            //setErrorMsg(ErrorMessages.UnknownError);
        }
        finally {
            setIsLoading(false);
        }
    }

    //const deleteAttachment = async (enrmntId, attachmentId, fileNm) => {
    //    selAttachmentId.current = attachmentId;
    //    selFileNm.current = fileNm;

    //    confirmDelDlgHdr.current = 'Delete file';
    //    confirmDelDlgBLine1.current = "Do you want to delete the file '" + fileNm + "' ?";

    //    setOpenDelConfirmDlg(true);
    //}

    //const deleteEnrollmentAttachment = async (enrmntId, attachmentId, fileNm) => {

    //    const inputJson = {
    //        EnrmntId: enrmntId,
    //        AttchmntId: attachmentId
    //    };

    //    const apiUrl = new URL(
    //        process.env.REACT_APP_DELETE_ENROLLMENT_DOC,
    //        process.env.REACT_APP_BASE_URL,
    //    );
    //    try {

    //        setIsLoading(true);

    //        let response = await CallAPI({ url: apiUrl.href, method: 'POST', headers: {}, body: inputJson }, sessions, dispatch);

    //        if (response !== null && response !== undefined) {
    //            setSuccessMsg('File \'' + fileNm + '\' deleted successfully');
    //            loadAttachments();
    //        }
    //    }
    //    catch (err) {
    //        setErrorMsg(ErrorMessages.UnknownError);
    //    }
    //    finally {
    //        setIsLoading(false);
    //    }
    //}

    const canDeleteAttachment = async (CntrlNm) => {

        if (CntrlNm === '') {
            if (isProspectUser.current) {
                return true;
            }
        }

        return false;
    }

    useEffect(() => {
        isProspectUser.current = IsLoggedInUserProspectUser(userId, sessionId);
        loadAttachments();
    }, []);

    const columns = [
        {
            accessorKey: 'AttchmntId',
            header: '',
            Cell: ({ cell }) => (
                <IconButton title="Download" onClick={() => downloadAttachment(cell.row.original.AttchmntId, cell.row.original.FileName)}>
                    <DownloadIcon width="8px" height="8px" color="#0f69ff" />
                </IconButton>
            ),
            size: 20
        },
        {
            accessorKey: 'FileName',
            header: 'File Name',
            Cell: ({ cell, column }) => (
                <Box>
                    {cell.getValue() && cell.getValue().length > 25 ? cell.getValue().substring(0, 25) + '...' : cell.getValue()}
                </Box>
            ),
            size: 230
        },
        {
            accessorKey: 'SignRequire',
            header: 'Requires Docusign',
            size: 180,
        },
        {
            accessorKey: 'SignCompleted',
            header: 'Docusign Completed',
            size: 180,
        },
        {
            accessorKey: 'CntrlNm',
            header: 'Signed Attachment',
            Cell: ({ cell }) => (
                cell.row.original.IsSignCompleted ?
                    <IconButton title="Download" onClick={() => downloadSignedAttachment(cell.row.original.AttchmntId, cell.row.original.FileName)}>
                        <DownloadIcon width="8px" height="8px" color="#0f69ff" />
                    </IconButton>
                    : ''
            ),
            size: 20
        },
        {
            accessorKey: 'CreatedBy',
            header: 'Uploaded By',
            Cell: ({ cell, column }) => (
                <Box>
                    {cell.getValue() && cell.getValue().length > 25 ? cell.getValue().substring(0, 25) + '...' : cell.getValue()}
                </Box>
            ),
            size: 230
        },
        //{
        //    accessorKey: 'CntrlNm',
        //    header: '',
        //    Cell: ({ cell }) => (
        //        <IconButton title="Delete" disabled={cell.getValue() == '' ? true : false} onClick={() => deleteAttachment(enrmntid, cell.row.original.AttchmntId, cell.row.original.FileName)}>
        //            <DeleteForeverIcon sx={{ fontSize: 24 }} color="#0f69ff" />
        //        </IconButton>
        //    ),
        //    maxSize: 10,
        //    minSize: 10,
        //},

    ];

    return (
        <React.Fragment >
            {/*<AutoLagoutTimer userId={userId} sessionId={sessionId} />*/}
            <Container maxWidth="lg" sx={{ pt: 8 }}>
                <Grid item>
                    <Box alignItems="center" sx={{ display: 'flex', justifyContent: 'space-between', }}>
                        <Stack direction="row" alignItems="center">
                            <Typography color="text.primary" sx={{}} variant="h6" component="div">
                            Applicantion name :
                            </Typography>
                            <Typography color="text.primary" sx={{ml: 1}} variant="body1" component="div">
                                {enrolNm}
                            </Typography>
                        </Stack>
                        <Stack direction="row">
                            <Typography color="text.primary" sx={{}} variant="body1" component="div">
                                Applied for : 
                            </Typography>
                            <Typography color="text.primary" sx={{ ml: 1 }} variant="body1" component="div">
                                {props.templateNm}
                            </Typography>
                        </Stack>
                    </Box>
                </Grid>
                <Grid item>
                    <LoadingOverlay active={isLoading} spinner={<RiseLoader color={'#1D50AF'} />} >
                        <Box sx={{ pt: 3 }}>
                            <AlertControl Message={errorMsg} severity="error" color="error" icon={true} />
                            <AlertWithTitle Message={successMsg} variant="body1" severity="success" color="success" icon={true} Title={successMsgTitle} />
                        </Box>
                        <Box sx={{ pt: 3 }}>
                            <MaterialReactTable
                                columns={columns}
                                data={data}
                                enableColumnActions={false}
                                enableColumnFilters={false}
                                enablePagination={false}
                                enableSorting={false}
                                enableBottomToolbar={false}
                                enableTopToolbar={false}
                                muiTablePaperProps={{
                                    elevation: 0,
                                }}
                                muiTableBodyCellProps={({ column }) => ({
                                    sx: (theme) => ({
                                        color: 'text.primary',
                                        fontSize: theme.typography.body1,
                                        borderBottom: 1,
                                        borderColor: 'divider',
                                        pl: 2, fontWeight: 400, textAlign: 'center', pt: 1, pb: 0.5,
                                    }),
                                })
                                }
                                muiTableHeadCellProps={{
                                    sx: (theme) => ({
                                        color: 'text.secondary',
                                        fontSize: theme.typography.body2,
                                        borderTop: 1,
                                        borderBottom: 1,
                                        borderColor: 'divider',
                                        pl: 2, pr: 2,
                                        pt: 1, pb: 1,
                                    }),
                                }}
                            />
                        </Box>
                    </LoadingOverlay>
                </Grid>
            </Container>
        </React.Fragment>
    );
};

export default ProspectDocListDlg;
