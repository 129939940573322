import React, { useEffect, useState, useRef } from 'react';
import { Dialog, DialogTitle, DialogContent, Box, DialogActions, Button, Typography, IconButton, Grid, Toolbar } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import AppViewControl from '../global/AppViewCtrl';
import AppBar from '@mui/material/AppBar';
import { makeStyles } from '@material-ui/core/styles';
import LinearProgress from '@material-ui/core/LinearProgress';

const useStyles = makeStyles({
    root: {
        width: '100%',
    },
});

const ExportToGWESLoaderDlg = (props) => {

    const { onClose, open } = props;

    const classes = useStyles();
    const [progress, setProgress] = useState(5);

    useEffect(() => {
        const timer = setInterval(() => {
            setProgress((oldProgress) => {
                if (oldProgress === 100) {
                    return 0;
                }
                const diff = Math.random() * 10;
                return Math.min(oldProgress + diff, 100);
            });
        }, 500);

        return () => {
            clearInterval(timer);
        };
    }, []);


    return (        
        <Dialog
            sx={{ '& .MuiDialog-paper': { width: '100%', maxHeight: 435 } }}
            maxWidth="xs"
            open={open}
        >
            <DialogTitle className="ms-2 me-2">
                <Typography variant="body1" color="text.primary" sx={{ fontWeight: 600 }}>{props.loaderText}</Typography>
            </DialogTitle>
            <DialogContent>
                 <LinearProgress variant="determinate" value={progress} active={open} />
            </DialogContent>            
        </Dialog>
    );
};

export default ExportToGWESLoaderDlg;
