import React, { useEffect, useState } from 'react';
import { FormControlLabel, Box, FormLabel, RadioGroup, Stack, Radio, FormControl, TextField, Typography, Button, Paper, Divider, List, ListItem, ListItemButton, ListItemText, ListSubheader } from '@mui/material';
import OverlaySpinner from '../Controls/OverlaySpinner';
import AlertControl from '../Controls/AlertControl';
import AlertWithTitle from '../Controls/AlertWithTitle';
import { ErrorMessages } from '../../common/ErrorMessages';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { APICall, WriteToActivityLog } from '../../global/Api';
import { ActivityLogEvent } from '../../common/AOBEnum';
import Checkbox, { checkboxClasses } from "@mui/material/Checkbox";

const DocusignSigners = (props) => {

    const [signerList, setSignerList] = useState(null);
    const [errorMsg, setErrorMsg] = useState(null);
    const [successMsg, setSuccessMsg] = useState('');
    const [isDataLoading, setIsDataLoading] = useState(false);
    const [listFilter, setListFilter] = React.useState('1');
    const [sortBy, setSortBy] = React.useState(1);

    const [userId, setUserID] = useState(props.userId || -1);
    const [sessionId, setSessionId] = useState(props.sessionId || '');

    const loadForm = async (sortListBy) => {
        try {

            const apiUrl = new URL(
                process.env.REACT_APP_GET_DOCUSIGN_SIGNERS,
                process.env.REACT_APP_BASE_URL,
            );

            apiUrl.searchParams.set('sortby', `${sortListBy}`);

            setIsDataLoading(true);

            let response = await APICall({ url: apiUrl.href, method: 'GET', contentType: 'application/json', responseType: '' }, userId, sessionId);

            if (response !== null && response !== undefined) {
                if (response["data"] && response["data"].length > 0) {
                    setSignerList(response.data);
                }
                else {
                    setErrorMsg(ErrorMessages.UnknownError);
                }
            }
            else {
                console.error("Response is null or undefined while fetching Docusign Configuration information.");
                setErrorMsg(ErrorMessages.UnknownError);
            }
        }
        catch (err) {
            setErrorMsg(ErrorMessages.UnknownError);
        }
        finally {
            setIsDataLoading(false);
        }
        return null;
    }

    useEffect(() => {
        loadForm(sortBy);
    }, []);

    const handleSortChange = (event) => {
        setSortBy(event.target.value);
        loadForm(event.target.value);
    };

    const SaveList = async () => {

        setErrorMsg('');
        setSuccessMsg('');

        try {

            let signerIds = [];

            if (signerList != null && signerList.length > 0) {
                signerList.forEach((value, key) => {

                    signerIds.push({ ContactId: value.ContactId, IsSelected: value.IsSelected ? true : false });
                });
            }

            setIsDataLoading(true);

            let inputJson = {
                "signerList": signerIds
            };

            const apiUrl = new URL(
                process.env.REACT_APP_SAVE_DOCUSIGN_SIGNER_LIST,
                process.env.REACT_APP_BASE_URL,
            );

            let response = await APICall({ url: apiUrl.href, method: 'POST', payload: inputJson, contentType: 'application/json', responseType: '' }, userId, sessionId);

            if (response !== null && response !== undefined) {
                if (response.meta.Updated === "1") {
                    await WriteToActivityLog(ActivityLogEvent.SystemOptions, "Saved DocuSign configuration.", userId, sessionId);
                    setSuccessMsg("DocuSign signer list has been successfully saved.");
                }
                else {
                    setErrorMsg(response.Errors[0].Message);
                }
            }
        }
        catch (err) {
            setErrorMsg(ErrorMessages.UnknownError);
        }
        finally {
            setIsDataLoading(false);
        }

    }

    const handleToggle = (value) => () => {

        let found = signerList.find(element => element.Email === value.Email);

        if (found != undefined && found != null) {
            found.IsSelected = !found.IsSelected;
        }

        const newSet = [...signerList];
        setSignerList(newSet);
    };

    return (
        <>
            <Box component="main" sx={{ flex: 1, height: '100%', pt: 0, pl: 6 }} >
                <Box >
                    <Typography variant='body2' color="text.blueText" sx={{ fontWeight: 500, pb: 1, lineHeight: 1.5, borderBottom: '0px solid #9FA4A8' }}>Docusign Signer</Typography>
                    <Divider variant="fullWidth" component="div" sx={{ background: '#BBBFBF', }} />
                    <OverlaySpinner disappear={isDataLoading} />
                    <AlertControl Message={errorMsg} severity="error" color="error" icon={true} />
                    <AlertWithTitle Message={successMsg} severity="success" color="success" icon={true} Title="Docusign Configuration" />
                    <Box item md={6} sx={{ pr: 2, pt: 2 }}>
                        <Stack direction="row" spacing={1.5}>
                            <Typography variant="body4" color="text.primary" sx={{ textTransform: 'none', paddingBottom: '4px', fontWeight: 500, pb: 2, lineHeight: 2 }} >Sort By</Typography>
                            <Box elevation={0} sx={{pb: 2, width: '200px'}}>
                                {/*<label htmlFor="SortBy" className="form-label">Sort By</label>*/}
                                <select id="SortBy" className="form-select" name="SortBy" value={sortBy} onChange={handleSortChange}>
                                    <option key="1" value="1">Name</option>
                                    <option key="2" value="2">Email</option>
                                    <option key="3" value="3">Selected</option>
                                </select>
                            </Box>
                        </Stack>
                        {/*<Box sx={{pt: 3, pb: 0.5}}>*/}
                        {/*    <Stack direction="row" spacing={2}>*/}
                        {/*        <Typography variant="body2" color="text.primary" sx={{ textTransform: 'none', fontWeight: 400, pt: 1 }} >Show</Typography>*/}
                        {/*        <RadioGroup row*/}
                        {/*            aria-labelledby="demo-controlled-radio-buttons-group"*/}
                        {/*            name="controlled-radio-buttons-group"*/}
                        {/*            value={listFilter}*/}
                        {/*            onChange={handleRadioChange}*/}
                        {/*        >*/}
                        {/*            <FormControlLabel value="1" control={<Radio />} label={<Typography variant="body3" color="textSecondary">All</Typography>} />*/}
                        {/*            <FormControlLabel value="2" control={<Radio />} label={<Typography variant="body3" color="textSecondary">Selected</Typography>} />*/}
                        {/*            <FormControlLabel value="3" control={<Radio />} label={<Typography variant="body3" color="textSecondary">Not selected</Typography>} />*/}
                        {/*        </RadioGroup>*/}
                        {/*    </Stack>*/}
                        {/*</Box>*/}
                        <Box sx={{pt: 1}}>
                            <List dense disabled={true} sx={{ pt: 1.5, border: 1, borderColor: '#B3B9BC', borderRadius: '6px', bgcolor: 'background.paper', maxHeight: '500px', overflow: 'auto' }} label="Templates">
                            {signerList !== null ?
                                signerList.map((value) => {
                                    const labelId = `checkbox-list-secondary-label-${value}`;
                                    return (
                                        <ListItem 
                                            key={value.ContactId}
                                            secondaryAction={
                                                <Checkbox 
                                                    edge="end"
                                                    sx={{
                                                        [`&, &.${checkboxClasses.checked}`]: {
                                                            color: 'Check.main',
                                                        },
                                                    }}
                                                    inputProps={{ 'aria-labelledby': labelId }}
                                                    key={value.ContactId}
                                                    checked={value.IsSelected ? true : false}
                                                    onChange={handleToggle(value)}
                                                />
                                            }
                                            disablePadding
                                        >
                                            <ListItemButton>
                                                <ListItemText id={value.ContactId} primary={<Typography variant="body4" color="text.primary" key={value.ContactId}>{value.FirstName + ' ' + value.LastName + ' (' + value.Email + ')'}</Typography>} />
                                            </ListItemButton>
                                        </ListItem>
                                    );
                                }) : ''}
                            </List>
                        </Box>
                    </Box>
                    {/*<Box sx={{ pt: 0, pr: 1, display: 'flex', justifyContent: 'space-between' }}>*/}
                    {/*    <Typography variant="body3" color="text.primary" sx={{ textTransform: 'none', fontWeight: 400, pt: 1 }} >Total: 2</Typography>*/}
                    {/*    <Typography variant="body3" color="text.primary" sx={{ textTransform: 'none', fontWeight: 400, pt: 1 }} >Selected: 1</Typography>*/}
                    {/*</Box>*/}
                    <Box sx={{ pt: 3.5 }}>
                        <Button variant="contained" size="large" style={{ boxShadow: 'none', border: 0, borderRadius: '20px', padding: '0.3rem 2.5rem', outline: 'none', }} onClick={() => SaveList()}>
                            Save
                        </Button>
                    </Box>
                </Box>
            </Box >
        </>
    )
}
export default DocusignSigners;