import React, { useEffect, useState, useRef } from 'react';
import { Dialog, Box, DialogTitle, DialogContent, DialogActions, Button, Typography, IconButton, Grid, FormControl } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import AlertControl from '../components/Controls/AlertControl';
import { useDispatch, useSelector } from 'react-redux';
import { ErrorMessages } from '../common/ErrorMessages';
import { LoadProfilePic } from '../global/Helpers';
import profimg from '../assets/images/profimg.jpg';
import { UploadUserFiles } from '../global/APIUtils';
import LoadingOverlay from 'react-loading-overlay';
import RiseLoader from 'react-spinners/RiseLoader'
import { APICall, WriteToActivityLog } from '../global/Api';
import { ActivityLogEvent } from '../common/AOBEnum';

const ProfilePicUpdDlg = (props) => {
    const { onClose, open, onChange, cntctid } = props;
    const [invalidImage, setInvalidImage] = useState(true);
    const [errorMsg, setErrorMsg] = useState('');
    const [imageUrl, setImageUrl] = useState(null);
    const [picUpload, setPicUpload] = useState(null);
    const [isOkBtnEnabled, setIsOkBtnEnabled] = useState(false);
    const [isLoading, setIsLoading] = useState(true);

    const [userId, setUserId] = useState(props.userId);
    const [sessionId, setSessionId] = useState(props.sessionId);

    const handleClose = (event) => {
        event.preventDefault();
        onClose();
    };

    const handleOk = async (event) => {
        event.preventDefault();

        if (!invalidImage) {

            try {

                setIsLoading(true);

                //const loginPayload = {
                //    UserId: parseInt(cntctid),
                //    ProfilePic: picUpload
                //};
                const formData = new FormData();
                formData.append("UserId", parseInt(cntctid));
                formData.append("ProfilePic", picUpload);

                //let endPoint = process.env.REACT_APP_PROFILE_IMAGE_UPLOAD;

                const apiUrl = new URL(
                    process.env.REACT_APP_PROFILE_IMAGE_UPLOAD,
                    process.env.REACT_APP_BASE_URL,
                );

                //let response = await UploadUserFiles(apiUrl.href, formData, sessions, dispatch);
                let response = await APICall({ url: apiUrl.href, method: 'POST', payload: formData, contentType: 'multipart/form-data', responseType: '' }, userId, sessionId);

                if (response !== null && response !== undefined) {

                    setIsLoading(false);

                    let apiErrors = response.Errors;

                    if (apiErrors != undefined && apiErrors.length > 0) {
                        setErrorMsg(apiErrors[0].Message);
                    }
                    else {
                        await WriteToActivityLog(ActivityLogEvent.ProfileScreen, "Changed profile picture.", userId, sessionId);
                        onChange();
                    }
                }
            }
            catch (err) {
                setErrorMsg(ErrorMessages.UnknownError);
                setIsLoading(false);
            }
            finally {
            }
        }
        else if (!imageUrl) {
            setErrorMsg('Please select a valid image file.');
        }
    };

    const onChangeFile = (event) => {
        const imageFile = event.target.files[0];

        const reader = new FileReader();

        setErrorMsg('');
        if (!imageFile) {
            setErrorMsg("Please select an image.");
            setInvalidImage(true);
            return false;
        }

        if (!imageFile.name.match(/\.(jpg|jpeg|png)$/)) {
            setErrorMsg("Please select a valid image file.");
            setInvalidImage(true);
            return false;
        }

        if (imageFile.type !== 'image/jpeg' && imageFile.type !== 'image/png') {
            setErrorMsg("Please select a valid image file.");
            setInvalidImage(true);
            return false;
        }

        if (Math.floor(imageFile.size / 1048576) > 2) {   /* 2MB */
            setErrorMsg("File size exceeds the limit allowed (2MB) and cannot be uploaded.");
            setInvalidImage(true);
            event.preventDefault();
            return false;
        }

        reader.onload = (e) => {
            const img = new Image();
            img.onload = () => {
                setPicUpload(imageFile);
                setInvalidImage(false);
            };
            img.onerror = () => {
                setErrorMsg("Invalid image content.");
                setInvalidImage(true);
                return false;
            };

            setImageUrl(e.target.result);
            img.src = e.target.result;
        };
        reader.readAsDataURL(imageFile);
        setIsOkBtnEnabled(true);
    }

    const loadProfilePic = async () => {

        try {

            setIsLoading(true);

            const apiUrl = new URL(
                process.env.REACT_APP_GET_PROFILE_IMAGE,
                process.env.REACT_APP_BASE_URL,
            );

            apiUrl.searchParams.set('cntctId', `${parseInt(userId)}`);

            //let imageURL = await LoadProfilePic(cntctid, userId, '');

            let imageURL = await APICall({ url: apiUrl.href, method: 'GET', responseType: 'blob' }, userId, sessionId);

            if (imageURL) {
                if (imageURL.type == 'image/jpeg') {
                    let img = URL.createObjectURL(imageURL);
                    setImageUrl(img);
                }
            }
        }
        catch (err) {
            setErrorMsg(ErrorMessages.UnknownError);
        }
        finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        loadProfilePic();
    }, []);

    return (
        <Dialog onClose={handleClose} open={open} keepMounted>
            <LoadingOverlay active={isLoading} spinner={<RiseLoader color={'#1D50AF'} />} >
            <DialogTitle>
                Update profile photo
                <IconButton
                    aria-label="close"
                    onClick={handleClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.Icon.main,
                    }}
                >
                    <CloseIcon />
                </IconButton>
            </DialogTitle>
            <DialogContent dividers sx={{ ml: 5, mr: 5 }}>
                <AlertControl Message={errorMsg} severity="error" color="error" icon={true} />
                <Grid container>
                    <Grid item md={11} sx={{ }}>
                        <Typography sx={{ fontWeight: 400 }} gutterBottom variant="body3" color="text.primary">
                            Please choose a photo that’s less than 2MB size, and format should be PNG,JPG,JPEG
                        </Typography>
                    </Grid>
                     <Grid item md={11} sx={{ pt: 1 }}>
                        <Typography sx={{ mb: 1 }} variant="body1" color="text.primary"><strong>Preview</strong></Typography>
                    </Grid>
                    <Grid item md={11} sx={{ pt: 1 }}>
                        {imageUrl ?
                            <img src={imageUrl} alt="Prifile Picture" className="prof-pic-prvw" />
                            :
                            <img src={profimg} alt="Prifile Picture" className="prof-pic-prvw" />
                            //<span className="">

                            //    <svg version="1.1" width="120" height="120" viewBox="0 0 256 256">

                            //        <defs>
                            //        </defs>
                            //        <g style={{ stroke: 'none', strokeWidth: 0, strokeDasharray: 'none', strokeLinecap: 'butt', strokeLinejoin: 'miter', strokeMiterlimit: 10, fill: 'none', fillRule: 'nonzero', opacity: 1 }} transform="translate(1.4065934065934016 1.4065934065934016) scale(2.81 2.81)" >
                            //            <circle cx="45" cy="45" r="44" style={{ stroke: 'none', strokeWidth: 1, strokeDasharray: 'none', strokeLinecap: 'butt', strokeLinejoin: 'miter', strokeMiterlimit: 10, fill: 'rgb(178,178,178)', fillRule: 'nonzero', opacity: 1 }} transform="  matrix(1 0 0 1 0 0) " />
                            //            <circle cx="44.997" cy="39.727000000000004" r="19.817" style={{ stroke: 'none', strokeWidth: 1, strokeDasharray: 'none', strokeLinecap: 'butt', strokeLinejoin: 'miter', strokeMiterlimit: 10, fill: 'rgb(109,109,109)', fillRule: 'nonzero', opacity: 1 }} transform="  matrix(1 0 0 1 0 0) " />
                            //            <path d="M 11.266 73.25 C 19.337 63.622 31.454 57.5 45 57.5 c 13.546 0 25.663 6.122 33.734 15.75 l 0 0 C 70.663 82.878 58.547 89 45 89 C 31.454 89 19.337 82.878 11.266 73.25 L 11.266 73.25 z" style={{ stroke: 'none', strokeWidth: 1, strokeDasharray: 'none', strokeLinecap: 'butt', strokeLinejoin: 'miter', strokeMiterlimit: 10, fill: 'rgb(109,109,109)', fillRule: 'nonzero', opacity: 1 }} transform=" matrix(1 0 0 1 0 0) " strokeLinecap="round" />
                            //            <path d="M 45 90 C 20.187 90 0 69.813 0 45 C 0 20.187 20.187 0 45 0 c 24.813 0 45 20.187 45 45 C 90 69.813 69.813 90 45 90 z M 45 2 C 21.29 2 2 21.29 2 45 c 0 23.71 19.29 43 43 43 c 23.71 0 43 -19.29 43 -43 C 88 21.29 68.71 2 45 2 z" style={{ stroke: 'none', strokeWidth: 1, strokeDasharray: 'none', strokeLinecap: 'butt', strokeLinejoin: 'miter', strokeMiterlimit: 10, fill: 'rgb(43,43,43)', fillRule: 'nonzero', opacity: 1 }} transform=" matrix(1 0 0 1 0 0) " strokeLinecap="round" />
                            //            <path d="M 78.734 73.25 c -6.576 -7.844 -15.837 -13.358 -26.368 -15.133 c 7.294 -2.925 12.451 -10.048 12.451 -18.387 c 0 -10.945 -8.873 -19.817 -19.817 -19.817 S 25.183 28.785 25.183 39.73 c 0 8.339 5.157 15.462 12.451 18.387 c -10.531 1.775 -19.793 7.29 -26.368 15.133 v 0 C 19.337 82.878 31.454 89 45 89 C 58.547 89 70.663 82.878 78.734 73.25 L 78.734 73.25 z" style={{ stroke: 'none', strokeWidth: 1, strokeDasharray: 'none', strokeLinecap: 'butt', strokeLinejoin: 'miter', strokeMiterlimit: 10, fill: 'rgb(109,109,109)', fillRule: 'nonzero', opacity: 1 }} transform=" matrix(1 0 0 1 0 0) " strokeLinecap="round" />
                            //            <path d="M 45 90 c -13.344 0 -25.919 -5.871 -34.5 -16.107 L 9.961 73.25 l 0.539 -0.643 c 6.239 -7.441 14.692 -12.654 24.046 -14.883 c -6.379 -3.687 -10.363 -10.467 -10.363 -17.995 c 0 -11.479 9.339 -20.817 20.817 -20.817 s 20.817 9.339 20.817 20.817 c 0 7.528 -3.983 14.309 -10.362 17.995 c 9.354 2.229 17.808 7.441 24.046 14.883 l 0.538 0.643 l -0.538 0.643 C 70.919 84.129 58.344 90 45 90 z M 12.581 73.25 C 20.764 82.635 32.531 88 45 88 c 12.47 0 24.236 -5.365 32.419 -14.75 C 70.887 65.761 61.964 60.748 52.2 59.104 l -3.506 -0.591 l 3.3 -1.323 c 7.183 -2.882 11.823 -9.734 11.823 -17.46 c 0 -10.376 -8.441 -18.817 -18.817 -18.817 s -18.817 8.441 -18.817 18.817 c 0 7.726 4.641 14.578 11.823 17.46 l 3.3 1.323 L 37.8 59.104 C 28.037 60.748 19.114 65.76 12.581 73.25 z" style={{ stroke: 'none', strokeWidth: 1, strokeDasharray: 'none', strokeLinecap: 'butt', strokeLinejoin: 'miter', strokeMiterlimit: 10, fill: 'rgb(43,43,43)', fillRule: 'nonzero', opacity: 1 }} transform=" matrix(1 0 0 1 0 0) " strokeLinecap="round" />
                            //        </g>
                            //    </svg>
                            //</span>
                        }
                        {/*<img src={URL.createObjectURL(imageUrl)} alt="Uploaded File" className="prof-pic-prvw pb-3" />*/}
                    </Grid>
                    <Grid item md={11} sx={{ pt: 3, pb: 2 }}>
                        <FormControl variant="outlined">
                            <input type="file" className="prof-pic-upld-ctrl" id="prof-pic-upld" onChange={onChangeFile} accept=".jpg,.jpeg,.png" />
                        </FormControl>
                    </Grid>
                </Grid>
            </DialogContent>
                <DialogActions sx={{ mr: 2 }}>
                    <Box sx={{}}>
                        <Button onClick={handleClose} variant="contained" sx={{ mr: 1, borderRadius: '20px', padding: '0.25rem 2.5rem', textTransform: 'none' }} >
                            Cancel
                        </Button>
                    </Box>
                    <Button onClick={handleOk} disabled={!isOkBtnEnabled} variant="contained" sx={{ mt: 1, mb: 1, borderRadius: '20px', padding: '0.25rem 2.5rem', textTransform: 'none' }} >Upload</Button>
            </DialogActions>
            </LoadingOverlay>
        </Dialog>
    );
}

export default ProfilePicUpdDlg;
