import React from 'react';
import ReactHtmlParser from 'react-html-parser';
import Alert from '@mui/material/Alert';
import { Typography, AlertTitle } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import Collapse from '@mui/material/Collapse';
import CloseIcon from '@mui/icons-material/Close';

const AlertWithTitle = (props) => {

    const [open, setOpen] = React.useState(true);

    return (
        <>
            {
                (props.Message) ?
                    props.icon ?
                        (
                            <Alert severity={props.severity} color={props.color} sx={{ mb: 2, mt: 1 }}>
                                <AlertTitle>{props.Title}</AlertTitle>
                                <Typography variant="body2">{props.Message}</Typography>
                            </Alert>
                        )
                        :
                        (

                            <Alert severity={props.severity} color={props.color} icon={false} sx={{ mb: 2, mt: 1 }}>
                                <AlertTitle>{props.Title}</AlertTitle>
                                <Typography variant="body2">{props.Message}</Typography>
                            </Alert>
                        )

                    : <p></p>
            }
        </>
    );
}

export default AlertWithTitle;