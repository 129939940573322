import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useNavigate } from "react-router-dom";
import MaterialReactTable, {
    MRT_ShowHideColumnsButton,
    MRT_ToggleGlobalFilterButton,
    MRT_ToggleFiltersButton,
} from 'material-react-table';
import PostLoginMenu from './PostLoginMenu'
import { MainNavMenu } from './MainNavMenu'
import { Typography, Tooltip, IconButton, AddBoxIcon, DeleteIcon, Box, Button, Menu, MenuItem, FormControlLabel, Checkbox, Paper } from '@mui/material';
import PriorityHighSharpIcon from '@mui/icons-material/PriorityHighSharp';
import { InputBase } from '@mui/material';
import {
    QueryClient,
    QueryClientProvider,
    useInfiniteQuery,
} from '@tanstack/react-query';
import { Virtualizer } from '@tanstack/react-virtual';
import { useSelector, useDispatch } from 'react-redux';
import { CallAPI, UploadUserFiles } from '../global/APIUtils';
import { ErrorMessages } from '../common/ErrorMessages';
import { Trash, TrashFill, Star, StarFill, EnvelopeOpen, ArchiveFill, ArrowClockwise, ChevronDown, ThreeDotsVertical, Search, SkipStartFill, CircleFill, Link } from 'react-bootstrap-icons';

import { EditorState, convertToRaw } from 'draft-js';
//import { Editor } from "react-draft-wysiwyg"
import draftToHtmlPuri from "draftjs-to-html";
//import { MessageUserListModal } from './MessageUserListModal';
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
//import { createTheme, ThemeProvider } from "@mui/material/styles";
import MUIEditor, { MUIEditorState } from 'react-mui-draft-wysiwyg'

//import MUIRichTextEditor from "mui-rte";
//import { NavMenu } from '../NavMenu'
//import LoaderControl from '../Controls/LoaderControl';
//import BreadCumControl from '../Controls/BreadcumControl';
//import AlertText from '../Controls/AlertText';
//import EnrollmentBanner from '../EnrollmentBanner';
//import { Footer } from '../Footer';
//import { ApiHelper } from '../../customFunctions/apiHelper';
import { MessageFollowup, MessageResponseType } from '../customFunctions/SysParmConfig';
//import { MessageUserListModal } from './MessageUserListModal';
//import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
//import { useSelector, useDispatch } from 'react-redux';
//import { Typography, Container } from "@material-ui/core";
//import TextEditor from "./TextEditor";


const EmailComposeMessage = (props) => {

    const sessions = useSelector((state) => state.sessionMgmnt);
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(false);
    const [msg, setMsg] = useState('');
    const [isSuccessMsg, setIsSuccessMsg] = useState(false);
    //const [editorState, setEditorState] = useState(EditorState.createEmpty());
    let history = useNavigate();
    const [emailList, setEmailList] = useState([]);
    const [selectedEmail, setSelectedEmail] = useState('')
    const [selectedName, setSelectedName] = useState('')
    const [toContactId, setToContactId] = useState(-1)
    const [showUserList, setShowUserList] = useState(false)
    const [suggestionItems, setSuggestionItems] = useState([]);
    const [priorityTypId, setPriorityTypId] = useState(MessageResponseType.None);
    const [followupTypId, setFollowupTypId] = useState(MessageFollowup.None);
    const [subject, setSubject] = useState('');
    const [htmlContent, setHtmlContent] = useState('');
    const [orgMessageId, setOrgMessageId] = useState(null)
    const [conversationId, setConversationId] = useState(null)

    const [priorityTypeList, setPriorityTypeList] = useState([]);
    const [recipientTypeList, setRecipientTypeList] = useState([]);
    const [responseTypeList, setResponseTypeList] = useState([]);
    const [followupTypeList, setFollowupTypeList] = useState([]);
    const [errorMsg, setErrorMsg] = useState('');

    const handleEditorChange = (state) => {
        setEditorState(state);
        //convertContentToHTML();
    }

    const onEmailChange = (event) => {
        setSelectedName(event.target.value);
        var selectedValue = emailList.find(email => email.Name === event.target.value);
        if (selectedValue) {
            setSelectedEmail(selectedValue.EmailAddress);
            setToContactId(selectedValue.ContactId);
            setSelectedName(selectedValue.Name);
        } else {
            setSelectedEmail('');
            setToContactId(-1);
        }
    };

    const loadEmailList = () => {
        setLoading(true);
        //setAlertMsg('');

        let headers = {
            'Content-Type': 'application/json'
        };

        let apiUrl = process.env.REACT_APP_GET_MESSAGES_LOOKUP_ITEMS;

        //ApiHelper({ url: apiUrl, method: 'GET', headers: headers, sessions: sessions, dispatch: dispatch })
        //    .then(resposnse => {
        //        if (resposnse !== null) {
        //            setEmailList(resposnse["MessageNotifyList"]);
        //            prepareSuggestionItems(resposnse["MessageNotifyList"]);

        //            setPriorityTypeList(resposnse["PriorityType"]);
        //            setRecipientTypeList(resposnse["RecipientType"]);
        //            setResponseTypeList(resposnse["ResponseType"]);
        //            setFollowupTypeList(resposnse["FollowupType"]);

        //            setLoading(false);
        //        }
        //    });
    }

    const lookupItems = async () => {
        let apiUrl = new URL(
            process.env.REACT_APP_GET_MESSAGES_LOOKUP_ITEMS,
            process.env.REACT_APP_API_STAGE,
            process.env.REACT_APP_BASE_URL
        );

        let response = await CallAPI({ url: apiUrl.href, method: 'GET', headers: {} }, sessions, dispatch);

        if (response !== null && response !== undefined) {
            setEmailList(response["MessageNotifyList"]);
            prepareSuggestionItems(response["MessageNotifyList"]);

            setPriorityTypeList(response["PriorityType"]);
            setRecipientTypeList(response["RecipientType"]);
            setResponseTypeList(response["ResponseType"]);
            setFollowupTypeList(response["FollowupType"]);
        }
        else {
            throw Error(ErrorMessages.UnknownError);
        }
    }


    const prepareSuggestionItems = (items) => {
        let sugItems = [];
        items.map((e) => (
            sugItems.push({ text: e.Name, value: e.Name, url: e.EmailAddress })
        ));
        setSuggestionItems(sugItems);
    }

    const SelectedUserFromModalOnHide = (cntctId, emailAdrs, name) => {
        if (cntctId) {
            setToContactId(cntctId);
            setSelectedEmail(emailAdrs);
            setSelectedName(name);
            setShowUserList(false);
        }
    }

    const handleUserListClick = () => {
        setShowUserList(true);
    }

    const handelCancelClick = () => {
        history('/EmailMessages');
    }

    const handleSendMessage = async () => {

        let plainText = editorState.getCurrentContent().getPlainText();
        if (!plainText) {
            setMsg('Email body content cannot be empty.');
            setIsSuccessMsg(false);
            return;
        }

        let htmlText = draftToHtmlPuri(convertToRaw(editorState.getCurrentContent()));
        let text = encodeURIComponent(htmlText); //base64_encode(htmlText);

        if (text.length > 8000) {
            setMsg('You can\'t use more than 8000 characters in Email body.');
            setIsSuccessMsg(false);
            return;
        }

        setMsg('');
        setLoading(true);

        //let inputJson = {
        //    "MessageId": null,
        //    "PriorityTypId": parseInt(priorityTypId),
        //    /* "FromCntctId": parseInt(props.location.state.loginusrId),*/
        //    "ToCntctId": toContactId,
        //    "Subject": subject,
        //    "Text": text,
        //    "OrgMessageId": orgMessageId,
        //    /*"ModUserId": parseInt(props.location.state.loginusrId),*/
        //    "ResponseTypId": parseInt(props.location.state.responseTypId),
        //    "FlagShowSendMail": true, //None
        //    "FollowupTypId": parseInt(followupTypId), //'None'
        //    "HasReplied": false,
        //    "IsDeleted": false
        //}

        let recipients = [];
        let senders = [];
        let ccRecipients = [];

        let recipientObj = { RecipientId: toContactId };
        recipients.push(recipientObj);

        //let senderObj = { SenderId: toContactId };
        //senders.push(senderObj);

        let ccRecipientObj = { CCRecipientId: null };
        ccRecipients.push(ccRecipientObj);


        let inputJson = {
            "ConversationId": conversationId,
            "PriorityTypId": parseInt(priorityTypId),
            "Subject": subject,
            "EmailBody": htmlText,
            "ParentId": orgMessageId,
            "IsImportant": false,
            "Recipients": recipients,
            "Senders": [],
            "CCRecipients": []
        };

        console.log(JSON.stringify(inputJson));

        let headers = {
            'Content-Type': 'application/json'
        };


        let apiUrl = new URL(
            process.env.REACT_APP_SEND_MESSAGE,
            process.env.REACT_APP_API_STAGE,
            process.env.REACT_APP_BASE_URL
        );

        let response = await CallAPI({ url: apiUrl.href, method: 'POST', headers: headers, body: inputJson }, sessions, dispatch);

        if (response !== null && response !== undefined) {
            let apiErrors = response["Errors"];

            if (apiErrors != undefined && apiErrors.length > 0) {
                //createControlLabelMsg(controlName, apiErrors[0].Message);
                setIsSuccessMsg(false);
                setMsg(apiErrors[0].Message);
            }
            else if (response["data"] != undefined && response["data"].length > 0) {
                setConversationId(response["data"][0].ConversationId)
                //setMsg("Message has been sent.")
                history('/EmailMessages');
            }
        }
        else {
            throw Error(ErrorMessages.UnknownError);
        }

        //ApiHelper({ url: apiUrl, method: 'POST', headers: headers, body: inputJson, sessions: sessions, dispatch: dispatch })
        //    .then(resposnse => {
        //        if (resposnse !== null) {
        //            if (resposnse.MessageId > 0) {
        //                setIsSuccessMsg(true);
        //                setMsg("Message has been sent.")
        //                setLoading(false);
        //                history.push({ pathname: '/DashBoard' });
        //            } else {
        //                setIsSuccessMsg(false);
        //                setMsg(resposnse.Message);
        //                setLoading(false);
        //            }
        //        }
        //    });


    }

    useEffect(() => {
        lookupItems();

        //loadEmailList();

        //if (props.location.state.orgMsgId && parseInt(props.location.state.orgMsgId) > 0) {
        //    setHtmlContent(props.location.state.data.Content);
        //    setSubject(props.location.state.data.Subject);
        //    setToContactId(props.location.state.data.ToContactId);
        //    setSelectedName(props.location.state.data.ToContact);
        //    setOrgMessageId(props.location.state.orgMsgId);
        //    setSubject(props.location.state.subject);
        //    setFollowupTypId(props.location.state.followupId);
        //}

    }, []);

    const [editorState, setEditorState] = useState(MUIEditorState.createEmpty());

    const onChange = (newState) => {
        setEditorState(newState);
    };

    const fileAttachOnChange = async (event) => {
        if (!event.target.files)
            return;

        if (event.target.files.length == 0)
            return;

        var fileName = event.target.files[0].name;
        let convId = parseInt(conversationId);

        let attachmentData = new FormData();
        attachmentData.set('FileName', fileName);
        attachmentData.set('File', event.target.files[0]);
        attachmentData.set('AttachemntId', 0);
        attachmentData.set('ConversationId', convId ? convId : 0);


        let endPoint = process.env.REACT_APP_SAVE_MESSAGE_ATTACHAMENT;

        try {

            let response = await UploadUserFiles(endPoint, attachmentData, sessions, dispatch);
            if (response !== null && response !== undefined) {

                let apiErrors = response.data["Errors"];

                if (apiErrors !== undefined && apiErrors.length > 0) {
                    setErrorMsg(apiErrors[0].Message);
                }

                let data = response.data["data"];

                if (data !== undefined && data.length > 0) {

                    setConversationId(data[0].ConversationId);
                }
            }
        }
        catch (err) {
            setErrorMsg(ErrorMessages.UnknownError);
        }
        finally {

        }
    }

    //const save = (data) => {
    //    console.log(data);
    //};

    //const myTheme = createTheme({
    //    // Set up your custom MUI theme here
    //});

    return (
        <>
            <PostLoginMenu />
            <MainNavMenu />
            <section className="userprofsec">
                <div className="container-fluid">
                    <div className="row justify-content-center">
                        <div className="col-12" style={{ maxWidth: '100rem' }}>
                            <div className="row mb-4">
                                <EmailComposeMessageBreadcrumb />
                            </div>
                            <div className="row mb-4">
                                <div className="col-sm-12">
                                    <div className="p-3 mb-2 bg-white text-dark border rounded">
                                        <h6>Notes:</h6>
                                        <h6>* Please do not send time sensitive information</h6>
                                        <h6>* Only one attachment is allowed per email with maximum size of attachment not to exceed 10MB</h6>
                                    </div>
                                </div>

                                {/*<div className="col-sm-12">*/}
                                {/*    <AlertText Message={msg} isSuccess={isSuccessMsg} />*/}
                                {/*</div>*/}
                                <div className="col-sm-12">
                                    <label htmlFor="toEmail" className="form-label">To</label>
                                    <div className="mb-3 input-group">
                                        {/*<input placeholder="Email Address" id="toEmail" aria-label="Email Address" aria-describedby="basic-addon1" className="form-control" readOnly />*/}
                                        <input className="form-control" list="userList" id="toEmail" placeholder="Type to search Contact Person..." value={selectedName} onChange={onEmailChange} autoComplete="off" />
                                        <datalist id="userList">
                                            {
                                                emailList.map((e) => (
                                                    <option key={e.ContactId} value={e.Name}>{e.EmailAddress}</option>
                                                ))
                                            }
                                        </datalist>
                                        <button className="btn btn-outline-dark border-0" type="button" onClick={() => handleUserListClick(toContactId)} ><i className="fas fa-user"></i></button>
                                        {/*<span className="input-group-text" style={{ backgroundColor: 'transparent', border: 'none' }} id="basic-addon1"><a onClick={() => alert('User List...')}><i className="fas fa-user"></i></a></span>*/}
                                    </div>
                                </div>
                                <div className="col-sm-9">
                                    <label htmlFor="subject" className="form-label">Subject</label>
                                    <div className="mb-3 form-group">
                                        <input placeholder="Subject" id="subject" aria-label="Email Address" className="form-control" value={subject} onChange={(event) => setSubject(event.target.value)} maxLength='80' />
                                    </div>
                                </div>
                                <div className="col-sm-3">
                                    <label htmlFor="piority" className="form-label">Priority</label>
                                    <div className="mb-3 form-group">
                                        <select className="form-select" aria-label="Default select example" value={priorityTypId} onChange={(event) => setPriorityTypId(event.target.value)}>
                                            {
                                                priorityTypeList.map((e) => (
                                                    <option key={e.LookupId} value={e.LookupId}>{e.LookupDesc}</option>
                                                ))
                                            }
                                            {/*<option value="44">Normal</option>*/}
                                            {/*<option value="45">Medium</option>*/}
                                            {/*<option value="46">High</option>*/}
                                        </select>
                                    </div>
                                </div>

                                <div className="col-sm-9 p-b-4">
                                    <label htmlFor="formFile" className="form-label">Attachment</label>
                                    <input className="form-control" type="file" id="formFile" onChange={fileAttachOnChange} />
                                </div>

                                <div className="col-sm-3">
                                    <label htmlFor="piority" className="form-label">Response</label>
                                    <div className="mb-3 form-group">
                                        <select className="form-select" aria-label="Default select example" value={followupTypId} onChange={(event) => setFollowupTypId(event.target.value)}>
                                            {
                                                followupTypeList.map((e) => (
                                                    <option key={e.LookupId} value={e.LookupId}>{e.LookupDesc}</option>
                                                ))
                                            }
                                            {/*<option value="53">None</option>*/}
                                            {/*<option value="54">Follow-up</option>*/}
                                            {/*<option value="55">Complete</option>*/}
                                        </select>
                                    </div>
                                </div>
                            </div>
                            <div className="row mb-2">
                                <div className="col-sm-12">
                                    <label htmlFor="formFile" className="form-label">Enter email body content</label>
                                    <div className="mb-3 form-group">
                                        <div className='editor'>
                                            {/*<Editor*/}
                                            {/*    editorState={editorState}*/}
                                            {/*    toolbarClassName="demo-toolbar-custom"*/}
                                            {/*    wrapperClassName="editor-border editor-wrapper"*/}
                                            {/*    //onEditorStateChange={onEditorStateChange}*/}
                                            {/*    onEditorStateChange={handleEditorChange}*/}

                                            {/*    toolbar={{*/}
                                            {/*        inline: { inDropdown: true },*/}
                                            {/*        list: { inDropdown: true },*/}
                                            {/*        textAlign: { inDropdown: true },*/}
                                            {/*        link: { inDropdown: true },*/}
                                            {/*        history: { inDropdown: true }*/}
                                            {/*    }}*/}
                                            {/*    mention={{*/}
                                            {/*        separator: ' ',*/}
                                            {/*        trigger: '@',*/}
                                            {/*        suggestions: suggestionItems*/}
                                            {/*    }}*/}
                                            {/*    hashtag={{}}*/}
                                            {/*/>*/}
                                            {/*<ThemeProvider theme={myTheme}>*/}
                                            {/*    <MUIRichTextEditor*/}
                                            {/*        label="Type something here..."*/}
                                            {/*        onSave={save}*/}
                                            {/*        inlineToolbar={true}*/}
                                            {/*    />*/}
                                            {/*</ThemeProvider>*/}
                                            <MUIEditor editorState={editorState} onChange={onChange} />
                                        </div>
                                        {
                                            htmlContent ?
                                                <div>
                                                    {/*<span className="font-weight-bold">Message History</span>*/}
                                                    <p style={{ padding: '10px 0px 0px 0px' }}><strong>Message History</strong></p>
                                                    <div style={{ maxHeight: '250px', overflow: 'auto' }} className="editor-border"
                                                        dangerouslySetInnerHTML={{
                                                            __html: htmlContent
                                                        }}
                                                    />
                                                </div>
                                                :
                                                <div />
                                        }

                                    </div>
                                </div>
                                {/*<Container>*/}
                                {/*    <Typography variant="h6">Editor padrão Text to HTML </Typography>*/}
                                {/*    <TextEditor value={htmlText} onChange={setHTMLText} />*/}
                                {/*    <pre>{JSON.stringify(htmlText, null, 2)}</pre>*/}
                                {/*    <div dangerouslySetInnerHTML={{ __html: htmlText }}></div>*/}
                                {/*</Container>*/}
                                <div className="col-sm-12">
                                    <div className="d-grid gap-2 d-md-flex justify-content-md-end">
                                        <button className="btn btn-secondary me-md-2" type="button" onClick={handelCancelClick}>
                                            <svg id="Layer_1" dataname="Layer 1" xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 122.88 122.88">
                                                <title>cross</title>
                                                <path className="cls-1000" d="M6,6H6a20.53,20.53,0,0,1,29,0l26.5,26.49L87.93,6a20.54,20.54,0,0,1,29,0h0a20.53,20.53,0,0,1,0,29L90.41,61.44,116.9,87.93a20.54,20.54,0,0,1,0,29h0a20.54,20.54,0,0,1-29,0L61.44,90.41,35,116.9a20.54,20.54,0,0,1-29,0H6a20.54,20.54,0,0,1,0-29L32.47,61.44,6,34.94A20.53,20.53,0,0,1,6,6Z" />
                                            </svg>
                                            <span className="m-s-3">Cancel</span>
                                        </button>
                                        <button className="btn btn-primary" type="button" disabled={!(subject && (toContactId > 0))} onClick={handleSendMessage}><i className="fa fa-paper-plane px-1"></i>&nbsp; Send</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}

function EmailComposeMessageBreadcrumb() {
    return (
        <>
            <div className="d-flex align-items-center" style={{ "--bs-breadcrumb-divider": "''", backgroundColor: '#f6f6f6', paddingLeft: '16px' }} aria-label="breadcrumb">
                <ol className="breadcrumb align-middle">
                    <li className="breadcrumb-item active" aria-current="page">
                        <span className="display-17 aob-bold align-middle aob-black ms-2">Compose Message</span>
                    </li>
                </ol>
            </div>
        </>
    );
}

export default EmailComposeMessage;