import React, { useEffect, useState } from 'react';
import { TableControl } from './Controls/TableControl';
import LoaderControl from './Controls/LoaderControl';
import BreadCumControl from './Controls/BreadcumControl';
import { ApiHelper } from '../customFunctions/apiHelper';
import { useNavigate } from "react-router-dom";
import EnrollmentBanner from './EnrollmentBanner';
import Footer from './Footer';
import { NavMenu } from './NavMenu'
import PersonalProfileSection from './Profile/PersonalProfileSection';

const errorInfoCol = [
    {
        dataField: 'ErrorId',
        text: 'ID',
        hidden: true
    },
    {
        dataField: 'ErrorDt',
        text: 'Error Date',
        sort: true,
        hidden:false
    },
    {
        dataField: 'Logger',
        text: 'Logger',
        sort: true,
        hidden:false
    },
    {
        dataField: 'CallSite',
        text: 'Call Site',
        hidden: false
    },
    {
        dataField: 'ErrorMessage',
        text: 'Error Message',
        hidden:true
    }
]
const traceInfoCol = [
    {
        dataField: 'TraceId',
        text: 'ID',
        hidden: true
    },
    {
        dataField: 'TraceDt',
        text: 'Trace date',
        sort: true,
        hidden: false
    },
    {
        dataField: 'Logger',
        text: 'Logger',
        sort: true,
        hidden: false
    },
    {
        dataField: 'CallSite',
        text: 'CallSite',
        hidden: false
    },
    {
        dataField: 'TraceMessage',
        text: 'Trace Message',
        hidden: true
    }
]
const activityInfoCol = [
    {
        dataField: 'ActivityLogId',
        text: 'ID',
        hidden: true
    },
    {
        dataField: 'ActivityDt',
        text: 'Activity Date',
        sort: true,
        hidden: false
    },
    {
        dataField: 'Event',
        text: 'Event',
        sort: true,
        hidden: false
    },
    {
        dataField: 'UserNm',
        text: 'User Name',
        sort: true,
        hidden: false
    }
]
  
function ErrorInfoMain() {
    const [ typeInfo, setTypeInfo] = useState(1);
    const [ currPage, SetCurrPage ] = useState(1);
    const [ totalPage, SetTotalPage ] = useState(1);
    const [ errorInfoData, setErrorInfoData] = useState([]);
    const [srchValue, setSrchValue] = useState("");
    const [fromDate, setFromdate] = useState("");
    const [toDate, setTodate] = useState("");
    const [srchEvent, setSrchEvent] = useState("");
    const [srchUser, setSrchUser] = useState("");
    const [loading, setLoading] = useState(false);
    const [activityCtrl, setActivityCtrl] = useState([]);
    const [activityUser, setActivityUser] = useState([]);

    let history = useNavigate();
    
    useEffect(() => {
        
        setTypeInfo(history.location.state.infotype);
        setLoading(true);
        GetData();
        
        
    }, [typeInfo,currPage]);

    const GetData = () => {
        // call API
        let headers = {
            'Content-Type': 'application/json'
        };

        let selfromDate = (fromDate === "" ? null : fromDate);
        let seltoDate = (toDate === "" ? null : toDate);
        let selsrchEvent = parseInt((srchEvent === "" || srchEvent === "-1") ? null : srchEvent);
        let selsrchUser = parseInt((srchUser === "" || srchUser === "-1") ? null : srchUser);

        let bodyContent = { "typeInfo": typeInfo, "PageNumber": currPage, "searchStr": srchValue, "fromDate": selfromDate, "toDate": seltoDate, "srchEvent": selsrchEvent, "srchUser": selsrchUser};
        let apiurl = process.env.REACT_APP_SYSTEM_INFO;

        ApiHelper({ url: apiurl, method: 'POST', headers: headers, body: bodyContent })
            .then(resposnse => {
                if (resposnse !== null) {
                    //console.log(resposnse);
                    //console.log(resposnse["activityData"]);
                    setErrorInfoData(typeInfo === 3 ? resposnse["activityData"] : resposnse);
                    if (typeInfo == 3) {
                        setActivityCtrl(resposnse["activityCtrlData"]);
                        setActivityUser(resposnse["activityUserData"])
                    }
                   // console.log(errorInfoData);
                    let maxPage = typeInfo === 3 ? resposnse["activityData"].slice(0, 1) : resposnse.slice(0, 1);
                    maxPage.map((item) => SetTotalPage(item.TotalNoOfPages));
                    //console.log(totalPage);
                } else {
                    console.log(resposnse.Message)
                }
                setLoading(false);
            });
    }

    const PreviousClickHandle = () => {
        if (currPage > 1) {
            SetCurrPage(currPage - 1);
        }
    }

    const NextClickHandle = () => {
        if (currPage < totalPage) {
            SetCurrPage(currPage + 1);
        }
    }

    const FirstPageClickHandle = () => {
        SetCurrPage(1);
    }

    const LastPageClickHandle = () => {
        SetCurrPage(totalPage);
    }
    const searchChangeHandle = (e) => {
        setSrchValue(e.target.value);
    }
    const srchClickHandle = () => {
        SetCurrPage(1);
        SetTotalPage(1);
        GetData();
    }
    const gotoHomePath = (event) => {
        event.preventDefault();
        history.push({ pathname: '/DashBoard' });
    }

    if (loading)
        return <LoaderControl show={loading} />
    else
        return (
            <div>
                <EnrollmentBanner key={typeInfo === 1 ? "ErrorBan" : (typeInfo === 2 ? "TraceBan" : "ActivityBan")}/>
                <section className="p-t-1 da-body">
                    <div className="container p-0">
                        <div className="row g-0">
                            <NavMenu key={typeInfo === 1 ? "ErrorNav" : (typeInfo === 2 ? "TraceNav" : "ActivityNav")}/>
                        </div>
                        {/* <LoaderControl show={msgModalShow} />*/}
                        <div className="row g-0">
                            <LoaderControl show={loading} />
                            <BreadCumControl pathname={typeInfo === 1 ? "Error" : (typeInfo === 2 ? "Trace" : "Activity")} />
                        </div>
                        <div className="row p-t-4 justify-content-center">
                            <div className="row mb-3">
                                <div className="col-sm-6">
                                    <div className="input-group m-t-12">
                                        <input className="form-control search-txtbox" type="text" placeholder="Find Logs, Descriptions, Log Date" aria-label="Search" id="txtSearchCtrl" onChange={(e) => searchChangeHandle(e)} />
                                        <button type="button" className="btn btn-secondary" id="btnSrch" onClick={srchClickHandle}>
                                            <i className="fas fa-search"></i>
                                        </button>
                                    </div>                                    
                                </div>
                                <div className="col-sm-6 d-sm-none d-md-block">
                                </div>
                            </div>                            
                            <div className="row mb-3">
                                <div className="col">
                                    <label className="p-b-1">From</label>
                                    <input
                                        type="date"
                                        className="form-control"
                                        value={ fromDate}
                                        id="fromDate"
                                        id="fromDate"
                                        name="fromDate"
                                        onChange={(e) => (setFromdate(e.target.value))}
                                        placeholder={'From date'}
                                    />
                                </div>
                                <div className="col">
                                    <label className="p-b-1">To</label>
                                    <input
                                        type="date"
                                        className="form-control"
                                        value={ toDate }
                                        id="toDate"
                                        name="toDate"
                                        onChange={(e) => (setTodate(e.target.value))}
                                        placeholder={'To date'}
                                    />
                                </div>
                                {
                                    typeInfo === 3 && errorInfoData !== null ?
                                        <div className="col">
                                            <label className="p-b-1">Event</label>
                                            <select className="form-select" placeholder="Event" name="traceEvent" value={ srchEvent} onChange={(e) => (setSrchEvent(e.target.value))} >
                                                <option value="-1"></option>
                                                {activityCtrl.map((item) =>
                                                    (<option key={item.CdId} value={item.CdId}>{item.CdVlDesc}</option>)
                                                )}
                                            </select>
                                        </div>
                                    : ''
                                }
                                {
                                    typeInfo === 3 && errorInfoData !== null ?
                                        <div className="col">
                                            <label className="p-b-1">User</label>
                                            <select className="form-select" placeholder="User" name="traceUser" value={ srchUser} onChange={(e) => (setSrchUser(e.target.value))} >
                                                <option value="-1"></option>
                                                {activityUser.map((item) =>
                                                    (<option key={item.UserId} value={item.UserId}>{item.User}</option>)
                                                )}
                                            </select>
                                        </div>:''
                                }
                            </div>
                            <div className="row mb-3">
                                <div className="col">
                                    <div className="btn-group" role="group" aria-label="Button group with nested dropdown">
                                        <button type="button" title="Refresh" className="mail-toolbar-button" style={{ height: '32px' }} onClick={GetData}>
                                            <span className="" style={{ height: '32px' }}>
                                                <svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 119.4 122.88">
                                                    <title>reload</title>
                                                    <path d="M83.91,26.34a43.78,43.78,0,0,0-22.68-7,42,42,0,0,0-24.42,7,49.94,49.94,0,0,0-7.46,6.09,42.07,42.07,0,0,0-5.47,54.1A49,49,0,0,0,30,94a41.83,41.83,0,0,0,18.6,10.9,42.77,42.77,0,0,0,21.77.13,47.18,47.18,0,0,0,19.2-9.62,38,38,0,0,0,11.14-16,36.8,36.8,0,0,0,1.64-6.18,38.36,38.36,0,0,0,.61-6.69,8.24,8.24,0,1,1,16.47,0,55.24,55.24,0,0,1-.8,9.53A54.77,54.77,0,0,1,100.26,108a63.62,63.62,0,0,1-25.92,13.1,59.09,59.09,0,0,1-30.1-.25,58.45,58.45,0,0,1-26-15.17,65.94,65.94,0,0,1-8.1-9.86,58.56,58.56,0,0,1,7.54-75,65.68,65.68,0,0,1,9.92-8.09A58.38,58.38,0,0,1,61.55,2.88,60.51,60.51,0,0,1,94.05,13.3l-.47-4.11A8.25,8.25,0,1,1,110,7.32l2.64,22.77h0a8.24,8.24,0,0,1-6.73,9L82.53,43.31a8.23,8.23,0,1,1-2.9-16.21l4.28-.76Z" />
                                                </svg>
                                            </span>
                                            <span className="m-s-2 p-t-3 d-none d-lg-inline d-xl-inline">Refresh</span>
                                        </button>
                                    </div>
                                </div>
                            </div>
                            <div className="row mb-3">
                                {errorInfoData !== null ?
                                    <div>
                                        <div>
                                            <TableControl
                                                key={typeInfo === 1 ? "Error" : (typeInfo === 2 ? "Trace" : "Activity")} typeinfo={typeInfo}
                                                keyId="KeyId"
                                                infoData={errorInfoData}
                                                infoCol={typeInfo === 1 ? errorInfoCol : (typeInfo === 2 ? traceInfoCol : activityInfoCol)}
                                                title={typeInfo === 1 ? "Error" : (typeInfo === 2 ? "Trace" : "Activity")} typeinfo={typeInfo} />
                                        </div>
                                        <div className="mb-4" style={{
                                            display: "flex",
                                            justifyContent: "center",
                                            alignItems: "center"
                                        }}>
                                            <button type="button" id="btmPrevious" onClick={PreviousClickHandle} disabled={currPage > 1 ? false : true} title="Previous" className="btn btn-secondary">&lt;&lt; Previous</button>&nbsp;
                                            <button type="button" id="btmNext" onClick={NextClickHandle} disabled={currPage === totalPage ? true : false} title="Next" className="btn btn-secondary">&gt;&gt; Next</button>
                                            &nbsp;&nbsp;Page: {currPage} of {totalPage}&nbsp;&nbsp;
                                            <button type="button" id="btmFirstPage" onClick={FirstPageClickHandle} disabled={currPage > 1 ? false : true} title="Go To First Page" className="btn btn-secondary">|&lt;&lt; Go To First Page</button>&nbsp;
                                            <button type="button" id="btmLastPage" onClick={LastPageClickHandle} disabled={currPage === totalPage ? true : false} title="Go To Last Page" className="btn btn-secondary">&gt;&gt;| Go To Last Page</button>
                                        </div>
                                    </div>
                                    :
                                    ""
                                }
                            </div>
                        </div>
                    </div>
                






                    {/*<div className="row p-t-2 justify-content-center">*/}
                    {/*    <div className="row">*/}
                    {/*        <div className="col">*/}
                    {/*            <h5>Search</h5>*/}
                    {/*            <div>*/}
                    {/*                <input type="text" id="txtSearchCtrl" onChange={(e) => searchChangeHandle(e)} />*/}
                    {/*                <button type="button" id="btnSrch" onClick={ srchClickHandle}>Search</button>*/}
                    {/*            </div>*/}
                    {/*        </div>*/}
                        
                    {/*    </div>*/}
                        {/*<div className="row">*/}
                        {/*    <div className="col">*/}
                        {/*        {errorInfoData !== null ?*/}
                        {/*            <div>*/}
                        {/*                <div>*/}
                        {/*                    <TableControl*/}
                        {/*                        key={typeInfo === 1 ? "Error" : (typeInfo === 2 ? "Trace" : "Activity")} typeinfo={typeInfo}*/}
                        {/*                        keyId="KeyId"*/}
                        {/*                        infoData={errorInfoData}*/}
                        {/*                        infoCol={typeInfo === 1 ? errorInfoCol : (typeInfo === 2 ? traceInfoCol : activityInfoCol)}*/}
                        {/*                        title={typeInfo === 1 ? "Error" : (typeInfo === 2 ? "Trace" : "Activity")} typeinfo={typeInfo} />*/}
                        {/*                </div>*/}
                        {/*                <div style={{*/}
                        {/*                    display: "flex",*/}
                        {/*                    justifyContent: "center",*/}
                        {/*                    alignItems:"center"*/}
                        {/*                }}>*/}
                        {/*                    <button type="button" id="btmPrevious" onClick={PreviousClickHandle} disabled={currPage > 1 ? false : true} title="Previous" className="btn btn-secondary">&lt;&lt; Previous</button>&nbsp;*/}
                        {/*                    <button type="button" id="btmNext" onClick={NextClickHandle} disabled={currPage === totalPage ? true : false} title="Next" className="btn btn-secondary">&gt;&gt; Next</button>*/}
                        {/*                    &nbsp;&nbsp;Page: {currPage} of {totalPage}&nbsp;&nbsp;*/}
                        {/*                    <button type="button" id="btmFirstPage" onClick={FirstPageClickHandle} disabled={currPage > 1 ? false : true} title="Go To First Page" className="btn btn-secondary">|&lt;&lt; Go To First Page</button>&nbsp;*/}
                        {/*                    <button type="button" id="btmLastPage" onClick={LastPageClickHandle} disabled={currPage === totalPage ? true : false} title="Go To Last Page" className="btn btn-secondary">&gt;&gt;| Go To Last Page</button>*/}
                        {/*                </div>*/}
                        {/*            </div>*/}
                        {/*        :*/}
                        {/*        ""*/}
                        {/*        }*/}
                        {/*    </div>*/}
                        {/*</div>*/}
                    {/*</div>*/}
                </section>
                <Footer key={typeInfo === 1 ? "ErrorFoot" : (typeInfo === 2 ? "TraceFoot" : "ActivityFoot")}/>
            </div>
        );

}

export default ErrorInfoMain;