import React from 'react';

export function ApiHelper(requestConfig) {
    try {

        let headers = { ...requestConfig.headers, "User-Agent": "Account-Onboarding" };
        //headers = { ...headers, "Access-Control-Allow-Origin": "*" };
       
        let endPoint = '';

        if (requestConfig.ignoreBaseUrl) {
            endPoint = requestConfig.url;
        }
        else {
            headers = { ...headers, "x-api-key": process.env.REACT_APP_API_KEY };
            endPoint = process.env.REACT_APP_BASE_URL + requestConfig.url;
        }

       // console.log(endPoint);

        /*let endPoint = process.env.REACT_APP_API_STAGE + requestConfig.url;*/
        try {
            return fetch(endPoint, {

                method: requestConfig.method ? requestConfig.method : 'GET',
                headers: headers,
                //headers: requestConfig.headers ? requestConfig.headers : {},
                body: requestConfig.body ? JSON.stringify(requestConfig.body) : requestConfig.form ? requestConfig.form : null
            })
                .then((res) => res.json()
                    .then((json) => {
                        return json;
                    }))
        } catch (ex) {
            console.log('Error');
            console.log(ex.Message);
            return ex;
        }
    } catch (err) {
        console.log('Error');
        console.log(err.Message);
        return err;
    }    
}