import React from 'react';
import EnrollmentBanner from './EnrollmentBanner'
import { NavMenu } from './NavMenu'
import Footer from './Footer';
import DashBoardMessages from './DashBoard/DashBoardMessages'

const EmployeeDashboard = () => {

    return (
        <>
            <EnrollmentBanner />
            <NavMenu />         
            <DashBoardMessages />                       
            <Footer />
        </>
    );
}

export default EmployeeDashboard;