import React from 'react';
import moment from "moment";
import { Logout } from '../global/Logout';
import { LoadUserFiles, CallAPIUsingAxios } from './APIUtils';
import { AOBSystemProcess, ContentType } from '../common/AOBEnum';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { APICall } from '../global/Api';
import { LoginStatus, EnrollmentStatus, EnrollmentStatusText } from '../common/AOBEnum';

export function withRouter(Component) {
    function ComponentWithRouterProp(props) {
        let location = useLocation();
        let navigate = useNavigate();
        let params = useParams();
        return (
            <Component
                {...props}
                location={location}
                params={params}
                navigate={navigate}
            />
        );
    }

    return ComponentWithRouterProp;
}

export function GetCountryOfUser() {

    var moment = require('moment-timezone');

    const { countries, zones } = require("moment-timezone/data/meta/latest.json");
    let timeZone = moment.tz.guess(Boolean);

    timeZone = moment.tz.guess(Boolean);
    if (timeZone == 'Asia/Calcutta')
        timeZone = 'Asia/Kolkata';

    let country = countries[zones[timeZone].countries[0]].name;

    return country;
}

export function GetBrowserName() {
    var platform = require('platform');
    let bname = platform.name;
    return bname;
}

export function GetOSPlatform() {
    var platform = require('platform');
    let osname = platform.os.family + ' ' + platform.os.version;
    return osname;
}

export function GetUserCurrentDate() {
    var currentdate = new Date();
    return currentdate.toString();
}
export function CheckSession(session) {
    let userID = 0;

    session.map(
        (sn) => {
            if (sn.key === 'LoginUserId') {
                userID = parseInt(sn.value);
            }
        });

    if (userID == 0) {
        Logout(0, 'session_notfound');
    }

    return 1;
}

export function CheckUserSession(session) {
    let userID = 0;

    session.map(
        (sn) => {
            if (sn.key === 'LoginUserId') {
                userID = parseInt(sn.value);
            }
        });

    if (userID == 0) {
        return false;
    }

    return true;
}
export function CanApprove(session) {

    let accessControlArray = GetAccessControlArray(session);

    if (accessControlArray.length == 0)
        return false;

    const found = accessControlArray.filter(element => element == AOBSystemProcess.ApproveApplication);

    if (found !== undefined && found.length > 0)
        return true;

    return false;
}
export function CanSubmitForEnrollment(session) {

    let accessControlArray = GetAccessControlArray(session);

    if (accessControlArray.length == 0)
        return false;

    const found = accessControlArray.filter(element => element == AOBSystemProcess.PublishApplication);

    if (found !== undefined && found.length > 0)
        return true;

    return false;
}
function GetAccessControlArray(session) {

    let accessControl = '';
    let accessControlArray = [];
    session.map(
        (sn) => {
            if (sn.key === 'AccessControl') {
                accessControl = sn.value;
            }
        });

    accessControlArray = accessControl.split("|");

    return accessControlArray;
}
export function CreateFileDownloadLink(cntrlNm, fileNm, filesize, atchmntId, DownloadAttachment) {

    var dot = fileNm.lastIndexOf(".");
    var extension = dot >= 0 ? fileNm.substr(dot, fileNm.length) : '';

    let iconFile = '<i class="fa fa-file-image"></i>';
    if (extension === '.pdf')
        iconFile = '<i class="fa fa-file-pdf"></i>'

    const link = document.createElement('a');
    //link.href = url;
    link.name = 'href_' + cntrlNm;
    link.id = 'href_' + cntrlNm;
    link.title = 'Download ' + fileNm;
    link.setAttribute('controlname', cntrlNm);
    link.setAttribute('filename', fileNm);
    link.setAttribute('data-id', atchmntId);
    link.innerHTML = iconFile + '&nbsp;' + (fileNm.replace(extension, '').length > 12 ? fileNm.substr(0, 11) + '...' + extension : fileNm) + (filesize && filesize !== undefined ? '(' + filesize + ')' : '');
    link.style = "width:20px;"
    link.title = 'Download ' + fileNm;
    link.addEventListener("click", e => {
        DownloadAttachment(e);
    });

    const div = document.createElement('div');
    div.id = 'div' + cntrlNm;
    div.name = 'div' + cntrlNm;

    //div.appendChild(donloadLink);
    //div.appendChild(deleteLink);

    var parentElemnt = document.getElementById(cntrlNm).parentElement;
    parentElemnt.appendChild(div);

    return;
}

export function CreateDeleteFileLink(cntrlNm, fileNm, DeleteAttachment) {

    const link = document.createElement('a');
    link.name = 'href2_' + cntrlNm;
    link.id = 'href2_' + cntrlNm;
    link.setAttribute('controlname', cntrlNm);
    link.setAttribute('filename', fileNm);
    link.innerHTML = '&nbsp; <i class="fa fa-trash" controlname="' + cntrlNm + '" filename="' + fileNm + '"></i>';
    link.title = 'Delete ' + fileNm;
    link.addEventListener("click", e => {
        DeleteAttachment(e);
    });

    return link;
}

export function GetFileSizeStr(fileSize) {

    let str = '';

    if (fileSize < 1024)
        return fileSize + ' Bytes';

    return Math.ceil(fileSize / 1024) + ' KB';

    return str;
}

export async function LoadProfilePic(userID, loggedInUser, sessionId) {
    const apiUrl = new URL(
        process.env.REACT_APP_GET_PROFILE_IMAGE,
        process.env.REACT_APP_BASE_URL,
    );

    apiUrl.searchParams.set('cntctId', `${parseInt(userID)}`);

    try {

        if (userID == null)
            return null;

        let response = await APICall({ url: apiUrl.href, method: 'GET', contentType: 'application/json', responseType: 'blob' }, loggedInUser, sessionId);

        if (response) {
            if (response.type == 'image/jpeg') {
                return response;
            }
            return null;
        }
    } catch (ex) {
        console.log(ex);
        throw ex;
    }

    return null;
}

export async function DownloadFileHelper(data, filename, contentType) {

    if (contentType === undefined || contentType === '') {
        var dot = filename.lastIndexOf(".");
        var extension = dot >= 0 ? filename.substr(dot, filename.length) : '';

        if (extension === '.pdf')
            contentType = ContentType.pdf
        else if (extension === '.jpeg')
            contentType = ContentType.jpeg
        else if (extension === '.jpg')
            contentType = ContentType.jpg
        else if (extension === '.png')
            contentType = ContentType.png
        else
            contentType = 'application/json';
    }

    var binaryData = [];
    binaryData.push(data);

    let blob = new Blob(binaryData, { type: contentType });
    // create file link in browser's memory
    const href = URL.createObjectURL(blob);

    // create "a" HTML element with href to file & click
    const link = document.createElement('a');
    link.href = href;
    link.setAttribute('download', filename.replace(/[/\\?%*:|"<>]/g, '-')); //or any other extension
    document.body.appendChild(link);
    link.click();

    // clean up "a" element & remove ObjectURL
    document.body.removeChild(link);
    URL.revokeObjectURL(href);
}

export const goToTop = () => {
    window.scrollTo({
        top: 0,
        behavior: 'smooth',
    });
};

export const goToTopSystemParameter = () => {
    window.scrollTo({
        top: 220,
        behavior: 'smooth',
    });
};

export function RedirectToHome(isProspectUser, accessControl, navigate, userId, sessionId) {

    if (isProspectUser === true) {
        navigate('/ProspectDashboard', { state: { userId: userId, sessionId: sessionId }, replace: true });
        return;
    }

    let accessControlArray = accessControl.split("|");

    var found = accessControlArray.find(function (element) {
        return element == AOBSystemProcess.ApplicationMaintenance
            || element == AOBSystemProcess.ViewApplication || element == AOBSystemProcess.ExportApplication
            || element == AOBSystemProcess.ApproveApplication || element == AOBSystemProcess.PublishApplication
            || element == AOBSystemProcess.DocuSignDocUpload || element == AOBSystemProcess.AllProcess;
    });

    if (found !== undefined && found.length > 0)
        navigate('/ApplicationDashboard', { state: { userId: userId, sessionId: sessionId }, replace: true });
    else
        navigate('/EmailMessages', { replace: true }, { state: { userId: userId, sessionId: sessionId } });
}

export const GetLoginSatusStr = (statusId) => {

    switch (statusId) {
        case LoginStatus.Failed:
            return "Failed";
        case LoginStatus.Locked:
            return "Locked";
        case LoginStatus.InvalidCredential:
            return "Invalid Credential";
        case LoginStatus.InactiveUser:
            return "Inactive User";
        case LoginStatus.AccountNotActivated:
            return "Account Not Activated";
        case LoginStatus.AllreadyLoggedIn:
            return "Allready Logged In";
        case LoginStatus.Success:
            return "Success";
        case LoginStatus.PasswordExpired:
            return "Password Expired";
        case LoginStatus.PasswordExpiredWarning:
            return "Password Expired Warning";
        default:
            return "";
    }
}

export const GetAppSatusStr = (enrmntStatusId) => {

    switch (enrmntStatusId) {
        case EnrollmentStatus.SubmitApplication:
            return EnrollmentStatusText.SubmitApplication;
        case EnrollmentStatus.CancelApplication:
            return EnrollmentStatusText.CancelApplication;
        case EnrollmentStatus.UnderReview:
            return EnrollmentStatusText.UnderReview;
        case EnrollmentStatus.RejectApplication:
            return EnrollmentStatusText.RejectApplication;
        case EnrollmentStatus.RequestCancellation:
            return EnrollmentStatusText.RequestCancellation;
        case EnrollmentStatus.ReviewPass:
            return EnrollmentStatusText.ReviewPass;
        case EnrollmentStatus.ReviewFail:
            return EnrollmentStatusText.ReviewFail;
        case EnrollmentStatus.KYCAMLInitiated:
            return EnrollmentStatusText.KYCAMLInitiated;
        case EnrollmentStatus.KYCAMLPassed:
            return EnrollmentStatusText.KYCAMLPassed;
        case EnrollmentStatus.KYCAMLFailed:
            return EnrollmentStatusText.KYCAMLFailed;
        case EnrollmentStatus.SubmittedEnrollment:
            return EnrollmentStatusText.SubmittedEnrollment;
        case EnrollmentStatus.DocuSignRequired:
            return EnrollmentStatusText.DocuSignRequired;
        case EnrollmentStatus.DocuSignCompleted:
            return EnrollmentStatusText.DocuSignCompleted;
        case EnrollmentStatus.AccountActivated:
            return EnrollmentStatusText.AccountActivated;
        case EnrollmentStatus.InComplete:
            return EnrollmentStatusText.InComplete;
        case EnrollmentStatus.Complete:
            return EnrollmentStatusText.Complete;
        default:
            return "";
    }
}
