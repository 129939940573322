import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Dialog from '@mui/material/Dialog';
import { Typography } from '@mui/material';

export function ExceptionDialog(props) {
    const { onClose, value: valueProp, open, ...other } = props;

    const handleCancel = () => {
        onClose();
    };

    return (
        <Dialog
            sx={{ '& .MuiDialog-paper': { width: '100%', maxHeight: 600 } }}
            maxWidth="md"
            open={open}
            {...other}
        >
            <DialogTitle className="ms-2 me-2">
                <Typography variant="body2" color="text.primary" sx={{ fontWeight: 400 }}>{props.alertheader}</Typography>
            </DialogTitle>
            <DialogContent dividers className="ms-2 me-2">
                <Box sx={{ pb: 1 }}>
                    <Typography variant="subtitle1" color="text.primary" sx={{ textAlign: 'left' }}><strong>Exception</strong></Typography>
                    <Typography variant="subtitle1" color="text.primary" sx={{ textAlign: 'left' }}>{props.bmsgline1}</Typography>
                    <Typography variant="subtitle1" color="text.primary" sx={{ textAlign: 'left' }}>{props.bmsgline2}</Typography>
                    <Typography variant="subtitle1" color="text.primary" sx={{ textAlign: 'left' }}>{props.bmsgline3}</Typography>
                    <Typography variant="subtitle1" color="text.primary" sx={{ textAlign: 'left' }}>{props.bmsgline4}</Typography>
                    <Typography variant="subtitle1" color="text.primary" sx={{ textAlign: 'left' }}>{props.bmsgline5}</Typography>
                </Box>
            </DialogContent>
            <DialogActions sx={{ pt: 2, pb: 2, pr: 2 }}>
                <Button autoFocus variant="contained" onClick={handleCancel} size="small" sx={{ pt: 1, pb: 1, borderRadius: '20px', padding: '0.25rem 2.5rem', textTransform: 'none'  }}>
                    OK
                </Button>
            </DialogActions>
        </Dialog>
    );
}

ExceptionDialog.propTypes = {
    onClose: PropTypes.func.isRequired,
    open: PropTypes.bool.isRequired,
    nvalue: PropTypes.number,
    svalue: PropTypes.string,
    alertheader: PropTypes.string.isRequired,
    bmsgline1: PropTypes.string,
    bmsgline2: PropTypes.string,
    bmsgline3: PropTypes.string,
    bmsgline4: PropTypes.string,
    bmsgline5: PropTypes.string,
};