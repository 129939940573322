import React, { useCallback, useEffect, useMemo, useRef, useState, } from 'react';
import { useNavigate } from "react-router-dom";
import MaterialReactTable, {
    MRT_ShowHideColumnsButton,
    MRT_ToggleGlobalFilterButton,
    MRT_ToggleFiltersButton,
} from 'material-react-table';
import PostLoginMenu from './PostLoginMenu'
import { MainNavMenu } from './MainNavMenu'
import { Divider, Stack, Typography, Tooltip, IconButton, AddBoxIcon, DeleteIcon, Box, Button, Menu, MenuItem, FormControlLabel, Checkbox, Paper } from '@mui/material';
import PriorityHighSharpIcon from '@mui/icons-material/PriorityHighSharp';
import { InputBase } from '@mui/material';
import {
    QueryClient,
    QueryClientProvider,
    useInfiniteQuery,
} from '@tanstack/react-query';
import { Virtualizer } from '@tanstack/react-virtual';
import { useSelector, useDispatch } from 'react-redux';
import { CallAPI } from '../global/APIUtils';
import { ErrorMessages } from '../common/ErrorMessages';
import { Trash, TrashFill, Star, StarFill, ArchiveFill, ChevronDown, ThreeDotsVertical, Search, SkipStartFill, CircleFill, Link } from 'react-bootstrap-icons';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Container from '@mui/material/Container';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import EnrollmentHeader from './EnrollmentHeader';
import { DrawerAppBar } from './AppNavbar';
import pwdimg from '../assets/images/487483.jpg';
import Image from 'material-ui-image'
import OverlaySpinner from './Controls/OverlaySpinner';
import { Grid } from '@mui/material';
//import PageFooter from './Footer';
import InboxOutlinedIcon from '@mui/icons-material/InboxOutlined';
import { grey, red } from '@mui/material/colors';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import InsertDriveFileOutlinedIcon from '@mui/icons-material/InsertDriveFileOutlined';
import SendOutlinedIcon from '@mui/icons-material/SendOutlined';
import StarOutlineOutlinedIcon from '@mui/icons-material/StarOutlineOutlined';
import PriorityHighOutlinedIcon from '@mui/icons-material/PriorityHighOutlined';
import MarkAsUnreadOutlinedIcon from '@mui/icons-material/MarkAsUnreadOutlined';
import RestoreFromTrashOutlinedIcon from '@mui/icons-material/RestoreFromTrashOutlined';
import RefreshOutlinedIcon from '@mui/icons-material/RefreshOutlined';
import DraftsOutlinedIcon from '@mui/icons-material/DraftsOutlined';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import MoreVertOutlinedIcon from '@mui/icons-material/MoreVertOutlined';
import ListItemIcon from '@mui/material/ListItemIcon';
import CheckIcon from '@mui/icons-material/Check';
import { ApplicationSortAction } from '../common/AOBEnum';

const EmailMessages = (props) => {

    let navigate = useNavigate();
    const [expanded, setExpanded] = useState(true);
    const [isDataLoading, setIsDataLoading] = useState(false);

    const data = [
        {
            name: {
                firstName: 'John',
                lastName: 'Doe',
            },
            address: '261 Erdman Ford',
            city: 'East Daphne',
            state: 'Kentucky',
        },
        {
            name: {
                firstName: 'Jane',
                lastName: 'Doe',
            },
            address: '769 Dominic Grove',
            city: 'Columbus',
            state: 'Ohio',
        },
        {
            name: {
                firstName: 'Joe',
                lastName: 'Doe',
            },
            address: '566 Brakus Inlet',
            city: 'South Linda',
            state: 'West Virginia',
        },
        {
            name: {
                firstName: 'Kevin',
                lastName: 'Vandy',
            },
            address: '722 Emie Stream',
            city: 'Lincoln',
            state: 'Nebraska',
        },
        {
            name: {
                firstName: 'Joshua',
                lastName: 'Rolluffs',
            },
            address: '32188 Larkin Turnpike',
            city: 'Charleston',
            state: 'South Carolina',
        },
    ];

    const columns = useMemo(
        () => [
            {
                accessorKey: 'name.firstName', //access nested data with dot notation
                header: 'First Name',
            },
            {
                accessorKey: 'name.lastName',
                header: 'Last Name',
            },
            {
                accessorKey: 'address', //normal accessorKey
                header: 'Address',
            },
            {
                accessorKey: 'city',
                header: 'City',
            },
            {
                accessorKey: 'state',
                header: 'State',
            },
        ],
        [],
    );

    const [isLoading, setIsLoading] = useState(false);
    //const [selectedEmailType, setSelectedEmailType] = useState(MessageType.inbox);
    //const [selectedEmailTypeLabel, setSelectedEmailTypeLabel] = useState('Inbox');

    const loadEmails = (mailType) => {
        setIsLoading(true);
        //setAlertMsg('');

        //let headers = {
        //    'Content-Type': 'application/json'
        //};


        //let apiUrl = process.env.REACT_APP_GET_MESSAGE_LIST + 'cntctId/' + props.loginusrId + '/messageType/' + mailType;

        //ApiHelper({ url: apiUrl, method: 'GET', headers: headers, sessions: sessions, dispatch: dispatch })
        //    .then(resposnse => {
        //        if (resposnse !== null) {
        //            setMailData(resposnse);
        //            setLoading(false);
        //        }
        //        else {
        //            setLoading(false);
        //        }
        //    });
    }

    const setEmailType = (msgType) => {
        //setSelectedEmailType(msgType);

        //if (msgType === MessageType.inbox)
        //    setSelectedEmailTypeLabel('Inbox');
        //else if (msgType === MessageType.archives)
        //    setSelectedEmailTypeLabel('Archives');
        //else if (msgType === MessageType.sent)
        //    setSelectedEmailTypeLabel('Sent');
        //else if (msgType === MessageType.drafts)
        //    setSelectedEmailTypeLabel('Draft');
        //else if (msgType === MessageType.starred)
        //    setSelectedEmailTypeLabel('Starred');
        //else if (msgType === MessageType.important)
        //    setSelectedEmailTypeLabel('Important');
        //else if (msgType === MessageType.allEmails)
        //    setSelectedEmailTypeLabel('All');

    }

    const composeMessage = () => {
        navigate('/EmailComposeMessage')
    }

    useEffect(() => {
        //loadEmails(MessageType.inbox);
    }, []);

    const handleAccordianChange = () => {
        if (expanded) {
            setExpanded(false);
        }
        else {
            setExpanded(true);
        }
    };

    return (
        <React.Fragment>
            <Container maxWidth="xl" sx={{}}>
                <OverlaySpinner disappear={isDataLoading} />
                <PostLoginMenu />
                <Paper className=""
                    sx={{
                        position: 'relative',
                        backgroundColor: '#fff',
                        color: '#fff',
                        backgroundSize: 'cover',
                        backgroundRepeat: 'no-repeat',
                        backgroundPosition: 'center',
                        pb: 12
                    }}
                >
                    <Grid item>
                        <Box sx={{
                            pr: { xs: 3, md: 2 },
                            pl: { xs: 3, md: 2 }
                        }}>
                            <Accordion spacing={0} elevation={0} expanded={expanded} onChange={handleAccordianChange} sx={{ p: 0, m: 0, width: '100%' }}>
                                <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header" sx={{ mr: 2 }}>
                                </AccordionSummary>
                                <AccordionDetails sx={{ pr: 5, pl: 3 }}>
                                    <EnrollmentHeader />
                                    <DrawerAppBar />
                                </AccordionDetails>
                                <Box sx={{ pr: 5, pl: 3 }}>
                                    <Grid item xs container direction="row" spacing={2} sx={{ pt: 0, pb: 1 }}>
                                        <Grid item md={7} sx={{ pr: 10, }} justifyContent="center">
                                            <Box sx={{ pl: 0, pr: 2 }}>
                                                <Typography variant="h4" color="#e53935" sx={{ textTransform: 'none', pb: 2 }} >Under construction</Typography>
                                                <EmailMessagesBreadcrumb />
                                                <Typography sx={{ mt: 3, mb: 2 }} variant="body1" color="text.secondary">
                                                    Send, receive, and manage your emails. Contact us for any query.
                                                    If you need send us an email, and we will get back to you as soon as possible!
                                                </Typography>
                                            </Box>
                                            <Box sx={{ pl: 0, mt: 4 }}>
                                                <Button variant="outlined" size="small" sx={{ padding: '0.5rem 2rem', border: 2, borderColor: '#424242' }} onClick={() => composeMessage()}>
                                                    <Typography variant="body1" color="#212121" sx={{ textTransform: 'none' }} >New Message</Typography>
                                                </Button>
                                            </Box>
                                        </Grid>
                                        <Grid item md={5}>
                                            <Grid container>
                                                <Grid item md={10} sx={{ pl: 2 }} >
                                                    <Image src={pwdimg} aspectRatio={1.9} sx={{ opacity: 0.75 }} />
                                                    {/*<Box display="flex" justifyContent="center" alignItems="center" sx={{opacity: 0.8, pl: 6, width: '100%',}}>*/}
                                                    {/*    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32" width="200" height="200">*/}
                                                    {/*    <g id="_27-Email-Message" dataname="27-Email-Message">*/}
                                                    {/*        <path d="M31.59,11.19,27,7.83V5a1,1,0,0,0-1-1H21.78L16.59.19a1,1,0,0,0-1.18,0L10.22,4H6A1,1,0,0,0,5,5V7.83L.41,11.19A1,1,0,0,0,0,12V29a3,3,0,0,0,3,3H29a3,3,0,0,0,3-3V12A1,1,0,0,0,31.59,11.19ZM27,10.31l2.2,1.61L27,13.23ZM16,2.24,18.4,4H13.6ZM7,6H25v8.43l-9,5.4-9-5.4ZM5,10.31v2.93L2.8,11.92ZM2,28.59V13.77l9.26,5.56ZM3.41,30,13,20.38l2.46,1.47a1,1,0,0,0,1,0L19,20.38,28.59,30ZM30,28.59l-9.26-9.26L30,13.77Z" />*/}
                                                    {/*        <rect x="10" y="9" width="12" height="2" />*/}
                                                    {/*        <rect x="12" y="13" width="8" height="2" />*/}
                                                    {/*    </g>*/}
                                                    {/*    </svg>*/}
                                                    {/*</Box>*/}
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Box>
                            </Accordion>
                        </Box>
                    </Grid>
                    <Box sx={{ pl: { xs: 3, md: 6 }, pr: { xs: 3, md: 6 }, pt: 4, pb: 4 }}>
                        <Divider variant="div" sx={{ background: '#757575', }} />
                    </Box>
                    <Grid item xs={12}>
                        <Grid item xs container direction="row" spacing={4} sx={{}}>
                            <Grid item xs={12} md={3} sx={{}}>
                                <Box sx={{ ml: 8, border: '0px solid #CCCCCC', pb: 3, pt: 3, backgroundColor: '#f7f7fa' }} >
                                    <Button sx={{ justifyContent: "flex-start", pl: 3 }} fullWidth={true}>
                                        <Box sx={{ width: '100%', pb: 1 }} component="span" display="flex"
                                            justifyContent="space-between"
                                            alignItems="center">
                                            <Stack direction="row" alignItems="center">
                                                {/*<InboxOutlinedIcon width="16px" height="16px" sx={{ color: grey[600], fontSize: 28 }} />*/}
                                                <Typography variant="body2" sx={{ textTransform: 'none', ml: 1 }} color="text.secondary">Inbox</Typography>
                                            </Stack>
                                            {/*<Stack direction="row" alignItems="center">*/}
                                            {/*    <ArrowForwardIosIcon sx={{ color: grey[800], fontSize: 28, pt: 1 }} />*/}
                                            {/*</Stack>*/}
                                        </Box>
                                    </Button>
                                    <Divider variant="fullWidth" component="div" sx={{ background: '#eeeeee', mt: 0, mb: 1 }} />
                                    <Button sx={{ justifyContent: "flex-start", pl: 3 }} fullWidth={true}>
                                        <Box sx={{ width: '100%', pb: 1 }} component="span" display="flex"
                                            justifyContent="space-between"
                                            alignItems="center">
                                            <Stack direction="row" alignItems="center">
                                                {/*<InsertDriveFileOutlinedIcon width="16px" height="16px" sx={{ color: grey[700], fontSize: 28 }} />*/}
                                                <Typography variant="body2" sx={{ textTransform: 'none', ml: 1 }} color="text.secondary">Drafts</Typography>
                                            </Stack>
                                            {/*<Stack direction="row" alignItems="center">*/}
                                            {/*    <ArrowForwardIosIcon sx={{ color: grey[800], fontSize: 28, pt: 1 }} />*/}
                                            {/*</Stack>*/}
                                        </Box>
                                    </Button>
                                    <Divider variant="fullWidth" component="div" sx={{ background: '#eeeeee', mt: 0, mb: 1 }} />
                                    <Button sx={{ justifyContent: "flex-start", pl: 3 }} fullWidth={true}>
                                        <Box sx={{ width: '100%', pb: 1 }} component="span" display="flex"
                                            justifyContent="space-between"
                                            alignItems="center">
                                            <Stack direction="row" alignItems="center">
                                                {/*<SendOutlinedIcon width="16px" height="16px" sx={{ color: grey[700], fontSize: 28 }} />*/}
                                                <Typography variant="body2" sx={{ textTransform: 'none', ml: 1 }} color="text.secondary">Sent</Typography>
                                            </Stack>
                                            {/*<Stack direction="row" alignItems="center">*/}
                                            {/*    <ArrowForwardIosIcon sx={{ color: grey[800], fontSize: 28, pt: 1 }} />*/}
                                            {/*</Stack>*/}
                                        </Box>
                                    </Button>
                                    <Divider variant="fullWidth" component="div" sx={{ background: '#eeeeee', mt: 0, mb: 1 }} />
                                    <Button sx={{ justifyContent: "flex-start", pl: 3 }} fullWidth={true}>
                                        <Box sx={{ width: '100%', pb: 1 }} component="span" display="flex"
                                            justifyContent="space-between"
                                            alignItems="center">
                                            <Stack direction="row" alignItems="center">
                                                {/*<StarOutlineOutlinedIcon width="16px" height="16px" sx={{ color: grey[700], fontSize: 28 }} />*/}
                                                <Typography variant="body2" sx={{ textTransform: 'none', ml: 1 }} color="text.secondary">Starred</Typography>
                                            </Stack>
                                            {/*<Stack direction="row" alignItems="center">*/}
                                            {/*    <ArrowForwardIosIcon sx={{ color: grey[800], fontSize: 28, pt: 1 }} />*/}
                                            {/*</Stack>*/}
                                        </Box>
                                    </Button>
                                    <Divider variant="fullWidth" component="div" sx={{ background: '#eeeeee', mt: 0, mb: 1 }} />
                                    <Button sx={{ justifyContent: "flex-start", pl: 3 }} fullWidth={true}>
                                        <Box sx={{ width: '100%', pb: 1 }} component="span" display="flex"
                                            justifyContent="space-between"
                                            alignItems="center">
                                            <Stack direction="row" alignItems="center">
                                                {/*<PriorityHighOutlinedIcon width="16px" height="16px" sx={{ color: red[700], fontSize: 28 }} />*/}
                                                <Typography variant="body2" sx={{ textTransform: 'none', ml: 1 }} color="text.secondary">Important</Typography>
                                            </Stack>
                                            {/*<Stack direction="row" alignItems="center">*/}
                                            {/*    <ArrowForwardIosIcon sx={{ color: grey[800], fontSize: 28, pt: 1 }} />*/}
                                            {/*</Stack>*/}
                                        </Box>
                                    </Button>
                                    <Divider variant="fullWidth" component="div" sx={{ background: '#eeeeee', mt: 0, mb: 1 }} />
                                    <Button sx={{ justifyContent: "flex-start", pl: 3 }} fullWidth={true}>
                                        <Box sx={{ width: '100%', pb: 1 }} component="span" display="flex"
                                            justifyContent="space-between"
                                            alignItems="center">
                                            <Stack direction="row" alignItems="center">
                                                {/*<MarkAsUnreadOutlinedIcon width="16px" height="16px" sx={{ color: grey[700], fontSize: 28 }} />*/}
                                                <Typography variant="body2" sx={{ textTransform: 'none', ml: 1 }} color="text.secondary">All Mail</Typography>
                                            </Stack>
                                            {/*<Stack direction="row" alignItems="center">*/}
                                            {/*    <ArrowForwardIosIcon sx={{ color: grey[800], fontSize: 28, pt: 1 }} />*/}
                                            {/*</Stack>*/}
                                        </Box>
                                    </Button>
                                    <Divider variant="fullWidth" component="div" sx={{ background: '#eeeeee', mt: 0, mb: 1 }} />
                                    <Button sx={{ justifyContent: "flex-start", pl: 3 }} fullWidth={true}>
                                        <Box sx={{ width: '100%' }} component="span" display="flex"
                                            justifyContent="space-between"
                                            alignItems="center">
                                            <Stack direction="row" alignItems="center">
                                                {/*<RestoreFromTrashOutlinedIcon width="16px" height="16px" sx={{ color: grey[700], fontSize: 28 }} />*/}
                                                <Typography variant="body2" sx={{ textTransform: 'none', ml: 1 }} color="text.secondary">Trash</Typography>
                                            </Stack>
                                            {/*<Stack direction="row" alignItems="center">*/}
                                            {/*    <ArrowForwardIosIcon sx={{ color: grey[800], fontSize: 28, pt: 1 }} />*/}
                                            {/*</Stack>*/}
                                        </Box>
                                    </Button>
                                    {/*<a*/}
                                    {/*    className="list-group-item list-group-item-action py-2"*/}
                                    {/*    onClick={() => setEmailType(MessageType.inbox)}*/}
                                    {/*>*/}
                                    {/*    <span className="me-2">*/}
                                    {/*        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24"><path d="M4.801 3.57A1.75 1.75 0 0 1 6.414 2.5h11.174c.702 0 1.337.42 1.611 1.067l3.741 8.828c.04.092.06.192.06.293v7.562A1.75 1.75 0 0 1 21.25 22H2.75A1.75 1.75 0 0 1 1 20.25v-7.5c0-.1.02-.199.059-.291L4.8 3.571ZM6.414 4a.25.25 0 0 0-.23.153L2.88 12H8a.75.75 0 0 1 .648.372L10.18 15h3.638l1.533-2.628a.75.75 0 0 1 .64-.372l5.13-.051-3.304-7.797a.25.25 0 0 0-.23-.152ZM21.5 13.445l-5.067.05-1.535 2.633a.75.75 0 0 1-.648.372h-4.5a.75.75 0 0 1-.648-.372L7.57 13.5H2.5v6.75c0 .138.112.25.25.25h18.5a.25.25 0 0 0 .25-.25Z"></path>*/}
                                    {/*        </svg>*/}
                                    {/*    </span>*/}
                                    {/*    <span className="display-20">Inbox</span>*/}
                                    {/*    <span className="me-4" style={{ float: 'right' }}>*/}
                                    {/*        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="20" height="20"><path fill="#333333" d="M8.72 18.78a.75.75 0 010-1.06L14.44 12 8.72 6.28a.751.751 0 01.018-1.042.751.751 0 011.042-.018l6.25 6.25a.75.75 0 010 1.06l-6.25 6.25a.75.75 0 01-1.06 0z"></path></svg>*/}
                                    {/*    </span>*/}
                                    {/*</a>*/}
                                </Box>
                            </Grid>
                            <Grid item xs={12} md={9} sx={{}} >
                                <EmailConversationProvider />
                            </Grid>
                        </Grid>
                    </Grid>
                </Paper>
                {/*<Footer />*/}
            </Container>
            {/*<PostLoginMenu />*/}
            {/*<MainNavMenu />*/}
            {/*<section className="userprofsec">*/}
            {/*    <div className="container-fluid">*/}
            {/*        <div className="row justify-content-center">*/}
            {/*            <div className="col-12" style={{ maxWidth: '100rem' }}>*/}
            {/*                <div className="row mb-4">*/}
            {/*                    <EmailMessagesBreadcrumb />*/}
            {/*                </div>*/}
            {/*                <div className="row mb-2">*/}
            {/*                    <nav id="sidebarMenu" className="col-md-3 col-lg-3 d-md-block sidebar collapse" >*/}
            {/*                        <div className="position-sticky w-75 mx-auto">*/}
            {/*                            <div className="col-md-12 col-lg-10">*/}
            {/*                                <button type="button" className="align-items-center btn btn-primary display-10" style={{ paddingLeft: '40px', paddingRight: '40px' }} onClick={() => composeMessage()}>*/}
            {/*                                    <strong>COMPOSE</strong>*/}
            {/*                                </button>*/}
            {/*                            </div>*/}
            {/*                            <div className="list-group list-group-flush mt-1 ">*/}
            {/*                                <a*/}
            {/*                                    className="list-group-item list-group-item-action py-2"*/}
            {/*                                    onClick={() => setEmailType(MessageType.inbox)}*/}
            {/*                                >*/}
            {/*                                    <span className="me-2">*/}
            {/*                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24"><path d="M4.801 3.57A1.75 1.75 0 0 1 6.414 2.5h11.174c.702 0 1.337.42 1.611 1.067l3.741 8.828c.04.092.06.192.06.293v7.562A1.75 1.75 0 0 1 21.25 22H2.75A1.75 1.75 0 0 1 1 20.25v-7.5c0-.1.02-.199.059-.291L4.8 3.571ZM6.414 4a.25.25 0 0 0-.23.153L2.88 12H8a.75.75 0 0 1 .648.372L10.18 15h3.638l1.533-2.628a.75.75 0 0 1 .64-.372l5.13-.051-3.304-7.797a.25.25 0 0 0-.23-.152ZM21.5 13.445l-5.067.05-1.535 2.633a.75.75 0 0 1-.648.372h-4.5a.75.75 0 0 1-.648-.372L7.57 13.5H2.5v6.75c0 .138.112.25.25.25h18.5a.25.25 0 0 0 .25-.25Z"></path>*/}
            {/*                                        </svg>*/}
            {/*                                    </span>*/}
            {/*                                    <span className="display-20">Inbox</span>*/}
            {/*                                    <span className="me-4" style={{ float: 'right' }}>*/}
            {/*                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="20" height="20"><path fill="#333333" d="M8.72 18.78a.75.75 0 010-1.06L14.44 12 8.72 6.28a.751.751 0 01.018-1.042.751.751 0 011.042-.018l6.25 6.25a.75.75 0 010 1.06l-6.25 6.25a.75.75 0 01-1.06 0z"></path></svg>*/}
            {/*                                    </span>*/}
            {/*                                </a>*/}
            {/*                                <a className="list-group-item list-group-item-action py-2" onClick={() => setEmailType(MessageType.drafts)}>*/}
            {/*                                    <span className="me-2">*/}
            {/*                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 2 26 26" width="24" height="25"><path d="M3 3a2 2 0 0 1 2-2h9.982a2 2 0 0 1 1.414.586l4.018 4.018A2 2 0 0 1 21 7.018V21a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2Zm2-.5a.5.5 0 0 0-.5.5v18a.5.5 0 0 0 .5.5h14a.5.5 0 0 0 .5-.5V8.5h-4a2 2 0 0 1-2-2v-4Zm10 0v4a.5.5 0 0 0 .5.5h4a.5.5 0 0 0-.146-.336l-4.018-4.018A.5.5 0 0 0 15 2.5Z"></path>*/}
            {/*                                        </svg>*/}
            {/*                                    </span>*/}
            {/*                                    <span className="display-20">Drafts</span>*/}
            {/*                                    <span className="me-4" style={{ float: 'right' }}>*/}
            {/*                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="20" height="20" strokeWidth="2"><path fill="#333333" d="M8.72 18.78a.75.75 0 010-1.06L14.44 12 8.72 6.28a.751.751 0 01.018-1.042.751.751 0 011.042-.018l6.25 6.25a.75.75 0 010 1.06l-6.25 6.25a.75.75 0 01-1.06 0z"></path></svg>*/}
            {/*                                    </span>*/}
            {/*                                </a>*/}
            {/*                                <a className="list-group-item list-group-item-action py-2" onClick={() => setEmailType(MessageType.sent)}>*/}
            {/*                                    <span className="me-2">*/}
            {/*                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 2 26 26" width="22" height="22"><path d="M1.513 1.96a1.374 1.374 0 0 1 1.499-.21l19.335 9.215a1.147 1.147 0 0 1 0 2.07L3.012 22.25a1.374 1.374 0 0 1-1.947-1.46L2.49 12 1.065 3.21a1.375 1.375 0 0 1 .448-1.25Zm2.375 10.79-1.304 8.042L21.031 12 2.584 3.208l1.304 8.042h7.362a.75.75 0 0 1 0 1.5Z"></path>*/}
            {/*                                        </svg>*/}
            {/*                                    </span>*/}
            {/*                                    <span className="display-20">Sent</span>*/}
            {/*                                    <span className="me-4" style={{ float: 'right' }}>*/}
            {/*                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="20" height="20" strokeWidth="2"><path fill="#333333" d="M8.72 18.78a.75.75 0 010-1.06L14.44 12 8.72 6.28a.751.751 0 01.018-1.042.751.751 0 011.042-.018l6.25 6.25a.75.75 0 010 1.06l-6.25 6.25a.75.75 0 01-1.06 0z"></path></svg>*/}
            {/*                                    </span>*/}
            {/*                                </a>*/}
            {/*                                <a className="list-group-item list-group-item-action py-2" onClick={() => setEmailType(MessageType.starred)}>*/}
            {/*                                    <span className="me-2">*/}
            {/*                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="22" height="22"><path d="M12 .25a.75.75 0 0 1 .673.418l3.058 6.197 6.839.994a.75.75 0 0 1 .415 1.279l-4.948 4.823 1.168 6.811a.751.751 0 0 1-1.088.791L12 18.347l-6.117 3.216a.75.75 0 0 1-1.088-.79l1.168-6.812-4.948-4.823a.75.75 0 0 1 .416-1.28l6.838-.993L11.328.668A.75.75 0 0 1 12 .25Zm0 2.445L9.44 7.882a.75.75 0 0 1-.565.41l-5.725.832 4.143 4.038a.748.748 0 0 1 .215.664l-.978 5.702 5.121-2.692a.75.75 0 0 1 .698 0l5.12 2.692-.977-5.702a.748.748 0 0 1 .215-.664l4.143-4.038-5.725-.831a.75.75 0 0 1-.565-.41L12 2.694Z"></path></svg>*/}
            {/*                                    </span>*/}
            {/*                                    <span className="display-20">Starred</span>*/}
            {/*                                    <span className="me-4" style={{ float: 'right' }}>*/}
            {/*                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="20" height="20" strokeWidth="2"><path fill="#333333" d="M8.72 18.78a.75.75 0 010-1.06L14.44 12 8.72 6.28a.751.751 0 01.018-1.042.751.751 0 011.042-.018l6.25 6.25a.75.75 0 010 1.06l-6.25 6.25a.75.75 0 01-1.06 0z"></path></svg>*/}
            {/*                                    </span>*/}
            {/*                                </a>*/}
            {/*                                <a className="list-group-item list-group-item-action py-2" onClick={() => setEmailType(MessageType.important)}>*/}
            {/*                                    <span className="me-2">*/}
            {/*                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 2 26 26" width="24" height="24"><path d="M7.886 1.553a1.75 1.75 0 0 1 2.869.604l.633 1.629a5.667 5.667 0 0 0 3.725 3.395l3.959 1.131a1.749 1.749 0 0 1 .757 2.92L16.06 15l5.594 5.595a.749.749 0 0 1-.326 1.275.749.749 0 0 1-.734-.215L15 16.061l-3.768 3.768a1.749 1.749 0 0 1-2.92-.757l-1.131-3.96a5.665 5.665 0 0 0-3.395-3.724l-1.63-.633a1.75 1.75 0 0 1-.603-2.869Zm6.589 12.912 4.293-4.294a.25.25 0 0 0-.108-.417L14.7 8.623A7.165 7.165 0 0 1 9.99 4.33L9.357 2.7a.25.25 0 0 0-.41-.086L2.614 8.946a.25.25 0 0 0 .086.41l1.63.634a7.167 7.167 0 0 1 4.294 4.71l1.13 3.96a.25.25 0 0 0 .417.108l4.294-4.293Z"></path>*/}
            {/*                                        </svg>*/}
            {/*                                    </span>*/}
            {/*                                    <span className="display-20">Important</span>*/}
            {/*                                    <span className="me-4" style={{ float: 'right' }}>*/}
            {/*                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="20" height="20" strokeWidth="2"><path fill="#333333" d="M8.72 18.78a.75.75 0 010-1.06L14.44 12 8.72 6.28a.751.751 0 01.018-1.042.751.751 0 011.042-.018l6.25 6.25a.75.75 0 010 1.06l-6.25 6.25a.75.75 0 01-1.06 0z"></path></svg>*/}
            {/*                                    </span>*/}
            {/*                                </a>*/}
            {/*                                <a className="list-group-item list-group-item-action py-2" onClick={() => setEmailType(MessageType.allEmails)}>*/}
            {/*                                    <span className="me-2">*/}
            {/*                                        <svg viewBox="0 10 122.88 95.02" width="28" height="28" style={{enableBackground: 'new 0 0 122.88 95.02'}}><g><path d="M6.09,0h93.94c1.67,0,3.2,0.69,4.3,1.79c1.1,1.1,1.79,2.63,1.79,4.3v64.56c0,1.26-0.39,2.44-1.05,3.41 c-0.12,0.31-0.31,0.61-0.57,0.86c-0.17,0.16-0.36,0.3-0.57,0.4c-1.06,0.88-2.42,1.42-3.89,1.42H6.09c-1.67,0-3.2-0.68-4.3-1.79 C0.69,73.84,0,72.32,0,70.64V6.09c0-1.68,0.68-3.2,1.79-4.3C2.89,0.68,4.41,0,6.09,0L6.09,0L6.09,0z M116.79,95.02H18.43 c-1.67,0-3.2-0.69-4.3-1.79c-1.1-1.1-1.79-2.63-1.79-4.3v-6.12h4.62v7.54h101.36V18.54h-6.16v-4.67h4.62c1.67,0,3.2,0.68,4.3,1.79 c1.1,1.1,1.79,2.62,1.79,4.3v68.98c0,1.68-0.68,3.2-1.79,4.3C119.99,94.34,118.47,95.02,116.79,95.02L116.79,95.02L116.79,95.02z M4.67,68.08l32.92-33L4.67,8.24V68.08L4.67,68.08L4.67,68.08z M41.22,38.03L7.27,72.06h91.28L66.12,38.04l-10.69,9.11l0,0 c-0.84,0.72-2.09,0.76-2.98,0.04L41.22,38.03L41.22,38.03L41.22,38.03z M69.67,35.02l31.78,33.33V7.94L69.67,35.02L69.67,35.02 L69.67,35.02z M7.66,4.67l46.22,37.68L98.11,4.67H7.66L7.66,4.67L7.66,4.67z" /></g></svg>*/}
            {/*                                    </span>*/}
            {/*                                    <span className="display-20">All Mail</span>*/}
            {/*                                    <span className="me-4" style={{ float: 'right' }}>*/}
            {/*                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="20" height="20" strokeWidth="2"><path fill="#333333" d="M8.72 18.78a.75.75 0 010-1.06L14.44 12 8.72 6.28a.751.751 0 01.018-1.042.751.751 0 011.042-.018l6.25 6.25a.75.75 0 010 1.06l-6.25 6.25a.75.75 0 01-1.06 0z"></path></svg>*/}
            {/*                                    </span>*/}
            {/*                                </a>*/}
            {/*                                <a className="list-group-item list-group-item-action py-2" onClick={() => setEmailType(MessageType.deletedItems)}>*/}
            {/*                                    <span className="me-2">*/}
            {/*                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 28 28" width="22" height="22"><path d="M16 1.75V3h5.25a.75.75 0 0 1 0 1.5H2.75a.75.75 0 0 1 0-1.5H8V1.75C8 .784 8.784 0 9.75 0h4.5C15.216 0 16 .784 16 1.75Zm-6.5 0V3h5V1.75a.25.25 0 0 0-.25-.25h-4.5a.25.25 0 0 0-.25.25ZM4.997 6.178a.75.75 0 1 0-1.493.144L4.916 20.92a1.75 1.75 0 0 0 1.742 1.58h10.684a1.75 1.75 0 0 0 1.742-1.581l1.413-14.597a.75.75 0 0 0-1.494-.144l-1.412 14.596a.25.25 0 0 1-.249.226H6.658a.25.25 0 0 1-.249-.226L4.997 6.178Z"></path><path d="M9.206 7.501a.75.75 0 0 1 .793.705l.5 8.5A.75.75 0 1 1 9 16.794l-.5-8.5a.75.75 0 0 1 .705-.793Zm6.293.793A.75.75 0 1 0 14 8.206l-.5 8.5a.75.75 0 0 0 1.498.088l.5-8.5Z"></path>*/}
            {/*                                        </svg>*/}
            {/*                                    </span>*/}
            {/*                                    <span className="display-20">Deleted Items</span>*/}
            {/*                                    <span className="me-4" style={{ float: 'right' }}>*/}
            {/*                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="20" height="20" strokeWidth="2"><path fill="#333333" d="M8.72 18.78a.75.75 0 010-1.06L14.44 12 8.72 6.28a.751.751 0 01.018-1.042.751.751 0 011.042-.018l6.25 6.25a.75.75 0 010 1.06l-6.25 6.25a.75.75 0 01-1.06 0z"></path></svg>*/}
            {/*                                    </span>*/}
            {/*                                </a>*/}
            {/*                                <a className="list-group-item list-group-item-action py-2" onClick={() => setEmailType(MessageType.archives)}>*/}
            {/*                                    <span className="me-2">*/}
            {/*                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 28 28" width="20" height="20"><path d="M2.75 2h18.5c.966 0 1.75.784 1.75 1.75v3.5A1.75 1.75 0 0 1 21.25 9H2.75A1.75 1.75 0 0 1 1 7.25v-3.5C1 2.784 1.784 2 2.75 2Zm18.5 1.5H2.75a.25.25 0 0 0-.25.25v3.5c0 .138.112.25.25.25h18.5a.25.25 0 0 0 .25-.25v-3.5a.25.25 0 0 0-.25-.25ZM2.75 10a.75.75 0 0 1 .75.75v9.5c0 .138.112.25.25.25h16.5a.25.25 0 0 0 .25-.25v-9.5a.75.75 0 0 1 1.5 0v9.5A1.75 1.75 0 0 1 20.25 22H3.75A1.75 1.75 0 0 1 2 20.25v-9.5a.75.75 0 0 1 .75-.75Z"></path><path d="M9.75 11.5a.75.75 0 0 0 0 1.5h4.5a.75.75 0 0 0 0-1.5h-4.5Z"></path>*/}
            {/*                                        </svg>*/}
            {/*                                    </span>*/}
            {/*                                    <span className="display-20">Archive</span>*/}
            {/*                                    <span className="me-4" style={{ float: 'right' }}>*/}
            {/*                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="20" height="20" strokeWidth="2"><path fill="#333333" d="M8.72 18.78a.75.75 0 010-1.06L14.44 12 8.72 6.28a.751.751 0 01.018-1.042.751.751 0 011.042-.018l6.25 6.25a.75.75 0 010 1.06l-6.25 6.25a.75.75 0 01-1.06 0z"></path></svg>*/}
            {/*                                    </span>*/}
            {/*                                </a>*/}
            {/*                            </div>*/}
            {/*                        </div>*/}
            {/*                    </nav>*/}
            {/*                    <main className="col-md-9 ms-sm-auto col-lg-9">*/}
            {/*                        <div className="mt-3">*/}
            {/*                            <EmailConversationProvider emailType={selectedEmailType} />*/}
            {/*                        </div>*/}
            {/*                        */}{/*{*/}
            {/*                        */}{/*    (selectedSection === sections.personalInfo) ?*/}
            {/*                        */}{/*        <PersonalInfoSection cntctid={cntCtId} disablerole={true} isadmin={false} />*/}
            {/*                        */}{/*        :*/}
            {/*                        */}{/*        (selectedSection === sections.changePassword) ?*/}
            {/*                        */}{/*            <ProfileChangePassword cntctid={cntCtId} emailadrs={emailAdrs} />*/}
            {/*                        */}{/*            :*/}
            {/*                        */}{/*            <div><h5>Invalid operation!!</h5></div>*/}

            {/*                        */}{/*}*/}
            {/*                        <div className="mb-3"></div>*/}
            {/*                    </main>*/}
            {/*                </div>*/}
            {/*            </div>*/}
            {/*        </div>*/}
            {/*    </div>*/}
            {/*</section>*/}

        </React.Fragment>
    );
}

//function EmailMessagesBreadcrumb() {
//    return (
//        <>
//            <div className="d-flex align-items-center" style={{ "--bs-breadcrumb-divider": "''", backgroundColor: '#f6f6f6', paddingLeft: '16px' }} aria-label="breadcrumb">
//                <ol className="breadcrumb align-middle">
//                    <li className="breadcrumb-item active" aria-current="page">
//                        <span className="display-17 aob-bold align-middle aob-black ms-2">Email Messages</span>
//                    </li>
//                </ol>
//            </div>
//        </>
//    );
//}

const EmailConversation = () => {

    const dispatch = useDispatch();
    let sessions = useSelector((state) => state.sessionMgmnt);
    const [dateColNm, setDateColNm] = useState('Recieved');
    const [msgTabNm, setMsgTabNm] = useState('Inbox');
    const [sortMnuAnchorEl, setSortMnuAnchorEl] = useState(null);
    const [actionAnchorEl, setActionAnchorEl] = useState(null);

    const sortMenuAction = useRef(MessagaSortType.Newest);
    const currentFolder = useRef(FolderType.Inbox);

    const tickSrtDtNewDisp = useRef('block');
    const tickSrtDtOldDisp = useRef('none');
    const tickSrtUnreadDisp = useRef('none');
    const tickSrtStarredDisp = useRef('none');
    const tickSrtSenderDisp = useRef('none');
    const tickSrtSubjectDisp = useRef('none');
    const tickSrtAttachmentDisp = useRef('none');

    //const [emailTypeId, setEmailTypeId] = useState(emailType.emailType);

    const open = Boolean(sortMnuAnchorEl);
    const openAction = Boolean(actionAnchorEl);

    const handleClick = (event) => {
        setSortMnuAnchorEl(event.currentTarget);
    };
    const handleActionClick = (event) => {
        setActionAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setSortMnuAnchorEl(null);
    };
    const handleActionClose = () => {
        setActionAnchorEl(null);
    };

    const readUnreadEmails = async (readUnreadJson) => {
        let baseUrl = process.env.REACT_APP_BASE_URL;
        const apiUrl = new URL(
            process.env.REACT_APP_READ_UNREAD_MESSAGE,
            baseUrl
        );
        let response = await CallAPI({ url: apiUrl.href, method: 'POST', headers: {}, body: readUnreadJson }, sessions, dispatch);

        if (response !== null && response !== undefined) {
            setActionAnchorEl(null);
            refetch();
        }
        else {
            throw Error(ErrorMessages.UnknownError);
        }
    }

    const readUnreadSelectedEmail = (Id, isRead) => {

        //const readUnreadInputJson = {
        //    Id: Id,
        //    IsRead: isRead,
        //    IsSender: !(emailType.emailType === MessageType.inbox)
        //}

        //const inputJson = [];
        //inputJson.push(readUnreadInputJson);

        //readUnreadEmails(inputJson);

    }
    const readUnreadMultipleEmails = (selectedRows, isRead, tableObj) => {
        tableObj.toggleAllRowsSelected();
        //const inputJson = [];
        //if (selectedRows.length > 0) {
        //    selectedRows.forEach((value, key) => {
        //        let readUnreadInputJson = {
        //            //Id: parseInt(value.id),
        //            Id: value.original.Id,
        //            IsRead: isRead,
        //            IsSender: !(emailType.emailType === MessageType.inbox)
        //        }
        //        inputJson.push(readUnreadInputJson);
        //    });
        //    if (inputJson.length > 0) {
        //        readUnreadEmails(inputJson);
        //    }
        //}


    }

    const starAddRemoveSelectedEmail = (Id, isStarred) => {

        //const addRemoveStarInputJson = {
        //    Id: Id,
        //    Starred: isStarred,
        //    IsSender: !(emailType.emailType === MessageType.inbox)
        //}

        //const inputJson = [];
        //inputJson.push(addRemoveStarInputJson);

        //starAddRemove(inputJson);

    }

    const starAddRemoveMultipleEmails = (selectedRows, isStarred) => {
        //const inputJson = [];
        //if (selectedRows.length > 0) {
        //    selectedRows.forEach((value, key) => {
        //        let addRemoveStarInputJson = {
        //            //Id: parseInt(value.id),
        //            Id: value.original.Id,
        //            Starred: isStarred,
        //            IsSender: !(emailType.emailType === MessageType.inbox)
        //        }
        //        inputJson.push(addRemoveStarInputJson);
        //    });
        //    if (inputJson.length > 0) {
        //        starAddRemove(inputJson);
        //    }
        //}
    }

    const starAddRemove = async (starAddRemoveJson) => {
        let baseUrl = process.env.REACT_APP_BASE_URL;

        const apiUrl = new URL(
            process.env.REACT_APP_STAR_ADD_REMOVE_MESSAGE,
            baseUrl
        );
        let response = await CallAPI({ url: apiUrl.href, method: 'POST', headers: {}, body: starAddRemoveJson }, sessions, dispatch);

        if (response !== null && response !== undefined) {
            setActionAnchorEl(null);
            refetch();
        }
        else {
            throw Error(ErrorMessages.UnknownError);
        }
    }


    const importantAddRemoveMultipleEmails = (selectedRows, isImportant, isSender) => {
        const inputJson = [];
        if (selectedRows.length > 0) {
            selectedRows.forEach((value, key) => {
                let addRemoveStarInputJson = {
                    //Id: parseInt(value.id),
                    Id: value.original.Id,
                    IsImportant: isImportant,
                    IsSender: isSender
                }
                inputJson.push(addRemoveStarInputJson);
            });
            if (inputJson.length > 0) {
                importantAddRemove(inputJson);
            }
        }
    }

    const importantAddRemove = async (importantAddRemoveson) => {
        let baseUrl = process.env.REACT_APP_BASE_URL;

        const apiUrl = new URL(
            process.env.REACT_APP_IMPORTANT_IND_ADD_REMOVE_MESSAGE,
            baseUrl
        );

        let response = await CallAPI({ url: apiUrl.href, method: 'POST', headers: {}, body: importantAddRemoveson }, sessions, dispatch);

        if (response !== null && response !== undefined) {
            setActionAnchorEl(null);
            refetch();
        }
        else {
            throw Error(ErrorMessages.UnknownError);
        }
    }

    const deleteEmails = async (selectedEmailJson) => {
        let baseUrl = process.env.REACT_APP_BASE_URL;

        const apiUrl = new URL(
            process.env.REACT_APP_DELETE_MESSAGE,
            baseUrl
        );
        let response = await CallAPI({ url: apiUrl.href, method: 'POST', headers: {}, body: selectedEmailJson }, sessions, dispatch);

        if (response !== null && response !== undefined) {
            setActionAnchorEl(null);
            refetch();
        }
        else {
            throw Error(ErrorMessages.UnknownError);
        }
    }

    const deleteSelectedEmail = async (id, isSender) => {
        let userprompt = false;
        userprompt = window.confirm("Do you want to delete selected Email?");
        if (userprompt) {
            const emailInputJson = {
                Id: id,
                IsSender: isSender
            }

            const inputJson = [];
            inputJson.push(emailInputJson);

            deleteEmails(inputJson);
        }

    }

    const deleteMultipleEmails = async (selectedRows, isSender) => {
        let userprompt = false;
        userprompt = window.confirm("Do you want to delete selected Email(s)?");
        if (userprompt) {
            const inputJson = [];
            selectedRows.forEach((value, key) => {
                let delEmailInputJson = {
                    Id: value.original.Id,
                    IsSender: isSender
                }
                inputJson.push(delEmailInputJson);
            });

            deleteEmails(inputJson);
        }

    }

    const columns = [
        //{
        //    accessorKey: 'Id',
        //    id: 'Id',
        //    //Header: ({ column }) => (
        //    //    <span>&nbsp;</span>
        //    //),
        //    //Cell: ({ cell }) => (
        //    //    <span>&nbsp;</span>
        //    //),
        //    renderColumnActionsMenuItems: ({ closeMenu, table }) => {
        //        return [
        //            <MenuItem
        //                onClick={() => {
        //                    // do something
        //                    closeMenu();
        //                }}
        //            >
        //                Custom Menu Item 1
        //            </MenuItem>,
        //            <MenuItem
        //                onClick={() => {
        //                    // do something
        //                    closeMenu();
        //                }}
        //            >
        //                Custom Menu Item 2
        //            </MenuItem>,
        //        ];
        //    },
        //    maxSize: 12,
        //},
        {
            accessorKey: 'IsRead',
            header: '',
            Cell: ({ cell }) => (
                <IconButton title={(cell.row.getValue("IsRead") === true) ? "Mark as read" : "Mark as unread"} onClick={() => readUnreadSelectedEmail(cell.row.original.Id, !cell.row.getValue("IsRead"))}>
                    <CircleFill width="8px" height="8px" color={(cell.row.getValue("IsRead") === true) ? "#0f69ff" : "#ddd"} />
                </IconButton>
                //<StarFill color="#F48400" />
            ),
            enableColumnFilter: false,
            maxSize: 16,
            minSize: 16,
            muiTableHeadCellProps: {
                align: 'center',
            },
            muiTableBodyCellProps: {
                align: 'center',
            },
        },
        {
            accessorKey: 'FromContact',
            header: '',
            size: 100,
            Cell: ({ cell, column }) => (
                <Tooltip title={cell.getValue()} arrow placement="bottom-start">
                    <div style={{ textOverflow: 'ellipsis', overflow: 'hidden' }}>
                        {cell.getValue()}
                    </div>
                </Tooltip>
            ),
        },
        {
            accessorKey: 'Starred',
            header: '',
            Cell: ({ cell }) => (
                <IconButton title={(cell.row.getValue("Starred") === true) ? "Remove star" : "Add star"} onClick={() => starAddRemoveSelectedEmail(cell.row.original.Id, !cell.row.getValue("Starred"))}>
                    <StarFill width="12px" height="12px" color={(cell.row.getValue("Starred") === true) ? "#F48400" : "#ddd"} />
                </IconButton>
                //<StarFill color="#F48400" />
            ),
            enableColumnFilter: false,
            maxSize: 24,
            minSize: 24,
            muiTableHeadCellProps: {
                align: 'center',
            },
            muiTableBodyCellProps: {
                align: 'center',
            },
        },
        //{
        //    accessorKey: 'IsImportant',
        //    header: '',
        //    Cell: ({ cell }) => (
        //       <PriorityHighSharpIcon width="12px" height="12px" style={{ display: (cell.row.getValue("IsImportant") === true) ? '' : 'none' }} htmlColor={(cell.row.getValue("IsImportant") === true) ? "#a6003b" : "#ffffff00"} title={(cell.row.getValue("IsImportant") === true) ? "Remove important mark" : "Mark as important"} />
        //    ),
        //    enableColumnFilter: false,
        //    maxSize: 8,
        //    minSize: 8,
        //    muiTableHeadCellProps: {
        //        align: 'center',
        //    },
        //    muiTableBodyCellProps: {
        //        align: 'center',
        //    },
        //},
        //{
        //    accessorKey: 'Subject',
        //    header: 'Subject',
        //    size: 300            
        //},
        {
            accessorKey: 'Subject',
            header: '',
            muiTableBodyCellCopyButtonProps: ({ cell }) => ({
                className: 'column-option',
                id: '${cell.getValue <string> ()}-column-option',
            }),
            Cell: ({ renderedCellValue, row }) =>
                row.original?.IsImportant ? (
                    <Tooltip title={row.original?.Subject} arrow placement="bottom-start">
                        <div style={{ textOverflow: 'ellipsis', overflow: 'hidden' }}>
                            <strong style={{ color: "#a6003b" }}>
                                !
                            </strong>
                            &nbsp;
                            {row.original?.Subject}
                        </div>
                    </Tooltip>
                ) : (
                    <Tooltip title={row.original?.Subject} arrow placement="bottom-start">
                        <div style={{ textOverflow: 'ellipsis', overflow: 'hidden' }}>
                            {row.original?.Subject}
                        </div>
                    </Tooltip >
                ),
            //Cell: ({ cell, column }) => (
            //    <Tooltip title={cell.getValue()} arrow placement="bottom-start">
            //        <div>
            //            {cell.getValue()}
            //        </div>
            //    </Tooltip>
            //),
            size: 250
        },
        {
            accessorKey: 'HasAttachment',
            header: '',
            Cell: ({ cell }) => (
                <Link width="20px" height="20px" style={{ display: (cell.row.getValue("HasAttachment") === true) ? '' : 'none' }} />
            ),
            enableColumnFilter: false,
            maxSize: 24,
            minSize: 24,
            muiTableHeadCellProps: {
                align: 'center',
            },
            muiTableBodyCellProps: {
                align: 'center',
            },
        },
        {
            accessorKey: 'ReceiveDate',
            header: '',
            size: 100,
            Cell: ({ cell, column }) => (
                <Tooltip title={cell.getValue()} arrow placement="bottom-start">
                    <div style={{ textOverflow: 'ellipsis', overflow: 'hidden' }}>
                        {cell.getValue()}
                    </div>
                </Tooltip>
            ),
        },
    ];

    const fetchSize = 25;

    const tableContainerRef = useRef(null); //we can get access to the underlying TableContainer element and react to its scroll events
    const virtualizerInstanceRef = useRef < Virtualizer > null; //we can get access to the underlying Virtualizer instance and call its scrollToIndex method

    const [columnFilters, setColumnFilters] = useState([]);
    const [globalFilter, setGlobalFilter] = useState();
    const [sorting, setSorting] = useState([]);

    const { data, fetchNextPage, isError, isFetching, isLoading, refetch } =
        useInfiniteQuery(
            ['table-data', columnFilters, globalFilter, sorting],
            async ({ pageParam = 0 }) => {
                //let endPoint = '';

                //if (emailType.emailType === MessageType.inbox)
                //    endPoint = process.env.REACT_APP_GET_INBOX_MESSAGES;
                //else if (emailType.emailType === MessageType.archives)
                //    endPoint = process.env.REACT_APP_GET_ARCHIVES_MESSAGES;
                //else if (emailType.emailType === MessageType.sent)
                //    endPoint = process.env.REACT_APP_GET_SENT_MESSAGES;
                //else if (emailType.emailType === MessageType.drafts)
                //    endPoint = process.env.REACT_APP_GET_DRAFT_MESSAGES;
                //else if (emailType.emailType === MessageType.starred)
                //    endPoint = process.env.REACT_APP_GET_STARRED_MESSAGES;
                //else if (emailType.emailType === MessageType.important)
                //    endPoint = process.env.REACT_APP_GET_IMPORTANT_MESSAGES;
                //else if (emailType.emailType === MessageType.allEmails)
                //    endPoint = process.env.REACT_APP_GET_ALL_MESSAGES;

                let colFilter = '';
                columnFilters.map(
                    (fltr) => {
                        if (fltr.id === 'FromContact') {
                            colFilter = colFilter + colFilter !== '' ? ' And ' : ' ' + ' IsNull(E.Author, \'\'\'\') Like \'%' + fltr.value + '%\' ';
                        }
                        if (fltr.id === 'Subject') {
                            colFilter = colFilter + (colFilter !== '' ? ' And ' : ' ') + ' IsNull(E.Subject, \'\'\'\') Like \'%' + fltr.value + '%\' ';
                        }
                        if (fltr.id === 'ReceiveDate') {
                            colFilter = colFilter + (colFilter !== '' ? ' And ' : ' ') + ' CONVERT(Varchar, E.SendDt, 100) Like \'%' + fltr.value + '%\' ';
                        }
                    });
                let baseUrl = process.env.REACT_APP_BASE_URL;

                const apiUrl = new URL(
                    process.env.REACT_APP_GET_EMAIL_MESSAGES,
                    baseUrl
                );
                apiUrl.searchParams.set('start', `${pageParam * fetchSize}`);
                apiUrl.searchParams.set('size', `${fetchSize}`);
                apiUrl.searchParams.set('filters', colFilter);
                apiUrl.searchParams.set('globalFilter', globalFilter ?? '');
                apiUrl.searchParams.set('sorting', sortMenuAction.current);
                apiUrl.searchParams.set('folder', currentFolder.current);

                let response = await CallAPI({ url: apiUrl.href, method: 'GET', headers: {} }, sessions, dispatch);

                if (response !== null && response !== undefined) {
                    return response;
                }
                else {
                    throw Error(ErrorMessages.UnknownError);
                }
            },
            {
                getNextPageParam: (_lastGroup, groups) => groups.length,
                keepPreviousData: true,
                refetchOnWindowFocus: false,
            },
        );

    const flatData = useMemo(
        () => data?.pages.flatMap((page) => page.data) ?? [],
        [data],
    );

    const totalDBRowCount = data?.pages?.[0]?.meta?.totalRowCount ?? 0;
    const totalFetched = flatData.length;

    //called on scroll and possibly on mount to fetch more data as the user scrolls and reaches bottom of table
    const fetchMoreOnBottomReached = useCallback(
        (containerRefElement) => {
            if (containerRefElement) {
                const { scrollHeight, scrollTop, clientHeight } = containerRefElement;
                //once the user has scrolled within 400px of the bottom of the table, fetch more data if we can
                if (
                    scrollHeight - scrollTop - clientHeight < 400 &&
                    !isFetching &&
                    totalFetched < totalDBRowCount
                ) {
                    fetchNextPage();
                }
            }
        },
        [fetchNextPage, isFetching, totalFetched, totalDBRowCount],
    );

    //scroll to top of table when sorting or filters change
    useEffect(() => {
        if (virtualizerInstanceRef.current) {
            virtualizerInstanceRef.current.scrollToIndex(0);
        }
    }, [sorting, columnFilters, globalFilter]);

    //a check on mount to see if the table is already scrolled to the bottom and immediately needs to fetch more data
    useEffect(() => {
        fetchMoreOnBottomReached(tableContainerRef.current);
    }, [fetchMoreOnBottomReached]);

    useEffect(() => {

        //if (emailType.emailType === MessageType.sent) {
        //    setDateColNm('Sent')
        //}
        //else
        //    setDateColNm('Received')

        //if (emailType.emailType === MessageType.inbox)
        //    setMsgTabNm('Inbox');
        //else if (emailType.emailType === MessageType.archives)
        //    setMsgTabNm('Archives');
        //else if (emailType.emailType === MessageType.sent)
        //    setMsgTabNm('Sent');
        //else if (emailType.emailType === MessageType.drafts)
        //    setMsgTabNm('Drafts');
        //else if (emailType.emailType === MessageType.starred)
        //    setMsgTabNm('Starred');
        //else if (emailType.emailType === MessageType.important)
        //    setMsgTabNm('Important');
        //else if (emailType.emailType === MessageType.allEmails)
        //    setMsgTabNm('All');
        //else if (emailType.emailType === MessageType.deletedItems)
        //    setMsgTabNm('Deleted');

        //refetch();
    }, []);

    const handleCreateNewUser = () => {

    };
    const handleRemoveUsers = () => {
    };

    const [rowSelection, setRowSelection] = useState({});

    const handleChange = (event) => {
        setRowSelection('500', true);
    };

    const handleSortDtNewMenuClick = (event) => {
        setSortMnuAnchorEl(null);
        sortMenuAction.current = MessagaSortType.Newest;
        refetch();

        SetSortMenuTick(MessagaSortType.Newest);
    }

    const handleSortDtOldMenuClick = (event) => {
        setSortMnuAnchorEl(null);
        sortMenuAction.current = MessagaSortType.Oldest;
        refetch();

        SetSortMenuTick(MessagaSortType.Oldest);
    }

    const handleSortUnreadMenuClick = (event) => {
        setSortMnuAnchorEl(null);
        sortMenuAction.current = MessagaSortType.Unread;
        refetch();

        SetSortMenuTick(MessagaSortType.Unread);
    }

    const handleSortStarredMenuClick = (event) => {
        setSortMnuAnchorEl(null);
        sortMenuAction.current = ApplicationSortAction.Starred;
        refetch();

        SetSortMenuTick(ApplicationSortAction.Starred);
    }

    const handleSortSenderMenuClick = (event) => {
        setSortMnuAnchorEl(null);
        sortMenuAction.current = ApplicationSortAction.Sender;
        refetch();

        SetSortMenuTick(ApplicationSortAction.Sender);
    }

    const handleSortSubjectMenuClick = (event) => {
        setSortMnuAnchorEl(null);
        sortMenuAction.current = ApplicationSortAction.Subject;
        refetch();

        SetSortMenuTick(ApplicationSortAction.Subject);
    }

    const handleSortAttachmentMenuClick = (event) => {
        setSortMnuAnchorEl(null);
        sortMenuAction.current = MessagaSortType.Attachment;
        refetch();

        SetSortMenuTick(MessagaSortType.Attachment);
    }

    function SetSortMenuTick(item) {
        tickSrtDtNewDisp.current = 'none';
        tickSrtDtOldDisp.current = 'none';
        tickSrtUnreadDisp.current = 'none';
        tickSrtStarredDisp.current = 'none';
        tickSrtSenderDisp.current = 'none';
        tickSrtSubjectDisp.current = 'none';
        tickSrtAttachmentDisp.current = 'none';

        if (item == MessagaSortType.Newest)
            tickSrtDtNewDisp.current = 'block';

        if (item == MessagaSortType.Oldest)
            tickSrtDtOldDisp.current = 'block';

        if (item == MessagaSortType.Unread)
            tickSrtUnreadDisp.current = 'block';

        if (item == MessagaSortType.Starred)
            tickSrtStarredDisp.current = 'block';

        if (item == MessagaSortType.Sender)
            tickSrtSenderDisp.current = 'block';

        if (item == MessagaSortType.Subject)
            tickSrtSubjectDisp.current = 'block';

        if (item == MessagaSortType.Attachment)
            tickSrtAttachmentDisp.current = 'block';
    }

    return (
        <React.Fragment >
            <Box sx={{ pl: 1, pr: 5, ml: 2 }}>
                <Box sx={{ width: '100%', borderBottom: '1px solid #bdbdbd', pb: 4 }} component="span" display="flex" justifyContent="space-between" alignItems="justify-end">
                    <Typography variant="h4" sx={{ pt: 1, pl: 1 }} color="text.secondary">{msgTabNm}</Typography>
                    <Paper elevation={0}
                        component="form"
                        sx={{ display: 'flex', alignItems: 'center', width: 500, border: 1, borderColor: '#bdbdbd', }}
                    >
                        <InputBase className="form-control" sx={{ boxShadow: 'none !important', border: 'none !important', padding: '0px 1rem !important', font: 'unset' }}
                            placeholder="Search messages"
                        />
                        <IconButton type="button" sx={{ p: '10px' }} aria-label="search">
                            <Search />
                        </IconButton>
                    </Paper>
                </Box>
                {/*<Box sx={{ pt: 2 }}>*/}
                {/*    <Divider variant="middle" sx={{ background: 'rgba(0,0,0,0.8)', }} />*/}
                {/*</Box>*/}
                {/*<Box sx={{ pt: 2, pb: 2, backgroundColor: '#f7f7fa', width: '100%' }}>@nbsp;</Box>*/}

                <Box sx={{ pt: 2 }}>
                    <MaterialReactTable
                        columns={columns}
                        data={flatData}
                        displayColumnDefOptions={{
                            'mrt-row-select': {
                                maxSize: 12
                            },
                            'mrt-row-actions': {
                                header: '', //change header text
                                maxSize: 30, //make actions column wider
                            },
                        }}
                        enablePagination={false}
                        enableRowNumbers={false}
                        enableRowVirtualization //optional, but recommended if it is likely going to be more than 100 rows
                        manualFiltering
                        enableSorting={false}
                        enableColumnActions={false}
                        enableHiding={false}
                        enableColumnOrdering={false}
                        enableRowSelection={true}
                        enableSelectAll={false}
                        enableRowActions={true}
                        renderRowActionMenuItems={(row, index) => [
                            <MenuItem key={1} onClick={() => console.info('Edit')}>Mark as important</MenuItem>,
                            <MenuItem key={2} onClick={() => deleteSelectedEmail(row.original?.Id, false)}>Delete</MenuItem>
                        ]
                        }
                        positionActionsColumn="last"
                        //enableTableHead={false}
                        //enableSelectAll={false}
                        //muiSelectAllCheckboxProps={{
                        //    sx: { visibility: 'hidden' },
                        //}}
                        muiTableHeadCellProps={{
                            sx: {},
                        }}
                        enableColumnResizing={false}
                        enableDensityToggle={false}
                        enableFullScreenToggle={false}
                        muiTableBodyCellProps={({ column }) => ({
                            sx: (theme) => ({
                                minHeight: '50px',
                                color: theme.palette.text.secondary,
                                fontSize: theme.typography.body1,
                                borderBottom: '1px solid #e0e0e0',
                            }),
                        })
                        }
                        muiTableBodyRowProps={({ row }) => ({
                            onClick: (event) => {
                                console.info(event, row.id);
                            },
                            sx: {
                                cursor: 'pointer', //you might want to change the cursor too when adding an onClick
                            },
                        })}
                        muiTableContainerProps={{
                            ref: tableContainerRef,
                            sx: { maxHeight: '600px' },
                            onScroll: (
                                event, //add an event listener to the table container element
                            ) => fetchMoreOnBottomReached(event.target),
                        }}
                        muiToolbarAlertBannerProps={
                            isError
                                ? {
                                    color: 'error',
                                    children: ErrorMessages.UnknownError,
                                }
                                : undefined
                        }
                        onColumnFiltersChange={setColumnFilters}
                        onGlobalFilterChange={setGlobalFilter}
                        onSortingChange={setSorting}
                        renderBottomToolbarCustomActions={() => (
                            <Typography className="mt-3 text-end w-100">
                                {
                                    !isError && data
                                        ? totalFetched + ' of ' + totalDBRowCount + ' messsages.'
                                        : ''
                                }
                            </Typography>
                        )}
                        //renderToolbarInternalActions={({ table }) => (
                        //    <>
                        //        <span>Search</span>
                        //        <MRT_ToggleGlobalFilterButton table={table} />
                        //        <span>Filter</span>
                        //        <MRT_ToggleFiltersButton table={table} />                        
                        //    </>
                        //)}
                        onRowSelectionChange={setRowSelection}
                        state={{
                            columnFilters,
                            globalFilter,
                            isLoading,
                            showAlertBanner: isError,
                            showProgressBars: isFetching,
                            rowSelection,
                            sorting,
                        }}
                        initialState={{
                            density: 'compact',
                        }}
                        muiTablePaperProps={{
                            elevation: 0,
                        }}
                        renderTopToolbar={({ table }) => (
                            <>
                                <Box sx={{ display: 'flex', justifyContent: 'space-between', pt: 1, }}>
                                    <Box>
                                        {/*<FormControlLabel control={<Checkbox defaultChecked size="small" onChange={handleChange} />} label="Label" />*/}
                                        <Checkbox
                                            inputProps={{ 'aria-label': 'controlled' }}
                                        />
                                        <Tooltip arrow title="Refresh">
                                            <IconButton onClick={() => refetch()}>
                                                <RefreshOutlinedIcon sx={{ color: grey[600], fontSize: 24 }} />
                                            </IconButton>
                                        </Tooltip>
                                        <Tooltip arrow title="Mark as read">
                                            <span>
                                                <IconButton disabled={table.getSelectedRowModel().flatRows.length === 0} onClick={() => readUnreadMultipleEmails(table.getSelectedRowModel().flatRows, false)}>
                                                    <DraftsOutlinedIcon sx={{ color: grey[600], fontSize: 24 }} />
                                                </IconButton>
                                            </span>
                                        </Tooltip>
                                        <Tooltip arrow title="Delete">
                                            <span>
                                                <IconButton disabled={table.getSelectedRowModel().flatRows.length === 0} onClick={() => deleteMultipleEmails(table.getSelectedRowModel().flatRows, false)}>
                                                    <DeleteOutlineOutlinedIcon sx={{ color: grey[600], fontSize: 24 }} />
                                                </IconButton>
                                            </span>
                                        </Tooltip>
                                        <Tooltip arrow title="More options">
                                            <span>
                                                <IconButton onClick={handleActionClick}>
                                                    <MoreVertOutlinedIcon sx={{ color: grey[600], fontSize: 24 }} />
                                                </IconButton>
                                            </span>
                                        </Tooltip>
                                        <Menu
                                            id="basic-menu"
                                            anchorEl={actionAnchorEl}
                                            open={openAction}
                                            onClose={handleActionClose}
                                            MenuListProps={{
                                                'aria-labelledby': 'basic-button',
                                            }}
                                        >
                                            <MenuItem onClick={() => readUnreadMultipleEmails(table.getSelectedRowModel().flatRows, true)}>Mark as unread</MenuItem>
                                            <Divider />
                                            <MenuItem onClick={() => importantAddRemoveMultipleEmails(table.getSelectedRowModel().flatRows, true, false)}>Mark as important</MenuItem>
                                            <MenuItem onClick={() => importantAddRemoveMultipleEmails(table.getSelectedRowModel().flatRows, false, false)}>Remove important</MenuItem>
                                            <Divider />
                                            <MenuItem onClick={() => starAddRemoveMultipleEmails(table.getSelectedRowModel().flatRows, true)}>Add star</MenuItem>
                                            <MenuItem onClick={() => starAddRemoveMultipleEmails(table.getSelectedRowModel().flatRows, false,)}>Remove star</MenuItem>
                                        </Menu>
                                    </Box>

                                    <Box className="display-20 aob-text-primary">
                                        <Stack direction="row" alignItems="center">
                                            <Typography variant="h6" color="text.primary">
                                                Filter
                                            </Typography>
                                            {/*<span>Filter</span>*/}
                                            <MRT_ToggleFiltersButton table={table} />
                                            <Typography variant="h6" color="text.primary">
                                                Sort
                                            </Typography>
                                            {/*<span className="aob-text-primary display-20">Sort</span>*/}
                                            <IconButton onClick={handleClick}>
                                                <ChevronDown sx={{ fontSize: 24, color: 'grey[600]' }} />
                                            </IconButton>
                                        </Stack>
                                        {/*<Button*/}
                                        {/*    id="basic-button"*/}
                                        {/*    aria-controls={open ? 'basic-menu' : undefined}*/}
                                        {/*    aria-haspopup="true"*/}
                                        {/*    aria-expanded={open ? 'true' : undefined}*/}
                                        {/*    onClick={handleClick}*/}
                                        {/*>*/}
                                        {/*    Dashboard*/}
                                        {/*</Button>*/}
                                        <Menu
                                            id="basic-menu"
                                            anchorEl={sortMnuAnchorEl}
                                            open={open}
                                            onClose={handleClose}
                                            MenuListProps={{
                                                'aria-labelledby': 'basic-button',
                                            }}
                                        >
                                            <MenuItem className="display-21" onClick={(event) => handleSortDtNewMenuClick(event)}>
                                                <ListItemIcon className="ms-3 mb-1">
                                                    <CheckIcon sx={{ display: tickSrtDtNewDisp.current, fontSize: 24, color: '#0070CC' }} />
                                                </ListItemIcon>
                                                <Typography className="me-5" variant="body2" sx={{ textTransform: 'none' }} color="text.primary">Date: Newest on top</Typography>
                                            </MenuItem>
                                            <MenuItem key={1} onClick={(event) => handleSortDtOldMenuClick(event)}>
                                                <ListItemIcon className="ms-3 mt-1 mb-1">
                                                    <CheckIcon sx={{ display: tickSrtDtOldDisp.current, fontSize: 24, color: '#0070CC' }} />
                                                </ListItemIcon>
                                                <Typography className="me-5" variant="body2" sx={{ textTransform: 'none' }} color="text.primary">Date: Oldtest on top</Typography>
                                            </MenuItem>
                                            <MenuItem key={1} onClick={(event) => handleSortUnreadMenuClick(event)}>
                                                <ListItemIcon className="ms-3 mt-1 mb-1">
                                                    <CheckIcon sx={{ display: tickSrtUnreadDisp.current, fontSize: 24, color: '#0070CC' }} />
                                                </ListItemIcon>
                                                <Typography className="me-5" variant="body2" sx={{ textTransform: 'none' }} color="text.primary">Unread</Typography>
                                            </MenuItem>
                                            <MenuItem key={1} onClick={(event) => handleSortStarredMenuClick(event)}>
                                                <ListItemIcon className="ms-3 mt-1 mb-1">
                                                    <CheckIcon sx={{ display: tickSrtStarredDisp.current, fontSize: 24, color: '#0070CC' }} />
                                                </ListItemIcon>
                                                <Typography className="me-5" variant="body2" sx={{ textTransform: 'none' }} color="text.primary">Starred</Typography>
                                            </MenuItem>
                                            <MenuItem key={1} onClick={(event) => handleSortSenderMenuClick(event)}>
                                                <ListItemIcon className="ms-3 mt-1 mb-1">
                                                    <CheckIcon sx={{ display: tickSrtSenderDisp.current, fontSize: 24, color: '#0070CC' }} />
                                                </ListItemIcon>
                                                <Typography className="me-5" variant="body2" sx={{ textTransform: 'none' }} color="text.primary">Sender</Typography>
                                            </MenuItem>
                                            <MenuItem key={1} onClick={(event) => handleSortSubjectMenuClick(event)}>
                                                <ListItemIcon className="ms-3 mt-1 mb-1">
                                                    <CheckIcon sx={{ display: tickSrtSubjectDisp.current, fontSize: 24, color: '#0070CC' }} />
                                                </ListItemIcon>
                                                <Typography className="me-5" variant="body2" sx={{ textTransform: 'none' }} color="text.primary">Subject</Typography>
                                            </MenuItem>
                                            <MenuItem key={1} onClick={(event) => handleSortAttachmentMenuClick(event)}>
                                                <ListItemIcon className="ms-3 mt-1 mb-1">
                                                    <CheckIcon sx={{ display: tickSrtAttachmentDisp.current, fontSize: 24, color: '#0070CC' }} />
                                                </ListItemIcon>
                                                <Typography className="me-5" variant="body2" sx={{ textTransform: 'none' }} color="text.primary">Attachment</Typography>
                                            </MenuItem>
                                        </Menu>
                                    </Box>
                                </Box>
                            </>
                        )}
                        //enableTopToolbar={false}
                        rowVirtualizerProps={{ overscan: 4 }}
                        localization={{
                            noRecordsToDisplay: 'No messages to display',
                            filterFuzzy: 'Contains',
                            select: '',
                        }}
                    //renderTopToolbarCustomActions={({ table }) => {
                    //    return (
                    //        <Box>
                    //            <FormControlLabel control={<Checkbox defaultChecked size="small" onChange={handleChange} />} label="Label" />
                    //            <Tooltip arrow title="Refresh Data">
                    //                <IconButton onClick={() => refetch()}>
                    //                    <ArrowClockwise />
                    //                </IconButton>
                    //            </Tooltip>
                    //            <Tooltip arrow title="Mark as read">
                    //                <span>
                    //                <IconButton disabled={table.getSelectedRowModel().flatRows.length === 0}>
                    //                    <EnvelopeOpen width="18px" height="18px" />
                    //                    </IconButton>
                    //                    </span>
                    //            </Tooltip>
                    //            <Tooltip arrow title="Archive">
                    //                <span>
                    //                <IconButton disabled={table.getSelectedRowModel().flatRows.length === 0}>
                    //                    <ArchiveFill width="21px" height="21px" />
                    //                </IconButton>
                    //                </span>
                    //            </Tooltip>
                    //            <Tooltip arrow title="Delete">
                    //                <span>
                    //                <IconButton disabled={table.getSelectedRowModel().flatRows.length === 0}>
                    //                    <TrashFill width="21px" height="21px" />
                    //                    </IconButton>
                    //                </span>
                    //            </Tooltip>
                    //        </Box>
                    //    );
                    //}}
                    />

                </Box>
            </Box>
        </React.Fragment>
    );
};

const FolderType = {
    Inbox: 1,
    Sent: 2,
    Archives: 3,
    Drafts: 4,
    Starred: 5,
    Important: 6,
    AllEmails: 7,
    DeletedItems: 8
}

const MessagaSortType = {
    Newest: 1,
    Oldest: 2,
    Unread: 3,
    Starred: 4,
    Sender: 5,
    Subject: 6,
    Attachment: 7
}

const queryClient = new QueryClient();

const EmailConversationProvider = () => (
    <QueryClientProvider client={queryClient}>
        <EmailConversation />
    </QueryClientProvider>
);

function EmailMessagesBreadcrumb() {
    return (
        <React.Fragment >

            <Box sx={{ borderLeft: '4px solid #42a5f5', mt: 0, pt: 2, pb: 2, pl: 1, backgroundColor: '#f8f9fa', borderTop: '1px solid #dee2e6', boxShadow: '0 0.125rem 0.25rem rgba(0, 0, 0, 0.075)' }}>
                <Typography variant="h5" sx={{ fontWeight: 300, lineHeight: 1.1, pl: 1, textTransform: 'none' }} color="text.primary">Email messages</Typography>
            </Box>

        </React.Fragment>
    );
}

export default EmailMessages;