import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from "react-router-dom";
import { Box, FormControl, TextField, Typography, Divider, Button, Table, TableBody, TableRow, TableCell, Stack, Tooltip, IconButton, Link } from '@mui/material';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import OverlaySpinner from '../Controls/OverlaySpinner';
import { useDispatch, useSelector } from 'react-redux';
import { CallAPI, IsError } from '../../global/APIUtils';
import { SysParmGrp, SysParmIdentifier } from '../../customFunctions/SysParmConfig';
import { ErrorMessages } from '../../common/ErrorMessages';
import AlertControl from '../Controls/AlertControl';
import { useFormik } from 'formik';
import * as yup from 'yup';
import AlertWithTitle from '../Controls/AlertWithTitle';
import { goToTopSystemParameter } from '../../global/Helpers';
import { APICall, WriteToActivityLog } from '../../global/Api';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import BankResourceDlg from '../../dialogs/BankResourceDlg';
import { ActivityLogEvent } from '../../common/AOBEnum';

const BankInfo = (props) => {

    const location = useLocation();

    const [isDataLoading, setIsDataLoading] = useState(false);
    const [submit, setSubmit] = useState(false);
    const [bankName, setBankName] = useState('');
    const [bankLogoFile, setBankLogoFile] = useState('');
    const [bankLogo2File, setBankLogo2File] = useState('');
    const [loginPageImgFile, setLoginPageImgFile] = useState('');
    const [prospectHomePageImgFile, setProspectHomePageImgFile] = useState('');
    const [showImageDlg, setShowImageDlg] = useState(false);
    const [imageType, setImageType] = useState(1);

    const [errorMsg, setErrorMsg] = useState(null);
    const [successMsg, setSuccessMsg] = useState('');

    const dispatch = useDispatch();
    let sessions = useSelector((state) => state.sessionMgmnt);

    const [userId, setUserID] = useState(props.userId || -1);
    const [sessionId, setSessionId] = useState(props.sessionId || '');

    const loadConfigGroup = async () => {
        try {

            let headers = {
                'Content-Type': 'application/json'
            };

            const apiUrl = new URL(
                process.env.REACT_APP_GET_BANK_INFO,
                process.env.REACT_APP_BASE_URL,
            );

            setIsDataLoading(true);

            let response = await APICall({ url: apiUrl.href, method: 'GET', contentType: 'application/json', responseType: '' }, userId, sessionId);

            if (response !== null && response !== undefined) {
                if (response["data"] && response["data"].length > 0) {
                    setBankName(response.data[0].BankName);
                    setBankLogoFile(response.data[0].BankLogoFileName);
                    setBankLogo2File(response.data[0].BankLogo2FileName);
                    setLoginPageImgFile(response.data[0].LoginPageImgFileName);
                    setProspectHomePageImgFile(response.data[0].ProspectHomePageImgFileName);
                }
            }
            else {
                setErrorMsg(ErrorMessages.UnknownError);
            }
        }
        catch (err) {
            setErrorMsg(ErrorMessages.UnknownError);
        }
        finally {
            setIsDataLoading(false);
        }
    }

    useEffect(() => {
        //setIsDataLoading(true);
        loadConfigGroup();
    }, []);


    const bankInformationForm = useFormik({
        enableReinitialize: true,
        initialValues: {
            BankName: bankName,
        },
        validationSchema: yup.object({
            BankName: yup.string()
                .trim('Bank Name - cannot contain leading and trailing spaces')
                .required('Bank Name - required')
                .max(512, "Bank Name - must be less than 512 characters.")
            
        }),
        onSubmit: values => {
            submitClick(values);
        }
    })

    const submitClick = async (values) => {

        setErrorMsg('');
        setSuccessMsg('');

        try {
            setIsDataLoading(true);

            let inputJson = {
                BankName: values.BankName
            };

            const apiUrl = new URL(
                process.env.REACT_APP_SAVE_BANK_NAME,
                process.env.REACT_APP_BASE_URL,
            );

            let response = await APICall({ url: apiUrl.href, method: 'POST', payload: inputJson, contentType: 'application/json', responseType: '' }, userId, sessionId);

            if (response !== null && response !== undefined) {

                if (response.meta.Updated == "1") {

                    await WriteToActivityLog(ActivityLogEvent.SystemOptions, "Bank name saved.", userId, sessionId);
                    setSuccessMsg("Bank name has been successfully saved.");
                }
                else
                    setErrorMsg(ErrorMessages.UnknownError);
            }
        }
        catch (err) {
            setErrorMsg(ErrorMessages.UnknownError);
        }
        finally {
            setSubmit(false);
            setIsDataLoading(false);
        }
    }

    const closeImageDlg = () => {
        //event.preventDefault();
        setShowImageDlg(false);
    }

    const onBankNameChanged = (e) => {
        setSuccessMsg('');
        bankInformationForm.setFieldValue('BankName', e.target.value)
    }

    const onPicChangeHandle = async () => {
        loadConfigGroup();
        setShowImageDlg(false);
    }

    const handleImageBrowseClick = (event, mode) => {
        setImageType(mode);
        setShowImageDlg(true);
    };

    return (
        <>
            <Box component="main" sx={{ flex: 1, height: '100%', pt: 0, pl: 6 }} >
                <Box >
                    <Typography variant='body2' color="text.blueText" sx={{ fontWeight: 500, pb: 1, lineHeight: 1.5, borderBottom: '0px solid #9FA4A8',  }}>Bank information</Typography>
                        <Divider variant="fullWidth" component="div" sx={{ }} />
                    <div >
                        <form onSubmit={bankInformationForm.handleSubmit} id="bankInfoForm">
                            <OverlaySpinner disappear={isDataLoading} />
                            <AlertControl Message={errorMsg} severity="error" color="error" icon={true} />
                            <AlertWithTitle Message={successMsg} severity="success" color="success" icon={true} Title="Bank Information" />
                            <Box sx={{pb: 3, width: '90%', pt: 2.5}}>
                                <label htmlFor="BankName" className="form-label">Bank Name*</label>
                                <input type="text" id="BankName" name="BankName" placeholder="Enter bank name here" className="form-control" value={bankInformationForm.values.BankName} onChange={bankInformationForm.handleChange} />
                                {
                                    bankInformationForm.touched.BankName && bankInformationForm.errors.BankName ? (<p className="text-danger">{bankInformationForm.errors.BankName}</p>) : null
                                }
                            </Box>
                            <Box sx={{ pl: 0.5, pb: 4, pt: 0, borderBottom: '1px solid #b6b7b6' }}>
                                <Button type="submit" variant="contained" size="large" style={{ boxShadow: 'none', border: 0, borderRadius: '20px', padding: '0.3rem 2.5rem', outline: 'none', textTransform: 'none'}}>
                                    Save
                                </Button>
                            </Box>
                            <Box sx={{pt: 1}}>
                                <Table aria-label="simple table" sx={{ border: '0px solid #e0e0e0', }} size="small">
                                    <TableBody>
                                        <TableRow sx={{}}>
                                            <TableCell component="th" scope="row" sx={{ pt: 2, pb: 2, pl: 0, borderBottom: '1px solid #BBBFBF' }}>
                                                <Stack direction="row" spacing={0} alignItems="center">
                                                    <Typography className="" variant="body1" color="text.primary" sx={{ fontWeight: 400 }}>
                                                        Bank Logo
                                                    </Typography>
                                                </Stack>
                                            </TableCell>
                                            <TableCell color="text.primary" sx={{ pb: 2, pt: 2, borderBottom: '1px solid #BBBFBF' }}>
                                                {
                                                    bankLogoFile === '' ?
                                                        <Typography className="" variant="body1" color="text.primary" sx={{ fontWeight: 400 }}>
                                                            No file chosen
                                                        </Typography>
                                                    :
                                                        <Typography variant="body2" sx={{ mb: 0, fontWeight: 400, color: 'Link.main' }}>{bankLogoFile.length > 25 ? bankLogoFile.substring(0, 25) + '...' : bankLogoFile}</Typography>
                                                }
                                            </TableCell>
                                            <TableCell align="right" color="text.primary" sx={{ pb: 2, pt: 2, borderBottom: '1px solid #BBBFBF' }}>
                                                <Tooltip arrow title="Upload image">
                                                    <span>
                                                        <IconButton sx={{ border: 0, borderColor: '#235ec9', p: 1 }} onClick={(event) => handleImageBrowseClick(event, 1)}>
                                                            <MoreHorizIcon  sx={{ fontSize: 24, color: 'Icon.main' }} />
                                                        </IconButton>
                                                    </span>
                                                </Tooltip>
                                            </TableCell>
                                        </TableRow>
                                        <TableRow sx={{}}>
                                            <TableCell component="th" scope="row" sx={{ pt: 3, pb: 2, pl: 0, borderBottom: '1px solid #BBBFBF' }}>
                                                <Stack direction="row" spacing={0} alignItems="center">
                                                    <Typography className="" variant="body1" color="text.primary" sx={{ fontWeight: 400 }}>
                                                        Bank Logo 2
                                                    </Typography>
                                                </Stack>
                                            </TableCell>
                                            <TableCell color="text.primary" sx={{ pb: 2, pt: 3, borderBottom: '1px solid #BBBFBF' }}>
                                                {
                                                    bankLogo2File === '' ?
                                                        <Typography className="" variant="body1" color="text.primary" sx={{ fontWeight: 400 }}>
                                                            No file chosen
                                                        </Typography>
                                                        :
                                                    <Typography variant="body2" sx={{ mb: 0, fontWeight: 400, color: 'Link.main' }}>{bankLogo2File.length > 25 ? bankLogo2File.substring(0, 25) + '...' : bankLogo2File}</Typography>
                                                }
                                            </TableCell>
                                            <TableCell align="right" color="text.primary" sx={{ pb: 2, pt: 3, borderBottom: '1px solid #BBBFBF' }}>
                                                <Tooltip arrow title="Upload image">
                                                    <span>
                                                        <IconButton sx={{ border: 0, borderColor: '#235ec9', p: 1 }} onClick={(event) => handleImageBrowseClick(event, 2)}>
                                                            <MoreHorizIcon sx={{ fontSize: 24, color: 'Icon.main' }} />
                                                        </IconButton>
                                                    </span>
                                                </Tooltip>
                                            </TableCell>
                                        </TableRow>
                                        <TableRow sx={{}}>
                                            <TableCell component="th" scope="row" sx={{ pt: 3, pb: 2, pl: 0, borderBottom: '1px solid #BBBFBF' }}>
                                                <Stack direction="row" spacing={0} alignItems="center">
                                                    <Typography className="" variant="body1" color="text.primary" sx={{ fontWeight: 400 }}>
                                                        Login page image
                                                    </Typography>
                                                </Stack>
                                            </TableCell>
                                            <TableCell color="text.primary" sx={{ pb: 2, pt: 3, borderBottom: '1px solid #BBBFBF' }}>
                                                {
                                                    loginPageImgFile === '' ?
                                                        <Typography className="" variant="body1" color="text.primary" sx={{ fontWeight: 400 }}>
                                                            No file chosen
                                                        </Typography>
                                                        :
                                                        <Typography variant="body2" sx={{ mb: 0, fontWeight: 400, color: 'Link.main' }}>{loginPageImgFile.length > 25 ? loginPageImgFile.substring(0, 25) + '...' : loginPageImgFile}</Typography>
                                                }
                                            </TableCell>
                                            <TableCell align="right" color="text.primary" sx={{ pb: 2, pt: 3, borderBottom: '1px solid #BBBFBF' }}>
                                                <Tooltip arrow title="Upload image">
                                                    <span>
                                                        <IconButton sx={{ border: 0, borderColor: '#235ec9', p: 1 }} onClick={(event) => handleImageBrowseClick(event, 3)}>
                                                            <MoreHorizIcon sx={{ fontSize: 24, color: 'Icon.main' }} />
                                                        </IconButton>
                                                    </span>
                                                </Tooltip>
                                            </TableCell>
                                        </TableRow>
                                        <TableRow sx={{}}>
                                            <TableCell component="th" scope="row" sx={{ pt: 3, pb: 2, pl: 0, borderBottom: '1px solid #BBBFBF' }}>
                                                <Stack direction="row" spacing={0} alignItems="center">
                                                    <Typography className="" variant="body1" color="text.primary" sx={{ fontWeight: 400 }}>
                                                        Prospect home page image
                                                    </Typography>
                                                </Stack>
                                            </TableCell>
                                            <TableCell color="text.primary" sx={{ pb: 2, pt: 3, borderBottom: '1px solid #BBBFBF' }}>
                                                {
                                                    prospectHomePageImgFile === '' ?
                                                        <Typography className="" variant="body1" color="text.primary" sx={{ fontWeight: 400 }}>
                                                            No file chosen
                                                        </Typography>
                                                        :
                                                        <Typography variant="body2" sx={{ mb: 0, fontWeight: 400, color: 'Link.main' }}>{prospectHomePageImgFile.length > 25 ? prospectHomePageImgFile.substring(0, 25) + '...' : prospectHomePageImgFile}</Typography>
                                                }
                                            </TableCell>
                                            <TableCell align="right" color="text.primary" sx={{ pb: 2, pt: 3, borderBottom: '1px solid #BBBFBF' }}>
                                                <Tooltip arrow title="Upload image">
                                                    <span>
                                                        <IconButton sx={{ border: 0, borderColor: '#235ec9', p: 1 }} onClick={(event) => handleImageBrowseClick(event, 4)}>
                                                            <MoreHorizIcon sx={{ fontSize: 24, color: 'Icon.main' }} />
                                                        </IconButton>
                                                    </span>
                                                </Tooltip>
                                            </TableCell>
                                        </TableRow>
                                    </TableBody>
                                </Table>
                            </Box>
                        </form>
                    </div>
                </Box>
                {showImageDlg ?
                    <BankResourceDlg open={showImageDlg} onClose={closeImageDlg} onChange={onPicChangeHandle} ImageType={imageType} userId={userId} sessionId={sessionId} />
                    : null
                }
            </Box >
        </>
    )
}
export default BankInfo;