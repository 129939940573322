import React, { useState } from 'react';
import { useNavigate } from "react-router-dom";
import { Plus, FileEarmarkArrowUp } from 'react-bootstrap-icons';
import TemplateImportModal from './Modal/TemplateImportModal';

const DashBoardCreateTemplate = (props) => {
    const [modalShow, setModalShow] = useState(false);

    let history = useNavigate();

    const handleClick = () => {
        history.push({
            pathname: '/TemplateDesign',
            state: { templateid: null, templatename: null, templatedesc: null }
        });
    }
    const importClick = () => {
        setModalShow(true);
    }
    return (
        <>
            <div className="col-sm-12">
                <div className="p-t-6 p-b-3">
                    <p>Create a template for a bank account.</p>
                    <span className="m-e-2 f-size-14">{props.datalength} &nbsp;Templates</span>
                    <button type="button" className="pill-button m-s-1" onClick={() => handleClick()} >
                        <Plus className="m-s-4 m-e-4 m-b-1 m-t-1" title="New template" color="#3D3D3D" size={22} /><span className="tool-icon-txt">New Template</span>
                    </button>
                    <button type="button" className="pill-button m-s-1" onClick={props.importHanleClick} >
                        {/*<span><i className="fa fa-file-import px-2"></i>&nbsp; Import Template</span>*/}
                        <FileEarmarkArrowUp className="m-s-4 m-e-4 m-b-1 m-t-1" title="Import template" color="#3D3D3D" size={22} /><span className="tool-icon-txt">Import Template</span>
                    </button>
                </div>
            </div>
            {
                /*<TemplateAddEditModal
                    show={modalShow}
                    backdrop="static"
                    keyboard={false}
                    onHide={() => setModalShow(false)}
                    title={"Create New Template"}
                />*/
            }
            {/*<TemplateImportModal show={modalShow} onHide={EnrollmentUploadModalOnHide} onExit={() => setModalShow(false)} title={"Import Template"} loginusrid={4}/>*/}
        </>
    )
}

export default DashBoardCreateTemplate;