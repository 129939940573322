import React, { useState, useEffect } from 'react';
import { useFormik } from 'formik';
import { useNavigate } from "react-router-dom";
import * as yup from 'yup';
import { ApiHelper } from '../customFunctions/apiHelper';
import { ArrowLeft, ArrowRight, ListCheck, Printer, Save } from 'react-bootstrap-icons';
import AlertControl from './Controls/AlertControl';
import { useSelector, useDispatch } from 'react-redux';
import { CallAPI, IsError } from '../global/APIUtils';
import OverlaySpinner from './Controls/OverlaySpinner';
import { Grid, Box, Typography, FormControl, TextField, Button, Paper, Stack } from '@mui/material';
import { ErrorMessages } from '../common/ErrorMessages';
import { APICall, WriteToActivityLog } from '../global/Api';
import { ActivityLogEvent } from '../common/AOBEnum';

const ProfileChangePassword = (props) => {

    const sessions = useSelector((state) => state.sessionMgmnt);
    const dispatch = useDispatch();
    const [submit, setSubmit] = useState(false);
    const [regMsg, setRegMsg] = useState('');
    const [validationMsg, setValidationMsg] = useState('');
    const [msgModalShow, setMsgModalShow] = useState(false);
    const [alertSucStatus, setAlertSucStatus] = useState(false);
    const [errorMsg, setErrorMsg] = useState('');
    const [successMsg, setSuccessMsg] = useState('');
    const [infoMsg, setInfoMsg] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [lastPwdChangeDate, setLastPwdChangeDate] = useState('');
    const [strength, setStrength] = useState("");
    const [strengthColor, setStrengthColor] = useState("");
    const [showStrength, setShowStrength] = useState("none");

    const [pwdLenMin, setPwdLenMin] = useState(10);

    const [userId, setUserId] = useState(props.userId || -1);
    const [sessionId, setSessionId] = useState(props.sessionId || '');

    const pwdRegExp = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/;
    let history = useNavigate();

    const existHandeler = () => {
        history.push({ pathname: '/DashBoard' });
    }

    const GetLastPwdChangeDate = async () => {
        //let baseUrl = process.env.REACT_APP_BASE_URL;

        const apiUrl = new URL(
            process.env.REACT_APP_GET_PASSWORD_CHANGEDATE,
            process.env.REACT_APP_BASE_URL,
        );

        apiUrl.searchParams.set('ID', `${userId}`);

        try {

            setIsLoading(true);

            //let response = await CallAPI({ url: apiUrl.href, method: 'GET', headers: {} }, userId, sessionId);
            let response = await APICall({ url: apiUrl.href, method: 'GET', contentType: 'application/json', responseType: '' }, userId, sessionId);

            if (response !== undefined && response !== null) {
                if (response.data && response.data.length > 0) {
                    setLastPwdChangeDate('Last changed ' + response.data[0].PasswordChangeDate);
                }
            }
        }
        catch (err) {
            setErrorMsg(ErrorMessages.UnknownError);
            
        }
        finally {
            setIsLoading(false);
        }
    }

    const GetPasswordPolicy = async () => {
        try {

            let headers = {
                'Content-Type': 'application/json'
            };

            const apiUrl = new URL(
                process.env.REACT_APP_GET_PWD_POLICY,
                process.env.REACT_APP_BASE_URL,
            );

            setIsLoading(true);

            let response = await APICall({ url: apiUrl.href, method: 'GET', contentType: 'application/json', responseType: '' }, userId, sessionId);

            if (response !== null && response !== undefined) {
                if (response["data"] && response["data"].length > 0) {
                    setPwdLenMin(parseInt(response.data[1].ParmValue));
                }
            }
            else {
                setErrorMsg(ErrorMessages.UnknownError);
            }
        }
        catch (err) {
            setErrorMsg(ErrorMessages.UnknownError);
        }
        finally {
            setIsLoading(false);
        }
    }

    function evaluatePasswordStrength(password) {
        let score = 0;

        if (!password) {
            setShowStrength('none');
            return '';
        }

        // Check password length
        if (password.length > 11) score += 1;
        // Contains lowercase
        if (/[a-z]/.test(password)) score += 1;
        // Contains uppercase
        if (/[A-Z]/.test(password)) score += 1;
        // Contains numbers
        if (/\d/.test(password)) score += 1;
        // Contains special characters
        if (/[^A-Za-z0-9]/.test(password)) score += 1;

        setShowStrength('inline-block');

        switch (score) {
            case 0:
            case 1:
            case 2:
                setStrengthColor('#f00');
                return "Weak";
            case 3:
            case 4:
                setStrengthColor('#ffa500');
                return "Medium";
            case 5:
                setStrengthColor('#228b22');
                return "Strong";
        }
    }

    useEffect(() => {

        GetLastPwdChangeDate();
        GetPasswordPolicy();
    }, []);

    const changePwdForm = useFormik({
        enableReinitialize: true,
        initialValues: {
            CurrentPassword: "",
            NewPassword: "",
            ConfirmPassword: ""
        },

        validationSchema: yup.object({
            CurrentPassword: yup.string()
                .required('Current Password - required.'),
            NewPassword: yup.string()
                .max(20, "Should have less than or equal to 20 characters")
                .min(pwdLenMin, "Should have more than or equal to " + pwdLenMin + " characters")
                .matches(pwdRegExp,
                    "Should have one uppercase, one lowercase, one number and one special character"
                )
                .required('Password - required.'),
            ConfirmPassword: yup.string()
                .max(20, "Should have less than or equal to 20 characters")
                .min(pwdLenMin, "Should have more than or equal to " + pwdLenMin + " characters")
                .matches(pwdRegExp,
                    "Should have one uppercase, one lowercase, one number and one special character"
                )
                .when("NewPassword", {
                    is: psswrd => (psswrd && psswrd.length > 0 ? true : false),
                    then: yup.string().oneOf(
                        [yup.ref("NewPassword")],
                        "New password and confirm password does not match")
                })
                .required('Confirm Password - required.')
        }),
        onSubmit: values => {
            submitClick(values);
        },
    });

    const submitClick = async (values) => {

        setSubmit(true); 
        setErrorMsg('');
        setSuccessMsg('');

        let chngModel = {
            EmailAddress: props.emailadrs,
            Password: values.CurrentPassword,
            NPassword: values.NewPassword,
            CPassword: values.ConfirmPassword,
            UserId: parseInt(userId)
        };

        let headers = {
            'Content-Type': 'application/json'
        };

        const apiUrl = new URL(
            process.env.REACT_APP_CHANGE_PASSWORD,
            process.env.REACT_APP_BASE_URL,
        );

        try {

            setIsLoading(true);

            //let response = await CallAPI({ url: apiUrl.href, method: 'POST', headers: headers, body: chngModel }, userId, sessionId);
            let response = await APICall({ url: apiUrl.href, method: 'POST', payload: chngModel, contentType: 'application/json', responseType: '' }, userId, sessionId);

            if (response != null && response != undefined) {

                setIsLoading(false);

                let error = IsError(response);

                if (error != 0) {
                    setErrorMsg(response.Errors[0].Message);
                }
                else {
                    setStrength('');
                    setShowStrength('none');
                    changePwdForm.resetForm();
                    await WriteToActivityLog(ActivityLogEvent.ProfileScreen, "Password changed.", userId, sessionId);
                    setSuccessMsg("Your new password is now active!");
                }
            }
        }
        catch (err) {
            setErrorMsg(ErrorMessages.UnknownError);
            setIsLoading(false);
        }
        finally {
        }
    }

    return (
        <React.Fragment>
            <OverlaySpinner disappear={isLoading} />
            <Grid item xs container direction="row" sx={{ borderLeft: 0, borderColor: '#747C81', ml: 0, mt: 0 }}>
                <Grid item xs={12} sx={{ mb: 2, ml: 4, textAlign: 'center', mt: 3}}>
                    <Typography align="center" gutterBottom variant="body1" color="text.secondary" sx={{ fontWeight: 500, borderBottom: '1px solid #9FA4A8', pb: 2.5, }}>
                        Change password
                    </Typography>
                    <Typography align="center" variant="caption" color="text.primary" sx={{ fontWeight: 400, pt: 0.5}}>
                        {lastPwdChangeDate}
                    </Typography>
                </Grid>
                <Grid item xs={12} container direction="row" sx={{ ml: 4, mr: 0, mt: 2 }}>
                    <form className="w-100" onSubmit={changePwdForm.handleSubmit} id="frmChangePwd">
                        <Grid item xs={12} sx={{ }} >
                            <AlertControl Message={errorMsg} severity="error" color="error" icon={true} />
                            <AlertControl Message={successMsg} severity="success" color="success" icon={true} />
                            <Typography variant="body1" color="text.blueText" sx={{mb: 2, fontWeight: 400 }}>
                                Don't use a variation of an old password or any personal information.
                            </Typography>
                        </Grid>
                        <Grid item xs={10} sx={{ pt: 3 }} >
                            <Paper elevation={0}>
                                <label htmlFor="CurrentPassword" className="form-label">Current Password*</label>
                                <input type="password" autoComplete="off" id="CurrentPassword" name="CurrentPassword" placeholder="Enter current password here" className="form-control" value={changePwdForm.values.CurrentPassword} onChange={changePwdForm.handleChange} />
                                {
                                    changePwdForm.touched.CurrentPassword && changePwdForm.errors.CurrentPassword ? (<p className="text-danger">{changePwdForm.errors.CurrentPassword}</p>) : null
                                }
                            </Paper>
                        </Grid>
                        <Grid item xs={10} sx={{ pt: 2 }} >
                            <Paper elevation={0}>
                                <label htmlFor="NewPassword" className="form-label">New Password*</label>
                                <input type="password" autoComplete="off" id="NewPassword" name="NewPassword" placeholder="Enter new password here" className="form-control" value={changePwdForm.values.NewPassword} onChange={(event) => { changePwdForm.handleChange(event); setStrength(evaluatePasswordStrength(event.target.value)) }} />
                                {
                                    changePwdForm.touched.NewPassword && changePwdForm.errors.NewPassword ? (<p className="text-danger">{changePwdForm.errors.NewPassword}</p>) : null
                                }
                                <Stack direction="row" spacing={1}>
                                    <Typography variant="body2" color="text.secondary" sx={{ display: showStrength, pt: 1, pb: 0, fontWeight: 500, }} >Password strength: </Typography>
                                    <Typography variant="body2" color={strengthColor} sx={{ pt: 1, pb: 0, fontWeight: 700, }} >{strength}</Typography>
                                </Stack>
                            </Paper>
                        </Grid>
                        <Grid item xs={10} sx={{ pt: 2 }} >
                            <Paper elevation={0}>
                                <label htmlFor="ConfirmPassword" className="form-label">Confirm Password*</label>
                                <input type="password" autoComplete="off" id="ConfirmPassword" name="ConfirmPassword" placeholder="Re-enter new password here" className="form-control" value={changePwdForm.values.ConfirmPassword} onChange={changePwdForm.handleChange} />
                                {
                                    changePwdForm.touched.ConfirmPassword && changePwdForm.errors.ConfirmPassword ? (<p className="text-danger">{changePwdForm.errors.ConfirmPassword}</p>) : null
                                }
                            </Paper>
                        </Grid>
                        <Grid item sm={12} sx={{}} >
                            <Box sx={{ pt: 5 }}>
                                <FormControl variant="outlined" sx={{}}>
                                    <Button type="submit" variant="contained" size="large" style={{ borderRadius: '20px', padding: '0.3rem 2.6rem', textTransform: 'none'}}>
                                        Save
                                    </Button>
                                </FormControl>
                            </Box>
                        </Grid>
                    </form>
                </Grid>
            </Grid>
        </React.Fragment>
    );

}
export default ProfileChangePassword;