import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { Typography } from '@mui/material';
import Box from '@mui/material/Box';
import { Stack } from '@mui/material';

export function FormDialog(props) {
    const { onClose, value: valueProp, open, ...other } = props;
    const [note, setNote] = useState('');

    const handleCancel = () => {
        onClose();
        setNote('');
    };

    const handleOk = () => {
        if (note) {
            onClose(props.nvalue, note);
            setNote('');
        }
    };

    const noteChangeHandler = (event) => {
        /*setTemplateInput({ ...statusHistInput, note: event.target.value })*/
        setNote(event.target.value);
    };

    return (
        <Dialog 
            sx={{ '& .MuiDialog-paper': { width: '80%', maxHeight: 535 } }}
            maxWidth="xs"
            open={open}
            {...other}>
            <DialogTitle>
                <Typography variant="body1" color="text.primary" sx={{ fontWeight: 500 }}>
                    {props.alertheader}
                </Typography>
            </DialogTitle>
            {/*<DialogContent dividers>*/}
                
            {/*</DialogContent>*/}
            <DialogContent dividers>
                <Box sx={{ pb: 1, pl: 2 }}>
                    <Stack direction="column">
                        <Typography variant="body1" color="text.primary" sx={{}}>{props.bmsgline1}</Typography>
                        <Typography variant="body1" color="text.primary" sx={{}}>{props.bmsgline2}</Typography>
                        <Typography variant="body1" color="text.primary" sx={{}}>{props.bmsgline3}</Typography>
                        <Typography variant="body1" color="text.primary" sx={{}}>{props.bmsgline4}</Typography>
                        <Typography variant="body1" color="text.primary" sx={{mt: 2}}>{props.bmsgline5}</Typography>
                    </Stack>
                    <TextField
                        id="standard-multiline-static"
                        autoFocus
                        margin="dense"
                        label="Reason"
                        multiline
                        rows={4}
                        value={note}
                        fullWidth
                        required={true}
                        onChange={noteChangeHandler}
                        />
                </Box>
            </DialogContent>
            <DialogActions>
                <Box sx={{ pb: 1, pt: 1 }}>
                    <Button autoFocus variant="contained" onClick={handleCancel} size="small" sx={{ borderRadius: '20px', padding: '0.25rem 2rem', mr: 1.5, textTransform: 'none' }}>
                        &nbsp;Cancel
                    </Button>
                    <Button variant="contained" onClick={handleOk} size="small" sx={{ borderRadius: '20px', padding: '0.25rem 2.6rem', textTransform: 'none', mr: 1 }} disabled={!note || note === undefined}>Ok</Button>
                </Box>
            </DialogActions>
        </Dialog>
    );
}

FormDialog.propTypes = {
    onClose: PropTypes.func.isRequired,
    open: PropTypes.bool.isRequired,
    nvalue: PropTypes.number,
    svalue: PropTypes.string,
    alertheader: PropTypes.string.isRequired,
    bmsgline1: PropTypes.string.isRequired,
    bmsgline2: PropTypes.string,
    bmsgline3: PropTypes.string,
    bmsgline4: PropTypes.string,
    bmsgline5: PropTypes.string,
};