import React, { useEffect, useState, useRef } from 'react';
import { useNavigate, useLocation } from "react-router-dom";
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import { Divider, Typography, InputBase, IconButton, Toolbar, Grid, Stack, FormControl, Box, FormGroup, FormControlLabel, InputLabel, Select, MenuItem, Paper } from '@mui/material';
import { ErrorMessages } from '../common/ErrorMessages';
import { CallAPI, IsError } from '../global/APIUtils';
import AlertWithTitle from '../components/Controls/AlertWithTitle';
import AlertControl from '../components/Controls/AlertControl';
import { APICall, WriteToActivityLog } from '../global/Api';
import { ActivityLogEvent } from '../common/AOBEnum';
import { Search } from 'react-bootstrap-icons';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import { tablePaginationClasses } from "@mui/material/TablePagination";
import { ConfirmDialog } from '../global/ConfirmDialog';
import AutoLagoutTimer from '../global/IdleMonitor';
import OverlaySpinner from './Controls/OverlaySpinner';
import PostLoginMenu from './PostLoginMenu';
import Container from '@mui/material/Container';
import EnrollmentHeader from './EnrollmentHeader';
import { DrawerAppBar } from './AppNavbar';
import Footer from './Footer';
import Checkbox, { checkboxClasses } from "@mui/material/Checkbox";

const DeleteCoreDataControl = (props) => {

    const location = useLocation();
    let navigate = useNavigate();

    const [data, setData] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [errorMsg, setErrorMsg] = useState('');
    const [successMsg, setSuccessMsg] = useState(null);
    const [successMsgTitle, setSuccessMsgTitle] = useState(null);
    const [searchText, setSearchText] = useState('');
    const [recordCount, setRecordCount] = React.useState(0);
    const [selected, setSelected] = React.useState([]);
    const [numSelected, setNumSelected] = useState(0);
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(25);
    const [openConfirmDlg, setOpenConfirmDlg] = useState(false);

    const [userId, setUserId] = useState(location.state ? location.state.userId : -1);
    const [sessionId, setSessionId] = useState(location.state ? location.state.sessionId : '');
    const [templateId, setTemplateId] = useState(location.state.templateid || -1);

    const loadForm = async () => {

        try {

            setIsLoading(true);

            let apiUrl = new URL(
                process.env.REACT_APP_GET_COREDATA_CONTROLS,
                process.env.REACT_APP_BASE_URL,
            );

            apiUrl.searchParams.set('searchText', `${searchText}`);

            let response = await APICall({ url: apiUrl.href, method: 'GET', contentType: 'application/json', responseType: '' }, userId, sessionId);

            if (response !== undefined && response !== null) {

                //const selCtrls = response.data.map((n) => n.FieldId);
                //setSelected(selCtrls);

                setRecordCount(response.data.length);

                setData(response.data);

                setPage(0);
            }
        }
        catch (err) {
            setErrorMsg(ErrorMessages.UnknownError);
        }
        finally {
            setIsLoading(false);
        }
    };

    const handleSearchTextChange = (event) => {
        setSearchText(event.target.value);
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value));
        setPage(0);
    };

    const isSelected = (FieldId) => selected.indexOf(FieldId) !== -1;

    const emptyRows =
        page > 0 ? Math.max(0, (1 + page) * rowsPerPage - data.length) : 0;

    const visibleRows = React.useMemo(
        () =>
            data && data.slice(
                page * rowsPerPage,
                page * rowsPerPage + rowsPerPage,
            ),
        [data, page, rowsPerPage],
    );

    const handleSelectAllClick = (event) => {
        if (event.target.checked) {
            const newSelected = data.map((n) => n.FieldId);
            setSelected(newSelected);
            return;
        }
        setSelected([]);
    };

    const handleClick = (event, FieldId) => {

        const selectedIndex = selected.indexOf(FieldId);
        let newSelected = [];

        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, FieldId);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                selected.slice(0, selectedIndex),
                selected.slice(selectedIndex + 1),
            );
        }
        setSelected(newSelected);
    };

    useEffect(() => {
        loadForm();
    }, []);

    const controlsHeadCells = [
        {
            id: '1',
            numeric: false,
            disablePadding: true,
            label: 'Caption',
        },
        {
            id: '2',
            numeric: false,
            disablePadding: false,
            label: 'Control Name',
        },
        {
            id: '3',
            numeric: false,
            disablePadding: false,
            label: 'Control Type',
        }
    ];

    function EnhancedTableToolbar(props) {
        const { numSelected } = props;

        return (
            <Toolbar
                sx={{
                    pl: { sm: 2 },
                    pr: { xs: 1, sm: 1 },
                }}
            >
                {numSelected > 0 ? (
                    <Stack direction="row" spacing={2}>
                        <Typography variant="body1">
                            <strong>{props.message}</strong>
                        </Typography>
                        <Typography variant="body1">
                            {numSelected} selected
                        </Typography>
                    </Stack>
                ) : (
                    <Typography
                        sx={{ flex: '1 1 100%' }}
                        variant="body1"
                        id="tableTitle"
                        component="div"
                    >
                        {props.message}
                    </Typography>
                )}
            </Toolbar>
        );
    }

    function EnhancedTableHead(props) {
        const { onSelectAllClick, numSelected, rowCount, headCells } = props;

        return (
            <TableHead>
                <TableRow>
                    <TableCell padding="checkbox">
                        <Checkbox sx={{
                            [`&, &.${checkboxClasses.checked}`]: {
                                color: 'Check.main',
                            },
                        }}
                            color="primary"
                            indeterminate={numSelected > 0 && numSelected < rowCount}
                            checked={rowCount > 0 && numSelected === rowCount}
                            onChange={onSelectAllClick}
                            inputProps={{
                                'aria-label': 'select all desserts',
                            }}
                        />
                    </TableCell>
                    {headCells.map((headCell) => (
                        <TableCell
                            key={headCell.id}
                            align={headCell.numeric ? 'right' : 'left'}
                            padding={headCell.disablePadding ? 'none' : 'normal'}
                        >
                            <Typography variant="body1" color="text.secondary" sx={{ fontWeight: 500 }} >{headCell.label}</Typography>
                        </TableCell>
                    ))}
                </TableRow>
            </TableHead>
        );
    }

    const FindClick = async (e) => {

        e.preventDefault();

        loadForm();
    };

    const navigateBack = () => {

        if (parseInt(templateId) == -1) {
            navigate('/TemplateDesigner', { state: { userId: userId, sessionId: sessionId }, replace: true });
        }
        else {
            navigate('/TemplateDesigner', { state: { userId: userId, sessionId: sessionId, templateid: templateId }, replace: true });
        }

    }

    const DeleteControls = async (e) => {

        e.preventDefault();
        setOpenConfirmDlg(true);
    }

    const handleConfirmDelDialogClose = async (nvalue, svalue) => {

        setOpenConfirmDlg(false);

        if (nvalue == null)
            return;

        if (selected.length == 0) {
            setErrorMsg("Please select one control for deletion");
            return;
        }

        let inputJson = {
            coreDataJSON: JSON.stringify(data ?? []),
            selectedJSON: JSON.stringify(selected ?? []),
        };

        let headers = {
            'Content-Type': 'application/json'
        };

        const apiUrl = new URL(
            process.env.REACT_APP_DELETE_COREDATA_CONTROLS,
            process.env.REACT_APP_BASE_URL,
        );

        try {
            setIsLoading(true);

            let response = await APICall({ url: apiUrl.href, method: 'POST', payload: inputJson, contentType: 'application/json', responseType: '' }, userId, sessionId);

            if (response !== null && response !== undefined) {

                if (response.meta.Updated == "Yes") {
                    await WriteToActivityLog(ActivityLogEvent.ProfileScreen, "Core data control(s) deleted.", userId, sessionId);
                    setSuccessMsg(nvalue + ' control' + (nvalue > 1 ? 's' : '') + ' deleted successfully');
                    loadForm();
                    setSelected([]);
                }
                else
                    setErrorMsg(ErrorMessages.UnknownError);
            }
        }
        catch (err) {
            setErrorMsg(ErrorMessages.UnknownError);
        }
        finally {
            setIsLoading(false);
        }
    }

    const BackClick = async (e) => {
        navigateBack();
    }

    return (

        <React.Fragment>
            <AutoLagoutTimer userId={userId} sessionId={sessionId} />
            <Container maxWidth="lg" sx={{}} className="" disableGutters={true}>
                <OverlaySpinner disappear={isLoading} />
                <Paper className="back-grid">
                    <PostLoginMenu userId={userId} sessionId={sessionId} />
                    <Grid item md={12} id="aobhead" >
                        <Box sx={{
                            pr: 0,
                            pl: 0,
                        }}>
                            <EnrollmentHeader />
                        </Box>
                    </Grid>
                    <Grid item sx={{ pr: 1 }}>
                        <DrawerAppBar userId={userId} sessionId={sessionId} />
                    </Grid>
                    <Grid item>
                        <Box sx={{ display: 'flex', pb: 2, pt: 0, ml: 6, mr: 6, justifyContent: 'space-between', alignItems: 'flex-end', borderBottom: '0px solid #898F94' }}>
                            <Typography variant="h6" color="text.secondary" sx={{ pl: 0, pb: 0, lineHeight: 1, fontWeight: 500, pb: 0, letterSpacing: '0px' }} >Delete Control From Control Panel</Typography>
                        </Box>
                        <Box sx={{ ml: 7, mr: 7, }}>
                            <Divider variant="fullWidth" component="div" sx={{ }} />
                        </Box>
                    </Grid>
                    <Grid item xs={12} sx={{ml: 6, mr: 6}}>
                        <Box sx={{ pt: 0 }}>
                            <AlertWithTitle Message={successMsg} variant="body1" severity="success" color="success" icon={true} Title={successMsgTitle} />
                            <AlertControl Message={errorMsg} severity="error" color="error" icon={true} />
                        </Box>
                    </Grid>
                    <Grid item xs={12} sx={{ ml: 6, mr: 6, pt: 2, pb: 3 }}>
                        <Box sx={{ pt: 0 }}>
                            <Stack direction="row" spacing={2}>
                                <Paper elevation={0}
                                    sx={{ display: 'flex', alignItems: 'center', width: 550, border: 1, borderColor: '#BFC3C5', }}
                                >
                                    <InputBase className="form-control" sx={{ color: '#000', boxShadow: 'none !important', border: 'none !important', padding: '0px 1rem !important', font: 'unset' }}
                                        placeholder="Search caption, control name" onChange={handleSearchTextChange}
                                    />
                                    <IconButton type="button" sx={{ p: '6px' }} aria-label="search" onClick={(e) => FindClick(e)}>
                                        <Search sx={{ color: '#0A0A0A' }} />
                                    </IconButton>
                                </Paper>
                            </Stack>
                        </Box>
                    </Grid>
                    <Grid item xs={12} sx={{ ml: 6, mr: 6 }}>
                        <Box sx={{ width: '100%', mt: 0 }}>
                            <Paper sx={{ width: '100%', mb: 2 }}>
                                <EnhancedTableToolbar numSelected={selected.length} message={'Controls'} />
                                <TableContainer sx={{ height: 400 }}>
                                    <Table
                                        sx={{ minWidth: 750, maxHeight: 400 }}
                                        aria-labelledby="tableTitle"
                                        size={'medium'}
                                    >
                                        <EnhancedTableHead headCells={controlsHeadCells}
                                            numSelected={selected.length}
                                            onSelectAllClick={handleSelectAllClick}
                                            rowCount={data !== null ? data.length : 0}
                                        />
                                        <TableBody>
                                            {visibleRows && visibleRows.map((row, index) => {
                                                const isItemSelected = isSelected(row.FieldId);
                                                const labelId = `enhanced-table-checkbox-${index}`;

                                                return (
                                                    <TableRow
                                                        hover
                                                        onClick={(event) => handleClick(event, row.FieldId)}
                                                        role="checkbox"
                                                        aria-checked={isItemSelected}
                                                        tabIndex={-1}
                                                        key={index}
                                                        selected={isItemSelected}
                                                        sx={{ cursor: 'pointer' }}
                                                    >
                                                        <TableCell padding="checkbox">
                                                            <Checkbox sx={{
                                                                [`&, &.${checkboxClasses.checked}`]: {
                                                                    color: 'Check.main',
                                                                },
                                                            }}
                                                                color="primary"
                                                                checked={isItemSelected}
                                                                inputProps={{
                                                                    'aria-labelledby': labelId,
                                                                }}
                                                            />
                                                        </TableCell>
                                                        <TableCell>
                                                            <Typography variant="body1" color="text.secondary" sx={{ fontWeight: 400 }} >{row.FieldLabel}</Typography>
                                                        </TableCell>
                                                        <TableCell>
                                                            <Typography variant="body1" color="text.secondary" sx={{ fontWeight: 400 }} >{row.FieldNm}</Typography>
                                                        </TableCell>
                                                        <TableCell>
                                                            <Typography variant="body1" color="text.secondary" sx={{ fontWeight: 400 }} >
                                                                {
                                                                    row.ControlType == 'checkbox-group' ? 'Checkbox Group' :
                                                                        row.ControlType == 'radio-group' ? 'Radio Group' :
                                                                            row.ControlType == 'select' ? 'Select' :
                                                                                row.ControlType == 'textarea' ? 'Text Area' :
                                                                                    row.ControlType == 'date' ? 'Date Field' :
                                                                                        row.ControlType == 'number' ? 'Number' : 'Text Field'
                                                                }
                                                            </Typography>
                                                        </TableCell>
                                                    </TableRow>
                                                );
                                            })}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                                <TablePagination
                                    rowsPerPageOptions={[25, 50, 75]}
                                    component="div"
                                    count={recordCount}
                                    rowsPerPage={rowsPerPage}
                                    page={page}
                                    onPageChange={handleChangePage}
                                    onRowsPerPageChange={handleChangeRowsPerPage}
                                    sx={{
                                        ".MuiTablePagination-selectLabel, .MuiTablePagination-toolbar": {
                                            paddingTop: "8px"
                                        },
                                        ".MuiTablePagination-displayedRows": {
                                            paddingTop: "8px", fontSize: '15px'
                                        },
                                    }}
                                    classes={{
                                        //toolbar: classes.toolbar,
                                    }}
                                />
                            </Paper>
                        </Box>
                    </Grid>
                    <Grid item sm={12} sx={{ml: 6, mr: 6, mb: 4}} >
                        <Box sx={{ display: 'flex', justifyContent: 'space-between', pt: 1 }}>
                            <Button onClick={(e) => BackClick(e)} variant="contained" size="large" sx={{ borderRadius: '20px', padding: '0.25rem 2.5rem', textTransform: 'none' }}>
                                Back
                            </Button>
                            <Button disabled={selected.length == 0} onClick={(e) => DeleteControls(e)} variant="contained" size="large" style={{ borderRadius: '20px', padding: '0.25rem 2.5rem', textTransform: 'none' }}>
                                Delete
                            </Button>
                        </Box>
                    </Grid>
                    <Footer userId={userId} />
                </Paper>
                <ConfirmDialog
                    keepMounted
                    open={openConfirmDlg}
                    onClose={handleConfirmDelDialogClose}
                    alertheader={'Delete control from control panel'}
                    bmsgline1={'Are you sure you want to delete ' + selected.length + ' control' + (selected.length < 2 ? '?' : 's? ')}
                    nvalue={selected.length}
                    svalue={''}
                />

            </Container>
        </React.Fragment>






        //<Dialog open={open} onClose={handleClose} keepMounted fullWidth maxWidth="md">
        //    <LoadingOverlay active={isLoading} spinner={<RiseLoader color={'#1D50AF'} />} >
        //        <DialogTitle>
        //            <Typography variant="body1" color="text.primary" sx={{ fontWeight: 500 }}>
        //                Delete Control From Control Panel
        //            </Typography>
        //        </DialogTitle>
        //        <DialogContent dividers>
        //            <Grid item xs container direction="row">
        //                <Grid item xs={12}>
        //                    <Box sx={{ pt: 0 }}>
        //                        <AlertWithTitle Message={successMsg} variant="body1" severity="success" color="success" icon={true} Title={successMsgTitle} />
        //                        <AlertControl Message={errorMsg} severity="error" color="error" icon={true} />
        //                    </Box>
        //                </Grid>
        //                <Grid item xs={12}>
        //                    <Box sx={{ pt: 0 }}>
        //                        <Stack direction="row" spacing={2}>
        //                            <Paper elevation={0}
        //                                sx={{ display: 'flex', alignItems: 'center', width: 500, border: 1, borderColor: '#BFC3C5', }}
        //                            >
        //                                <InputBase className="form-control" sx={{ boxShadow: 'none !important', border: 'none !important', padding: '0px 1rem !important', font: 'unset' }}
        //                                    placeholder="Search caption, control name" onChange={handleSearchTextChange}
        //                                />
        //                                <IconButton type="button" sx={{ p: '10px' }} aria-label="search" onClick={(e) => FindClick(e)}>
        //                                    <Search sx={{ color: '#333333' }} />
        //                                </IconButton>
        //                            </Paper>
        //                        </Stack>
        //                    </Box>
        //                </Grid>
        //                <Grid item xs={12}>
        //                    <Box sx={{ width: '100%', mt: 1 }}>
        //                        <Paper sx={{ width: '100%', mb: 2 }}>
        //                            <EnhancedTableToolbar numSelected={selected.length} message={'Controls'} />
        //                            <TableContainer sx={{ maxHeight: 400 }}>
        //                                <Table
        //                                    sx={{ minWidth: 750, maxHeight: 400 }}
        //                                    aria-labelledby="tableTitle"
        //                                    size={'medium'}
        //                                >
        //                                    <EnhancedTableHead headCells={controlsHeadCells}
        //                                        numSelected={selected.length}
        //                                        onSelectAllClick={handleSelectAllClick}
        //                                        rowCount={data !== null ? data.length : 0}
        //                                    />
        //                                    <TableBody>
        //                                        {visibleRows && visibleRows.map((row, index) => {
        //                                            const isItemSelected = isSelected(row.FieldId);
        //                                            const labelId = `enhanced-table-checkbox-${index}`;

        //                                            return (
        //                                                <TableRow
        //                                                    hover
        //                                                    onClick={(event) => handleClick(event, row.FieldId)}
        //                                                    role="checkbox"
        //                                                    aria-checked={isItemSelected}
        //                                                    tabIndex={-1}
        //                                                    key={index}
        //                                                    selected={isItemSelected}
        //                                                    sx={{ cursor: 'pointer' }}
        //                                                >
        //                                                    <TableCell padding="checkbox">
        //                                                        <Checkbox
        //                                                            color="primary"
        //                                                            checked={isItemSelected}
        //                                                            inputProps={{
        //                                                                'aria-labelledby': labelId,
        //                                                            }}
        //                                                        />
        //                                                    </TableCell>
        //                                                    <TableCell>
        //                                                        <Typography variant="body1" color="text.secondary" sx={{ fontWeight: 400 }} >{row.FieldLabel}</Typography>
        //                                                    </TableCell>
        //                                                    <TableCell>
        //                                                        <Typography variant="body1" color="text.secondary" sx={{ fontWeight: 400 }} >{row.FieldNm}</Typography>
        //                                                    </TableCell>
        //                                                    <TableCell>
        //                                                        <Typography variant="body1" color="text.secondary" sx={{ fontWeight: 400 }} >
        //                                                            {
        //                                                                row.ControlType == 'checkbox-group' ? 'Checkbox Group' :
        //                                                                    row.ControlType == 'radio-group' ? 'Radio Group' :
        //                                                                        row.ControlType == 'select' ? 'Select' :
        //                                                                            row.ControlType == 'textarea' ? 'Text Area' :
        //                                                                                row.ControlType == 'date' ? 'Date Field' :
        //                                                                                    row.ControlType == 'number' ? 'Number' : 'Text Field'
        //                                                            }
        //                                                        </Typography>
        //                                                    </TableCell>
        //                                                </TableRow>
        //                                            );
        //                                        })}
        //                                    </TableBody>
        //                                </Table>
        //                            </TableContainer>
        //                            <TablePagination
        //                                rowsPerPageOptions={[25, 50, 75]}
        //                                component="div"
        //                                count={recordCount}
        //                                rowsPerPage={rowsPerPage}
        //                                page={page}
        //                                onPageChange={handleChangePage}
        //                                onRowsPerPageChange={handleChangeRowsPerPage}
        //                                sx={{
        //                                    ".MuiTablePagination-selectLabel, .MuiTablePagination-toolbar": {
        //                                        paddingTop: "8px"
        //                                    },
        //                                    ".MuiTablePagination-displayedRows": {
        //                                        paddingTop: "8px", fontSize: '15px'
        //                                    },
        //                                }}
        //                                classes={{
        //                                    //toolbar: classes.toolbar,
        //                                }}
        //                            />
        //                        </Paper>
        //                    </Box>
        //                </Grid>
        //            {/*    <Grid item sm={12} sx={{}} >*/}
        //            {/*        <Box sx={{ pt: 1 }}>*/}
        //            {/*            <Button disabled={selected.length == 0} onClick={(e) => UpdateClick(e)} variant="contained" size="large" style={{ boxShadow: 'none', border: 0, borderRadius: '20px', padding: '0.4rem 3rem', outline: 'none', }}>*/}
        //            {/*                Delete*/}
        //            {/*            </Button>*/}
        //            {/*        </Box>*/}
        //            {/*    </Grid>*/}
        //            </Grid>
        //        </DialogContent>
        //        <DialogActions sx={{ mr: 2 }}>
        //            <Button onClick={handleClose}>
        //                <Typography variant="body3" sx={{ fontWeight: 500 }}>
        //                    Cancel
        //                </Typography>
        //            </Button>
        //            <Button disabled={selected.length == 0} onClick={(e) => DeleteControls(e)} >
        //                <Typography variant="body3" sx={{ fontWeight: 500 }}>
        //                    Delete
        //                </Typography>
        //            </Button>
        //        </DialogActions>
        //    </LoadingOverlay>
        //    <ConfirmDialog
        //        keepMounted
        //        open={openConfirmDlg}
        //        onClose={handleConfirmDelDialogClose}
        //        alertheader={'Delete control from control panel'}
        //        bmsgline1={'Are you sure you want to delete ' + selected.length + ' control' + (selected.length < 2 ? '?' : 's? ')}
        //        nvalue={selected.length}
        //        svalue={'kk'}
        //    />
        //</Dialog>
    );
}

export default DeleteCoreDataControl;