import React, { useState, useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { useIdleTimer } from 'react-idle-timer'
import Snackbar from '@mui/material/Snackbar';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { Typography, Stack, Toolbar, Box, Divider } from '@mui/material';
import AppBar from '@mui/material/AppBar';
import Modal from '@mui/material/Modal';

const AutoLagoutTimer = (props) => {

    const [open, setOpen] = React.useState(false);
    const [remaining, setRemaining] = useState(0);
    const [userId, setUserId] = useState(props.userId);
    const [sessionId, setSessionId] = useState(props.sessionId);

    const SESSION_IDLE_MINUTES = 20 * 1000 * 60;
    const SESSION_IDLE_WARNING_MINUTES = 5 * 1000 * 60;

    let navigate = useNavigate();

    const handleStayLogin = () => {
        activate();
        setOpen(false);
    };

    const handleLogoff = () => {
        setOpen(false);
        navigate('/AppLogout', { state: { userId: userId, sessionId: sessionId }, replace: true });
    };

    const handleClose = () => {
        setOpen(false);
    };

    const onActive = () => {
        activate();
        setOpen(false);
    }

    const handleOnIdle = (event) => {
        navigate('/AppLogout/?reason=SESSION_NOTFOUND', { state: { userId: userId, sessionId: sessionId }, replace: true });
    }

    const handleOnPrompt = () => {
        setOpen(true)
    }
    const { getLastActiveTime, getRemainingTime, activate } = useIdleTimer({
        timeout: 20 * 1000 * 60,
        onIdle: handleOnIdle,
        onPrompt: handleOnPrompt,
        events: [
            'mousemove',
            'keydown',
            'wheel',
            'DOMMouseScroll',
            'mousewheel',
            'mousedown',
            'touchstart',
            'touchmove',
            'MSPointerDown',
            'MSPointerMove',
            'visibilitychange',
            'focus'
        ],
        debounce: 0,
        throttle: 500,
        promptBeforeIdle: 5 * 1000 * 60,
        eventsThrottle: 200,
        element: document,
        name: 'idle-timer',
        startOnMount: true,
    })

    useEffect(() => {
        const interval = setInterval(() => {
            setRemaining(Math.ceil(getRemainingTime() / 1000))
        }, 500)

        return () => {
            clearInterval(interval)
        }
    })

    const action = (
        <React.Fragment>
            <Button color="secondary" size="small" onClick={handleClose}>
                UNDO
            </Button>
            <IconButton
                size="small"
                aria-label="close"
                color="inherit"
                onClick={handleClose}
            >
                <CloseIcon fontSize="small" />
            </IconButton>
        </React.Fragment>
    );

    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 400,
        bgcolor: 'background.paper',
        border: '2px solid #000',
        boxShadow: 24,
        p: 4,
        zIndex: '15000'
    };

    return (
        <React.Fragment >

            {/*<Snackbar*/}
            {/*    open={open}*/}
            {/*    autoHideDuration={306000}*/}
            {/*    onClose={handleClose}*/}
            {/*    message="Note archived"*/}
            {/*    action={action}*/}
            {/*/>*/}

            <Modal keepMounted
                open={open}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                sx={{ zIndex: '10000' }}
            >
                <Box sx={style}>
                    <Stack direction="column">
                        <Typography variant="h6" color="#696969" sx={{pb: 1, fontWeight: 500}} >
                            Session Timeout
                        </Typography>
                        <Typography variant="body3" color="#696969" sx={{ pb: 2 }} >
                            You will automatically be logged off in {remaining} seconds.
                        </Typography>
                    </Stack>
                    <Divider />
                    <Box sx={{pt: 1}}>
                        <Button sx={{color: '#002d72'}} onClick={handleLogoff}>Log off</Button>
                        <Button onClick={handleStayLogin} autoFocus>
                            Stay logged on
                        </Button>
                    </Box>
                </Box>
            </Modal>

            {/*<Dialog keepMounted*/}
            {/*    open={open} */}
            {/*    aria-labelledby="alert-dialog-title"*/}
            {/*    aria-describedby="alert-dialog-description"*/}
            {/*>*/}
                {/*<AppBar sx={{ position: 'relative', background: '#fff' }}>*/}
                {/*    <Toolbar>*/}
                {/*        <IconButton*/}
                {/*            edge="start"*/}
                {/*            color="inherit"*/}
                {/*            onClick={handleClose}*/}
                {/*            aria-label="close"*/}
                {/*        >*/}
                {/*            <CloseIcon />*/}
                {/*        </IconButton>*/}
                {/*        <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div" color="text.primary">*/}
                {/*            You Are About To Be Logged Out*/}
                {/*        </Typography>*/}
                {/*    </Toolbar>*/}
                {/*</AppBar>*/}

                

            {/*    <DialogTitle id="alert-dialog-title">*/}
            {/*        {"You Are About To Be Logged Out"}*/}
            {/*    </DialogTitle>*/}
            {/*    <DialogContent dividers>*/}
            {/*        <DialogContentText id="alert-dialog-description">*/}
            {/*            <Stack direction="column">*/}
            {/*                <Typography variant="h6" color="#696969" sx={{pb: 1, fontWeight: 500}} >*/}
            {/*                    Session Timeout*/}
            {/*                </Typography>*/}
            {/*                <Typography variant="body3" color="#696969" sx={{ pb: 2 }} >*/}
            {/*                    You will automatically be logged off in {remaining} seconds.*/}
            {/*                </Typography>*/}
            {/*            </Stack>                        */}
            {/*        </DialogContentText>*/}
            {/*    </DialogContent>*/}
            {/*    <DialogActions>*/}
            {/*        <Button onClick={handleLogoff}>Log off</Button>*/}
            {/*        <Button onClick={handleStayLogin} autoFocus>*/}
            {/*            Stay logged on*/}
            {/*        </Button>*/}
            {/*    </DialogActions>*/}
            {/*</Dialog>*/}
        </React.Fragment >

    );
}

export default AutoLagoutTimer;

//const IdleMonitor = () => {
//    const [idleModal, setIdleModal] = useState(false);

//    let idleTimeout = 1000 * 60 * 1;  //1 minute
//    let idleLogout = 1000 * 60 * 2; //2 Minutes
//    let idleEvent;
//    let idleLogoutEvent;

//    const events = [
//        'click',
//        'keypress',
//        'scroll',
//        'load',
//        'keydown'
//    ];


//    const sessionTimeout = () => {
//        if (!!idleEvent) clearTimeout(idleEvent);
//        if (!!idleLogoutEvent) clearTimeout(idleLogoutEvent);

//        idleEvent = setTimeout(() => setIdleModal(true), idleTimeout); //show session warning modal.
//        idleLogoutEvent = setTimeout(() => logOut, idleLogout); //Call logged out on session expire.
//    };


//    const extendSession = () => {
//        console.log('user wants to stay logged in');
//    }


//    const logOut = () => {
//        console.log('logging out');
//    }

//    useEffect(() => {
//        for (let e in events) {
//            window.addEventListener(events[e], sessionTimeout);
//        }

//        return () => {
//            for (let e in events) {
//                window.removeEventListener(events[e], sessionTimeout);
//            }
//        }
//    }, []);


//    return (

//        <Modal isOpen={idleModal} toggle={() => setIdleModal(false)}>
//            <ModalHeader toggle={() => setIdleModal(false)}>
//                Session expire warning
//            </ModalHeader>
//            <ModalBody>
//                your session will expire in {idleLogout / 60 / 1000} minutes. Do you want to extend the session?
//            </ModalBody>
//            <ModalFooter>
//                <button className="btn btn-info" onClick={() => logOut()}>Logout</button>
//                <button className="btn btn-success" onClick={() => extendSession()}>Extend session</button>

//            </ModalFooter>
//        </Modal>
//    )

//}

//export default IdleMonitor;
