import React, { useCallback, useState, useEffect, useMemo, useRef, } from 'react';
import { useNavigate, useLocation } from "react-router-dom";
import PostLoginMenu from './PostLoginMenu';
import OverlaySpinner from './Controls/OverlaySpinner';
import { QueryClient, QueryClientProvider, useInfiniteQuery, } from '@tanstack/react-query';
import { useSelector, useDispatch } from 'react-redux';
import { Virtualizer } from '@tanstack/react-virtual';
import { Grid, Paper, Box, Typography, Button, Stack, IconButton, Tooltip, Divider, FormControlLabel, FormControl, InputBase } from '@mui/material';
import Container from '@mui/material/Container';
import EnrollmentHeader from './EnrollmentHeader';
import { DrawerAppBar } from './AppNavbar';
import MaterialReactTable from 'material-react-table';
import { ErrorMessages } from '../common/ErrorMessages';
import AlertWithTitle from './Controls/AlertWithTitle';
import AlertControl from './Controls/AlertControl';
import { APICall, WriteToActivityLog } from '../global/Api';
import AutoLagoutTimer from '../global/IdleMonitor';
import Footer from './Footer';
import { ActivityLogEvent } from '../common/AOBEnum';
import { makeStyles } from "@material-ui/core/styles";
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { IsError } from '../global/APIUtils';
import Toolbar from '@mui/material/Toolbar';
import { alpha } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import { tablePaginationClasses } from "@mui/material/TablePagination";
import { Search } from 'react-bootstrap-icons';
import { goToTop } from '../global/Helpers'
import { ConfirmDialog } from '../global/ConfirmDialog';
import Checkbox, { checkboxClasses } from "@mui/material/Checkbox";

const useStyles = makeStyles({
    toolbar: {
        "& > p:nth-of-type(2)": {
            fontSize: "25px",
            //color: "red",
            //fontWeight: 600
        }
    }
});

const controlsHeadCells = [
    {
        id: '1',
        numeric: false,
        disablePadding: true,
        label: 'Change Type',
    },
    {
        id: '2',
        numeric: false,
        disablePadding: false,
        label: 'Label',
    },
    {
        id: '3',
        numeric: false,
        disablePadding: false,
        label: 'Control Type',
    },
    {
        id: '4',
        numeric: false,
        disablePadding: false,
        label: 'Control Name',
    },
    {
        id: '5',
        numeric: false,
        disablePadding: false,
        label: 'Action',
    },
];

const lookupsHeadCells = [
    {
        id: '1',
        numeric: false,
        disablePadding: true,
        label: 'Change Type',
    },
    {
        id: '2',
        numeric: false,
        disablePadding: false,
        label: 'Label',
    },
    {
        id: '3',
        numeric: false,
        disablePadding: false,
        label: 'Control Type',
    },
    {
        id: '4',
        numeric: false,
        disablePadding: false,
        label: 'Lookup ID',
    },
    {
        id: '5',
        numeric: false,
        disablePadding: false,
        label: 'Lookup Value',
    },
    {
        id: '6',
        numeric: false,
        disablePadding: false,
        label: 'Control Name',
    },
    {
        id: '7',
        numeric: false,
        disablePadding: false,
        label: 'Action',
    },
];

function EnhancedTableHead(props) {
    const { onSelectAllClick, numSelected, rowCount, headCells } = props;

    return (
        <TableHead>
            <TableRow>
                <TableCell padding="checkbox">
                    <Checkbox
                        color="primary"
                        sx={{
                            [`&, &.${checkboxClasses.checked}`]: {
                                color: 'Check.main',
                            },
                        }}
                        indeterminate={numSelected > 0 && numSelected < rowCount}
                        checked={rowCount > 0 && numSelected === rowCount}
                        onChange={onSelectAllClick}
                        inputProps={{
                            'aria-label': 'select all desserts',
                        }}
                    />
                </TableCell>
                {headCells.map((headCell) => (
                    <TableCell
                        key={headCell.id}
                        align={headCell.numeric ? 'right' : 'left'}
                        padding={headCell.disablePadding ? 'none' : 'normal'}
                    >
                        <Typography variant="body2" color="text.secondary" sx={{fontWeight: 500 }} >{headCell.label}</Typography>
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    );
}

function EnhancedTableToolbar(props) {
    const { numSelected } = props;

    return (
        <Toolbar
            sx={{
                pl: { sm: 2 },
                pr: { xs: 1, sm: 1 },
            }}
        >
            {numSelected > 0 ? (
                <Stack direction="row" spacing={2}>
                    <Typography variant="body1">
                        <strong>{props.message}</strong>
                    </Typography>
                    <Typography variant="body1">
                        {numSelected} selected
                    </Typography>
                </Stack>
            ) : (
                <Typography
                    sx={{ flex: '1 1 100%' }}
                    variant="body1"
                    id="tableTitle"
                    component="div"
                >
                        {props.message}
                </Typography>
            )}
        </Toolbar>
    );
}

const UpdatedCoreDataList = (props) => {

    const [data, setData] = useState(null);
    const [recordCount, setRecordCount] = React.useState(0);
    const [selected, setSelected] = React.useState([]);

    const [errorMsg, setErrorMsg] = useState('');
    const [infoMsg, setInfoMsg] = useState('');
    const [numSelected, setNumSelected] = useState(0);
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(25);
    const [searchText, setSearchText] = useState('');

    const [newChecked, setNewChecked] = useState(true);
    const [updatedChecked, setUpdatedChecked] = useState(true);
    const [deletedChecked, setDeletedChecked] = useState(true);
    const [openConfirmDlg, setOpenConfirmDlg] = useState(false);

    const [userId, setUserId] = useState(props.userId || -1);
    const [sessionId, setSessionId] = useState(props.sessionId || '');

    const loadForm = async (isNewChecked, isUpdateChecked, isDeleteChecked) => {

        try {

            props.loadingChange(true);
            setErrorMsg('');

            await WriteToActivityLog(ActivityLogEvent.Template, "Refresh template designer - Controls tab", userId, sessionId);

            const apiUrl = new URL(
                process.env.REACT_APP_REFRESH_TEMPLATE_DESIGNER_GWESCONTROLS,
                process.env.REACT_APP_BASE_URL,
            );

            apiUrl.searchParams.set('searchText', `${searchText}`);
            apiUrl.searchParams.set('isNewChecked', `${isNewChecked}`);
            apiUrl.searchParams.set('isUpdateChecked', `${isUpdateChecked}`);
            apiUrl.searchParams.set('isDeleteChecked', `${isDeleteChecked}`);

            let response = await APICall({ url: apiUrl.href, method: 'GET', contentType: 'application/json', responseType: '' }, userId, sessionId);

            if (response !== null && response !== undefined) {

                let error = IsError(response);

                if (error != 0) {
                    setErrorMsg(response.Errors[0].Message);
                    return false;
                }

                const selCtrls = response.data.map((n) => n.ControlNm);
                setSelected(selCtrls);

                setRecordCount(response.data.length);

                setData(response.data);
                setPage(0);
            }
        }
        catch (err) {
            setErrorMsg(ErrorMessages.UnknownError);
        }
        finally {
            props.loadingChange(false);
        }
    };
    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value));
        setPage(0);
    };

    const isSelected = (ctrlNm) => selected.indexOf(ctrlNm) !== -1;

    const emptyRows =
        page > 0 ? Math.max(0, (1 + page) * rowsPerPage - data.length) : 0;

    const visibleRows = React.useMemo(
        () =>
            data && data.slice(
                page * rowsPerPage,
                page * rowsPerPage + rowsPerPage,
            ),
        [data, page, rowsPerPage],
    );

    const handleSelectAllClick = (event) => {
        if (event.target.checked) {
            const newSelected = data.map((n) => n.ControlNm);
            setSelected(newSelected);
            return;
        }
        setSelected([]);
    };

    const handleClick = (event, ctrlNm) => {

        const selectedIndex = selected.indexOf(ctrlNm);
        let newSelected = [];

        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, ctrlNm);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                selected.slice(0, selectedIndex),
                selected.slice(selectedIndex + 1),
            );
        }
        setSelected(newSelected);
    };

    const handleSearchTextChange = (event) => {
        setSearchText(event.target.value);
    };

    useEffect(() => {

        loadForm(newChecked, updatedChecked, deletedChecked);
    }, []);

    const FindClick = async (e) => {

        e.preventDefault();
        loadForm(newChecked, updatedChecked, deletedChecked);
    }

    const handleConfirmDelDialogClose = async (nvalue, svalue) => {

        setOpenConfirmDlg(false);

        if (nvalue == null)
            return;

        if (selected.length == 0) {
            setErrorMsg("Please select atleast one item for update");
            return;
        }

        let inputJson = {
            coreDataJSON: JSON.stringify(data ?? []),
            selectedJSON: JSON.stringify(selected ?? []),
        };

        let headers = {
            'Content-Type': 'application/json'
        };

        const apiUrl = new URL(
            process.env.REACT_APP_REFRESH_TEMPLATE_UPDATE_COREDATA,
            process.env.REACT_APP_BASE_URL,
        );

        try {
            props.loadingChange(true);

            let response = await APICall({ url: apiUrl.href, method: 'POST', payload: inputJson, contentType: 'application/json', responseType: '' }, userId, sessionId);

            if (response !== null && response !== undefined) {

                if (response.meta.Updated == "Yes") {
                    loadForm(newChecked, updatedChecked, deletedChecked);
                    await WriteToActivityLog(ActivityLogEvent.ProfileScreen, "Refresh template designer - Control(s) refreshed.", userId, sessionId);
                    setInfoMsg("Your data has been successfully saved.");
                    goToTop();
                }
                else
                    setErrorMsg(ErrorMessages.UnknownError);
            }
        }
        catch (err) {
            setErrorMsg(ErrorMessages.UnknownError);
        }
        finally {
            props.loadingChange(false);
        }
    }

    const UpdateClick = async (e) => {

        e.preventDefault();
        setOpenConfirmDlg(true);
    }

    const handleNewCheckChange = (event) => {
        setNewChecked(event.target.checked);
        loadForm(event.target.checked, updatedChecked, deletedChecked);
    };

    const handleUpdatedCheckChange = (event) => {
        setUpdatedChecked(event.target.checked);
        loadForm(newChecked, event.target.checked, deletedChecked);
    };

    const handleDeletedCheckChange = (event) => {
        setDeletedChecked(event.target.checked);
        loadForm(newChecked, updatedChecked, event.target.checked);
    };

    return (
        <React.Fragment>
            <Grid item xs container direction="row">
                <Grid item xs={12} sx={{ mt: 1, mb: 0, textAlign: 'center', }}>
                    <Box sx={{  }}>
                        <AlertControl Message={errorMsg} severity="error" color="error" icon={true} />
                        <AlertControl Message={infoMsg} severity="success" color="success" icon={true} />
                    </Box>
                </Grid>
                <Grid item xs={12}>
                    <Box sx={{ pt: 1 }}>
                        <Stack direction="row" spacing={2}>
                            <Paper elevation={0}
                                sx={{ display: 'flex', alignItems: 'center', width: 500, border: 1, borderColor: '#BFC3C5', }}
                            >
                                <InputBase className="form-control" sx={{ color: '#000', boxShadow: 'none !important', border: 'none !important', padding: '0px 1rem !important', font: 'unset' }}
                                    placeholder="Search control name, label" onChange={handleSearchTextChange}
                                />
                                <IconButton type="button" sx={{ p: '10px' }} aria-label="search" onClick={(e) => FindClick(e)}>
                                    <Search sx={{ color: '#333333' }} />
                                </IconButton>
                            </Paper>
                            <Box sx={{pl: 2}}>
                                <FormControl variant="outlined" sx={{ width: '100%' }} >
                                    <FormControlLabel color="text.primary" control={<Checkbox sx={{
                                        [`&, &.${checkboxClasses.checked}`]: {
                                            color: 'Check.main',
                                        },
                                    }} checked={newChecked} onChange={handleNewCheckChange} />} label={<Typography sx={{ fontWeight: 400 }} variant="body1" color="text.primary">New</Typography>} />
                                </FormControl>
                            </Box>
                            <Box sx={{}}>
                                <FormControl variant="outlined" sx={{ width: '100%' }} >
                                    <FormControlLabel color="text.primary" control={<Checkbox sx={{
                                        [`&, &.${checkboxClasses.checked}`]: {
                                            color: 'Check.main',
                                        },
                                    }} checked={updatedChecked} onChange={handleUpdatedCheckChange} />} label={<Typography sx={{ fontWeight: 400 }} variant="body1" color="text.primary">Label Updated</Typography>} />
                                </FormControl>
                            </Box>
                            <Box sx={{}}>
                                <FormControl variant="outlined" sx={{ width: '100%' }} >
                                    <FormControlLabel color="text.primary" control={<Checkbox sx={{
                                        [`&, &.${checkboxClasses.checked}`]: {
                                            color: 'Check.main',
                                        },
                                    }} checked={deletedChecked} onChange={handleDeletedCheckChange} />} label={<Typography sx={{ fontWeight: 400 }} variant="body1" color="text.primary">Deleted</Typography>} />
                                </FormControl>
                            </Box>
                        </Stack>
                    </Box>
                </Grid>
                <Grid item xs={12}>
                    <Box sx={{ width: '100%' }}>
                        <Paper sx={{ width: '100%', mb: 2 }}>
                            <EnhancedTableToolbar numSelected={selected.length} message={'Unsynced controls' } />
                            <TableContainer>
                                <Table
                                    sx={{ minWidth: 750 }}
                                    aria-labelledby="tableTitle"
                                    size={'medium'}
                                >
                                    <EnhancedTableHead headCells={controlsHeadCells}
                                        numSelected={selected.length}
                                        onSelectAllClick={handleSelectAllClick}
                                        rowCount={data !== null ? data.length : 0}
                                    />
                                    <TableBody>
                                        {visibleRows && visibleRows.map((row, index) => {
                                            const isItemSelected = isSelected(row.ControlNm);
                                            const labelId = `enhanced-table-checkbox-${index}`;

                                            return (
                                                <TableRow
                                                    hover
                                                    onClick={(event) => handleClick(event, row.ControlNm)}
                                                    role="checkbox"
                                                    aria-checked={isItemSelected}
                                                    tabIndex={-1}
                                                    key={index}
                                                    selected={isItemSelected}
                                                    sx={{ cursor: 'pointer' }}
                                                >
                                                    <TableCell padding="checkbox">
                                                        <Checkbox sx={{
                                                            [`&, &.${checkboxClasses.checked}`]: {
                                                                color: 'Check.main',
                                                            },
                                                        }}
                                                            color="primary"
                                                            checked={isItemSelected}
                                                            inputProps={{
                                                                'aria-labelledby': labelId,
                                                            }}
                                                        />
                                                    </TableCell>
                                                    <TableCell sx={{width: 100}}>
                                                        <Typography variant="body1" color="text.secondary" sx={{ fontWeight: 400 }} >{row.ChangeType}</Typography>
                                                    </TableCell>
                                                    <TableCell>
                                                        <Typography variant="body1" color="text.secondary" sx={{ fontWeight: 400 }} >{row.FieldLabel}</Typography>
                                                    </TableCell>
                                                    <TableCell sx={{ width: 150 }}>
                                                        <Typography variant="body1" color="text.secondary" sx={{ fontWeight: 400 }} >
                                                            {
                                                                row.ControlType == 'Checkbox' ? 'Checkbox Group' :
                                                                    row.ControlType == 'RadioButton' ? 'Radio Group' :
                                                                        row.ControlType == 'Select' ? 'Select' :
                                                                            row.ControlType == 'TextArea' ? 'Text Area' :
                                                                                row.ControlType == '' && row.DataType == 'D' ? 'Date Field' :
                                                                                    row.ControlType == '' && row.DataType == 'N' ? 'Number' :
                                                                                        row.ControlType == 'CheckboxList' ? 'Checkbox Group' : 'Text Field'
                                                            }
                                                        </Typography>
                                                    </TableCell>
                                                    <TableCell sx={{ width: 150 }} >
                                                        <Typography variant="body1" color="text.secondary" sx={{ fontWeight: 400 }} >{row.ControlNm}</Typography>
                                                    </TableCell>
                                                    <TableCell>
                                                        <Typography variant="body1" color="text.secondary" sx={{ fontWeight: 400 }} >
                                                            {
                                                                row.ChangeType === 'New' ? 'Add in the Control Panel' :
                                                                    row.ChangeType === 'Deleted' ? 'Remove from Control Panel' :
                                                                        'Update Label of the Control'
                                                            }
                                                        </Typography>
                                                    </TableCell>                                                    
                                                </TableRow>
                                            );
                                        })}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                            <TablePagination
                                rowsPerPageOptions={[25, 50, 75]}
                                component="div"
                                count={recordCount}
                                rowsPerPage={rowsPerPage}
                                page={page}
                                onPageChange={handleChangePage}
                                onRowsPerPageChange={handleChangeRowsPerPage}
                                sx={{
                                    ".MuiTablePagination-selectLabel, .MuiTablePagination-toolbar": {
                                        paddingTop: "8px"
                                    },
                                    ".MuiTablePagination-displayedRows": {
                                        paddingTop: "8px", fontSize: '15px'
                                    },
                                }}
                                classes={{
                                    //toolbar: classes.toolbar,
                                }}
                            />
                        </Paper>
                    </Box>
                </Grid>
                <Grid item sm={12} sx={{pb: 4}} >
                    <Box sx={{ pt: 1 }}>
                        <Button disabled={selected.length == 0} onClick={(e) => UpdateClick(e)} variant="contained" size="large" style={{ boxShadow: 'none', border: 0, borderRadius: '20px', padding: '0.4rem 3rem', outline: 'none', }}>
                            UPDATE
                        </Button>
                    </Box>
                </Grid>
            </Grid>
            <ConfirmDialog
                keepMounted
                open={openConfirmDlg}
                onClose={handleConfirmDelDialogClose}
                alertheader={'Update control panel of the designer'}
                bmsgline1={'Are you sure you want to update control panel of the designer with ' + selected.length + ' item' + (selected.length < 2 ? '?' : 's? ')}
                nvalue={selected.length}
                svalue={''}
            />
        </React.Fragment>
    );
}

const UpdatedCoreDataLookup = (props) => {

    const [data, setData] = useState(null);
    const [recordCount, setRecordCount] = React.useState(0);
    const [selected, setSelected] = React.useState([]);

    const [errorMsg, setErrorMsg] = useState('');
    const [infoMsg, setInfoMsg] = useState('');
    const [numSelected, setNumSelected] = useState(0);
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(25);
    const [searchText, setSearchText] = useState('');

    const [newChecked, setNewChecked] = useState(true);
    const [updatedChecked, setUpdatedChecked] = useState(true);
    const [deletedChecked, setDeletedChecked] = useState(true);

    const [openConfirmDlg, setOpenConfirmDlg] = useState(false);

    const [userId, setUserId] = useState(props.userId || -1);
    const [sessionId, setSessionId] = useState(props.sessionId || '');

    const loadForm = async (isNewChecked, isUpdateChecked, isDeleteChecked) => {

        try {

            props.loadingChange(true);

            await WriteToActivityLog(ActivityLogEvent.Template, "Refresh template designer - Lookup values tab", userId, sessionId);

            const apiUrl = new URL(
                process.env.REACT_APP_REFRESH_TEMPLATE_DESIGNER_GWESCOREDATA_LOOKUP,
                process.env.REACT_APP_BASE_URL,
            );

            apiUrl.searchParams.set('searchText', `${searchText}`);
            apiUrl.searchParams.set('isNewChecked', `${isNewChecked}`);
            apiUrl.searchParams.set('isUpdateChecked', `${isUpdateChecked}`);
            apiUrl.searchParams.set('isDeleteChecked', `${isDeleteChecked}`);

            let response = await APICall({ url: apiUrl.href, method: 'GET', contentType: 'application/json', responseType: '' }, userId, sessionId);

            if (response !== null && response !== undefined) {

                let error = IsError(response);

                if (error != 0) {
                    setErrorMsg(response.Errors[0].Message);
                    return false;
                }

                const selCtrls = response.data.map((n) => n.LookuoUniqueId);
                setSelected(selCtrls);

                setRecordCount(response.data.length);

                setData(response.data);
                setPage(0);
            }
        }
        catch (err) {
            setErrorMsg(ErrorMessages.UnknownError);
        }
        finally {
            props.loadingChange(false);
        }
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value));
        setPage(0);
    };

    const isSelected = (LookuoUniqueId) => selected.indexOf(LookuoUniqueId) !== -1;

    const emptyRows =
        page > 0 ? Math.max(0, (1 + page) * rowsPerPage - data.length) : 0;

    const visibleRows = React.useMemo(
        () =>
            data && data.slice(
                page * rowsPerPage,
                page * rowsPerPage + rowsPerPage,
            ),
        [data, page, rowsPerPage],
    );

    const handleSelectAllClick = (event) => {
        if (event.target.checked) {
            const newSelected = data.map((n) => n.LookuoUniqueId);
            setSelected(newSelected);
            return;
        }
        setSelected([]);
    };

    const handleClick = (event, LookuoUniqueId) => {

        const selectedIndex = selected.indexOf(LookuoUniqueId);
        let newSelected = [];

        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, LookuoUniqueId);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                selected.slice(0, selectedIndex),
                selected.slice(selectedIndex + 1),
            );
        }
        setSelected(newSelected);
    };

    useEffect(() => {
        loadForm(newChecked, updatedChecked, deletedChecked);
    }, []);

    const handleSearchTextChange = (event) => {
        setSearchText(event.target.value);
    };

    const FindClick = async (e) => {

        e.preventDefault();

        loadForm(newChecked, updatedChecked, deletedChecked);
    };

    const handleNewCheckChange = (event) => {
        setNewChecked(event.target.checked);
        loadForm(event.target.checked, updatedChecked, deletedChecked);
    };

    const handleUpdatedCheckChange = (event) => {
        setUpdatedChecked(event.target.checked);
        loadForm(newChecked, event.target.checked, deletedChecked);
    };

    const handleDeletedCheckChange = (event) => {
        setDeletedChecked(event.target.checked);
        loadForm(newChecked, updatedChecked, event.target.checked);
    };

    const handleConfirmDelDialogClose = async (nvalue, svalue) => {

        setOpenConfirmDlg(false);

        if (nvalue == null)
            return;

        if (selected.length == 0) {
            setErrorMsg("Please select atleast one item for update");
            return;
        }

        let inputJson = {
            coreDataLookupJSON: JSON.stringify(data ?? []),
            selectedJSON: JSON.stringify(selected ?? []),
        };

        let headers = {
            'Content-Type': 'application/json'
        };

        const apiUrl = new URL(
            process.env.REACT_APP_REFRESH_TEMPLATE_UPDATE_COREDATA_LOOKUP,
            process.env.REACT_APP_BASE_URL,
        );

        try {
            props.loadingChange(true);

            let response = await APICall({ url: apiUrl.href, method: 'POST', payload: inputJson, contentType: 'application/json', responseType: '' }, userId, sessionId);

            if (response !== null && response !== undefined) {

                if (response.meta.Updated == "Yes") {
                    await loadForm(newChecked, updatedChecked, deletedChecked);
                    await WriteToActivityLog(ActivityLogEvent.ProfileScreen, "Refresh template designer - Lookup value(s) refreshed.", userId, sessionId);
                    setInfoMsg("Your data has been successfully saved.");
                    goToTop();
                }
                else
                    setErrorMsg(ErrorMessages.UnknownError);
            }
        }
        catch (err) {
            setErrorMsg(ErrorMessages.UnknownError);
        }
        finally {
            props.loadingChange(false);
        }
    }

    const UpdateClick = async (e) => {

        e.preventDefault();
        setOpenConfirmDlg(true);
    }

    return (
        <React.Fragment>
            <Grid item xs container direction="row">
                <Grid item xs={12} sx={{ mt: 1, mb: 0, textAlign: 'center', }}>
                    <Box sx={{  }}>
                        <AlertControl Message={errorMsg} severity="error" color="error" icon={true} />
                        <AlertControl Message={infoMsg} severity="success" color="success" icon={true} />
                    </Box>
                </Grid>
                <Grid item xs={12}>
                    <Box sx={{ pt: 1 }}>
                        <Stack direction="row" spacing={2}>
                            <Paper elevation={0}
                                sx={{ display: 'flex', alignItems: 'center', width: 500, border: 1, borderColor: '#BFC3C5', }}
                            >
                                <InputBase className="form-control" sx={{ color: '#000', boxShadow: 'none !important', border: 'none !important', padding: '0px 1rem !important', font: 'unset' }}
                                    placeholder="Search control name, label, lookup value" onChange={handleSearchTextChange}
                                />
                                <IconButton type="button" sx={{ p: '10px' }} aria-label="search" onClick={(e) => FindClick(e)}>
                                    <Search sx={{ color: '#333333' }} />
                                </IconButton>
                            </Paper>
                            <Box sx={{pl: 2}}>
                                <FormControl variant="outlined" sx={{ width: '100%' }} >
                                    <FormControlLabel color="text.primary" control={<Checkbox sx={{
                                        [`&, &.${checkboxClasses.checked}`]: {
                                            color: 'Check.main',
                                        },
                                    }} checked={newChecked} onChange={handleNewCheckChange} />} label={<Typography sx={{ fontWeight: 400 }} variant="body1" color="text.primary">New</Typography>} />
                                </FormControl>
                            </Box>
                            <Box sx={{}}>
                                <FormControl variant="outlined" sx={{ width: '100%' }} >
                                    <FormControlLabel color="text.primary" control={<Checkbox sx={{
                                        [`&, &.${checkboxClasses.checked}`]: {
                                            color: 'Check.main',
                                        },
                                    }} checked={updatedChecked} onChange={handleUpdatedCheckChange} />} label={<Typography sx={{ fontWeight: 400 }} variant="body1" color="text.primary">Label Updated</Typography>} />
                                </FormControl>
                            </Box>
                            <Box sx={{}}>
                                <FormControl variant="outlined" sx={{ width: '100%' }} >
                                    <FormControlLabel color="text.primary" control={<Checkbox sx={{
                                        [`&, &.${checkboxClasses.checked}`]: {
                                            color: 'Check.main',
                                        },
                                    }} checked={deletedChecked} onChange={handleDeletedCheckChange} />} label={<Typography sx={{ fontWeight: 400 }} variant="body1" color="text.primary">Deleted</Typography>} />
                                </FormControl>
                            </Box>
                        </Stack>
                    </Box>
                </Grid>
                <Grid item xs={12}>
                    <Box sx={{ width: '100%'}}>
                        <Paper sx={{ width: '100%', mb: 2, mt: 3.5, borderTop: '1px solid #EAEBEB' }}>
                            <EnhancedTableToolbar numSelected={selected.length} message={'Unsynced lookup values'} />
                            <TableContainer>
                                <Table
                                    sx={{ minWidth: 750 }}
                                    aria-labelledby="tableTitle"
                                    size={'medium'}
                                >
                                    <EnhancedTableHead headCells={lookupsHeadCells}
                                        numSelected={selected.length}
                                        onSelectAllClick={handleSelectAllClick}
                                        rowCount={data !== null ? data.length : 0}
                                    />
                                    <TableBody>
                                        {visibleRows && visibleRows.map((row, index) => {
                                            const isItemSelected = isSelected(row.LookuoUniqueId);
                                            const labelId = `enhanced-table-checkbox-${index}`;

                                            return (
                                                <TableRow
                                                    hover
                                                    onClick={(event) => handleClick(event, row.LookuoUniqueId)}
                                                    role="checkbox"
                                                    aria-checked={isItemSelected}
                                                    tabIndex={-1}
                                                    key={index}
                                                    selected={isItemSelected}
                                                    sx={{ cursor: 'pointer' }}
                                                >
                                                    <TableCell padding="checkbox">
                                                        <Checkbox sx={{
                                                            [`&, &.${checkboxClasses.checked}`]: {
                                                                color: 'Check.main',
                                                            },
                                                        }}
                                                            color="primary"
                                                            checked={isItemSelected}
                                                            inputProps={{
                                                                'aria-labelledby': labelId,
                                                            }}
                                                        />
                                                    </TableCell>
                                                    <TableCell sx={{width: 100}}>
                                                        <Typography variant="body1" color="text.secondary" sx={{ fontWeight: 400 }} >{row.ChangeType}</Typography>
                                                    </TableCell>
                                                    <TableCell>
                                                        <Typography variant="body1" color="text.secondary" sx={{ fontWeight: 400 }} >{row.FieldLabel}</Typography>
                                                    </TableCell>
                                                    <TableCell sx={{ width: 150 }}>
                                                        <Typography variant="body1" color="text.secondary" sx={{ fontWeight: 400 }} >
                                                            {
                                                                row.ControlType == 'checkbox-group' ? 'Checkbox Group' :
                                                                    row.ControlType == 'radio-group' ? 'Radio Group' :
                                                                        row.ControlType == 'select' ? 'Select' :
                                                                            row.ControlType == 'textarea' ? 'Text Area' :
                                                                                row.ControlType == 'date' ? 'Date Field' :
                                                                                    row.ControlType == 'number' ? 'Number' : 'Text Field'
                                                            }
                                                        </Typography>
                                                    </TableCell>
                                                    <TableCell sx={{ width: 150 }} >
                                                        <Typography variant="body1" color="text.secondary" sx={{ fontWeight: 400 }} >{row.LookupId}</Typography>
                                                    </TableCell>
                                                    <TableCell sx={{ width: 150 }} >
                                                        <Typography variant="body1" color="text.secondary" sx={{ fontWeight: 400 }} >{row.LookupValue}</Typography>
                                                    </TableCell>
                                                    <TableCell sx={{ width: 150 }} >
                                                        <Typography variant="body1" color="text.secondary" sx={{ fontWeight: 400 }} >{row.ControlNm}</Typography>
                                                    </TableCell>
                                                    <TableCell>
                                                        <Typography variant="body1" color="text.secondary" sx={{ fontWeight: 400 }} >
                                                            {
                                                                row.ChangeType === 'New' ? 'Add in the lookup values of the Control' :
                                                                    row.ChangeType === 'Deleted' ? 'Remove from the lookup values of the Control' :
                                                                        'Update lookup value'
                                                            }
                                                        </Typography>
                                                    </TableCell>                                                    
                                                </TableRow>
                                            );
                                        })}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                            <TablePagination
                                rowsPerPageOptions={[25, 50, 75]}
                                component="div"
                                count={recordCount}
                                rowsPerPage={rowsPerPage}
                                page={page}
                                onPageChange={handleChangePage}
                                onRowsPerPageChange={handleChangeRowsPerPage}
                                sx={{
                                    ".MuiTablePagination-selectLabel, .MuiTablePagination-toolbar": {
                                        paddingTop: "8px"
                                    },
                                    ".MuiTablePagination-displayedRows": {
                                        paddingTop: "8px", fontSize: '15px'
                                    },
                                }}
                                classes={{
                                    //toolbar: classes.toolbar,
                                }}
                            />
                        </Paper>
                    </Box>
                </Grid>
                <Grid item sm={12} sx={{pb: 4}} >
                    <Box sx={{ pt: 1 }}>
                        <Button disabled={selected.length == 0} onClick={(e) => UpdateClick(e)} variant="contained" size="large" style={{ boxShadow: 'none', border: 0, borderRadius: '20px', padding: '0.4rem 3rem', outline: 'none', }}>
                            UPDATE
                        </Button>
                    </Box>
                </Grid>
            </Grid>
            <ConfirmDialog
                keepMounted
                open={openConfirmDlg}
                onClose={handleConfirmDelDialogClose}
                alertheader={'Update control panel of the designer'}
                bmsgline1={'Are you sure you want to update control panel of the designer with ' + selected.length + ' item' + (selected.length < 2 ? '?' : 's? ')}
                nvalue={selected.length}
                svalue={''}
            />
        </React.Fragment>
    );
}
const TemplateDesignerRefresh = (props) => {

    const location = useLocation();

    const [errorMsg, setErrorMsg] = useState(null);
    const [successMsg, setSuccessMsg] = useState(null);
    const [successMsgTitle, setSuccessMsgTitle] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [tabValue, setTabValue] = React.useState(0);

    const [userId, setUserId] = useState(location.state ? location.state.userId : -1);
    const [sessionId, setSessionId] = useState(location.state ? location.state.sessionId : '');

    let navigate = useNavigate();

    useEffect(() => {

    }, []);



    const tabStyles = makeStyles({
        indicator: {
            border: "1px solid #16191f"
        },
    });

    const tabClasses = tabStyles();

    const handleTabChange = (event, newValue) => {
        setTabValue(newValue);
    };

    function a11yProps(index) {
        return {
            id: `simple-tab-${index}`,
            'aria-controls': `simple-tabpanel-${index}`,
        };
    }

    function handleLoadingState(isLoading) {
        setIsLoading(isLoading);
    }

    return (
        <React.Fragment>
            <AutoLagoutTimer userId={userId} sessionId={sessionId} />
            <Container maxWidth="lg" sx={{}} className="">
                <OverlaySpinner disappear={isLoading} />
                <Paper className="back-grid">
                    <PostLoginMenu userId={userId} sessionId={sessionId} />
                    <Grid item md={12} id="aobhead" >
                        <Box sx={{
                            pr: 0,
                            pl: 0,
                        }}>
                            <EnrollmentHeader />
                        </Box>
                    </Grid>
                    <Grid item sx={{ pr: 1 }}>
                        <DrawerAppBar userId={userId} sessionId={sessionId} />
                    </Grid>
                    <Grid item>
                        <Box sx={{ display: 'flex', pb: 2, pt: 0, ml: 6, mr: 6, justifyContent: 'space-between', alignItems: 'flex-end', borderBottom: '0px solid #898F94' }}>
                            <Typography variant="h6" color="text.primary" sx={{  pl: 0, pb: 0, lineHeight: 1, fontWeight: 500, pb: 0, letterSpacing: '0px' }} >Refresh Template Designer</Typography>
                        </Box>
                        <Box sx={{ }}>
                            <Divider variant="fullWidth" component="div" sx={{ }} />
                        </Box>
                    </Grid>
                    <Grid item>
                        <Box sx={{ ml: 6, mr: 6, mb: 0, mt: 2, borderTop: '0px solid #D5D9D9', }}>
                            <Box sx={{ borderBottom: 0, borderColor: 'divider' }}>
                                <Tabs value={tabValue} classes={{ indicator: tabClasses.indicator }} onChange={handleTabChange} sx={{ height: '50px', borderBottom: '1px solid #B3B9BC' }}>
                                    <Tab label="Controls" {...a11yProps(0)} sx={{ height: '50px', textTransform: 'none', fontSize: '1rem', fontWeight: 500 }} />
                                    <Tab label="Lookup Values" {...a11yProps(1)} sx={{ height: '50px', textTransform: 'none', fontSize: '1rem', fontWeight: 500 }} />
                                </Tabs>
                            </Box>
                            {tabValue === 0 && (
                                <UpdatedCoreDataList userId={userId} sessionId={sessionId} loadingChange={handleLoadingState} />
                            )}
                            {tabValue === 1 && (
                                <UpdatedCoreDataLookup userId={userId} sessionId={sessionId} loadingChange={handleLoadingState} />
                            )}
                        </Box>
                    </Grid>
                    <Footer userId={userId} />
                </Paper>
            </Container>
        </React.Fragment>
    );
};

export default TemplateDesignerRefresh;