import React, { Component } from 'react';
import * as $ from 'jquery';
import 'reactjs-popup/dist/index.css';
//import EnrollmentDownloadControls from '../Enrollment/EnrollmentDownloadControls';

require('formBuilder/dist/form-render.min.js')

class EachFormRender extends Component {

    constructor(props) {
        super(props);

        this.fileUploadEvent = this.fileUploadEvent.bind(this);
    }

    fileUploadEvent = (fileNm) => {
        const file = document.getElementById(fileNm);
        if (file !== null) {            
            if (!file.hasAttribute('listenerOnChange')) {
                file.addEventListener("change", e => {
                    this.props.onFileChangeValue(e);
                });
            }
            file.setAttribute('listenerOnChange', 'true');            
        }
    }

    //fileOnloadEvent = (cntrlNm, fileNm) => {
    //    EnrollmentDownloadControls(cntrlNm, fileNm, this.props.onFileDownload, this.props.onDeleteFile);
    //}
   
    fileOnloadEvent = (cntrlNm, fileNm, filesize) => {
        const file = document.getElementById(cntrlNm);

        if (file !== null && fileNm && fileNm !== undefined) {
            if (!file.hasAttribute('listenerOnLoad')) {

                var parentElemnt = file.parentElement;

                const div = document.createElement('div');
                div.id = 'div' + cntrlNm;
                div.name = 'div' + cntrlNm;

                var dot = fileNm.lastIndexOf(".");
                var extension = dot >= 0 ? fileNm.substr(dot, fileNm.length) : '';

                let iconFile = '<i class="fa fa-file-image"></i>';
                if (extension === '.pdf')
                    iconFile = '<i class="fa fa-file-pdf"></i>'

                const link = document.createElement('a');
                link.name = 'href_' + cntrlNm;
                link.id = 'href_' + cntrlNm;
                link.setAttribute('controlname', cntrlNm);
                link.setAttribute('filename', fileNm);
                link.innerHTML = iconFile + '&nbsp;' + (fileNm.replace(extension, '').length > 12 ? fileNm.substr(0, 11) + '...' + extension : fileNm) + (filesize && filesize !== undefined ? '('+filesize+')':'');
                link.style="width:20px;"
                link.title = 'Download '+ fileNm;
                link.addEventListener("click", e => {
                    this.props.onFileDownload(e);
                });

                const link2 = document.createElement('a');
                link2.name = 'href2_' + cntrlNm;
                link2.id = 'href2_' + cntrlNm;
                link2.setAttribute('controlname', cntrlNm);
                link2.setAttribute('filename', fileNm);
                link2.innerHTML = '&nbsp; <i class="fa fa-trash" controlname="' + cntrlNm + '" filename="' + fileNm +'"></i>';
                link2.title = 'Delete '+fileNm;
                link2.addEventListener("click", e => {
                    this.props.onDeleteFile(e);
                });

                div.appendChild(link);
                div.appendChild(link2);

                parentElemnt.appendChild(div);
                document.getElementById(cntrlNm).innerHTML = fileNm;

            }
            file.setAttribute('listenerOnLoad', 'true');
        }
    }
    
    fileDownloadEvent = (fileNm) => {
        const file = document.getElementById(fileNm);
        if (file !== null) {
            if (!file.hasAttribute('listenerOnClick')) {
                file.addEventListener("click", e => {
                    this.props.onFileDownload(e);
                });
            }
            file.setAttribute('listenerOnClick', 'true');
        }
    }
    
    componentDidMount() {

        //console.log(JSON.stringify('componentDidMount'));
        //console.log(JSON.stringify(this.props.data));
        //console.log(JSON.stringify('End of componentDidMount'));

        $('.fb-render').formRender({
            dataType: 'json',
            formData: JSON.stringify(this.props.data),
            i18n: {
                location: process.env.REACT_APP_I18N_LANG
            }
        });


        const fileTypes = this.props.data.filter((fileType) => {
            return fileType.type.toString() === "file";
        });

        //File Upload
        //console.log(fileTypes);
        if (fileTypes.length > 0) {
            fileTypes.forEach((value, key) => {
                const file = document.getElementById(value.name);
                if (file !== null) {
                    if (!file.hasAttribute('listenerOnChange')) {
                        this.fileUploadEvent(value.name);
                    }
                    file.setAttribute('listenerOnChange', 'true');

                    //if (!file.hasAttribute('listenerOnClick')) {
                    //    this.fileDownloadEvent(value.name);
                    //}
                    //file.setAttribute('listenerOnClick', 'true');

                    if (!file.hasAttribute('listenerOnLoad')) {
                        this.fileOnloadEvent(value.name, value.value, value.filesize);
                    }
                    file.setAttribute('listenerOnLoad', 'true');
                }
            })
        }
        //End Upload
    }

    componentDidUpdate() {

        // $('.fb-render').formRender({
        //    dataType: 'json',
        //    formData: JSON.stringify(this.props.data),
        //    i18n: {
        //        location: process.env.REACT_APP_I18N_LANG
        //    }
        // });

        //const fileTypes = this.props.data.filter((fileType) => {
        //    return fileType.type.toString() === "file";
        //});
        ////File Upload
        //console.log(fileTypes);
        //if (fileTypes.length > 0) {
        //    fileTypes.forEach((value, key) => {
        //        const file = document.getElementById(value.name);
        //        if (file !== null) {
        //            if (!file.hasAttribute('listenerOnChange')) {
        //                this.fileUploadEvent(value.name);
        //            }
        //            file.setAttribute('listenerOnChange', 'true');
        //        }
        //    })
        //}
        //End Upload
    }

    render() {
        return (
            <>
                <div className="shadow-sm bg-white Box">
                    <div className="fb-render" id='fb-render-id'>
                    {/*{{ display: this.props.visible === true ? "" : "none" }}*/}
                    </div>
                </div>
            </>
        )
    }
}

const RenderControl = ({ data, id, visible, onFileChangeValue, onFileDownload, onDeleteFile }) => {
    return (
        <>
            {
                <EachFormRender id={id} style={{ display: visible === true ? "" : "none" }} data={data} key={id} onFileChangeValue={onFileChangeValue} onFileDownload={onFileDownload} onDeleteFile={onDeleteFile}/>
            }
        </>
    );
}

export default RenderControl;