import axios from 'axios';
import * as $ from 'jquery';
import React, { useState, Component, useRef, useEffect } from 'react';
//import { ArrowLeft, ArrowRight, ListCheck, Printer, Save } from 'react-bootstrap-icons';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate, useLocation } from "react-router-dom";
//import 'reactjs-popup/dist/index.css';
import { ApiHelper } from '../customFunctions/apiHelper';
import { GenerateToken } from '../customFunctions/generateToken';
import { TokenValidation } from '../customFunctions/tokenValidation';
import Breadcrumb from './Controls/Breadcrumb';
import LoaderControl from './Controls/LoaderControl';
import RenderControl from './Controls/RenderControl';
//import ApplicationSectionCard from './DashBoard/ApplicationSectionCard';
import EnrollmentBanner from './EnrollmentBanner';
import Footer from './Footer';
import { NavMenu } from './NavMenu';
import ReviewControl from './ReviewControl';
//import './TemplateUI.css';
import AlertText from './Controls/AlertText';
import { ContentType } from '../customFunctions/SysParmConfig';
import EnrollmentDocumentDownload from './Enrollment/EnrollmentDocumentDownload';
import DownloadFile from './Enrollment/DownloadFile';
import OverlaySpinner from './Controls/OverlaySpinner';
import PostLoginMenu from './PostLoginMenu';
import { MainNavMenu } from './MainNavMenu';
import { CallAPI, UploadUserFiles, IsInRole, CallAPIUsingAxios, IsLoggedInUserProspectUser, GetLoggedInUserName } from '../global/APIUtils';
import { ErrorMessages } from '../common/ErrorMessages';
import { EnrollmentStatus, EnrollmentStatusText, AOBSystemProcess, ActivityLogEvent } from '../common/AOBEnum';
import { CreateFileDownloadLink, CreateDeleteFileLink, DownloadFileHelper } from '../global/Helpers';
import AlertControl from './Controls/AlertControl';
import { ApplicationDocumentDownload, ApplicationAllDocumentDownload } from './ApplicationDocumentDownload';
import { DrawerAppBar } from './AppNavbar';
//import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { AlertTitle, Table, TableRow, TableCell, Box, Typography, Stack, Button, Badge, Paper, Grid, Link, Tooltip, Divider, Menu, MenuItem, Card, CardMedia, IconButton } from '@mui/material';
import Container from '@mui/material/Container';
import EnrollmentHeader from './EnrollmentHeader'
import pwdimg from '../assets/images/appl.jpg';
import Image from 'material-ui-image'
import { IsError } from '../global/APIUtils';
import { ConfirmDialog } from '../global/ConfirmDialog';
import StepButton from '@mui/material/StepButton';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import MoreVertOutlinedIcon from '@mui/icons-material/MoreVertOutlined';
import { grey, red } from '@mui/material/colors';
import ListItemIcon from '@mui/material/ListItemIcon';
import GradingIcon from '@mui/icons-material/Grading';
import SaveOutlinedIcon from '@mui/icons-material/SaveOutlined';
import ReplyOutlinedIcon from '@mui/icons-material/ReplyOutlined';
import PrintIcon from '@mui/icons-material/Print';
import { GetAttachedFile, CreateFileLinks } from '../global/EnrollmentHelper';
import LensIcon from '@mui/icons-material/Lens';
import DoneIcon from '@mui/icons-material/Done';
import profimg from '../assets/images/profimg.jpg';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import EnrollmentImageUpldDlg from '../dialogs/EnrollmentImageUpdDlg';
import { GetApplicantImage } from '../global/EnrollmentHelper';
import AlertWithTitle from './Controls/AlertWithTitle';
import { goToTop } from '../global/Helpers'
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import CancelPresentationIcon from '@mui/icons-material/CancelPresentation';
import DoneAllIcon from '@mui/icons-material/DoneAll';
import Snackbar from '@mui/material/Snackbar';
import CloseIcon from '@mui/icons-material/Close';
import AppViewDlg from '../dialogs/ApplViewDlg';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import { APICall, WriteToActivityLog } from '../global/Api';
import MuiAccordion, { AccordionProps } from '@mui/material/Accordion';
import { styled } from '@mui/material/styles';
import SaveIcon from '@mui/icons-material/Save';
import EditIcon from '@mui/icons-material/Edit';
import EditOffIcon from '@mui/icons-material/EditOff';
import { FormDialog } from '../global/FormDialog';
import AutoLagoutTimer from '../global/IdleMonitor';
import Alert from '@mui/material/Alert';

//For Element Drag and Drop
//require('formBuilder/dist/form-render.min.js')

const Accordion = styled((props) => (
    <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
    border: 0,
    '&:not(:last-child)': {
        borderBottom: 0,
    },
    '&:before': {
        display: 'none',
    },
}));


const enrollmentData = [];

const AppEventStatus = {
    InComplete: 1,
    Review: 2,
    Save: 3,
    SaveCompleted: 5,
    Submitted: 6
}


const FormRender = (props) => {

    const [files, setFiles] = useState([]);
    const [dataOwner, setDataOwner] = useState(null);
    const [nextId, setNextId] = useState('');
    const [curId, setCurId] = useState('');
    const [prevId, setPrevId] = useState('');
    const [appEventSts, setAppEventSts] = useState('');
    const [errorMsg, setErrorMsg] = useState('');
    const [savedMessage, setsavedMessage] = useState('');
    const [delFiles, setDelFiles] = useState([]);
    const [curSecHdrIdx, setCurSecHdrIdx] = useState(0);
    const [isLoading, setIsLoading] = useState(false);
    const [isFormDirty, setFormDirty] = useState(false);
    const sectionHeaders = useRef([]);
    //const sections = useRef([]);
    const [sections, setSections] = useState(null);
    const enrollmentDetail = useRef('');
    const [expanded, setExpanded] = useState(true);
    const [activeStep, setActiveStep] = React.useState(0);
    const [moreMenuAnchorEl, setMoreMenuAnchorEl] = useState(null);
    const [imageUrl, setImageUrl] = useState(null);
    const [showImageDlg, setShowImageDlg] = useState(false);
    const [showAppViewDlg, setShowAppViewDlg] = useState(false);
    const [isProspectUser, setProspect] = useState(true);
    const [ownerId, setOwnerId] = useState(props.ownerid || -1);
    const [ownerName, setOwnerName] = useState(null);
    const [ownerEmail, setOwnerEmail] = useState(null);
    const [ownerMobile, setOwnerMobile] = useState(null);
    const [successMsg, setSuccessMsg] = useState(null);
    const [submittedMsg, setSubmittedMsg] = useState(null);
    const openMoreMenu = Boolean(moreMenuAnchorEl);
    const [enrollmentStatusId, setEnrollmentStatusId] = useState(props.EnrollmentStatus || -1);
    const [submitAlert, setSubmitAlert] = useState('none');
    const [successMsgTitle, setSuccessMsgTitle] = useState(null);
    const [appStatusStr, setAppStatusStr] = useState('Complete');

    const [openConfirmDlg, setOpenConfirmDlg] = useState(false);
    const [openRvwPassCnfrmDlg, setOpenRvwPassCnfrmDlg] = useState(false);
    const [openRvwFailCnfrmDlg, setOpenRvwFailCnfrmDlg] = useState(false);

    const [openUndrRvwCnfrmDlg, setOpenUndrRvwCnfrmDlg] = useState(false);
    const [openCancelConfirmDlg, setOpenCancelConfirmDlg] = useState(false);
    const [confirmDlgNValue, setConfirmDlgNValue] = useState(null);
    const [confirmDlgSValue, setConfirmDlgSValue] = useState(null);
    const [disSection, setDisSection] = useState(false);
    const [showOwnerList, setShowOwnerList] = useState('block');

    const [jntownrChecked, setJntownrChecked] = React.useState(false);
    const [jointOwnerName, setJointOwnerName] = useState('');
    const [jointOwnerEmail, setJointOwnerEmail] = useState('');

    const confirmDlgHdr = useRef('');
    const confirmDlgBLine1 = useRef('');
    const confirmDlgBLine5 = useRef('');
    const delFileControl = useRef('');
    const isAdmin = useRef(false);
    const canApprove = useRef(false);
    const canPublish = useRef(false);

    const onSubmitFormHandler = useRef('');
    const onFormLoadHandler = useRef('');

    const [isAppReadOnly, setIsAppReadOnly] = useState(props.appreadonly || false);
    const [unlockApp, setUnlockApp] = useState(props.unlockApp || false);
    const [isAdminView, setIsAdminView] = useState(props.AdminView || false);

    const [userId, setUserId] = useState(props.userId || -1);
    const [sessionId, setSessionId] = useState(props.sessionId || '');
    const [disEditToolbar, setDisEditToolbar] = useState('none');
    const [editToolbarJusContent, setEditToolbarJusContent] = useState('flex-end');

    const [disEditIcon, setDisEditIcon] = useState('block');
    const [disEditOffIcon, setDisEditOffIcon] = useState('none');
    const [disSaveIcon, setDisSaveIcon] = useState(true);

    const [disReviewPassBtn, setDisReviewPassBtn] = useState('none');
    const [disReviewFailBtn, setDisReviewFailBtn] = useState('none');
    const [disSubmitBtn, setDisSubmitBtn] = useState('block');


    let navigate = useNavigate();

    const handleMoreMenuClick = (event) => {
        setMoreMenuAnchorEl(event.currentTarget);
    };
    const handleMoreMenuClose = () => {
        setMoreMenuAnchorEl(null);
    };

    const changeCboOwner = (CntCtId) => {
        setOwnerId(CntCtId)
        if (dataOwner != undefined && dataOwner != null) {
            let selectedValue = dataOwner.find(x => x.UserId == CntCtId);
            if (selectedValue != undefined && selectedValue != null) {
                setOwnerName(selectedValue.UserFullName);
                setOwnerEmail(selectedValue.EmailAddress);
                setOwnerMobile(selectedValue.MobileNbr);
            }
        }
    }

    const closeImageDlg = () => {
        setShowImageDlg(false);
    }

    const closeAppViewDlg = () => {
        setShowAppViewDlg(false);
    }

    const onPicChangeHandle = async () => {
        setShowImageDlg(false);

        try {
            setIsLoading(true);
            await loadApplicantImage();
        }
        catch (err) {
            setErrorMsg(ErrorMessages.UnknownError);
        }
        finally {
            setIsLoading(false);
        }
    }

    const handleNext = () => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const handleReset = () => {
        setActiveStep(0);
    };

    const dispatch = useDispatch();
    let sessions = useSelector((state) => state.sessionMgmnt);

    const styles = theme => ({
         stepIcon: {
            color: "pink"
        }
    });

    const handleScrollIntoView = (event) => {
        event.preventDefault();
        let id = event.target.id.replace('btn_', '');
        const myArray = id.split("_");
        let nmPrfx = myArray[1];

        sectionDivider(sectionHeaders.current, enrollmentDetail.current, false, nmPrfx);
    }

    const changeFileHandlerEvent = async (event) => {

        if (!event.target.files)
            return;

        if (event.target.files.length == 0)
            return;

        var fileName = event.target.files[0].name;
        var cntrlNm = event.target.name;

        var fileSize = event.target.files[0].size;

        const responseItems = JSON.parse(enrollmentDetail.current);
        const fileTemplate = responseItems.filter(item => item.name.toString() === event.target.name && item.type === 'file');

        dropAnchorIfReq(cntrlNm);

        if (fileTemplate && fileTemplate.length > 0 && fileTemplate[0].maxfilesize && fileTemplate[0].maxfilesize !== undefined) {
            if (fileTemplate[0].maxfilesize > 0 && (Math.floor(fileSize / 1048576) > fileTemplate[0].maxfilesize)) {
                event.target.value = null;                
                let msgText = fileName + " - size exeeced (Max " + fileTemplate[0].maxfilesize + "MB)";
                createControlLabelMsg(cntrlNm, msgText);
                return;
            }
        }

        let AttachmentID = -1;

        const curRowIndex = responseItems.findIndex(item => item.name.toString() === event.target.name);//Control Name

        responseItems[curRowIndex].filesize = fileSize;
        responseItems[curRowIndex].value = fileName;
        responseItems[curRowIndex].userData = [fileName];

        let attachmentData = new FormData();
        attachmentData.set('EnrollmentId', props.enrmntid);
        attachmentData.set('EnrollmentData', JSON.stringify(responseItems));
        attachmentData.set('IsSignReq', false);
        attachmentData.append('ControlName', cntrlNm);
        attachmentData.append("File", event.target.files[0]);
      
        try {

            setIsLoading(true);

            const apiUrl = new URL(
                process.env.REACT_APP_SAVE_ENROLLMENT_ATTACHMENT,
                process.env.REACT_APP_BASE_URL,
            );

            let response = await APICall({ url: apiUrl.href, method: 'POST', payload: attachmentData, contentType: 'multipart/form-data', responseType: '' }, userId, sessionId);

            //let response = await CallAPIUsingAxios({ url: apiUrl.href, method: 'POST', formData: attachmentData }, userId, sessionId);

            if (response !== null && response !== undefined) {

                setIsLoading(false);

                let apiErrors = response["Errors"];

                if (apiErrors !== undefined && apiErrors.length > 0) {
                    createControlLabelMsg(cntrlNm, apiErrors[0].Message);
                    return;
                }

                let data = response["data"];
                if (data !== undefined && data.length > 0) {

                    enrollmentDetail.current = (data[0].EnrollmentData);

                    let AttachmentID = data[0].AttachmentID;

                    event.target.disabled = true;

                    CreateFileLinks(cntrlNm, fileName, fileSize, handleDownloadFileEvent, handleDeleteFileEvent);

                    await WriteToActivityLog(ActivityLogEvent.Application, "Attachment added to the application. Application name: '" + props.name + "'. File name: '" + fileName + "'.", userId, sessionId);

                }
            }
        }
        catch (err) {
            createControlLabelMsg(cntrlNm, ErrorMessages.UnknownError);
            setIsLoading(false);
        }
        finally {
        }
    };

    const handleDownloadFileEvent = async (event) => {
        event.preventDefault();

        var controlName = event.target.getAttribute('controlname');
        var filename = event.target.getAttribute('filename');


        try {

            setIsLoading(true);

            const apiUrl = new URL(
                process.env.REACT_APP_DOWNLOAD_ENROLLMENT_DOCUMENT,
                process.env.REACT_APP_BASE_URL,
            );
            apiUrl.searchParams.set('enrmntId', `${parseInt(props.enrmntid)}`);
            apiUrl.searchParams.set('attachmentId', '-1');
            apiUrl.searchParams.set('fname', `${filename}`);
            apiUrl.searchParams.set('tag', `${controlName}`);

            let success = await GetAttachedFile(apiUrl.href, filename, userId, sessionId);
            if (success) {
                await WriteToActivityLog(ActivityLogEvent.Application, "Downloaded attachment of the application. Application name: '" + props.name + "'. File name: '" + filename + "'.", userId, sessionId);
                setIsLoading(false);
            }
        }
        catch (ex) {
            setErrorMsg(ErrorMessages.UnknownError);
            setIsLoading(false);
        }
        finally {
            setIsLoading(false);
        }
    };

    const dropAnchorIfReq = (cntrlNm) => {
        var divCntrl = document.getElementById('div' + cntrlNm);
        if (divCntrl) {
            var parentElemnt1 = divCntrl.parentElement;
            parentElemnt1.removeChild(divCntrl);
        }
    }

    const createControlLabelMsg = (cntrlNm, msgText) => {
        const div = document.createElement('div');
        div.id = 'div' + cntrlNm;
        div.name = 'div' + cntrlNm;
        div.className = 'text-danger';
        div.innerHTML = msgText;//fileName + " - size exeeced to " + fileTemplate[0].maxfilesize + "MB.";

        var parentElemnt = document.getElementById(cntrlNm).parentElement;
        parentElemnt.appendChild(div);
    }

    const handleDeleteFileEvent = async (event) => {

        event.preventDefault();
        var controlName = event.target.getAttribute('controlname');
        var fileName = event.target.getAttribute('filename');
        var enrmntId = parseInt(props.enrmntid);

        var dot = fileName.lastIndexOf(".");
        var extension = dot >= 0 ? fileName.substr(dot, fileName.length) : '';
        var fileText = (fileName.replace(extension, '').length > 12 ? fileName.substr(0, 11) + '...' + extension : fileName)

        delFileControl.current = controlName;


        confirmDlgHdr.current = 'Delete file';
        confirmDlgBLine1.current = "Do you want to delete the file '" + fileText + "' ?"
        setConfirmDlgSValue(controlName);
        setOpenConfirmDlg(true);
    }

    const handleConfirmDialogClose = async (nvalue, svalue) => {

        setOpenConfirmDlg(false);

        if (svalue == null)
            return;

        var enrmntId = parseInt(props.enrmntid);
        const responseItems = JSON.parse(enrollmentDetail.current);
        const curRowIndex = responseItems.findIndex(item => item.name.toString() === svalue);//Control Name

        let fileName = responseItems[curRowIndex].value;
        responseItems[curRowIndex].value = '';
        responseItems[curRowIndex].userData = [];
        responseItems[curRowIndex].filesize = null;

        const deletedFiles = { "EnrollmentId": enrmntId, "ControlName": svalue, "AttchmntId": 0 };

        const inputJson = {
            EnrmntId: enrmntId,
            CntrlNm: svalue,
            EnrmntData: JSON.stringify(responseItems)
        };

        const apiUrl = new URL(
            process.env.REACT_APP_DELETE_ENROLLMENT_ATTACHMENT,
            process.env.REACT_APP_BASE_URL,
        );

        try {

            setIsLoading(true);

            let response = await APICall({ url: apiUrl.href, method: 'POST', payload: inputJson, contentType: 'application/json', responseType: '' }, userId, sessionId);

            if (response !== null && response !== undefined) {

                setIsLoading(false);

                let apiErrors = response["Errors"];

                if (apiErrors != undefined && apiErrors.length > 0) {
                    createControlLabelMsg(svalue, apiErrors[0].Message);
                }
                else if (response["data"] != undefined && response["data"].length > 0) {

                    enrollmentDetail.current = response["data"][0].EnrollmentData;
                    dropAnchorIfReq(svalue);
                    document.getElementById(svalue).value = null;
                    document.getElementById(svalue).disabled = false;
                    document.getElementById(svalue).removeAttribute("value");
                    document.getElementById(svalue).removeAttribute("user-data");

                    await WriteToActivityLog(ActivityLogEvent.Application, "Attachment deleted from the application. Application name: '" + props.name + "'. File name: '" + fileName + "'.", userId, sessionId);
                }
            }
        }
        catch (err) {
            setErrorMsg(ErrorMessages.UnknownError);
            setIsLoading(false);
        }
        finally {
        }
    };

    const hndlRvwPassCnfrmDlgClose = async (nvalue, svalue) => {

        setOpenRvwPassCnfrmDlg(false);

        if (nvalue == null)
            return;

        try {
            setErrorMsg('');
            setSuccessMsg('');
            setSubmitAlert('none');
            setIsLoading(true);
            let isSuccess = await saveEnrollmentData(EnrollmentStatus.ReviewPass);
            if (isSuccess) {
                await WriteToActivityLog(ActivityLogEvent.Application, "Application status changed to 'Review Passed'. Application name: '" + props.name + "'.", userId, sessionId);
                navigate('/ApplicationDashboard', { state: { userId: userId, sessionId: sessionId }, replace: true });
                return;
            }

            goToTop();
        }
        catch (err) {
            setErrorMsg(ErrorMessages.UnknownError);
        }
        finally {
            setIsLoading(false);
        }
    };

    const hndlRvwFailCnfrmDlgClose = async (nvalue, svalue) => {

        setOpenRvwFailCnfrmDlg(false);

        if (nvalue == undefined || nvalue == null)
            return;

        if (nvalue && svalue) {

            setErrorMsg('');
            setSuccessMsg('');
            setSubmitAlert('none');
            setIsLoading(true);
            let isSuccess = await updateEnrollmentStatus(nvalue, EnrollmentStatus.ReviewFail, svalue);
            if (isSuccess) {
                await WriteToActivityLog(ActivityLogEvent.Application, "Application status changed to 'Review Failed'. Application name: '" + props.name + "'.", userId, sessionId);
                navigate('/ApplicationDashboard', { state: { userId: userId, sessionId: sessionId }, replace: true });
                return;
            }

            goToTop();
        }
    };

    const hndlUndrRvwCnfrmDlgClose = async (nvalue, svalue) => {

        setOpenUndrRvwCnfrmDlg(false);

        if (nvalue == null)
            return;

        setSubmitAlert('none');
        setErrorMsg('');
        setSuccessMsg('');
        setIsLoading(true);
        let isSuccess = await updateEnrollmentStatus(nvalue, EnrollmentStatus.UnderReview, null);
        if (isSuccess) {
            await WriteToActivityLog(ActivityLogEvent.Application, "Application status changed to 'Under Review'. Application name: '" + props.name + "'.", userId, sessionId);
            navigate('/ApplicationDashboard', { state: { userId: userId, sessionId: sessionId }, replace: true });
            return;
        }

        goToTop();
    };

    const downloadFile = (data, filename, contentType) => {
        var binaryData = [];
        binaryData.push(data);

        let blob = new Blob(binaryData, { type: contentType });
        // create file link in browser's memory
        const href = URL.createObjectURL(blob);

        // create "a" HTML element with href to file & click
        const link = document.createElement('a');
        link.href = href;
        link.setAttribute('download', filename); //or any other extension
        document.body.appendChild(link);
        link.click();

        // clean up "a" element & remove ObjectURL
        document.body.removeChild(link);
        URL.revokeObjectURL(href);
    }

    const ValidateForm = async (emntData, eStatus) => {

        //let requiredField = JSON.parse(emntData).filter(ed => { return ed.required });
        let requiredField = emntData.filter(ed => { return ed.required });

        let validtnMsg = "";
        let msgCount = 0;
        let isDisabled = false;

        if (requiredField) {
            for (var i = 0; i < requiredField.length; i++) {

                if (requiredField[i].adminonly && !isAdminView) {
                    continue;
                }

                //isDisabled = $('#' + requiredField[i].name).prop('disabled');
                //if (isDisabled)
                //    continue;

                //if ($('#' + requiredField[i].name).is(':disabled')) {
                //    continue;
                //}

                if ((requiredField[i].userData === undefined || requiredField[i].userData === null || requiredField[i].userData.length == 0 ||
                    requiredField[i].userData[0] === "" || requiredField[i].userData[0].length === 0) && requiredField[i].value == null)
                {
                    msgCount++;
                    validtnMsg += msgCount > 1 ? ", " : ""
                    validtnMsg += "'" + requiredField[i].label + "'";
                }
            }
        }

        if (parseInt(ownerId) === -1) {
            if (validtnMsg === "") {
                validtnMsg = "'Application owner'";
            }
            else {
                validtnMsg = validtnMsg + ", 'Application owner'";
            }
        }

        return validtnMsg;
    }

    const UnderReview = async () => {

        confirmDlgHdr.current = 'Application Status Update';
        confirmDlgBLine1.current = "Do you want to change the application status from 'Submitted' to 'Under Review ?"

        setOpenUndrRvwCnfrmDlg(true);
    }


    const ReviewPassed = async () => {

        onChangeFormData();


        let formData = $('.fb-render').formRender("userData");
        let emntData = (enrollmentDetail.current);

        setErrorMsg('');

        let validtnMsg = await ValidateForm(JSON.parse(emntData), EnrollmentStatus.ReviewPass);

        if (validtnMsg.length > 0) {
            setErrorMsg("Fill up " + validtnMsg + " inormation.");
            goToTop();
            return;
        }

        confirmDlgHdr.current = 'Application Status Update';
        confirmDlgBLine1.current = "Do you want to change the application status from 'Under Review' to 'Review Passed ?"

        setOpenRvwPassCnfrmDlg(true);        
    }

    const ReviewFailed = async () => {

        confirmDlgHdr.current = "Application Status Update";
        confirmDlgBLine1.current = "Do you want to change the application status from 'Under Review' to 'Review Failed ?";
        confirmDlgBLine5.current = 'Please enter the reason:';

        setOpenRvwFailCnfrmDlg(true);
    }

    const SubmitData = async () => {

        onChangeFormData();


        let formData = $('.fb-render').formRender("userData");
        let emntData = (enrollmentDetail.current);

        setErrorMsg('');


        try {
            setErrorMsg('');
            setSuccessMsg('');
            setIsLoading(true);

            let validtnMsg = await ValidateForm(JSON.parse(emntData), EnrollmentStatus.SubmitApplication);

            if (validtnMsg.length > 0) {
                setErrorMsg("Fill up " + validtnMsg + " inormation before submitting the form.");
                goToTop();
                return;
            }

            if (onSubmitFormHandler.current !== '') {
                validtnMsg = new Function(onSubmitFormHandler.current)(); //eval(onSubmitFormHandler.current);
                if (validtnMsg && validtnMsg.length > 0) {
                    setErrorMsg(validtnMsg);
                    goToTop();
                    return;
                }
            }

            let isSuccess = await saveEnrollmentData(EnrollmentStatus.SubmitApplication);
            if (isSuccess) {
                await WriteToActivityLog(ActivityLogEvent.Application, "Application submitted. Application name: '" + props.name + "'.", userId, sessionId);
                setSubmitAlert('block');
                setDisSection(true);
                setIsAppReadOnly(true);
            }

            goToTop();
        }
        catch (err) {
            setErrorMsg(ErrorMessages.UnknownError);
        }
        finally {
            setIsLoading(false);
        }
    }

    const saveEnrollmentData = async (eStatusId) => {

        let emntData = (enrollmentDetail.current);

        const inputJson = {
            enrmntId: parseInt(props.enrmntid),
            ownerId: parseInt(props.ownerid),
            templateId: parseInt(props.templateid),
            enrmntData: emntData,
            mntStatusId: parseInt(null),
            enrmntStatusId: eStatusId,
            name: props.name,
            IsJointAccount: jntownrChecked,
            JointOwnerName: jntownrChecked ? jointOwnerName : '',
            JointOwnerEmail: jntownrChecked ? jointOwnerEmail : '',
            IsSubmitted: eStatusId == EnrollmentStatus.SubmitApplication
        };

        let apiUrl = new URL(
            process.env.REACT_APP_MODIFY_ENROLLMENT,
            process.env.REACT_APP_BASE_URL,
        );

        //let response = await CallAPI({ url: apiUrl.href, method: 'POST', headers: {}, body: inputJson }, userId, sessionId);
        let response = await APICall({ url: apiUrl.href, method: 'POST', payload: inputJson, contentType: 'application/json', responseType: '' }, userId, sessionId);

        if (response !== null && response !== undefined) {

            let error = IsError(response);

            if (error != 0) {
                setErrorMsg(response.Errors[0].Message);
                return false;
            }

            let enrollmentID = response["data"][0].EnrolmentID;

            if (enrollmentID && enrollmentID > 0) {
                setEnrollmentStatusId(eStatusId);
                setAppStatusStr(GetAppSatusStr(eStatusId));
                return true;
                //if (isRedirectToDashBoard === true) {
                //    let history = props.history;
                //    history('/ProspectDashboard');
                //}
            } else {
                setErrorMsg(ErrorMessages.UnknownError);
            }                
        }

        return false;
    }

    const IsMandatoryFieldEntered = () => {
        let msgCount = 0;
        let emntData = (enrollmentDetail.current);

        let requiredField = JSON.parse(emntData).filter(ed => { return ed.required });

        if (requiredField) {
            for (var i = 0; i < requiredField.length; i++) {
                if (requiredField[i].userData && requiredField[i].userData === undefined || requiredField[i].userData === null ||
                    (requiredField[i].userData && requiredField[i].userData.length > 0 && (requiredField[i].userData[0] === "" || requiredField[i].userData[0].length === 0))
                ) {
                    msgCount++;
                }
            }
        }

        if (msgCount > 0)
            return false;

        return true;
    }

    const viewEnrollment = async (event) => {
        setMoreMenuAnchorEl(null);
        onChangeFormData();
        await WriteToActivityLog(ActivityLogEvent.Application, "Reviewed application. Application name: '" + props.name + "'.", userId, sessionId);
        setShowAppViewDlg(true);
    }

    const saveEnrollment = async (event) => {

        try {

            setSuccessMsg('');
            setErrorMsg('');
            setMoreMenuAnchorEl(null);
            onChangeFormData();

            if (unlockApp) {
                let emntData = (enrollmentDetail.current);

                let validtnMsg = await ValidateForm(JSON.parse(emntData), EnrollmentStatus.SubmitApplication);

                if (validtnMsg.length > 0) {
                    setErrorMsg("Fill up " + validtnMsg + " inormation before saving the form.");
                    goToTop();
                    return;
                }

                if (onSubmitFormHandler.current !== '') {
                    validtnMsg = new Function(onSubmitFormHandler.current)(); //eval(onSubmitFormHandler.current);
                    if (validtnMsg && validtnMsg.length > 0) {
                        setErrorMsg(validtnMsg);
                        goToTop();
                        return;
                    }
                }
            }

            let completed = IsMandatoryFieldEntered();

            setIsLoading(true);
            let isSuccess = await saveEnrollmentData(enrollmentStatusId == EnrollmentStatus.Complete || enrollmentStatusId == EnrollmentStatus.InComplete ? completed ? EnrollmentStatus.Complete : EnrollmentStatus.InComplete : enrollmentStatusId);

            await WriteToActivityLog(ActivityLogEvent.Application, "Application saved. Application name: '" + props.name + "'.", userId, sessionId);

            if (isSuccess && unlockApp)
                setSuccessMsg("Your application is saved.");
            else
                setSuccessMsg("Your application is saved. You can always come back and complete them later on.");

            goToTop();
        }
        catch (err) {
            setErrorMsg(ErrorMessages.UnknownError);
        }
        finally {
            setIsLoading(false);
        }
    }

    const ExitApplication = async (event) => {
        if (isProspectUser) {
            navigate('/ProspectDashboard', { state: { userId: userId, sessionId: sessionId }, replace: true });
            return;
        }

        navigate('/ApplicationDashboard', { state: { userId: userId, sessionId: sessionId }, replace: true });
    }

    const CancelApplication = async (event) => {

        setMoreMenuAnchorEl(null);

        confirmDlgHdr.current = 'Application cancellation';
        confirmDlgBLine1.current = "Do you want to cancel the application ?"

        setOpenCancelConfirmDlg(true);
    }

    const handleCancelConfirmDialogClose = async (nvalue, svalue) => {

        setOpenCancelConfirmDlg(false);

        if (nvalue == null)
            return;

        try {

            let apiUrl = new URL(
                process.env.REACT_APP_UPDATE_USER_ENROLMENT_STATUS,
                process.env.REACT_APP_BASE_URL,
            );

            const inputJson = {
                enrmntId: parseInt(props.enrmntid),
                enrmntStatusId: EnrollmentStatus.CancelApplication
            };

            setIsLoading(true);

            let response = await APICall({ url: apiUrl.href, method: 'POST', payload: inputJson, contentType: 'application/json', responseType: '' }, userId, sessionId);

            //let response = await CallAPI({ url: apiUrl.href, method: 'POST', headers: {}, body: inputJson }, userId, sessionId);

            if (response !== null && response !== undefined) {

                setIsLoading(false);

                if (response.Errors.length > 0) {
                    setErrorMsg(response.Errors[0].Message);
                }
                else if (response.meta.Updated === "1") {

                    await WriteToActivityLog(ActivityLogEvent.Application, "Application cancelled. Application name: '" + props.name + "'.", userId, sessionId);
                    setSuccessMsgTitle('Application cancelled');
                    setSuccessMsg("You application has been cancelled. We will not be moving your application forward.");
                    setEnrollmentStatusId(EnrollmentStatus.CancelApplication);
                    setAppStatusStr(GetAppSatusStr(EnrollmentStatus.CancelApplication));
                }
            }
        }
        catch (err) {
            setErrorMsg(ErrorMessages.UnknownError);
            setIsLoading(false);
        }
        finally {
        }

        goToTop();

    };

    const displaySectionStatus = async (headerTypes, actualData, isInital) => {

        let ind, ind2;
        let noOfRequiredFieldHasData;
        let requiredItems;
        let userData;
        let nmPrfx = '';
        let myArray;

        //const responseItems = JSON.parse(enrollmentDetail.current);

        for (ind = 0; ind < headerTypes.length; ind++) {
            if (headerTypes[ind].type.toString() === "header" && headerTypes[ind].className == "section-group") {
                myArray = headerTypes[ind].name.split("_");
                nmPrfx = myArray[1];

                let data = [];
                try {
                    data = JSON.parse(enrollmentDetail.current).filter((section) => {
                        return section.sectionIdentifier.toString().endsWith(nmPrfx);
                    });
                } catch (e) {
                    data = (enrollmentDetail.current).filter((section) => {
                        return section.sectionIdentifier.toString().endsWith(nmPrfx)
                    });
                }

                if (data != null && data != undefined) {

                    noOfRequiredFieldHasData = 0;
                    requiredItems = data.filter(item => item.required === true);

                    for (ind2 = 0; ind2 < requiredItems.length; ind2++) {
                        userData = requiredItems[ind2].userData;
                        //if (userData && userData.length > 0) {
                        if ((userData && userData.length > 0 && userData[0] !== '' && userData[0].length > 0) ||
                            (requiredItems[ind2].value != undefined && requiredItems[ind2].value != null && requiredItems[ind2].value != '')) {
                            noOfRequiredFieldHasData += 1;
                        }
                    }

                    headerTypes[ind].sectionStatus = (requiredItems.length === 0 ? 'Optional' : (requiredItems.length === noOfRequiredFieldHasData) ? 'Complete' : 'Required');
                }
            }
        }
    }

    const nextButtonClick = async () => {

        onChangeFormData();

        let curRowIndex = curSecHdrIdx;
        let currSection = sectionHeaders.current[curRowIndex];

        const myArray = currSection.name.split("_");
        const nmPrfx = myArray[1];

        let data = [];
        try {
            data = JSON.parse(enrollmentDetail.current).filter((section) => {
                return section.sectionIdentifier.toString().endsWith(nmPrfx);
            });
        } catch (e) {
            data = (enrollmentDetail.current).filter((section) => {
                return section.sectionIdentifier.toString().endsWith(nmPrfx)
            });
        }

        let validtnMsg = await ValidateForm(data, EnrollmentStatus.SubmitApplication);

        setSuccessMsg('');
        setErrorMsg('');
        if (validtnMsg.length > 0) {
            setErrorMsg("Fill up " + validtnMsg + " inormation before submitting the form.");
            goToTop();
            return;
        }

        if (currSection.onblur !== null && currSection.onblur !== undefined && currSection.onblur !== '') {
            validtnMsg = new Function(currSection.onblur)();
            if (validtnMsg && validtnMsg.length > 0) {
                setErrorMsg(validtnMsg);
                goToTop();
                return;
            }
        }

        sectionDivider(sectionHeaders.current, enrollmentDetail.current, false, nextId);
    }

    const sectionDivider = (headerTypes, actualData, isInital, sectionId) => {

        //Preparing Card Wise Data

        let nmPrfx = '';
        let curRowIndex = 0; 

        if (!isInital) {
            onChangeFormData();
            curRowIndex = headerTypes.findIndex(item => item.name.toString().endsWith(sectionId));
        }

        setCurSecHdrIdx(curRowIndex);

        setAppEventSts(AppEventStatus.InComplete);
        setsavedMessage('');

        let currSection = headerTypes[curRowIndex];
        if (currSection.type.toString() === "header" && currSection.className == "section-group") {
            const myArray = headerTypes[curRowIndex].name.split("_");
            nmPrfx = myArray[1];

            //Prev State
            if (curRowIndex === 0)
                setPrevId('');
            else {
                const myArray = headerTypes[curRowIndex - 1].name.split("_");
                let prevNmPrfx = myArray[1];
                setPrevId(prevNmPrfx);
            }

            let cardObj = {
                id: null,
                visibile: true,
                currSec: null,
                values: []
            };

            let data = [];
            try {
                data = JSON.parse(actualData).filter((section) => {
                    return section.sectionIdentifier.toString().endsWith(nmPrfx);
                });
            } catch (e) {
                data = (actualData).filter((section) => {
                    return section.sectionIdentifier.toString().endsWith(nmPrfx)
                });
            }

            cardObj.id = nmPrfx;
            cardObj.visibile = true;
            cardObj.values = data;
            cardObj.currSec = currSection;

            if (isAppReadOnly) {
                setDisSection(true);
            }
            else {
                setDisSection(false);
            }

            setCurId(nmPrfx);
            setSections(cardObj);
            if (curRowIndex === headerTypes.length - 1) {
                setNextId('');
            }
            else {
                const myArray = headerTypes[curRowIndex + 1].name.split("_");
                let nextNmPrfx = myArray[1];
                setNextId(nextNmPrfx);
            }

            displaySectionStatus(headerTypes, actualData, isInital);
        }
    };

    const onChangeFormData = () => {

        const responseItems = JSON.parse(enrollmentDetail.current);  //existing data
        let formData = $('.fb-render').formRender("userData"); //new data

        let ctrlObj = null;
        for (let i = 0; i < responseItems.length; i++) {
            ctrlObj = responseItems[i];
            if (ctrlObj.type === "checkbox-group" || ctrlObj.type === "radio-group" || ctrlObj.type === "select") {
                if (ctrlObj.values != undefined && ctrlObj.values != null) {
                    for (var k = 0; k < ctrlObj.values.length; k++) {
                        if (ctrlObj.values[k].selected) {
                            ctrlObj.values[k].selected = false;
                            if (ctrlObj.userData != null && ctrlObj.userData != undefined) {
                                let found = ctrlObj.userData.find(element => element.value == ctrlObj.values[k].value);
                                if (!found) {
                                    ctrlObj.userData.push(ctrlObj.values[k].value);
                                }
                            }
                            else {
                                ctrlObj.userData = [ctrlObj.values[k].value];
                            }
                        }
                    }
                }
            }
            else if (ctrlObj.type === "number" || ctrlObj.type === "textarea" || ctrlObj.type === "text" || ctrlObj.type === "date") {
                if (ctrlObj.value != undefined && ctrlObj.value != null) {
                    if (ctrlObj.userData != null && ctrlObj.userData != undefined) {
                        let found = ctrlObj.userData.find(element => element.value == ctrlObj.value);
                        if (!found) {
                            ctrlObj.userData.push(ctrlObj.value);
                        }
                    }
                    else {
                        ctrlObj.userData = [ctrlObj.value];
                    }
                }
            }
        }

        let isDisabled = false;

        ctrlObj = null;
        let ctrlVal = null;

        var inputData = [];

        for (var ind = 0; ind < formData.length; ind++) {
            const itemNm = formData[ind].name;
            const userData = formData[ind].userData;

            const curRowIndex = responseItems.findIndex(item => item.name.toString() === itemNm);

            ctrlObj = responseItems[curRowIndex];

            let ctrlId = itemNm;
            //if ($('#' + ctrlId).prop('required')) {
            //    ctrlObj.required = true;
            //}
            //else {
            //    ctrlObj.required = false;
            //}

            //isDisabled = $('#' + ctrlId).prop('disabled');

            //if (isDisabled)
            //    ctrlObj.disabled = true;

            //if (ctrlObj.disabled && !isDisabled) {
            //    ctrlObj.disabled = false;
            //}

            inputData = [];
            if (ctrlObj.type === "checkbox-group") {
                $.each($("input[name='" + itemNm + "[]']:checked"), function () {
                    inputData.push($(this).val());
                });

                ctrlObj.userData = inputData;
            }
            else if (ctrlObj.type === "radio-group") {
                $.each($("input[name='" + itemNm + "']:checked"), function () {
                    inputData.push($(this).val());
                });

                ctrlObj.userData = inputData;
            }
            else if (ctrlObj.type === "select") {
                ctrlVal = $("#" + ctrlId + " option:selected").val();
                if (ctrlVal !== '-1') {
                    inputData.push(ctrlVal);
                    ctrlObj.userData = inputData;
                }
                else {
                    ctrlObj.userData = null;
                }
            }
            else if (ctrlObj.type === "number" || ctrlObj.type === "textarea" || ctrlObj.type === "text" || ctrlObj.type === "date") {
                inputData.push($("#" + ctrlId).val());
                ctrlObj.userData = inputData;
            }
           /* }*/
        //    else {
        //        ctrlObj.userData = formData[ind].type !== 'file' ? userData : [];
        //    }
        }

        //update header status
        let headerTypes = sectionHeaders.current;

        enrollmentDetail.current = JSON.stringify(responseItems);
        sectionHeaders.current = headerTypes;

    }

    const reviewUserDataBtn = () => {
        //Last form Change Update will reflect
        setIsLoading(true);

        onChangeFormData();

        //Used Delay for State update in onChangeFormData..
        
            //Temp Data
        let emntData = JSON.parse(enrollmentDetail.current);
        let prevSectionId = curId;//Curid is last section of the JSON when it is in the Review Page.

        let cardObj = {
            id: null,
            visibile: true,
            values: []
        };

        cardObj.id = '';
        cardObj.visibile = true;
        cardObj.values = emntData;

        //this.setState({
        //    sections: [cardObj]
        //});

        setSections(cardObj);

        setAppEventSts(AppEventStatus.Review);
        setPrevId(prevSectionId);

        setIsLoading(false);
    }

    const printData = () => {
        //console.log('print');  

        var divText = document.getElementById("printablediv").innerHTML;
        let width = window.screen.width - 10;
        let height = window.screen.height - 10;
        var win = window.open('', '_blank', "toolbar=no,location=no,status=no,menubar=no,scrollbars=yes,resizable=yes,width=" + width + ",height=" + height);

        let printerIcon = '<svg fill="#000" height="36px" width="36px" version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" ';
        printerIcon += 'viewBox="-23.68 -23.68 111.36 111.36" enable-background="new 0 0 64 64" xml:space="preserve" stroke="#0000" stroke-width="1.6"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_iconCarrier"> <g id="Printer"> <path d="M57.7881012,14.03125H52.5v-8.0625c0-2.2091999-1.7909012-4-4-4h-33c-2.2091999,0-4,1.7908001-4,4v8.0625H6.2119002 C2.7871001,14.03125,0,16.8183498,0,20.2431507V46.513649c0,3.4248009,2.7871001,6.2119026,6.2119002,6.2119026h2.3798995 c0.5527,0,1-0.4472008,1-1c0-0.5527-0.4473-1-1-1H6.2119002C3.8896,50.7255516,2,48.8359489,2,46.513649V20.2431507 c0-2.3223,1.8896-4.2119007,4.2119002-4.2119007h51.5762024C60.1102982,16.03125,62,17.9208508,62,20.2431507V46.513649 c0,2.3223-1.8897018,4.2119026-4.2118988,4.2119026H56c-0.5527992,0-1,0.4473-1,1c0,0.5527992,0.4472008,1,1,1h1.7881012 C61.2128983,52.7255516,64,49.9384499,64,46.513649V20.2431507C64,16.8183498,61.2128983,14.03125,57.7881012,14.03125z M13.5,5.96875c0-1.1027999,0.8971996-2,2-2h33c1.1027985,0,2,0.8972001,2,2v8h-37V5.96875z"></path> <path d="M44,45.0322495H20c-0.5517998,0-0.9990005,0.4472008-0.9990005,0.9990005S19.4482002,47.0302505,20,47.0302505h24 c0.5517006,0,0.9990005-0.4472008,0.9990005-0.9990005S44.5517006,45.0322495,44,45.0322495z"></path> <path d="M44,52.0322495H20c-0.5517998,0-0.9990005,0.4472008-0.9990005,0.9990005S19.4482002,54.0302505,20,54.0302505h24 c0.5517006,0,0.9990005-0.4472008,0.9990005-0.9990005S44.5517006,52.0322495,44,52.0322495z"></path> <circle cx="7.9590998" cy="21.8405495" r="2"></circle> <circle cx="14.2856998" cy="21.8405495" r="2"></circle> <circle cx="20.6121998" cy="21.8405495" r="2"></circle> <path d="M11,62.03125h42v-26H11V62.03125z M13.4036999,38.4349518h37.1925964v21.1925964H13.4036999V38.4349518z"></path> </g> </g></svg>';

        //let closeIcon = '<svg fill="#000000" height="36px" width="36px" version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 36 36" xml:space="preserve">';
        //closeIcon += '<g><g><polygon points="512,59.076 452.922,0 256,196.922 59.076,0 0,59.076 196.922,256 0,452.922 59.076,512 256,315.076 452.922,512 512,452.922 315.076,256 		"/></g></g></svg>';

        //win.document.open();
        win.document.write('<html>');
        win.document.write('<head>');
        win.document.write('<title>Application Review</title>');
        win.document.write('<link href="https://cdn.jsdelivr.net/npm/bootstrap@5.0.2/dist/css/bootstrap.min.css" rel="stylesheet" integrity="sha384-EVSTQN3/azprG1Anm3QDgpJLIm9Nao0Yz1ztcQTwFspd3yD65VohhpuuCOmLASjC" crossorigin="anonymous">');
        win.document.write('<script src="https://cdn.jsdelivr.net/npm/bootstrap@5.0.2/dist/js/bootstrap.bundle.min.js" integrity="sha384-MrcW6ZMFYlzcLA8Nl+NtUVF0sA7MsXsP1UyJoMp4YLEuNSfAP+JcXn/tWtIaxVXM" crossorigin="anonymous"></script>');
        win.document.write('<style type="text/css" media="print">.no-print {display: none !important; }</style>');
       
        win.document.write('</head>');
        win.document.write('<body onload="window.print()">');
        win.document.write('<p style="display: flex;justify-content: right; text-decoration-style: solid;font-weight: bold; margin-top:20px;margin-right:50px;" class="no-print">');
        win.document.write('<span class="no-print" style="cursor: pointer;" onclick="{window.print();}" title="Print">' + printerIcon +'</span>');
        win.document.write('<span class="no-print" style="cursor: pointer;margin-top:5px;margin-left:5px;" onclick="{window.close();}" title="Close">&#10006;</span>');
        win.document.write('</p>');

        win.document.write(divText);
        win.document.write('</body></html>');
        win.document.close();
        win.open();

        //setTimeout(() => {
        //    win.print();
        //}, 1000);
    }

    const handleClickDashBoard = (event) => {
        event.preventDefault();

        //let history = props.history;
        //history('/ProspectDashboard');
        //history.push({
        //    pathname: '/ProspectDashboard'
        //});
    }
    const loadApplicantImage = async () => {

        const apiUrl = new URL(
            process.env.REACT_APP_GET_APPLICANT_IMAGE,
            process.env.REACT_APP_BASE_URL,
        );

        apiUrl.searchParams.set('enrmntId', `${parseInt(props.enrmntid)}`);
        apiUrl.searchParams.set('tag', 'ApplicantImage');

        let response = await APICall({ url: apiUrl.href, method: 'GET', responseType: 'blob' }, userId, sessionId);

        if (response) {
            if (response.type == 'image/jpeg') {
                setImageUrl(response);
            }
            else {
                setImageUrl(null);
            }
            return true;
        }

        return false;
    }

    const loadOwnersList = async () => {

        const apiUrl = new URL(
            process.env.REACT_APP_GET_PROSPECTS,
            process.env.REACT_APP_BASE_URL,
        );

        let response = await APICall({ url: apiUrl.href, method: 'GET', contentType: 'application/json', responseType: '' }, userId, sessionId);

        if (response !== null && response !== undefined) {

            let userData = response["data"];
            if (userData == null || userData == undefined) {
                console.log("Prospect user list unavailable.");
            }
            else {
                setDataOwner(userData);
                if (ownerId != -1) {
                    let selectedValue = userData.find(x => parseInt(x.UserId) === parseInt(ownerId));
                    if (selectedValue != undefined && selectedValue != null) {
                        setOwnerName(selectedValue.UserFullName);
                        setOwnerEmail(selectedValue.EmailAddress);
                        setOwnerMobile(selectedValue.MobileNbr);
                    }
                }
            }

            return true;
        }

        return false;
    }
    const GetAppSatusStr = (enrmntStatusId) => {

        switch (enrmntStatusId) {
            case EnrollmentStatus.SubmitApplication:
                return EnrollmentStatusText.SubmitApplication;
            case EnrollmentStatus.CancelApplication:
                return EnrollmentStatusText.CancelApplication;
            case EnrollmentStatus.UnderReview:
                return EnrollmentStatusText.UnderReview;
            case EnrollmentStatus.RejectApplication:
                return EnrollmentStatusText.RejectApplication;
            case EnrollmentStatus.RequestCancellation:
                return EnrollmentStatusText.RequestCancellation;
            case EnrollmentStatus.ReviewPass:
                return EnrollmentStatusText.ReviewPass;
            case EnrollmentStatus.ReviewFail:
                return EnrollmentStatusText.ReviewFail;
            case EnrollmentStatus.KYCAMLInitiated:
                return EnrollmentStatusText.KYCAMLInitiated;
            case EnrollmentStatus.KYCAMLPassed:
                return EnrollmentStatusText.KYCAMLPassed;
            case EnrollmentStatus.KYCAMLFailed:
                return EnrollmentStatusText.KYCAMLFailed;
            case EnrollmentStatus.SubmittedEnrollment:
                return EnrollmentStatusText.SubmittedEnrollment;
            case EnrollmentStatus.DocuSignRequired:
                return EnrollmentStatusText.DocuSignRequired;
            case EnrollmentStatus.DocuSignCompleted:
                return EnrollmentStatusText.DocuSignCompleted;
            case EnrollmentStatus.AccountActivated:
                return EnrollmentStatusText.AccountActivated;
            case EnrollmentStatus.InComplete:
                return EnrollmentStatusText.InComplete;
            case EnrollmentStatus.Complete:
                return EnrollmentStatusText.Complete;
            default:
                return "";
        }
    }
    const loadEnrollment = async () => {

        goToTop();
        let templateId = props.templateid;
        let enrmntId = props.enrmntid;

        setFiles([]);
        setIsLoading(true);

        const apiUrl = new URL(
            process.env.REACT_APP_GET_ENROLLMENT_BY_ID,
            process.env.REACT_APP_BASE_URL,
        );
        apiUrl.searchParams.set('enrmntId', `${parseInt(enrmntId)}`);

        try {

            //let response = await CallAPI({ url: apiUrl.href, method: 'GET', headers: {} }, userId, sessionId);
            let response = await APICall({ url: apiUrl.href, method: 'GET', contentType: 'application/json', responseType: '' }, userId, sessionId);

            if (response !== null && response !== undefined) {

                let error = IsError(response);

                if (error != 0) {
                    setErrorMsg(response.Errors[0].Message);
                    return;
                }

                await WriteToActivityLog(ActivityLogEvent.Application, "Application page viewed. Application name: '" + props.name + "'.", userId, sessionId);

                let statusId = response.data[0].enrmntStatusId;
                setEnrollmentStatusId(statusId);
                setAppStatusStr(GetAppSatusStr(response.data[0].enrmntStatusId));
                enrollmentDetail.current = response.data[0].enrmntData;
                onSubmitFormHandler.current = response.data[0].OnSubmitHandler;
                onFormLoadHandler.current = response.data[0].OnLoadHandler;

                setJntownrChecked(response.data[0].IsJointAccount);
                setJointOwnerName(response.data[0].JointOwnerName);
                setJointOwnerEmail(response.data[0].JointOwnerEmail);

                //if (parseInt(statusId) == EnrollmentStatus.SubmitApplication)
                //    setIsAppReadOnly(true);

                const headerTypes = JSON.parse(response.data[0].enrmntData).filter((headerType) => {
                    return headerType.type.toString() === "header" && headerType.className == "section-group" && (headerType.adminonly ? isAdmin.current ? true : false : true);

                });

                sectionDivider(headerTypes, JSON.parse(response["data"][0].enrmntData), true, '')

                if (headerTypes.length > 0) {
                    sectionHeaders.current = headerTypes;
                }
            }

            await loadOwnersList();
            await loadApplicantImage();
        }
        catch (err) {
            console.error(err.message);
            setErrorMsg(ErrorMessages.UnknownError);
        }
        finally {
            setIsLoading(false);
        }
    }

    useEffect(() => {
        let isProspect = IsLoggedInUserProspectUser(userId, sessionId);
        setProspect(isProspect);

        isAdmin.current = IsInRole(AOBSystemProcess.AllProcess, userId, sessionId);
        canApprove.current = IsInRole(AOBSystemProcess.ApproveApplication, userId, sessionId);
        canPublish.current = IsInRole(AOBSystemProcess.PublishApplication, userId, sessionId);

        if (isProspect) {
            setShowOwnerList('none');
        }

        let name = GetLoggedInUserName(userId);

        setOwnerName(name);

        loadEnrollment();

        if (isAppReadOnly)
            setDisSection(true);

        if (enrollmentStatusId == EnrollmentStatus.UnderReview) {
            setDisReviewPassBtn('block');
            setDisReviewFailBtn('block');
            setDisSubmitBtn('none');
        }
    }, []);

    const handleStep = (step) => () => {
        setActiveStep(step);
    };

    const handleAccordianChange = () => {
        if (expanded) {
            setExpanded(false);
        }
        else {
            setExpanded(true);
        }
    }

    const handleBackClick = () => {
        if (isProspectUser) {
            navigate('/ProspectDashboard', { state: { userId: userId, sessionId: sessionId }, replace: true });
            return;
        }
        navigate('/ApplicationDashboard', { state: { userId: userId, sessionId: sessionId }, replace: true });
    }

    const handleImageBrowseClick = (event) => {
        setShowImageDlg(true);
    };

    const handleEditSectionClick = () => {
        sections.id = 9999;
        setDisSection(false);
        setDisEditIcon('none');
        setDisEditOffIcon('block');
        setDisSaveIcon(false);
    }

    const handleEditOffClick = () => {
        sections.id = 9999;
        setDisSection(false);
        setDisEditIcon('none');
        setDisEditOffIcon('block');
        setDisSaveIcon(false);
    }

    const updateEnrollmentStatus = async (enrmntId, enrmntStatusId, reason) => {

        try {

            const inputJson = {
                enrmntId: enrmntId,
                enrmntStatusId: enrmntStatusId,
                stsReason: reason
            };

            setIsLoading(true);

            const apiUrl = new URL(
                process.env.REACT_APP_UPDATE_USER_ENROLMENT_STATUS,
                process.env.REACT_APP_BASE_URL,
            );


            let response = await APICall({ url: apiUrl.href, method: 'POST', payload: inputJson, contentType: 'application/json', responseType: '' }, userId, sessionId);

            if (response !== null && response !== undefined) {

                if (response.Errors.length > 0) {
                    setErrorMsg(response.Errors[0].Message);
                }
                else if (response.meta.Updated === "1") {
                    setAppStatusStr(GetAppSatusStr(enrmntStatusId));
                    setEnrollmentStatusId(enrmntStatusId);
                    return true;
                }
            }
        }
        catch (err) {
            setErrorMsg(ErrorMessages.UnknownError);
        }
        finally {
            setIsLoading(false);
        }

        return false;
    }

    return (
        <React.Fragment>
            <AutoLagoutTimer userId={userId} sessionId={sessionId} />
            <Container maxWidth="lg" sx={{}} className="" disableGutters={true}>
                <OverlaySpinner disappear={isLoading} />
                <Paper className="back-grid">
                    <PostLoginMenu userId={userId} sessionId={sessionId} />
                    <Grid item md={12} id="aobhead" >
                        <Box sx={{
                            pr: 0,
                            pl: 0, 
                        }}>
                            <EnrollmentHeader />
                        </Box>
                    </Grid>
                    <Grid item sx={{ pr: 1 }}>
                        <DrawerAppBar userId={userId} sessionId={sessionId} />
                    </Grid>
                    <Grid item container direction="row" spacing={0} sx={{ pt: 0, pb: 0, }}>
                        <Grid item md={12} sx={{ pl: 6.5, pr: 6.5 }}>
                            {/*<Box sx={{ mr: 8}}>*/}
                                <Box sx={{ display: 'flex', pb: 2, pt: 0, justifyContent: 'space-between', alignItems: 'flex-end', borderBottom: '0px solid #898F94' }}>
                                    <Typography variant="h6" color="text.primary" sx={{ pl: 0, pb: 0, lineHeight: 1, fontWeight: 500, pb: 0 }} >Application for {props.templatenm}</Typography>
                                </Box>
                        </Grid>
                        <Grid item md={12} sx={{}}>
                            <Box sx={{ mr: 0, }}>
                                <Divider variant="fullWidth" component="div" sx={{}} />
                            </Box>
                        </Grid>
                        <Grid item md={9} sx={{ pl: 6.5 }}>
                                <Box sx={{ mr: 2 }}>
                                <Table sx={{ ml: 0, mt: 4, border: '1px solid rgba(81, 81, 81, 1)'}} size="small">
                                        <TableRow sx={{  }} key={1}>
                                        <TableCell scope="row" sx={{ borderBottom: '1px solid rgba(81, 81, 81, 1)', minWidth: 150, pt: 2, pb: 2 }}>
                                            <Typography color="text.primary" variant="body1" sx={{ fontWeight: 400, }}>
                                                Application Owner
                                            </Typography>
                                        </TableCell>
                                        <TableCell scope="row" sx={{ borderBottom: '1px solid rgba(81, 81, 81, 1)', pt: 2, pb: 2}}>
                                            {
                                                isProspectUser ? <Typography variant="body1" sx={{ fontWeight: 400 }}>{ownerName}</Typography> :
                                                    dataOwner ?
                                                        <Box sx={{}}>
                                                            <select className="form-select" id="cboOwner" value={ownerId} onChange={(event) => changeCboOwner(event.target.value)} disabled={(isProspectUser === true || isAppReadOnly || (enrollmentStatusId != EnrollmentStatus.Complete && enrollmentStatusId != EnrollmentStatus.InComplete)) ? true : false}>
                                                                <option key={-1} value={-1}></option>
                                                                {
                                                                    dataOwner.map(item => (
                                                                        <option key={item.UserId} value={item.UserId}>{item.FullName}</option>
                                                                    ))
                                                                }
                                                            </select>
                                                        </Box>
                                                        : ''
                                            }
                                        </TableCell>
                                    </TableRow>
                                    <TableRow sx={{}} key={2}>
                                        <TableCell scope="row" sx={{ borderBottom: '1px solid rgba(81, 81, 81, 1)',  }}>
                                                <Typography color="text.primary" variant="body1" sx={{ fontWeight: 400, }}>
                                                Application Name
                                            </Typography>
                                        </TableCell>
                                        <TableCell scope="row" sx={{ borderBottom: '1px solid rgba(81, 81, 81, 1)', }}>
                                                <Typography variant="body1" sx={{ fontWeight: 400, pl: 0 }}>
                                                {props.name}
                                            </Typography>
                                        </TableCell>
                                    </TableRow>
                                    <TableRow sx={{}} key={2}>
                                        <TableCell scope="row" sx={{ borderBottom: '1px solid rgba(81, 81, 81, 1)',  }}>
                                                <Typography color="text.primary" variant="body1" sx={{ fontWeight: 400, }}>
                                                Joint Owner Name
                                            </Typography>
                                        </TableCell>
                                        <TableCell scope="row" sx={{ borderBottom: '1px solid rgba(81, 81, 81, 1)', }}>
                                                <Typography variant="body1" sx={{ fontWeight: 400, pl: 0 }}>
                                                {jointOwnerName === '' ? 'NA' : jointOwnerName}
                                            </Typography>
                                        </TableCell>
                                    </TableRow>
                                    <TableRow sx={{}} key={2}>
                                        <TableCell scope="row" sx={{ borderBottom: '1px solid rgba(81, 81, 81, 1)',  }}>
                                                <Typography color="text.primary" variant="body1" sx={{ fontWeight: 400, }}>
                                                Joint Owner's Email
                                            </Typography>
                                        </TableCell>
                                        <TableCell scope="row" sx={{ borderBottom: '1px solid rgba(81, 81, 81, 1)', }}>
                                                <Typography variant="body1" sx={{ fontWeight: 400, pl: 0 }}>
                                                {jointOwnerEmail === '' ? 'NA' : jointOwnerEmail}
                                            </Typography>
                                        </TableCell>
                                    </TableRow>
                                    </Table>
                                </Box>
                                <Stack direction="column" sx={{ pl: 0, pt: 2 }}>
                                    <Typography sx={{ borderTop: '0px solid #D4D6D9', pt: 2, mt: 0, mr: 2, fontWeight: 400 }} variant="body1" color="text.primary">
                                            Please complete the following questionnaire with as much detail as possible. This will help us connect you with the banking team that specializes in your region and industry.
                                    </Typography>
                                    <Typography variant="body1" color="text.primary" sx={{ mt: 2, mb: 1, lineHeight: 1.5, mr: 15, fontWeight: 400 }}>Upload your most recent digital passport size colour photograph(not older than 3 months).</Typography>
                                </Stack>
                            {/*</Box>*/}
                        </Grid>
                        <Grid item md={3}>
                            <Grid container>
                                <Grid item md={12} sx={{ p: 1, }} >
                                    <Box sx={{ display: 'flex', justifyContent: 'flex-end', borderLeft: '0px solid rgba(0,0,0,0.1)', pr: 5, mt: 2.5}}>
                                        <Box>
                                            <Typography variant="body2" sx={{ mt: 0, mb: 2.5, pb: 0, mr: 7, fontWeight: 400, borderBottom: '0px solid #D4D6D9' }}>Applicant photo</Typography>
                                        <Card elevation={0} sx={{ Width: '100%', mt: 1, mr: 2 }}>
                                            {imageUrl ?
                                                <CardMedia component="img" sx={{ maxWidth: 170 }} image={URL.createObjectURL(imageUrl)} />
                                                :
                                                <CardMedia sx={{ maxWidth: 170, display: 'flex', justifyContent: 'left' }}>
                                                    <img src={profimg} alt="Prifile Picture" className="prof-pic-prvw" />
                                                </CardMedia>
                                            }
                                        </Card>
                                        <Tooltip arrow title="Upload image">
                                            <span>
                                                <IconButton disabled={isAppReadOnly} sx={{ border: 0, borderColor: '#235ec9', p: 1 }} onClick={handleImageBrowseClick}>
                                                    <MoreHorizIcon width="32px" height="32px" />
                                                </IconButton>
                                            </span>
                                            </Tooltip>
                                        </Box>
                                    </Box>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item sx={{ pl: { xs: 3, md: 6.5 }, pr: { xs: 3, md: 6.5 }, pt: 1 }}>
                        <AlertWithTitle Message={successMsg} variant="body2" severity="success" color="success" icon={true} Title={successMsgTitle} />
                    </Grid>
                    <Grid item sx={{ pl: { xs: 3, md: 6.5 }, pr: { xs: 3, md: 6.5 }, pt: 1, display: submitAlert }}>
                        <Box sx={{ mb: 2, mt: 0, border: 0, borderRadius: 1 }}>

                            <Alert severity="success" sx={{ mb: 2, mt: 1 }}>
                                <AlertTitle>Application submitted successfully</AlertTitle>
                                <Typography variant="body2">Thank you for submitting your application. We’ll review your information and come back to you as soon as possible. You can check your application status </Typography>
                                <Link color="text.primary" component="button" href="#" underline="hover" variant="body1" sx={{ mb: 0, fontWeight: 500 }} onClick={(e) => ExitApplication(e)} ><strong>here</strong></Link>
                            </Alert>
                        </Box>
                    </Grid>
                    <Grid item>
                        <Grid item xs container alignItems="flex-start" direction="row" spacing={0} sx={{ pt: 1,  }}>
                            <Grid item xs={12} sx={{ pl: { xs: 3, md: 6.5 }, pr: { xs: 3, md: 1 }}}>
                                <Box sx={{ pb: 1.5, mr: 6, pt: 0, borderBottom: '0px solid #898F94', display: 'flex', justifyContent: 'space-between' }}>
                                    <Typography variant="body1" color="text.blueText" sx={{ pl: 0, pb: 0, lineHeight: 1, fontWeight: 400, pb: 0, letterSpacing: '0px' }} >Details of the Account</Typography>
                                    <Typography variant="body1" color="text.blueText" sx={{ pl: 0, pb: 0, fontWeight: 400, pb: 0, letterSpacing: '0px' }} >Status - {appStatusStr}</Typography>
                                </Box>
                            </Grid>
                            <Grid item xs={12}>
                                <Box sx={{ }}>
                                    <Divider variant="fullWidth" component="div" sx={{ }} />
                                </Box>
                            </Grid>
                            <Grid item xs={12} md={9} sx={{ pl: 6.5}}>
                                <Box sx={{  pb: 8, pr: 4, }}>
                                    <Box sx={{ pt: 1 }}>
                                        <AlertControl Message={errorMsg} severity="error" color="error" icon={true} />
                                    </Box>
                                    <Box sx={{ display: 'flex', justifyContent: 'flex-end', pt: 0, pb: 0 }}>
                                        <Typography variant="caption" color="text.primary" sx={{ textAlign: 'right', fontStyle: 'italic', fontWeight: 400 }}>* indicates a required field</Typography>
                                    </Box>
                                    <Box sx={{ pl: 0, pb: 4, borderBottom: '4px solid rgba(0,0,0,0.2)', mb: 3,   }}>
                                        {
                                            ((sections !== null > 0 && appEventSts === AppEventStatus.InComplete) ?
                                                <RenderSection data={sections} id={sections.id} visible={sections.visibile} disableAll={disSection} key={sections.id} onFileChangeValue={changeFileHandlerEvent} onFileDownload={handleDownloadFileEvent} onDeleteFile={handleDeleteFileEvent} onDeleteFile={handleDeleteFileEvent} onFormLoadHandler={onFormLoadHandler.current} />
                                                : '')
                                        }
                                    </Box>
                                    <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                                        <Stack direction="row" spacing={2}>
                                            <Box>
                                                <Button variant="contained" size="small" sx={{ textTransform: 'none', padding: '0.3rem 2.5rem', borderRadius: 10, }} disabled={!(prevId !== '' && enrollmentStatusId !== EnrollmentStatus.SubmitApplication && (appEventSts === AppEventStatus.InComplete || appEventSts === AppEventStatus.Review))} onClick={(e) => sectionDivider(sectionHeaders.current, enrollmentDetail.current, false, prevId)}>
                                                    Back
                                                </Button>
                                            </Box>
                                            <Box>
                                                <Button variant="contained" size="small" sx={{ textTransform: 'none', padding: '0.3rem 2.5rem', borderRadius: 10, }} disabled={!(nextId !== '' && enrollmentStatusId !== EnrollmentStatus.SubmitApplication && appEventSts === AppEventStatus.InComplete)} onClick={(e) => nextButtonClick()}>
                                                    Next
                                                </Button>
                                            </Box>
                                            <Box>
                                                <Button variant="contained" size="small" disabled={enrollmentStatusId != EnrollmentStatus.SubmitApplication || (enrollmentStatusId === EnrollmentStatus.SubmitApplication && !canApprove.current)} sx={{ textTransform: 'none', display: isAdminView && (enrollmentStatusId == EnrollmentStatus.SubmitApplication || enrollmentStatusId == EnrollmentStatus.UnderReview) ? 'block' : 'none', textTransform: 'none', padding: '0.3rem 1.5rem', borderRadius: 10,  }} onClick={(e) => UnderReview()}>
                                                    Under Review
                                                </Button>
                                            </Box>
                                            <Box>
                                                <Button variant="contained" size="small" disabled={!(enrollmentStatusId == EnrollmentStatus.UnderReview && nextId === '')} sx={{ textTransform: 'none', display: isAdminView && (enrollmentStatusId == EnrollmentStatus.SubmitApplication || enrollmentStatusId == EnrollmentStatus.UnderReview) ? 'block' : 'none', textTransform: 'none', padding: '0.3rem 1.5rem', borderRadius: 10,  }} onClick={(e) => ReviewPassed()}>
                                                    Review Passed
                                                </Button>
                                            </Box>
                                            <Box>
                                                <Button variant="contained" size="small" disabled={!(enrollmentStatusId == EnrollmentStatus.UnderReview && nextId === '')} sx={{ textTransform: 'none', display: isAdminView && (enrollmentStatusId == EnrollmentStatus.SubmitApplication || enrollmentStatusId == EnrollmentStatus.UnderReview) ? 'block' : 'none', textTransform: 'none', padding: '0.3rem 1.5rem', borderRadius: 10, }} onClick={(e) => ReviewFailed()}>
                                                    Review Failed
                                                </Button>
                                            </Box>
                                            <Box>
                                                <Button variant="contained" disabled={nextId !== ''} size="small" sx={{ display: (enrollmentStatusId == EnrollmentStatus.InComplete || enrollmentStatusId == EnrollmentStatus.Complete || enrollmentStatusId == EnrollmentStatus.ReviewFail) && !isAppReadOnly ? 'block' : 'none', textTransform: 'none', padding: '0.3rem 2rem', borderRadius: 10, }} onClick={(e) => SubmitData()}>
                                                    Submit
                                                </Button>
                                            </Box>
                                            <Tooltip arrow title="More options">
                                                <span>
                                                    <IconButton onClick={handleMoreMenuClick}>
                                                        <MoreVertOutlinedIcon sx={{ color: 'Icon.main', fontSize: 28, pb: 1 }} />
                                                    </IconButton>
                                                </span>
                                            </Tooltip>
                                            <Menu
                                                id="basic-menu"
                                                anchorEl={moreMenuAnchorEl}
                                                open={openMoreMenu}
                                                onClose={handleMoreMenuClose}
                                                MenuListProps={{
                                                    'aria-labelledby': 'basic-button',
                                                }}
                                            >
                                                <MenuItem sx={{ mt: 2, ml: 2 }} onClick={(event) => viewEnrollment(event)}>
                                                    <ListItemIcon sx={{   }}>
                                                        <GradingIcon sx={{ color: 'Icon.main', fontSize: 24 }} />
                                                    </ListItemIcon>
                                                    <Typography variant="body1" sx={{ textTransform: 'none',  }} color="text.primary">Review application</Typography>
                                                </MenuItem>
                                                <MenuItem sx={{ ml: 2, mt: 0.5 }} disabled={(!isAppReadOnly && (enrollmentStatusId == EnrollmentStatus.InComplete || enrollmentStatusId == EnrollmentStatus.Complete || enrollmentStatusId == EnrollmentStatus.UnderReview || enrollmentStatusId == EnrollmentStatus.ReviewFail)) || (unlockApp && nextId === '') ? false : true} onClick={(event) => saveEnrollment(event)}>
                                                    <ListItemIcon sx={{ }}>
                                                        <SaveOutlinedIcon sx={{ color: 'Icon.main', fontSize: 24 }} />
                                                    </ListItemIcon>
                                                    <Typography variant="body1" sx={{ textTransform: 'none', }} color="text.primary">Save application</Typography>
                                                </MenuItem>
                                                <MenuItem sx={{ ml: 2, mt: 0.5, mb: 3 }}  onClick={(event) => CancelApplication(event)} disabled={(enrollmentStatusId != EnrollmentStatus.InComplete && enrollmentStatusId != EnrollmentStatus.Complete &&  enrollmentStatusId != EnrollmentStatus.ReviewFail) || isAppReadOnly }>
                                                    <ListItemIcon sx={{  }}>
                                                        <CancelPresentationIcon sx={{ color: 'Icon.main', fontSize: 24 }} />
                                                    </ListItemIcon>
                                                    <Typography variant="body1" sx={{ textTransform: 'none',  }} color="text.primary">Cancel application</Typography>
                                                </MenuItem>
                                            </Menu>
                                        </Stack>
                                    </Box>
                                </Box>
                            </Grid>
                            <Grid item xs={12} md={3} sx={{}}>
                                <Box sx={{ mr: 0, pt: 3, pb: 8, pl: 0, borderTop: '0px solid #1e1e1e', borderRight: '0px solid #1e1e1e', borderBottom: '0px solid #1e1e1e',}}>
                                    <ApplicationSections sectionHeaders={sectionHeaders.current} CurrentIndex={curSecHdrIdx} appStatusStr={appStatusStr} handleClick={(event) => handleScrollIntoView(event)} />
                                </Box>
                            </Grid>

                            {showImageDlg ?
                                <EnrollmentImageUpldDlg open={showImageDlg} onClose={closeImageDlg} onChange={onPicChangeHandle} enrmntid={props.enrmntid} enrmntName={props.name} userId={userId} sessionId={sessionId} />
                                : null
                            }
                            {showAppViewDlg ?
                                <AppViewDlg open={showAppViewDlg} onClose={closeAppViewDlg} enrollData={enrollmentDetail.current} enrmntid={props.enrmntid} acctType={props.templatenm} applicantImage={imageUrl} avtarImage={profimg} applicantName={ownerName} applicantEmail={ownerEmail} applicantMobile={ownerMobile} JointOwnerName={jointOwnerName} JointOwnerEmail={jointOwnerEmail} userId={userId} sessionId={sessionId}  />
                                : null
                            }

                        </Grid>
                    </Grid>
                </Paper>
                <ConfirmDialog
                    keepMounted
                    open={openConfirmDlg}
                    onClose={handleConfirmDialogClose}
                    alertheader={confirmDlgHdr.current}
                    bmsgline1={confirmDlgBLine1.current}
                    svalue={confirmDlgSValue} 
                />
                <ConfirmDialog
                    keepMounted
                    open={openRvwPassCnfrmDlg}
                    onClose={hndlRvwPassCnfrmDlgClose}
                    alertheader={confirmDlgHdr.current}
                    bmsgline1={confirmDlgBLine1.current}
                    nvalue={props.enrmntid} // {delFileControl.current}
                />
                <ConfirmDialog
                    keepMounted
                    open={openUndrRvwCnfrmDlg}
                    onClose={hndlUndrRvwCnfrmDlgClose}
                    alertheader={confirmDlgHdr.current}
                    bmsgline1={confirmDlgBLine1.current}
                    nvalue={props.enrmntid} // {delFileControl.current}
                />
                <ConfirmDialog
                    keepMounted
                    open={openCancelConfirmDlg}
                    onClose={handleCancelConfirmDialogClose}
                    alertheader={confirmDlgHdr.current}
                    bmsgline1={confirmDlgBLine1.current}
                    nvalue={parseInt(props.enrmntid)}
                />
                <FormDialog
                    id="confirm-from-dlg"
                    keepMounted
                    open={openRvwFailCnfrmDlg}
                    onClose={hndlRvwFailCnfrmDlgClose}
                    nvalue={parseInt(props.enrmntid)}
                    alertheader={confirmDlgHdr.current}
                    bmsgline1={confirmDlgBLine1.current}
                />

                <Footer userId={userId}  />
            </Container>
        </React.Fragment>
    );
}

export function ApplicationEnrollment() {

    const location = useLocation();

    return (
        <FormRender templateid={location.state.templateid} enrmntid={location.state.enrmntid} name={location.state.name} templatenm={location.state.templatenm} userId={location.state.userId} ownerid={location.state.ownerid} sessionId={location.state.sessionId} appreadonly={location.state.appreadonly} AdminView={location.state.AdminView} EnrollmentStatus={location.state.EnrollmentStatus} unlockApp={location.state.unlockApp} />
    );
}

const ApplicationSections = (props) => {

    return (
        <>
            <Box className="app-sec-nav" sx={{pt: 3}} >
                {/*<Box sx={{ mb: 3}}>*/}
                {/*    <Typography variant="body3" color="text.secondary" sx={{ textAlign: 'right' }}>*/}
                {/*        Status - {props.appStatusStr}*/}
                {/*    </Typography>*/}
                {/*</Box>*/}
                {
                    props.sectionHeaders.map((elem, i) => (
                        <Stack key={i} direction="row" spacing={2}>
                            <Box sx={{pl: 1}}>
                                <span className={props.CurrentIndex === i ? 'step-number-active display-19' : 'step-number display-19'}><strong>{i + 1}</strong></span>
                            </Box>
                            <Box sx={{ pl: 4 }} className={props.CurrentIndex === i ? 'text-box-active' : 'text-box'}>

                                {/*<Link color={props.CurrentIndex === i ? 'text.primary' : '#bdbdbd'} component="button" href="#" underline="hover" variant="body3" sx={{ mb: 0, fontWeight: 700 }} onClick={(e) => props.handleClick(e)} id={"btn_" + elem.name} >{elem.label}</Link>*/}
                                <Typography variant="body2" color="text.secondary" sx={{ mt: 0, fontWeight: 500 }}>
                                    {elem.label}
                                </Typography>
                                <Stack direction="row" alignItems="center" spacing={1} sx={{ ml: 1 }}>
                                    <Typography variant="caption" color="text.secondary" sx={{ mt: 0 }}>
                                        {elem.sectionStatus}
                                    </Typography>
                                    {elem.sectionStatus === 'Complete' ?
                                        <DoneIcon sx={{ fontSize: 24, color: '#4caf50' }} />
                                        :
                                        <LensIcon sx={{ fontSize: 12, color: elem.sectionStatus === 'Required' ? '#ef5350' : '#4caf50' }} />
                                    }
                                </Stack>
                            </Box>
                        </Stack>
                    ))
                }
            </Box>
        </>
    );
};

const RenderSection = ({ data, id, visible, onFileChangeValue, onFileDownload, onDeleteFile, disableAll, onFormLoadHandler }) => {
    return (
        <>
            {
                <SectionFormRender id={id} style={{ display: visible === true ? "" : "none" }} data={data} key={id} onFileChangeValue={onFileChangeValue} onFileDownload={onFileDownload} onDeleteFile={onDeleteFile} disableAll={disableAll} onFormLoadHandler={onFormLoadHandler} />
            }
        </>
    );
}

class SectionFormRender extends Component {

    constructor(props) {
        super(props);

        this.fileUploadEvent = this.fileUploadEvent.bind(this);
    }

    fileUploadEvent = (fileNm) => {
        const file = document.getElementById(fileNm);
        if (file !== null) {
            if (!file.hasAttribute('listenerOnChange')) {
                file.addEventListener("change", e => {
                    this.props.onFileChangeValue(e);
                });
            }
            file.setAttribute('listenerOnChange', 'true');
        }
    }

    fileOnloadEvent = (cntrlNm, fileNm, filesize) => {
        const file = document.getElementById(cntrlNm);

        if (file !== null && fileNm && fileNm !== undefined) {
            if (!file.hasAttribute('listenerOnLoad')) {

                CreateFileLinks(cntrlNm, fileNm, filesize, this.props.onFileDownload, this.props.onDeleteFile);

                document.getElementById(cntrlNm).innerHTML = fileNm;
                document.getElementById(cntrlNm).disabled = true;
            }
            file.setAttribute('listenerOnLoad', 'true');
        }
    }

    fileDownloadEvent = (fileNm) => {
        const file = document.getElementById(fileNm);
        if (file !== null) {
            if (!file.hasAttribute('listenerOnClick')) {
                file.addEventListener("click", e => {
                    this.props.onFileDownload(e);
                });
            }
            file.setAttribute('listenerOnClick', 'true');
        }
    }

    componentDidMount() {

        $('.fb-render').formRender({
            dataType: 'json',
            formData: JSON.stringify(this.props.data["values"]),
            i18n: {
                location: process.env.REACT_APP_I18N_LANG
            }
        });

        if (this.props.onFormLoadHandler != null && this.props.onFormLoadHandler != undefined && this.props.onFormLoadHandler != '') {
            eval(this.props.onFormLoadHandler);
        }

        if (this.props.data.currSec.onload != null && this.props.data.currSec.onload != undefined && this.props.data.currSec.onload != '') {
            eval(this.props.data.currSec.onload);
        }

        const fileTypes = this.props.data["values"].filter((fileType) => {
            return fileType.type.toString() === "file";
        });

        //File Upload
        console.log(fileTypes);
        if (fileTypes.length > 0) {
            fileTypes.forEach((value, key) => {
                const file = document.getElementById(value.name);
                if (file !== null) {

                    if (!file.hasAttribute('listenerOnChange')) {
                        this.fileUploadEvent(value.name);
                    }
                    file.setAttribute('listenerOnChange', 'true');

                    if (!file.hasAttribute('listenerOnLoad')) {
                        this.fileOnloadEvent(value.name, value.value, value.filesize);
                    }
                    file.setAttribute('listenerOnLoad', 'true');
                }
            })
        }
        //End Upload
    }

    componentDidUpdate() {
    }

    render() {
        return (
            <>
                <Box elevation={0}>
                    <form>
                        <fieldset disabled={this.props.disableAll}>
                            <div className="fb-render" id='fb-render-id'>
                            </div>
                        </fieldset>
                    </form>
                </Box>
            </>
        )
    }
}

function EnrollmentBreadCumControl(props) {
    return (
        <React.Fragment >
            <Box sx={{ borderBottom: '1px solid #cdcfd2', pt: 0, pb: 4 }}>
                <Typography variant="h5" color="text.primary" sx={{ lineHeight: 1, fontWeight: 300, letterSpacing: '-1px' }} >Application for {props.templatenm}</Typography>
            </Box>
        </React.Fragment>
    );
}
