import React, { useEffect, useState } from 'react';
import PDFMerger from 'pdf-merger-js/browser';
import pdfMake from 'pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
import htmlToPdfmake from 'html-to-pdfmake';
import uuid from 'react-uuid';
import { CallAPI, UploadUserFiles, DownloadUserFiles, CallAPIUsingAxios } from '../global/APIUtils';
import { DownloadFileHelper } from '../global/Helpers';
import { ErrorMessages } from '../common/ErrorMessages';
import { APICall } from '../global/Api';

export async function ApplicationDocumentDownload (endpoint, filename, sessions, dispatch) {

    const formData = new FormData();

    try {

        let response = await DownloadUserFiles(endpoint, sessions, dispatch);
        if (response && response.data) {
            let contentType = response.headers["content-type"];
            await DownloadFileHelper(response.data, filename, contentType);
        }
    } catch (ex) {
        console.log(ex);
    }

    return null;

};

async function getBase64ImageFromURL(url) {
    return new Promise((resolve, reject) => {
        var img = new Image();
        img.setAttribute("crossOrigin", "anonymous");
        img.onload = () => {
            var canvas = document.createElement("canvas");
            canvas.width = img.width;
            canvas.height = img.height;
            var ctx = canvas.getContext("2d");
            ctx.drawImage(img, 0, 0);
            var dataURL = canvas.toDataURL("image/png");
            resolve(dataURL);
        };
        img.onerror = error => {
            reject(error);
        };
        img.src = url;
    });
}


export async function ApplicationAllDocumentDownload(enrmntId, userId, sessionId, userInfo) {

    const apiUrl = new URL(
        process.env.REACT_APP_GET_ENROLLMENT_BY_ID,
        process.env.REACT_APP_BASE_URL,
    );

    apiUrl.searchParams.set('enrmntId', `${parseInt(enrmntId)}`);

    //let response = await CallAPI({ url: apiUrl.href, method: 'GET', headers: {} }, userId, sessionId);

    let response = await APICall({ url: apiUrl.href, method: 'GET', contentType: 'application/json', responseType: '' }, userId, sessionId);

    if (response !== null && response !== undefined) {

        let apiErrors = response["Errors"];

        if (apiErrors && apiErrors.length > 0) {
            return apiErrors[0].Message;
        }

        if (response["data"] && response["data"].length > 0) {
            let enrmntData = JSON.parse(response["data"][0].enrmntData);
            let submittedDt = response["data"][0].SubmittedDate;

            if (submittedDt != null && submittedDt != undefined && submittedDt != '') {
                submittedDt = "Submitten on " + submittedDt;
            }
            else {
                submittedDt = "Not submitted";
            }

            let files = enrmntData.filter((item) => { return item.type === "file" });

            var documentDefinition = await preparePdfContent(enrmntData, response["data"][0].templateNm, userInfo, submittedDt, response["data"][0].JointOwnerName, response["data"][0].JointOwnerEmail);

            pdfMake.vfs = pdfFonts.pdfMake.vfs;

            let hasFile = files.filter(item => { return item.value !== null && item.value !== '' });
            let fileName = uuid().replace(/-/g, '') + '.pdf';

            if (hasFile && hasFile.length > 0) {
                //const pdfDocGenerator = pdfMake.createPdf(documentDefinition);
                var document = await getBlob(documentDefinition); //pdfDocGenerator.getStream();
                await prepareMergFiles(enrmntId, hasFile, document, userId, sessionId);

            } else {
                pdfMake.createPdf(documentDefinition).download(fileName);
            }
        }

    }
    else {
        return ErrorMessages.UnknownError;
    }

    return '';

};

async function getBlob(docDef) {
    const data = pdfMake.createPdf(docDef)
    const wait = pdfData => new Promise(resolve => {
        pdfData.getBlob(buffer => {
            resolve(buffer);
        });
    });
    return await wait(data);
}

async function blobToBase64(blob) {
    return new Promise(resolve => {
        const reader = new FileReader();
        reader.onloadend = () => resolve(reader.result);
        reader.readAsDataURL(blob);
    });
}

function getLookupValDescById (cntrlType, values, fieldValue)
{
    let selectedData = '';
    if (fieldValue && fieldValue !== null && fieldValue.length > 0) {
        if (cntrlType === 'checkbox-group') {
            fieldValue.forEach((value, key) => {
                let filterData = values.filter((item) => { return item.value === value.toString() })
                filterData.length > 0 ?
                    selectedData = selectedData ? selectedData + (filterData ? ', ' + filterData[0].label : '') : (filterData ? filterData[0].label : '')
                    : selectedData = selectedData + ''
            })
        } else {
            let selvalue = fieldValue.map(item => (item));
            let valuelst = values ? values.filter((item) => { return (item.value === selvalue.toString()) }) : null;
            let ddval = valuelst ? valuelst.map((item) => { return item.label }) : null;
            selectedData = (ddval !== null && ddval.length > 0) ? ddval[0] : '';
        }
    }


    return selectedData
}

async function prepareHtmlForPdf (enrmntData, userInfo) {

    let html1 = ''

    if (userInfo) {
       
        html1 = html1.concat('<table style="width:100%;">')
        html1 = html1.concat('<tr style="width:100%; border-collapse: collapse;border:0px;">')

        if (userInfo.ApplicantsImg) {
            var img = await blobToBase64(userInfo.ApplicantsImg);

            let docDefinition = {
                content: [
                    {
                        text: 'PDF Generated with Image from external URL',
                        fontSize: 20
                    },
                    {
                        image: img
                    }
                ]
            };

            //pdfMake.createPdf(docDefinition).open();

          
        }
        else {
            html1 = html1.concat('<td style="width:100%;" colspan="2" align ="center"><h5>Name: </h5>' + userInfo.ApplicantsName + '</td>')
        }
        html1 = html1.concat('</tr>')       
        html1 = html1.concat('</table>')

        //html1 = html1.concat('<div>')
        //html1 = html1.concat('<img src="https://picsum.photos/id/1080/367/267" data-src="{&quot;url&quot;:&quot;https://picsum.photos/id/1080/367/267&quot;,&quot;headers&quot;:{&quot;myheader&quot;:&quot;123&quot;}}" />')
        ////html1 = html1.concat('<img src="https://picsum.photos/seed/picsum/200/300" data-src='{ "url": "https://picsum.photos/seed/picsum/200/300", "headers": { "myheader": "123" } }' />')
        //html1 = html1.concat('</div>')

        html1 = html1.concat('<hr color="grey"/>')
    }
   

    let html = html1
    html = html.concat('<table>')

    for (let i = 0; i < enrmntData.length; i++) {
        html = html.concat('<tr style="width:100%; border-collapse: collapse;border:0px;">')

        switch (enrmntData[i].type) {
            case "header":
                if (enrmntData[i].className === "section-group") {
                    html = html.concat('<td className="m-t-12 m-b-9 rev-sec-name" colspan="2" align ="center"><h5>' + enrmntData[i].label + '</h5></td>')
                } else {
                    html = html.concat('<td className="m-t-12 m-b-9 rev-sec-name" colspan="2" align ="center">' + enrmntData[i].label + '</td>')
                }
                break;
            case "paragraph":
                html = html.concat('<td className="m-t-12 m-b-9 rev-sec-name" colspan="2">' + enrmntData[i].label + '</td>')
                break;
            case "button":
                break;
            case "radio-group":
                html = html.concat("<td>" + enrmntData[i].label + "</td><td>" + getLookupValDescById("radio-group", enrmntData[i].values, enrmntData[i].userData) + "</td>")
                break;
            case "select":
                html = html.concat("<td>" + enrmntData[i].label + "</td><td>" + getLookupValDescById("select", enrmntData[i].values, enrmntData[i].userData) + "</td>")
                break;
            case "checkbox-group":
                html = html.concat("<td>" + enrmntData[i].label + "</td><td>" + getLookupValDescById("checkbox-group", enrmntData[i].values, enrmntData[i].userData) + "</td>")
                break;
            case "file":
                html = html.concat('<td>' + enrmntData[i].label + '</td><td><i className="fa fa-download"></i>' + (enrmntData[i].value ? enrmntData[i].value : '') + '</td>')
                break;
            default:
                html = html.concat("<td>" + enrmntData[i].label + "</td><td>" + (enrmntData[i].userData ? enrmntData[i].userData : '') + "</td>")
                break;
        }
        html = html.concat('</tr>')
    }

    html = html.concat('</table>')

    //let docDefinition = {
    //    content: [{
    //        "table": {
    //            "body": [[…]],
    //            "widths": [188, "auto"],
    //            "heights": [75, 151]
    //        }
    //    }]
    //}

    return html;
}

async function preparePdfContent(enrmntData, templateNm, userInfo, submittedDt, jointOwnerName, jointOwnerEmail) {
    
    if (enrmntData && enrmntData.length > 0) {
        //var img = await blobToBase64(userInfo.ApplicantsImg);

        //Table Content
        let enrolmntContent = [];

        for (let i = 0; i < enrmntData.length; i++) {
            switch (enrmntData[i].type) {
                case "header":
                    if (enrmntData[i].className === "no-space") {
                        enrolmntContent.push(
                            [
                                {
                                    "nodeName": "HR",
                                    "margin": [0, 5, 0, 12],
                                    "canvas": [
                                        {
                                            "type": "line",
                                            "x1": 0,
                                            "y1": 0,
                                            "x2": 560,
                                            "y2": 0,
                                            "lineWidth": 0.5,
                                            "lineColor": "#ccc"
                                        }
                                    ]
                                }
                            ]
                        )
                    }
                    else {
                        enrolmntContent.push(
                            [
                                {
                                    "nodeName": "HR",
                                    "margin": [0, 15, 0, 10],
                                    "canvas": [
                                        {
                                            "type": "line",
                                            "x1": 0,
                                            "y1": 0,
                                            "x2": 560,
                                            "y2": 0,
                                            "lineWidth": 0.5,
                                            "lineColor": "#ccc"
                                        }
                                    ]
                                },
                                {
                                    text: htmlToPdfmake(enrmntData[i].label, { window: window }),
                                    //bold: true,
                                    style: 'h5',
                                    "margin": [10, 15, 0, 15],
                                }
                            ]
                        )
                    }
                    break;
                case "paragraph":
                    enrolmntContent.push(
                        [
                            {
                                text: htmlToPdfmake(enrmntData[i].label, { window: window }),
                                style: 'body2',
                                "margin": [10, 4, 15, 15],
                            }
                        ]
                    )
                    break;
                case "button":
                    break;
                case "radio-group":
                    enrolmntContent.push(
                        [
                            {
                                "margin": [10, 5, 15, 5],
                                columns: [
                                    {
                                        text: enrmntData[i].label,
                                        bold: true,
                                        style: 'body2',
                                        //"margin": [0, 15, 0, 25],
                                    },
                                ]
                            },
                            {
                                "margin": [10, 0, 15, 15],
                                columns: [
                                    {
                                        text: getLookupValDescById("radio-group", enrmntData[i].values, enrmntData[i].userData),
                                        style: 'body3',
                                        //"margin": [0, 15, 0, 25],
                                    }
                                ]
                            }
                        ]
                    )
                    break;
                case "select":
                    enrolmntContent.push(
                        [
                            {
                                "margin": [10, 5, 15, 5],
                                columns: [
                                    {
                                        text: enrmntData[i].label,
                                        bold: true,
                                        style: 'body2',
                                        //"margin": [0, 15, 0, 25],
                                    },
                                ]
                            },
                            {
                                "margin": [10, 0, 15, 15],
                                columns: [
                                    {
                                        text: getLookupValDescById("select", enrmntData[i].values, enrmntData[i].userData),
                                        style: 'body3',
                                        //"margin": [0, 15, 0, 25],
                                    }
                                ]
                            }
                        ]
                    )
                    break;
                case "checkbox-group":
                    enrolmntContent.push(
                        [
                            {
                                "margin": [10, 5, 15, 5],
                                columns: [
                                    {
                                        text: enrmntData[i].label,
                                        bold: true,
                                        style: 'body2',
                                        //"margin": [0, 15, 0, 25],
                                    },
                                ]
                            },
                            {
                                "margin": [10, 0, 15, 15],
                                columns: [
                                    {
                                        text: getLookupValDescById("checkbox-group", enrmntData[i].values, enrmntData[i].userData),
                                        style: 'body3',
                                        //"margin": [0, 15, 0, 25],
                                    }
                                ]
                            }

                        ]
                    )
                    break;
                case "file":
                    enrolmntContent.push(
                        [
                            {
                                "margin": [10, 5, 15, 5],
                                columns: [
                                    {
                                        text: enrmntData[i].label,
                                        bold: true,
                                        style: 'body2',
                                        //"margin": [0, 15, 0, 25],
                                    },
                                ]
                            },
                            {
                                "margin": [10, 0, 15, 15],
                                columns: [
                                    {
                                        text: enrmntData[i].value ? enrmntData[i].value : '',
                                        style: 'body3',
                                        //"margin": [0, 15, 0, 25],
                                    }
                                ]
                            }
                        ]
                    )
                    break;
                default:
                    enrolmntContent.push(
                        [
                            {
                                "margin": [10, 5, 20, 5],
                                columns: [
                                    {
                                        text: enrmntData[i].label,
                                        bold: true,
                                        style: 'body2',
                                        //"margin": [0, 15, 0, 25],
                                    },
                                ]
                            },
                            {
                                "margin": [10, 0, 20, 15],
                                columns: [
                                    {
                                        text: enrmntData[i].userData ? enrmntData[i].userData : '',
                                        style: 'body3',
                                        //"margin": [0, 15, 0, 25],
                                    }
                                ]
                            }
                        ]
                    )
                    break;
            }
        }

        var docDefinition = {
            //footer: function (currentPage, pageCount, pageSize) {

            //    return [
            //        {
            //            columns: [
            //                { text: submittedDt, "margin": [20, 0, 0, 0], fontSize: 9, },
            //                { text: currentPage.toString() + ' of ' + pageCount, alignment: 'right', "margin": [0, 0, 20, 0], }
            //            ]   
            //        },
            //    ]
            //},
            pageSize: 'LETTER',
            pageMargins: [40, 50, 40, 50],
            content: [
                {
                    columns: [
                        {
                            "nodeName": "IMG",
                            "image": await blobToBase64(userInfo.BankLogo),
                            width: 150,
                        },
                        {
                            width: '*',
                            "nodeName": "TABLE",                            
                            layout: 'noBorders',
                            "table": {
                                widths: ['*'],
                                body: [
                                    //[{ text: 'Demo Bank, NY', bold: true, alignment: 'right', style: 'body1' }],
                                    //[{ text: 'https://www.demobank.com/', alignment: 'right', margin: [10, 0, 0, 0], style: 'body2' }],
                                    //[{ text: '(123) 456 - 7890', alignment: 'right', margin: [10, 0, 0, 0], style: 'body2' }]

                                    //[{ text: 'SouthState Wealth', bold: true, alignment: 'right', style: 'body2', margin: [0, 0, 0, 0] }],
                                    [{ text: 'https://www.southstatebank.com/', alignment: 'right', margin: [10, 0, 0, 0], style: 'body3' }],
                                    [{ text: '(800) 277-2175', alignment: 'right', margin: [10, 0, 0, 0], style: 'body3' }]
                                ]
                            }
                        },
                    ],
                },
                {
                    "nodeName": "HR",
                    "margin": [0, 25, 0, 15],
                    "canvas": [
                        {
                            "type": "line",
                            "x1": 0,
                            "y1": 0,
                            "x2": 560,
                            "y2": 0,
                            "lineWidth": 0.5,
                            "lineColor": "#ccc"
                        }
                    ]
                },
                {
                    text: 'ACCOUNT OPENING APPLICATION',
                    style: 'body1',
                    alignment: 'center',
                },
                {
                    text: 'For a new ' + templateNm,
                    "margin": [0, 5, 0, 5],
                    style: 'body2',
                    alignment: 'center',
                },
                {
                    "nodeName": "HR",
                    "margin": [0, 5, 0, 20],
                    "canvas": [
                        {
                            "type": "line",
                            "x1": 0,
                            "y1": 0,
                            "x2": 560,
                            "y2": 0,
                            "lineWidth": 0.5,
                            "lineColor": "#ccc"
                        }
                    ]
                },
                {
                    columns: [
                        {
                            width: '*',
                            "nodeName": "TABLE",
                            margin: [0, 10, 0, 0],
                            layout: 'noBorders',
                            "table": {
                                body: [
                                    [{ text: 'Applicant: ', bold: true, margin: [10, 0, 0, 0], style: 'body2' },
                                        { text: userInfo.ApplicantsName, bold: false, margin: [10, 0, 0, 0], style: 'body3' },],
                                    [{ text: 'Email: ', bold: true, margin: [10, 0, 0, 0], style: 'body2' },
                                        { text: userInfo.ApplicantEmail, bold: false, margin: [10, 0, 0, 0], style: 'body3' },],
                                    [{ text: 'Joint Applicant: ', bold: true, margin: [10, 0, 0, 0], style: 'body2' },
                                        { text: jointOwnerName == '' ? 'NA' : jointOwnerName, bold: false, margin: [10, 0, 0, 0], style: 'body3' },],
                                    [{ text: 'Joint Applicant\'s Email: ', bold: true, margin: [10, 0, 0, 0], style: 'body2'},
                                        { text: jointOwnerEmail == '' ? 'NA' : jointOwnerEmail, bold: false, margin: [10, 0, 0, 0], style: 'body3' },]

                                ]
                            }
                        },
                        {
                            "nodeName": "IMG",
                            margin: [0, 0, 20, 0],
                            alignment: 'center',
                            "image": userInfo.ApplicantsImg ? await blobToBase64(userInfo.ApplicantsImg) : await getBase64ImageFromURL(userInfo.AvtarImage),
                            width: 140,
                        },
                    ],
                },
                //{
                //    "nodeName": "HR",
                //    "margin": [0, 15, 0, 0],
                //    "canvas": [
                //        {
                //            "type": "line",
                //            "x1": 0,
                //            "y1": 0,
                //            "x2": 530,
                //            "y2": 0,
                //            "lineWidth": 0.5,
                //            "lineColor": "#BBBFC9"
                //        }
                //    ]
                //},
                enrolmntContent
            ],
            styles: {
                h4: {
                    fontSize: 30,
                    alignment: 'center',
                    lineHeight: 1.4,
                },
                h5: {
                    fontSize: 22,
                    lineHeight: 1.4,
                },
                h6: {
                    fontSize: 18,
                    lineHeight: 1.3,
                },
                h7: {
                    fontSize: 16,
                    lineHeight: 1.3,
                },
                body1: {
                    fontSize: 14,
                },
                body2: {
                    fontSize: 12,
                    fontWeight: 300,
                    lineHeight: 1.5,
                },
                body3: {
                    fontSize: 10,
                    fontWeight: 300
                },
            }
        };


        //let docDefinition = {
        //    pageSize: 'LETTER',
        //    content: [
        //        {
        //            alignment: 'center',
        //            text: 'Review Application',
        //            style: 'header',
        //            fontSize: 23,
        //            bold: true,
        //            margin: [0, 10]
        //        },
        //        {
        //            "nodeName": "TABLE",
        //            "margin": [0, 0, 0, 10],
        //            "table": {
        //                "body": [
        //                    [
        //                        {
        //                            "text": userInfo.ApplicantsName ? "Name: " + userInfo.ApplicantsName: ''  ,
        //                            "fontSize": 14,
        //                            "nodeName": "TD",
        //                            "width": "100%",
        //                            "borderCollapse": "collapse",
        //                            "border": [
        //                                false,
        //                                false,
        //                                false,
        //                                false
        //                            ],
        //                            "style": [
        //                                "html-td",
        //                                "html-tr",
        //                                "html-tbody",
        //                                "html-table"
        //                            ]
        //                        },                                    
        //                        imageData
        //                    ]
        //                ]
        //            }
        //        },
        //        {
        //            "nodeName": "HR",
        //            "margin": [0, 0, 0, 5],
        //            "canvas": [
        //                {
        //                    "type": "line",
        //                    "x1": 0,
        //                    "y1": 0,
        //                    "x2": 530,
        //                    "y2": 0,
        //                    "lineWidth": 0.5,
        //                    "lineColor": "#6a7284"
        //                }
        //            ]
        //        } , //enrolmntContent
               
        //        {
        //            "nodeName": "TABLE",
        //            "margin": [0, 0, 0, 10],
        //            "border": [
        //                false,
        //                false,
        //                false,
        //                false
        //            ],
        //            "borderCollapse": "collapse",
        //            "table": {
        //                "body": enrolmntContent
        //            }
        //        }
        //    ],
        //    styles: {
        //        'with-margin': {
        //            marginLeft: 20 // apply a margin with the specific class is used
        //        }
        //    }
        //};

        return docDefinition;

    }

}

/*
async function prepareMergFiles(enrmntId, enrollmentFiles, enrolmentDoc, sessions, dispatch) {
    alert(enrmntId);
}
*/

async function prepareMergFiles(enrmntId, enrollmentFiles, enrolmentDoc, userId, sessionId)
{
    if (enrollmentFiles && enrollmentFiles.length > 0) {
        let files = [];
        let hasFiles = enrollmentFiles.filter((item) => { return (item.value !== null && item.value !== '') });
        
        files.push(enrolmentDoc); //First Document

        for (const file of hasFiles) {
            let filename = file["value"];
            let tagIdentifier = file["name"];
            let fileLabel = file.label;

            const apiUrl = new URL(
                process.env.REACT_APP_DOWNLOAD_ENROLLMENT_DOCUMENT,
                process.env.REACT_APP_BASE_URL,
            );
            apiUrl.searchParams.set('enrmntId', `${parseInt(enrmntId)}`);
            apiUrl.searchParams.set('attachmentId', '-1');
            apiUrl.searchParams.set('fname', `${filename}`);
            apiUrl.searchParams.set('tag', `${tagIdentifier}`);

            let response = await APICall({ url: apiUrl.href, method: 'GET', responseType: 'blob' }, userId, sessionId);

            if (response) {
                let contentType = response.type;
                var binaryData = [];
                binaryData.push(response);

                let blob = new Blob(binaryData, { type: contentType });

                if (contentType === 'application/pdf') {
                    files.push(blob)
                }
                else {
                //Convert to Image
                    var img = await blobToBase64(blob);
                    //var ret = htmlToPdfmake('<img src="' + img + '" style="max-height: 612px; max-width: 791px;" />', {
                    //    imagesByReference: true
                    //});

                    var dd = {
                            //content: ret.content,
                            //images: ret.images,
                            //pageSize: 'LETTER',
                            //defaultStyle: {
                            //    alignment: 'center'
                            //}

                        pageSize: 'LETTER',
                        pageMargins: [25, 50, 25, 50],
                        content: [
                            {
                                text: fileLabel + ": " + filename,
                                margin: [0, 0, 0, 20],
                                style: 'h5',
                            },
                            {
                                "nodeName": "IMG",
                                "image": img,
                                maxWidth: 540,
                                margin: [15, 0, 15, 0]
                            },
                        ]
                    }
                    
                    var imgDoc = await getBlob(dd);
                    files.push(imgDoc);
                 }
            };
        }
        /*});*/
        
        //Merging File

        await asyncMergPdf(files);

    }
}

async function  asyncMergPdf (files) {
    let fileName = uuid().replace(/-/g, '') + '.pdf';

    //if (appName) {
    //    fileName = appName.replace(/[/\\?%*:|"<>]/g, '-') + '.pdf';
    //}

    const merger = new PDFMerger();

    await merger.setMetadata({
        title: "Custom Title"
    });

    for (const file of files) {
        await merger.add(file)
    }

    const mergedPdf = await merger.saveAsBlob();
    const href = URL.createObjectURL(mergedPdf);

    //Download file
    // create "a" HTML element with href to file & click
    const link = document.createElement('a');
    link.href = href;
    link.setAttribute('download', fileName); //or any other extension
    document.body.appendChild(link);
    link.click();

    // clean up "a" element & remove ObjectURL
    document.body.removeChild(link);

    //if (props.isPrintOption) {
    //    let width = window.screen.width - 10;
    //    let height = window.screen.height - 10;

    //    window.open(href, "_blank", "toolbar=no,location=no,status=no,menubar=no,scrollbars=yes,resizable=yes,width=" + width + ",height=" + height);

    //} else {
    //    //Download file
    //    // create "a" HTML element with href to file & click
    //    const link = document.createElement('a');
    //    link.href = href;
    //    link.setAttribute('download', fileName); //or any other extension
    //    document.body.appendChild(link);
    //    link.click();

    //    // clean up "a" element & remove ObjectURL
    //    document.body.removeChild(link);
    //}


    //URL.revokeObjectURL(href);
}